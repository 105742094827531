import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useFormState, useForm } from 'react-final-form';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import { OnChange } from 'react-final-form-listeners';
import QCXAutocompleteSelect from '../../../shared-components/autocomplete-select/QCXAutocompleteSelect' // lista suspensa
import MultiSelectFormInput from '../../../ts/common/components/forms/MultiSelectFormInput';

export default function QCXAtributoListaEstatica({
    atributo, 
    avaliaCondicionantesEvent, 
    isCondicionado,
    isConsult,
    isComposto = false,
    codAtributoPai, 
    basePath="",
    ...restProps

}) {

    const [label, setLabel] = useState("")
    const [name,  setName] = useState("")
    const [obrigatorio, setObrigatorio] = useState(false);
    const [codigo, setCodigo] = useState("");
    const [listValores, setListValores] = useState([]);
    const [defaultValue, setDefaultValue] = useState('');
    const [tamanhoMaximo, setTamanhoMaximo] = useState(null);
    let path = `atributosPreenchidos`
    const form = useForm();

    if (basePath) {
        path = `${basePath}.${path}`
    }

    const verificaCondicionante = (value) => {
        const condicionados =  _.get(atributo, "detalhesAtributos.condicionados")
        if(condicionados && _.isArray(condicionados) && condicionados.length && _.isFunction(avaliaCondicionantesEvent)) {
            avaliaCondicionantesEvent(value, codigo)
        }
    }

    useEffect(() => {
        
        if(atributo && atributo.detalhesAtributos) {
            const detalhes = atributo.detalhesAtributos;
            const condicionados =  _.get(atributo, "detalhesAtributos.condicionados")
            let pathAtributo = `${path}.atributos.${detalhes.codigo}.valor`

            setLabel(detalhes.nomeApresentacao)
            setCodigo(detalhes.codigo)
            setName(pathAtributo)
            setObrigatorio(detalhes.obrigatorio)
            setTamanhoMaximo(detalhes.tamanhoMaximo)

            if(detalhes.formaPreenchimento === "LISTA_ESTATICA" && Array.isArray(detalhes.dominio)) {
                
                setListValores([...detalhes.dominio].map(el => ({
                    id: el.codigo,
                    value: `${el.codigo} - ${el.descricao}`,
                })))
                setDefaultValue({ id: null, value: '' });
            }

            if(atributo.multivalorado) {
                setName(`${path}.atributosMultivalorados.${detalhes.codigo}.valores`)
                pathAtributo = `${path}.atributosMultivalorados.${detalhes.codigo}.valores`
            } else if(isComposto && codAtributoPai) {
                setName(`${path}.atributosCompostos.${codAtributoPai}.valores.${detalhes.codigo}.valor`)
            }

            const currentValue = _.get(form.getState()?.values, pathAtributo)
            // if(currentValue && _.isArray(condicionados) && condicionados.length && !isCondicionado){
                // eh um atributo condicionante
                // verificaCondicionante(currentValue)
            // }
        }

        return null;
    }, [])

    const filterOptions = createFilterOptions({
        stringify: (item) => `${item.codigo} - ${item.value}`,
    });

    if(!name) {
        return null
    }

    const renderComponentList = () => {
        if(!name) return null;

        if(atributo.multivalorado) {
            return <MultiSelectFormInput name={name} options={listValores} label={label} />;
        }

        return (
            <QCXAutocompleteSelect
                label={label}
                id={name}
                name={name}
                options={listValores}
                filterOptions={filterOptions}
                defaultValue={defaultValue}
                getOptionLabel={(item) => (
                    item.value ? item.value : ''
                )}
                required={obrigatorio}
                isConsult={isConsult}
                disabled={isConsult}
                {...restProps}
            />
        )
    }

    return (
        <>
            {renderComponentList()}
            <OnChange name={name}>
                {(value, previous) => verificaCondicionante(value)}
            </OnChange>
        </>
    )
    
    

}
