import React, { useCallback, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import MultiSelectStyled, { SimpleMultiSelectOption } from '../input/multiSelectStyled';

type Props<T extends SimpleMultiSelectOption> = {
  name: string;
  options: T[];
  label: string;
  disablePortal?: boolean;
  onChangeCallback?: (selected: T[]) => void;
};

const MultiSelectFormInput = <OptionType extends SimpleMultiSelectOption>({
  name,
  options,
  label,
  disablePortal = undefined,
  onChangeCallback,
}: Props<OptionType>) => {
  const form = useForm();
  const { values } = useFormState();

  const localValues = useMemo(() => {
    return values[name];
  }, [name, values]);

  const handleValuesChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, selectedValues: SimpleMultiSelectOption[] | null) => {
      if (!selectedValues) {
        form.change(name, []);
        return;
      }

      // Talves tenha uma forma mais eficiente de fazer isso
      // Apenas dando um merge das informações ou usando um set com os ids
      const fullValues = options.filter((option) =>
        selectedValues.some((selectedValue) => selectedValue.id === option.id)
      );

      onChangeCallback?.(fullValues);

      form.change(name, [...fullValues]);
    },
    [form, options]
  );

  return (
    <MultiSelectStyled
      options={options.map((option) => ({
        id: option.id,
        value: option.value,
      }))}
      onChangeAction={handleValuesChange}
      label={label}
      controlledValues={localValues}
      disablePortal={disablePortal}
    ></MultiSelectStyled>
  );
};

export default MultiSelectFormInput;
