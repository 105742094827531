/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Paper, Tab, Tabs } from '@material-ui/core';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TipoDocumento } from 'quickcomex-api-types';
import { etapaFollowUpSelectors } from '../../../../../features/etapa-follow-up/etapaFollowUpSelectors';
import {
  dowloadMultipleFilesAsync,
  repasseByProcessosAsync,
  uploadMultipleFilesAsync,
} from '../../../../../features/etapa-follow-up/etapaFollowUpThunks';
import QCXFinalForm from '../../../../../shared-components/final-form/QCXFinalForm';
import QCXActionsManagerPageTemplate from '../../../../../templates/actions-manager-page/QCXActionsManagerPageTemplate';
import { useGetNomeUsuarioResumido } from '../../../../../ts/common/hooks/keycloak/useGetNomeUsuarioResumido';
import { MediaType } from '../../../../../utils/api/api-utils';
import { isLoadingStatus } from '../../../../../utils/store/store-utils';
import {
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
} from '../../dados-embarque/api/dadosEmbarqueSlice';
import DownloadDocumentosSection from './DownloadTabSection/DownloadDocumentosSection';
import UploadDocumentosSection from './UploadTabSection/UploadDocumentosSection';
import InformacoesSection from './InformaçõesTabSection/InformacoesSection';
import { convertLocalDateTimeStringToUTC } from '../../../../../utils/general/dateTime-utils';
import DadosSection from './DadosSection/DadosSection';
import { repasseDisByProcessosAsync, repasseFaturaByProcessosAsync } from '../../../../../features/fatura/faturaThunks';
import FaturaSection from './FaturaTabSection/FaturaSection';

export enum TabIndex {
  INFORMACOES = 0,
  FATURA = 1,
  DADOS = 2,
  UPLOAD = 3,
  DOWNLOAD = 4,
}

export default function TransferenciaLoteManagerPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const pageTitle = t('com.muralis.qcx.transferenciaLote.label');

  const nomePerfil = useGetNomeUsuarioResumido();

  const parseToFormData = useCallback((data: { documentos: any; followUpStorages: any }) => {
    const { documentos, followUpStorages } = data;

    const jsonData = followUpStorages;
    const json = JSON.stringify(jsonData);

    const blob = new Blob([json], {
      type: MediaType.APPLICATION_JSON,
    });

    const formData = new FormData();

    formData.append('data', blob);
    if (documentos && documentos?.length > 0) {
      documentos.forEach((doc: string | Blob) => {
        formData.append('files', doc);
      });
    }

    return formData;
  }, []);

  const normalizeArquivosProcessadosToFormData = useCallback((values: any[]) => {
    const documentos = values.map((doc: { file: any }) => doc.file);

    const followUpStorages = values.map((doc: { processo: any; tipoDocumento: any }) => ({
      numero: doc.processo,
      tipoDocumento: doc.tipoDocumento,
    }));

    return parseToFormData({ documentos, followUpStorages });
  }, []);

  const normalize = useCallback(
    (values: {
      [x: string]: any;
      processos: any;
      unidadeProcesso: any;
      limparDadosAposRepasse: any;
      processosInputValue: any;
      ignorableFields: any;
    }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { processos, produto, unidadeProcesso, limparDadosAposRepasse, processosInputValue, ignorableFields, ...rest } =
        values;

      let { atributosAdicionais } = values;

      const { arquivosProcessados } = ignorableFields || {};

      let formDataRequest = {
        mustSendFormData: false,
        formDataPayload: {},
      };

      if (arquivosProcessados && arquivosProcessados.length > 0) {
        const formDataPayload = normalizeArquivosProcessadosToFormData(arquivosProcessados);
        const mustSendFormData = true;
        formDataRequest = { formDataPayload, mustSendFormData };
      }

      // Back end não aceita enviar atributos adicionais sem o tipo
      // por isso, forcei o tipo para DI_DUIMP, se um dia tiver mais de um tipo no repasse, isso deve ser alterado
      if (atributosAdicionais) {
        atributosAdicionais = { ...atributosAdicionais, tipo: 'DI_DUIMP' };
      }

      return {
        params: { processos, unidadeProcesso, produto },
        payload: { ...rest, atributosAdicionais },
        limparDadosAposRepasse,
        formDataRequest,
      };
    },
    []
  );

  const [selectedTab, setSelectedTab] = useState(0);

  const handleSubmit = useCallback(
    (values: any, form: { reset: () => void }) => {
      if (selectedTab === TabIndex.DOWNLOAD) {
        dispatch(
          dowloadMultipleFilesAsync({
            processos: values.processos,
            tiposDocumentos: values.tiposDocumentos?.map((td: TipoDocumento) => td.id),
            cliente: values.cliente,
            etapa: values.etapa?.id,
            dataInicio: convertLocalDateTimeStringToUTC(values.dataInicio),
            dataFim: convertLocalDateTimeStringToUTC(values.dataFim),
          })
        );

        return;
      }

      const { payload, params, limparDadosAposRepasse, formDataRequest } = normalize(values);

      if (selectedTab === TabIndex.DADOS) {
        dispatch(repasseDisByProcessosAsync({ payload, params }));
      }

      if (selectedTab === TabIndex.INFORMACOES && (payload as any).etapa) {
        dispatch(repasseByProcessosAsync({ payload, params }));
      }

      if(selectedTab === TabIndex.FATURA) {
        dispatch(repasseFaturaByProcessosAsync({ payload, params }));
      }

      if (selectedTab === TabIndex.UPLOAD && formDataRequest.mustSendFormData) {
        dispatch(uploadMultipleFilesAsync(formDataRequest.formDataPayload));
      }

      if (limparDadosAposRepasse) {
        form.reset();
      }
    },
    [selectedTab]
  );

  const isCreate = useMemo(() => false, []);

  const isConsult = useMemo(() => false, []);

  const isUpdate = useMemo(() => true, []);

  const handleChangeToCreate = useCallback(() => {
    dispatch(changeToCreateMode());
  }, []);

  const handleChangeToConsult = useCallback(() => {
    dispatch(changeToConsultMode());
  }, []);

  const handleChangeToUpdate = useCallback(() => {
    dispatch(changeToUpdateMode());
  }, []);

  const handleCancelUpdate = useCallback(() => {
    dispatch(changeToConsultMode());
  }, []);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
    ],
    []
  );

  const status = useSelector(etapaFollowUpSelectors.selectStatus);

  const isLoading = useMemo(() => isLoadingStatus(status), [status]);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const currentTab = useMemo(() => {
    switch (selectedTab) {
      case TabIndex.INFORMACOES:
        return <InformacoesSection />;
      case TabIndex.DADOS:
        return <DadosSection />;
      case TabIndex.UPLOAD:
        return <UploadDocumentosSection />;
      case TabIndex.DOWNLOAD:
        return <DownloadDocumentosSection />;
      case TabIndex.FATURA:
        return <FaturaSection />;
      default:
        return <div>Erro</div>;
    }
  }, [selectedTab]);

  return (
    <QCXActionsManagerPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      authInfo={authInfo}
      isUpdate={undefined}
      isConsult={undefined}
    >
      {() => (
        <>
          <QCXFinalForm
            initialValues={{ usuario: nomePerfil, active: true }}
            onSubmit={handleSubmit}
            isCreate={isCreate}
            isUpdate={isUpdate}
            isConsult={isConsult}
            handleChangeToCreate={handleChangeToCreate}
            handleChangeToUpdate={handleChangeToUpdate}
            handleChangeToConsult={handleChangeToConsult}
            handleCancelUpdate={handleCancelUpdate}
            disablePristine
            debugOn={undefined}
            handleSubmitValues={undefined}
            handleAlternativeSave={undefined}
            validate={undefined}
          >
            {() => (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Paper>
                      <Tabs
                        value={selectedTab}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                      >
                        <Tab label="FollowUp" />
                        <Tab label="Fatura" />
                        <Tab label="Transporte" />
                        <Tab label="Upload" />
                        <Tab label="Download" />
                      </Tabs>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <>{currentTab}</>
                  </Grid>
                </Grid>
              </>
            )}
          </QCXFinalForm>
        </>
      )}
    </QCXActionsManagerPageTemplate>
  );
}
