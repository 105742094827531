import React from 'react';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectManyClienteTable from './QCXSelectManyClienteTable';

export default function QCXSelectManyClienteFormTableManager({
  list = [],
  isConsult,
  handleSubmit,
  handleChange,
  controlComponentProps,
  configureFilterPredicate,
  ...restProps
}) {
  return (
    <QCXFormStepWizard onSubmit={handleSubmit} controlComponentProps={controlComponentProps} {...restProps}>
      {() => (
        <QCXSelectManyClienteTable
          selected={list}
          isConsult={isConsult}
          handleChange={handleChange}
          configureFilterPredicate={configureFilterPredicate}
        />
      )}
    </QCXFormStepWizard>
  );
}
