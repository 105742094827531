import { useMemo } from 'react';
import { isString } from 'lodash';
import { useSelector } from 'react-redux';
import { v4 as uuid, validate } from 'uuid';
import {
  forceUnnormalizeNumeral,
  normalizeData,
  normalizeYear,
  normalizeNumeral,
  unnormalizeNumeral,
  unnormalizeData,
} from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import { UNIDADE_DE_MEDIDA_UNIDADE } from '../../features/unidade-medida/unidadeDeMedidaUtils';
import { unidadeDeMedidaActions } from '../../features/unidade-medida/unidadeDeMedidaSlice';
import {
  isRelacaoFornecedorFabricanteDesconhecido,
  isRelacaoFornecedorFabricanteExportador,
} from '../../shared-components/select-relacao-fornecedor/relacaoFornecedorUtils';
import { normalizeLoteBond, unnormalizeLoteBond } from './FaturaMercadoriaForm/QCXFinalLotesBondManager';

function MercadoriaUtils(deps) {
  this.deps = deps;
}

const normalizeAtributosDinamicosNcm = (unnormalizedData) => {
  const atributos = {
    atributosSimples: [],
    atributosMultivalorados: [],
    atributosCompostos: [],
  }

  if (!unnormalizedData.atributosPreenchidos) return atributos
      
  if (unnormalizedData.atributosPreenchidos.atributos) {

    const keysAtributos = Object.keys(unnormalizedData.atributosPreenchidos.atributos);
    atributos.atributosSimples = keysAtributos.map(key => ({
      id: unnormalizedData.atributosPreenchidos.atributos[key].id || null,
      atributo: key,
      valor: `${unnormalizedData.atributosPreenchidos.atributos[key].valor}`,
      isMultivalorado: false,
    }))
      
  }

  if (unnormalizedData.atributosPreenchidos.atributosMultivalorados) {
    const keysAtributos = Object.keys(unnormalizedData.atributosPreenchidos.atributosMultivalorados);
    const multivalorados = unnormalizedData.atributosPreenchidos.atributosMultivalorados;

    atributos.atributosMultivalorados = keysAtributos.map(key => {
      const strArr = multivalorados[key].valores.map(el => el.id);
  
      return {
        id: multivalorados[key].id,
        atributo: key,
        valores: strArr,
        isMultivalorado: true,
      }
    })
  }

  if (unnormalizedData.atributosPreenchidos.atributosCompostos) {

    const keysAtributos = Object.keys(unnormalizedData.atributosPreenchidos.atributosCompostos);

    const atributosCompostos = { ...unnormalizedData.atributosPreenchidos.atributosCompostos };

    for (let i = 0; i < keysAtributos.length; i += 1) {
      const key = keysAtributos[i];
      const atributoAtual = atributosCompostos[key];
      const keysValores = Object.keys(atributoAtual.valores);

      const atributoComposto = {
        id: atributoAtual.id || null,
        atributo: key,
        valores: [],
      };

      atributoComposto.valores = keysValores.map((keyAtributo) => ({
        id: atributoAtual?.valores[keyAtributo]?.id,
        atributo: keyAtributo,
        valor: atributoAtual?.valores[keyAtributo]?.valor,
      }));

      atributos.atributosCompostos.push(atributoComposto);
    }
    

  }

  return atributos;

}

const unnormalizeAtributosDinamicosNcm = (normalizedData, ncm) => {
  const atributosUnnormalized = {
    atributosPreenchidos : {
      atributos: {},
      atributosMultivalorados: {},
      atributosCompostos: {},
    },
  }


  if(normalizedData && normalizedData.atributosSimples && Array.isArray(normalizedData.atributosSimples)) {
    normalizedData.atributosSimples.map(atributoSimples => {
      const {atributo, valor} = atributoSimples;
      atributosUnnormalized.atributosPreenchidos.atributos[atributo] = {valor, id: atributoSimples.id};
      return null;
    })
  }

  if(normalizedData && normalizedData.atributosMultivalorados && Array.isArray(normalizedData.atributosMultivalorados) && ncm && ncm.listaAtributosMetadados) {

    normalizedData.atributosMultivalorados.map(atributoMultivalorado => {

      const {atributo, valores} = atributoMultivalorado;
      const atributoMetadado = ncm.listaAtributosMetadados.find(el => el.codigo === atributo);

      if(atributoMetadado && atributoMetadado.detalhesAtributos && atributoMetadado.detalhesAtributos.dominio) {
        const dominioSelecionado = atributoMetadado.detalhesAtributos.dominio.filter(dominio => valores.includes(dominio.codigo));
        atributosUnnormalized.atributosPreenchidos.atributosMultivalorados[atributo] = {}
        atributosUnnormalized.atributosPreenchidos.atributosMultivalorados[atributo].id = atributoMultivalorado.id
        atributosUnnormalized.atributosPreenchidos.atributosMultivalorados[atributo].valores = dominioSelecionado.map(dominio => ({id: dominio.codigo, value: dominio.descricao}))
      }
      
      return null;
    })
  }

  if ( normalizedData && normalizedData.atributosCompostos && Array.isArray(normalizedData.atributosCompostos) && ncm && ncm.listaAtributosMetadados ) {
    normalizedData.atributosCompostos.map((atributoComposto) => {
      const { atributo, valores } = atributoComposto;
      const atributoUnnormalized = {};

      valores.forEach((atributoCompostoAtual) => {
        atributoUnnormalized[`${atributoCompostoAtual.atributo}`] = {
          valor: atributoCompostoAtual.valor,
          id: atributoCompostoAtual.id,
        };
      });

      atributosUnnormalized.atributosPreenchidos.atributosCompostos[atributo] = {
        id: atributoComposto.id,
        valores: atributoUnnormalized,
      };

      return null;
    });
  }

  return atributosUnnormalized;

}


MercadoriaUtils.prototype.normalize = function (unnormalizedData) {
  let fabricante = unnormalizedData?.fabricante?.id ? unnormalizedData?.fabricante : null;

  if (isRelacaoFornecedorFabricanteDesconhecido(unnormalizedData?.relacao)) {
    fabricante = null;
  }

  if (isRelacaoFornecedorFabricanteExportador(unnormalizedData?.relacao)) {
    fabricante = unnormalizedData?.exportador?.id ? unnormalizedData?.exportador : null;
  }

  const normalized = {
    ...unnormalizedData,
    numeroLI: unnormalizedData?.numeroLI,
    dataRegistro: normalizeData(unnormalizedData?.dataRegistro),
    partnumber: unnormalizedData?.partnumber,
    relacao: unnormalizedData?.relacao,
    fabricante,
    exportador: unnormalizedData?.exportador?.id ? unnormalizedData?.exportador : null,
    paisOrigem: unnormalizedData?.paisOrigem?.id ? unnormalizedData?.paisOrigem : null,
    descricaoResumida: unnormalizedData?.descricaoResumida,
    quantidade: normalizeNumeral(unnormalizedData?.quantidade),
    quantidadeEstatistica: normalizeNumeral(unnormalizedData?.quantidadeEstatistica),
    quantidadeEstatisticaAjustada: normalizeNumeral(unnormalizedData?.quantidadeEstatisticaAjustada),
    percentualDesconto: normalizeNumeral(unnormalizedData?.percentualDesconto),
    pesoLiquido: normalizeNumeral(unnormalizedData?.pesoLiquido),
    pesoLiquidoUnitario: normalizeNumeral(unnormalizedData?.pesoLiquidoUnitario),
    valorUnitarioMoeda: normalizeNumeral(unnormalizedData?.valorUnitarioMoeda),
    tipoCalculo: unnormalizedData?.tipoCalculo,
    icms: normalizeNumeral(unnormalizedData?.icms),
    valorTotalMcvMoeda: normalizeNumeral(unnormalizedData?.valorTotalMcvMoeda),
    valorTotalMoeda: normalizeNumeral(unnormalizedData?.valorTotalMoeda),
    valorDescontoMoeda: normalizeNumeral(unnormalizedData?.valorDescontoMoeda),
    valorTotalReal: normalizeNumeral(unnormalizedData?.valorTotalReal),
    destaqueNcm: unnormalizedData?.destaqueNcm,
    produto: unnormalizedData?.produto?.id
      ? {
          id: unnormalizedData?.produto?.id,
        }
      : null,
    unidadeDeMedida: unnormalizedData?.unidadeDeMedida?.id ? unnormalizedData?.unidadeDeMedida : null,
    ncm: unnormalizedData?.ncm?.id ? unnormalizedData?.ncm : null,
    naladiSh: unnormalizedData?.naladiSh?.id ? unnormalizedData?.naladiSh : null,
    naladiNcca: unnormalizedData?.naladiNcca?.id ? unnormalizedData?.naladiNcca : null,
    atributos: (unnormalizedData?.atributos || [])?.map((atributo) =>
      validate(atributo?.id)
        ? {
            ...atributo,
            nve: atributo?.nve?.id ? atributo?.nve : null,
            atributo: atributo?.atributo?.id ? atributo?.atributo : null,
            especificacao: atributo?.especificacao?.id ? atributo?.especificacao : null,
            id: null,
          }
        : {
            ...atributo,
            nve: atributo?.nve?.id ? atributo?.nve : null,
            atributo: atributo?.atributo?.id ? atributo?.atributo : null,
            especificacao: atributo?.especificacao?.id ? atributo?.especificacao : null,
          }
    ),
    adicionais: {
      ...unnormalizedData?.adicionais,
      numeroAtoConcessorio: unnormalizedData?.adicionais?.numeroAtoConcessorio,
      itemAtoConcessorio: unnormalizedData?.adicionais?.itemAtoConcessorio,
      numeroSerie: unnormalizedData?.adicionais?.numeroSerie,
      marca: unnormalizedData?.adicionais?.marca,
      modelo: unnormalizedData?.adicionais?.modelo,
      dataValidade: normalizeData(unnormalizedData?.adicionais?.dataValidade),
      anoFabricacao: normalizeYear(unnormalizedData?.adicionais?.anoFabricacao),
      mercadoriaAdicionaisLote: unnormalizedData?.adicionais?.mercadoriaAdicionaisLote?.map(normalizeLoteBond),
      especificacao: unnormalizedData?.adicionais?.especificacao,
    },
    ...normalizeAtributosDinamicosNcm(unnormalizedData),
  };

  return normalized;
};

MercadoriaUtils.prototype.unnormalize = function (normalizedData) {
  const foundUnidade = this.deps.unidadeMedidaList.find((item) => item.id === normalizedData?.unidadeDeMedida?.id);
  const unidadeMedidaDescription = foundUnidade?.description?.toUpperCase();

  let isUnidade = false;
  if (isString(unidadeMedidaDescription)) {
    isUnidade = unidadeMedidaDescription === UNIDADE_DE_MEDIDA_UNIDADE;
  }

  return {
    ...normalizedData,
    numeroLI: normalizedData?.numeroLI,
    dataRegistro: unnormalizeData(normalizedData?.dataRegistro),
    valorUnitarioMoeda: unnormalizeNumeral(normalizedData?.valorUnitarioMoeda, formatBrazilianNumericDecimal(7)),
    pesoLiquido: forceUnnormalizeNumeral(normalizedData?.pesoLiquido, formatBrazilianNumericDecimal(5)),
    pesoLiquidoUnitario: forceUnnormalizeNumeral(
      normalizedData?.pesoLiquidoUnitario,
      formatBrazilianNumericDecimal(12)
    ),
    quantidade: forceUnnormalizeNumeral(normalizedData?.quantidade, formatBrazilianNumericDecimal(5)),
    percentualDesconto: forceUnnormalizeNumeral(normalizedData?.percentualDesconto, formatBrazilianNumericDecimal(2)),
    icms: forceUnnormalizeNumeral(normalizedData?.icms, formatBrazilianNumericDecimal(7)),
    quantidadeEstatistica: forceUnnormalizeNumeral(
      normalizedData?.quantidadeEstatistica,
      formatBrazilianNumericDecimal(isUnidade ? 5 : 7)
    ),
    quantidadeEstatisticaAjustada: forceUnnormalizeNumeral(
      normalizedData?.quantidadeEstatisticaAjustada,
      formatBrazilianNumericDecimal(isUnidade ? 5 : 7)
    ),
    valorTotalMcvMoeda: unnormalizeNumeral(normalizedData?.valorTotalMcvMoeda, formatBrazilianNumericDecimal(2)),
    valorTotalMoeda: unnormalizeNumeral(normalizedData?.valorTotalMoeda, formatBrazilianNumericDecimal(2)),
    valorTotalReal: unnormalizeNumeral(normalizedData?.valorTotalReal, formatBrazilianNumericDecimal(2)),
    valorDescontoMoeda: unnormalizeNumeral(normalizedData?.valorDescontoMoeda, formatBrazilianNumericDecimal(2)),
    atributos: (normalizedData?.atributos || [])?.map((atributo) =>
      atributo?.id
        ? {
            ...atributo,
            nve: atributo?.nve?.id ? atributo?.nve : undefined,
            atributo: atributo?.atributo?.id ? atributo?.atributo : undefined,
            especificacao: atributo?.especificacao?.id ? atributo?.especificacao : undefined,
          }
        : {
            ...atributo,
            nve: atributo?.nve?.id ? atributo?.nve : undefined,
            atributo: atributo?.atributo?.id ? atributo?.atributo : undefined,
            especificacao: atributo?.especificacao?.id ? atributo?.especificacao : undefined,
            id: uuid(),
          }
    ),
    adicionais: {
      ...normalizedData?.adicionais,
      anoFabricacao: `${normalizedData?.adicionais?.anoFabricacao || ''}`,
      mercadoriaAdicionaisLote: normalizedData?.adicionais?.mercadoriaAdicionaisLote.map(unnormalizeLoteBond),
    },
    ...unnormalizeAtributosDinamicosNcm(normalizedData),
  };
};

const useMercadoriaUtils = () => {
  const unidadeMedidaList = useSelector(unidadeDeMedidaActions.selectUnidadesdeMedida);

  const mercadoriaUtils = useMemo(
    () =>
      new MercadoriaUtils({
        unidadeMedidaList,
      }),
    [unidadeMedidaList]
  );

  return mercadoriaUtils;
};

export { MercadoriaUtils, useMercadoriaUtils, normalizeAtributosDinamicosNcm };
