import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAllAsync,
  fetchByFilterAsync,
  fetchClientesAssociadosACatalogoProdutosAsync,
  fetchClientesWithFilterDtoAsync,
  selectClientes,
  selectConfigControl,
} from '../../features/cliente/clienteSlice';
import { setRelatedClienteAtual } from '../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSlice';
import { formatCnpj } from '../../utils/hooks/form/field/formatters';
import { isAutoLoadControl } from '../../utils/store/store-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import { ClienteUtils } from './clienteUtils';
import { fetchClientesWithFilterDto } from '../../features/cliente/clienteAPI';

export default function QCXSelectClienteAutocomplete({ label, name, initialValues, filter = undefined, ...restProps }) {
  const dispatch = useDispatch();

  const list = useSelector(selectClientes);
  const configControl = useSelector(selectConfigControl);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    dispatch(setRelatedClienteAtual(defaultValue));
  }, [defaultValue]);

  useEffect(() => {
    const fetchAndLoad = () => {
      if (filter && filter?.importador) {
        dispatch(
          fetchByFilterAsync([
            {
              name: 'tipoEmpresa',
              value: ClienteUtils.IMPORTADOR,
            },
          ])
        );

        return;
      }

      if (filter && filter?.exportador) {
        dispatch(
          fetchByFilterAsync([
            {
              name: 'tipoEmpresa',
              value: ClienteUtils.EXPORTADOR,
            },
          ])
        );

        return;
      }

      if (filter && filter?.clientesAssociadosACatalogoProdutos) {
        dispatch(fetchClientesAssociadosACatalogoProdutosAsync());

        return;
      }

      if (isAutoLoadControl(configControl)) {
        dispatch(fetchClientesWithFilterDtoAsync());
      }
    };

    fetchAndLoad();
  }, [configControl]);

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.id || ''} - ${item.pessoa?.nome || item.description}`,
  });
  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) =>
        item?.id && item.pessoa?.nome && item?.description
          ? `${item?.id || ''} - ${item?.pessoa?.nome || item?.description} - ${formatCnpj(item?.pessoa?.cnpj) || ''}`
          : ''
      }
      {...restProps}
    />
  );
}
