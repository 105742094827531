import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import debounce from 'lodash/debounce';
import QCXCidadeForm from '../../../../../components/cidade/QCXCidadeForm';
import { register, save, checkForDuplicates } from '../../../../../features/cidade/cidadeAPI';
import { setSuccessFeedback, setErrorFeedback } from '../../../../../features/feedback/feedbackSlice';
import {
    addToList,
    changeToConsultMode,
    changeToCreateMode,
    changeToUpdateMode,
    fetchByIdAsync,
    resetModel,
    setModel,
    success,
    failure,
    selectCidade,
    selectMode, 
    loading,
    setError,
    updateOnList,
    setResponse,
} from '../../../../../features/cidade/cidadeSlice';
import {
    isConsultMode,
    isCreateMode,
    isUpdateMode,
} from '../../../../../utils/store/store-utils';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';

export default function CidadeRegistrationPage({ authInfo = {} }) {
    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const cidade = useSelector(selectCidade);
    const mode = useSelector(selectMode);

    const isCreate = useMemo(() => isCreateMode(mode), [mode]);
    const isConsult = useMemo(() => isConsultMode(mode), [mode]);
    const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

    useEffect(() => {
        if (id) {
            dispatch(fetchByIdAsync(id));
            dispatch(changeToConsultMode());
        }
    }, [id, dispatch]);

    const handleChangeToCreate = () => {
        dispatch(changeToCreateMode());
    };

    const handleChangeToConsult = () => {
        dispatch(changeToConsultMode());
    };

    const handleChangeToUpdate = () => {
        dispatch(changeToUpdateMode());
    };

    const handleCancelUpdate = () => {
        const currentId = cidade?.id || id;
        if (currentId) {
            dispatch(fetchByIdAsync(currentId));
        }
        handleChangeToConsult();
    };

    const create = async (data) => {
        const executeDebounced = debounce(async () => {
            try {
                const response = await register(data);

                if (response.status === 201) {
                    dispatch(setSuccessFeedback({ message: 'Cidade cadastrada com sucesso!' }));
                    dispatch(resetModel());

                    const created = response.data;

                    dispatch(addToList({ data: created }));
                    dispatch(success());
                    dispatch(
                        setResponse({
                            status: response.status,
                            data: created,
                            message: 'Cidade Cadastrada com Sucesso',
                        })
                    );
                    history.push(t('com.muralis.qcx.url.moduloOperacionaisCidade'));
                }
            } catch (error) {
                dispatch(setErrorFeedback({ message: 'Erro ao atualizar cidade' }));
                dispatch(failure());
                dispatch(
                    setError({
                        message: 'Erro ao Cadastrar cidade',
                    })
                );
            }
        }, 500);

        dispatch(loading());
        executeDebounced();
    };

    const update = async (data) => {
        const executeDebounced = debounce(async () => {
            try {
                const response = await save(data);

                if (response.status === 200) {
                    const saved = response.data;
                    dispatch(setSuccessFeedback({ message: 'Cidade atualizada com sucesso!' }));
                    dispatch(success());
                    dispatch(setModel(saved));
                    dispatch(updateOnList({ data: saved }));
                    dispatch(fetchByIdAsync(saved.id));
                    dispatch(
                        setResponse({
                            status: response.status,
                            data: saved,
                            message: 'Cidade Atualizada com Sucesso',
                        })
                    );
                    handleChangeToConsult(); // Switch to consult mode after update
                }
            } catch (error) {
            dispatch(setErrorFeedback({ message: 'Erro ao atualizar cidade, verifique o código da cidade.' }));
                console.log(error);
                dispatch(failure());
                dispatch(
                    setError({
                        message: 'Erro ao Atualizar cidade',
                    })
                );
            }
        }, 500);

        dispatch(loading());
        executeDebounced();
    };

    const handleSubmit = async (data) => {
        const brasil = (data?.estado?.id < 280) && (data?.estado?.id > 253);
        if (brasil) {
            dispatch(setErrorFeedback({ message: 'Não é permitido selecionar estados do BRASIL.' }));
            return;
        } 
        if (!brasil){     
            if (isUpdate) {
                await update(data);
            } else {
                try {
                    const duplicateCheckResponse = await checkForDuplicates(data);
                    const isDuplicate = duplicateCheckResponse?.data?.isDuplicate; 
                    if (isDuplicate) {
                        dispatch(setErrorFeedback({ message: 'Já existe uma Cidade com este código. Por favor, verifique.' }));
                        return; 
                    }
                    await create(data);
                } catch (error) {
                    dispatch(setErrorFeedback({ message: 'Erro ao verificar duplicatas. Por favor, tente novamente.' }));
                }
            }
        }
    
    };

    const model = useMemo(() => (isCreate ? {} : cidade), [isCreate, cidade]);

    const breadcrumbs = useMemo(() => [
        { link: { to: '/', name: t('com.muralis.qcx.inicio') } },
        { text: { name: t('com.muralis.qcx.cadastros') } },
        { link: { to: t('com.muralis.qcx.url.moduloOperacionais'), name: t('com.muralis.qcx.operacionais') } },
        { link: { to: t('com.muralis.qcx.url.moduloOperacionaisCidade'), name: t('com.muralis.qcx.endereco.cidade') } },
        { text: { name: isCreate ? t('com.muralis.qcx.acoes.novo') : t('com.muralis.qcx.acoes.visualizar') } },
    ], [isCreate, t]);

    const pageTitle = useMemo(() =>
        isCreate
            ? 'Nova cidade'
            : 'Editar cidade',
        [isCreate, t]
    );

    return (
        <QCXRegistrationFormPageTemplate
            pageTitle={pageTitle}
            breadcrumbs={breadcrumbs}
            isCreate={isCreate}
            isConsult={isConsult}
            isUpdate={isUpdate}
            handleChangeToCreate={handleChangeToCreate}
            handleChangeToConsult={handleChangeToConsult}
            handleChangeToUpdate={handleChangeToUpdate}
            handleCancelUpdate={handleCancelUpdate}
            authInfo={authInfo}
        >
            {(formProps) => (
                <QCXCidadeForm
                    initialValues={model}
                    handleSubmit={handleSubmit}
                    isConsult={isConsult}
                    isUpdate={isUpdate}
                    authInfo={authInfo}
                    {...formProps}
                />
            )}
        </QCXRegistrationFormPageTemplate>
    );
}
