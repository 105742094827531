import { debounce, isEmpty, isFunction } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import i18n from '../../../../../i18n';
import { fetchByIdAsync } from '../../../../../features/modelo-danfe/modeloDanfeThunks';
import {
  selectModeloDanfe,
  selectStatus,
  selectMode,
  selectBackgroundMode,
} from '../../../../../features/modelo-danfe/modeloDanfeSelectors';
import {
  isBackgroundCreateMode,
  isConsultMode,
  isCreateMode,
  isFailureStatus,
  isIdleStatus,
  isLoadingStatus,
  isNoneMode,
  isPreparingActionStatus,
  isUpdateMode,
} from '../../../../../utils/store/store-utils';
import { associateModeloDanfe } from '../../../../../features/cliente/clienteAPI';
import {
  changeToUpdateMode,
  changeToConsultMode,
  changeToBackgroundCreateMode,
  setModel,
  success,
  loading,
  failure,
  changeToCreateMode,
  setResponse,
  setError,
  resetModel,
  addToList,
  resetBackgroundMode,
  preparingAction,
} from '../../../../../features/modelo-danfe/modeloDanfeSlice';
import { register, save } from '../../../../../features/modelo-danfe/modeloDanfeAPI';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import QCXModeloDanfeWizardFinalForm from '../../../../../components/modelo-danfe/QCXModeloDanfeWizardFinalForm';
import {
  selectClientes,
  fetchAllAsync as fetchAllClientesAsync,
  // setList as setListaClientes,
} from '../../../../../features/cliente/clienteSlice';
import { normalizeCSTBond, unnormalizeCSTBond } from '../../../../../components/modelo-danfe/CSTBondManager';

function normalizeBoolean(s) {
  if (s === 'SIM') {
    return true;
  }
  if (s === 'NÃO') {
    return false;
  }
  if (s !== undefined) {
    return s;
  }

  return undefined;
}

function unnormalizeBoolean(b) {
  if (b === true) {
    return i18n.t('com.muralis.qcx.expressao.sim').toUpperCase();
  }
  if (b === false) {
    return i18n.t('com.muralis.qcx.expressao.nao').toUpperCase();
  }
  return undefined;
}

function normalize(unnormalizedData) {
  const {
    agruparExportador,
    agruparFabricante,
    agruparCfop,
    agruparAliquotaIcms,
    exibirCif,
    considerarBaseReduzidaICMS,
    baseImpostoEhOculto,
    freteInternacionalEhOculto,
    ipiEhOculto,
    seguroEhOculto,
    freteEhOculto,
    totalIncluiValorProdutos,
    totalIncluiICMS,
    totalIncluiDespesasAssessorias,
    totalIncluiIPI,
    totalIncluiII,
    totalIncluiPIS,
    totalIncluiCOFINS,
    totalIncluiTaxaSiscomex,
    totalIncluiAFRMM,
    totalIncluiFecp,
    totalIncluiFrete,
    totalIncluiSeguro,
    totalIncluiAcrescimoDeducao,
    cstList,
    agruparRegimeTributacaoIcms,
    agruparPorQuebraAdicional,
    agruparPorAuxiliarInvoice,
    agruparPorArea,
    agruparPorCstIcms,
    ...rest
  } = unnormalizedData;

  return {
    ...rest,
    agruparExportador: normalizeBoolean(agruparExportador),
    agruparFabricante: normalizeBoolean(agruparFabricante),
    agruparCfop: normalizeBoolean(agruparCfop),
    agruparAliquotaIcms: normalizeBoolean(agruparAliquotaIcms),
    agruparRegimeTributacaoIcms: normalizeBoolean(agruparRegimeTributacaoIcms),
    agruparPorQuebraAdicional: normalizeBoolean(agruparPorQuebraAdicional),
    agruparPorAuxiliarInvoice: normalizeBoolean(agruparPorAuxiliarInvoice),
    agruparPorArea: normalizeBoolean(agruparPorArea),
    agruparPorCstIcms: normalizeBoolean(agruparPorCstIcms),
    exibirCif: normalizeBoolean(exibirCif),
    exibirCifIcms: normalizeBoolean(rest.exibirCifIcms),
    considerarBaseReduzidaICMS: normalizeBoolean(considerarBaseReduzidaICMS),
    baseImpostoEhOculto: normalizeBoolean(baseImpostoEhOculto),
    freteInternacionalEhOculto: normalizeBoolean(freteInternacionalEhOculto),
    ipiEhOculto: normalizeBoolean(ipiEhOculto),
    seguroEhOculto: normalizeBoolean(seguroEhOculto),
    freteEhOculto: normalizeBoolean(freteEhOculto),
    totalIncluiValorProdutos: normalizeBoolean(totalIncluiValorProdutos),
    totalIncluiII: normalizeBoolean(totalIncluiII),
    totalIncluiIPI: normalizeBoolean(totalIncluiIPI),
    totalIncluiPIS: normalizeBoolean(totalIncluiPIS),
    totalIncluiCOFINS: normalizeBoolean(totalIncluiCOFINS),
    totalIncluiTaxaSiscomex: normalizeBoolean(totalIncluiTaxaSiscomex),
    totalIncluiAFRMM: normalizeBoolean(totalIncluiAFRMM),
    totalIncluiICMS: normalizeBoolean(totalIncluiICMS),
    totalIncluiFecp: normalizeBoolean(totalIncluiFecp),
    totalIncluiFrete: normalizeBoolean(totalIncluiFrete),
    totalIncluiSeguro: normalizeBoolean(totalIncluiSeguro),
    totalIncluiDespesasAssessorias: normalizeBoolean(totalIncluiDespesasAssessorias),
    totalIncluiAcrescimoDeducao: normalizeBoolean(totalIncluiAcrescimoDeducao),
    cstList: cstList?.map((cst) => normalizeCSTBond(cst)) || [],
  };
}

export default function ModeloDanfeRegistrationPage({ authInfo = {} }) {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const modeloDanfe = useSelector(selectModeloDanfe);
  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);

  const listaTodosClientes = useSelector(selectClientes);

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);
  const isLoading = useMemo(() => isLoadingStatus(status), [status]);
  const isIdle = useMemo(() => isIdleStatus(status), [status]);
  const isFailure = useMemo(() => isFailureStatus(status), [status]);
  const isNone = useMemo(() => isNoneMode(mode), [mode]);
  const isCreate = useMemo(() => isCreateMode(mode), [mode]);
  const isConsult = useMemo(() => isConsultMode(mode), [mode]);
  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);
  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const fetchById = useCallback((modeloDanfeId) => dispatch(fetchByIdAsync(modeloDanfeId)), []);

  const handleChangeToCreate = useCallback(() => {
    dispatch(changeToCreateMode());
  });

  const handleChangeToConsult = useCallback(() => {
    dispatch(changeToConsultMode());
  }, []);

  useEffect(() => {
    dispatch(fetchAllClientesAsync());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const handleCancelUpdate = useCallback(() => {
    const currentId = isBackgroundCreate ? modeloDanfe?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
    handleChangeToConsult();
  }, [id, modeloDanfe]);

  const handleChangeToUpdate = useCallback(() => {
    dispatch(changeToUpdateMode());

    // if (!isEmpty(additional) && isFunction(additional?.callback)) {
    //   additional.callback();
    // }
  }, []);

  const handleChangeToBackgroundCreate = () => {
    dispatch(changeToBackgroundCreateMode());
  };

  const handleResetBackgroundMode = () => {
    dispatch(resetBackgroundMode());
  };

  const handleChangeToPreparingAction = () => {
    dispatch(preparingAction());
  };

  // eslint-disable-next-line no-unused-vars
  const handleDispatchSetModel = useCallback(
    (data) => {
      dispatch(setModel(normalize(data)));
    },
    [normalize]
  );

  const unnormalize = useCallback(
    (unnormalizedData) => {
      const {
        agruparExportador,
        agruparFabricante,
        agruparRegimeTributacaoIcms,
        considerarBaseReduzidaICMS,
        agruparCfop,
        agruparAliquotaIcms,
        exibirCif,
        cstList,
        ...rest
      } = unnormalizedData;

      const unnormalizedClientes = (modeloDanfe?.clientes || listaTodosClientes || [])
        .filter((cliente) => typeof modeloDanfe?.id === 'number' && cliente?.modeloDanfe?.id === modeloDanfe?.id)
        .map((cl) => cl);

      return {
        ...rest,
        agruparExportador: unnormalizeBoolean(agruparExportador),
        agruparFabricante: unnormalizeBoolean(agruparFabricante),
        agruparRegimeTributacaoIcms: unnormalizeBoolean(agruparRegimeTributacaoIcms),
        agruparCfop: unnormalizeBoolean(agruparCfop),
        agruparAliquotaIcms: unnormalizeBoolean(agruparAliquotaIcms),
        agruparPorQuebraAdicional: unnormalizeBoolean(rest.agruparPorQuebraAdicional),
        agruparPorAuxiliarInvoice: unnormalizeBoolean(rest.agruparPorAuxiliarInvoice),
        agruparPorArea: unnormalizeBoolean(rest.agruparPorArea),
        agruparPorCstIcms: unnormalizeBoolean(rest.agruparPorCstIcms),
        exibirCif: unnormalizeBoolean(exibirCif),
        exibirCifIcms: unnormalizeBoolean(rest.exibirCifIcms),
        considerarBaseReduzidaICMS: unnormalizeBoolean(considerarBaseReduzidaICMS),
        clientes: unnormalizedClientes,
        cstList: cstList?.map((cst) => unnormalizeCSTBond(cst)) || [],
      };
    },
    [modeloDanfe, listaTodosClientes]
  );

  // Chamado pelo handleSubmit quando estamos criando um item novo.
  const createModeloDanfe = async (data, step, next) => {
    let executeDebounced;
    switch (step) {
      case 0:
        // Salvamos o modelo de danfe, somente sem atribuir nenhum
        // cliente a este novo modelo.
        executeDebounced = debounce(async () => {
          try {
            const response = await register(data);
            if (response?.status === 200 || response?.status === 201) {
              dispatch(resetModel());
              const handleResultWithDebounce = debounce(() => {
                dispatch(success());
                const created = response?.data;
                handleDispatchSetModel(created);
                dispatch(
                  setResponse({
                    status: response.status,
                    data: created,
                    message: i18n.t('com.muralis.qcx.mensagem.modeloDANFERegistrado', { modelo: created?.code }),
                  })
                );
                dispatch(addToList({ data: created }));
              });
              handleResultWithDebounce();
            }
          } catch (e) {
            dispatch(failure());
            dispatch(
              setError({
                message: i18n.t('com.muralis.qcx.erro.erroSalvarModeloDANFEEspecifico', {
                  modelo: e.response?.data?.code,
                  erro: e.response?.data?.message,
                }),
              })
            );
          }
        }, 500);
        break;
      case 1:
        // Alteramos todos os clientes que foram selecionados, somente
        // alterando o campo de modelo de danfe para referenciar
        // este modelo.
        executeDebounced = debounce(async () => {
          // data.clientes deve ter a lista de clientes que vão ser associados
          // a este modelo de danfe. Também usamos a lista de todos os clientes
          // aqui, para saber quais alterações devemos fazer.
          // 1. Para cada cliente na lista de todos os clientes que possui este
          // modelo de danfe, mas não está presente na lista de clientes deste
          // modelo de danfe, significa que o cliente não está mais associado
          // e esta propriedade deve ser alterada. Enviamos um PUT com o cliente
          // para apagar a associação com este modelo de danfe.
          // 2. Para cada cliente na lista de todos os clientes que não possui
          // nenhum modelo de danfe associado, e está presente na lista de clientes
          // deste modelo de danfe, significa que o cliente vai passar a estar
          // associado e esta propriedade deve ser alterada. Enviamos um PUT com
          // o cliente para escrever esta associação com este modelo de danfe.

          // Função que checa se nosso form está com um determinado cliente
          // selecionado.
          const esteModeloDanfeContemCliente = (cliente) => {
            if (!Array.isArray(data?.clientes)) {
              return false;
            }
            const idx = data?.clientes?.findIndex((cl) => cl?.id === cliente?.id);
            return idx !== -1;
          };

          for (let i = 0; i < listaTodosClientes?.length; i += 1) {
            const cliente = listaTodosClientes[i];
            // Primeira condição, para remover a associação entre cliente e este modelo.
            if (cliente?.modeloDanfe?.id === data.id && !esteModeloDanfeContemCliente(cliente)) {
              // Removeremos o modelo de danfe deste cliente.
              associateModeloDanfe(cliente.id, -1);
            }
            // Segunda condição, para adicionar a associação entre cliente e este modelo.
            if (!cliente?.modeloDanfe && esteModeloDanfeContemCliente(cliente)) {
              // Adicionaremos o modelo de danfe neste cliente.
              associateModeloDanfe(cliente?.id, data?.id);
            }
          }
          // TODO: Quando algum dos updates falha não temos como mostrar o erro.
          // Deve fazer mais sentido criar um endpoint que trate desta lógica.
          // Por isso, sempre mandamos o usuário para a tela da listagem. Mas não
          // é assim que deveria funcionar.
          dispatch(success());
          history.push(i18n.t('com.muralis.qcx.url.moduloOperacionaisModeloDANFE'));
          dispatch(
            setResponse({
              status: 200,
              data: 0,
              message: i18n.t('com.muralis.qcx.mensagem.modeloDANFECriado'),
            })
          );
        }, 500);
        break;
      default:
        break;
    }
    dispatch(loading());
    executeDebounced();
    if (isFunction(next)) {
      next();
    }
  };

  // Chamado pelo handleSubmit quando estamos editando um item que já existe.
  const updateModeloDanfe = async (data, step) => {
    let executeDebounced;
    switch (step) {
      case 0:
        executeDebounced = debounce(async () => {
          try {
            const response = await save(data);

            if (response?.status === 200 || response?.status === 201) {
              const handleResultWithDebounce = debounce(() => {
                handleChangeToConsult();
                dispatch(success());

                const saved = response?.data;

                dispatch(
                  setResponse({
                    status: response.status,
                    data: saved,
                    message: i18n.t('com.muralis.qcx.mensagem.modeloDANFESalvo', { modelo: saved?.code }),
                  })
                );

                dispatch(setModel(saved));
              }, 500);
              handleResultWithDebounce();
            }
          } catch ({ response }) {
            dispatch(failure());
            dispatch(
              setError({
                message: i18n.t('com.muralis.qcx.erro.erroSalvarModeloDANFEEspecifico', {
                  modelo: data?.code,
                  erro: response?.data?.message,
                }),
              })
            );
          }
        }, 500);
        break;
      case 1:
        executeDebounced = debounce(async () => {
          // Vide comentários em createModeloDanfe. Esta função é a mesma que
          // existe lá, exceto que não manda o usuário para a tela da listagem.
          handleChangeToConsult();

          // Função que checa se nosso form está com um determinado cliente
          // selecionado.
          const esteModeloDanfeContemCliente = (cliente) => {
            if (!Array.isArray(data.clientes)) {
              return false;
            }
            const idx = data.clientes.findIndex((cl) => cl.id === cliente?.id);
            return idx !== -1;
          };

          for (let i = 0; i < listaTodosClientes.length; i += 1) {
            const cliente = listaTodosClientes[i];
            // Primeira condição, para remover a associação entre cliente e este modelo.
            if (cliente.modeloDanfe?.id === data?.id && !esteModeloDanfeContemCliente(cliente)) {
              // Removeremos o modelo de danfe deste cliente.
              associateModeloDanfe(cliente.id, -1);
            }
            // Segunda condição, para adicionar a associação entre cliente e este modelo.
            if (esteModeloDanfeContemCliente(cliente)) {
              // Adicionaremos   o modelo de danfe neste cliente.
              associateModeloDanfe(cliente.id, data.id);
            }
          }

          dispatch(success());
          dispatch(
            setResponse({
              status: 200,
              data: 0,
              message: i18n.t('com.muralis.qcx.mensagem.alteracoesSalvas'),
            })
          );
        }, 500);
        break;
      default:
        break;
    }
    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = useCallback(
    async (data, step, next) => {
      const normalizedData = normalize(data);
      if (id) {
        await updateModeloDanfe(normalizedData, step, next);
      } else {
        await createModeloDanfe(normalizedData, step, next);
      }
    },
    [id, normalize]
  );

  const handleAlternativeSave = useCallback(
    async (event, step, next) => {
      event?.stopPropagation?.();

      const normalizedData = normalize(modeloDanfe);

      if (isUpdate && !isBackgroundCreate) {
        await updateModeloDanfe(normalizedData, step, next);
      } else {
        await createModeloDanfe(normalizedData, step, next);
      }
    },
    [isUpdate, normalize, modeloDanfe, isBackgroundCreate]
  );

  const refreshSelectedModel = useCallback(() => {
    if (!isEmpty(modeloDanfe) && modeloDanfe?.id) {
      fetchById(modeloDanfe.id);
    }
  }, [modeloDanfe, isUpdate, fetchById]);

  const model = useMemo(() => (!isCreate ? unnormalize(modeloDanfe) : {}), [isCreate, modeloDanfe, unnormalize]);

  const actionName = useMemo(() => {
    if (isCreate || isNone) return i18n.t('com.muralis.qcx.acoes.novo');
    if (isConsult) return i18n.t('com.muralis.qcx.acoes.visualizar');
    return i18n.t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult]);

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: i18n.t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: i18n.t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: i18n.t('com.muralis.qcx.url.moduloOperacionais'),
        name: i18n.t('com.muralis.qcx.operacionais'),
      },
    },
    {
      link: {
        to: i18n.t('com.muralis.qcx.url.moduloOperacionaisModeloDANFE'),
        name: i18n.t('com.muralis.qcx.modeloDANFE.label'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ];

  const pageTitle = useMemo(
    () =>
      isNone || isCreate
        ? i18n.t('com.muralis.qcx.modeloDANFE.label')
        : i18n.t('com.muralis.qcx.modeloDANFE.modeloDANFEEXistente', { ID: modeloDanfe.seqId }),
    // <- Só existe quando o modelo de DANFE já foi cadastrado.
    [isNone, isCreate, modeloDanfe.code]
  );

  const breadcrumbsProps = {
    maxItems: 6,
  };

  // Tem vários props que estão repetidos porque eu não sei direito como eles
  // funcionam. Certamente há como melhorar este código.
  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      breadcrumbsProps={breadcrumbsProps}
      isIdle={isIdle}
      isLoading={isLoading}
      isFailure={isFailure}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isPreparingAction={isPreparingAction}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      showSubtitle={false}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXModeloDanfeWizardFinalForm
          model={model}
          isUpdate={isUpdate}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          handleChangeToCreate={handleChangeToCreate}
          handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
          handleResetBackgroundMode={handleResetBackgroundMode}
          handleChangeToConsult={handleChangeToConsult}
          handleChangeToUpdate={handleChangeToUpdate}
          handleCancelUpdate={handleCancelUpdate}
          handleChangeToPreparingActions={handleChangeToPreparingAction}
          refreshSelectedModel={refreshSelectedModel}
          handleAlternativeSave={handleAlternativeSave}
          isConsult={isConsult}
          authInfo={authInfo}
          requiredRoles={['modelo-danfe', 'cliente']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
