import { Grid } from '@material-ui/core';
import { useEffect } from 'react';
import { useForm } from 'react-final-form';
import QCXRenderConditionalComponent from '../../../../../../../components/catalogo-produtos/QCXRenderConditionalComponent';
import { CatalogoProdutosPartnumberFiltroFormValues } from '../types/CatalogoProdutosPartnumberFiltroFormValues';

type CatalogoProdutosNcmAtributosFormProps = {
  ncm: CatalogoProdutosPartnumberFiltroFormValues['ncm'];
};

export default function CatalogoProdutosNcmAtributosForm({ ncm }: CatalogoProdutosNcmAtributosFormProps) {
  const form = useForm();

  useEffect(() => {
    form.change('atributosPreenchidos', {});
  }, [ncm]);

  return (
    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
      {ncm &&
        ncm.listaAtributosMetadados &&
        ncm.listaAtributosMetadados.map((atributo: any) => (
          <>
            <QCXRenderConditionalComponent atributo={atributo} disabled={false} isConsult={false} />
          </>
        ))}
    </Grid>
  );
}
