import React from 'react';

import { useTranslation } from 'react-i18next';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { AiOutlineWarning } from 'react-icons/ai';
import ErrorIcon from '@material-ui/icons/Error';
import {Typography, CircularProgress } from '@material-ui/core';

import ScheduleIcon from '@material-ui/icons/Schedule';


import QCXListItemIcon from '../../../../../shared-components/list-item-icon/QCXListItemIcon';

const StatusIcon = ({ statusTransmissao }) => {
    const { t } = useTranslation();

    const getIcon = () => {

        switch (statusTransmissao) {
            case 'TRANSMITIDO':
                return <AiOutlineWarning color="orange" size={20} />
            case 'REGISTRADO':
                return <AssignmentTurnedInIcon color="primary" fontSize="small" />
            case 'TRANSMITINDO':
                return <CircularProgress size={20} />
            case 'ERRO':
                return <ErrorIcon color="error" fontSize="small" />
              default:
                return <ScheduleIcon color="primary" fontSize="small" />
        }
    };
    
    return (
        <>
            <QCXListItemIcon>{getIcon()}</QCXListItemIcon>
            <Typography
                variant="inherit"
                style={{
                    fontSize: 12,
                }}
            >
                {statusTransmissao === undefined || statusTransmissao === 'ERRO'
                    ? t('com.muralis.qcx.transmissaoRegistro').toUpperCase()
                    : 'Resultado Transmissão' }
            </Typography>
        </>
    );
};

export default StatusIcon;