import { Grid } from '@material-ui/core';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';

export default function QCXFaturaIntegracaoFormGroup({ modes }) {

  const { isConsult, isSubConsult, isSubNone } = modes;

  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-totvsLi"
          key="text-field-totvsLi"
          name="totvsLi"
          label='TOTVS LI'
          disabled={isConsult || isSubConsult || isSubNone}
          maxLength={11}
        />
      </Grid>
    </>
  );
}
