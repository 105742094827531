import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { selectError, selectResponse, selectStatus } from '../../features/downloader/downloaderSelectors';
import { clearError, resetStatus } from '../../features/downloader/downloaderSlice';
import { isFailureStatus, isLoadingStatus, isSuccessStatus } from '../../utils/store/store-utils';
import QCXErrorSnackbar from '../../shared-components/snackbar/QCXErrorSnackbar';
import QCXCircularProgress from '../../shared-components/progress/QCXCircularProgress';

export default function QCXDownloader() {
  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const error = useSelector(selectError);
  const response = useSelector(selectResponse);

  const [errorSnackbar, setErrorSnackbar] = useState({
    open: false,
    message: '',
  });

  const loading = useMemo(() => isLoadingStatus(status), [status]);

  const handleOpenErrorSnackbar = useCallback((message) => {
    setErrorSnackbar({
      open: true,
      message,
    });
  }, []);

  const handleCloseErrorSnackbar = useCallback(() => {
    setErrorSnackbar({
      ...errorSnackbar,
      open: false,
    });
  }, []);

  useEffect(() => {
    const handleStatus = () => {
      if (isFailureStatus(status) && error && error.message) {
        handleOpenErrorSnackbar(error.message);
        dispatch(resetStatus());
        dispatch(clearError());

        return;
      }

      if (isSuccessStatus(status)) {
        dispatch(resetStatus());
      }
    };

    handleStatus();
  }, [response, status, error]);

  return (
    <>
      {loading && (
        <Snackbar
          open
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
        >
          <Alert severity="success" icon={<QCXCircularProgress size={20} />}>
            Preparando o download do(s) arquivo(s)...
          </Alert>
        </Snackbar>
      )}
      {errorSnackbar?.open && (
        <QCXErrorSnackbar
          open={errorSnackbar?.open}
          content={errorSnackbar?.message}
          handleClose={handleCloseErrorSnackbar}
          {...errorSnackbar}
        />
      )}
    </>
  );
}
