const selectNumerariosImportacao = (state) => state.numerarioImportacao.list;
const selectNumerariosImportacaoCount = (state) => state.numerarioImportacao.count;
const selectNumerarioImportacao = (state) => state.numerarioImportacao.model;
const selectRelatedEmailModel = (state) => (
  state.numerarioImportacao.related.model.email
);
const selectStatus = (state) => state.numerarioImportacao.status;
const selectRefresh = (state) => state.numerarioImportacao.refresh;
const selectMode = (state) => state.numerarioImportacao.mode.main;
const selectBackgroundMode = (state) => state.numerarioImportacao.mode.background;
const selectSubMode = (state) => state.numerarioImportacao.mode.sub;
const selectError = (state) => state.numerarioImportacao.error;
const selectResponse = (state) => state.numerarioImportacao.response;

const numerarioImportacaoSelectors = {
  selectNumerariosImportacao,
  selectNumerarioImportacao,
  selectRelatedEmailModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectError,
  selectResponse,
  selectNumerariosImportacaoCount,
};

export {
  numerarioImportacaoSelectors,
  selectNumerariosImportacao,
  selectNumerarioImportacao,
  selectRelatedEmailModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectError,
  selectResponse,
  selectNumerariosImportacaoCount,
};
