import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../../shared-components/dados-embarque/base-components/BaseSelectWithOptions';

function SelectTipoRateioTaxaSiscomex({ ...props }) {
  const list = useMemo(
    () => [
      {
        id: 'CIF',
        label: 'CIF',
        value: 'CIF',
      },
      {
        id: 'ADIÇÕES',
        label: 'ADIÇÕES',
        value: 'ADICOES',
      },
    ],
    []
  );

  return <BaseSelectWithOptions list={list} {...props} />;
}

export default SelectTipoRateioTaxaSiscomex;
