import { utils, writeFile } from 'xlsx';
import { RelatorioLaItem } from './relatorioLaPage.types';

const headers = [
  'numero_danfe',
  'processo',
  'cliente',
  'referencias',
  'nr_di',
  'data_di',
  'pedido',
  'produto',
  'descricao',
  'adicao',
  'item',
  'ncm',
  'fob',
  'frete',
  'seguro',
  'acrescimo',
  'deducao',
  'quantidade',
  'unidade',
  'cif_unitario',
  'cif_total',
  'ii',
  'aliquota_ii',
  'base_calculo_ipi',
  'aliquota_ipi',
  'ipi',
  'base_icms',
  'aliquota_icms',
  'icms',
  'base_calculo_pis_cofins',
  'aliquota_pis',
  'pis',
  'aliquota_cofins',
  'cofins',
  'antidumping',
  'taxa_siscomex',
  'peso_liquido',
  'peso_bruto',
  'nr_li',
  'taxa_vmle',
  'vmle_mneg',
  'taxa_frt',
  'frt_mneg',
  'valor_cif_dolar',
  'especie',
  'fabricante',
  'exportador',
  'protocolo',
  'marinha_mercante',
];

const formattedHeaders = [
  'Nota',
  'Processo',
  'Cliente',
  'Referências',
  'Nr.DI',
  'Data DI',
  'Pedido',
  'Produto',
  'Descricao',
  'Adicao',
  'Seq.Item DI',
  'NCM',
  'Fob',
  'Frete',
  'Seguro',
  'Acrescimo',
  'Deducao',
  'Qtde',
  'Unidade',
  'CIF Unitario',
  'CIF Total',
  'II Recolhido',
  '% II',
  'Base do IPI',
  '% IPI',
  'IPI Recolhido',
  'Base do ICMS',
  // 'Base Final do ICMS',
  '% ICMS',
  'ICMS',
  'Base Pis/Cofins',
  '% Pis',
  'PIS Recolhido',
  '% Cofins',
  'COFINS Recolhido',
  'AntiDumping',
  'Tx. Siscomex',
  'Peso Liquido',
  'Peso Bruto',
  'Nr.LI',
  'Taxa VMLE',
  'VMLE MNeg',
  'Taxa FRT',
  'FRT MNeg',
  'CIF US$',
  'Especie',
  'Fabricante',
  'Exportador',
  'PROTOCOLO',
  'Marinha Mercante',
];

export const generateSpreadsheet = (
  data: RelatorioLaItem[], 
  processo: string | undefined, 
  clientes: any[], 
  inicioVigencia: string | null, 
  fimVigencia: string | null
) => {
  const workbook = utils.book_new();
  const worksheet = utils.json_to_sheet(data, { header: headers });

  // Add the formatted headers
  utils.sheet_add_aoa(worksheet, [formattedHeaders], {
    origin: { c: 0, r: 0 },
  });

  // Set A column width
  worksheet['!cols'] = [{ wch: 20 }];
  worksheet['!cols'][1] = { wch: 20 };
  worksheet['!cols'][2] = { wch: 30 };

  // Set F column to wrap text
  worksheet['!cols'][5] = { wch: 30 };

  processo = processo?.replace('/', '-');

  if (clientes && clientes.length > 0) {
    utils.sheet_add_aoa(worksheet, [
      [`Clientes: ${clientes.map(c => c.value).join(', ')}`]
    ], { origin: -1 });
  }

  if (inicioVigencia && fimVigencia) {
    utils.sheet_add_aoa(worksheet, [
      [`Período: ${inicioVigencia} - ${fimVigencia}`]
    ], { origin: -1 });
  }


  utils.book_append_sheet(workbook, worksheet, `Relatório LA - ${processo}`);

  writeFile(workbook, `Relatório LA - ${processo}.xlsx`);
};


export {};
