import { CssBaseline } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import QCXShortcutFeedback from './components/shortcut-feedback/QCXShortcutFeedback';
import config from './config.json';
import {
  fetchUnidadesDeNegocioAsync,
  selectUnidadesDeNegocioAssociadas,
  selectUnidadeSelecionada,
  setUnidadeSelecionada,
} from './features/usuario-logado/usuarioLogadoSlice';
import ErrorPage from './pages/module/error/ErrorPage';
import HomePage from './pages/module/home/HomePage';
import LoadingKeycloak from './pages/module/loadingKeycloak';
import useComposedRoutes from './routes/useComposedRoutes';
import useRoutes from './routes/useRoutes';
import QCXThemeProvider from './shared-components/theme-provider/QCXThemeProvider';
import i18n from './i18n';
import { tsRoutes } from './ts/common/routes/tsRoutes.ts';
import WebSocketProvider from './shared-components/websocket/WebSocketProvider';

const queryClient = new QueryClient();

export const KEYCLOAK_TOKEN_TIMEOUT = 10;

export default function App() {
  // eslint-disable-next-line no-console
  console.error = () => {};
  // eslint-disable-next-line no-console
  console.warn = () => {};

  const routes = useMemo(() => useRoutes(), []);

  const composedRoutes = useMemo(() => useComposedRoutes(), []);
  const dispatch = useDispatch();

  const { initialized, keycloak } = useKeycloak();
  const unidadeDeNegocioSelecionada = useSelector(selectUnidadeSelecionada);
  const unidadesDeNegocioAssociadas = useSelector(selectUnidadesDeNegocioAssociadas);

  const authInfo = useMemo(
    () => ({
      accessModules: keycloak?.tokenParsed?.attributes?.modulosAcesso || [],
      roles: keycloak?.tokenParsed?.resource_access?.api?.roles || [],
      grupo: keycloak?.tokenParsed?.attributes?.grupo || [],
      unidadesDeNegocio: keycloak?.tokenParsed?.attributes?.unidadesDeNegocio || [],
      padrao: keycloak?.tokenParsed?.attributes?.padrao || [],
      locale: keycloak?.idTokenParsed?.locale || 'pt-BR',
    }),
    [keycloak?.tokenParsed]
  );

  useEffect(() => {
    i18n.changeLanguage(authInfo.locale);
  }, [authInfo.locale]);

  useEffect(() => {
    if (authInfo?.padrao?.[0] && !Number.isNaN(authInfo?.padrao?.[0])) {
      dispatch(setUnidadeSelecionada(Number(authInfo?.padrao[0])));
    }
    if (!unidadesDeNegocioAssociadas || !unidadesDeNegocioAssociadas?.length) {
      dispatch(fetchUnidadesDeNegocioAsync());
    }
  }, [authInfo]);

  useEffect(() => {
    if (unidadeDeNegocioSelecionada) {
      localStorage.setItem('tenant', unidadeDeNegocioSelecionada);
      if (!unidadesDeNegocioAssociadas || !unidadesDeNegocioAssociadas?.length) {
        dispatch(fetchUnidadesDeNegocioAsync());
      }
    }
  }, [unidadeDeNegocioSelecionada]);

  if (config.enableKeycloak) {
    if (!initialized) {
      return <LoadingKeycloak />;
    }

    if (!keycloak?.authenticated) {
      localStorage.clear();
      return keycloak?.login();
    }
  }

  const BASE_API_URL = process.env.REACT_APP_QCX_API_BASE_URL;
  const SOCKET_URL = `${BASE_API_URL}/notify`;

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <QCXThemeProvider>
          <WebSocketProvider webSocketUrl={SOCKET_URL}>
            <BrowserRouter>
              <Switch>
                <Route path="/" exact render={(props) => <HomePage {...props} authInfo={authInfo} />} />
              </Switch>
              <Switch>
                <Route
                  path={i18n.t('com.muralis.qcx.url.erro')}
                  exact
                  render={(props) => <ErrorPage {...props} authInfo={authInfo} />}
                />
              </Switch>

              {routes.map(({ path, component, access }, index) => (
                <Switch key={`switch-router-id-${index + 1}`}>
                  <MenuRoute
                    key={`route-id-${index + 1}`}
                    path={path}
                    componentProps={{ authInfo }}
                    component={component}
                    requiredAccess={access}
                    authInfo={authInfo}
                    exact
                  />
                </Switch>
              ))}
              {composedRoutes.map(({ container: Container, subcontainers, role }, index) => (
                <Container key={`container-page-${index + 1}`}>
                  <Switch key={`composed-switch-router-id-${index + 1}`}>
                    {subcontainers.map(({ component, path }, secondIndex) => (
                      <FeatureRoute
                        key={`composed-route-id-${secondIndex + 1}`}
                        component={component}
                        componentProps={{ authInfo }}
                        path={path}
                        requiredRole={role}
                        authInfo={authInfo}
                        keycloak={keycloak}
                        exact
                        unidadeDeNegocioSelecionada={unidadeDeNegocioSelecionada}
                      />
                    ))}
                  </Switch>
                </Container>
              ))}
              <Switch>
                {tsRoutes.map((route) => (
                  <Route exact path={route.path}>
                    <route.component authInfo={authInfo} />
                  </Route>
                ))}
              </Switch>
            </BrowserRouter>
          </WebSocketProvider>
          <QCXShortcutFeedback />
        </QCXThemeProvider>
      </QueryClientProvider>
    </>
  );
}

function FeatureRoute({
  component: Component,
  componentProps,
  requiredRole,
  authInfo,
  keycloak,
  unidadeDeNegocioSelecionada,
  ...rest
}) {
  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

  if (requiredRole && authInfo?.roles?.includes(requiredRole) && unidadeDeNegocioSelecionada) {
    return <Route {...rest} render={(props) => <Component {...props} {...componentProps} />} />;
  }
  if (!unidadeDeNegocioSelecionada) {
    return <Redirect to={i18n.t('com.muralis.qcx.url.erroUnidadeNegocioNaoSelecionada')} />;
  }
  return <Redirect to={i18n.t('com.muralis.qcx.url.erro403')} />;
}

function MenuRoute({ component: Component, componentProps, requiredAccess, authInfo = {}, ...rest }) {
  if (requiredAccess && authInfo?.accessModules.includes(requiredAccess)) {
    return <Route {...rest} render={(props) => <Component {...props} {...componentProps} />} />;
  }

  return <Redirect to={i18n.t('com.muralis.qcx.url.erro403')} />;
}

// function NotFoundRoute({ component: Component, authed, ...rest }) {
//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         authed === true ? (
//           <Redirect to={{ pathname: "/", state: { from: props.location } }} />
//         ) : (
//           <Redirect to={{ pathname: "/", state: { from: props.location } }} />
//         )
//       }
//     />
//   );
// }
