import { Container } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { validate } from 'uuid';
import QCXClienteWizardFinalForm from '../../../../../components/cliente/QCXClienteWizardFinalForm';
import QCXMainLayout from '../../../../../components/main-layout/QCXMainLayout';
import { register, save } from '../../../../../features/cliente/clienteAPI';
import {
  addToList,
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  resetBackgroundMode,
  resetModel,
  resetRelatedContatoModel,
  resetSubMode,
  selectBackgroundMode,
  selectCliente,
  selectMode,
  setModel,
  success,
  updateOnList,
} from '../../../../../features/cliente/clienteSlice';
import { useUpdateKeycloakProfilePermissions } from '../../../../../ts/common/hooks/useUpdateKeycloakProfilePermissions.ts';
import { MediaType } from '../../../../../utils/api/api-utils';
import {
  normalizeOptanteSimplesNacional,
  normalizeReter,
  unnormalizeOptanteSimplesNacional,
  unnormalizeReter,
} from '../../../../../utils/general/cliente/clienteUtils';
import {
  forceUnnormalizeNumeral,
  isValid,
  normalizeData,
  normalizeDigits,
  normalizeNumeral,
  unnormalizeData,
  unnormalizeNumeral,
} from '../../../../../utils/general/general-utils';
import TipoCalculoSeguroUtils from '../../../../../utils/general/tipo-calculo-seguro/TipoCalculoSeguroUtils';
import {
  formatBrazilianNumericDecimal,
  formatCep,
  formatCnpj,
  formatCpf,
  formatTelefoneCelular,
  formatTelefoneFixo,
} from '../../../../../utils/hooks/form/field/formatters';
import { numberOnly } from '../../../../../utils/hooks/form/field/parsers';
import {
  isBackgroundCreateMode,
  isConsultMode,
  isCreateMode,
  isUpdateMode,
} from '../../../../../utils/store/store-utils';
import { setErrorFeedback, setSuccessFeedback } from '../../../../../features/feedback/feedbackSlice';

export default function ClienteRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const history = useHistory();

  const { keycloak } = useKeycloak();

  const [profileMutation] = useUpdateKeycloakProfilePermissions();

  const dispatch = useDispatch();

  const [isCreated, setIsCreated] = useState(false);

  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);

  const cliente = useSelector(selectCliente);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
    dispatch(resetRelatedContatoModel());
    dispatch(resetSubMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  const handleCancelUpdate = () => {
    const currentId = isBackgroundCreate ? cliente?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
    handleChangeToConsult();
  };

  const handleChangeToBackgroundCreate = () => {
    dispatch(changeToBackgroundCreateMode());
  };

  const handleResetBackgroundMode = () => {
    dispatch(resetBackgroundMode());
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const isPessoaFisica = (tipoPessoa) =>
    tipoPessoa === 'PESSOA_FISICA' || tipoPessoa === 'FISICAS_DOMICILIADAS_NO_EXTERIOR';

  const normalize = useCallback(
    (unnormalizedData) => {
      const isBrasil = unnormalizedData?.endereco?.cidade?.estado?.pais?.id === 1;

      const cnpj = isBrasil ? numberOnly(unnormalizedData?.pessoa?.cnpj) : unnormalizedData?.pessoa?.cnpj;
      const cpf = isBrasil ? numberOnly(unnormalizedData?.pessoa?.cpf) : unnormalizedData?.pessoa?.cpf;

      const normalizedPessoaData = {
        ...unnormalizedData?.pessoa,
        tipo: isPessoaFisica(unnormalizedData?.tipoPessoa) ? 'PessoaFisica' : 'PessoaJuridica',
        cnae: unnormalizedData?.pessoa?.cnae?.id ? unnormalizedData?.pessoa?.cnae : null,
        cpf: unnormalizedData?.pessoa?.cpf ? cpf : null,
        cnpj: unnormalizedData?.pessoa?.cnpj ? cnpj : null,
      };

      const vencimentoRadar = normalizeData(unnormalizedData?.vencimentoRadar);
      const vencimentoProcuracao = normalizeData(unnormalizedData?.vencimentoProcuracao);
      const normalizedOptanteSimplesNacional = normalizeOptanteSimplesNacional(
        unnormalizedData?.optanteSimplesNacional
      );

      let normalizedClienteServicoDespesaReceitas = [];

      if (unnormalizedData?.clienteServicoDespesaReceitaByServicoId?.NUMERARIO) {
        Object.keys(unnormalizedData?.clienteServicoDespesaReceitaByServicoId?.NUMERARIO).forEach((key) => {
          const clienteServicoDespesaReceitaRelacao =
            unnormalizedData.clienteServicoDespesaReceitaByServicoId?.NUMERARIO[key];

          if (clienteServicoDespesaReceitaRelacao)
            normalizedClienteServicoDespesaReceitas = normalizedClienteServicoDespesaReceitas.concat(
              clienteServicoDespesaReceitaRelacao
            );
        });
      }

      if (unnormalizedData?.clienteServicoDespesaReceitaByServicoId?.FATURAMENTO) {
        Object.keys(unnormalizedData?.clienteServicoDespesaReceitaByServicoId?.FATURAMENTO).forEach((key) => {
          const clienteServicoDespesaReceitaRelacao =
            unnormalizedData.clienteServicoDespesaReceitaByServicoId?.FATURAMENTO[key];

          if (clienteServicoDespesaReceitaRelacao)
            normalizedClienteServicoDespesaReceitas = normalizedClienteServicoDespesaReceitas.concat(
              clienteServicoDespesaReceitaRelacao
            );
        });
      }

      if (
        !unnormalizedData?.clienteServicoDespesaReceitaByServicoId?.FATURAMENTO &&
        !unnormalizedData?.clienteServicoDespesaReceitaByServicoId?.NUMERARIO
      ) {
        normalizedClienteServicoDespesaReceitas = unnormalizedData?.relacaoClienteServicoDespesaReceitas ?? [];
      }

      normalizedClienteServicoDespesaReceitas = normalizedClienteServicoDespesaReceitas.map((c) => ({
        ...c,
        id: null,
      }));

      let normalizedClienteServicoTabelasSda = [];

      if (unnormalizedData?.clienteServicoTabelaSdaByServicoId) {
        Object.keys(unnormalizedData?.clienteServicoTabelaSdaByServicoId).forEach((key) => {
          const clienteServicoTabelaSdaRelacao = unnormalizedData.clienteServicoTabelaSdaByServicoId?.[key]?.map(
            (r) => ({ ...r, id: null })
          );

          if (clienteServicoTabelaSdaRelacao)
            normalizedClienteServicoTabelasSda =
              normalizedClienteServicoTabelasSda.concat(clienteServicoTabelaSdaRelacao);
        });
      } else {
        normalizedClienteServicoTabelasSda = unnormalizedData?.relacaoClienteServicoTabelasSda ?? [];
      }

      const normalizedData = {
        ...unnormalizedData,
        relacaoClienteServicoDespesaReceitas: normalizedClienteServicoDespesaReceitas,
        relacaoClienteServicoTabelasSda: normalizedClienteServicoTabelasSda,
        endereco: {
          ...unnormalizedData?.endereco,
          cep: unnormalizedData?.endereco?.cep ? normalizeDigits(unnormalizedData?.endereco?.cep) : null,
        },
        pessoa: normalizedPessoaData,
        contatos: (unnormalizedData?.contatos || []).map((item) => ({
          ...item,
          id: validate(item?.id) ? null : item?.id,
          contato: {
            ...item?.contato,
            telefone: item?.contato?.telefone ? normalizeDigits(item?.contato?.telefone) : undefined,
            celular: item?.contato?.celular ? normalizeDigits(item?.contato?.celular) : undefined,
          },
        })),
        contaBancariaImpostosEstaduais: unnormalizedData?.contaBancariaImpostosEstaduais?.id
          ? unnormalizedData?.contaBancariaImpostosEstaduais
          : undefined,
        contaBancariaImpostosFederais: unnormalizedData?.contaBancariaImpostosFederais?.id
          ? unnormalizedData?.contaBancariaImpostosFederais
          : undefined,
        contaBancariaPagamentoDespesas: unnormalizedData?.contaBancariaPagamentoDespesas?.id
          ? unnormalizedData?.contaBancariaPagamentoDespesas
          : undefined,
        vencimentoRadar,
        vencimentoProcuracao,
        description: unnormalizedData?.pessoa.nome,
        despachantes: unnormalizedData?.despachantes?.length ? unnormalizedData?.despachantes : null,
        unidadesDeNegocio: unnormalizedData?.unidadesDeNegocio?.length ? unnormalizedData?.unidadesDeNegocio : null,
        servicos: unnormalizedData?.servicos?.length ? unnormalizedData?.servicos : null,
        planoDeConta: unnormalizedData?.planoDeConta?.id ? unnormalizedData?.planoDeConta : null,
        moeda: unnormalizedData?.moeda?.id ? unnormalizedData?.moeda : null,
        tipoCalculoSeguro: TipoCalculoSeguroUtils.parseFromSingletonList(unnormalizedData?.tipoCalculoSeguro),
        valorPercentualSeguro: normalizeNumeral(unnormalizedData?.valorPercentualSeguro),
        tipoEmpresa: { ...unnormalizedData?.tipoEmpresa },
        limiteCredito: normalizeNumeral(unnormalizedData?.limiteCredito),
        optanteSimplesNacional: normalizedOptanteSimplesNacional,
        certificadoDigitalDanfeSenhaConfirma: undefined, // Campo de confirmação somente, não enviamos
        certificadoDigitalDanfeSenha: unnormalizedData?.certificadoDigitalDanfeSenha || undefined,
        valorMinimo: normalizeNumeral(unnormalizedData?.valorMinimo),
        aliquotaPis: normalizeNumeral(unnormalizedData?.aliquotaPis),
        aliquotaCofins: normalizeNumeral(unnormalizedData?.aliquotaPis),
        aliquotaCsll: normalizeNumeral(unnormalizedData?.aliquotaPis),
        percentualSpread: normalizeNumeral(unnormalizedData?.percentualSpread),
        indicadorInscricaoEstadual: unnormalizedData?.indicadorInscricaoEstadual,
        diasParaVencimento: normalizeNumeral(unnormalizedData?.diasParaVencimento),
        diaLimiteParaFaturamento: normalizeNumeral(unnormalizedData?.diaLimiteParaFaturamento),
        tipoRetencao: unnormalizedData?.tipoRetencao,
        reter: normalizeReter(unnormalizedData?.reter),
      };

      return normalizedData;
    },
    [normalizeNumeral]
  );

  const unnormalize = useCallback(
    (normalizedData) => {
      const unnormalizedData = {
        ...normalizedData,
        endereco: {
          ...normalizedData?.endereco,
          cep: normalizedData?.endereco?.cep ? formatCep(normalizedData?.endereco?.cep) : '',
        },
        pessoa: {
          ...normalizedData?.pessoa,
          cpf: normalizedData?.pessoa?.cpf ? formatCpf(normalizedData?.pessoa?.cpf) : '',
          cnpj: normalizedData?.pessoa?.cnpj ? formatCnpj(normalizedData?.pessoa?.cnpj) : '',
        },
        contatos: (normalizedData?.contatos || []).map((item) => ({
          ...item,
          contato: {
            ...item?.contato,
            telefone: item?.contato?.telefone ? formatTelefoneFixo(item?.contato?.telefone) : '',
            celular: item?.contato?.celular ? formatTelefoneCelular(item?.contato?.celular) : '',
          },
        })),
        vencimentoRadar: unnormalizeData(normalizedData?.vencimentoRadar),
        vencimentoProcuracao: unnormalizeData(normalizedData?.vencimentoProcuracao),
        tipoCalculoSeguro: TipoCalculoSeguroUtils.parseToSingletonList(normalizedData?.tipoCalculoSeguro),
        valorPercentualSeguro: forceUnnormalizeNumeral(
          normalizedData?.valorPercentualSeguro,
          formatBrazilianNumericDecimal(7)
        ),
        limiteCredito: isValid(normalizedData?.limiteCredito)
          ? unnormalizeNumeral(normalizedData?.limiteCredito, formatBrazilianNumericDecimal(2))
          : undefined,
        certificadoDigital: normalizedData?.certificadoDigitalDanfe,
        // Preenchemos o campo de confirmação de senha com a senha, senão daria erro de validação.
        certificadoDigitalDanfeSenhaConfirma: normalizedData.certificadoDigitalDanfeSenha,
        optanteSimplesNacional: unnormalizeOptanteSimplesNacional(normalizedData?.optanteSimplesNacional),
        valorMinimo: isValid(normalizedData?.valorMinimo)
          ? unnormalizeNumeral(normalizedData?.valorMinimo, formatBrazilianNumericDecimal(2))
          : undefined,
        aliquotaPis: forceUnnormalizeNumeral(normalizedData?.aliquotaPis, formatBrazilianNumericDecimal(7)),
        aliquotaCofins: forceUnnormalizeNumeral(normalizedData?.aliquotaCofins, formatBrazilianNumericDecimal(7)),
        aliquotaCsll: forceUnnormalizeNumeral(normalizedData?.aliquotaCsll, formatBrazilianNumericDecimal(7)),
        percentualSpread: forceUnnormalizeNumeral(normalizedData?.percentualSpread, formatBrazilianNumericDecimal(2)),
        indicadorInscricaoEstadua: unnormalizeData(normalizedData?.indicadorInscricaoEstadual),
        diasParaVencimento: normalizedData?.diasParaVencimento
          ? unnormalizeNumeral(normalizedData?.diasParaVencimento)
          : null,
        diaLimiteParaFaturamento: normalizedData?.diaLimiteParaFaturamento
          ? unnormalizeNumeral(normalizedData?.diaLimiteParaFaturamento)
          : null,
        tipoRetencao: normalizedData?.tipoRetencao,
        reter: unnormalizeReter(normalizedData?.reter),
      };

      return unnormalizedData;
    },
    [unnormalizeData, unnormalizeNumeral]
  );

  /**
   * Função usada somente nas requests PUT, que podem conter arquivos de
   * certificado digital.
   */
  const parseToFormData = useCallback((data) => {
    const { certificadoDigital, ...restData } = data;

    const jsonData = {
      ...restData,
      ...(certificadoDigital?.id
        ? {
            certificadoDigital,
          }
        : {}),
    };

    const json = JSON.stringify(jsonData);
    const blob = new Blob([json], {
      type: MediaType.APPLICATION_JSON,
    });

    const file = certificadoDigital?.length > 0 ? certificadoDigital[0] : undefined;

    const formData = new FormData();

    formData.append('data', blob);

    if (file) {
      formData.append('file', file);
    }

    return formData;
  }, []);

  const handleDispatchSetModel = useCallback(
    (data) => {
      dispatch(setModel(normalize(data)));
    },
    [normalize]
  );

  const createByStep = async (data, step, next) => {
    const isFirstStep = step === 0;
    const isLastStep = step === 6;

    const executeDebounced = debounce(async () => {
      try {
        if (isFirstStep && !isCreated) {
          const response = await register(data, [
            {
              name: 'step',
              value: step + 1,
            },
          ]);

          if (response?.status === 201) {
            // Atualiza o role do keycloak para incluir o novo cliente.
            const roleName = keycloak.tokenParsed.assignedRole;
            const clienteId = response.data.id;
            const keycloakPayload = {
              nomePerfil: roleName,
              clienteId,
            };
            profileMutation(keycloakPayload);
            next();
            dispatch(resetModel());
            dispatch(success());

            const created = response?.data;

            handleDispatchSetModel(created);
            dispatch(addToList({ data: created }));
            dispatch(fetchByIdAsync(created?.id));

            setIsCreated(true);
          }
        } else if (isLastStep) {
          const formData = parseToFormData(data);

          const response = await save(formData, [
            {
              name: 'step',
              value: step + 1,
            },
          ]);

          if (response?.status === 200) {
            dispatch(resetModel());

            const handleResultWithDebounce = debounce(() => {
              history.push(t('com.muralis.qcx.url.moduloOperacionaisClientes'));

              dispatch(success());

              const saved = response?.data;

              dispatch(
                setSuccessFeedback({
                  message: t('com.muralis.qcx.mensagem.clienteCadastrado', { cliente: saved?.description }),
                })
              );
            }, 500);

            handleResultWithDebounce();
          }
        } else {
          const formData = parseToFormData(data);

          const response = await save(formData, [
            {
              name: 'step',
              value: step + 1,
            },
          ]);

          if (response?.status === 200) {
            if (isCreate) {
              next();
            } else {
              handleChangeToConsult();
            }
            const saved = response?.data;

            dispatch(success());
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }
        }
      } catch (error) {
        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: t('com.muralis.qcx.erro.erroSalvarDadosClienteMensagem', { erro: error?.response?.data?.message }),
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data, step) => {
    const executeDebounced = debounce(async () => {
      try {
        const formData = parseToFormData(data);

        const response = await save(formData, [
          {
            name: 'step',
            value: step + 1,
          },
        ]);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(
              setSuccessFeedback({
                message: t('com.muralis.qcx.mensagem.clienteSalvo', { cliente: saved?.description }),
              })
            );

            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: t('com.muralis.qcx.erro.erroSalvarDadosClienteEspecifico', {
              cliente: data?.description,
              erro: response?.data?.message,
            }),
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data, step, next) => {
    const normalizedData = normalize(data);

    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData, step);
      handleChangeToConsult();
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const handleAlternativeSave = async (event, step, next) => {
    if (event) {
      event.stopPropagation();
    }

    const normalizedData = normalize(cliente);

    if (isUpdate) {
      await update(normalizedData);
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const model = useMemo(
    () =>
      isCreated || !isCreate
        ? unnormalize(cliente)
        : {
            tipoPessoa: 'PESSOA_JURIDICA',
          },
    [isCreate, isCreated, cliente, unnormalize]
  );

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionaisClientes'),
        name: t('com.muralis.qcx.cliente.labelPlural'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ];

  const pageTitle = useMemo(
    () =>
      isCreate || isBackgroundCreate
        ? t('com.muralis.qcx.cliente.novoCliente')
        : t('com.muralis.qcx.cliente.clienteExistente', { ID: cliente?.description || cliente?.code }),
    [isUpdate, isConsult, isBackgroundCreate, cliente]
  );

  return (
    <QCXMainLayout pageTitle={pageTitle} breadcrumbs={breadcrumbs} authInfo={authInfo}>
      <Container style={{ display: 'flex', padding: '0 1rem 3rem 1rem' }}>
        <QCXClienteWizardFinalForm
          isCreate={isCreate}
          isConsult={isConsult}
          isUpdate={isUpdate}
          isBackgroundCreate={isBackgroundCreate}
          model={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          handleChangeToCreate={handleChangeToCreate}
          handleChangeToConsult={handleChangeToConsult}
          handleAlternativeSave={handleAlternativeSave}
          handleChangeToUpdate={handleChangeToUpdate}
          handleCancelUpdate={handleCancelUpdate}
          handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
          handleResetBackgroundMode={handleResetBackgroundMode}
          authInfo={authInfo}
          requiredRoles={['cliente']}
        />
      </Container>
    </QCXMainLayout>
  );
}
