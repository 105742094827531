import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import QCXFinalFormWizard from '../../../../../../shared-components/final-form-wizard/QCXFinalFormWizard';
import { isValid } from '../../../../../../utils/general/general-utils';
import { parseToNumber } from '../../../../../../utils/general/parse-utils';
import useQueryParam from '../../../../../../utils/hooks/router/useQueryParam';
import {
  isNoneSubMode,
  isSubConsultMode,
  isSubCreateMode,
  isSubUpdateMode,
} from '../../../../../../utils/store/store-utils';
import { selectSubMode } from '../../api/custeiosSelectors';
import {
  changeToSubBackgroundConsultMode,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  loading,
  resetStatus,
  resetSubBackgroundMode,
  resetSubMode,
} from '../../api/custeiosSlice';
import ItensStepForm from './steps/ItensStepForm';
import CusteiosBasicasStepForm from './steps/CusteiosBasicasStepForm';
import { omit } from 'lodash';

export default function QCXCusteiosWizardFinalForm({
  isIdle,
  isLoading,
  isFailure,
  isCreate,
  isConsult,
  isUpdate,
  isLocked,
  isBackgroundCreate,
  isBackgroundDelete,
  isSubBackgroundCreate,
  isSubBackgroundConsult,
  isSubBackgroundUpdate,
  isSubBackgroundDelete,
  isPreparingAction,
  handleSubmit,
  handleCustomSaveBy,
  handleAlternativeSave,
  handleAdicao,
  handleChangeToPreparingAction,
  model,
  handleChangeModel,
  refreshSelectedModel,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleChangeToBackgroundDelete,
  handleResetBackgroundMode,
  authInfo = {},
  requiredRoles = [],
  children,
  ...restProps
}: any) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const stepQueryParam = useQueryParam('step');
  const subMode = useSelector(selectSubMode);

  const stepNumberQueryParam = useMemo(
    () => (isValid(stepQueryParam) ? parseToNumber(stepQueryParam) - 1 : undefined),
    [stepQueryParam]
  );

  const isSubNone = useMemo(() => isNoneSubMode(subMode), [subMode]);

  const isSubCreate = useMemo(() => isSubCreateMode(subMode), [subMode]);

  const isSubConsult = useMemo(() => isSubConsultMode(subMode), [subMode]);

  const isSubUpdate = useMemo(() => isSubUpdateMode(subMode), [subMode]);

  const handleChangeToSubCreate = useCallback(() => {
    dispatch(changeToSubCreateMode());
  }, []);

  const handleChangeToSubConsult = useCallback(() => {
    dispatch(changeToSubConsultMode());
  }, []);

  const handleChangeToSubUpdate = useCallback(() => {
    dispatch(changeToSubUpdateMode());
  }, []);

  const handleChangeToSubNone = useCallback(() => {
    if (isSubBackgroundCreate) {
      handleChangeToSubCreate();
      dispatch(resetSubBackgroundMode());

      return;
    }

    if (isSubBackgroundConsult) {
      handleChangeToSubConsult();
      dispatch(resetSubBackgroundMode());

      return;
    }
    if (isSubBackgroundUpdate) {
      handleChangeToSubUpdate();
      dispatch(changeToSubBackgroundConsultMode());

      return;
    }

    dispatch(resetSubMode());
  }, [
    isSubBackgroundCreate,
    isSubBackgroundConsult,
    isSubBackgroundUpdate,
    handleChangeToSubCreate,
    handleChangeToSubConsult,
    handleChangeToSubUpdate,
  ]);

  const handleLoadingStatus = useCallback(() => dispatch(loading()), []);

  const handleResetStatus = useCallback(() => dispatch(resetStatus()), []);

  const wizardSteps = useMemo(() => [t('com.muralis.qcx.basicas'), t('com.muralis.qcx.item.labelPlural')], []);

  const buttonGroupOptions = useMemo(
    () => ({
      remove: {
        visibleOn: [false],
      },
    }),
    []
  );

  return (
    <>
      <QCXFinalFormWizard
        defaultStep={stepNumberQueryParam}
        handleAlternativeSave={handleAlternativeSave}
        handleCancelUpdate={handleCancelUpdate}
        handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
        handleChangeToConsult={handleChangeToConsult}
        handleChangeToCreate={handleChangeToCreate}
        handleChangeToSubNone={handleChangeToSubNone}
        handleChangeToUpdate={handleChangeToUpdate}
        handleChangeValues={handleChangeModel}
        handleMetadataUpdate={undefined}
        handleResetBackgroundMode={handleResetBackgroundMode}
        handleSubmit={handleSubmit}
        initialValues={model}
        isBackgroundCreate={isBackgroundCreate}
        isConsult={isConsult}
        isCreate={isCreate}
        isSubConsult={isSubConsult}
        isSubCreate={isSubCreate}
        isSubNone={isSubNone}
        isSubUpdate={isSubUpdate}
        isUpdate={isUpdate}
        steps={wizardSteps}
        {...restProps}
      >
        {(formProps: any) => <CusteiosBasicasStepForm key="dados-embarque-form" {...formProps} />}
        {({ controlComponentProps, ...restFormProps }: { controlComponentProps: any }) => (
          <ItensStepForm
            authInfo={authInfo}
            buttonGroupOptions={buttonGroupOptions}
            controlComponentProps={controlComponentProps}
            handleCancelUpdate={handleCancelUpdate}
            handleChangeToBackgroundDelete={handleChangeToBackgroundDelete}
            handleChangeToConsult={handleChangeToConsult}
            handleChangeToSubConsult={handleChangeToSubConsult}
            handleChangeToSubCreate={handleChangeToSubCreate}
            handleChangeToSubNone={handleChangeToSubNone}
            handleChangeToSubUpdate={handleChangeToSubUpdate}
            handleChangeToUpdate={handleChangeToUpdate}
            handleCustomSaveBy={handleCustomSaveBy}
            handleLoadingStatus={handleLoadingStatus}
            handleResetStatus={handleResetStatus}
            isBackgroundDelete={isBackgroundDelete}
            isConsult={isConsult}
            isCreate={isCreate}
            isFailure={isFailure}
            isIdle={isIdle}
            isLoading={isLoading}
            isLocked={isLocked}
            isPreparingAction={isPreparingAction}
            isSubConsult={isSubConsult}
            isSubCreate={isSubCreate}
            isSubNone={isSubNone}
            isSubUpdate={isSubUpdate}
            isUpdate={isUpdate}
            key="dados-container-form"
            parentModel={model}
            requiredRoles={requiredRoles}
            {...restFormProps}
          />
        )}
      </QCXFinalFormWizard>
      {children}
    </>
  );
}
