import { validateQueryParams } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import { FILTER_ENDPOINT, NVE_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

export async function fetchAll() {
  return doGetQCXRequest([NVE_ENDPOINT]);
}

export async function fetchByFilterList(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([NVE_ENDPOINT, 'list', FILTER_ENDPOINT], validatedParams);
  }
  return { data: [] };
}

export async function fetchByFilter(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([NVE_ENDPOINT, FILTER_ENDPOINT], validatedParams);
  }
  return { data: [] };
}

export async function fetchById(id) {
  return doGetQCXRequest([NVE_ENDPOINT, id]);
}

export async function register(data) {
  return doPostQCXRequest([NVE_ENDPOINT], data);
}

export async function save(data) {
  return doPutQCXRequest([NVE_ENDPOINT], data);
}

export async function activateById(id) {
  return doPutQCXActivateRequest([NVE_ENDPOINT], id);
}

export async function inactivateById(id) {
  return doPutQCXInactivateRequest([NVE_ENDPOINT], id);
}
