import { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import { AUTO_LOAD_CONTROL } from '../../features/config-control';
import { moedaActions } from '../../features/moeda/moedaSlice'; 
import { required as requiredValidator } from '../../utils/validators/field/validator';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXSelectMoedaAutocomplete from '../../shared-components/final-select-moeda-field/QCXSelectMoedaAutocomplete';


export default function QCXTaxaConversaoCambioForm({
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  ...restProps
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(moedaActions.changeControlTo(AUTO_LOAD_CONTROL)); 
  }, []);

  return (
    <QCXFinalForm
      isConsult={isConsult}
      isUpdate={isUpdate}
      initialValues={initialValues}
      handleSubmitValues={handleSubmit}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      {...restProps}
    >
      {() => (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <QCXSelectMoedaAutocomplete 
                id="moeda.id" 
                key="moeda.id" 
                name="moeda.id" 
                label='Moeda' 
                fieldProps={{ validate: requiredValidator }} 
                initialValues={initialValues}
                disabled={isConsult}
                required
              />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
              <QCXFinalDatePickerField
                id="date-picker-inicio"
                key="date-picker-inicio"
                name="vigencia.inicio"
                label="Início da vigência"
                required
                validate={requiredValidator}
                format="DD/MM/YYYY"
                placeholder="DD/MM/YYYY"
                disabled={isConsult}
                disablePast={false}
                disableFuture={false}
                minDate={undefined}
                initialValues={initialValues}
              />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
              <QCXFinalDatePickerField
                id="date-picker-fim"
                key="date-picker-fim"
                name="vigencia.fim"
                label="Fim da vigência"
                required
                validate={requiredValidator}
                format="DD/MM/YYYY"
                placeholder="DD/MM/YYYY"
                disabled={isConsult}
                disablePast={false}
                disableFuture={false}
                minDate={undefined}
                initialValues={initialValues}
              />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <QCXFinalTextField
              name="taxaConversao"
              label="Taxa de Conversão da Moeda"
              disabled={isConsult}
              required
            />
          </Grid>
        </>
      )}
    </QCXFinalForm>
  );
}
