import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Field, FieldRenderProps, useForm, useFormState } from 'react-final-form';
import { Box, IconButton, Badge } from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles((theme) => ({
  root: {
    '& input': {
      maxWidth: '20%',
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    display: 'flex',
    flex: 1,
  },
  inputAdornment: {
    maxWidth: '80%',
  },
  inputEndAdornment: {
    marginRight: 12,
    marginLeft: 'auto',
  },
  clearButton: {
    position: 'relative',
    '&:hover $clearIcon': {
      visibility: 'visible',
    },
    '&:hover $badge': {
      visibility: 'hidden',
    },
  },
  clearIcon: {
    visibility: 'hidden',
  },
  badge: {
    position: 'absolute',
  },
}));

interface Props {
  name: string;
  label: string;
  separateByEnterKey?: boolean;
}

const QCXFinalMultilineInputWithChips: React.FC<Props> = ({ name, label, separateByEnterKey = false }) => {
  const classes = useStyles();

  const form = useForm();
  const { values } = useFormState();

  const chips = values[name] ?? [];

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!separateByEnterKey) return;

    if (event.key === 'Enter') {
      event.preventDefault();
      const value = (event.target as HTMLInputElement | undefined)?.value?.trim();
      if (value) {
        const oldValues = values[name] ?? [];
        form.change(name, [...oldValues, value]);
        form.change(`${name}InputValue`, '');
      }
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement>,
    inputOnBlur: (inputEvent: React.FocusEvent<HTMLInputElement>) => void,
    value: string
  ) => {
    if (!value) {
      return;
    }

    inputOnBlur(event); // calling final form's onBlur

    // use regex to separe words by comma or space
    const regex = /[, ]+/;
    const newValues = separateByEnterKey ? [value.trim()] : value.trim().split(regex);

    if (newValues.length > 0) {
      const oldValues = values[name] ?? [];
      form.change(name, [...oldValues, ...newValues]);
    }

    form.change(`${name}InputValue`, '');
  };

  const handleDelete = (chipToDelete: string) => () => {
    const newChips = (values[name] ?? [])?.filter((chip: string) => chip !== chipToDelete);
    form.change(name, newChips);
  };

  const removeAllChips = () => form.change(name, []);

  return (
    <>
      <Field
        name={`${name}InputValue`}
        render={({ input, meta }: FieldRenderProps<string, HTMLInputElement>) => (
          <>
            <TextField
              {...(input as Partial<TextFieldProps>)}
              className={classes.root}
              label={label.toUpperCase()}
              variant="outlined"
              fullWidth
              error={Boolean(meta.touched && meta.error)}
              helperText={meta.touched ? meta.error : ''}
              onBlur={(event) => handleBlur(event as React.FocusEvent<HTMLInputElement>, input.onBlur, input.value)}
              onKeyDown={handleKeyDown}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={classes.inputAdornment}>
                    <Box width="100%" height={32} overflow="hidden" display="flex" flexDirection="row" flexWrap="wrap">
                      {chips.map((chip: string) => (
                        <Chip
                          key={chip}
                          label={chip}
                          className={classes.chip}
                          onDelete={handleDelete(chip)}
                          size="small"
                        />
                      ))}
                    </Box>
                  </InputAdornment>
                ),
                endAdornment:
                  chips.length === 0 ? null : (
                    <InputAdornment position="end" className={classes.inputEndAdornment}>
                      <IconButton
                        aria-label="clear input"
                        onClick={removeAllChips}
                        edge="end"
                        size="small"
                        className={classes.clearButton}
                      >
                        <ClearIcon className={classes.clearIcon} />
                        <Badge className={classes.badge} badgeContent={chips.length} color="primary" />
                      </IconButton>
                    </InputAdornment>
                  ),
              }}
            />
          </>
        )}
      />
    </>
  );
};

export default QCXFinalMultilineInputWithChips;
