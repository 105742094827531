import { Grid } from '@material-ui/core';
// eslint-disable-next-line import/extensions
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { OnChange } from 'react-final-form-listeners';
import QCXSelectEmbarcacaoAutocomplete from '../../../../../../shared-components/select-embarcacao/QCXSelectEmbarcacaoAutocomplete';
import QCXSelectPaisAutocomplete from '../../../../../../shared-components/select-pais/QCXSelectPaisAutocomplete';
import QCXSelectTransportadorInternacionalAutocomplete from '../../../../../../shared-components/select-transportador-internacional/QCXSelectTransportadorInternacionalAutocomplete';

function InfoMaritimasForms() {
  const form = useForm();

  const { values } = useFormState();
  const { t } = useTranslation();

  const transportadorInternacionalFilter = useCallback(() => {
    const filterParams = [
      {
        name: 'viatransporte',
        value: values?.atributosAdicionais?.transporte?.viaTransporte?.id,
      },
    ];

    return filterParams;
  }, [values]);

  return (
    <>
      <Grid item container spacing={1} xs={12} style={{ paddingTop: '2vh' }}>
        <Grid item xs={4}>
          <QCXSelectEmbarcacaoAutocomplete
            id="text-field-embarcacao-transporte"
            key="text-field-embarcacao-transporte"
            name="atributosAdicionais.transporte.embarcacao"
            hideCode
            label={t('com.muralis.qcx.embarcacao.label')}
            disabled={false}
            initialValues={values}
          />
        </Grid>

        <Grid item xs={4}>
          <QCXSelectTransportadorInternacionalAutocomplete
            id="text-field-transportador-internacional-transportador"
            key="text-field-transportador-internacional-transportador"
            name="atributosAdicionais.transporte.transportadorInternacional.transportador"
            label={t('com.muralis.qcx.transportadorInternacional.label')}
            disabled={false}
            initialValues={values}
            getFilters={transportadorInternacionalFilter}
            disableFilter
          />
          <OnChange name="atributosAdicionais.transporte.transportadorInternacional.transportador">
            {(value) => {
              if (value) {
                form.change(
                  'atributosAdicionais.transporte.transportadorInternacional.pais.id',
                  value?.endereco?.pais?.id
                );
              }
            }}
          </OnChange>
        </Grid>

        <Grid item xs={4}>
          <QCXSelectPaisAutocomplete
            id="autocomplete-select-bandeira-transporte-field"
            key="autocomplete-select-bandeira-transporte-field"
            name="atributosAdicionais.transporte.transportadorInternacional.pais.id"
            label={t('com.muralis.qcx.bandeira')}
            initialValues={values}
            disabled={false}
            autoLoad
          />
        </Grid>
      </Grid>
    </>
  );
}

export default InfoMaritimasForms;
