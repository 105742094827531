import { Box, IconButton } from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import { Autocomplete } from 'mui-rff';
import { useCallback, useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchByFilterAsync, selectNaladiShs } from '../../features/naladi-sh/naladiShSlice';
import QCXFinalTextField from '../final-text-field/QCXFinalTextField';

export default function QCXSelectNaladiShAutocomplete({
  label,
  name,
  textFieldProps = {
    variant: 'outlined',
    size: 'small',
    InputLabelProps: {
      shrink: true,
      style: {
        textTransform: 'uppercase',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  fieldProps,
  required,
  ...restProps
}) {
  const list = useSelector(selectNaladiShs);
  const dispatch = useDispatch();
  const form = useForm();
  const { initialValues } = useFormState();

  const [inputValue, setInputValue] = useState('');

  const handleSetInputValue = (_event, newInputValue, reason) => {
    if (reason !== 'clear') {
      setInputValue(newInputValue);
      return;
    }

    setInputValue('');
  };

  const handleFilter = useCallback((filterValue) => {
    dispatch(fetchByFilterAsync([{ name: 'code', value: filterValue }]));
  }, []);

  const handleSearch = useCallback((filterValue) => {
    dispatch(
      fetchByFilterAsync([
        { name: 'code', value: filterValue },
        { name: 'search', value: true },
      ])
    );
  }, []);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.code} - ${item?.description}`,
  });

  const [value, setValue] = useState(null);

  useEffect(() => {
    const initialValue = _.get(initialValues, 'naladiSh');
    setValue(initialValue);
  }, [initialValues]);

  useEffect(() => {
    if (list.length !== 1 || inputValue === '') {
      return;
    }

    setValue(list[0]);
    form.change(name, list[0].id);
  }, [list]);

  const getOptionValue = useCallback((option) => option?.id, []);

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box width="100%">
        <Autocomplete
          name={name}
          label={label}
          options={list}
          freeSolo
          renderInput={(params) => (
            <QCXFinalTextField
              {...params}
              {...textFieldProps}
              name="naladiSh.code"
              label={label}
              fieldProps={fieldProps}
              required={required}
            />
          )}
          value={value}
          inputValue={inputValue}
          onInputChange={handleSetInputValue}
          onBlur={() => handleFilter(inputValue)}
          textFieldProps={textFieldProps}
          filterOptions={filterOptions}
          getOptionLabel={(item) => (item?.code && item?.description ? `${item?.code} - ${item?.description}` : '')}
          getOptionValue={getOptionValue}
          fieldProps={fieldProps}
          {...restProps}
        />
      </Box>
      <Box style={{ marginRight: -16 }}>
        <IconButton onClick={() => handleSearch(inputValue)} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
