import axios from 'axios';

const baseUrl = process.env.REACT_APP_QCX_API_BASE_URL + '/fatura';

const exportFatura = async (numeros: string[], clientes: string[], token: string, unidadeId: number) => {
  let message = null;
  const params = new URLSearchParams();

  numeros.forEach((numero) => params.append('numeros', numero));

  if (clientes && clientes.length > 0) {
    clientes.forEach((cliente) => params.append('clientes', cliente));
  }

  try {
    const response = await axios.get(
      `${baseUrl}/export-fatura?${params.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          quickcomexTenant: unidadeId,
        },
        responseType: 'blob',
      }
    );

    if (response.status === 200) {
      const file = new File([response.data], "exportFaturaOcr.xlsx", {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;
      a.click();
    } else {
      message = await response.data.text();
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data) {
        message = await error.response.data.text();
      } else {
        message = "Erro inesperado";
      }
    } else {
      message = "Erro inesperado";
    }
  }
  return message;
};

const fetchFaturasNumero = async (inputValue: string, clients: string[], token: string, unidadeId: number) => {
  let faturaOptions: { id: any; value: any }[] = [];
  const params = new URLSearchParams();
  params.set('numero', inputValue);

  if (clients && clients.length > 0) {
    clients.forEach((client) => params.append('clientes', client));
  }

  try {
    const response = await axios.get(
      `${baseUrl}/buscar-faturas?${params.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          quickcomexTenant: unidadeId,
        },
      });

    if (response.status === 200 && Array.isArray(response.data)) {
      faturaOptions = response.data.map((fatura: any) => ({
        id: null,
        value: fatura,
      }));
    }
  } catch (error) {
    console.error('Erro ao buscar os números das faturas:', error);
  }

  return faturaOptions;
};

const fetchClientsNome = async (inputValue: string, token: string, unidadeId: number) => {
  let clientOptions: { id: any; value: any }[] = [];
  const params = new URLSearchParams();
  params.set('nome', inputValue);

  try {
    const response = await axios.get(
      `${baseUrl}/buscar-clientes?${params.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          quickcomexTenant: unidadeId,
        },
      });

    if (response.status === 200 && Array.isArray(response.data)) {
      clientOptions = response.data.map((client: any) => ({
        id: null,
        value: client,
      }));
    }
  } catch (error) {
    console.error('Erro ao buscar os nomes dos clientes:', error);
  }

  return clientOptions;
};

export { exportFatura, fetchFaturasNumero, fetchClientsNome };
