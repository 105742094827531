import { Box, Grid, Hidden, IconButton, makeStyles, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { AccountCircle, ExitToAppRounded, Menu } from '@material-ui/icons';
import { useKeycloak } from '@react-keycloak/web';
import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectUnidadeSelecionada, setUnidadeSelecionada } from '../../features/usuario-logado/usuarioLogadoSlice';
import QCXBox from '../box/QCXBox';
import QCXNegativeLogoOnlyIcon from '../logo/QCXNegativeLogoOnlyIcon';
// import QCXNegativeLogo from '../logo/QCXNegativeLogo';
// import QCXBreadcrumbs from '../breadcrumbs/QCXBreadcrumbs';
import { useGetNomeUsuarioResumido } from '../../ts/common/hooks/keycloak/useGetNomeUsuarioResumido.ts';
import QCXPrimaryLogo from '../logo/QCXPrimaryLogo';
import QCXNotification from '../notification/QCXNotification';
import QCXSelectUnidadeNegocioAssociadaAutocomplete from '../select-unidade-negocio-associada/QCXSelectUnidadeNegocioAssociadaAutocomplete';
import QCXAppBar from './QCXAppBar';

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1,
  },
  logo: {
    height: '2rem',
    width: '10.5rem',
    marginLeft: '2px',
    marginTop: '5px',
  },
  logoIcon: {
    height: '2rem',
  },
}));

export default function QCXFixedAppBar({ handleToggleDrawer, ...restProps }) {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  const logout = () => {
    localStorage.clear();
    keycloak?.logout({ redirectUri: window.location.origin });
  };
  const classes = useStyles();

  const nomeResumido = useGetNomeUsuarioResumido();

  const mensagemBoasVindas = t('com.muralis.qcx.saudacao.bemVindo', { usuario: nomeResumido });

  const mensagemBoasVindasMemo = useMemo(() => mensagemBoasVindas, [mensagemBoasVindas]);

  const nomePerfil = useMemo(() => {
    const perfil = keycloak?.tokenParsed?.assignedRole;
    if (!perfil) {
      return null;
    }
    if (perfil.length <= 28) {
      return `${perfil}`;
    }

    return `${perfil.substring(0, 25)}...`;
  }, [keycloak]);

  const [anchorProfileMenu, setAnchorProfileMenu] = React.useState(null);

  const isProfileMenuOpen = Boolean(anchorProfileMenu);
  const unidadeDeNegocioSelecionada = useSelector(selectUnidadeSelecionada);

  const handleProfileMenuOpen = (event) => {
    setAnchorProfileMenu(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorProfileMenu(null);
  };

  const profileMenuId = 'app-bar-account-menu';
  const renderProfileMenu = (
    <Menu
      anchorEl={anchorProfileMenu}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={profileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuClose}>{t('com.muralis.qcx.perfis.labelSingular')}</MenuItem>
      <MenuItem onClick={handleProfileMenuClose}>{t('com.muralis.qcx.credenciais.minhaConta')}</MenuItem>
    </Menu>
  );

  const handleUnidadeNegocioChange = (form, value, typeEvt) => {
    if (typeEvt === 'clear') {
      dispatch(setUnidadeSelecionada(null));
      return;
    }
    if (value) {
      dispatch(setUnidadeSelecionada(value));
      localStorage.setItem('tenant', value);
    } else {
      form.change('unidadeDeNegocio.id', unidadeDeNegocioSelecionada);
    }
    history.push('/');
  };

  return (
    <div className={classes.grow}>
      <QCXAppBar
        style={{
          paddingRight: '10px',
          paddingLeft: '10px',
        }}
        {...restProps}
      >
        <Grid
          container
          alignItems="center"
          style={{
            height: '100%',
          }}
        >
          <Grid item container alignItems="center" xl={2} lg={2} md={3} sm={2} xs={3}>
            <Hidden lgUp>
              <IconButton onClick={handleToggleDrawer}>
                <Menu color="secondary" />
              </IconButton>
            </Hidden>
            <Hidden smDown>
              <Box>
                {/* <QCXNegativeLogo
                  className={classes.logo}
                  enableLink
                  to="/"
                /> */}
                <QCXPrimaryLogo className={classes.logo} enableLink to="/" />
              </Box>
            </Hidden>
            <Hidden smUp>
              <Box>
                <QCXNegativeLogoOnlyIcon className={classes.logoIcon} enableLink to="/" />
              </Box>
            </Hidden>
          </Grid>

          {/* <Grid item container justify="flex-start" xs={1} sm={3} md={5} lg={8}>
            <Hidden mdDown>
              {
                breadcrumbs?.length > 0
                && (
                  <QCXBreadcrumbs data={breadcrumbs} />
                )
              }
            </Hidden>
          </Grid> */}
          <Grid item container alignItems="center" lg={10} md={9} sm={10} xs={9}>
            <Grid item container alignItems="center" justify="flex-end">
              <Grid
                item
                container
                xl={9}
                lg={8}
                md={6}
                sm={9}
                xs={8}
                spacing={1}
                alignItems="center"
                justify="flex-end"
                // justifyContent="space-between"
              >
                <Form
                  initialValues={{
                    unidadeDeNegocio: { id: unidadeDeNegocioSelecionada },
                  }}
                  onSubmit={() => {}}
                >
                  {({ form, values }) => (
                    <>
                      <Grid item lg={5} md={10} sm={10} xs={9}>
                        <QCXSelectUnidadeNegocioAssociadaAutocomplete
                          name="unidadeDeNegocio.id"
                          label={t('com.muralis.qcx.unidadeNegocio.label')}
                          onChange={(evt, option, typeEvt) => {
                            handleUnidadeNegocioChange(form, option?.id, typeEvt);
                          }}
                          initialValues={values}
                          defaultAdditionalDelay={!unidadeDeNegocioSelecionada}
                          autoSelect={false}
                          autoComplete
                          textFieldProps={{
                            variant: 'outlined',
                            size: 'small',
                            InputLabelProps: {
                              shrink: true,
                              style: {
                                textTransform: 'uppercase',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              },
                            },
                          }}
                          getOptionLabel={(item) => `${item?.pessoa?.nomeResumido} - ${item?.id}`}
                        />
                      </Grid>
                    </>
                  )}
                </Form>
              </Grid>
              <Hidden smDown>
                <Grid item lg={2} md={3} sm={3} justify="flex-end">
                  <Typography variant="p" color="primary" style={{ textAlign: 'justify', textAlignLast: 'right' }}>
                    <QCXBox fontWeight="fontWeightBold">{mensagemBoasVindasMemo}</QCXBox>
                    {nomePerfil && <QCXBox fontWeight="fontWeightBold">{nomePerfil}</QCXBox>}
                  </Typography>
                </Grid>
              </Hidden>
              <Grid item>
                <QCXNotification unidadeDeNegocio={unidadeDeNegocioSelecionada} />
              </Grid>
              <Grid item>
                <Tooltip arrow title={t('com.muralis.qcx.credenciais.minhaConta')} aria-label="minha conta">
                  <IconButton
                    aria-label="conta do usuário atual"
                    aria-controls={profileMenuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip arrow title={t('com.muralis.qcx.acoes.sair')} aria-label="sair">
                  <IconButton edge="end" aria-label="sair" aria-haspopup="true" color="inherit" onClick={logout}>
                    <ExitToAppRounded />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </QCXAppBar>
      {renderProfileMenu}
    </div>
  );
}
