import { v4 as uuidv4 } from 'uuid';
import { Grid, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DescriptionOutlined as DescriptionOutlinedIcon } from '@material-ui/icons';
import QCXTabsManager from '../../../../../components/tabs/QCXTabsManager';
import { DashboardSgtFaturaRow } from '../types/DashboardSgtFaturaRow';
import { GridColDef } from '@material-ui/data-grid';
import { DashboardSgtConhecimentoRow } from '../types/DashboardSgtConhecimentoRow';
import DataGridControl from '../../../../../templates/simple-consult-page/SimpleConsultPageTemplate/DataGridControl';
import { DashboardSgtAdvancedFilterFormConfigChange } from '../types/DashboardSgtAdvancedFilterFormConfigChange';
import DashboardSgtAdvancedFilterForm from './DashboardSgtAdvancedFilterForm';
import { formatDate } from '../../../../../utils/general/general-utils';

type DashboardSgtTablesProps = {
  faturaTableData: DashboardSgtFaturaRow[];
  conhecimentoTableData: DashboardSgtConhecimentoRow[];
  onFaturaConfigChange: (values: DashboardSgtAdvancedFilterFormConfigChange) => void;
  onConhecimentoConfigChange: (values: DashboardSgtAdvancedFilterFormConfigChange) => void;
  openGED: (id: number) => void;
  generateExcelFatura: () => void;
  generateExcelConhecimento: () => void;
};

const DashboardSgtTables = ({
  faturaTableData,
  conhecimentoTableData,
  onFaturaConfigChange,
  onConhecimentoConfigChange,
  openGED,
  generateExcelFatura,
  generateExcelConhecimento,
}: DashboardSgtTablesProps) => {
  const { t } = useTranslation();

  const tabManagerProps = {
    scrollButtons: 'auto',
    variant: 'scrollable',
    tabs: [
      {
        label: t('com.muralis.qcx.conhecimento'),
      },
      {
        label: t('com.muralis.qcx.fatura.labelSingular'),
      },
    ],
  };

  const faturaTableColumns: GridColDef[] = [
    {
      field: 'numeroFatura',
      headerName: 'Número Fatura',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.numeroFatura,
    },
    {
      field: 'tipoFatura',
      headerName: 'Tipo Fatura',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.tipoFatura,
    },
    {
      field: 'tipoDeclaracao',
      headerName: 'Tipo Declaração',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.tipoDeclaracao,
    },
    {
      field: 'processo',
      headerName: 'Processo',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.processo,
    },
    {
      field: 'modalidade',
      headerName: 'Modalidade',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.modalidade,
    },
    {
      field: 'dataFatura',
      headerName: 'Data da Fatura',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => (row?.dataFatura ? formatDate(row.dataFatura) : ''),
    },
    {
      field: 'dataEmbarque',
      headerName: 'Data de Embarque',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.dataEmbarque,
    },
    {
      field: 'pesoTotal',
      headerName: 'Peso Total',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.pesoTotal,
    },
    {
      field: 'valorTotal',
      headerName: 'Valor Total',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.valorTotal,
    },
    {
      field: 'valorServico',
      headerName: 'Valor do Serviço',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.valorServico,
    },
    {
      field: 'valorMoeda',
      headerName: 'Valor da Moeda',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.valorMoeda,
    },
    {
      field: 'refEmbarque',
      headerName: 'Ref. Embarque',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.refEmbarque,
    },
    {
      field: 'referencia',
      headerName: 'Referência',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.referencia,
    },
    {
      field: 'statusFatura',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.statusFatura,
    },
  ];

  const faturaTableControlButtons = [
    {
      description: 'Exportar Excel',
      onClick: generateExcelFatura,
    },
  ];

  const conhecimentoTableColumns: GridColDef[] = [
    {
      field: 'processo',
      headerName: 'Processo',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.processo,
    },
    {
      field: 'refCliente',
      headerName: 'Referência do Cliente',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.refCliente,
    },
    {
      field: 'dataProcesso',
      headerName: 'Data do Processo',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => (row?.dataProcesso ? formatDate(row.dataProcesso) : ''),
    },
    {
      field: 'importador',
      headerName: 'Importador',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.importador,
    },
    {
      field: 'exportador',
      headerName: 'Exportador',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.exportador,
    },
    {
      field: 'statusProcesso',
      headerName: 'Status do Processo',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.statusProcesso,
    },
    {
      field: 'origem',
      headerName: 'Origem',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.origem,
    },
    {
      field: 'destino',
      headerName: 'Destino',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.destino,
    },
    {
      field: 'paisProcedencia',
      headerName: 'País de Procedência',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      valueGetter: ({ row }) => row?.paisProcedencia,
    },
    {
      field: 'acoes',
      headerName: t('com.muralis.qcx.documento.labelPlural'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 1,
      renderCell: ({ row: { id } }) => (
        <>
          <IconButton key={id} name={`stw-active-${id}`} onClick={() => openGED(id)}>
            <DescriptionOutlinedIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const conhecimentoTableControlButtons = [
    {
      description: 'Exportar Excel',
      onClick: generateExcelConhecimento,
    },
  ];

  return (
    <QCXTabsManager
      key={undefined}
      id={undefined}
      onChangeDecorator={undefined}
      renderControlButtons={undefined}
      {...tabManagerProps}
    >
      <>
        <Grid item xs={12}>
          <DataGridControl
            columns={conhecimentoTableColumns}
            controlButtons={conhecimentoTableControlButtons}
            rows={conhecimentoTableData.map((f) => ({ ...f, id: f.id ? f.id : uuidv4() }))}
            filterPropGetter={undefined}
            entityPluralName={undefined}
            rowsPerPageOptions={[5, 10, 25, 50]}
            rowsCount={undefined}
            onConfigChangeCallback={onConhecimentoConfigChange}
            advancedFilterFormChildren={DashboardSgtAdvancedFilterForm as any}
            advancedFilterKeyAccessors={['dateStart', 'dateEnd']}
          />
        </Grid>
      </>

      <>
        <Grid item xs={12}>
          <DataGridControl
            pageSize={10}
            columns={faturaTableColumns}
            controlButtons={faturaTableControlButtons}
            rows={faturaTableData.map((f) => ({ ...f, id: f.id ? f.id : uuidv4() }))}
            filterPropGetter={undefined}
            entityPluralName={undefined}
            rowsCount={undefined}
            onConfigChangeCallback={onFaturaConfigChange}
            advancedFilterFormChildren={DashboardSgtAdvancedFilterForm as any}
            advancedFilterKeyAccessors={['dateStart', 'dateEnd']}
          />
        </Grid>
      </>
    </QCXTabsManager>
  );
};

export default DashboardSgtTables;
