import { doGetQCXRequest, doPutQCXInactivateRequest } from '../../utils/api/domain/domain-api-utils';
import { DEMANDA_OCR_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAllAsync() {
  return doGetQCXRequest([DEMANDA_OCR_ENDPOINT]);
}

async function inactivateById(id: number) {
  return doPutQCXInactivateRequest([DEMANDA_OCR_ENDPOINT], id);
}

const demandaOcrAPI = {
  fetchAllAsync,
  inactivateById,
};

export { demandaOcrAPI, fetchAllAsync, inactivateById };
