import saveAs from 'file-saver';

/**
 * It creates a Blob object with the text you want to save, and then uses the FileSaver library to save
 * it.
 * @param {string} text - string - the text to be saved
 */
export const saveTxtFile = (text: string, name?: string) => {
  const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
  saveAs(blob, `${name ?? 'totvs'}.txt`);
};

/**
 * It takes a string, a length, and an alignment, and returns a string that is either truncated or
 * padded to the specified length
 * @param {string} value - The string to be filled and aligned.
 * @param {number} length - The length of the string you want to return.
 * @param {"left" | "right"} align - "left" | "right"
 * @returns A string that is either truncated or padded with spaces.
 */
export const fillAndAlignString = (
  value: string | null,
  length: number,
  align: 'left' | 'right',
  isNumeric: boolean = false,
  precision: number = 2,
  filler?: string,
  cropDirection?: 'start' | 'end'
) => {
  if (isNumeric) {
    let number;
    if (!value || value.trim() === '') {
      number = Number('0.00').toFixed(precision);
    } else if (isNaN(Number(value))) {
      number = Number('0.00').toFixed(precision);
    } else {
      number = Number(value).toFixed(precision);
    }
    return number.padStart(length, filler ?? '0');
  }
  if (!value) {
    return ' '.repeat(length);
  }
  if (value.length > length && cropDirection === 'start') {
    return value.substring(value.length - length, value.length);
  } else if (value.length > length) {
    return value.substring(0, length);
  }
  if (align === 'left') {
    return value.padEnd(length, filler ?? ' ');
  }
  return value.padStart(length, filler ?? ' ');
};

/**
 * It takes a date, gets the day, month and year, and returns a string with the day, month and year,
 * with the day and month padded with zeros if they are less than 10
 * @param {Date} date - The date you want to format.
 * @returns A string with the date in the format DDMMAAAA
 */
export const formatDateToDDMMAAAA = (dateString: string | null, hasSlash = false) => {
  if (!dateString) return '';
  const secondDatePattern = /^\d{4}-\d{2}-\d{2}$/;

  if (secondDatePattern.test(dateString)) {
    const [year, month, day] = dateString.split('-');

    if (!hasSlash) return `${day.padStart(2, '0')}${month.padStart(2, '0')}${year}`;

    return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
  }

  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (hasSlash) return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

  return `${day.toString().padStart(2, '0')}${month.toString().padStart(2, '0')}${year}`;
};

/**
 * It takes a date string, converts it to a Date object, then returns a string in the format YYYYMMDD
 * @param {string | null} dateString - The date string to format.
 * @returns A function that takes a date string and returns a formatted date string.
 */
export const formatDateToAAAAMMDD = (dateString: string | null | undefined) => {
  if (!dateString) return '';

  const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;

  if (datePattern.test(dateString)) {
    const [day, month, year] = dateString.split('/');
    return `${year}${month.padStart(2, '0')}${day.padStart(2, '0')}`;
  }

  const secondDatePattern = /^\d{4}-\d{2}-\d{2}$/;

  if (secondDatePattern.test(dateString)) {
    const [year, month, day] = dateString.split('-');
    return `${year}${month.padStart(2, '0')}${day.padStart(2, '0')}`;
  }

  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`;
};

/**
 * It takes a date string, converts it to a Date object, gets the month and year, and returns a string
 * with the month and year in the format MMYYYY
 * @param {string | null} dateString - The date string to be formatted.
 * @returns A string with the month and year of the dateString.
 */
export const formatDateToMMAAAA = (dateString: string | null) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${month.toString().padStart(2, '0')}${year}`;
};

/**
 * It takes a date string, converts it to a date object, extracts the day, month and year, and returns
 * a string in the format DDMMAA
 * @param {string | null} dateString - The date string to be formatted.
 * @returns A string with the date in the format DDMMAA
 */
export const formatDateToDDMMAA = (dateString: string | null) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day.toString().padStart(2, '0')}${month.toString().padStart(2, '0')}${year.toString().substring(2)}`;
};
