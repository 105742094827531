import React, { useEffect, useState, useCallback } from 'react';
import { Grid, LinearProgress, Tooltip, Typography, IconButton, Chip, Grow, SvgIconProps } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import HelpIcon from '@mui/icons-material/Help';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { inactivateByIdAsync } from '../../../../../features/demanda-ocr/demandaOcrThunks';
import { setErrorFeedback } from '../../../../../features/feedback/feedbackSlice';
import { DemandaOCRMessage } from '../ocrPage';

interface OcrDemandProgessIndicatorProps {
  message: DemandaOCRMessage;
}

const statusToIcon: { [key: string]: React.ComponentType<SvgIconProps> } = {
  NA_FILA: AccessTimeIcon,
  APLICANDO_OCR: DocumentScannerIcon,
  CONCLUIDO: CheckCircleIcon,
  CRIANDO_FATURA: NoteAddIcon,
  BAIXANDO_FATURA: CloudDownloadIcon,
  FATURA_DESCONHECIDA: HelpIcon,
  default: HelpIcon,
};

const statusToMessage: { [key: string]: string } = {
  NA_FILA: 'Aguardando processamento',
  APLICANDO_OCR: 'Aplicando OCR',
  CONCLUIDO: 'Concluído',
  CRIANDO_FATURA: 'Criando fatura',
  BAIXANDO_FATURA: 'Baixando fatura',
  FATURA_DESCONHECIDA: 'Fatura desconhecida',
  default: 'Erro',
};

const statusToColor: { [key: string]: 'default' | 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success' } =
  {
    NA_FILA: 'warning',
    APLICANDO_OCR: 'info',
    CONCLUIDO: 'success',
    CRIANDO_FATURA: 'info',
    BAIXANDO_FATURA: 'info',
    FATURA_DESCONHECIDA: 'error',
    default: 'error',
  };

const statusToProgressValue: { [key: string]: number } = {
  NA_FILA: 0,
  APLICANDO_OCR: 50,
  CONCLUIDO: 100,
  CRIANDO_FATURA: 75,
  BAIXANDO_FATURA: 25,
  FATURA_DESCONHECIDA: 100,
  default: 0,
};

const MAX_ELAPSED_HOURS = 0;
const MAX_ELAPSED_MINUTES = 10;

const OcrDemandProgessIndicator: React.FC<OcrDemandProgessIndicatorProps> = ({ message }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [elapsedTime, setElapsedTime] = useState('');

  const parseDateString = useCallback((dateString: string) => {
    const parts = dateString.split(' ');

    const dateParts = parts[0].split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const year = parseInt(dateParts[2], 10);

    const timeParts = parts[1].split(':');
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
    const seconds = parseInt(timeParts[2], 10);

    return new Date(year, month, day, hours, minutes, seconds);
  }, []);

  const updateElapsedTime = useCallback(() => {
    const parsedDate = parseDateString(message.insertionDate);
    const diff = Date.now() - parsedDate.getTime();
    const elapsed = new Date(diff);
    const hours = elapsed.getUTCHours();
    const minutes = elapsed.getUTCMinutes();
    const seconds = elapsed.getUTCSeconds();
    setElapsedTime(
      `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`
    );
  }, [message.insertionDate]);

  const elapsedHours = parseInt(elapsedTime.split(':')[0] || '0');
  const elapsedMinutes = parseInt(elapsedTime.split(':')[1] || '0');

  useEffect(() => {
    const interval = setInterval(updateElapsedTime, 1000);
    return () => clearInterval(interval);
  }, [updateElapsedTime]);

  const consultarFatura = () => history.push(`/importacao/faturas/${message.faturaId}/detalhes`);
  const displayHelpMessage = () =>
    dispatch(
      setErrorFeedback({
        message:
          'Se a fatura apresenta a mensagem Fatura Desconhecida, significa que o sistema não foi treinado para o modelo de fatura que você submeteu. Por favor, entre em contato com o suporte para inserirmos o modelo em nossa base.',
      })
    );
  const deletarDemanda = () => message.id && dispatch(inactivateByIdAsync(message.id));

  const StatusIcon = statusToIcon[message.status] || statusToIcon.default;
  const statusMessage = statusToMessage[message.status] || statusToMessage.default;
  const chipColor = statusToColor[message.status] || statusToColor.default;
  const progressBarValue = statusToProgressValue[message.status] || statusToProgressValue.default;

  return (
    <Grow in={true} key={message.description} timeout={1000} mountOnEnter unmountOnExit>
      <Grid item container spacing={2} xs={12} alignItems="center">
        <Grid item>
          <Chip icon={<StatusIcon />} color={chipColor} label={statusMessage} />
        </Grid>
        <Grid item>
          <Typography variant="body1">{message.description}</Typography>
        </Grid>
        {message.status !== 'ERRO' && message.status !== 'CONCLUIDO' && message.status !== 'FATURA_DESCONHECIDA' && (
          <Grid item xs>
            <LinearProgress variant="determinate" value={progressBarValue} />
          </Grid>
        )}
        <Grid item>
          {statusMessage === 'Concluído' && (
            <Tooltip title="Consultar fatura">
              <IconButton onClick={consultarFatura}>
                <SearchIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}

          {(statusMessage === 'Erro' ||
            statusMessage === 'Fatura desconhecida' ||
            elapsedHours > MAX_ELAPSED_HOURS ||
            elapsedMinutes >= MAX_ELAPSED_MINUTES) && (
            <Tooltip title="Ajuda">
              <IconButton onClick={displayHelpMessage}>
                <HelpIcon color="primary" />
              </IconButton>
            </Tooltip>
          )}
          {statusMessage === 'Concluído' ||
          statusMessage === 'Fatura desconhecida' ||
          statusMessage === 'Erro' ||
          elapsedHours > MAX_ELAPSED_HOURS ||
          elapsedMinutes >= MAX_ELAPSED_MINUTES ? (
            <Tooltip title="Remover do histórico">
              <IconButton onClick={deletarDemanda}>
                <CleaningServicesIcon color="primary" />
              </IconButton>
            </Tooltip>
          ) : (
            <Typography variant="body1">{elapsedTime}</Typography>
          )}
        </Grid>
      </Grid>
    </Grow>
  );
};

export default OcrDemandProgessIndicator;
