import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFollowUps } from '../../features/follow-up/followUpSelectors';
import { fetchAllAsync } from '../../features/follow-up/followUpThunks';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectFollowUpImportacaoAutocompleteField({
  label,
  name,
  initialValues,
  controlled = false,
  ...restProps
}) {
  const dispatch = useDispatch();

  const list = useSelector(selectFollowUps);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    if (!controlled) {
      dispatch(fetchAllAsync());
    }
  }, [controlled]);

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name));

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  // const filterOptions = createFilterOptions({
  //   stringify: (option) => (
  //     option?.numero
  //   ),
  // });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      // filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => item?.numero || ''}
      {...restProps}
    />
  );
}
