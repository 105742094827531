import AssessoriaFollowupPage from '../../pages/assessoria/assessoriaFollowupPage/assessoriaFollowupPage';
import FollowUpAssessoriaTimelineManagerPage from '../../pages/assessoria/assessoriaFollowupTimelineManagerPage/assessoriaFollowUpTimelineManagePage';
import AssessoriaPage from '../../pages/assessoria/assessoriaPage';
import NewAssessoriaFollowupPage from '../../pages/assessoria/newAssessoriaFollowupPage/newAssessoriaFollowupPage';
import ViewAssessoriaFollowupPage from '../../pages/assessoria/viewAssessoriaFollowupPage/viewAssessoriaFollowupPage';
import ComercialPage from '../../pages/comercial/comercialPage';
import PropostasCadastros from '../../pages/comercial/propostas/propostasCadastrosPage/propostasCadastros';
import PropostasDetails from '../../pages/comercial/propostas/propostasDetails/propostasDetails';
import PropostasFollowUpPage from '../../pages/comercial/propostas/propostasFollowUpPage/propostasFollowUpPage';
import PropostasIndices from '../../pages/comercial/propostas/propostasIndicesPage/propostasIndices';
import PropostasMercadorias from '../../pages/comercial/propostas/propostasMercadoria/propostasMercadorias';
import PropostasNewPage from '../../pages/comercial/propostas/propostasNewPage/propostasNewPage';
import PropostasPage from '../../pages/comercial/propostas/propostasPage';
import PropostasServicos from '../../pages/comercial/propostas/propostasServicosPage/propostasServicos';
import IntegracaoPage from '../../pages/integracao/integracaoPage';
import PlanilhasPage from '../../pages/integracao/planilhas/PlanilhasPage';
import FaturasPlanilhasPage from '../../pages/integracao/planilhas/faturas/faturaPlanilhasPage';
import EmpresasEstrangeirasPlanilhasPage from '../../pages/integracao/planilhas/EmpresaEstrangeiras/empresasEstrangeirasPlanilhasPage';
import TotvsEscritaDanfePage from '../../pages/integracao/totvs-easy/escrita/danfe/totvsEscritaDanfePage';
import TotvsEscritaDespesasPage from '../../pages/integracao/totvs-easy/escrita/despesas/totvsEscritaDespesasPage';
import TotvsEscritaNumerariosPage from '../../pages/integracao/totvs-easy/escrita/numerarios/totvsEscritaNumerariosPage';
import TotvsEscritaPage from '../../pages/integracao/totvs-easy/escrita/totvsEscritaPage';
import TotvsLeituraPage from '../../pages/integracao/totvs-easy/leitura/totvsLeituraPage';
import TotvsCorrespondenciaDetailsPage from '../../pages/integracao/totvs-easy/totvsCorrespondenciaDetailsPage';
import TotvsCorrespondenciaPage from '../../pages/integracao/totvs-easy/totvsCorrespondenciaPage';
import TotuvseasyPage from '../../pages/integracao/totvs-easy/totvseasyPage';
import RelatorioAssociatedPage from '../../pages/relatorios/relatorioAssociated/relatorioAssociatedPage';
import RelatorioDraftDanfePage from '../../pages/relatorios/relatorioDraftDanfe/relatorioDraftDanfePage';
import RelatorioDraftDanfeFaxePage from '../../pages/relatorios/relatorioDraftDanfeFaxe/relatorioDraftDanfeFaxePage';
import RelatorioHalliburtonPage from '../../pages/relatorios/relatorioHalliburton/relatorioHalliburtonPage';
import RelatorioLaPage from '../../pages/relatorios/relatorioLa/relatorioLaPage';
import RelatoriosPage from '../../pages/relatorios/relatoriosPage';
import FinanceiroRelatoriosPage from '../../pages/financeiro/financeiroRelatoriosPage';
import MapaProcessosPage from '../../pages/relatorios/mapaProcessos/mapaProcessosPage';
import ProcessosDesembaracadosPage from '../../pages/relatorios/processosDesembaracados/ProcessosDesembaracadosPage';
import CrodaEscritaPage from '../../pages/integracao/croda/escrita/crodaEscritaPage';
import CrodaPage from '../../pages/integracao/croda/CrodaPage';
import CrodaEscritaDanfePage from '../../pages/integracao/croda/escrita/danfe/crodaEscritaDanfePage';
import CrodaEscritaNotaFiscalPage from '../../pages/integracao/croda/escrita/nota-fiscal/crodaEscritaNotaFiscalPage';
import OcrPage from '../../pages/integracao/ocr/ocrPage';
import OcrExportPage from '../../pages/integracao/ocr-export/ocrExportPage';
import TriangulusPage from '../../pages/integracao/triangulus/TriangulusPage';
import TriangulusDanfePage from '../../pages/integracao/triangulus/danfe/triangulusDanfePage';
import RelatorioTransportadorasPage from '../../pages/relatorios/relatorioTransportadoras/relatorioTransportadorasPage';
import CrawlersPage from '../../pages/integracao/crawlers/CrawlersPage';
import CrawlersLiPage from '../../pages/integracao/crawlers/li/crawlersLiPage';
import RedBullPage from '../../pages/relatorios/relatorioRedBull/RedBullPage';
import RedBullLatasPage from '../../pages/relatorios/relatorioRedBull/latas/redBullLatasPage';
import RedBullRelatorioPadraoPage from '../../pages/relatorios/relatorioRedBull/relatorio-padrao/redBullLatasPage';
import RelatorioGerencialPage from '../../pages/relatorios/relatorioGerencial/relatorioGerencialPage';
import RelatorioProcessosPage from '../../pages/relatorios/relatorioProcessos/relatorioProcessosPage';
import RelatorioDashboardPage from '../../pages/relatorios/relatorioDashboard/relatorioDashboardPage';
import RochePage from '../../pages/integracao/roche/RochePage';
import RocheEscritaPage from '../../pages/integracao/roche/escrita/rocheEscritaPage';
import RocheEscritaDanfePage from '../../pages/integracao/roche/escrita/danfe/rocheEscritaDanfePage';
import CatalogoProdutosPlanilhaPage from '../../pages/integracao/planilhas/catalogoProdutos/CatalogoProdutosPlanilhaPage';
import ThermoFisherLeituraPage from '../../pages/integracao/thermo-fisher/leitura/ThermoFisherLeituraPage';
import ThermoFisherPage from '../../pages/integracao/thermo-fisher/ThermoFisherPage';
import ThermoFisherEscritaPage from '../../pages/integracao/thermo-fisher/escrita/ThermoFisherEscritaPage';
import DashboardSgtPage from '../../pages/relatorios/dashboardSgt/dashboardSgtPage';

type Route = {
  path: string;
  component: React.FC;
};

const tsRoutes: Route[] = [
  {
    path: '/comercial',
    component: ComercialPage,
  },
  {
    path: '/comercial/propostas',
    component: PropostasPage,
  },
  {
    path: '/comercial/propostas/nova',
    component: PropostasNewPage,
  },
  {
    path: '/comercial/propostas/indices',
    component: PropostasIndices,
  },
  {
    path: '/comercial/propostas/mercadorias',
    component: PropostasMercadorias,
  },
  {
    path: '/comercial/propostas/servicos',
    component: PropostasServicos,
  },
  {
    path: '/comercial/propostas/cadastros',
    component: PropostasCadastros,
  },
  {
    path: '/comercial/propostas/:id',
    component: PropostasDetails,
  },
  {
    path: '/comercial/propostas/:id/follow-ups',
    component: PropostasFollowUpPage,
  },
  {
    path: '/assessoria',
    component: AssessoriaPage,
  },
  {
    path: '/assessoria/followup',
    component: AssessoriaFollowupPage,
  },
  {
    path: '/assessoria/followup/novo',
    component: NewAssessoriaFollowupPage,
  },
  {
    path: '/assessoria/followup/:id/detalhes',
    component: ViewAssessoriaFollowupPage,
  },
  {
    path: '/assessoria/followup/:id/timeline',
    component: FollowUpAssessoriaTimelineManagerPage,
  },
  {
    path: '/integracao',
    component: IntegracaoPage,
  },
  {
    path: '/integracao/planilhas',
    component: PlanilhasPage,
  },
  {
    path: '/integracao/planilhas/faturas',
    component: FaturasPlanilhasPage,
  },
  {
    path: '/integracao/planilhas/empresas-estrangeiras',
    component: EmpresasEstrangeirasPlanilhasPage,
  },
  {
    path: '/integracao/planilhas/catalogo',
    component: CatalogoProdutosPlanilhaPage,
  },
  {
    path: '/integracao/totvs-easy',
    component: TotuvseasyPage,
  },
  {
    path: '/integracao/totvs-easy/leitura',
    component: TotvsLeituraPage,
  },
  {
    path: '/integracao/totvs-easy/correspondencia',
    component: TotvsCorrespondenciaPage,
  },
  {
    path: '/integracao/totvs-easy/correspondencia/:id',
    component: TotvsCorrespondenciaDetailsPage,
  },
  {
    path: '/integracao/totvs-easy/escrita',
    component: TotvsEscritaPage,
  },
  {
    path: '/integracao/totvs-easy/escrita/despesas',
    component: TotvsEscritaDespesasPage,
  },
  {
    path: '/integracao/totvs-easy/escrita/danfe',
    component: TotvsEscritaDanfePage,
  },
  {
    path: '/integracao/totvs-easy/escrita/numerarios',
    component: TotvsEscritaNumerariosPage,
  },
  {
    path: '/integracao/triangulus',
    component: TriangulusPage,
  },
  {
    path: '/integracao/triangulus/danfe',
    component: TriangulusDanfePage,
  },
  {
    path: '/integracao/croda',
    component: CrodaPage,
  },
  {
    path: '/integracao/croda/escrita',
    component: CrodaEscritaPage,
  },
  {
    path: '/integracao/croda/escrita/danfe',
    component: CrodaEscritaDanfePage,
  },
  {
    path: '/integracao/croda/escrita/nota-fiscal',
    component: CrodaEscritaNotaFiscalPage,
  },
  {
    path: '/integracao/ocr',
    component: OcrPage,
  },
  {
    path: '/integracao/ocr-export',
    component: OcrExportPage,
  },
  {
    path: '/integracao/crawlers',
    component: CrawlersPage,
  },

  {
    path: '/integracao/crawlers/li',
    component: CrawlersLiPage,
  },
  {
    path: '/relatorios',
    component: RelatoriosPage,
  },
  {
    path: '/financeiro/relatorios',
    component: FinanceiroRelatoriosPage,
  },
  {
    path: '/relatorios/mapa-processos',
    component: MapaProcessosPage,
  },
  {
    path: '/relatorios/processos-desembaracados',
    component: ProcessosDesembaracadosPage,
  },
  {
    path: '/relatorios/halliburton',
    component: RelatorioHalliburtonPage,
  },
  {
    path: '/relatorios/danfe',
    component: RelatorioDraftDanfePage,
  },
  {
    path: '/relatorios/danfeFaxe',
    component: RelatorioDraftDanfeFaxePage,
  },
  {
    path: '/relatorios/la',
    component: RelatorioLaPage,
  },
  {
    path: '/relatorios/associated',
    component: RelatorioAssociatedPage,
  },
  {
    path: '/relatorios/transportadoras',
    component: RelatorioTransportadorasPage,
  },
  {
    path: '/relatorios/redbull',
    component: RedBullPage,
  },
  {
    path: '/relatorios/redbull/latas',
    component: RedBullLatasPage,
  },
  {
    path: '/relatorios/redbull/comum',
    component: RedBullRelatorioPadraoPage,
  },
  {
    path: '/relatorios/gerencial',
    component: RelatorioGerencialPage,
  },
  {
    path: '/relatorios/processos',
    component: RelatorioProcessosPage,
  },
  {
    path: '/relatorios/dashboard',
    component: RelatorioDashboardPage,
  },
  {
    path: '/relatorios/dashboard-sgt',
    component: DashboardSgtPage,
  },
  {
    path: '/integracao/roche',
    component: RochePage,
  },
  {
    path: '/integracao/roche/escrita',
    component: RocheEscritaPage,
  },
  {
    path: '/integracao/roche/escrita/danfe',
    component: RocheEscritaDanfePage,
  },
  {
    path: '/integracao/thermo-fisher/leitura',
    component: ThermoFisherLeituraPage,
  },
  {
    path: '/integracao/thermo-fisher/escrita',
    component: ThermoFisherEscritaPage,
  },
  {
    path: '/integracao/thermo-fisher',
    component: ThermoFisherPage,
  },
];

export { tsRoutes };
