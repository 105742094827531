import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import ExtensionIcon from '@mui/icons-material/Extension';
import RttIcon from '@mui/icons-material/Rtt';
import CardsGridSubmenu from '../../../../common/components/cardsGrid/cardsGridSubmenu';
import BasicLayout from '../../../../common/layouts/basicLayout';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { CardsGridItem } from '../../../../common/components/cardsGrid/cardsGrid';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { Calculate, Web } from '@mui/icons-material';
import React from 'react';

const cover: CardsGridItem = {
  title: 'Escrita',
  icon: Web,
  path: '/integracao/roche',
};

const gridItems: CardsGridItem[] = [
  {
    title: 'DANFE',
    icon: ReceiptLongIcon,
    path: '/integracao/roche/escrita/danfe',
  },
];

const RocheEscritaPage = () => {
  return (
    <BasicLayout title={'Roche'} icon={<ExtensionIcon color={'secondary'} />}>
      <CardsGridSubmenu cover={cover} items={gridItems}></CardsGridSubmenu>
    </BasicLayout>
  );
};

export default RocheEscritaPage;
