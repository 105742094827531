import { saveAs } from 'file-saver';

export interface GenerateDanfeResponse {
  statusCode: number;
  message: string;
}

export async function downloadFile(token: string, selectedUnidade: string, url: string, filename: string): Promise<GenerateDanfeResponse> {

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.append('Authorization', `Bearer ${token}`);
  requestHeaders.append('quickcomexTenant', selectedUnidade);


  const response = await fetch(url, {
    method: 'GET',
    headers: requestHeaders,
  });

  if (!response.ok) {

    try {

      const data =  await response.json();
      const message: string = data.message;
      return {statusCode: 500, message: `Erro no download: ${message}`}

    } catch (error) {

      return {statusCode: 500, message: "Erro ao gerar Xml da Danfe"}
    }
  }

  const blob = await response.blob();
  saveAs(blob, filename + ".zip");
  return {statusCode: 200, message: `Download realizado com sucesso!`}
}