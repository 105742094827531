/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { AUTO_LOAD_CONTROL, IDLE_CONTROL } from '../config-control';
import i18n from '../../i18n';
import {
  CONSULT_MODE,
  CREATE_MODE,
  UPDATE_MODE,
  LOCKED_MODE,
  NONE_MODE,
  BACKGROUND_CREATE_MODE,
  BACKGROUND_UPDATE_MODE,
  BACKGROUND_DELETE_MODE,
  NONE_BACKGROUND_MODE,
  SUB_CREATE_MODE,
  SUB_CONSULT_MODE,
  SUB_UPDATE_MODE,
  NONE_SUB_MODE,
  BACKGROUND_CONSULT_MODE,
  NONE_SUB_BACKGROUND_MODE,
} from '../mode';
import { NO_REFRESH, REFRESH } from '../refresh';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import { fetchAllAsync, inactivateByIdAsync } from './demandaOcrThunks';

interface DemandaOcrState {
  status: string;
  mode: {
    main: string;
    background: string;
    sub: string;
    subBackground: string;
  };
  refresh: string;
  error: any;
  response: {
    status: number | null;
    message: string | null;
    data: any;
  };
  config: {
    control: string;
  };
  list: any[];
  model: any;
  related: {
    model: {
      duplicata: any;
      email: any;
    };
  };
  historyModel: {
    last: any;
  };
}

const initialState: DemandaOcrState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
    subBackground: NONE_SUB_BACKGROUND_MODE,
  },
  refresh: NO_REFRESH,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {
    control: AUTO_LOAD_CONTROL,
  },
  list: [],
  model: {},
  related: {
    model: {
      duplicata: {},
      email: {},
    },
  },
  historyModel: {
    last: {},
  },
};

const demandaOcrSlice = createSlice({
  name: 'demandaOcr',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    refresh: (state) => {
      state.refresh = REFRESH;
    },
    resetRefresh: (state) => {
      state.refresh = NO_REFRESH;
    },
    changeModeTo: (state, action) => {
      state.mode.main = action.payload;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    changeToLockMode: (state) => {
      state.mode.main = LOCKED_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    changeToBackgroundConsultMode: (state) => {
      state.mode.background = BACKGROUND_CONSULT_MODE;
    },
    changeToBackgroundUpdateMode: (state) => {
      state.mode.background = BACKGROUND_UPDATE_MODE;
    },
    changeToBackgroundDeleteMode: (state) => {
      state.mode.background = BACKGROUND_DELETE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    changeControlToAutoLoad: (state) => {
      state.config.control = AUTO_LOAD_CONTROL;
    },
    resetControl: (state) => {
      state.config.control = IDLE_CONTROL;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    setList: (state, action) => {
      state.list = action.payload;
    },
    resetList: (state) => {
      state.list = [];
    },
    setRelatedDuplicataModel: (state, action) => {
      state.related.model.duplicata = {
        ...action.payload,
      };
    },
    resetRelatedDuplicataModel: (state) => {
      state.related.model.duplicata = {};
    },
    setRelatedEmailModel: (state, action) => {
      state.related.model.email = {
        ...action.payload,
      };
    },
    resetRelatedEmailModel: (state) => {
      state.related.model.email = {};
    },
    changeLastHistoryModel: (state, action) => {
      state.historyModel.last = action.payload;
    },

    resetLastHistoryModel: (state) => {
      state.historyModel.last = {};
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarDemandasOcr', { mensagem: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        state.response.message = i18n.t('com.muralis.qcx.mensagem.demandaOcrExcluida');

        if (state.response.status === 200) {
          const deletedId = action.payload.response.data.id;
          state.list = state.list.filter((current) => current.id !== deletedId);
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroExcluirDemandaOcr', { mensagem: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  refresh,
  resetRefresh,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  changeToBackgroundConsultMode,
  changeToBackgroundUpdateMode,
  changeToBackgroundDeleteMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  changeControlToAutoLoad,
  resetControl,
  setModel,
  resetModel,
  setList,
  resetList,
  setRelatedDuplicataModel,
  resetRelatedDuplicataModel,
  setRelatedEmailModel,
  resetRelatedEmailModel,
  changeLastHistoryModel,
  resetLastHistoryModel,
  setResponse,
  clearResponse,
  setError,
  clearError,
} = demandaOcrSlice.actions;

const demandaOcrActions = demandaOcrSlice.actions;

export {
  demandaOcrSlice,
  demandaOcrActions,
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  refresh,
  resetRefresh,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  changeToBackgroundConsultMode,
  changeToBackgroundUpdateMode,
  changeToBackgroundDeleteMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  changeControlToAutoLoad,
  resetControl,
  setModel,
  resetModel,
  setList,
  resetList,
  setRelatedDuplicataModel,
  resetRelatedDuplicataModel,
  setRelatedEmailModel,
  resetRelatedEmailModel,
  changeLastHistoryModel,
  resetLastHistoryModel,
  setResponse,
  clearResponse,
  setError,
  clearError,
};

export default demandaOcrSlice.reducer;
