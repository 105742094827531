import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _, { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { despesaReceitaAPI } from '../../features/despesa-receita/despesaReceitaAPI';
import {
  fetchAllAsync,
  selectDespesasReceitas,
  setList,
} from '../../features/despesa-receita/despesaReceitaSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectDespesaReceitaAutocomplete({
  label,
  name,
  initialValues,
  filterParams,
  ...restProps
}) {
  const dispatch = useDispatch();

  const list = useSelector(selectDespesasReceitas);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(async () => {
    if (!isEmpty(filterParams)) {
      const response = await despesaReceitaAPI.fetchByFilter(filterParams);
      if (response?.status === 200) {
        dispatch(setList({ data: response?.data }));
      }

      return;
    }

    dispatch(fetchAllAsync());
  }, [filterParams]);

  useEffect(() => {
    const value = list?.find((item) => (
      item?.id === _.get(initialValues, name)
    ));

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }

    const fieldDescription = "ignorableFields.lancamento.despesaReceita.description"
    if(!value && _.isNumber(_.get(initialValues, name)) && _.get(initialValues, fieldDescription)) {
      const valueEdit = _.get(initialValues, "ignorableFields.lancamento.despesaReceita")
      setDefaultValue(valueEdit);
    }

  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (option) => (
      `${option?.id} - ${option?.description}`
    ),
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (
        item?.id && item?.description
          ? `${item?.id} - ${item?.description}`
          : ''
      )}
      {...restProps}
    />
  );
}
