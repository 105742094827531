/* eslint-disable import/extensions */
import { useSelector } from 'react-redux';
import {selectLoading, selectPreviousForm} from './applicationStateSelectors';

export default function useGetApplicationState() {
  const isLoading = useSelector(selectLoading);
  const previousForm = useSelector(selectPreviousForm);

  return { isLoading, previousForm };
}
