import { FaturaData } from './faturasPlanilhasPage.types';
import { followUpAPI } from '../../../../../features/follow-up/followUpAPI';
import { MediaType } from '../../../../../utils/api/api-utils';
import axios from 'axios';
import { BasicFormData } from './faturaPlanilhasPage';

const baseUrlFatura = process.env.REACT_APP_QCX_API_BASE_URL! + '/' + process.env.REACT_APP_QCX_API_FATURA_ENDPOINT!;

const uploadFile = async (value: BasicFormData, unidadeId: number, token: string, body: any, baseUrl?: string) => {
  if (!baseUrl) {
    baseUrl = baseUrlFatura;
  }

  let formData = new FormData();

  const clientesIds = value.clientes
    ? value.clientes
        .map((cliente) => cliente?.id)
        .filter((id) => id !== null && id !== undefined)
        .join(',')
    : '';

  formData.append('clientes', clientesIds);

  if (value.cliente && value.cliente.id !== null && value.cliente.id !== undefined) {
    formData.append('clienteId', value.cliente.id.toString());
  }

  if (value.processo !== null && value.processo !== undefined) {
    formData.append('processo', value.processo);
  }

  if (value.servico && value.servico.id !== null && value.servico.id !== undefined) {
    formData.append('servicoId', value.servico.id.toString());
  }

  formData.append('fatura', value.fatura ? value.fatura.toString() : 'false');
  formData.append('catalogoProdutos', value.catalogoProdutos ? value.catalogoProdutos.toString() : 'false');

  const jsonBlob = new Blob([JSON.stringify(body)], { type: 'application/json' });
  formData.append('bodyData', jsonBlob, 'bodyData.json');

  const response = await axios
    .post(`${baseUrl}/import-planilha`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        quickcomexTenant: unidadeId,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });

  return response;
};

const uploadFileCatalogo = async (value: BasicFormData, unidadeId: number, token: string, baseUrl?: string) => {
  if (!baseUrl) {
    baseUrl = baseUrlFatura;
  }

  let formData = new FormData();

  const clientesIds = value.clientes
    ? value.clientes
        .map((cliente) => cliente?.id)
        .filter((id) => id !== null && id !== undefined)
        .join(',')
    : '';

  formData.append('clientes', clientesIds);

  if (value.cliente && value.cliente.id !== null && value.cliente.id !== undefined) {
    formData.append('clienteId', value.cliente.id.toString());
  }

  if (value.processo !== null && value.processo !== undefined) {
    formData.append('processo', value.processo);
  }

  if (value.servico && value.servico.id !== null && value.servico.id !== undefined) {
    formData.append('servicoId', value.servico.id.toString());
  }

  formData.append('fatura', value.fatura ? value.fatura.toString() : 'false');
  formData.append('catalogoProdutos', value.catalogoProdutos ? value.catalogoProdutos.toString() : 'false');

  if (value.arquivo) {
    formData.append('bodyData', value.arquivo);
  }

  const response = await axios
    .post(`${baseUrl}/import-planilha`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        quickcomexTenant: unidadeId,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });

  return response;
};

const createFollowUps = async (faturas: FaturaData[], importador: { id: number }, servico: { id: number }) => {
  const followUps: {
    id: number | null;
    numero: string | undefined;
    alreadyExists: boolean;
    hasError?: boolean;
    message?: string;
  }[] = [];

  for (const fatura of faturas) {
    try {
      const { faturapo } = fatura;
      const payload = {
        numero: faturapo,
        importador,
        servico,
        overridesTipoMascara: true,
      };
      const formData = new FormData();
      const blob = new Blob([JSON.stringify(payload)], {
        type: MediaType.APPLICATION_JSON,
      });
      formData.append('data', blob);
      const meta = { metadata: [] };
      const jsonMeta = JSON.stringify(meta);
      const blobMeta = new Blob([jsonMeta], {
        type: MediaType.APPLICATION_JSON,
      });
      formData.append('metadata', blobMeta);
      const followUpResult = await followUpAPI.register(formData);
      followUps.push(followUpResult.data);
    } catch (error: any) {
      const { hasError, message } = error.response.data;
      let followUp = {
        id: error.response.data.id,
        numero: fatura.faturapo,
        alreadyExists: false,
        hasError: false,
        message,
      };

      if (error.response.status == 303) {
        followUp.alreadyExists = true;
        followUps.push(followUp);
      } else {
        followUp.hasError = true;
        followUps.push(followUp);
      }
    }
  }

  return followUps;
};

const planilhasPageHelpers = {
  createFollowUps,
  uploadFile,
  uploadFileCatalogo,
};

export default planilhasPageHelpers;
