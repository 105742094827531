import BancoRegistrationPage from '../pages/module/registrations/financial/banco/BancoRegistrationPage';
import BancoConsultPage from '../pages/module/registrations/financial/banco/BancoConsultPage';
import BancoPage from '../pages/module/registrations/financial/banco/BancoPage';
import TransportadorInternacionalRegistrationPage from '../pages/module/registrations/operational/transportador-internacional/TransportadorInternacionalRegistrationPage';
import MoedaConsultPage from '../pages/module/registrations/financial/moeda/MoedaConsultPage';
import MoedaPage from '../pages/module/registrations/financial/moeda/MoedaPage';
import MoedaRegistrationPage from '../pages/module/registrations/financial/moeda/MoedaRegistrationPage';
import ModeloFaturamentoConsultPage from '../pages/module/registrations/general/modelo-faturamento/ModeloFaturamentoConsultPage';
import ModeloFaturamentoPage from '../pages/module/registrations/general/modelo-faturamento/ModeloFaturamentoPage';
import ModeloFaturamentoRegistrationPage from '../pages/module/registrations/general/modelo-faturamento/ModeloFaturamentoRegistrationPage';
import ModeloNumerarioConsultPage from '../pages/module/registrations/general/modelo-numerario/ModeloNumerarioConsultPage';
import ModeloNumerarioPage from '../pages/module/registrations/general/modelo-numerario/ModeloNumerarioPage';
import ModeloNumerarioRegistrationPage from '../pages/module/registrations/general/modelo-numerario/ModeloNumerarioRegistrationPage';
import ArmazemConsultPage from '../pages/module/registrations/operational/armazem/ArmazemConsultPage';
import ArmazemPage from '../pages/module/registrations/operational/armazem/ArmazemPage';
import ArmazemRegistrationPage from '../pages/module/registrations/operational/armazem/ArmazemRegistrationPage';
import CFOPOperacaoFiscalConsultPage from '../pages/module/registrations/operational/cfop-operacao-fiscal/cfopOperacaoFiscalConsultPage';
import CFOPOperacaoFiscalPage from '../pages/module/registrations/operational/cfop-operacao-fiscal/cfopOperacaoFiscalPage';
import CFOPOperacaoFiscalRegistrationPage from '../pages/module/registrations/operational/cfop-operacao-fiscal/cfopOperacaoFiscalRegistrationPage';
import ClienteConsultPage from '../pages/module/registrations/operational/cliente/ClienteConsultPage';
import ClientePage from '../pages/module/registrations/operational/cliente/ClientePage';
import ClienteRegistrationPage from '../pages/module/registrations/operational/cliente/ClienteRegistrationPage';
import EmbarcacaoConsultPage from '../pages/module/registrations/operational/embarcacao/EmbarcacaoConsultPage';
import EmbarcacaoPage from '../pages/module/registrations/operational/embarcacao/EmbarcacaoPage';
import EmbarcacaoRegistrationPage from '../pages/module/registrations/operational/embarcacao/EmbarcacaoRegistrationPage';
import EmpresaEstrangeiraConsultPage from '../pages/module/registrations/operational/empresa-estrangeira/EmpresaEstrangeiraConsultPage';
import EmpresaEstrangeiraPage from '../pages/module/registrations/operational/empresa-estrangeira/EmpresaEstrangeiraPage';
import EmpresaEstrangeiraRegistrationPage from '../pages/module/registrations/operational/empresa-estrangeira/EmpresaEstrangeiraRegistrationPage';
import EstadoConsultPage from '../pages/module/registrations/operational/estado/EstadoConsultPage';
import EstadoPage from '../pages/module/registrations/operational/estado/EstadoPage';
import EstadoRegistrationPage from '../pages/module/registrations/operational/estado/EstadoRegistrationPage';
import CidadeConsultPage from '../pages/module/registrations/operational/cidade/CidadeConsultPage';
import CidadePage from '../pages/module/registrations/operational/cidade/CidadePage';
import CidadeRegistrationPage from '../pages/module/registrations/operational/cidade/CidadeRegistrationPage';
import TaxaConversaoCambioConsultPage from '../pages/module/registrations/operational/taxa-conversao-cambio/TaxaConversaoCambioConsultPage';
import TaxaConversaoCambioPage from '../pages/module/registrations/operational/taxa-conversao-cambio/TaxaConversaoCambioPage';
import TaxaConversaoCambioRegistrationPage from '../pages/module/registrations/operational/taxa-conversao-cambio/TaxaConversaoCambioRegistrationPage';
import TabelaArmazenagemConsultPage from '../pages/module/registrations/operational/tabela-armazenagem/TabelaArmazenagemConsultPage';
import TabelaArmazenagemPage from '../pages/module/registrations/operational/tabela-armazenagem/TabelaArmazenagemPage';
import TabelaArmazenagemRegistrationPage from '../pages/module/registrations/operational/tabela-armazenagem/TabelaArmazenagemRegistrationPage';
import UnidadeDeMedidaConsultPage from '../pages/module/registrations/operational/unidade-medida/UnidadeDeMedidaConsultPage';
import UnidadeDeMedidaPage from '../pages/module/registrations/operational/unidade-medida/UnidadeDeMedidaPage';
import UnidadeDeMedidaRegistrationPage from '../pages/module/registrations/operational/unidade-medida/UnidadeDeMedidaRegistrationPage';
import CatalogoProdutosPage from '../pages/module/registrations/operational/catalogo-produtos/CatalogoProdutosPage';
import CatalogoProdutosConsultPage from '../pages/module/registrations/operational/catalogo-produtos/CatalogoProdutosConsultPage';
import CatalogoProdutosRegistrationPage from '../pages/module/registrations/operational/catalogo-produtos/CatalogoProdutosRegistrationPage';
import CotacaoMoedaConsultPage from '../pages/module/registrations/financial/cotacao-moeda/CotacaoMoedaConsultPage';
import CotacaoMoedaRegistrationPage from '../pages/module/registrations/financial/cotacao-moeda/CotacaoMoedaRegistrationPage';
import TabelaSDAPage from '../pages/module/registrations/general/tabela-sda/TabelaSDAPage';
import TabelaSDAConsultPage from '../pages/module/registrations/general/tabela-sda/TabelaSDAConsultPage';
import TabelaSDARegistrationPage from '../pages/module/registrations/general/tabela-sda/TabelaSDARegistrationPage';
import {
  ServicoPage,
  ServicoConsultPage,
  ServicoRegistrationPage,
} from '../pages/module/registrations/general/servico';
import {
  GrupoUnidadeNegocioPage,
  GrupoUnidadeNegocioConsultPage,
  GrupoUnidadeNegocioRegistrationPage,
} from '../pages/module/registrations/general/grupo-unidade-negocio';
import CotacaoMoedaPage from '../pages/module/registrations/financial/cotacao-moeda/CotacaoMoedaPage';
import {
  UnidadeNegocioConsultPage,
  UnidadeNegocioPage,
  UnidadeNegocioRegistrationPage,
} from '../pages/module/registrations/general/unidade-negocio';
import {
  TipoDocumentoConsultPage,
  TipoDocumentoPage,
  TipoDocumentoRegistrationPage,
} from '../pages/module/registrations/general/tipo-documento';
import {
  NcmSubitemPage,
  NcmSubitemConsultPage,
  NcmSubitemRegistrationPage,
} from '../pages/module/registrations/operational/ncm-subitem';
import {
  DespachanteConsultPage,
  DespachantePage,
  DespachanteRegistrationPage,
} from '../pages/module/registrations/operational/despachante';
import {
  ModeloFollowUpConsultPage,
  ModeloFollowUpPage,
  ModeloFollowUpRegistrationPage,
} from '../pages/module/registrations/general/modelo-follow-up';
import { EtapaConsultPage, EtapaPage, EtapaRegistrationPage } from '../pages/module/registrations/general/etapa';
import PlanoContaConsultPage from '../pages/module/registrations/financial/plano-conta/PlanoContaConsultPage';
import PlanoContaRegistrationPage from '../pages/module/registrations/financial/plano-conta/PlanoContaRegistrationPage';
import PlanoContaPage from '../pages/module/registrations/financial/plano-conta/PlanoContaPage';
import PerfilPage from '../pages/module/registrations/general/perfil/PerfilPage';
import PerfilConsultPage from '../pages/module/registrations/general/perfil/PerfilConsultPage';
import PerfilRegistrationPage from '../pages/module/registrations/general/perfil/PerfilRegistrationPage';
import {
  ModeloInformacoesComplementaresConsultPage,
  ModeloInformacoesComplementaresPage,
  ModeloInformacoesComplementaresRegistrationPage,
} from '../pages/module/registrations/operational/modelo-informacoes-complementares';
import {
  DespesaReceitaPage,
  DespesaReceitaConsultPage,
  DespesaReceitaRegistrationPage,
} from '../pages/module/registrations/financial/despesa-receita';
import {
  FornecedorPage,
  FornecedorConsultPage,
  FornecedorRegistrationPage,
} from '../pages/module/registrations/financial/fornecedor';
import {
  TransportadorInternacionalConsultPage,
  TransportadorInternacionalPage,
} from '../pages/module/registrations/operational/transportador-internacional';
import {
  TransportadoraConsultPage,
  TransportadoraPage,
  TransportadoraRegistrationPage,
} from '../pages/module/registrations/operational/transportadora';
import {
  PortoAeroportoPage,
  PortoAeroportoRegistrationPage,
  PortoAeroportoConsultPage,
} from '../pages/module/registrations/operational/porto-aeroporto';
import {
  ContaBancariaPage,
  ContaBancariaConsultPage,
  ContaBancariaRegistrationPage,
} from '../pages/module/registrations/financial/conta-bancaria';
import UsuarioConsultPage from '../pages/module/registrations/general/usuario/UsuarioConsultPage';
import UsuarioRegistrationPage from '../pages/module/registrations/general/usuario/UsuarioRegistrationPage';
import UsuarioPage from '../pages/module/registrations/general/usuario/UsuarioPage';
import {
  DispositivoLegalPage,
  DispositivoLegalRegistrationPage,
  DispositivoLegalConsultPage,
} from '../pages/module/registrations/operational/dispositivo-legal';
import {
  FaturaConsultPage,
  FaturaPage,
  FaturaRegistrationPage,
  FaturaTransferManagerPage,
  FaturaReversalManagerPage,
} from '../pages/module/import/fatura';
import {
  LicencaImportacaoConsultPage,
  LicencaImportacaoPage,
  LicencaImportacaoRegistrationPage,
  LicencaImportacaoReversalManagerPage,
  LicencaImportacaoTransferManagerPage,
} from '../pages/module/import/licenca-importacao';
import {
  NumerarioImportacaoConsultPage,
  NumerarioImportacaoPage,
  NumerarioImportacaoRegistrationPage,
} from '../pages/module/import/numerario-importacao';
import {
  DeclaracaoImportacaoConsultPage,
  DeclaracaoImportacaoPage,
  DeclaracaoImportacaoRegistrationPage,
} from '../pages/module/import/declaracao-importacao';
import {
  FollowUpConsultPage,
  FollowUpPage,
  FollowUpRegistrationPage,
  FollowUpTimelineManagerPage,
} from '../pages/module/import/follow-up';
import { DanfePage, DanfeConsultPage, DanfeRegistrationPage } from '../pages/module/import/danfe';
import {
  ModeloDanfeConsultPage,
  ModeloDanfePage,
  ModeloDanfeRegistrationPage,
} from '../pages/module/registrations/operational/modelo-danfe';
import i18n from '../i18n';
import {
  FollowUpExportacaoConsultPage,
  FollowUpExportacaoPage,
  FollowUpExportacaoRegistrationPage,
  FollowUpExportacaoTimelineManagerPage,
} from '../pages/module/export/follow-up';

import {
  DeclaracaoUnicaExportacaoPage,
  DeclaracaoUnicaExportacaoConsultPage,
  DeclaracaoUnicaExportacaoRegistrationPage,
} from '../pages/module/export/declaracao-unica-exportacao';

import {
  SolicitacaoPagamentoPage,
  SolicitacaoPagamentoConsultPage,
  SolicitacaoPagamentoRegistrationPage,
} from '../pages/module/financial/solicitacao-pagamento';

import {
  AprovarRecusarPagamentoPage,
  AprovarRecusarPagamentoConsultPage,
} from '../pages/module/financial/aprovar-recusar-pagamento';

import {
  ContasPagarPage,
  ContasPagarConsultPage,
  ContasPagarDetailsPage,
} from '../pages/module/financial/contas-pagar';

import { ContasReceberPage, ContasReceberConsultPage } from '../pages/module/financial/contas-receber';

import {
  ConciliacaoBancariaPage,
  ConciliacaoBancariaConsultPage,
} from '../pages/module/financial/conciliacao-bancaria';

import {
  FaturamentoPage,
  FaturamentoConsultPage,
  FaturamentoRegistrationPage,
} from '../pages/module/financial/faturamento';
import FollowUpListManagerPage from '../pages/module/import/follow-up/FollowUpListManagerPage/FollowUpListManagerPage';
import OcorrenciaPage from '../pages/module/registrations/general/ocorrencias/OcorrenciaPage';
import OcorrenciaConsultPage from '../pages/module/registrations/general/ocorrencias/OcorrenciaConsultPage';
import OcorrenciaRegistrationPage from '../pages/module/registrations/general/ocorrencias/OcorrenciaRegistrationPage/OcorrenciaRegistrationPage';
import DesviosPage from '../pages/module/registrations/general/desvios/DesviosPage';
import DesviosConsultPage from '../pages/module/registrations/general/desvios/DesviosConsultPage';
import DesviosRegistrationPage from '../pages/module/registrations/general/desvios/DesviosRegistrationPage/DesviosRegistrationPage';
import CamposAdicionaisPage from '../pages/module/registrations/general/campos-adicionais/CamposAdicionaisPage';
import CamposAdicionaisConsultPage from '../pages/module/registrations/general/campos-adicionais/CamposAdicionaisConsultPage';
import CamposAdicionaisRegistrationPage from '../pages/module/registrations/general/campos-adicionais/CamposAdicionaisRegistrationPage/CamposAdicionaisRegistrationPage';
import {
  DadosEmbarqueConsultPage,
  DadosEmbarquePage,
  DadosEmbarqueRegistrationPage,
} from '../pages/module/import/dados-embarque';
import ExportacaoDadosEmbarqueConsultPage from '../pages/module/export/dados-embarque/DadosEmbarqueConsultPage/ExportacaoDadosEmbarqueConsultPage';
import ExportacaoDadosEmbarqueRegistrationPage from '../pages/module/export/dados-embarque/DadosEmbarqueRegistrationPage/ExportacaoDadosEmbarqueRegistrationPage';
import CusteiosPage from '../ts/pages/importacao/custeios/CusteiosPage.tsx';
import CusteiosConsultPage from '../ts/pages/importacao/custeios/CusteiosConsultPage/CusteiosConsultPage.tsx';
import CusteiosRegistrationPage from '../ts/pages/importacao/custeios/CusteiosRegistrationPage/CusteiosRegistrationPage.tsx';
import TransferenciaLoteManagerPage from '../pages/module/import/transferencia-lote/TransferenciaLoteManagerPage/TransferenciaLoteManagerPage.tsx';
import TransferenciaLotePage from '../pages/module/import/transferencia-lote/TransferenciaLotePage';
import NumerarioExportPage from '../pages/module/export/numerario-importacao/NumerarioExportacaoPage';
import { NumerarioExportacaoConsultPage } from '../pages/module/export/numerario-importacao';
import NumerarioExportRegistrationPage from '../pages/module/export/numerario-importacao/NumerarioExportacaoRegistrationPage';
import ExpoTransferenciaLoteManagerPage from '../pages/module/export/transferencia-lote/ExpoTransferenciaLoteManagerPage';
import CatalogoProdutosFiltroRepassePage from '../pages/module/registrations/operational/catalogo-produtos/CatalogoProdutosFiltroRepassePage/CatalogoProdutosFiltroRepassePage';
import CatalogoProdutosLogsPage from '../pages/module/registrations/operational/catalogo-produtos/CatalogoProdutosLogsPage/CatalogoProdutosLogsPage';

const SEPARATOR = '/';

const composedRegistrationRoutes = {
  base: i18n.t('com.muralis.qcx.routes.cadastros'),
  routes: [
    {
      container: UnidadeNegocioPage,
      role: i18n.t('com.muralis.qcx.routes.unidadeNegocioVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.gerais'), i18n.t('com.muralis.qcx.routes.unidadesNegocio')],
          component: UnidadeNegocioConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.unidadesNegocio'),
            i18n.t('com.muralis.qcx.routes.nova'),
          ],
          component: UnidadeNegocioRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.unidadesNegocio'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: UnidadeNegocioRegistrationPage,
        },
      ],
    },
    {
      container: UsuarioPage,
      role: i18n.t('com.muralis.qcx.routes.usuarioVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.gerais'), i18n.t('com.muralis.qcx.routes.usuarios')],
          component: UsuarioConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.usuarios'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: UsuarioRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.usuarios'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: UsuarioRegistrationPage,
        },
      ],
    },
    {
      container: TipoDocumentoPage,
      role: i18n.t('com.muralis.qcx.routes.tipoDocumentoVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.gerais'), i18n.t('com.muralis.qcx.routes.tipoDocumentos')],
          component: TipoDocumentoConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.tipoDocumentos'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: TipoDocumentoRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.tipoDocumentos'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: TipoDocumentoRegistrationPage,
        },
      ],
    },
    {
      container: ServicoPage,
      role: i18n.t('com.muralis.qcx.routes.servicoVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.gerais'), i18n.t('com.muralis.qcx.routes.servicos')],
          component: ServicoConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: ServicoRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: ServicoRegistrationPage,
        },
      ],
    },
    {
      container: GrupoUnidadeNegocioPage,
      role: i18n.t('com.muralis.qcx.routes.grupoVisualizar'),
      // role: i18n.t('com.muralis.qcx.routes.servicoVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.gerais'), i18n.t('com.muralis.qcx.routes.gruposUnidadeNegocio')],
          component: GrupoUnidadeNegocioConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.gruposUnidadeNegocio'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: GrupoUnidadeNegocioRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.gruposUnidadeNegocio'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: GrupoUnidadeNegocioRegistrationPage,
        },
      ],
    },
    {
      container: ModeloDanfePage,
      role: i18n.t('com.muralis.qcx.routes.modeloDANFEVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.operacionais'), i18n.t('com.muralis.qcx.routes.modeloDANFE')],
          component: ModeloDanfeConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.modeloDANFE'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: ModeloDanfeRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.modeloDANFE'),
            i18n.t('com.muralis.qcx.routes.editar'),
            i18n.t('com.muralis.qcx.routes.id'),
          ],
          component: ModeloDanfeRegistrationPage,
        },
      ],
    },
    {
      container: ModeloFaturamentoPage,
      role: i18n.t('com.muralis.qcx.routes.modeloFaturamentoVisualizar'),
      subcontainers: [
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.modelosFaturamento'),
          ],
          component: ModeloFaturamentoConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.modelosFaturamento'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: ModeloFaturamentoRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.modelosFaturamento'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: ModeloFaturamentoRegistrationPage,
        },
      ],
    },
    {
      container: ModeloFollowUpPage,
      role: i18n.t('com.muralis.qcx.routes.modeloFollowUPVisualizar'),
      subcontainers: [
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.modelosFollowUp'),
          ],
          component: ModeloFollowUpConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.modelosFollowUp'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: ModeloFollowUpRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.modelosFollowUp'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: ModeloFollowUpRegistrationPage,
        },
      ],
    },
    {
      container: ModeloNumerarioPage,
      role: i18n.t('com.muralis.qcx.routes.modeloFaturamentoVisualizar'),
      subcontainers: [
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.modelosNumerario'),
          ],
          component: ModeloNumerarioConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.modelosNumerario'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: ModeloNumerarioRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.modelosNumerario'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: ModeloNumerarioRegistrationPage,
        },
      ],
    },
    {
      container: TabelaSDAPage,
      role: i18n.t('com.muralis.qcx.routes.tabelaSDAVizualizar'),
      subcontainers: [
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.tabelasSDA'),
          ],
          component: TabelaSDAConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.tabelasSDA'),
            i18n.t('com.muralis.qcx.routes.nova'),
          ],
          component: TabelaSDARegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.tabelasSDA'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: TabelaSDARegistrationPage,
        },
      ],
    },
    {
      container: EtapaPage,
      role: i18n.t('com.muralis.qcx.routes.etapaVisualizar'),
      subcontainers: [
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.etapas'),
          ],
          component: EtapaConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.etapas'),
            i18n.t('com.muralis.qcx.routes.nova'),
          ],
          component: EtapaRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.etapas'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: EtapaRegistrationPage,
        },
      ],
    },
    {
      container: OcorrenciaPage,
      role: i18n.t('com.muralis.qcx.routes.etapaVisualizar'),
      subcontainers: [
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.ocorrencias'),
          ],
          component: OcorrenciaConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.ocorrencias'),
            i18n.t('com.muralis.qcx.routes.nova'),
          ],
          component: OcorrenciaRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.ocorrencias'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: OcorrenciaRegistrationPage,
        },
      ],
    },
    {
      container: CamposAdicionaisPage,
      role: i18n.t('com.muralis.qcx.routes.etapaVisualizar'),
      subcontainers: [
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.camposAdicionais'),
          ],
          component: CamposAdicionaisConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.camposAdicionais'),
            i18n.t('com.muralis.qcx.routes.nova'),
          ],
          component: CamposAdicionaisRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.camposAdicionais'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: CamposAdicionaisRegistrationPage,
        },
      ],
    },
    {
      container: DesviosPage,
      role: i18n.t('com.muralis.qcx.routes.etapaVisualizar'),
      subcontainers: [
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.desvios'),
          ],
          component: DesviosConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.desvios'),
            i18n.t('com.muralis.qcx.routes.nova'),
          ],
          component: DesviosRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.servicos'),
            i18n.t('com.muralis.qcx.routes.desvios'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: DesviosRegistrationPage,
        },
      ],
    },
    {
      container: EmpresaEstrangeiraPage,
      role: i18n.t('com.muralis.qcx.routes.exportadorFabricanteVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.operacionais'), i18n.t('com.muralis.qcx.routes.empresasEstrangeiras')],
          component: EmpresaEstrangeiraConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.empresasEstrangeiras'),
            i18n.t('com.muralis.qcx.routes.nova'),
          ],
          component: EmpresaEstrangeiraRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.empresasEstrangeiras'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: EmpresaEstrangeiraRegistrationPage,
        },
      ],
    },
    {
      container: EstadoPage,
      role: 'estado-cadastro-visualizar',
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.operacionais'), i18n.t('com.muralis.qcx.routes.estados')],
          component: EstadoConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.estados'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: EstadoRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.estados'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: EstadoRegistrationPage,
        },
      ],
    },
    {
      container: CidadePage,
      role: 'cidade-cadastro-visualizar',
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.operacionais'), i18n.t('com.muralis.qcx.routes.cidades')],
          component: CidadeConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.cidades'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: CidadeRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.cidades'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: CidadeRegistrationPage,
        },
      ],
    },
    {
      container: TaxaConversaoCambioPage,
      role: 'taxa-conversao-cambio-visualizar',
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.operacionais'), i18n.t('com.muralis.qcx.routes.taxaConversaoCambio')],
          component: TaxaConversaoCambioConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.taxaConversaoCambio'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: TaxaConversaoCambioRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.taxaConversaoCambio'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: TaxaConversaoCambioRegistrationPage,
        },
      ],
    },
    {
      container: EmbarcacaoPage,
      role: i18n.t('com.muralis.qcx.routes.embarcacaoVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.operacionais'), i18n.t('com.muralis.qcx.routes.embarcacao')],
          component: EmbarcacaoConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.embarcacao'),
            i18n.t('com.muralis.qcx.routes.nova'),
          ],
          component: EmbarcacaoRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.embarcacoes'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: EmbarcacaoRegistrationPage,
        },
      ],
    },
    {
      container: CFOPOperacaoFiscalPage,
      role: i18n.t('com.muralis.qcx.routes.CFOPVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.operacionais'), i18n.t('com.muralis.qcx.routes.CFOP')],
          component: CFOPOperacaoFiscalConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.CFOP'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: CFOPOperacaoFiscalRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.CFOPs'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: CFOPOperacaoFiscalRegistrationPage,
        },
      ],
    },
    {
      container: UnidadeDeMedidaPage,
      role: i18n.t('com.muralis.qcx.routes.unidadeMedidaVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.operacionais'), i18n.t('com.muralis.qcx.routes.unidadeMedida')],
          component: UnidadeDeMedidaConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.unidadeMedida'),
            i18n.t('com.muralis.qcx.routes.nova'),
          ],
          component: UnidadeDeMedidaRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.unidadesMedida'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: UnidadeDeMedidaRegistrationPage,
        },
      ],
    },
    {
      container: NcmSubitemPage,
      role: i18n.t('com.muralis.qcx.routes.tabelaArmazenagemVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.operacionais'), i18n.t('com.muralis.qcx.routes.NCMnivel6')],
          component: NcmSubitemConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.NCMnivel6'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: NcmSubitemRegistrationPage,
        },
      ],
    },
    {
      container: ArmazemPage,
      role: i18n.t('com.muralis.qcx.routes.armazemVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.operacionais'), i18n.t('com.muralis.qcx.routes.armazem')],
          component: ArmazemConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.armazem'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: ArmazemRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.armazens'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: ArmazemRegistrationPage,
        },
      ],
    },

    {
      container: TabelaArmazenagemPage,
      role: i18n.t('com.muralis.qcx.routes.tabelaArmazenagemVisualizar'),
      subcontainers: [
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.armazens'),
            i18n.t('com.muralis.qcx.routes.tabelasArmazenagem'),
          ],
          component: TabelaArmazenagemConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.armazens'),
            i18n.t('com.muralis.qcx.routes.tabelasArmazenagem'),
            i18n.t('com.muralis.qcx.routes.nova'),
          ],
          component: TabelaArmazenagemRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.armazens'),
            i18n.t('com.muralis.qcx.routes.tabelasArmazenagem'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: TabelaArmazenagemRegistrationPage,
        },
      ],
    },
    {
      container: CatalogoProdutosPage,
      role: i18n.t('com.muralis.qcx.routes.catalogoProdutosVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.operacionais'), i18n.t('com.muralis.qcx.routes.catalogoProdutos')],
          component: CatalogoProdutosConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.catalogoProdutos'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: CatalogoProdutosRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            'catalogos-produtos',
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: CatalogoProdutosRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.catalogoProdutos'),
            i18n.t('com.muralis.qcx.routes.filtroRepasse'),
          ],
          component: CatalogoProdutosFiltroRepassePage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            'catalogos-produtos',
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.logs'),
          ],
          component: CatalogoProdutosLogsPage,
        },
      ],
    },
    {
      container: ModeloInformacoesComplementaresPage,
      role: i18n.t('com.muralis.qcx.routes.modeloInformacoesComplementaresVisualizar'),
      subcontainers: [
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.modelosInformacoesComplementares'),
          ],
          component: ModeloInformacoesComplementaresConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.modelosInformacoesComplementares'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: ModeloInformacoesComplementaresRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.modelosInformacoesComplementares'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: ModeloInformacoesComplementaresRegistrationPage,
        },
      ],
    },
    {
      container: ClientePage,
      role: i18n.t('com.muralis.qcx.routes.clienteVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.operacionais'), i18n.t('com.muralis.qcx.routes.cliente')],
          component: ClienteConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.cliente'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: ClienteRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.clientes'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: ClienteRegistrationPage,
        },
      ],
    },
    {
      container: DespachantePage,
      role: i18n.t('com.muralis.qcx.routes.despachanteVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.operacionais'), i18n.t('com.muralis.qcx.routes.despachantes')],
          component: DespachanteConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.despachantes'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: DespachanteRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.despachantes'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: DespachanteRegistrationPage,
        },
      ],
    },
    {
      container: DespesaReceitaPage,
      role: i18n.t('com.muralis.qcx.routes.despesaReceitaVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.financeiros'), i18n.t('com.muralis.qcx.routes.despesasReceitas')],
          component: DespesaReceitaConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.financeiros'),
            i18n.t('com.muralis.qcx.routes.despesasReceitas'),
            i18n.t('com.muralis.qcx.routes.nova'),
          ],
          component: DespesaReceitaRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.financeiros'),
            i18n.t('com.muralis.qcx.routes.despesasReceitas'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: DespesaReceitaRegistrationPage,
        },
      ],
    },
    {
      container: MoedaPage,
      role: i18n.t('com.muralis.qcx.routes.moedaVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.financeiros'), i18n.t('com.muralis.qcx.routes.moeda')],
          component: MoedaConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.financeiros'),
            i18n.t('com.muralis.qcx.routes.moeda'),
            i18n.t('com.muralis.qcx.routes.nova'),
          ],
          component: MoedaRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.financeiros'),
            i18n.t('com.muralis.qcx.routes.moedas'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: MoedaRegistrationPage,
        },
      ],
    },
    {
      container: CotacaoMoedaPage,
      role: i18n.t('com.muralis.qcx.routes.cotacaoVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.financeiros'), i18n.t('com.muralis.qcx.routes.cotacoesMoeda')],
          component: CotacaoMoedaConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.financeiros'),
            i18n.t('com.muralis.qcx.routes.cotacoesMoeda'),
            i18n.t('com.muralis.qcx.routes.nova'),
          ],
          component: CotacaoMoedaRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.financeiros'),
            i18n.t('com.muralis.qcx.routes.cotacoesMoeda'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: CotacaoMoedaRegistrationPage,
        },
      ],
    },
    {
      container: ContaBancariaPage,
      role: i18n.t('com.muralis.qcx.routes.contaBancariaVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.financeiros'), i18n.t('com.muralis.qcx.routes.contasBancarias')],
          component: ContaBancariaConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.financeiros'),
            i18n.t('com.muralis.qcx.routes.contasBancarias'),
            i18n.t('com.muralis.qcx.routes.nova'),
          ],
          component: ContaBancariaRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.financeiros'),
            i18n.t('com.muralis.qcx.routes.contasBancarias'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: ContaBancariaRegistrationPage,
        },
      ],
    },
    {
      container: PlanoContaPage,
      role: i18n.t('com.muralis.qcx.routes.planoContaVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.financeiros'), i18n.t('com.muralis.qcx.routes.planoContas')],
          component: PlanoContaConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.financeiros'),
            i18n.t('com.muralis.qcx.routes.planoContas'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: PlanoContaRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.financeiros'),
            i18n.t('com.muralis.qcx.routes.planoContas'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: PlanoContaRegistrationPage,
        },
      ],
    },
    {
      container: FornecedorPage,
      role: i18n.t('com.muralis.qcx.routes.fornecedorVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.financeiros'), i18n.t('com.muralis.qcx.routes.fornecedor')],
          component: FornecedorConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.financeiros'),
            i18n.t('com.muralis.qcx.routes.fornecedor'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: FornecedorRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.financeiros'),
            i18n.t('com.muralis.qcx.routes.fornecedor'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: FornecedorRegistrationPage,
        },
      ],
    },
    {
      container: PerfilPage,
      role: i18n.t('com.muralis.qcx.routes.perfilVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.gerais'), i18n.t('com.muralis.qcx.routes.perfis')],
          component: PerfilConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.perfis'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: PerfilRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.gerais'),
            i18n.t('com.muralis.qcx.routes.perfis'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: PerfilRegistrationPage,
        },
      ],
    },
    {
      container: TransportadorInternacionalPage,
      role: i18n.t('com.muralis.qcx.routes.transportadorInternacionalVisualizar'),
      subcontainers: [
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.transportadorInternacional'),
          ],
          component: TransportadorInternacionalConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.transportadorInternacional'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: TransportadorInternacionalRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.transportadoresInternacionais'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: TransportadorInternacionalRegistrationPage,
        },
      ],
    },
    {
      container: TransportadoraPage,
      role: i18n.t('com.muralis.qcx.routes.transportadoraVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.operacionais'), i18n.t('com.muralis.qcx.routes.transportadoras')],
          component: TransportadoraConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.transportadoras'),
            i18n.t('com.muralis.qcx.routes.nova'),
          ],
          component: TransportadoraRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.transportadoras'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: TransportadoraRegistrationPage,
        },
      ],
    },
    {
      container: BancoPage,
      role: i18n.t('com.muralis.qcx.routes.bancoVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.financeiros'), i18n.t('com.muralis.qcx.routes.banco')],
          component: BancoConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.financeiros'),
            i18n.t('com.muralis.qcx.routes.banco'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: BancoRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.financeiros'),
            i18n.t('com.muralis.qcx.routes.bancos'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: BancoRegistrationPage,
        },
      ],
    },
    {
      container: PortoAeroportoPage,
      role: i18n.t('com.muralis.qcx.routes.portoAeroportoVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.operacionais'), i18n.t('com.muralis.qcx.routes.portoAeroporto')],
          component: PortoAeroportoConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.portoAeroporto'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: PortoAeroportoRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.portosAeroportos'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: PortoAeroportoRegistrationPage,
        },
      ],
    },
    {
      container: DispositivoLegalPage,
      role: i18n.t('com.muralis.qcx.routes.dispositivoLegalVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.operacionais'), i18n.t('com.muralis.qcx.routes.dispositivoLegal')],
          component: DispositivoLegalConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.dispositivoLegal'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: DispositivoLegalRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.operacionais'),
            i18n.t('com.muralis.qcx.routes.dispositivosLegais'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: DispositivoLegalRegistrationPage,
        },
      ],
    },
  ],
};

const composedImportRoutes = {
  base: i18n.t('com.muralis.qcx.routes.importacao'),
  routes: [
    {
      container: FollowUpPage,
      role: i18n.t('com.muralis.qcx.routes.importacaoFollowUpVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.followUp')],
          component: FollowUpConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.followUp'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.timeline'),
          ],
          component: FollowUpTimelineManagerPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.followUp'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.lista'),
          ],
          component: FollowUpListManagerPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.followUp'),
            i18n.t('com.muralis.qcx.routes.registro'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: FollowUpRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.followUp'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: FollowUpRegistrationPage,
        },
      ],
    },
    {
      container: TransferenciaLotePage,
      role: i18n.t('com.muralis.qcx.routes.importacaoFollowUpVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.transferenciaLote')],
          component: TransferenciaLoteManagerPage,
        },
      ],
    },
    {
      container: DadosEmbarquePage,
      role: i18n.t('com.muralis.qcx.routes.dadosEmbarqueImportacaoVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.dadosEmbarque')],
          component: DadosEmbarqueConsultPage,
        },
        {
          path: [i18n.t('com.muralis.qcx.routes.dadosEmbarque'), i18n.t('com.muralis.qcx.routes.novo')],
          component: DadosEmbarqueRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.dadosEmbarque'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: DadosEmbarqueRegistrationPage,
        },
      ],
    },
    {
      container: FaturaPage,
      role: i18n.t('com.muralis.qcx.routes.importacaoFaturaVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.faturas')],
          component: FaturaConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.faturas'),
            i18n.t('com.muralis.qcx.routes.registroManual'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: FaturaRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.faturas'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: FaturaRegistrationPage,
        },
        {
          path: [i18n.t('com.muralis.qcx.routes.faturas'), i18n.t('com.muralis.qcx.routes.transferencia')],
          component: FaturaTransferManagerPage,
        },
        {
          path: [i18n.t('com.muralis.qcx.routes.faturas'), i18n.t('com.muralis.qcx.routes.estorno')],
          component: FaturaReversalManagerPage,
        },
      ],
    },
    {
      container: LicencaImportacaoPage,
      role: i18n.t('com.muralis.qcx.routes.importacaoLILPCOVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.licencasImportacao')],
          component: LicencaImportacaoConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.licencasImportacao'),
            i18n.t('com.muralis.qcx.routes.registroManual'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: LicencaImportacaoRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.licencasImportacao'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: LicencaImportacaoRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.licencasImportacao'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.origem'),
            i18n.t('com.muralis.qcx.routes.origemId'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: LicencaImportacaoRegistrationPage,
        },
        {
          path: [i18n.t('com.muralis.qcx.routes.licencasImportacao'), i18n.t('com.muralis.qcx.routes.transferencia')],
          component: LicencaImportacaoTransferManagerPage,
        },
        {
          path: [i18n.t('com.muralis.qcx.routes.licencasImportacao'), i18n.t('com.muralis.qcx.routes.estorno')],
          component: LicencaImportacaoReversalManagerPage,
        },
      ],
    },
    {
      container: DeclaracaoImportacaoPage,
      role: i18n.t('com.muralis.qcx.routes.importacaoDIDUIMPVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.declaracoesImportacao')],
          component: DeclaracaoImportacaoConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.declaracoesImportacao'),
            i18n.t('com.muralis.qcx.routes.registroManual'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: DeclaracaoImportacaoRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.declaracoesImportacao'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: DeclaracaoImportacaoRegistrationPage,
        },
      ],
    },
    {
      container: DanfePage,
      role: i18n.t('com.muralis.qcx.routes.DANFEVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.DANFE')],
          component: DanfeConsultPage,
        },
        {
          path: [i18n.t('com.muralis.qcx.routes.DANFE'), i18n.t('com.muralis.qcx.routes.novo')],
          component: DanfeRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.DANFE'),
            i18n.t('com.muralis.qcx.routes.editar'),
            i18n.t('com.muralis.qcx.routes.id'),
          ],
          component: DanfeRegistrationPage,
        },
      ],
    },
    {
      container: NumerarioImportacaoPage,
      role: 'importacao-solicitacao-numerario-visualizar',
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.numerarios')],
          component: NumerarioImportacaoConsultPage,
        },
        {
          path: [i18n.t('com.muralis.qcx.routes.numerarios'), i18n.t('com.muralis.qcx.routes.novo')],
          component: NumerarioImportacaoRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.numerarios'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: NumerarioImportacaoRegistrationPage,
        },
      ],
    },
    {
      container: CusteiosPage,
      role: 'importacao-custeio-visualizar',
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.custeios')],
          component: CusteiosConsultPage,
        },
        {
          path: [i18n.t('com.muralis.qcx.routes.custeios'), i18n.t('com.muralis.qcx.routes.novo')],
          component: CusteiosRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.custeios'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: CusteiosRegistrationPage,
        },
      ],
    },
  ],
};

const composedExportRoutes = {
  base: i18n.t('com.muralis.qcx.routes.exportacao'),
  routes: [
    {
      container: FollowUpExportacaoPage,
      role: 'exportacao-follow-up-visualizar',
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.followUp')],
          component: FollowUpExportacaoConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.followUp'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.timeline'),
          ],
          component: FollowUpExportacaoTimelineManagerPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.followUp'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.lista'),
          ],
          component: FollowUpListManagerPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.followUp'),
            i18n.t('com.muralis.qcx.routes.registro'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: FollowUpExportacaoRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.followUp'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: FollowUpExportacaoRegistrationPage,
        },
      ],
    },
    {
      container: NumerarioExportPage,
      role: 'importacao-solicitacao-numerario-visualizar', // TODO: Alterar Role
      subcontainers: [
        {
          path: ['numerario'],
          component: NumerarioExportacaoConsultPage,
        },
        {
          path: ['numerario', i18n.t('com.muralis.qcx.routes.novo')],
          component: NumerarioExportRegistrationPage,
        },
        {
          path: ['numerario', i18n.t('com.muralis.qcx.routes.id'), i18n.t('com.muralis.qcx.routes.detalhes')],
          component: NumerarioExportRegistrationPage,
        },
      ],
    },
    {
      container: TransferenciaLotePage,
      role: i18n.t('com.muralis.qcx.routes.importacaoFollowUpVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.transferenciaLote')],
          component: ExpoTransferenciaLoteManagerPage,
        },
      ],
    },
    {
      container: DadosEmbarquePage,
      role: i18n.t('com.muralis.qcx.routes.dadosEmbarqueExportacaoVisualizar'),
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.dadosEmbarque')],
          component: ExportacaoDadosEmbarqueConsultPage,
        },
        {
          path: [i18n.t('com.muralis.qcx.routes.dadosEmbarque'), i18n.t('com.muralis.qcx.routes.novo')],
          component: ExportacaoDadosEmbarqueRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.dadosEmbarque'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: ExportacaoDadosEmbarqueRegistrationPage,
        },
      ],
    },
    {
      container: DeclaracaoUnicaExportacaoPage,
      role: 'exportacao-due-visualizar',
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.due')],
          component: DeclaracaoUnicaExportacaoConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.due'),
            i18n.t('com.muralis.qcx.routes.registro'),
            i18n.t('com.muralis.qcx.routes.nova'),
          ],
          component: DeclaracaoUnicaExportacaoRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.due'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: DeclaracaoUnicaExportacaoRegistrationPage,
        },
      ],
    },
  ],
};

const composedFinancialRoutes = {
  base: i18n.t('com.muralis.qcx.routes.financeiro'),
  routes: [
    {
      container: SolicitacaoPagamentoPage,
      role: 'solicitacao-pagamento-visualizar',
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.solicitacaoPagamento')],
          component: SolicitacaoPagamentoConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.solicitacaoPagamento'),
            i18n.t('com.muralis.qcx.routes.registro'),
            i18n.t('com.muralis.qcx.routes.nova'),
          ],
          component: SolicitacaoPagamentoRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.solicitacaoPagamento'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: SolicitacaoPagamentoRegistrationPage,
        },
      ],
    },
    {
      container: AprovarRecusarPagamentoPage,
      role: 'aprovar-recusar-pagamento-visualizar',
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.aprovarRecusarPagamento')],
          component: AprovarRecusarPagamentoConsultPage,
        },
      ],
    },
    {
      container: ContasPagarPage,
      role: 'conta-pagar-visualizar',
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.contasPagar')],
          component: ContasPagarConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.contasPagar'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: ContasPagarDetailsPage,
        },
      ],
    },
    {
      container: ContasReceberPage,
      role: 'conta-receber-visualizar',
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.contasReceber')],
          component: ContasReceberConsultPage,
        },
      ],
    },
    {
      container: ConciliacaoBancariaPage,
      role: 'conciliacao-bancaria-visualizar',
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.conciliacaoBancaria')],
          component: ConciliacaoBancariaConsultPage,
        },
      ],
    },
    {
      container: FaturamentoPage,
      role: 'faturamento-visualizar',
      subcontainers: [
        {
          path: [i18n.t('com.muralis.qcx.routes.faturamento')],
          component: FaturamentoConsultPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.faturamento'),
            i18n.t('com.muralis.qcx.routes.registro'),
            i18n.t('com.muralis.qcx.routes.novo'),
          ],
          component: FaturamentoRegistrationPage,
        },
        {
          path: [
            i18n.t('com.muralis.qcx.routes.faturamento'),
            i18n.t('com.muralis.qcx.routes.id'),
            i18n.t('com.muralis.qcx.routes.detalhes'),
          ],
          component: FaturamentoRegistrationPage,
        },
      ],
    },
  ],
};

export default function useComposedRoutes() {
  const mapTo = ({ base, routes }) =>
    routes.map(({ container, subcontainers, role }) => ({
      container,
      role,
      subcontainers: subcontainers.map(({ path, component }) => ({
        path: SEPARATOR.concat([base, ...path].join(SEPARATOR)),
        component,
      })),
    }));

  return [
    ...mapTo(composedRegistrationRoutes),
    ...mapTo(composedImportRoutes),
    ...mapTo(composedExportRoutes),
    ...mapTo(composedFinancialRoutes),
  ];
}
