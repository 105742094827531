import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import {
  fetchAllAsync,
  selectConfigControl,
  selectTransportadoras,
} from '../../features/transportadora/transportadoraSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import { isAutoLoadControl } from '../../utils/store/store-utils';

export default function QCXSelectTransportadoraAutocomplete({ id, key, label, name, ...restProps }) {
  const dispatch = useDispatch();

  const { values } = useFormState();

  const list = useSelector(selectTransportadoras);
  const configControl = useSelector(selectConfigControl);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const handleFetch = () => {
      if (isAutoLoadControl(configControl)) {
        dispatch(fetchAllAsync());
      }
    };

    handleFetch();
  }, [configControl]);

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(values, name)?.id);

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, values]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.id} - ${item?.description}`,
  });

  return (
    <QCXAutocompleteSelect
      id={id}
      key={key}
      label={label}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionValue={(item) => item}
      getOptionLabel={(item) => (item?.id && item?.description ? `${item?.id} - ${item?.description}` : '')}
      {...restProps}
    />
  );
}
