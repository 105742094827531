import React, { useCallback } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

export default function QCXScrollbar(props) {
  const renderThumb = useCallback(({ style, ...restThumbProps }) => {
    const thumbStyle = {
      backgroundColor: '#00000033',
      borderRadius: 'inherit',
    };

    return (
      <div
        style={{
          ...style,
          ...thumbStyle,
        }}
        {...restThumbProps}
      />
    );
  }, []);
  return <Scrollbars renderThumbHorizontal={renderThumb} renderThumbVertical={renderThumb} {...props} />;
}
