import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXSelectCFOPOperacoesFiscaisAutocomplete from '../../shared-components/final-autocomplete-select-cfop-operacoes-fiscais-field/QCXSelectCFOPOperacoesFiscaisAutocomplete';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXSelectMoedaAutocomplete from '../../shared-components/final-select-moeda-field/QCXSelectMoedaAutocomplete';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXFinalLotesBondManager from './FaturaMercadoriaForm/QCXFinalLotesBondManager';

export default function QCXFaturaAdicionaisFormGroup({ modes }) {
  const { t } = useTranslation();

  const { isConsult, isSubConsult, isSubNone } = modes;

  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-numero-ato-concessorio"
          key="text-field-numero-ato-concessorio"
          name="adicionais.numeroAtoConcessorio"
          label={t('com.muralis.qcx.numeroAtoConcessorio')}
          disabled={isConsult || isSubConsult || isSubNone}
          maxLength={11}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-item-ato-concessorio"
          key="text-field-item-ato-concessorio"
          name="adicionais.itemAtoConcessorio"
          label={t('com.muralis.qcx.fatura.itemAtoConcessorio')}
          disabled={isConsult || isSubConsult || isSubNone}
          maxLength={3}
        />
      </Grid>
      <Grid item container xs={12} sm={6} md={6} lg={6}>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalTextField
            id="text-field-marca"
            key="text-field-marca"
            name="adicionais.marca"
            label={t('com.muralis.qcx.marca')}
            disabled={isConsult || isSubConsult || isSubNone}
            maxLength={20}
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalTextField
            id="text-field-modelo"
            key="text-field-modelo"
            name="adicionais.modelo"
            label={t('com.muralis.qcx.modelo')}
            disabled={isConsult || isSubConsult || isSubNone}
            maxLength={20}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={6} md={6} lg={6}>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalTextField
            id="text-field-numero-serie"
            key="text-field-numero-serie"
            name="adicionais.numeroSerie"
            label={t('com.muralis.qcx.numeroSerie')}
            disabled={isConsult || isSubConsult || isSubNone}
            maxLength={20}
          />
        </Grid>
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXFinalDatePickerField
            id="date-picker-ano-fabricacao-field"
            key="date-picker-ano-fabricacao-field"
            name="adicionais.anoFabricacao"
            label={t('com.muralis.qcx.anoFabricacao')}
            views={['year']}
            format="YYYY"
            placeholder="YYYY"
            disabled={isConsult || isSubConsult || isSubNone}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXSelectCFOPOperacoesFiscaisAutocomplete
          id="text-cfop-operacoes-fiscais-field"
          key="text-cfop-operacoes-fiscais-field"
          name="operacaoFiscal"
          label={t('com.muralis.qcx.CFOP.labelExtenso')}
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-area"
          key="text-field-area"
          name="area"
          label={t('com.muralis.qcx.area')}
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-numero-pedido"
          key="text-field-numero-pedido"
          name="numeroPedido"
          label={t('com.muralis.qcx.numeroPedido')}
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-sequencia-pedido"
          key="text-field-sequencia-pedido"
          name="sequenciaPedido"
          label={t('com.muralis.qcx.sequenciaPedido')}
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-numero-ordem-compra"
          key="text-field-numero-ordem-compra"
          name="numeroOrdemCompra"
          label={t('com.muralis.qcx.numeroOrdemCompra')}
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-sequencia-ordem-compra"
          key="text-field-sequencia-ordem-compra"
          name="sequenciaOrdemCompra"
          label={t('com.muralis.qcx.sequenciaOrdemCompra')}
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
      <Grid item container alignItems="center" xs={12} sm={12} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-descricao"
          key="text-field-descricao"
          name="gtin"
          label="GTIN"
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
      <Grid item container alignItems="center" xs={12} sm={12} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-descricao"
          key="text-field-descricao"
          name="codigoSecundario"
          label="Código Secundário"
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
      <Grid item container alignItems="center" xs={12} sm={12} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-descricao"
          key="text-field-descricao"
          name="auxiliarInvoice"
          label="Auxiliar Invoice"
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
      <Grid item container alignItems="center" xs={12} sm={12} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-descricao"
          key="text-field-descricao"
          name="sequenciaInvoice"
          label="Sequência Invoice"
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>

      {/* Data Invoice */}
      <Grid item container alignItems="center" xs={12} sm={12} md={6} lg={6}>
        <QCXFinalDatePickerField
          id="date-picker-data-invoice-field"
          key="date-picker-data-invoice-field"
          name="dataInvoice"
          label="Data Invoice"
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>

      {/* Moeda Fatura Original */}
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <QCXSelectMoedaAutocomplete
          id="text-field-descricao"
          key="text-field-descricao"
          name="moedaFaturaOriginal.id"
          label="Moeda Fatura Original"
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>

      <Grid item container alignItems="center" xs={12} sm={12} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-descricao"
          key="text-field-descricao"
          name="serie"
          label="Série"
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>

      <Grid item container alignItems="center" xs={12} sm={12} md={6} lg={6}>
        <QCXFinalTextField
          id="text-field-numero-original"
          key="text-field-numero-original"
          name="numeroFaturaOriginal"
          label="Número Fatura Original"
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
    </>
  );
}
