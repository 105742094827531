import { useTranslation } from "react-i18next";
import PageLayout from "../../../common/layouts/pageLayout";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useDispatch, useSelector } from "react-redux";
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from "react-router";
import { Button, FormControl, Grid, TextField } from '@mui/material';
import FormHeaderMedium from "../../../common/components/forms/formHeaderMedium";
import SelectStyled from "../../../common/components/input/selectStyled";
import { useListClientes } from "../../../common/hooks/useListClientes";
import { SyntheticEvent, useEffect, useState } from "react";
import MultiSelectStyled from "../../../common/components/input/multiSelectStyled";
import {
    selectUnidadesDeNegocioAssociadas,
    selectUnidadeSelecionada,
} from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { useUnidadeNegocioGuard } from "../../../common/hooks/useUnidadeNegocioGuard";
import { KEYCLOAK_TOKEN_TIMEOUT } from "../../../../App";
import { setErrorFeedback, setSuccessFeedback } from "../../../../features/feedback/feedbackSlice";
import { generateSpreadsheetGerencial } from "./relatorioGerencialPage.helpers";
import { makeStyles } from '@material-ui/core';
import { fetchAllAnalistas } from "../../../../features/usuario/usuarioAPI";
import { Analyst } from "../../../common/types/analyst";

interface ControlledValue {
    id: string | number;
    value: string;
}
const globalClient = {
    id: 0,
    value: "Global",
} as ControlledValue;
interface IUnidadesDeNegocioAssociadas {
    code: string;
    description: string;
    id: number;
    pessoa: {
        nome: string;
        nomeResumido: string;
    };
    sigla: string;
}
interface Datas {
    dataInicio: string;
    dataFim: string;
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        gap: '10px', // Ajuste o gap para controlar a distância entre os inputs
    },
    input: {
        width: '50%',
        marginRight: '1px', // Reduz a margem direita para aproximar os inputs
        borderRadius: '4px',
    },
    button: {
        borderRadius: '4px',
    },
});

const RelatorioGerencialPage = () => {
    const classes = useStyles();
    useUnidadeNegocioGuard();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const { token } = keycloak;
    keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

    const selectedUnit = useSelector(selectUnidadeSelecionada);
    const list: IUnidadesDeNegocioAssociadas[] = useSelector(selectUnidadesDeNegocioAssociadas);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedBusiness, setSelectedBusiness] = useState<ControlledValue>();

    const [clientes] = useListClientes();
    const [selectedClients, setSelectedClients] = useState<ControlledValue[]>([]);

    const [analyst, setAnalyst] = useState<ControlledValue[]>([]);
    const [analystList, setAnalystList] = useState<ControlledValue[]>([])

    const [datas, setDatas] = useState<Datas>({
        dataInicio: '',
        dataFim: '',
    });
    
    useEffect(() => {
        getAnalistas();
    }, [])

    async function getAnalistas() {
        let a = (await fetchAllAnalistas()).data;
        let list: any[] = a.map((elem: Analyst) => {
            return {
                id: elem.id,
                value: `${elem.nome} - ${elem.email}`
            }
        })
        setAnalystList(list);
    }

    const getIDs = (controlledClients: ControlledValue[]) => {
        let stringList = controlledClients.map((client: ControlledValue) => { return client.id.toString() })
        return stringList;
    }

    const getValues = (controlledClients: ControlledValue[]) => {
        let stringList = controlledClients.map((client: ControlledValue) => { return client.value.toString() })
        return stringList;
    }

    const validarDatas = (datas: Datas): boolean => {
        if (!datas.dataInicio || !datas.dataFim) {
            return false;
        }

        const dataInicio = new Date(datas.dataInicio);
        const dataFim = new Date(datas.dataFim);

        return dataInicio <= dataFim;
    };

    const handleChangeDataInicio = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDatas((prevDatas) => ({ ...prevDatas, dataInicio: e.target.value }));
    };

    const handleChangeDataFim = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDatas((prevDatas) => ({ ...prevDatas, dataFim: e.target.value }));
    };

    const handleClientsChange = (
        event: SyntheticEvent<Element, Event>,
        value:
            | {
                id: string | number | undefined;
                value: string | undefined;
            }[]
            | null
    ) => {
        let newSelectedClients: any[] = [];
        value?.forEach((value) => {
            const selectedClient = clientes.find((cliente) => cliente.id === value.id);
            if(!selectedClients.includes({
                id: selectedClient!.id!.toString(), 
                value: `(${selectedClient!.pessoa!.cnpj}) ${selectedClient!.pessoa!.nome}`
            }))
            selectedClient && newSelectedClients.push(selectedClient);
        });
        setSelectedClients(newSelectedClients);
    };

    const handleChangeAnalyst = (
        event: SyntheticEvent<Element, Event>,
        value:
            | {
                id: string | number | undefined;
                value: string | undefined;
            }[]
            | null
    ) => {
        let newList: any[] = [];
        value?.forEach((v) => {
            const temp = analystList.find((elem) => elem.id === v.id);
            if(!newList.includes(temp))
            temp && newList.push(temp);
        });
        setAnalyst(newList);
    };

    const handleBusinessChange = (event: SyntheticEvent<Element, Event>,
        value: {
            id: string | number | undefined;
            value: string | undefined;
        } | null) => {
        if (value == null) setSelectedBusiness(undefined);
        else setSelectedBusiness({
            id: value!.id as string,
            value: value!.value as string
        });
    }

    const handleSubmit = async () => {
        keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
        setIsModalOpen(true);

        if (!validarDatas(datas)) {
            dispatch(setErrorFeedback({
                message: 'Preencha as datas de início e fim corretamente.',
            }));
            return;
        }

        if (token && selectedUnit) {
            try {
                const error: string = await generateSpreadsheetGerencial(datas.dataInicio, datas.dataFim, token, selectedUnit,
                    selectedBusiness!.id.toString(), getIDs(selectedClients), getValues(analyst));
                if (error != null) {
                    dispatch(setErrorFeedback({
                        message: error ? error : t('com.muralis.qcx.integracao.Planilhas.erros.erroAoGerarPlanilha'),
                    }));
                } else {
                    dispatch(setSuccessFeedback({
                        message: t('com.muralis.qcx.integracao.Planilhas.sucesso.planilhaComSuceso')
                    }));
                }
            } catch (error) {
                dispatch(setErrorFeedback({
                    message: t('translation.com.muralis.qcx.integracao.Planilhas.erros.erroInesperado')
                }));
            }
            setIsModalOpen(false);
        }

        keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
        handleNavigation("/relatorios");
    };

    

    const handleNavigation = (path: string | undefined) => {
        if (path) {
            history.push(path);
        }
    };

    return (
        <PageLayout
            title='Relatório Acompanhamento Gerencial'
            icon={<InsertDriveFileIcon color={'secondary'} />}>
            <Grid container spacing={2}>
                <FormHeaderMedium>Selecione o importador</FormHeaderMedium>
                <Grid item sm={12} style={{ margin: '25px 0 0 0' }}>
                    <FormControl fullWidth>
                        <SelectStyled
                            controlledValue={selectedBusiness}
                            options={list.map((business) => ({
                                id: business.id,
                                value: business.pessoa.nomeResumido
                            } as ControlledValue))}
                            onChangeAction={handleBusinessChange}
                            label={"Unidade de Negócio"}
                        ></SelectStyled>
                    </FormControl>
                </Grid>
                <Grid item sm={12} style={{ margin: '25px 0 0 0' }}>
                    <FormControl fullWidth>
                        <MultiSelectStyled
                            options={clientes.map((client) => ({
                                id: client.id,
                                value: `(${client.pessoa!.cnpj}) ${client.pessoa!.nome}`
                            } as ControlledValue))}
                            onChangeAction={handleClientsChange}
                            label={"Clientes"}
                        ></MultiSelectStyled>
                    </FormControl>
                </Grid>
                <Grid item sm={12} style={{ margin: '25px 0 25px 0' }}>
                    <FormControl fullWidth>
                        <MultiSelectStyled
                            key={"AnalystSelect"}
                            options={analystList.map((analyst) => ({
                                id: analyst.id,
                                value: analyst.value
                            } as ControlledValue))}
                            onChangeAction={handleChangeAnalyst}
                            label={"Analista"}
                        ></MultiSelectStyled>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.container}>
                        <TextField
                            color='primary'
                            label="Data de Início"
                            type="date"
                            value={datas.dataInicio}
                            onChange={handleChangeDataInicio}
                            InputLabelProps={{ shrink: true }}
                            className={classes.input}
                            required
                        />

                        <TextField
                            color='primary'
                            label="Data de Fim"
                            type="date"
                            value={datas.dataFim}
                            onChange={handleChangeDataFim}
                            InputLabelProps={{ shrink: true }}
                            className={classes.input}
                            required
                        />
                    </div>
                </Grid>
                <Grid item sm={4} style={{margin:'25px 0 0 0'}}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmit}
                        fullWidth
                        disabled={selectedBusiness == undefined || datas.dataFim == '' || datas.dataInicio == ''}>
                        Gerar Relatório
                    </Button>
                </Grid>
            </Grid>
        </PageLayout>
    );
}

export default RelatorioGerencialPage;