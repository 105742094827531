import { validateQueryParams } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import { DANFE_ENDPOINT, FILTER_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

/**
 * Fetches all DANFEs.
 */
async function fetchAll() {
  return doGetQCXRequest([DANFE_ENDPOINT]);
}

/**
 * Fetches a DANFE by its ID.
 * @param {number|string} id - The ID of the DANFE.
 */
async function fetchById(id) {
  return doGetQCXRequest([DANFE_ENDPOINT, id]);
}

/**
 * Registers a new DANFE.
 * @param {object} data - The data for the new DANFE.
 * @param {Array} queryParams - Optional query parameters.
 */
async function register(data, queryParams = []) {
  return doPostQCXRequest([DANFE_ENDPOINT], data, queryParams);
}

/**
 * Saves updates to a DANFE.
 * @param {object} data - The updated data for the DANFE.
 * @param {Array} queryParams - Optional query parameters.
 */
async function save(data, queryParams = []) {
  return doPutQCXRequest([DANFE_ENDPOINT], data, queryParams);
}

/**
 * Sends a DANFE (transmits it).
 * @param {number|string} id - The ID of the DANFE.
 */
async function send(id) {
  return doPutQCXRequest([DANFE_ENDPOINT, 'send-danfe', id]);
}

/**
 * Signs a DANFE.
 * @param {number|string} id - The ID of the DANFE.
 */
async function assinar(id) {
  return doPutQCXRequest([DANFE_ENDPOINT, 'assinar-danfe', id]);
}

/**
 * Activates a DANFE by its ID.
 * @param {number|string} id - The ID of the DANFE.
 */
async function activateById(id) {
  return doPutQCXActivateRequest([DANFE_ENDPOINT], id);
}

/**
 * Inactivates a DANFE by its ID.
 * @param {number|string} id - The ID of the DANFE.
 */
async function inactivateById(id) {
  return doPutQCXInactivateRequest([DANFE_ENDPOINT], id);
}

/**
 * Prints a DANFE.
 * @param {number|string} id - The ID of the DANFE.
 */
async function printDanfe(id) {
  return doGetQCXRequest([DANFE_ENDPOINT, 'print-danfe', id], [], {
    responseType: 'blob',
  });
}

/**
 * Downloads the XML of a DANFE.
 * @param {number|string} id - The ID of the DANFE.
 */
async function baixarXmlNfe(id) {
  return doGetQCXRequest([DANFE_ENDPOINT, 'download-xml', id], [], {
    responseType: 'blob',
  });
}

/**
 * Downloads the PDF of a DANFE.
 * @param {number|string} id - The ID of the DANFE.
 */
async function baixarPdfDanfe(id) {
  return doGetQCXRequest([DANFE_ENDPOINT, 'print-danfe', id], [], {
    responseType: 'blob',
  });
}

/**
 * Validates the first step of DANFE creation.
 * @param {object} data - The data to validate.
 * @param {Array} queryParams - Optional query parameters.
 */
async function validateDanfeStepOne(data, queryParams = []) {
  return doPutQCXRequest([DANFE_ENDPOINT, 'validate-danfe-step-one'], data, queryParams);
}

/**
 * Fetches DANFEs by filter parameters.
 * @param {Array} params - The filter parameters.
 */
async function fetchByFilter(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([DANFE_ENDPOINT, FILTER_ENDPOINT], validatedParams);
  }
  return { data: [] };
}

/**
 * Fetches paginated DANFEs.
 * @param {Array} params - The pagination and filter parameters.
 */
async function fetchPaginateAsync(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([DANFE_ENDPOINT, FILTER_ENDPOINT, 'pagination'], validatedParams);
  }
  return { data: [] };
}

/**
 * Consults the status of a DANFE.
 * @param {number|string} id - The ID of the DANFE.
 */
async function consultaStatusNfe(id) {
  return doGetQCXRequest([DANFE_ENDPOINT, 'danfe-status', id]);
}

/**
 * Fetches DANFE notes by process.
 * @param {Array} params - The parameters including the process.
 */
async function fetchNotasDanfeByProcesso(params = []) {
  const validatedParams = validateQueryParams(params);
  return doGetQCXRequest([DANFE_ENDPOINT, 'find-notas'], validatedParams);
}

/**
 * Downloads XML files for multiple DANFEs in a batch.
 * @param {object} data - The data containing IDs of DANFEs.
 */
async function baixarXmlNfeLote(data) {
  return doPutQCXRequest([DANFE_ENDPOINT, 'download-xml', 'lote'], data, [], {
    responseType: 'blob',
  });
}

/**
 * Downloads signed XML files for multiple DANFEs in a batch.
 * @param {object} data - The data containing IDs of DANFEs.
 */
async function baixarXmlAssinadoNfeLote(data) {
  return doPutQCXRequest([DANFE_ENDPOINT, 'download-xml-assinado', 'lote'], data, [], {
    responseType: 'blob',
  });
}

/**
 * Signs multiple DANFEs in a batch.
 * @param {object} data - The data containing IDs of DANFEs.
 */
async function assinarNfeLote(data) {
  return doPutQCXRequest([DANFE_ENDPOINT, 'assinar-danfe-lote'], data, []);
}

/**
 * Cancels a DANFE by its ID.
 * @param {number|string} id - The ID of the DANFE.
 * @param {object} data - The cancellation data, including motivoCancelamento.
 */
async function cancelDanfe(id, data) {
  return doPutQCXRequest([DANFE_ENDPOINT, 'cancel-danfe', id], data);
}

async function corrigirDanfe(id, data) {
  return doPostQCXRequest([DANFE_ENDPOINT, 'corrigir-danfe', id], data);
}

async function trocarNumeroDanfe(id, data) {
  return doPutQCXRequest([DANFE_ENDPOINT, 'numero-danfe', id], data);
}

export {
  fetchAll,
  fetchById,
  register,
  save,
  send,
  assinar,
  activateById,
  inactivateById,
  printDanfe,
  baixarXmlNfe,
  baixarPdfDanfe,
  validateDanfeStepOne,
  fetchByFilter,
  fetchPaginateAsync,
  consultaStatusNfe,
  fetchNotasDanfeByProcesso,
  baixarXmlNfeLote,
  baixarXmlAssinadoNfeLote,
  assinarNfeLote,
  cancelDanfe, // <-- Exported cancelDanfe
  corrigirDanfe,
  trocarNumeroDanfe,
};
