import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseMutateFunction } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync as fetchAllClientesAsync, selectClientes } from '../../../../features/cliente/clienteSlice';
import { QueriesTypes, useDeleteRequest, useGetRequest, usePostRequest } from '../../../common/hooks/queries';
import { ClientData } from '../../../common/types/clientData';
import { DanfeCapa, DanfeItem } from './escrita/danfe/totvsEscritaDanfePage.types';
import { DespesasCapa, DespesasItem } from './escrita/despesas/totvsEscritaDespesasPage.types';
import { Numerario } from './escrita/numerarios/totvsEscritaNumerariosPage.types';
import {
  CorrelationsType,
  CorrespondenciaGeralTotvs,
  CorrespondenciaImportadorTotvs,
  CorrespondenciasListItem,
} from './totvs.types';
import { Despachante } from 'quickcomex-api-types';

export const useCorrelationsTypes = () => {
  const correlationsKeys = Object.keys(CorrelationsType).filter((key) => isNaN(Number(key)));
  return correlationsKeys;
};

export const useFabricantesExportadores = () => {

  const { data: fabricantesExportadoresData, isError } = useGetRequest(QueriesTypes.FabricanteExportadorSummary);

  const exportadores = useMemo(() => {
    if (fabricantesExportadoresData && !isError) {
      try {
        return fabricantesExportadoresData?.filter(
          (item: any) => item.exportador && item.nome && item.active
        );
      } catch (e) {
        console.log(e);
        return [];
      }
    } else {
      return [];
    }
  }, [fabricantesExportadoresData]);
  const fabricantes = useMemo(() => {
    if (fabricantesExportadoresData && !isError) {
      try {
        return fabricantesExportadoresData?.filter(
          (item: any) => item.fabricante && item.nome && item.active
        );
      } catch (e) {
        console.log(e);
        return [];
      }
    } else {
      return [];
    }
  }, [fabricantesExportadoresData]);
  return { exportadores, fabricantes };
};

export const useTransportadoresInternacionais = () => {
  const { data } = useGetRequest(QueriesTypes.TransportadorInternacional);
  try {
    return data?.filter((item: any) => item.active) ?? [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const useLocaisDeEmbarque = () => {
  const { data } = useGetRequest(QueriesTypes.LocaisDeEmbarque);
  try {
    return data?.filter((item: any) => item.active && item.local == 'LOCAL_DE_ORIGEM') ?? [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const useAgentesDeCarga = () => {
  const { data } = useGetRequest(QueriesTypes.AgentesDeCarga);
  try {
    return data?.filter((item: any) => item.active && item.agenteTransportador) ?? [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const useDispositivosLegais = () => {
  const { data } = useGetRequest(QueriesTypes.DispositivosLegais);
  try {
    return data?.filter((item: any) => item.active) ?? [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const useDespesasReceitas = (clientId: string | number) => {
  const queryParams = useMemo(() => `clientId=${clientId}`, [clientId]);
  const { data: receitasDespesas } = useGetRequest(QueriesTypes.DespesasReceitasFilter, '', queryParams);
  try {
    return [...receitasDespesas?.filter((item: any) => item.active)] ?? [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const useDespachantes = (clientId: string | number): Despachante[] => {
  const queryParams = useMemo(() => `clientId=${clientId}`, [clientId]);
  const { data } = useGetRequest(QueriesTypes.DespachantesFilter, '', queryParams);

  try {
    return data?.filter((item: any) => item.active) ?? [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const useCfops = () => {
  const { data } = useGetRequest(QueriesTypes.Cfops);
  try {
    return data?.filter((item: any) => item.active) ?? [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const useTransportadoras = () => {
  const { data } = useGetRequest(QueriesTypes.Transportadora);
  try {
    return data?.filter((item: any) => item.active) ?? [];
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const useCorrespondenciasImportadores = (): [CorrespondenciaImportadorTotvs[], boolean, boolean] => {
  const { data, isLoading, isError } = useGetRequest(QueriesTypes.TotvsCorrespondenciasImportador);
  return [data, isLoading, isError];
};

export const useCreateCorrealacaoImportador = (): [
  UseMutateFunction<
    any,
    unknown,
    {
      requestPayload: {
        [key: string]: any;
      };
      queryParams?:
        | {
            clientId?: string | number;
            step?: number;
          }
        | undefined;
    }
  >,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = usePostRequest(QueriesTypes.TotvsCorrespondenciasImportador);

  return [mutate, isLoading, isError];
};

export const useDeleteCorrespondenciaImportador = (): [
  UseMutateFunction<
    any,
    unknown,
    {
      id?: string | number;
      nome?: string | undefined;
      clienteId?: number | undefined;
    },
    unknown
  >,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = useDeleteRequest(QueriesTypes.TotvsCorrespondenciasImportador);

  return [mutate, isLoading, isError];
};

export const useCorrespondenciaGeral = (totvsId: string): [CorrespondenciaGeralTotvs[], boolean, boolean] => {
  const { data, isLoading, isError } = useGetRequest(QueriesTypes.TotvsCorrespondenciasGerais, totvsId);
  return [data, isLoading, isError];
};

export const useListAllCorrespondencias = (): [CorrespondenciasListItem[], boolean, boolean] => {
  const { data, isLoading, isError } = useGetRequest(QueriesTypes.TotvsCorrespondenciasGerais);
  return [data, isLoading, isError];
};

export const useCreateCorrespondenciaGeral = (): [
  UseMutateFunction<
    any,
    unknown,
    {
      requestPayload: {
        [key: string]: any;
      };
      queryParams?:
        | {
            clientId?: string | number;
            step?: number;
          }
        | undefined;
    }
  >,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = usePostRequest(QueriesTypes.TotvsCorrespondenciasGerais);

  return [mutate, isLoading, isError];
};

export const useDeleteCorrespondenciaGeral = (): [
  UseMutateFunction<
    any,
    unknown,
    {
      id?: string | number;
      nome?: string | undefined;
      clienteId?: number | undefined;
    },
    unknown
  >,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = useDeleteRequest(QueriesTypes.TotvsCorrespondenciasGerais);

  return [mutate, isLoading, isError];
};

export const useClientes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const clientes: ClientData[] = useSelector(selectClientes);
  const dispatch = useDispatch();

  useEffect(() => {
    // Ignore typescript due to older version of redux-toolkit
    // TODO: Update redux-toolkit to typescript
    // @ts-ignore
    dispatch(fetchAllClientesAsync()).then(() => {
      setIsLoading(false);
    });
  }, []);

  return { clientes, isLoading };
};

export const useCreateFaturaTotvs = ({
  defaultErrorMessage,
  defaultSuccessMessage,
}: {
  defaultErrorMessage: string;
  defaultSuccessMessage: string;
}): [
  UseMutateFunction<
    any,
    unknown,
    {
      requestPayload: {
        [key: string]: any;
      };
      queryParams?:
        | {
            clientId?: string | number;
            step?: number;
          }
        | undefined;
    }
  >,
  boolean,
  boolean
] => {
  const { isLoading, isError, mutate } = usePostRequest(QueriesTypes.TotvsFaturas, {
    defaultErrorMessage,
    defaultSuccessMessage,
    cleanEmptyValues: true,
  });

  return [mutate, isLoading, isError];
};

export const useDespesasCapa = (process: string | undefined): { despesasCapa: DespesasCapa[]; isLoading: boolean } => {
  const { data, isLoading } = useGetRequest(QueriesTypes.TotvsDespesasCapa, undefined, `process=${process}`);

  return { despesasCapa: data ?? [], isLoading };
};

export const useDespesasItens = (
  process: string | undefined
): { despesasItens: DespesasItem[]; isLoading: boolean } => {
  const { data, isLoading } = useGetRequest(QueriesTypes.TotvsDespesasItens, undefined, `process=${process}`);

  return { despesasItens: data ?? [], isLoading };
};

export const useDanfeCapa = (
  process: string | undefined,
  numeronota: string | undefined
): { danfesCapas: DanfeCapa[]; isLoading: boolean } => {
  const { data, isLoading } = useGetRequest(
    QueriesTypes.TotvsDanfesCapa,
    undefined,
    `process=${process}&numeronota=${numeronota}`
  );

  return { danfesCapas: data ?? [], isLoading };
};

export const useDanfeItens = (
  process: string | undefined,
  numeronota: string | undefined
): { danfesItens: DanfeItem[]; isLoading: boolean } => {
  const { data, isLoading } = useGetRequest(
    QueriesTypes.TotvsDanfesItens,
    undefined,
    `process=${process}&numeronota=${numeronota}`
  );

  return { danfesItens: data ?? [], isLoading };
};

export const useNumerario = (process: string | undefined): { data: Numerario[]; isloading: boolean } => {
  const { data, isLoading } = useGetRequest(QueriesTypes.TotvsNumerarios, undefined, `process=${process}`);

  return { data: data ?? [], isloading: isLoading };
};
