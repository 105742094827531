import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  AssignmentInd as AssignmentIndIcon,
  ChromeReaderMode as ChromeReaderModeIcon,
  Compare as CompareIcon,
  DeveloperBoard,
  DirectionsBoat,
  Dns as DnsIcon, Domain,
  Gavel,
  Group as GroupIcon,
  Height,
  LocationCity,
  Storefront as StorefrontIcon,
  ViewList as ViewListIcon,
  Web as WebIcon,
  LocalShipping as LocalShippingIcon,
  ListAlt as ListAltIcon,
} from '@material-ui/icons';
import PaidIcon from '@mui/icons-material/Paid';
import { Box } from '@mui/material';
import React, {
  useEffect,
} from 'react';
import { GiCargoCrate, GiCommercialAirplane } from 'react-icons/gi';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import QCXFocusLock from '../../../../components/focus-lock/QCXFocusLock';
import QCXMainLayout from '../../../../components/main-layout/QCXMainLayout';
import { focusLockActions } from '../../../../features/focus-lock/focusLockSlice';
import QCXCardMenu from '../../../../shared-components/card-menu/QCXCardMenu';
import QCXCardMenuWithSubmenu from '../../../../shared-components/card-menu/QCXCardMenuWithSubmenu';
import { CADASTROS_OPERACIONAIS_MODULE } from '../../../../utils/general/modules';

const useStyles = makeStyles((theme) => ({
  cardMenuContainer: {
    marginTop: theme.spacing(0),
  },
  cardMenu: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  subNested: {
    paddingLeft: theme.spacing(8),
  },
  active: {
    display: 'flex!important',
  },
  noActive: {
    display: 'none!important',
    transformOrigin: '0 0 0!important',
  },
}));

export default function OperationalRegistrationsPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();
  const userHasAnyRole = (mainRoles = []) => authInfo.roles.some(
    (role) => mainRoles.find((item) => role.startsWith(item))
  );
  const {
    location: {
      pathname: currentLocationPath,
    },
  } = useHistory();

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.operacionais'),
      },
    },
  ];
  const [open, setOpen] = React.useState(false);
  const [openArmazemSubMenu, setOpenArmazemSubMenu] = React.useState(false);
  const [openCatalogoProdutoSubMenu, setOpenCatalogoProdutoSubMenu] = React.useState(false);

  const toggleArmazemSubMenu = () => {
    setOpenArmazemSubMenu((old) => !old);
    setOpen((old) => !old);
  };

  const toggleCatalogoProdutoSubMenu = () => {
    setOpenCatalogoProdutoSubMenu((old) => !old);
    setOpen((old) => !old);
  };

  useEffect(() => {
    const handleFocusLock = () => {
      const currentPaths = currentLocationPath.split('/');

      const match = (modulePaths) => (
        modulePaths.every(
          (path) => currentPaths.includes(path)
        )
      );

      if (match(CADASTROS_OPERACIONAIS_MODULE)) {
        dispatch(focusLockActions.lock());
      }
    };

    handleFocusLock();
  }, [currentLocationPath]);

  // constante para inibir o aparecimento do submenu para Armazéns #22031
  const hiddenArmazensSubMenu = true;

  return (
    <QCXMainLayout
      pageIcon={<DnsIcon />}
      pageTitle={t('com.muralis.qcx.cadastrosOperacionais')}
      breadcrumbs={breadcrumbs}
      authInfo={authInfo}
    >
      <Grid className={classes.cardMenuContainer} item container justify="center" xs={12} sm={12} md={12} xl={12}>
        <QCXFocusLock>
          <Grid container style={{ maxWidth: '1920px' }}>
            {userHasAnyRole(['cliente']) && (
            <Grid
              item
              justify="flex-start"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={`${open ? classes.noActive : classes.active}`}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.cliente.labelPlural')}
                link={t('com.muralis.qcx.url.moduloOperacionaisClientes')}
                icon={GroupIcon}
                openMenu={open}
              />

            </Grid>
            )}

            {userHasAnyRole(['exportador-fabricante']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={`${open ? classes.noActive : classes.active}`}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.empresaEstrangeira.labelPlural')}
                link={t('com.muralis.qcx.url.moduloOperacionaisEmpresaEstrangeira')}
                openMenu={open}
                icon={LocationCity}
              />

            </Grid>
            )}

            {userHasAnyRole(['transportador-internacional']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={`${open ? classes.noActive : classes.active}`}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.transportadorInternacional.label')}
                link={t('com.muralis.qcx.url.moduloOperacionaisTransportadorInternacional')}
                openMenu={open}
                icon={GiCargoCrate}
              />

            </Grid>
            )}

            {userHasAnyRole(['transportadora']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={`${open ? classes.noActive : classes.active}`}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.transportadora.labelPlural')}
                link={t('com.muralis.qcx.url.moduloOperacionaisTransportadoras')}
                openMenu={open}
                icon={LocalShippingIcon}
              />

            </Grid>
            )}

            {userHasAnyRole(['despachante']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={`${open ? classes.noActive : classes.active}`}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.despachante.labelPlural')}
                link={t('com.muralis.qcx.url.moduloOperacionaisDespachantes')}
                openMenu={open}
                icon={AssignmentIndIcon}
              />

            </Grid>
            )}

            {userHasAnyRole(['embarcacao']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={`${open ? classes.noActive : classes.active}`}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.embarcacao.labelPlural')}
                link={t('com.muralis.qcx.url.moduloOperacionaisEmbarcacao')}
                openMenu={open}
                icon={DirectionsBoat}
              />
            </Grid>
            )}

            {hiddenArmazensSubMenu && userHasAnyRole(['armazem']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={`${open ? classes.noActive : classes.active}`}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.armazem.labelPlural')}
                link={t('com.muralis.qcx.url.moduloOperacionaisArmazens')}
                openMenu={open}
                icon={StorefrontIcon}
              />
            </Grid>
            )}

            {openArmazemSubMenu ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: '16px',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  width: '100% !important',
                }}
              >
                {!hiddenArmazensSubMenu && userHasAnyRole(['armazem', 'tabela-armazenagem']) && (
                <Grid
                  xs={6}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2}
                  item
                >
                  <QCXCardMenuWithSubmenu title={t('com.muralis.qcx.armazem.labelPlural')} icon={StorefrontIcon} isActiveOpenMenu={open} onClick={toggleArmazemSubMenu} />
                </Grid>
                )}

                <Grid
                  container
                  className={classes.active}
                >
                  {userHasAnyRole(['armazem']) && (
                  <Grid
                    item
                    justify="center"
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                    className={classes.active}
                  >
                    <QCXCardMenu
                      title={t('com.muralis.qcx.armazem.labelPlural')}
                      link={t('com.muralis.qcx.url.moduloOperacionaisArmazens')}
                      icon={Domain}
                      timeout={500}
                      openMenu={openArmazemSubMenu}
                      isSubmenu
                    />
                  </Grid>
                  )}
                  {userHasAnyRole(['tabela-armazenagem']) && (
                  <Grid
                    item
                    justify="center"
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                    className={classes.active}
                  >
                    <QCXCardMenu
                      title={t('com.muralis.qcx.tabelaArmazenagem.labelPlural')}
                      link={t('com.muralis.qcx.url.moduloOperacionaisTabelaArmazenagem')}
                      icon={CompareIcon}
                      timeout={500}
                      openMenu={openArmazemSubMenu}
                      isSubmenu
                    />
                  </Grid>
                  )}
                </Grid>
              </Box>
            ) : null}
            {!hiddenArmazensSubMenu && userHasAnyRole(['armazem', 'tabela-armazenagem']) && !open ? (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                lg={3}
                xl={2}
                className={classes.active}
              >
                <QCXCardMenuWithSubmenu title={t('com.muralis.qcx.armazem.labelPlural')} icon={StorefrontIcon} isActiveOpenMenu={open} onClick={toggleArmazemSubMenu} />
              </Grid>
            ) : null}

            {openCatalogoProdutoSubMenu ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: '16px',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  width: '100% !important',
                }}
                xs={12}
                sm={12}
                md={12}
                xl={12}
              >
                {userHasAnyRole(['ncm', 'operacao-fiscal', 'unidade-de-medida', 'catalogo-produtos', 'tabela-armazenagem']) && (
                <Grid
                  xs={6}
                  sm={4}
                  md={3}
                  lg={3}
                  xl={2}
                  item
                  className={classes.active}
                >
                  <QCXCardMenuWithSubmenu
                    title={t('com.muralis.qcx.catalogoProdutosMercadoria')}
                    icon={ViewListIcon}
                    isActiveOpenMenu={open}
                    onClick={toggleCatalogoProdutoSubMenu}
                  />
                </Grid>
                )}

                <Grid
                  container
                  className={classes.active}
                >
                  {userHasAnyRole(['catalogo-produtos']) && (
                  <Grid
                    item
                    justify="center"
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                    className={classes.active}
                  >
                    <QCXCardMenu
                      title={t('com.muralis.qcx.catalogoProdutos.label')}
                      link={t('com.muralis.qcx.url.moduloOperacionaisCatalogosProdutos')}
                      icon={ViewListIcon}
                      timeout={500}
                      openMenu={openCatalogoProdutoSubMenu}
                      isSubmenu
                    />
                  </Grid>
                  )}
                  {userHasAnyRole(['unidade-de-medida']) && (
                  <Grid
                    item
                    justify="center"
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                    className={classes.active}
                  >
                    <QCXCardMenu
                      title={t('com.muralis.qcx.unidadeMedida.labelPlural')}
                      link={t('com.muralis.qcx.url.modulosOperacionaisUnidadeMedida')}
                      icon={Height}
                      timeout={500}
                      openMenu={openCatalogoProdutoSubMenu}
                      isSubmenu
                    />
                  </Grid>
                  )}
                  {userHasAnyRole(['operacao-fiscal']) && (
                  <Grid
                    item
                    justify="center"
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                    className={classes.active}
                  >
                    <QCXCardMenu
                      title={t('com.muralis.qcx.CFOP.labelExtenso')}
                      link={t('com.muralis.qcx.url.moduloOperacionaisCFOP')}
                      icon={DeveloperBoard}
                      timeout={500}
                      openMenu={openCatalogoProdutoSubMenu}
                      isSubmenu
                    />
                  </Grid>
                  )}
                  {userHasAnyRole(['tabela-armazenagem']) && (
                  <Grid
                    item
                    justify="center"
                    xs={6}
                    sm={4}
                    md={3}
                    lg={3}
                    xl={2}
                    className={classes.active}
                  >
                    <QCXCardMenu
                      title={t('com.muralis.qcx.NCM.NCMnivel6')}
                      link={t('com.muralis.qcx.url.moduloOperacionaisNcmNivel6')}
                      icon={ListAltIcon}
                      timeout={500}
                      openMenu={openCatalogoProdutoSubMenu}
                      isSubmenu
                    />
                  </Grid>
                  )}
                </Grid>
              </Box>
            ) : null}
            {userHasAnyRole(['ncm', 'operacao-fiscal', 'unidade-de-medida', 'catalogo-produtos']) && !open ? (
              <Grid
                spacing={0}
                item
                xs={6}
                sm={4}
                md={3}
                lg={3}
                xl={2}
                className={classes.active}
              >
                <QCXCardMenuWithSubmenu title={t('com.muralis.qcx.catalogoProdutosMercadoria')} icon={ViewListIcon} isActiveOpenMenu={open} onClick={toggleCatalogoProdutoSubMenu} />
              </Grid>
            ) : null}

            {userHasAnyRole(['modelo-informacoes-complementares']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={`${open ? classes.noActive : classes.active}`}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.modeloInformacaoComplementar.label')}
                link={t('com.muralis.qcx.url.moduloOperacionaisModelosInformacoesComplementares')}
                openMenu={open}
                icon={ChromeReaderModeIcon}
              />
            </Grid>
            )}

            {userHasAnyRole(['porto-aeroporto']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={`${open ? classes.noActive : classes.active}`}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.portoAeroporto.labelPlural')}
                link={t('com.muralis.qcx.url.moduloOperacionaisPortoAeroporto')}
                openMenu={open}
                icon={GiCommercialAirplane}
              />
            </Grid>
            )}

            {userHasAnyRole(['dispositivo-legal']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={`${open ? classes.noActive : classes.active}`}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.dispositivoLegal.label')}
                link={t('com.muralis.qcx.url.moduloOperacionaisDispositivosLegais')}
                openMenu={open}
                icon={Gavel}
              />
            </Grid>
            )}

            {userHasAnyRole(['modelo-danfe']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={`${open ? classes.noActive : classes.active}`}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.modeloDANFE.label')}
                link={t('com.muralis.qcx.url.moduloOperacionaisModeloDANFE')}
                openMenu={open}
                icon={WebIcon}
              />
            </Grid>
            )}

            {userHasAnyRole(['estado-cadastro']) && (
              <Grid
                item
                justify="center"
                xs={6}
                sm={4}
                md={3}
                lg={3}
                xl={2}
                className={`${open ? classes.noActive : classes.active}`}
              >
                <QCXCardMenu
                  title={t('com.muralis.qcx.estado.labelPlural')}
                  link={t('com.muralis.qcx.url.moduloOperacionaisEstado')}
                  openMenu={open}
                  icon={LocationCity}
                />
              </Grid>
            )}

            {userHasAnyRole(['cidade-cadastro']) && (
              <Grid
                item
                justify="center"
                xs={6}
                sm={4}
                md={3}
                lg={3}
                xl={2}
                className={`${open ? classes.noActive : classes.active}`}
              >
                <QCXCardMenu
                  title={t('com.muralis.qcx.endereco.cidade')}
                  link={t('com.muralis.qcx.url.moduloOperacionaisCidade')}
                  openMenu={open}
                  icon={LocationCity}
                />
              </Grid>
            )}

            {userHasAnyRole(['taxa-conversao-cambio']) && (
              <Grid
                item
                justify="center"
                xs={6}
                sm={4}
                md={3}
                lg={3}
                xl={2}
                className={`${open ? classes.noActive : classes.active}`}
              >
                <QCXCardMenu
                  title={t('com.muralis.qcx.taxaConversaoCambio')}
                  link={t('com.muralis.qcx.url.moduloOperacionaisTaxaConversaoCambio')}
                  openMenu={open}
                  icon={PaidIcon}
                />
              </Grid>
            )}
          </Grid>
        </QCXFocusLock>
      </Grid>
    </QCXMainLayout>
  );
}
