import CardsGridSubmenu from '../../../common/components/cardsGrid/cardsGridSubmenu';
import BasicLayout from '../../../common/layouts/basicLayout';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { CardsGridItem } from '../../../common/components/cardsGrid/cardsGrid';
import SmartToyIcon from '@mui/icons-material/SmartToy';

const cover: CardsGridItem = {
  title: 'Crawlers',
  icon: SmartToyIcon,
  path: '/integracao',
};

const gridItems: CardsGridItem[] = [
  {
    title: 'LICENÇAS DE IMPORTAÇÃO (LI)',
    icon: AssignmentIcon,
    path: '/integracao/crawlers/li',
    requiredRoles: ['importacao-li-lpco-visualizar', 'importacao-li-lpco-incluir']
  },
];

const CrawlersPage = () => {
  return (
    <BasicLayout
      title={'Crawlers'}
      icon={<SmartToyIcon color={'secondary'} />}
    >
      <CardsGridSubmenu cover={cover} items={gridItems}></CardsGridSubmenu>
    </BasicLayout>
  );
};

export default CrawlersPage;
