import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../base-components/BaseSelectWithOptions';

function TipoEstufagemSelect({ ...props }) {
  const list = useMemo(
    () => [
      {
        id: 'FCL',
        label: 'FCL',
        value: 'FCL',
      },
      {
        id: 'LCL',
        label: 'LCL',
        value: 'LCL',
      },
      {
        id: 'Granel',
        label: 'Granel',
        value: 'GRANEL',
      },
      {
        id: 'Parte Lote',
        label: 'Parte Lote',
        value: 'PARTE_LOTE',
      },
      {
        id: 'Outros',
        label: 'Outros',
        value: 'OUTROS',
      },
    ],
    []
  );

  return <BaseSelectWithOptions list={list} {...props} />;
}

export default TipoEstufagemSelect;
