import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
  } from 'react';
  import { useDispatch, useSelector } from 'react-redux';
  import CidadeContext from '../../../../../contexts/registrations/cidade/CidadeContext';
  import {
    clearResponse,
    resetStatus,
    selectError,
    selectStatus,
    selectResponse,
  } from '../../../../../features/cidade/cidadeSlice'; 
  import { selectStatus as selectStatusEstado } from '../../../../../features/estado/estadoSlice';
  import { ALTERNATIVE_LOADING_STATUS, FAILURE_STATUS, LOADING_STATUS, SUCCESS_STATUS } from '../../../../../features/status';
  import QCXPageTemplate from '../../../../../templates/page/QCXPageTemplate';
  
  export default function CidadePage({ children }) {
    const dispatch = useDispatch();
    const status = useSelector(selectStatus);
    const statusEstado = useSelector(selectStatusEstado);
    const error = useSelector(selectError);
    const response = useSelector(selectResponse);
  
    const isLoading = useMemo(() => (
      status === LOADING_STATUS || statusEstado === ALTERNATIVE_LOADING_STATUS
    ), [status, statusEstado]);
  
    const [successSnackbar, setSuccessSnackbar] = useState({
      open: false,
      message: '',
    });
  
    const [errorSnackbar, setErrorSnackbar] = useState({
      open: false,
      message: '',
    });
  
    const handleOpenSuccessSnackbar = useCallback((message) => {
      setSuccessSnackbar({
        open: true,
        message,
      });
    }, []);
  
    const handleOpenErrorSnackbar = useCallback((message) => {
      setErrorSnackbar({
        open: true,
        message,
      });
    }, []);
  
    const handleCloseSuccessSnackbar = useCallback(() => {
      setSuccessSnackbar({
        ...successSnackbar,
        open: false,
      });
    }, []);
  
    const handleCloseErrorSnackbar = useCallback(() => {
      setErrorSnackbar({
        ...errorSnackbar,
        open: false,
      });
    }, []);
  
    useEffect(() => {
      if (status === FAILURE_STATUS && error && error.message) {
        handleOpenErrorSnackbar(error.message);
        dispatch(resetStatus());
      } else if (status === SUCCESS_STATUS && response && response.message) {
        handleOpenSuccessSnackbar(response.message);
        dispatch(resetStatus());
        dispatch(clearResponse());
      }
    }, [response, status, error]);
  
    const providerData = useMemo(() => ({
      status,
      isLoading,
      successSnackbar,
      errorSnackbar,
      handleCloseSuccessSnackbar,
      handleCloseErrorSnackbar,
    }), [
      status,
      isLoading,
      successSnackbar,
      errorSnackbar,
    ]);
  
    return (
      <CidadeContext.Provider value={providerData}>
        <QCXPageTemplate contextComponent={CidadeContext}>
          {children}
        </QCXPageTemplate>
      </CidadeContext.Provider>
    );
  }
  