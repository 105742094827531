import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Container, Grid, makeStyles } from '@material-ui/core';
import QCXMainLayout from '../../components/main-layout/QCXMainLayout';
import {
  confirmOperation,
  resetOperation,
  selectOperationActive,
  selectOperationTitle,
  selectOperationMessage,
} from '../../features/controller/controllerSlice';
import QCXConfirmDialog from '../../shared-components/dialog/QCXConfirmDialog';
import QCXFormTitle from '../../components/form-title/QCXFormTitle';
import ReadOnlyFormContext from '../../contexts/components/registration-page/ReadOnlyFormContext';
import ActionsBar from '../../shared-components/actions-bar/ActionsBar';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    padding: '0 1rem 3rem 1rem',
  },
}));

export default function QCXRegistrationFormPageTemplate({
  pageTitle,
  pageIcon,
  breadcrumbs = [],
  breadcrumbsProps = {},
  isUpdate,
  isConsult,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  showSubtitle = true,
  children,
  authInfo = {},
  disableUpdate = false,
  actionBarProps,
  ...restProps
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  const dispatch = useDispatch();

  const operationActive = useSelector(selectOperationActive);
  const operationTitle = useSelector(selectOperationTitle);
  const operationMessage = useSelector(selectOperationMessage);

  const handleConfirmDialog = useCallback(() => {
    dispatch(confirmOperation());
  }, []);

  const handleCloseDialog = useCallback(() => {
    dispatch(resetOperation());
  }, []);

  const readOnlyFormContextValue = useMemo(
    () => ({
      disableUpdate,
    }),
    [disableUpdate]
  );

  return (
    <ReadOnlyFormContext.Provider value={readOnlyFormContextValue}>
      <QCXMainLayout
        key="main-layout-registration-page"
        pageIcon={pageIcon}
        pageTitle={pageTitle}
        breadcrumbs={breadcrumbs}
        breadcrumbsProps={breadcrumbsProps}
        authInfo={authInfo}
      >
        <Container className={classes.container}>
          <Grid container style={{ marginTop: '0.2rem' }}>
            <Grid container item xs={12} justify="flex-end">
              <ActionsBar {...actionBarProps} />
            </Grid>
            {showSubtitle && (
              <Grid item xs={12} style={{ marginBottom: '1.4rem' }}>
                <QCXFormTitle
                  title={
                    isConsult || isUpdate
                      ? t('com.muralis.qcx.dados').toUpperCase()
                      : t('com.muralis.qcx.preenchaOsCamposAbaixo').toUpperCase()
                  }
                />
              </Grid>
            )}
            {typeof children === 'function'
              ? children({
                  isConsult,
                  isUpdate,
                  handleChangeToConsult,
                  handleChangeToUpdate,
                  handleCancelUpdate,
                  ...restProps,
                })
              : children}
          </Grid>
          <QCXConfirmDialog
            open={operationActive}
            title={operationTitle}
            content={operationMessage}
            onClose={handleCloseDialog}
            onConfirm={handleConfirmDialog}
          />
        </Container>
      </QCXMainLayout>
    </ReadOnlyFormContext.Provider>
  );
}
