import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 as uuid, validate } from 'uuid';
import {
  useHistory,
  useParams,
} from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { isEmpty } from 'lodash';
import {
  changeModeTo,
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetModel,
  selectBackgroundMode,
  selectTransportadora,
  selectMode,
  selectStatus,
  setError,
  setModel,
  setResponse,
  success,
  addToList,
  updateOnList,
} from '../../../../../features/transportadora/transportadoraSlice';
import QCXTransportadoraWizardFinalForm from '../../../../../components/transportadora/QCXTransportadoraWizardFinalForm';
import {
  isCreateMode,
  isBackgroundCreateMode,
  isConsultMode,
  isUpdateMode,
  isPreparingActionStatus,
  isNoneMode,
} from '../../../../../utils/store/store-utils';
import {
  estadoActions,
} from '../../../../../features/estado/estadoSlice';
import { register, save } from '../../../../../features/transportadora/transportadoraAPI';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import {
  normalizeDigits, normalizeZipCode,
} from '../../../../../utils/general/general-utils';
import { formatCep, formatCnpj } from '../../../../../utils/hooks/form/field/formatters';
import useAutoChangeMode from '../../../../../utils/hooks/mode/useAutoChangeMode';

export default function TransportadoraRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const [isCreated, setIsCreated] = useState(false);

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const transportadora = useSelector(selectTransportadora);

  const isNone = useMemo(() => (
    isNoneMode(mode)
  ), [mode]);

  const isCreate = useMemo(() => (
    isCreateMode(mode)
  ), [mode]);

  const isBackgroundCreate = useMemo(() => (
    isBackgroundCreateMode(backgroundMode)
  ), [backgroundMode]);

  const isConsult = useMemo(() => (
    isConsultMode(mode)
  ), [mode]);

  const isUpdate = useMemo(() => (
    isUpdateMode(mode)
  ), [mode]);

  const isPreparingAction = useMemo(() => (
    isPreparingActionStatus(status)
  ), [status]);

  useAutoChangeMode((currentMode) => (
    dispatch(changeModeTo(currentMode))
  ), [isUpdate]);

  const handleChangeToPreparingAction = () => {
    dispatch(preparingAction());
  };

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToBackgroundCreate = () => {
    dispatch(changeToBackgroundCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  const handleResetBackgroundMode = () => {
    dispatch(resetBackgroundMode());
  };

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  useEffect(() => (
    () => (
      dispatch(estadoActions.resetControl())
    )
  ), []);

  const handleCancelUpdate = () => {
    const currentId = isBackgroundCreate ? transportadora?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
    handleChangeToConsult();
  };

  const normalize = useCallback((unnormalizedData) => {
    const normalizedData = {
      ...unnormalizedData,
      pessoaJuridica: {
        ...unnormalizedData?.pessoaJuridica,
        cnpj: normalizeDigits(
          unnormalizedData?.pessoaJuridica?.cnpj
        ),
      },
      endereco: {
        ...unnormalizedData?.endereco,
        cep: normalizeZipCode(
          unnormalizedData?.endereco?.cep
        ),
      },
      contatos: unnormalizedData?.contatos && Array.isArray(unnormalizedData?.contatos) ? unnormalizedData?.contatos.map(contato => {
        if(validate(contato.id)) {
          return {
            ...contato,
            id: null,
          }
        }
        return contato
      }) : [],
    };

    return normalizedData;
  }, []);

  const unnormalize = useCallback((normalizedData) => {
    const unnormalizedData = {
      ...normalizedData,
      pessoaJuridica: {
        ...normalizedData?.pessoaJuridica,
        cnpj: formatCnpj(
          normalizedData?.pessoaJuridica?.cnpj
        ),
      },
      endereco: {
        ...normalizedData?.endereco,
        cep: formatCep(
          normalizedData?.endereco?.cep
        ),
      },
    };

    return unnormalizedData;
  }, []);

  const handleDispatchSetModel = useCallback((data) => {
    dispatch(setModel(normalize(data)));
  }, [normalize]);

  const createByStep = async (data, step, next) => {
    const isFirstStep = step === 0;
    const isLastStep = step === 1;

    const executeDebounced = debounce(async () => {
      try {
        if (isFirstStep && !isCreated) {
          const response = await register(data, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 201) {
            next();
            dispatch(resetModel());
            dispatch(success());

            const created = response?.data;

            handleDispatchSetModel(created);
            dispatch(addToList({ data: created }));
            dispatch(fetchByIdAsync(created?.id));

            setIsCreated(true);
          }
        } else if (isLastStep) {
          const response = await save(data, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 200) {
            dispatch(resetModel());

            const handleResultWithDebounce = debounce(() => {
              history.push(t('com.muralis.qcx.url.moduloOperacionaisTransportadoras'));

              dispatch(success());

              const saved = response?.data;

              dispatch(setModel(saved));

              dispatch(setResponse({
                status: response.status,
                data: saved,
                message: t('com.muralis.qcx.mensagem.transportadoraCadastrada', { transportadora: data?.pessoaJuridica?.nome }),
              }));

              dispatch(updateOnList({ data: saved }));
            }, 500);

            handleResultWithDebounce();
          }
        } else {
          const response = await save(data, [{ name: 'STEP', value: step + 1 }]);

          if (response?.status === 200) {
            if (isCreate) {
              next();
            } else {
              handleChangeToConsult();
            }

            const saved = response?.data;

            dispatch(success());
            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }
        }
      } catch (error) {
        const errorMessage = (
          error?.response
          && error?.response?.data
          && error?.response?.data?.message
        ) ? t('com.muralis.qcx.erro.erroSalvarTransportadoraMensagem', { erro: error.response.data.message })
          : t('com.muralis.qcx.erro.erroSalvarTransportadora');

        dispatch(failure());
        dispatch(setError({
          message: errorMessage,
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data, step) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data, [{ name: 'STEP', value: step + 1 }]);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(setResponse({
              status: response.status,
              data: saved,
              message: t('com.muralis.qcx.mensagem.transportadoraSalva', { transportadora: saved?.pessoaJuridica?.nome }),
            }));

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(setError({
          message: t('com.muralis.qcx.erro.erroSalvarTransportadoraEspecifica', { transportadora: data?.pessoaJuridica?.nome, erro: response?.data?.message }),
        }));
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data, step, next) => {
    const normalizedData = normalize(data);

    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData, step);
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const handleAlternativeSave = async (event, step, next) => {
    if (event) {
      event.stopPropagation();
    }

    const normalizedData = normalize(transportadora);

    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData, step);
    } else {
      await createByStep(normalizedData, step, next);
    }
  };

  const model = useMemo(() => (
    isCreated || !isCreate
      ? unnormalize(transportadora)
      : {}
  ), [isCreate, isCreated, transportadora, unnormalize]);

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = useMemo(() => ([
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionaisTransportadoras'),
        name: t('com.muralis.qcx.transportadora.labelPlural'),
      },
    },
    {
      text: {
        name: actionName,
      },
    },
  ]), [actionName]);

  const pageTitle = useMemo(() => (
    isNone || isCreate || isBackgroundCreate
      ? t('com.muralis.qcx.transportadora.novaTransportadora')
      : t('com.muralis.qcx.transportadora.transportadoraExistente', { ID: transportadora?.pessoaJuridica?.nome || transportadora?.id || '-' })
  ), [isNone, isCreate, isBackgroundCreate, transportadora]);

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isPreparingAction={isPreparingAction}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      showSubtitle={false}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXTransportadoraWizardFinalForm
          model={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          handleAlternativeSave={handleAlternativeSave}
          authInfo={authInfo}
          requiredRoles={['transportadora']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
