import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectDocumentoAnexacao } from '../../features/documento-anexacao/documentoAnexacaoSlice';
import { isAutoLoadControl } from '../../utils/store/store-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectDocumentoAnexacao({
  label,
  name,
  autoLoad,
  initialValues,
  fnAction,
  ...restProps
}) {
  const dispatch = useDispatch();
  const list = useSelector(selectDocumentoAnexacao);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  const [defaultValue, setDefaultValue] = useState('');

  const atualizaEstadoPai = (id) => {
    if (_.isFunction(fnAction)) {
      fnAction(id);
    }
  }

  useEffect(() => {
    console.log(initialValues)
    const value = list.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
      atualizaEstadoPai(value.id);
    } else {
      setDefaultValue({ id: null, description: '' });
      atualizaEstadoPai(null);
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.idDocumento} - ${item?.nomeTipoDocumento}`,
  });
  
  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (item?.idDocumento && item?.nomeTipoDocumento ? `${item?.idDocumento} - ${item?.nomeTipoDocumento}` : '')}
      {...restProps}
    />
  );
}
