/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { Grid } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import QCXMoveFocusInside from '../../../../../../../components/focus-lock/QCXMoveFocusInside';
import QCXFormTitle from '../../../../../../../components/form-title/QCXFormTitle';
import ReferenciaClienteBondManager from '../../../../../../../shared-components/dados-embarque/bond-managers/ReferenciaClienteBondManager';
import AnalistaResponsavelSelect from '../../../../../../../shared-components/dados-embarque/selects/AnalistaResponsavelSelect';
import AutorizacaoEmbarqueSelect from '../../../../../../../shared-components/dados-embarque/selects/AutorizacaoEmbarqueSelect';
import DesovaSelect from '../../../../../../../shared-components/dados-embarque/selects/DesovaSelect';
import TipoCargaSelect from '../../../../../../../shared-components/dados-embarque/selects/TipoCargaSelect';
import TipoDesovaSelect from '../../../../../../../shared-components/dados-embarque/selects/TipoDesovaSelect';
import TipoEstufagemSelect from '../../../../../../../shared-components/dados-embarque/selects/TipoEstufagemSelect';
import QCXFinalTextField from '../../../../../../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../../../../../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectRecintoAduaneiroAutocomplete from '../../../../../../../shared-components/select-recinto-aduaneiro/QCXSelectRecintoAduaneiroAutocomplete';
import QCXSelectUnidadeNegocioAutocomplete from '../../../../../../../shared-components/select-unidade-negocio/QCXSelectUnidadeNegocioAutocomplete';
import { isConsultMode, isCreateMode } from '../../../../../../../utils/store/store-utils';
import { required } from '../../../../../../../utils/validators/field/validator';
import QCXSelectFollowUpImportacaoAutocompleteField from '../../../DadosEmbarqueConsultPage/components/QCXSelectFollowUpImportacaoAutocompleteField';
import { selectMode } from '../../../api/dadosEmbarqueSelectors';
import OrgaoAnuenteBondManager from '../bondmanagers/OrgaoAnuenteBondManager';
import EmbalagemMadeiraSelect from '../selects/EmbalagemMadeiraSelect';
import NecessidadeLiSelect from '../selects/NecessidadeLiSelect';
import QCXSelectEmbarcacaoAutocomplete from '../../../../../../../shared-components/select-embarcacao/QCXSelectEmbarcacaoAutocomplete';
import QCXSelectTransportadorInternacionalAutocomplete from '../../../../../../../shared-components/select-transportador-internacional/QCXSelectTransportadorInternacionalAutocomplete';
import QCXSelectPortoAeroportoAutocomplete from '../../../../../../../shared-components/select-porto-aeroporto/QCXSelectPortoAeroportoAutocomplete';

export default function DadosEmbarqueBasicasStepForm({
  initialValues,
  handleSubmit,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();

  const mode = useSelector(selectMode);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      disablePristine
      {...restProps}
    >
      {({ values }) => (
        <>
          <QCXFormTitle title={t('com.muralis.qcx.fichaBasicas')} />
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            <Grid item xs={6}>
              <QCXMoveFocusInside>
                <QCXSelectFollowUpImportacaoAutocompleteField
                  key="select-autocomplete-processo-field"
                  id="select-autocomplete-processo-field"
                  name="followUp"
                  label={t('com.muralis.qcx.processo')}
                  validate={required}
                  isCreate={isCreate}
                  disabled
                  required
                />
              </QCXMoveFocusInside>
            </Grid>

            <Grid item container alignContent="flex-start" xs={12}>
              <Grid item xs={12}>
                <ReferenciaClienteBondManager isConsult={isConsult} />
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <AnalistaResponsavelSelect
                key="analista-responsavel-select-field"
                id="analista-responsavel-select-field"
                name="usuarioResponsavel"
                label={t('com.muralis.qcx.dadosEmbarque.analistaResponsavel')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXSelectUnidadeNegocioAutocomplete
                key="unidade-negocio-select-field"
                id="unidade-negocio-select-field"
                name="unidadeNegocioFaturamento.id"
                label={t('com.muralis.qcx.dadosEmbarque.unidadeNegocioFaturamento')}
                disabled={isConsult}
                autoLoad
              />
            </Grid>

            <Grid item xs={3}>
              <NecessidadeLiSelect
                key="select-necessidade-li-field"
                id="select-necessidade-li-field"
                name="ignorableFields.flags.necessidadeLi"
                label={t('com.muralis.qcx.dadosEmbarque.necessidadeLi')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={3}>
              <TipoEstufagemSelect
                key="select-tipo-estufagem-field"
                id="select-tipo-estufagem-field"
                name="tipoEstufagem"
                label={t('com.muralis.qcx.dadosEmbarque.tipoEstufagem')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXSelectEmbarcacaoAutocomplete
                id="text-field-embarcacao-transporte"
                key="text-field-embarcacao-transporte"
                name="embarcacao"
                hideCode
                label={t('com.muralis.qcx.embarcacao.label')}
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>

            {values.ignorableFields?.flags?.necessidadeLi ? (
              <Grid item container alignContent="flex-start" xs={12}>
                <Grid item xs={12}>
                  <OrgaoAnuenteBondManager isConsult={isConsult} />
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            <Grid item xs={values.ignorableFields?.flags?.desova ? 4 : 6}>
              <AutorizacaoEmbarqueSelect
                key="select-autorizacao-embarque-field"
                id="select-autorizacao-embarque-field"
                name="autorizacaoEmbarque"
                label={t('com.muralis.qcx.dadosEmbarque.autorizacaoEmbarque')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={values.ignorableFields?.flags?.desova ? 4 : 6}>
              <DesovaSelect
                key="select-desova-field"
                id="select-desova-field"
                name="ignorableFields.flags.desova"
                label={t('com.muralis.qcx.dadosEmbarque.desova')}
                disabled={isConsult}
              />
            </Grid>

            {values.ignorableFields?.flags?.desova ? (
              <Grid item xs={4}>
                <TipoDesovaSelect
                  key="select-tipo-desova-field"
                  id="select-tipo-desova-field"
                  name="tipoDesova"
                  label={t('com.muralis.qcx.dadosEmbarque.tipoDesova')}
                  disabled={isConsult}
                />
              </Grid>
            ) : (
              <></>
            )}

            <Grid item xs={6}>
              <TipoCargaSelect
                key="select-tipo-carga-field"
                id="select-tipo-carga-field"
                name="tipoCarga"
                label={t('com.muralis.qcx.dadosEmbarque.tipoCarga')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXSelectRecintoAduaneiroAutocomplete
                id="select-field-local-"
                key="select-field-local-"
                name="terminalEmbarque.id"
                label={t('com.muralis.qcx.dadosEmbarque.terminalChegada')}
                disabled={isConsult}
                initialValues={values}
                autoLoad
              />
            </Grid>

            <Grid item xs={6}>
              <EmbalagemMadeiraSelect
                key="select-embalagem-madeira-field"
                id="select-embalagem-madeira-field"
                name="embalagemMadeira"
                label={t('com.muralis.qcx.dadosEmbarque.embalagemMadeira')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXSelectRecintoAduaneiroAutocomplete
                id="select-field-local-"
                key="select-field-local-"
                name="terminalRedestinacao.id"
                label={t('com.muralis.qcx.dadosEmbarque.terminalRedestinacao')}
                disabled={isConsult}
                initialValues={values}
                autoLoad
              />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalTextField
                key="bu-cliente-field"
                id="bu-cliente-field"
                name="buCliente"
                label={t('com.muralis.qcx.dadosEmbarque.buCliente')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXSelectRecintoAduaneiroAutocomplete
                id="select-field-local-"
                key="select-field-local-"
                name="terminalDespacho.id"
                label={t('com.muralis.qcx.dadosEmbarque.terminalDespacho')}
                disabled={isConsult}
                initialValues={values}
                autoLoad
              />
            </Grid>

            <Grid item xs={6}>
              <QCXSelectTransportadorInternacionalAutocomplete
                key="transportadora-internacional-field"
                id="transportadora-internacional-field"
                name="agenteCarga"
                initialValues={values}
                label={t('com.muralis.qcx.dadosEmbarque.agenteCarga')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXSelectPortoAeroportoAutocomplete
                id="autocomplete-select-local-embarque"
                key="autocomplete-select-local-embarque"
                name="localEmbarque.id"
                label={
                  t('com.muralis.qcx.localEmbarque')
                }
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalTextField
                key="resumo-mercadoria-field"
                id="resumo-mercadoria-field"
                name="resumoMercadoria"
                label={t('com.muralis.qcx.dadosEmbarque.resumoMercadoria')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalTextField
                key="observacao-field"
                id="observacao-field"
                name="observacoes"
                label={t('com.muralis.qcx.dadosEmbarque.observacoes')}
                disabled={isConsult}
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
