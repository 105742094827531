import axios from 'axios';
import { BasicFormData } from './leitura/ThermoFisherLeituraPage';
import { saveAs } from 'file-saver';
import { GenerateDanfeResponse } from '../triangulus/triangulus.helpers';

const uploadFile = async (value: BasicFormData, unidadeId: number, token: string, url: string) => {
  let formData = new FormData();

  if (value.cliente && value.cliente.id !== null && value.cliente.id !== undefined) {
    formData.append('clienteId', value.cliente.id.toString());
  }

  if (value.servico && value.servico.id !== null && value.servico.id !== undefined) {
    formData.append('servicoId', value.servico.id.toString());
  }

  if (value.arquivo) {
    formData.append('bodyData', value.arquivo);
  }

  const response = await axios
    .post(`${url}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        quickcomexTenant: unidadeId,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      }
    });

  return response;
};

export async function downloadFile(
  token: string,
  selectedUnidade: string,
  url: string,
  filename: string
): Promise<GenerateDanfeResponse> {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.append('Authorization', `Bearer ${token}`);
  requestHeaders.append('quickcomexTenant', selectedUnidade);

  const response = await fetch(url, {
    method: 'GET',
    headers: requestHeaders,
  });

  if (!response.ok) {
    try {
      const data = await response.json();
      const message: string = data.message;
      return { statusCode: 500, message: `Erro no download: ${message}` };
    } catch (error) {
      return { statusCode: 500, message: 'Erro ao gerar Csv da Danfe' };
    }
  }

  const blob = await response.blob();
  saveAs(blob, filename + '.csv');
  return { statusCode: 200, message: `Download realizado com sucesso!` };
}

const thermoFisherPageHelpers = {
  uploadFile,
  downloadFile,
};

export default thermoFisherPageHelpers;
