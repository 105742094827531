import { Grid } from '@material-ui/core';
import { isFunction } from 'lodash';
import { useForm, useFormState } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useTranslation } from 'react-i18next';
import { taxaConversaoExportacaoAPI } from '../../features/taxa-conversao-exportacao/taxaConversaoCambioAPI';
import { convertToYYYYMMDD } from '../../utils/general/dateTime-utils';
import { unnormalizeNumeral } from '../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../utils/hooks/form/field/formatters';
import QCXFinalCheckboxField from '../final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalAlternativeCurrencyField from '../final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXSelectMoedaAutocomplete from '../final-select-moeda-field/QCXSelectMoedaAutocomplete';

export default function QCXMoedaMleFormGroup({
  isConsult,
  handleValorNaMoedaOnBlur,
  handleMoedaMLEDUEOnBlur,
  valorValidators,
  submitSucceeded,
  intl,
  required = true,
  openAskRepassarMoedasPopUp,
}) {
  const { t } = useTranslation();

  const form = useForm();
  const { values } = useFormState();
  const tipo = values?.atributosAdicionais?.tipo;

  async function updateTaxa(moedaId, localValues) {
    const danfes = localValues?.followUp?.danfes;

    danfes?.forEach(async (element, index) => {
      if (moedaId === 153887) {
        form.change(`followUp.danfes[${index}].taxaConversao`, unnormalizeNumeral(1, formatBrazilianNumericDecimal(7)));
        return;
      }

      const response = await taxaConversaoExportacaoAPI.fetchByDayAndMoedaId(
        convertToYYYYMMDD(element?.dataEmissao),
        moedaId
      );

      if (response.status !== 200) return;

      const taxa = response.data;

      if (!taxa) return;

      const unnormalizedValue = unnormalizeNumeral(taxa?.taxaConversao, formatBrazilianNumericDecimal(7));

      form.change(`followUp.danfes[${index}].taxaConversao`, unnormalizedValue ?? '');
    });
  }

  async function clearMoeda(value) {
    if (value) return;

    form.change('mle.moeda', '');
    form.change('mle.valorMoeda', '');
  }

  function askRepasseMoeda() {
    if (!isFunction(openAskRepassarMoedasPopUp)) {
      return;
    }

    openAskRepassarMoedasPopUp({ args: { form, values } });
  }

  return (
    <>
      {tipo === 'DI_DUIMP' && (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <QCXFinalCheckboxField
            id="checkbox-mesma-moeda-condicao-venda"
            key="checkbox-mesma-moeda-condicao-venda"
            name="mle.mesmaMoedaCondicaoVenda"
            label={t('com.muralis.qcx.moeda.mesmaMoedaCondicaoVenda')}
            disabled={isConsult}
          />
          <OnChange name="mle.mesmaMoedaCondicaoVenda">{(value) => clearMoeda(value)}</OnChange>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={4} lg={4}>
        {(tipo !== 'DI_DUIMP' || values?.mle?.mesmaMoedaCondicaoVenda) && (
          <QCXSelectMoedaAutocomplete
            id="select-field-moeda-vmle"
            key="select-field-moeda-vmle"
            name="mle.moeda.id"
            label={t('com.muralis.qcx.fatura.moedaMLE')}
            onBlur={tipo === 'DUE' && handleMoedaMLEDUEOnBlur(form, values)}
            disabled={isConsult}
            initialValues={values}
            required={required}
          />
        )}
        <OnChange name="mle.moeda.id">
          {(value) => {
            updateTaxa(value, values);
            askRepasseMoeda();
          }}
        </OnChange>
      </Grid>

      <Grid item xs={12} sm={12} md={4} lg={4}>
        {(tipo !== 'DI_DUIMP' || values?.mle?.mesmaMoedaCondicaoVenda) && (
          <QCXFinalAlternativeCurrencyField
            id="currency-valor-moeda-vmle-field"
            key="currency-valor-moeda-vmle-field"
            name="mle.valorMoeda"
            label={t('com.muralis.qcx.moeda.valorMoedaMLE')}
            validate={valorValidators}
            onBlur={handleValorNaMoedaOnBlur(form, values, 'mle.moeda', 'mle.valorMoeda', 'mle.valorReal')}
            disabled={isConsult || !values?.mle?.moeda?.id}
            submitSucceeded={submitSucceeded}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <QCXFinalAlternativeCurrencyField
          id="currency-valor-real-moeda-vmle-field"
          key="currency-valor-real-moeda-vmle-field"
          name="mle.valorReal"
          label={t('com.muralis.qcx.moeda.valorMoedaMLEReais')}
          intl={intl}
          adorned
          disabled
          submitSucceeded={submitSucceeded}
          helperText={!isConsult && t('com.muralis.qcx.mensagem.arredondamentoValor')}
        />
      </Grid>
    </>
  );
}
