import { CatalogoProdutosAtributosFormValues } from '../types/CatalogoProdutosAtributosFormValues';
import { CatalogoProdutosSaveAtributosAPIPayload } from '../types/CatalogoProdutosSaveAtributosAPIPayload';

function normalizeAtributos(formValues: CatalogoProdutosAtributosFormValues): CatalogoProdutosSaveAtributosAPIPayload {
  const ids = formValues.meta.selected.map((selected) => selected.id);

  const attrPreenchidos = formValues?.atributosPreenchidos;

  const normalizedAtributosSimples = getAtributosSimplesPayload(attrPreenchidos.atributos);
  const normalizedAtributosCompostos = getAtributosCompostosPayload(attrPreenchidos?.atributosCompostos);
  const normalizedAtributosMultivalorados = getAtributosMultivaloradosPayload(attrPreenchidos?.atributosMultivalorados);

  const tipo = !!formValues.tipo?.includes('DUIMP');

  const normalizedExportadoresIds = formValues.exportadores?.map((e) => e.id) ?? [];
  const normalizedRelacaoFabricantes =
    formValues.relacaoFabricantes?.map((f) => ({
      fabricanteId: f.fabricante ? f.fabricante.id : null,
      paisId: f.pais.id,
    })) ?? [];

  return {
    ids,
    atributos: normalizedAtributosSimples,
    atributosCompostos: normalizedAtributosCompostos,
    atributosMultivalorados: normalizedAtributosMultivalorados,
    repassarDuimp: tipo,
    repassarPartnumberComoCodigoInterno: !!formValues.usePartnumberAsInternalCode,
    repassarCodigoSecundarioComoCodigoInterno: !!formValues.useSecondaryCodeAsInternalCode,
    relacao: formValues?.relacao,
    exportadoresIds: normalizedExportadoresIds,
    relacaoFabricantes: normalizedRelacaoFabricantes,
  };
}

function getAtributosSimplesPayload(
  atributosSimplesFormValue: CatalogoProdutosAtributosFormValues['atributosPreenchidos']['atributos'] | undefined
): CatalogoProdutosSaveAtributosAPIPayload['atributos'] {
  if (atributosSimplesFormValue === undefined) return [];

  return Object.keys(atributosSimplesFormValue).map((key) => {
    const atributo = atributosSimplesFormValue[key];

    return {
      atributo: key,
      valor: String(atributo.valor),
    };
  });
}

const atributosNormalization = {
  normalizeAtributos,
};

function getAtributosCompostosPayload(
  atributosCompostosFormValue:
    | CatalogoProdutosAtributosFormValues['atributosPreenchidos']['atributosCompostos']
    | undefined
): CatalogoProdutosSaveAtributosAPIPayload['atributosCompostos'] {
  if (atributosCompostosFormValue === undefined) return [];

  return Object.keys(atributosCompostosFormValue).map((key) => {
    const atributo = atributosCompostosFormValue[key];
    const atributosSimples = getAtributosSimplesPayload(atributo.valores);

    return {
      atributo: key,
      valores: atributosSimples,
    };
  });
}

function getAtributosMultivaloradosPayload(
  atributosMultivaloradosFormValue:
    | CatalogoProdutosAtributosFormValues['atributosPreenchidos']['atributosMultivalorados']
    | undefined
): CatalogoProdutosSaveAtributosAPIPayload['atributosMultivalorados'] {
  if (atributosMultivaloradosFormValue === undefined) return [];

  return Object.keys(atributosMultivaloradosFormValue).map((key) => {
    const atributo = atributosMultivaloradosFormValue[key];

    return {
      atributo: key,
      valores: atributo.valores,
    };
  });
}

export default atributosNormalization;
