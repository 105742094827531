import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useFindPath = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState<string>();
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);
  return currentPath;
};

export const useLastPathPart = () => {
  const location = useLocation();

  const {clientId} = location.state as {clientId: string};

  const [currentPath, setCurrentPath] = useState<string>();
  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  if (!currentPath) {
    return ['', clientId];
  }

  const currentPathArray = currentPath.split('/');
  const currentPathLastPart = currentPathArray[currentPathArray.length - 1];
  return [currentPathLastPart, clientId];
};
