import axios from "axios";

const baseUrl = process.env.REACT_APP_QCX_API_BASE_URL! + "/"
    + process.env.REACT_APP_RELATORIOS_API_MAPA_PROCESSOS!;

export const generateMapaProcessosReport = async (
    token: string,
    unidadeId: string  // Added to receive the selected unit ID
): Promise<string | null> => {

    try {
        const response = await axios.get(
            `${baseUrl}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                    quickcomexTenant: unidadeId,  // Include the selected unit ID in the headers
                },
                responseType: "blob",
            }
        );

        if (response.status === 200) {
            const file = new File([response.data], "mapaProcessos.xlsx", {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            const url = window.URL.createObjectURL(file);
            const a = document.createElement("a");
            a.href = url;
            a.download = file.name;
            a.click();
        } else {
            return await response.data.text();
        }
    } catch (error) {
        return axios.isAxiosError(error) ? "Erro inesperado" : "Erro ao gerar o relatório.";
    }

    return null;
};
