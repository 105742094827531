import { Grid } from '@material-ui/core';
import { useFormState, useForm } from 'react-final-form';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { 
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle'
import QCXFinalBondManager from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManager';
import QCXSelectEmpresaEstrangeiraAutocomplete from '../../shared-components/final-select-empresa-estrangeira-field/QCXSelectEmpresaEstrangeiraAutocomplete';
import {
    fetchAllAsync,
    selectEmpresasEstrangeiras,
    fetchFilteredAsync,
    fetchByIdAndInsertList,
} from '../../features/empresa-estrangeira/empresaEstrangeiraSlice';
import QCXSelectBoolean from '../../shared-components/select-boolean/QCXSelectBoolean';
import QCXSelectPaisAutocomplete from '../../shared-components/select-pais/QCXSelectPaisAutocomplete';
import {
  selectPaises,
} from '../../features/pais/paisSlice';

import { required as requiredValidator } from '../../utils/validators/field/validator';


export default function QCXSelectManyFabricantes({ ...props }) {

  const {
    title,
    name,
    exportador,
    inputConfig,
    isConsult,
    isAlternativeReasonToDisabled,
    autoCompleteProps = {},
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showListFabricantes, setShowListFabricantes] = useState(false);

  const form = useForm();
  const { values } = useFormState();

  const list = useSelector(selectEmpresasEstrangeiras);
  const paisesList = useSelector(selectPaises);

  useEffect(() => {
    dispatch(fetchFilteredAsync());
  }, []);

  useEffect(() => {
    const icConhecido = _.get(form.getState().values, "ignorableFields.fornecedor.icFabricanteConhecido")

    if (icConhecido === "SIM") {
      setShowListFabricantes(true)
    } else {
      setShowListFabricantes(false)
    }    
  }, [values]);

  const getEndereco = (row) => {
    const enderecoFabricante = _.get(row, "fabricante.endereco")
    let strEndereco = ""


    if(enderecoFabricante) {
      if(enderecoFabricante?.logradouro) {
        strEndereco += enderecoFabricante?.logradouro
      }

      if(enderecoFabricante?.pais?.description) {
        if(strEndereco) strEndereco += " - "
        strEndereco += enderecoFabricante?.pais?.description
      }

      return strEndereco
    }

    if(row?.pais?.description) {
      strEndereco = ""
      strEndereco += row?.pais?.description
    }
    
    return strEndereco

  }

  const fornecedorProps = useMemo(() => ({
      name,
      columns: [
        {
          field: 'pessoaJuridica.id',
          headerName: t('com.muralis.qcx.codigo'),
          headerAlign: 'center',
          align: 'center',
          type: 'string',
          flex: 50,
          valueGetter: ({ row }) => row?.fabricante?.pessoaJuridica?.id,
        },
        {
          field: 'pessoaJuridica.nomeResumido',
          headerName: t('com.muralis.qcx.cliente.label'),
          type: 'string',
          flex: 300,
          valueGetter: ({ row }) => row?.fabricante?.pessoaJuridica?.nomeResumido,
        },
        {
          field: 'isConhecido',
          headerName: "Conhecido ?",
          type: 'string',
          flex: 300,
          valueGetter: ({ row }) => row?.isConhecido ? "SIM" : "NÃO",
        },
        {
          field: 'endereco.pais.description',
          headerName: t('com.muralis.qcx.endereco.endereco'),
          type: 'string',
          flex: 300,
          valueGetter: ({ row }) => getEndereco(row),
        }
      ],
  }), [list]);

  const fornecedorFormProps = useMemo(() => ({
    rootName: `ignorableFields.fornecedor`,
    fields: [
      {
        name: 'fabricante',
        label: "Fabricante",
        required: false,
      }
    ],
  }), []);

  const resetFormAddFornecedor = () => {
    form.change("ignorableFields.fornecedor.fabricante",  null)
    form.change("ignorableFields.fornecedor.paisFabricante",  null)
    form.change("ignorableFields.fornecedor.icFabricanteConhecido",  null)
    setShowListFabricantes(null)
  }

  const handleAddFornecedor= useCallback(async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    const inserirObjFabricante = (fabricanteObj) => {
      const fabricantesForm = form.getState().values?.relacaoFabricantes

      if (fabricantesForm && Array.isArray(fabricantesForm)) {
        const fabricanteClone = _.cloneDeep(fabricanteObj)
        form.change("relacaoFabricantes", [...fabricantesForm,  fabricanteClone])
      } else if(fabricanteObj) {
        const fabricanteClone = _.cloneDeep(fabricanteObj)
        form.change("relacaoFabricantes", [fabricanteClone])
      }
    }

    if (!list || (list && Array.isArray(list) && list.length === 0)) {
      console.log(`sem lista de fornecedores`)
      return
    }

    const idCatalogo = _.get(form.getState().values, "id")
    const paisId = _.get(form.getState().values, "ignorableFields.fornecedor.paisFabricante")
    const icConhecido = _.get(form.getState().values, "ignorableFields.fornecedor.icFabricanteConhecido")
    
    const relacaoFabricante = {
      id: uuid(),
      isConhecido: null,
      catalogoProduto: null,
      fabricante: null,
      pais: null,
    }

    if (icConhecido !== "SIM" && paisesList && Array.isArray(paisesList) && paisesList.length && paisId) {
      const paisSelecionado = paisesList.find(el => el.id === paisId);
      if (!paisSelecionado) return ;

      relacaoFabricante.isConhecido = false;
      relacaoFabricante.catalogoProduto = idCatalogo
      relacaoFabricante.fabricante = null
      relacaoFabricante.pais = paisSelecionado
      
      inserirObjFabricante(relacaoFabricante)
      resetFormAddFornecedor()
      return ;
    }

    if(currentVinculo.fabricante) {
      const id = currentVinculo.fabricante
      const fabricanteSelecionado = list.find(el => el.id === id);

      relacaoFabricante.isConhecido = true;
      relacaoFabricante.catalogoProduto = idCatalogo
      relacaoFabricante.fabricante = fabricanteSelecionado;
      relacaoFabricante.pais = _.get(fabricanteSelecionado, "endereco.pais");

      inserirObjFabricante(relacaoFabricante)
      
    }

    resetFormAddFornecedor()
  
  }, [list, paisesList]);

  const handleUpdateFornecedor = useCallback( async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {

    const idVinculoAntigo = currentVinculos.findIndex(objeto => objeto.id === currentVinculo.id);
    if(idVinculoAntigo !== -1) {

      const exportadorId = _.get(form.getState().values, "ignorableFields.fornecedor.fabricante")
      const exportadorNovo = list.find(el => el.id === exportadorId)

      if(exportadorNovo) {
        const exportadorCopia = _.cloneDeep(exportadorNovo);
        const arrVinculos = [...currentVinculos]
        arrVinculos[idVinculoAntigo] = exportadorCopia
        form.change("fabricantes", arrVinculos)
      }
    }
  }, [list, paisesList]);


  const fabricanteFilterPredicate = useCallback((empresaEstrangeira) => (
    empresaEstrangeira?.fabricante && empresaEstrangeira?.active
  ), []);

  return (
    <>
      <Grid item xs={12}>
        <QCXFormSubtitle title={title} />
      </Grid>

      <QCXFinalBondManager
        isParentConsult={isConsult}
        listProps={fornecedorProps}
        formProps={fornecedorFormProps}
        handleAdd={handleAddFornecedor}
        handleUpdate={handleUpdateFornecedor}
        forceShowDelete
      >
        
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <QCXSelectBoolean
            id="select-agrupar-exportador"
            key="select-agrupar-exportador"
            name="ignorableFields.fornecedor.icFabricanteConhecido"
            label="Fabricante/produtor conhecido ?"
            initialValues={values}
            disabled={isConsult}
          />
        </Grid>

        { showListFabricantes !== null && showListFabricantes !== undefined && !showListFabricantes && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              
              <QCXSelectPaisAutocomplete
                name="ignorableFields.fornecedor.paisFabricante"
                label={t('com.muralis.qcx.endereco.pais')}
                // fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                initialValues={values}
              />
              
            </Grid>
          )}
  
          { showListFabricantes && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXSelectEmpresaEstrangeiraAutocomplete
                id="select-field-empresa-estrangeira-fabricante"
                key="select-field-empresa-estrangeira-fabricante"
                name="ignorableFields.fornecedor.fabricante"
                label={t('com.muralis.qcx.fabricante.label')}
                initialValues={values}
                filterPredicate={fabricanteFilterPredicate}
                // fieldProps={{
                //   validate: requiredValidator,
                // }}
                disabled={isConsult || isAlternativeReasonToDisabled}            
                {...autoCompleteProps}
              />
            </Grid>
          )}
           
      </QCXFinalBondManager>
    </>
  );
}
