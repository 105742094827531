/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { omit } from 'lodash';

import {
  activateById,
  fetchById,
  inactivateById,
  calculateById,
  fetchByFilter,
  repasseByProcessos,
  fetchFaturasByFilter,
  repasseFaturaByProcessos,
  limparAcrescimosDeclaracaoImportacao,
} from './faturaAPI';
import { setErrorFeedback, setSuccessFeedback } from '../feedback/feedbackSlice';

const fetchAllAsync = createAsyncThunk('fatura/fetchAll', async () => {
  const { data } = await fetchByFilter();
  return { data };
});

const fetchFaturaByFilterAsync = createAsyncThunk('fatura/fetchByFilter', async (payload: any, { rejectWithValue }) => {
  try {
    const { data } = await fetchFaturasByFilter(payload);
    return { data };
  } catch (error: any) {
    console.log('error', error);
    return rejectWithValue(error.response.data);
  }
});

const fetchByIdAsync = createAsyncThunk('fatura/fetchById', async (data) => {
  const response = await fetchById(data);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const activateByIdAsync = createAsyncThunk('fatura/activateById', async (id) => {
  const { status, data } = await activateById(id);
  return { response: { status, data } };
});

const inactivateByIdAsync = createAsyncThunk('fatura/inactivateById', async (id, { rejectWithValue, dispatch }) => {
  try {
    const { status, data } = await inactivateById(id);

    dispatch(setSuccessFeedback({ message: 'Fatura inativada com sucesso!' }));

    return { response: { status, data } };
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

const repasseDisByProcessosAsync = createAsyncThunk(
  'etapaFollowUp/repasseByProcessos',
  async ({ payload, params }: any, thunkApi) => {
    try {
      const response = await repasseByProcessos(payload, params);

      if (response.status === 200) {
        const message = `Repasse realizado com sucesso! ${response?.data?.message ?? ''}`;
        thunkApi.dispatch(setSuccessFeedback({ message }));
      } else {
        thunkApi.dispatch(setErrorFeedback({ message: 'Erro ao realizar repasse!' }));
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
        },
      };
    } catch (error: any) {
      thunkApi.dispatch(setErrorFeedback({ message: 'Erro ao realizar repasse!' }));
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

const repasseFaturaByProcessosAsync = createAsyncThunk(
  'etapaFollowUp/repasseFaturaByProcessos',
  async ({ payload, params }: any, thunkApi) => {
    try {
      const response = await repasseFaturaByProcessos(payload, params);

      if (response.status === 200) {
        const message = `Repasse realizado com sucesso! ${response?.data?.message ?? ''}`;
        thunkApi.dispatch(setSuccessFeedback({ message }));
      } else {
        thunkApi.dispatch(setErrorFeedback({ message: 'Erro ao realizar repasse!' }));
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
        },
      };
    } catch (error: any) {
      thunkApi.dispatch(setErrorFeedback({ message: 'Erro ao realizar repasse!' }));
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);

const calculateByIdAsync = createAsyncThunk(
  'fatura/calculateById',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await calculateById(data?.id);

      if (response?.status === 200) {
        dispatch(setSuccessFeedback({ message: 'Fatura calculada com sucesso!' }));
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error: any) {
      dispatch(setErrorFeedback({ message: `Erro ao calcular fatura: ${error.response.data.message}` }));

      return rejectWithValue(error.response.data);
    }
  }
);

const limparAcrescimosAsync = createAsyncThunk(
  'fatura/limparAcrescimos',
  async ({ data, finishCallback }: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await limparAcrescimosDeclaracaoImportacao(data);

      if (response?.status === 200) {
        dispatch(setSuccessFeedback({ message: 'Acrescimos limpos com sucesso!' }));
        finishCallback();
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error: any) {
      dispatch(setErrorFeedback({ message: `Erro ao limpar acrescimos: ${error.response.data.message}` }));

      return rejectWithValue(error.response.data);
    }
  }
);

export {
  fetchAllAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  calculateByIdAsync,
  repasseDisByProcessosAsync,
  fetchFaturaByFilterAsync,
  repasseFaturaByProcessosAsync,
  limparAcrescimosAsync,
};
