/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import { Box, Grid, MenuItem, Typography, makeStyles } from '@material-ui/core';
import { FileUpload as FileUploadIcon } from '@mui/icons-material';
import { isArray, isEmpty, isFunction } from 'lodash';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid, validate } from 'uuid';
import { acrescimoDeducaoDeclaracaoImportacaoSelectors } from '../../../features/acrescimo-deducao-declaracao-importacao/acrescimoDeducaoDeclaracaoImportacaoSlice';
import { cfopOperacaoFiscalActions } from '../../../features/cfop-operacoes-fiscais/cfopOperacoesFiscaisSlice';
import { AUTO_LOAD_CONTROL } from '../../../features/config-control';
import { selectRelatedAdicaoModel } from '../../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import {
  changeToSubConsultMode,
  failure,
  loading,
  resetBackgroundMode,
  resetRelatedAdicaoModel,
  setRelatedAdicaoModel,
  setResponse,
  success,
} from '../../../features/declaracao-importacao/declaracaoImportacaoSlice';
import {
  fetchAdicoesByDiDuimpIdAsync,
  fetchByIdFromFaturaAsync,
  fetchMercadoriasByFaturaIdAsync,
} from '../../../features/declaracao-importacao/declaracaoImportacaoThunks';
import { setErrorFeedback, setSuccessFeedback } from '../../../features/feedback/feedbackSlice';
import { mercadoriaMovimentacaoAPI } from '../../../features/mercadoria-movimentacao/mercadoriaMovimentacaoAPI';
import { selectMetodosDeValoracao } from '../../../features/metodo-valoracao/metodoValoracaoSlice';
import { selectMoedas } from '../../../features/moeda/moedaSlice';
import { fetchAllAsync as fetchAllMotivosImportacaoSemCoberturaCambialAsync } from '../../../features/motivo-importacao-sem-cobertura-cambial/motivoImportacaoSemCoberturaCambialSlice';
import { ncmSubitemActions } from '../../../features/ncm-subitem/ncmSubitemSlice';
import { nveAtributoActions } from '../../../features/nve-atributo/nveAtributoSlice';
import { nveEspecificacaoActions } from '../../../features/nve-especificacao/nveEspecificacaoSlice';
import { nveActions } from '../../../features/nve/nveSlice';
import {
  fetchAllAsync as fetchAllRegimeTributacaoAsync,
  selectRegimesTributacao,
} from '../../../features/regime-tributacao/regimeTributacaoSlice';
import { selectTiposDeclaracao } from '../../../features/tipo-declaracao/tipoDeclaracaoSlice';
import QCXAFRMMFormGroup from '../../../shared-components/afrmm-form-group/QCXAFRMMFormGroup';
import QCXInfoAlert from '../../../shared-components/alert/QCXInfoAlert';
import QCXAloneInlineBoxWrapper from '../../../shared-components/alone-inline-box-wrapper/QCXAloneInlineBoxWrapper';
import QCXAntidumpingFormGroup from '../../../shared-components/antidumping-form-group/QCXAntidumpingFormGroup';
import QCXConfirmDialog from '../../../shared-components/dialog/QCXConfirmDialog';
import QCXFinalCheckboxField from '../../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalAlternativeCurrencyField from '../../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalDatePickerField from '../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalNumericIntegerField from '../../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import QCXFinalOnblurMaskedField from '../../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import QCXSelectIncotermCondicaoVendaAutocomplete from '../../../shared-components/final-select-incoterm-condicao-venda-field/QCXSelectIncotermCondicaoVendaAutocomplete';
import QCXFinalMultilineTextField from '../../../shared-components/final-text-field/QCXFinalMultilineTextField';
import QCXFinalTextField from '../../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXIcmsFormGroup from '../../../shared-components/icms-form-group/QCXIcmsFormGroup';
import QCXIIFormGroup from '../../../shared-components/ii-form-group/QCXIIFormGroup';
import QCXIPIFormGroup from '../../../shared-components/ipi-form-group/QCXIPIFormGroup';
import useIpiListenersGroup from '../../../shared-components/ipi-form-group/ipiFormGroupListeners';
import QCXListItemIcon from '../../../shared-components/list-item-icon/QCXListItemIcon';
import QCXPisCofinsFormGroup from '../../../shared-components/pis-cofins-form-group/QCXPisCofinsFormGroup';
import QCXSelectCoberturaCambialAutocomplete from '../../../shared-components/select-cobertura-cambial/QCXSelectCoberturaCambialAutocomplete';
import {
  COBERTURA_CAMBIAL_COM_COBERTURA_180_360_DIAS,
  COBERTURA_CAMBIAL_COM_COBERTURA_ACIMA_360_DIAS,
  COBERTURA_CAMBIAL_COM_COBERTURA_ATE_180_DIAS,
  COBERTURA_CAMBIAL_SEM_COBERTURA,
  isCoberturaCambialComCoberturaAcima360Dias,
  isCoberturaCambialSemCobertura,
  isSomeCoberturaCambial,
} from '../../../shared-components/select-cobertura-cambial/coberturaCambialUtils';
import QCXSelectInstituicaoFinanciadoraAutocomplete from '../../../shared-components/select-instituicao-financiadora/QCXSelectInstituicaoFinanciadoraAutocomplete';
import QCXSelectMetodoValoracaoAutocomplete from '../../../shared-components/select-metodo-valoracao/QCXSelectMetodoValoracaoAutocomplete';
import { configureSpecificMetodoValoracaoArt1SuspensaoMatcher } from '../../../shared-components/select-metodo-valoracao/metodoValoracaoUtils';
import QCXSelectMotivoImportacaoSemCoberturaCambialAutocomplete from '../../../shared-components/select-motivo-importacao-sem-cobertura-cambial/QCXSelectMotivoImportacaoSemCoberturaCambialAutocomplete';
import QCXSelectPortoAeroportoAutocomplete from '../../../shared-components/select-porto-aeroporto/QCXSelectPortoAeroportoAutocomplete';
import {
  REGIME_TRIBUTACAO_IMUNIDADE_CODE,
  REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE,
  configureGeneralRegimeTributacaoMatcherWith,
  configureSpecificRegimeTributacaoImunidadeMatcher,
  configureSpecificRegimeTributacaoSuspensaoMatcher,
} from '../../../shared-components/select-regime-tributacao/regimeTributacaoUtils';
import {
  TIPO_ACRESCIMO,
  TIPO_DEDUCAO,
} from '../../../shared-components/select-tipo-acrescimo-deducao/tipoAcrescimoDeducaoUtils';
import {
  TIPO_DECLARACAO_ADMISSAO_EM_DAD_DEPOSITO_ADUANEIRO_DE_DISTRIBUICAO,
  TIPO_DECLARACAO_ADMISSAO_EM_DE_DAF,
  TIPO_DECLARACAO_ADMISSAO_EM_LOJA_FRANCA,
  TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA,
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
  TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
  TIPO_DECLARACAO_INTERNACAO_DE_ALC_AREA_DE_LIVRE_COMERCIO,
  TIPO_DECLARACAO_SAIDA_DE_ENTREPOSTO_INDUSTRIAL,
  configureGeneralTipoDeclaracaoMatcherWith,
} from '../../../shared-components/select-tipo-declaracao/tipoDeclaracaoUtils';
import QCXSelectVinculoCompradorVendedorAutocomplete from '../../../shared-components/select-vinculo-comprador-vendedor/QCXSelectVinculoCompradorVendedorAutocomplete';
import QCXSisComexFormGroup from '../../../shared-components/siscomex-form-group/QCXSisComexFormGroup';
import QCXCustomRegistrationTabControlTemplate from '../../../templates/custom-registration-one-to-many-page/QCXCustomRegistrationTabControlTemplate';
import useCatalogoProdutosAtributosListeners from '../../../utils/general/catalogo-produtos/catalogoProdutosAtributosListeners';
import useFaturaTotaisListeners from '../../../utils/general/fatura/faturaTotaisListeners';
import { isValid, normalizeNumeral, normalizeYear, unnormalizeNumeral } from '../../../utils/general/general-utils';
import {
  formatBrazilianNumericDecimal,
  formatLINumber,
  formatNotZero,
} from '../../../utils/hooks/form/field/formatters';
import { BRAZILIAN } from '../../../utils/hooks/form/field/mask-types';
import { numberOnly } from '../../../utils/hooks/form/field/parsers';
import useOperationMiddleware from '../../../utils/hooks/operation/middleware/useOperationMiddleware';
import { isUpdateMode } from '../../../utils/store/store-utils';
import QCXMoveFocusInside from '../../focus-lock/QCXMoveFocusInside';
import QCXFormSubtitle from '../../form-title/QCXFormSubtitle';
import QCXFormTitle from '../../form-title/QCXFormTitle';
import QCXRelacaoFornecedorFieldGroup from '../../relacao-fornecedor/QCXRelacaoFornecedorFieldGroup';
import { useFornecedorListenersGroup } from '../../relacao-fornecedor/relacao-fornecedor-utils';
import AcrescimoDeducaoBondManager from '../QCXDelaracaoImportacaoTotaisForm/components/AcrescimoDeducaoBondManager';
import MercadoriaAdicaoForm from './MercadoriaAdicaoForm';
import QCXFinalFormDialog from '../../../shared-components/dialog/QCXFinalFormDialog';
import QCXSelectServicoAutocomplete from '../../../shared-components/select-servico/QCXSelectServicoAutocomplete';
import useFormDialogAsync from '../../../utils/hooks/form/dialog/useFormDialogAsync';
import {
  CLOSED_STATUS,
  DONE_STATUS,
  SUBMITTING_STATUS,
  isOpenStatus,
} from '../../../utils/hooks/form/dialog/formDialogUtils';
import QCXButton from '../../../shared-components/button/QCXButton';
import AuxiliarTributosPopup from '../auxiliarTributos/AuxiliarTributosPopup';

import { normalizeAtributosDinamicosNcm } from '../../fatura/mercadoriaUtils';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  moedaInternacionalFieldLeft: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '5px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingBottom: '16px',
    },
  },
  moedaInternacionalFieldRight: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '11px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  rateioCheckbox: {
    paddingBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '16px',
    },
  },
  sibling: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '8px',
      marginTop: '8px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '8px',
    },
  },
  generateLiIcon: {
    color: theme.palette.primary.main,
  },
}));

export default function QCXDeclaracaoImportacaoAdicoesForm({
  isIdle,
  isLoading,
  isPreparingAction,
  isCreate,
  isConsult,
  isUpdate,
  isSubNone,
  isSubCreate,
  isSubConsult,
  isSubUpdate,
  isBackgroundDelete,
  isSubBackgroundCreate,
  isSubBackgroundUpdate,
  isSubBackgroundDelete,
  parentModel,
  defaultItem,
  handleCustomSaveBy,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleChangeToSubNone,
  handleChangeToSubConsult,
  handleChangeToSubUpdate,
  handleChangeToBackgroundDelete,
  handleCancelUpdate,
  handleMetadataUpdate,
  handleLoadingStatus,
  handleResetStatus,
  controlComponentProps,
  tabManagerProps = {},
  formPaginationControlProps = {},
  buttonGroupOptions = {},
  ...restProps
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const hasFetchedMercadoriasRef = useRef(false);

  const adicao = useSelector(selectRelatedAdicaoModel);
  const regimesTributacao = useSelector(selectRegimesTributacao);
  const metodosDeValoracao = useSelector(selectMetodosDeValoracao);
  const moedas = useSelector(selectMoedas);
  const ncmList = useSelector(ncmSubitemActions.selectNcmSubitens);
  const tiposDeclaracao = useSelector(selectTiposDeclaracao);

  const acrescimoDeducaoStatus = useSelector(acrescimoDeducaoDeclaracaoImportacaoSelectors.selectStatus);

  useEffect(() => {
    dispatch(fetchAllRegimeTributacaoAsync());
    dispatch(fetchAllMotivosImportacaoSemCoberturaCambialAsync());
  }, []);

  const isAcrescimoDeducaoUpdate = useMemo(() => isUpdateMode(acrescimoDeducaoStatus), [acrescimoDeducaoStatus]);

  const adicoes = useMemo(() => parentModel?.atributosAdicionais?.adicoes, [parentModel]);

  const hasItems = useMemo(() => isArray(adicoes) && !isEmpty(adicoes), [adicoes]);

  const idFatura = useMemo(() => parentModel?.id, [parentModel]);
  const idDIDUIMP = useMemo(() => parentModel?.atributosAdicionais?.id, [parentModel]);

  const modes = useMemo(
    () => ({
      isConsult,
      isSubConsult,
      isSubNone,
      isSubCreate,
      isSubUpdate,
    }),
    [isConsult, isSubConsult, isSubNone]
  );

  useEffect(() => {
    dispatch(cfopOperacaoFiscalActions.changeControlTo(AUTO_LOAD_CONTROL));
  }, []);

  useEffect(() => {
    if (adicoes?.length === 0 && !hasFetchedMercadoriasRef.current) {
      hasFetchedMercadoriasRef.current = true;
      dispatch(fetchMercadoriasByFaturaIdAsync(idFatura));
      dispatch(fetchAdicoesByDiDuimpIdAsync(idDIDUIMP));
    }
  }, [idFatura, idDIDUIMP, adicoes]);

  // NORMALIZA PARA ENVIAR PARA O BACK
  const normalize = useCallback((unnormalizedData) => {

    const mapAcrecimoDeducao = (acrecimoDeducao) => {
      const isUUid = validate(acrecimoDeducao?.id);

      if (isUUid) {
        const obj = {
          ...acrecimoDeducao,
          id: null,
          valorMoeda: normalizeNumeral(acrecimoDeducao?.valorMoeda),
          valorReal: normalizeNumeral(acrecimoDeducao?.valorReal),
          moeda: acrecimoDeducao?.moeda?.id ? acrecimoDeducao?.moeda : null,
          acrescimoValoracaoAduaneira:
            acrecimoDeducao?.acrescimoValoracaoAduaneira?.id && acrecimoDeducao?.tipo === TIPO_ACRESCIMO
              ? acrecimoDeducao?.acrescimoValoracaoAduaneira
              : null,
          deducaoValoracaoAduaneira:
            acrecimoDeducao?.acrescimoValoracaoAduaneira?.id && acrecimoDeducao?.tipo === TIPO_DEDUCAO
              ? acrecimoDeducao?.acrescimoValoracaoAduaneira
              : null,
        };
        return obj;
      }

      const obj = {
        ...acrecimoDeducao,
        valorMoeda: normalizeNumeral(acrecimoDeducao?.valorMoeda),
        valorReal: normalizeNumeral(acrecimoDeducao?.valorReal),
        moeda: acrecimoDeducao?.moeda?.id ? acrecimoDeducao?.moeda : null,
        acrescimoValoracaoAduaneira:
          acrecimoDeducao?.acrescimoValoracaoAduaneira?.id && acrecimoDeducao?.tipo === TIPO_ACRESCIMO
            ? acrecimoDeducao?.acrescimoValoracaoAduaneira
            : null,
        deducaoValoracaoAduaneira:
          acrecimoDeducao?.acrescimoValoracaoAduaneira?.id && acrecimoDeducao?.tipo === TIPO_DEDUCAO
            ? acrecimoDeducao?.acrescimoValoracaoAduaneira
            : null,
      };

      return obj;
    };
    const normalizedAcrescimosDeducoes = (unnormalizedData?.acrescimosDeducoes || [])?.map(mapAcrecimoDeducao);

    const fatura = {
      ...unnormalizedData,
      exportador: unnormalizedData?.exportador?.id ? unnormalizedData?.exportador : undefined,
      fabricante: unnormalizedData?.fabricante?.id ? unnormalizedData?.fabricante : undefined,
      paisOrigem: unnormalizedData?.paisOrigem?.id ? unnormalizedData?.paisOrigem : undefined,
      instituicaoFinanciadora: unnormalizedData?.instituicaoFinanciadora?.id
        ? unnormalizedData?.instituicaoFinanciadora
        : undefined,
      motivoImportacaoSemCoberturaCambial: unnormalizedData?.motivoImportacaoSemCoberturaCambial?.id
        ? unnormalizedData?.motivoImportacaoSemCoberturaCambial
        : undefined,
      moeda: unnormalizedData?.moeda?.id ? unnormalizedData?.moeda : null,
      moedaDespesa: unnormalizedData?.moedaDespesa?.id ? unnormalizedData?.moedaDespesa : null,
      ncm: unnormalizedData?.ncm?.id ? unnormalizedData?.ncm : null,
      naladiNcca: unnormalizedData?.naladiNcca?.id ? unnormalizedData?.naladiNcca : null,
      naladiSh: unnormalizedData?.naladiSh?.id ? unnormalizedData?.naladiSh : null,
      operacaoFiscal: unnormalizedData?.operacaoFiscal?.id ? unnormalizedData?.operacaoFiscal : null,
      atributos: (unnormalizedData?.atributos || [])?.map((atributo) =>
        validate(atributo?.id)
          ? {
              ...atributo,
              nve: atributo?.nve?.id ? atributo?.nve : null,
              atributo: atributo?.atributo?.id ? atributo?.atributo : null,
              especificacao: atributo?.especificacao?.id ? atributo?.especificacao : null,
              id: null,
            }
          : {
              ...atributo,
              nve: atributo?.nve?.id ? atributo?.nve : null,
              atributo: atributo?.atributo?.id ? atributo?.atributo : null,
              especificacao: atributo?.especificacao?.id ? atributo?.especificacao : null,
            }
      ),
      declaracaoExportacoes: (unnormalizedData?.declaracaoExportacoes || [])?.map((de) =>
        validate(de?.id)
          ? {
              ...de,
              id: null,
              qtdUnidadeEstatistica: normalizeNumeral(de?.qtdUnidadeEstatistica),
            }
          : {
              ...de,
              qtdUnidadeEstatistica: normalizeNumeral(de?.qtdUnidadeEstatistica),
            }
      ),
      valorFornecedor: normalizeNumeral(unnormalizedData?.valorFornecedor),
      pesoLiquido: normalizeNumeral(unnormalizedData?.pesoLiquido),
      pesoBruto: normalizeNumeral(unnormalizedData?.pesoBruto),
      quantidadeEstatistica: normalizeNumeral(unnormalizedData?.quantidadeEstatistica),
      valorMleReal: normalizeNumeral(unnormalizedData?.valorMleReal),
      valorMleMoeda: normalizeNumeral(unnormalizedData?.valorMleMoeda),
      valorDespesaMoeda: normalizeNumeral(unnormalizedData?.valorDespesaMoeda),
      valorDespesaReal: normalizeNumeral(unnormalizedData?.valorDespesaReal),
      valorCifReal: normalizeNumeral(unnormalizedData?.valorCifReal),
      valorMcvMoeda: normalizeNumeral(unnormalizedData?.valorMcvMoeda),
      valorMcvReal: normalizeNumeral(unnormalizedData?.valorMcvReal),
      valorFreteTerritorioNacionalMoeda: normalizeNumeral(unnormalizedData?.valorFreteTerritorioNacionalMoeda),
      valorFreteTerritorioNacionalReal: normalizeNumeral(unnormalizedData?.valorFreteTerritorioNacionalReal),
      valorSeguroMoeda: normalizeNumeral(unnormalizedData?.valorSeguroMoeda),
      valorSeguroReal: normalizeNumeral(unnormalizedData?.valorSeguroReal),
      valorFreteMoeda: normalizeNumeral(unnormalizedData?.valorFreteMoeda),
      valorFreteReal: normalizeNumeral(unnormalizedData?.valorFreteReal),
      acrescimosDeducoes: normalizedAcrescimosDeducoes,
      incotermCondicaoVenda: unnormalizedData?.incotermCondicaoVenda?.id
        ? unnormalizedData?.incotermCondicaoVenda
        : null,
      metodoValoracaoAduaneira: unnormalizedData?.metodoValoracaoAduaneira?.id
        ? unnormalizedData?.metodoValoracaoAduaneira
        : null,
      portoAeroporto: unnormalizedData?.portoAeroporto?.id ? unnormalizedData?.portoAeroporto : null,
      ii: {
        ...unnormalizedData?.ii,
        fundamentoLegalRegimeTributacaoIi: unnormalizedData?.ii?.fundamentoLegalRegimeTributacaoIi?.id
          ? unnormalizedData?.ii?.fundamentoLegalRegimeTributacaoIi
          : null,
        tipoAtoLegalExTarifario: unnormalizedData?.ii?.tipoAtoLegalExTarifario?.id
          ? unnormalizedData?.ii?.tipoAtoLegalExTarifario
          : null,
        tipoAtoLegalAcordoTarifario: unnormalizedData?.ii?.tipoAtoLegalAcordoTarifario?.id
          ? unnormalizedData?.ii?.tipoAtoLegalAcordoTarifario
          : null,
        regimeTributacao: unnormalizedData?.ii?.regimeTributacao?.id ? unnormalizedData?.ii?.regimeTributacao : null,
        orgaoEmissorAtoLegalExTarifario: unnormalizedData?.ii?.orgaoEmissorAtoLegalExTarifario?.id
          ? unnormalizedData?.ii?.orgaoEmissorAtoLegalExTarifario
          : null,
        orgaoEmissorAtoLegalAcordoTarifario: unnormalizedData?.ii?.orgaoEmissorAtoLegalAcordoTarifario?.id
          ? unnormalizedData?.ii?.orgaoEmissorAtoLegalAcordoTarifario
          : undefined,
        ano: normalizeYear(unnormalizedData?.ii?.ano),
        anoExTarifario: normalizeYear(unnormalizedData?.ii?.anoExTarifario),
        anoAcordoTarifario: normalizeYear(unnormalizedData?.ii?.anoAcordoTarifario),
        baseCalculoReal: normalizeNumeral(unnormalizedData?.ii?.baseCalculoReal),
        aliquota: normalizeNumeral(unnormalizedData?.ii?.aliquota),
        aliquotaAcordo: normalizeNumeral(unnormalizedData?.ii?.aliquotaAcordo),
        percentualReducao: normalizeNumeral(unnormalizedData?.ii?.percentualReducao),
        aliquotaReduzida: normalizeNumeral(unnormalizedData?.ii?.aliquotaReduzida),
        coeficienteReducao: normalizeNumeral(unnormalizedData?.ii?.coeficienteReducao),
        valorDolar: normalizeNumeral(unnormalizedData?.ii?.valorDolar),
        valorReal: normalizeNumeral(unnormalizedData?.ii?.valorReal),
        valorDevidoReal: normalizeNumeral(unnormalizedData?.ii?.valorDevidoReal),
        valorRecolherReal: normalizeNumeral(unnormalizedData?.ii?.valorRecolherReal),
        recolherPermanencia: normalizeNumeral(unnormalizedData?.ii?.recolherPermanencia),
        ...(unnormalizedData?.ii?.acordoAladi && {
          acordoAladi: { id: unnormalizedData?.ii?.acordoAladi },
        }),
        ...normalizeAtributosDinamicosNcm(unnormalizedData?.ii),
      },
      ipi: {
        ...unnormalizedData?.ipi,
        tipoAtoLegalFundamentoLegal: unnormalizedData?.ipi?.tipoAtoLegalFundamentoLegal?.id
          ? unnormalizedData?.ipi?.tipoAtoLegalFundamentoLegal
          : null,
        orgaoEmissorFundamentoLegal: unnormalizedData?.ipi?.orgaoEmissorFundamentoLegal?.id
          ? unnormalizedData?.ipi?.orgaoEmissorFundamentoLegal
          : null,
        numeroAtoFundamentoLegal: unnormalizedData?.ipi?.numeroAtoFundamentoLegal,
        anoFundamentoLegal: normalizeYear(unnormalizedData?.ipi?.anoFundamentoLegal),
        numeroExFundamentoLegal: unnormalizedData?.ipi?.numeroExFundamentoLegal,
        orgaoEmissorAtoLegal: unnormalizedData?.ipi?.orgaoEmissorAtoLegal?.id
          ? unnormalizedData?.ipi?.orgaoEmissorAtoLegal
          : null,
        regimeTributacao: unnormalizedData?.ipi?.regimeTributacao?.id ? unnormalizedData?.ipi?.regimeTributacao : null,
        tipoRecipiente: unnormalizedData?.ipi?.tipoRecipiente?.id ? unnormalizedData?.ipi?.tipoRecipiente : null,
        unidadeDeMedida: unnormalizedData?.ipi?.unidadeDeMedida?.id ? unnormalizedData?.ipi?.unidadeDeMedida : null,
        tipoAtoLegalExTarifario: unnormalizedData?.ipi?.tipoAtoLegalExTarifario?.id
          ? unnormalizedData?.ipi?.tipoAtoLegalExTarifario
          : null,

        baseCalculoReal: normalizeNumeral(unnormalizedData?.ipi?.baseCalculoReal),
        aliquota: normalizeNumeral(unnormalizedData?.ipi?.aliquota),
        aliquotaReduzida: normalizeNumeral(unnormalizedData?.ipi?.aliquotaReduzida),
        valorUnidadeMedidaReal: normalizeNumeral(unnormalizedData?.ipi?.valorUnidadeMedidaReal),
        valorRecolherReal: normalizeNumeral(unnormalizedData?.ipi?.valorRecolherReal),
        recolherPermanencia: normalizeNumeral(unnormalizedData?.ipi?.recolherPermanencia),
        valorDevidoReal: normalizeNumeral(unnormalizedData?.ipi?.valorDevidoReal),
        quantidade: normalizeNumeral(unnormalizedData?.ipi?.quantidade),
        capacidade: normalizeNumeral(unnormalizedData?.ipi?.capacidade),
        anoExTarifario: normalizeYear(unnormalizedData?.ipi?.anoExTarifario),
      },
      multaLi: {
        ...unnormalizedData?.multaLi,
        valorDevidoReal: normalizeNumeral(unnormalizedData?.multaLi?.valorDevidoReal),
        valorRecolherReal: normalizeNumeral(unnormalizedData?.multaLi?.valorRecolherReal),
      },
      pisCofins: {
        ...unnormalizedData?.pisCofins,
        unidadeDeMedidaPisPasep: unnormalizedData?.pisCofins?.unidadeDeMedidaPisPasep?.id
          ? unnormalizedData?.pisCofins?.unidadeDeMedidaPisPasep
          : null,
        unidadeDeMedidaCofins: unnormalizedData?.pisCofins?.unidadeDeMedidaCofins?.id
          ? unnormalizedData?.pisCofins?.unidadeDeMedidaCofins
          : null,
        regimeTributacao: unnormalizedData?.pisCofins?.regimeTributacao?.id
          ? unnormalizedData?.pisCofins?.regimeTributacao
          : null,
        fundamentoLegalRegimeTributacaoPisCofins: unnormalizedData?.pisCofins?.fundamentoLegalRegimeTributacaoPisCofins
          ?.id
          ? unnormalizedData?.pisCofins?.fundamentoLegalRegimeTributacaoPisCofins
          : null,
        fundamentoLegal: unnormalizedData?.pisCofins?.fundamentoLegal?.id
          ? unnormalizedData?.pisCofins?.fundamentoLegal
          : null,
        baseCalculoReal: normalizeNumeral(unnormalizedData?.pisCofins?.baseCalculoReal),
        percentualReducao: normalizeNumeral(unnormalizedData?.pisCofins?.percentualReducao),
        percentualReducaoBaseCalculo: normalizeNumeral(unnormalizedData?.pisCofins?.percentualReducaoBaseCalculo),
        aliquotaPisPasep: normalizeNumeral(unnormalizedData?.pisCofins?.aliquotaPisPasep),
        aliquotaReduzidaPisPasep: normalizeNumeral(unnormalizedData?.pisCofins?.aliquotaReduzidaPisPasep),
        valorRecolherPisPasepReal: normalizeNumeral(unnormalizedData?.pisCofins?.valorRecolherPisPasepReal),
        valorDevidoCofinsReal: normalizeNumeral(unnormalizedData?.pisCofins?.valorDevidoCofinsReal),
        valorDevidoPisPasepReal: normalizeNumeral(unnormalizedData?.pisCofins?.valorDevidoPisPasepReal),
        recolherPermanenciaPisPasep: normalizeNumeral(unnormalizedData?.pisCofins?.recolherPermanenciaPisPasep),
        recolherPermanenciaCofins: normalizeNumeral(unnormalizedData?.pisCofins?.recolherPermanenciaCofins),
        quantidadePisPasep: normalizeNumeral(unnormalizedData?.pisCofins?.quantidadePisPasep),
        aliquotaCofins: normalizeNumeral(unnormalizedData?.pisCofins?.aliquotaCofins),
        aliquotaReduzidaCofins: normalizeNumeral(unnormalizedData?.pisCofins?.aliquotaReduzidaCofins),
        valorRecolherCofinsReal: normalizeNumeral(unnormalizedData?.pisCofins?.valorRecolherCofinsReal),
        quantidadeCofins: normalizeNumeral(unnormalizedData?.pisCofins?.quantidadeCofins),
        // normalize atributos
        ...normalizeAtributosDinamicosNcm(unnormalizedData?.pisCofins),
      },
      taxaSiscomex: {
        ...unnormalizedData?.taxaSiscomex,
        valorReal: normalizeNumeral(unnormalizedData?.taxaSiscomex?.valorReal),
        valorDolar: normalizeNumeral(unnormalizedData?.taxaSiscomex?.valorDolar),
        valorMoeda: normalizeNumeral(unnormalizedData?.taxaSiscomex?.valorMoeda),
      },
      icms: {
        ...unnormalizedData?.icms,
        regimeTributacao: unnormalizedData?.icms?.regimeTributacao?.id
          ? unnormalizedData?.icms?.regimeTributacao
          : null,
        dispositivoLegal: unnormalizedData?.icms?.dispositivoLegal?.id
          ? unnormalizedData?.icms?.dispositivoLegal
          : null,
        baseCalculoReal: normalizeNumeral(unnormalizedData?.icms?.baseCalculoReal),
        aliquota: normalizeNumeral(unnormalizedData?.icms?.aliquota),
        aliquotaAntecipadaPercentual: normalizeNumeral(unnormalizedData?.icms?.aliquotaAntecipadaPercentual),
        aliquotaOriginal: normalizeNumeral(unnormalizedData?.icms?.aliquotaOriginal),
        aliquotaFecp: normalizeNumeral(unnormalizedData?.icms?.aliquotaFecp),
        percentualReducao: normalizeNumeral(unnormalizedData?.icms?.percentualReducao),
        valorRecolherReal: normalizeNumeral(unnormalizedData?.icms?.valorRecolherReal),
        recolherPermanencia: normalizeNumeral(unnormalizedData?.icms?.recolherPermanencia),
        valorDevidoReal: normalizeNumeral(unnormalizedData?.icms?.valorDevidoReal),
        percentualBeneficio: normalizeNumeral(unnormalizedData?.icms?.percentualBeneficio),
        baseSubstTrib: normalizeNumeral(unnormalizedData?.icms?.baseSubstTrib),
        percMva: normalizeNumeral(unnormalizedData?.icms?.percMva),
        valorDevidoSubstTrib: normalizeNumeral(unnormalizedData?.icms?.valorDevidoSubstTrib),
        substTributaria: normalizeNumeral(unnormalizedData?.icms?.substTributaria),
      },
      antidumping: {
        ...unnormalizedData?.antidumping,
        orgaoEmissorAtoLegal: unnormalizedData?.antidumping?.orgaoEmissorAtoLegal?.id
          ? unnormalizedData?.antidumping?.orgaoEmissorAtoLegal
          : null,
        unidadeDeMedida: unnormalizedData?.antidumping?.unidadeDeMedida?.id
          ? unnormalizedData?.antidumping?.unidadeDeMedida
          : null,
        tipoAtoLegal: unnormalizedData?.antidumping?.tipoAtoLegal?.id
          ? unnormalizedData?.antidumping?.tipoAtoLegal
          : null,
        ano: normalizeYear(unnormalizedData?.antidumping?.ano),
        aliquota: normalizeNumeral(unnormalizedData?.antidumping?.aliquota),
        valorReal: normalizeNumeral(unnormalizedData?.antidumping?.valorReal),
        quantidade: normalizeNumeral(unnormalizedData?.antidumping?.quantidade),
        baseCalculoReal: normalizeNumeral(unnormalizedData?.antidumping?.baseCalculoReal),
        valorRecolherReal: normalizeNumeral(unnormalizedData?.antidumping?.valorRecolherReal),
        valorDevidoReal: normalizeNumeral(unnormalizedData?.antidumping?.valorDevidoReal),
      },
      afrmm: {
        ...unnormalizedData?.afrmm,
        valorRecolherReal: normalizeNumeral(unnormalizedData?.afrmm?.valorRecolherReal),
        valorDevidoReal: normalizeNumeral(unnormalizedData?.afrmm?.valorDevidoReal),
      },
      valorDespesaAcessoriaReal: normalizeNumeral(unnormalizedData?.valorDespesaAcessoriaReal),
      mercadorias: unnormalizedData?.mercadorias || [],
    };

    if (fatura && fatura.atributosPreenchidos) delete fatura.atributosPreenchidos;

    return fatura;
  }, []);

  const unnormalize = useCallback((normalizedData) => {
    const { acrescimosDeducoes } = normalizedData;

    const normalizedAcrescimosAduaneiros = acrescimosDeducoes || [];

    return {
      ...normalizedData,
      valorFornecedor: isValid(normalizedData?.valorFornecedor)
        ? unnormalizeNumeral(normalizedData?.valorFornecedor, formatBrazilianNumericDecimal(2))
        : undefined,
      pesoLiquido: isValid(normalizedData?.pesoLiquido)
        ? unnormalizeNumeral(normalizedData?.pesoLiquido, formatBrazilianNumericDecimal(5))
        : undefined,
      pesoBruto: isValid(normalizedData?.pesoBruto)
        ? unnormalizeNumeral(normalizedData?.pesoBruto, formatBrazilianNumericDecimal(5))
        : undefined,
      quantidadeEstatistica: isValid(normalizedData?.quantidadeEstatistica)
        ? unnormalizeNumeral(normalizedData?.quantidadeEstatistica, formatBrazilianNumericDecimal(7))
        : undefined,
      valorMleMoeda: isValid(normalizedData?.valorMleMoeda)
        ? unnormalizeNumeral(normalizedData?.valorMleMoeda, formatBrazilianNumericDecimal(2))
        : undefined,
      valorMleReal: isValid(normalizedData?.valorMleReal)
        ? unnormalizeNumeral(normalizedData?.valorMleReal, formatBrazilianNumericDecimal(2))
        : undefined,
      valorDespesaMoeda: isValid(normalizedData?.valorDespesaMoeda)
        ? unnormalizeNumeral(normalizedData?.valorDespesaMoeda, formatBrazilianNumericDecimal(2))
        : undefined,
      valorDespesaReal: isValid(normalizedData?.valorDespesaReal)
        ? unnormalizeNumeral(normalizedData?.valorDespesaReal, formatBrazilianNumericDecimal(2))
        : undefined,
      valorCifReal: isValid(normalizedData?.valorCifReal)
        ? unnormalizeNumeral(normalizedData?.valorCifReal, formatBrazilianNumericDecimal(2))
        : undefined,
      valorMcvMoeda: isValid(normalizedData?.valorMcvMoeda)
        ? unnormalizeNumeral(normalizedData?.valorMcvMoeda, formatBrazilianNumericDecimal(2))
        : undefined,
      valorMcvReal: isValid(normalizedData?.valorMcvReal)
        ? unnormalizeNumeral(normalizedData?.valorMcvReal, formatBrazilianNumericDecimal(2))
        : undefined,
      valorFreteTerritorioNacionalMoeda: isValid(normalizedData?.valorFreteTerritorioNacionalMoeda)
        ? unnormalizeNumeral(normalizedData?.valorFreteTerritorioNacionalMoeda, formatBrazilianNumericDecimal(2))
        : undefined,
      valorFreteTerritorioNacionalReal: isValid(normalizedData?.valorFreteTerritorioNacionalReal)
        ? unnormalizeNumeral(normalizedData?.valorFreteTerritorioNacionalReal, formatBrazilianNumericDecimal(2))
        : undefined,
      valorSeguroMoeda: isValid(normalizedData?.valorSeguroMoeda)
        ? unnormalizeNumeral(normalizedData?.valorSeguroMoeda, formatBrazilianNumericDecimal(2))
        : undefined,
      valorSeguroReal: isValid(normalizedData?.valorSeguroReal)
        ? unnormalizeNumeral(normalizedData?.valorSeguroReal, formatBrazilianNumericDecimal(2))
        : undefined,
      valorFreteMoeda: isValid(normalizedData?.valorFreteMoeda)
        ? unnormalizeNumeral(normalizedData?.valorFreteMoeda, formatBrazilianNumericDecimal(2))
        : undefined,
      valorFreteReal: isValid(normalizedData?.valorFreteReal)
        ? unnormalizeNumeral(normalizedData?.valorFreteReal, formatBrazilianNumericDecimal(2))
        : undefined,
      exportador: normalizedData?.exportador?.id ? normalizedData?.exportador : undefined,
      fabricante: normalizedData?.fabricante?.id ? normalizedData?.fabricante : undefined,
      paisOrigem: normalizedData?.paisOrigem?.id ? normalizedData?.paisOrigem : undefined,
      instituicaoFinanciadora: normalizedData?.instituicaoFinanciadora?.id
        ? normalizedData?.instituicaoFinanciadora
        : undefined,
      motivoImportacaoSemCoberturaCambial: normalizedData?.motivoImportacaoSemCoberturaCambial?.id
        ? normalizedData?.motivoImportacaoSemCoberturaCambial
        : undefined,
      moeda: normalizedData?.moeda?.id ? normalizedData?.moeda : undefined,
      moedaDespesa: normalizedData?.moedaDespesa?.id ? normalizedData?.moedaDespesa : undefined,
      ncm: normalizedData?.ncm?.id ? normalizedData?.ncm : undefined,
      naladiNcca: normalizedData?.naladiNcca?.id ? normalizedData?.naladiNcca : undefined,
      naladiSh: normalizedData?.naladiSh?.id ? normalizedData?.naladiSh : undefined,
      operacaoFiscal: normalizedData?.operacaoFiscal?.id ? normalizedData?.operacaoFiscal : undefined,
      atributos: (normalizedData?.atributos || [])?.map((atributo) =>
        atributo?.id
          ? {
              ...atributo,
              nve: atributo?.nve?.id ? atributo?.nve : undefined,
              atributo: atributo?.atributo?.id ? atributo?.atributo : undefined,
              especificacao: atributo?.especificacao?.id ? atributo?.especificacao : undefined,
            }
          : {
              ...atributo,
              nve: atributo?.nve?.id ? atributo?.nve : undefined,
              atributo: atributo?.atributo?.id ? atributo?.atributo : undefined,
              especificacao: atributo?.especificacao?.id ? atributo?.especificacao : undefined,
              id: uuid(),
            }
      ),
      declaracaoExportacoes: (normalizedData.declaracaoExportacoes || [])?.map((de) =>
        de?.id
          ? {
              ...de,
              qtdUnidadeEstatistica: isValid(de?.qtdUnidadeEstatistica)
                ? unnormalizeNumeral(de?.qtdUnidadeEstatistica, formatBrazilianNumericDecimal(7))
                : undefined,
            }
          : {
              ...de,
              id: uuid(),
              qtdUnidadeEstatistica: isValid(de?.qtdUnidadeEstatistica)
                ? unnormalizeNumeral(de?.qtdUnidadeEstatistica, formatBrazilianNumericDecimal(7))
                : undefined,
            }
      ),
      acrescimosDeducoes: normalizedAcrescimosAduaneiros.map((acrescimoAduaneiro) => ({
        ...acrescimoAduaneiro,
        valorMoeda: isValid(acrescimoAduaneiro?.valorMoeda)
          ? unnormalizeNumeral(acrescimoAduaneiro?.valorMoeda, formatBrazilianNumericDecimal(2))
          : undefined,
        valorReal: isValid(acrescimoAduaneiro?.valorReal)
          ? unnormalizeNumeral(acrescimoAduaneiro?.valorReal, formatBrazilianNumericDecimal(2))
          : undefined,
        acrescimoValoracaoAduaneira: !acrescimoAduaneiro?.acrescimoValoracaoAduaneira
          ? acrescimoAduaneiro?.deducaoValoracaoAduaneira
          : acrescimoAduaneiro?.acrescimoValoracaoAduaneira,
      })),
      incotermCondicaoVenda: normalizedData?.incotermCondicaoVenda?.id
        ? normalizedData?.incotermCondicaoVenda
        : undefined,
      metodoValoracaoAduaneira: normalizedData?.metodoValoracaoAduaneira?.id
        ? normalizedData?.metodoValoracaoAduaneira
        : undefined,
      portoAeroporto: normalizedData?.portoAeroporto?.id ? normalizedData?.portoAeroporto : undefined,
      antidumping: {
        ...normalizedData?.antidumping,
        orgaoEmissorAtoLegal: normalizedData?.antidumping?.orgaoEmissorAtoLegal?.id
          ? normalizedData?.antidumping?.orgaoEmissorAtoLegal
          : undefined,
        unidadeDeMedida: normalizedData?.antidumping?.unidadeDeMedida?.id
          ? normalizedData?.antidumping?.unidadeDeMedida
          : undefined,
        tipoAtoLegal: normalizedData?.antidumping?.tipoAtoLegal?.id
          ? normalizedData?.antidumping?.tipoAtoLegal
          : undefined,
        ano: `${normalizedData?.antidumping?.ano || ''}`,
        aliquota: isValid(normalizedData?.antidumping?.aliquota)
          ? unnormalizeNumeral(normalizedData?.antidumping?.aliquota, formatBrazilianNumericDecimal(2))
          : undefined,
        valorReal: isValid(normalizedData?.antidumping?.valorReal)
          ? unnormalizeNumeral(normalizedData?.antidumping?.valorReal, formatBrazilianNumericDecimal(5))
          : undefined,
        quantidade: isValid(normalizedData?.antidumping?.quantidade)
          ? unnormalizeNumeral(normalizedData?.antidumping?.quantidade)
          : undefined,
        baseCalculoReal: isValid(normalizedData?.antidumping?.baseCalculoReal)
          ? unnormalizeNumeral(normalizedData?.antidumping?.baseCalculoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorDevidoReal: isValid(normalizedData?.antidumping?.valorDevidoReal)
          ? unnormalizeNumeral(normalizedData?.antidumping?.valorDevidoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorRecolherReal: isValid(normalizedData?.antidumping?.valorRecolherReal)
          ? unnormalizeNumeral(normalizedData?.antidumping?.valorRecolherReal, formatBrazilianNumericDecimal(2))
          : undefined,
      },
      icms: {
        ...normalizedData?.icms,
        regimeTributacao: normalizedData?.icms?.regimeTributacao?.id
          ? normalizedData?.icms?.regimeTributacao
          : undefined,
        dispositivoLegal: normalizedData?.icms?.dispositivoLegal?.id
          ? normalizedData?.icms?.dispositivoLegal
          : undefined,
        baseCalculoReal: isValid(normalizedData?.icms?.baseCalculoReal)
          ? unnormalizeNumeral(normalizedData?.icms?.baseCalculoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        aliquota: isValid(normalizedData?.icms?.aliquota)
          ? unnormalizeNumeral(normalizedData?.icms?.aliquota, formatBrazilianNumericDecimal(7))
          : undefined,
        aliquotaAntecipadaPercentual: isValid(normalizedData?.icms?.aliquotaAntecipadaPercentual)
          ? unnormalizeNumeral(normalizedData?.icms?.aliquotaAntecipadaPercentual, formatBrazilianNumericDecimal(7))
          : undefined,
        aliquotaOriginal: isValid(normalizedData?.icms?.aliquotaOriginal)
          ? unnormalizeNumeral(normalizedData?.icms?.aliquotaOriginal, formatBrazilianNumericDecimal(7))
          : undefined,
        aliquotaFecp: isValid(normalizedData?.icms?.aliquotaFecp)
          ? unnormalizeNumeral(normalizedData?.icms?.aliquotaFecp, formatBrazilianNumericDecimal(7))
          : undefined,
        percentualReducao: isValid(normalizedData?.icms?.percentualReducao)
          ? unnormalizeNumeral(normalizedData?.icms?.percentualReducao, formatBrazilianNumericDecimal(7))
          : undefined,
        valorRecolherReal: isValid(normalizedData?.icms?.valorRecolherReal)
          ? unnormalizeNumeral(normalizedData?.icms?.valorRecolherReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorDevidoReal: isValid(normalizedData?.icms?.valorDevidoReal)
          ? unnormalizeNumeral(normalizedData?.icms?.valorDevidoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        recolherPermanencia: isValid(normalizedData?.icms?.valorDevidoReal)
          ? unnormalizeNumeral(normalizedData?.icms?.recolherPermanencia, formatBrazilianNumericDecimal(2))
          : undefined,
        percentualBeneficio: isValid(normalizedData?.icms?.percentualBeneficio)
          ? unnormalizeNumeral(normalizedData?.icms?.percentualBeneficio, formatBrazilianNumericDecimal(7))
          : undefined,
        baseSubstTrib: isValid(normalizedData?.icms?.baseSubstTrib)
          ? unnormalizeNumeral(normalizedData?.icms?.baseSubstTrib, formatBrazilianNumericDecimal(2))
          : undefined,
        percMva: isValid(normalizedData?.icms?.percMva)
          ? unnormalizeNumeral(normalizedData?.icms?.percMva, formatBrazilianNumericDecimal(2))
          : undefined,
        valorDevidoSubstTrib: isValid(normalizedData?.icms?.valorDevidoSubstTrib)
          ? unnormalizeNumeral(normalizedData?.icms?.valorDevidoSubstTrib, formatBrazilianNumericDecimal(2))
          : undefined,
        substTributaria: isValid(normalizedData?.icms?.substTributaria)
          ? unnormalizeNumeral(normalizedData?.icms?.substTributaria, formatBrazilianNumericDecimal(2))
          : undefined,
      },
      pisCofins: {
        ...normalizedData?.pisCofins,
        unidadeDeMedidaPisPasep: normalizedData?.pisCofins?.unidadeDeMedidaPisPasep?.id
          ? normalizedData?.pisCofins?.unidadeDeMedidaPisPasep
          : undefined,
        unidadeDeMedidaCofins: normalizedData?.pisCofins?.unidadeDeMedidaCofins?.id
          ? normalizedData?.pisCofins?.unidadeDeMedidaCofins
          : undefined,
        regimeTributacao: normalizedData?.pisCofins?.regimeTributacao?.id
          ? normalizedData?.pisCofins?.regimeTributacao
          : undefined,
        fundamentoLegalRegimeTributacaoPisCofins: normalizedData?.pisCofins?.fundamentoLegalRegimeTributacaoPisCofins
          ?.id
          ? normalizedData?.pisCofins?.fundamentoLegalRegimeTributacaoPisCofins
          : undefined,
        baseCalculoReal: isValid(normalizedData?.pisCofins?.baseCalculoReal)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.baseCalculoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        percentualReducao: isValid(normalizedData?.pisCofins?.percentualReducao)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.percentualReducao, formatBrazilianNumericDecimal(7))
          : undefined,
        percentualReducaoBaseCalculo: isValid(normalizedData?.pisCofins?.percentualReducaoBaseCalculo)
          ? unnormalizeNumeral(
              normalizedData?.pisCofins?.percentualReducaoBaseCalculo,
              formatBrazilianNumericDecimal(7)
            )
          : undefined,
        aliquotaPisPasep: isValid(normalizedData?.pisCofins?.aliquotaPisPasep)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.aliquotaPisPasep, formatBrazilianNumericDecimal(7))
          : undefined,
        aliquotaReduzidaPisPasep: isValid(normalizedData?.pisCofins?.aliquotaReduzidaPisPasep)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.aliquotaReduzidaPisPasep, formatBrazilianNumericDecimal(7))
          : undefined,
        valorRecolherPisPasepReal: isValid(normalizedData?.pisCofins?.valorRecolherPisPasepReal)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.valorRecolherPisPasepReal, formatBrazilianNumericDecimal(2))
          : undefined,
        quantidadePisPasep: isValid(normalizedData?.pisCofins?.quantidadePisPasep)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.quantidadePisPasep, formatBrazilianNumericDecimal(2))
          : undefined,
        aliquotaCofins: isValid(normalizedData?.pisCofins?.aliquotaCofins)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.aliquotaCofins, formatBrazilianNumericDecimal(7))
          : undefined,
        aliquotaReduzidaCofins: isValid(normalizedData?.pisCofins?.aliquotaReduzidaCofins)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.aliquotaReduzidaCofins, formatBrazilianNumericDecimal(7))
          : undefined,
        valorRecolherCofinsReal: isValid(normalizedData?.pisCofins?.valorRecolherCofinsReal)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.valorRecolherCofinsReal, formatBrazilianNumericDecimal(2))
          : undefined,
        quantidadeCofins: isValid(normalizedData?.pisCofins?.quantidadeCofins)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.quantidadeCofins, formatBrazilianNumericDecimal(7))
          : undefined,
        valorDevidoPisPasepReal: isValid(normalizedData?.pisCofins?.valorDevidoPisPasepReal)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.valorDevidoPisPasepReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorDevidoCofinsReal: isValid(normalizedData?.pisCofins?.valorDevidoCofinsReal)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.valorDevidoCofinsReal, formatBrazilianNumericDecimal(2))
          : undefined,
        recolherPermanenciaCofins: isValid(normalizedData?.pisCofins?.recolherPermanenciaCofins)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.recolherPermanenciaCofins, formatBrazilianNumericDecimal(2))
          : undefined,
        recolherPermanenciaPisPasep: isValid(normalizedData?.pisCofins?.recolherPermanenciaPisPasep)
          ? unnormalizeNumeral(normalizedData?.pisCofins?.recolherPermanenciaPisPasep, formatBrazilianNumericDecimal(2))
          : undefined,
      },
      ipi: {
        ...normalizedData?.ipi,
        tipoAtoLegalFundamentoLegal: normalizedData?.ipi?.tipoAtoLegalFundamentoLegal?.id
          ? normalizedData?.ipi?.tipoAtoLegalFundamentoLegal
          : undefined,
        orgaoEmissorFundamentoLegal: normalizedData?.ipi?.orgaoEmissorFundamentoLegal?.id
          ? normalizedData?.ipi?.orgaoEmissorFundamentoLegal
          : undefined,
        numeroAtoFundamentoLegal: normalizedData?.ipi?.numeroAtoFundamentoLegal,
        anoFundamentoLegal: `${normalizedData?.ipi?.anoFundamentoLegal || ''}`,
        numeroExFundamentoLegal: `${normalizedData?.ipi?.numeroExFundamentoLegal || ''}`,

        orgaoEmissorAtoLegal: normalizedData?.ipi?.orgaoEmissorAtoLegal?.id
          ? normalizedData?.ipi?.orgaoEmissorAtoLegal
          : undefined,
        regimeTributacao: normalizedData?.ipi?.regimeTributacao?.id ? normalizedData?.ipi?.regimeTributacao : undefined,
        tipoRecipiente: normalizedData?.ipi?.tipoRecipiente?.id ? normalizedData?.ipi?.tipoRecipiente : undefined,
        unidadeDeMedida: normalizedData?.ipi?.unidadeDeMedida?.id ? normalizedData?.ipi?.unidadeDeMedida : undefined,
        baseCalculoReal: isValid(normalizedData?.ipi?.baseCalculoReal)
          ? unnormalizeNumeral(normalizedData?.ipi?.baseCalculoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        aliquota: isValid(normalizedData?.ipi?.aliquota)
          ? unnormalizeNumeral(normalizedData?.ipi?.aliquota, formatBrazilianNumericDecimal(7))
          : undefined,
        aliquotaReduzida: isValid(normalizedData?.ipi?.aliquotaReduzida)
          ? unnormalizeNumeral(normalizedData?.ipi?.aliquotaReduzida, formatBrazilianNumericDecimal(7))
          : undefined,
        valorUnidadeMedidaReal: isValid(normalizedData?.ipi?.valorUnidadeMedidaReal)
          ? unnormalizeNumeral(normalizedData?.ipi?.valorUnidadeMedidaReal, formatBrazilianNumericDecimal(2))
          : undefined,
        quantidade: isValid(normalizedData?.ipi?.quantidade)
          ? unnormalizeNumeral(normalizedData?.ipi?.quantidade, formatBrazilianNumericDecimal(7))
          : undefined,
        capacidade: isValid(normalizedData?.ipi?.capacidade)
          ? unnormalizeNumeral(normalizedData?.ipi?.capacidade, formatBrazilianNumericDecimal(7))
          : undefined,
        valorDevidoReal: isValid(normalizedData?.ipi?.valorDevidoReal)
          ? unnormalizeNumeral(normalizedData?.ipi?.valorDevidoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorRecolherReal: isValid(normalizedData?.ipi?.valorRecolherReal)
          ? unnormalizeNumeral(normalizedData?.ipi?.valorRecolherReal, formatBrazilianNumericDecimal(2))
          : undefined,
        recolherPermanencia: isValid(normalizedData?.ipi?.recolherPermanencia)
          ? unnormalizeNumeral(normalizedData?.ipi?.recolherPermanencia, formatBrazilianNumericDecimal(2))
          : undefined,
        tipoAtoLegalExTarifario: normalizedData?.ipi?.tipoAtoLegalExTarifario?.id
          ? normalizedData?.ipi?.tipoAtoLegalExTarifario
          : undefined,
        anoExTarifario: `${normalizedData?.ipi?.anoExTarifario || ''}`,
      },
      ii: {
        ...normalizedData?.ii,
        fundamentoLegalRegimeTributacaoIi: normalizedData?.ii?.fundamentoLegalRegimeTributacaoIi?.id
          ? normalizedData?.ii?.fundamentoLegalRegimeTributacaoIi
          : undefined,
        tipoAtoLegalExTarifario: normalizedData?.ii?.tipoAtoLegalExTarifario?.id
          ? normalizedData?.ii?.tipoAtoLegalExTarifario
          : undefined,
        tipoAtoLegalAcordoTarifario: normalizedData?.ii?.tipoAtoLegalAcordoTarifario?.id
          ? normalizedData?.ii?.tipoAtoLegalAcordoTarifario
          : undefined,
        regimeTributacao: normalizedData?.ii?.regimeTributacao?.id ? normalizedData?.ii?.regimeTributacao : undefined,
        ano: `${normalizedData?.ii?.ano || ''}`,
        anoExTarifario: `${normalizedData?.ii?.anoExTarifario || ''}`,
        anoAcordoTarifario: `${normalizedData?.ii?.anoAcordoTarifario || ''}`,
        orgaoEmissorAtoLegalExTarifario: normalizedData?.ii?.orgaoEmissorAtoLegalExTarifario?.id
          ? normalizedData?.ii?.orgaoEmissorAtoLegalExTarifario
          : undefined,
        orgaoEmissorAtoLegalAcordoTarifario: normalizedData?.ii?.orgaoEmissorAtoLegalAcordoTarifario?.id
          ? normalizedData?.ii?.orgaoEmissorAtoLegalAcordoTarifario
          : undefined,
        baseCalculoReal: isValid(normalizedData?.ii?.baseCalculoReal)
          ? unnormalizeNumeral(normalizedData?.ii?.baseCalculoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        aliquota: isValid(normalizedData?.ii?.aliquota)
          ? unnormalizeNumeral(normalizedData?.ii?.aliquota, formatBrazilianNumericDecimal(2))
          : undefined,
        aliquotaAcordo: isValid(normalizedData?.ii?.aliquotaAcordo)
          ? unnormalizeNumeral(normalizedData?.ii?.aliquotaAcordo, formatBrazilianNumericDecimal(2))
          : undefined,
        percentualReducao: isValid(normalizedData?.ii?.percentualReducao)
          ? unnormalizeNumeral(normalizedData?.ii?.percentualReducao, formatBrazilianNumericDecimal(7))
          : undefined,
        aliquotaReduzida: isValid(normalizedData?.ii?.aliquotaReduzida)
          ? unnormalizeNumeral(normalizedData?.ii?.aliquotaReduzida, formatBrazilianNumericDecimal(7))
          : undefined,
        coeficienteReducao: isValid(normalizedData?.ii?.coeficienteReducao)
          ? unnormalizeNumeral(normalizedData?.ii?.coeficienteReducao, formatBrazilianNumericDecimal(7))
          : undefined,
        valorDolar: isValid(normalizedData?.ii?.valorDolar)
          ? unnormalizeNumeral(normalizedData?.ii?.valorDolar, formatBrazilianNumericDecimal(2))
          : undefined,
        valorReal: isValid(normalizedData?.ii?.valorReal)
          ? unnormalizeNumeral(normalizedData?.ii?.valorReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorDevidoReal: isValid(normalizedData?.ii?.valorDevidoReal)
          ? unnormalizeNumeral(normalizedData?.ii?.valorDevidoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorRecolherReal: isValid(normalizedData?.ii?.valorRecolherReal)
          ? unnormalizeNumeral(normalizedData?.ii?.valorRecolherReal, formatBrazilianNumericDecimal(2))
          : undefined,
        recolherPermanencia: isValid(normalizedData?.ii?.recolherPermanencia)
          ? unnormalizeNumeral(normalizedData?.ii?.recolherPermanencia, formatBrazilianNumericDecimal(2))
          : undefined,
        acordoAladi: normalizedData?.ii?.acordoAladi ? normalizedData?.ii?.acordoAladi.id : undefined,
      },
      taxaSiscomex: {
        ...normalizedData?.taxaSiscomex,
        valorReal: isValid(normalizedData?.taxaSiscomex?.valorReal)
          ? unnormalizeNumeral(normalizedData?.taxaSiscomex?.valorReal, formatBrazilianNumericDecimal(2))
          : undefined,
      },
      multaLi: {
        ...normalizedData?.multaLi,
        valorDevidoReal: isValid(normalizedData?.multaLi?.valorDevidoReal)
          ? unnormalizeNumeral(normalizedData?.multaLi?.valorDevidoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorRecolherReal: isValid(normalizedData?.multaLi?.valorRecolherReal)
          ? unnormalizeNumeral(normalizedData?.multaLi?.valorRecolherReal, formatBrazilianNumericDecimal(2))
          : undefined,
      },
      valorDespesaAcessoriaReal: isValid(normalizedData?.valorDespesaAcessoriaReal)
        ? unnormalizeNumeral(normalizedData?.valorDespesaAcessoriaReal, formatBrazilianNumericDecimal(2))
        : undefined,
      afrmm: {
        ...normalizedData?.afrmm,
        valorDevidoReal: isValid(normalizedData?.afrmm?.valorDevidoReal)
          ? unnormalizeNumeral(normalizedData?.afrmm?.valorDevidoReal, formatBrazilianNumericDecimal(2))
          : undefined,
        valorRecolherReal: isValid(normalizedData?.afrmm?.valorRecolherReal)
          ? unnormalizeNumeral(normalizedData?.afrmm?.valorRecolherReal, formatBrazilianNumericDecimal(2))
          : undefined,
      },
    };
  }, []);

  const normalizeAdicaoAliquotas = useCallback((parentModelData, unnormalizedData) => {
    const adicoesAliquotas = parentModelData?.atributosAdicionais?.adicoes?.map((a) => {
      const copy = { ...a };

      const ii = unnormalizedData?.metaAliquotas?.[a.id]?.ii;
      if (ii?.checked && ii?.value !== 'R') {
        copy.ii = {
          ...copy.ii,
          aliquota: ii?.value,
        };
      }

      const ipi = unnormalizedData?.metaAliquotas?.[a.id]?.ipi;
      if (ipi?.checked && ipi?.value !== 'R') {
        copy.ipi = {
          ...copy.ipi,
          aliquota: ipi?.value,
        };
      }

      const pis = unnormalizedData?.metaAliquotas?.[a.id]?.pis;
      if (pis?.checked && pis?.value !== 'R') {
        copy.pisCofins = {
          ...copy.pisCofins,
          aliquotaPisPasep: pis?.value,
        };
      }

      const cofins = unnormalizedData?.metaAliquotas?.[a.id]?.cofins;
      if (cofins?.checked && cofins?.value !== 'R') {
        copy.pisCofins = {
          ...copy.pisCofins,
          aliquotaCofins: cofins?.value,
        };
      }

      const exTarifarioII = unnormalizedData?.metaAliquotas?.[`adicao_${a.id}`]?.exTarifario?.ii;
      if (exTarifarioII) {
        const sanitizedValue = exTarifarioII.ii.replace(',', '.');
        copy.ii = {
          ...copy.ii,
          aliquota: Number(sanitizedValue),
        };
      }

      const exTarifarioIPI = unnormalizedData?.metaAliquotas?.[`adicao_${a.id}`]?.exTarifario?.ipi;
      if (exTarifarioIPI) {
        const sanitizedValue = exTarifarioIPI.ipi.replace(',', '.');

        if (sanitizedValue === 'NT') {
          copy.ipi = {
            ...copy.ipi,
            ipiNaoTributavel: true,
          };
        } else {
          copy.ipi = {
            ...copy.ipi,
            aliquota: Number(sanitizedValue),
          };
        }
      }

      return copy;
    });

    return adicoesAliquotas;
  }, []);

  const handleChangeModel = useCallback((updatedModel) => {
    dispatch(setRelatedAdicaoModel(updatedModel));
  }, []);

  const handleEdit = useCallback(() => {
    if (!isCreate) {
      handleChangeToUpdate({
        callback: handleChangeToSubUpdate,
      });

      return;
    }

    handleChangeToSubUpdate();
  }, [isCreate, handleChangeToUpdate, handleChangeToSubUpdate]);

  const handleInternalCancelUpdate = useCallback(() => {
    if (isUpdate) {
      handleCancelUpdate({
        callback: handleChangeToSubConsult,
      });

      return;
    }

    handleChangeToSubConsult();
  }, [isUpdate, handleCancelUpdate, handleChangeToSubConsult]);

  const handleClearForm = useCallback(() => {
    dispatch(resetRelatedAdicaoModel());
  }, []);

  const [handleGenerateLiByAdicaoMiddleware, formDialogGenerateLiStatus, handleFormDialogGenerateLiStatus] =
    useFormDialogAsync();

  const handleCloseFormDialogGenerateLi = useCallback(
    () => handleFormDialogGenerateLiStatus(CLOSED_STATUS),
    [handleFormDialogGenerateLiStatus]
  );

  const isFormDialogContinueToTransferOpen = useMemo(
    () => isOpenStatus(formDialogGenerateLiStatus),
    [formDialogGenerateLiStatus]
  );

  const handleGerarLiByAdicao = useCallback(async (data) => {
    dispatch(loading());
    handleFormDialogGenerateLiStatus(SUBMITTING_STATUS);

    const response = await mercadoriaMovimentacaoAPI
      .transferAdicaoToLi({
        di: { id: data?.id },
        servico: { id: data?.servico.id },
      })
      .catch((reason) => {
        const errorMessage = t('com.muralis.qcx.erro.erroGerarLIAdicaoDIDUIMP', {
          item: data?.item,
          processo: parentModel?.atributosAdicionais?.processo,
          mensagem: reason?.response?.data?.message ? `: ${reason?.response?.data?.message}.` : '.',
        });

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
        handleFormDialogGenerateLiStatus(CLOSED_STATUS);
      });

    if (response?.status === 200) {
      dispatch(success());
      dispatch(
        setSuccessFeedback({
          message: t('com.muralis.qcx.mensagem.LIgeradaPartirAdicaoDIDUIMP', {
            processo: response?.data?.atributosAdicionais?.processo,
            item: data?.item,
            atributosAdicionais: parentModel?.atributosAdicionais?.processo,
          }),
        })
      );
      handleFormDialogGenerateLiStatus(DONE_STATUS);
    }
  }, []);

  const [gerarLiInitialValues, setGerarLiInitialValues] = useState();

  const handleConfigureGenerateLiByAdicaoClick = useCallback(
    (row, popupState) => {
      const { id, item, metaFields } = row;

      const handleGenerateLiByAdicaoClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          setGerarLiInitialValues({ id, item, metaFields });

          handleGenerateLiByAdicaoMiddleware();
        }
      };

      return handleGenerateLiByAdicaoClick;
    },
    [parentModel, handleGenerateLiByAdicaoMiddleware]
  );

  const [handleRemoveByIdMiddleware, operationOfRemoveById] = useOperationMiddleware(
    async (data, refreshCallback) => {
      const composeWith = (parent) => {
        dispatch(resetRelatedAdicaoModel(data));

        return {
          ...parent,
          atributosAdicionais: {
            ...parent?.atributosAdicionais,
            adicoes: [...(parent?.atributosAdicionais?.adicoes?.filter((current) => current?.id !== data?.id) || [])],
          },
        };
      };

      const executeDebounced = debounce(async () => {
        try {
          const response = await handleCustomSaveBy({
            composeWith,
            step: 9, // TODO: Verificar dinamicamento de acordo com o Tipo de Declaração atual
          });

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              dispatch(success());

              const saved = response?.data;

              const processo = saved?.atributosAdicionais?.processo;
              const adicaoNumber = data?.item;

              const feedbackMessage = t('com.muralis.qcx.mensagem.adicaoRemovidaListaDeclaracaoImportacao', {
                adicaoNumber,
                processo,
              });

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: feedbackMessage,
                })
              );

              dispatch(changeToSubConsultMode());
              dispatch(fetchByIdFromFaturaAsync(saved?.id));

              if (isFunction(refreshCallback)) {
                refreshCallback(saved?.atributosAdicionais?.adicoes);
              }
            }, 500);

            handleResultWithDebounce();
          }
        } catch ({ request, response }) {
          dispatch(failure());
          dispatch(
            setErrorFeedback({
              message: t('com.muralis.qcx.erro.erroRemoverAdicaoListaDeclaracaoImportacao', {
                item: data?.item,
                processo: request?.data?.atributosAdicionais?.processo,
                mensagem: response?.data?.message,
              }),
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    },
    [handleCustomSaveBy]
  );

  const handleRemoveByForm = useCallback(
    (refreshCallback) => {
      handleChangeToBackgroundDelete();

      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.acoes.confirmarOperacaoRemocao').toUpperCase(),
          message: t('com.muralis.qcx.mensagem.confirmarOperacaoAdicao', {
            item: adicao?.item,
          }),
          endMessage: t('com.muralis.qcx.acoes.certezaRemocao'),
        },
        cleanUp: () => {
          dispatch(resetBackgroundMode());
        },
      });

      handleRemoveByIdMiddleware(configureOperation, adicao, refreshCallback);
    },
    [adicao, handleChangeToBackgroundDelete, handleRemoveByIdMiddleware]
  );

  const handleRemoveByTable = useCallback(
    ({ row }) => {
      handleChangeToBackgroundDelete();

      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.acoes.confirmarOperacaoRemocao').toUpperCase(),
          message: t('com.muralis.qcx.mensagem.confirmarOperacaoAdicao', {
            item: row?.item,
          }),
          endMessage: t('com.muralis.qcx.acoes.certezaRemocao'),
        },
        cleanUp: () => {
          dispatch(resetBackgroundMode());
        },
      });

      handleRemoveByIdMiddleware(configureOperation, row);
    },
    [handleChangeToBackgroundDelete, handleRemoveByIdMiddleware]
  );

  const handleSubmit = useCallback(
    async (data) => {
      const mercadoriasIds = data?.meta?.selected?.map((s) => s.id) ?? [];
      const atributosParaRepassar = data?.ignorableFields?.atributos?.map((a) => ({ ...a, id: null })) ?? [];

      const adicaoComAtributosRepassados = {
        ...data,
        mercadorias: [...(data.mercadorias?.map((m) => ({ ...m, atributos: [...(m.atributos ?? [])] })) ?? [])],
      };

      mercadoriasIds.forEach((mercadoriaId) => {
        if (adicaoComAtributosRepassados?.mercadorias) {
          const mercadoria = adicaoComAtributosRepassados.mercadorias.find((m) => m.id === mercadoriaId);
          mercadoria.atributos = atributosParaRepassar;
        }
      });

      const normalizedAdicao = normalize(adicaoComAtributosRepassados);

      if (normalizedAdicao?.declaracaoExportacoes) {
        normalizedAdicao.declaracaoExportacoes = normalizedAdicao.declaracaoExportacoes.map((de) => ({
          ...de,
          id: typeof de.id === 'string' ? null : de.id, // Verifica se o ID é um UUID string,
        }));
      }

      const composeWith = (parent) => {
        const normalizedAdicaoAliquotas = normalizeAdicaoAliquotas(parent, data);

        const composed = {
          ...parent,
          atributosAdicionais: {
            ...parent?.atributosAdicionais,
            adicoes: normalizedAdicao?.id
              ? [
                  ...(parent?.atributosAdicionais?.adicoes.map((current) =>
                    current?.id === data?.id ? normalizedAdicao : current
                  ) || []),
                ]
              : normalizedAdicaoAliquotas,
          },
        };

        return composed;
      };

      const executeDebounced = debounce(async () => {
        try {
          const response = await handleCustomSaveBy({
            composeWith,
            step: 9, // TODO: Verificar dinamicamento de acordo com o Tipo de Declaração atual
          });

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              dispatch(success());

              const saved = response?.data;

              const processo = saved?.atributosAdicionais?.processo;
              const adicaoNumber = data?.item;

              const feedbackMessage = t('com.muralis.qcx.mensagem.adicaoDeclaracaoImportacaoSalva', {
                adicaoNumber,
                processo,
              });

              dispatch(
                setSuccessFeedback({
                  message: feedbackMessage,
                })
              );

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: feedbackMessage,
                })
              );

              if (isUpdate) {
                handleChangeToConsult();
              }

              dispatch(changeToSubConsultMode());
              dispatch(fetchByIdFromFaturaAsync(saved?.id));
            }, 500);

            handleResultWithDebounce();
          }
        } catch ({ request, response }) {
          dispatch(failure());
          dispatch(
            setErrorFeedback({
              message: t('com.muralis.qcx.erro.erroSalvarAdicaoListaDeclaracaoImportacao', {
                item: data?.item,
                atributosAdicionais: request?.data?.atributosAdicionais?.processo,
                mensagem: response?.data?.message,
              }),
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    },
    [isUpdate, normalize, handleCustomSaveBy, handleChangeToConsult]
  );

  const handleAlternativeSaveAdicao = useCallback(() => {
    if (isEmpty(controlComponentProps) || !isFunction(controlComponentProps?.handleAlternativeSave)) {
      throw Error('Error: "handleAlternativeSave" is not a function.');
    }

    const { handleAlternativeSave } = controlComponentProps;

    // TODO: Verificar dinamicamento de acordo com o Tipo de Declaração atual
    const adicoesStepIndex = 8;

    handleAlternativeSave(null, adicoesStepIndex);
  }, [controlComponentProps]);

  const [onBlurObjectHandlers] = useFaturaTotaisListeners(isConsult, moedas);

  const catalogoProdutoActions = useMemo(
    () => ({
      nveActions,
      nveAtributoActions,
      nveEspecificacaoActions,
    }),
    [nveActions, nveAtributoActions, nveEspecificacaoActions]
  );

  const relacaoFornecedorListeners = useFornecedorListenersGroup({
    dadosComumFornecedor: false,
  });

  const catalogoProdutoFormProps = useMemo(
    () => ({
      ncmList,
      ncm: adicao?.ncm,
    }),
    [ncmList, adicao]
  );

  const catalogoProdutosAtributosChangeListeners = useCatalogoProdutosAtributosListeners({
    rootName: 'ignorableFields.atributoNve',
    modes,
    actions: catalogoProdutoActions,
    formProps: catalogoProdutoFormProps,
  });

  const ipiOnChangeListeners = useIpiListenersGroup(isConsult);

  const regimeTributacaoII = useCallback((values) => values?.ii?.regimeTributacao?.id, []);

  const isSomeRegimeTributacaoIIBy = useCallback(
    (values, codesToCompare) =>
      configureGeneralRegimeTributacaoMatcherWith(regimesTributacao)(regimeTributacaoII(values), codesToCompare),
    [regimeTributacaoII, regimesTributacao]
  );

  const isRegimeTributacaoIISuspensaoBy = useCallback(
    (values) => configureSpecificRegimeTributacaoSuspensaoMatcher(regimesTributacao)(regimeTributacaoII(values)),
    [regimeTributacaoII, regimesTributacao]
  );

  const isRegimeTributacaoIIImunidadeBy = useCallback(
    (values) => configureSpecificRegimeTributacaoImunidadeMatcher(regimesTributacao)(regimeTributacaoII(values)),
    [regimeTributacaoII, regimesTributacao]
  );

  const metodoValoracao = useCallback((values) => values?.metodoValoracaoAduaneira?.id, []);

  const isMetodoValoracaoArt1By = useCallback(
    (values) => configureSpecificMetodoValoracaoArt1SuspensaoMatcher(metodosDeValoracao)(metodoValoracao(values)),
    [metodoValoracao, metodosDeValoracao]
  );

  // Se tive acrescimoDeducao na capa deve bloquear o bondManager
  const hasAcrescimosDeducoesNaCapa = useMemo(
    () => !isEmpty(parentModel?.atributosAdicionais?.acrescimosDeducoes),
    [parentModel]
  );

  const tipoDeclaracao = useMemo(() => parentModel?.atributosAdicionais?.tipoDeclaracao?.id, [parentModel]);

  const isSomeTipoDeclaracaoBy = useCallback(
    (codesToCompare) => configureGeneralTipoDeclaracaoMatcherWith(tiposDeclaracao)(tipoDeclaracao, codesToCompare),
    [tiposDeclaracao, tipoDeclaracao]
  );

  const handleCoberturaCambialChange = (form) => (value) => {
    if (isCoberturaCambialSemCobertura(value)) {
      form.change('instituicaoFinanciadora', undefined);
      form.change('valorFornecedor', undefined);

      return;
    }

    if (
      isSomeCoberturaCambial(value, [
        COBERTURA_CAMBIAL_COM_COBERTURA_ATE_180_DIAS,
        COBERTURA_CAMBIAL_COM_COBERTURA_180_360_DIAS,
      ])
    ) {
      form.change('instituicaoFinanciadora', undefined);
      form.change('motivoImportacaoSemCoberturaCambial', undefined);
      form.change('valorFornecedor', undefined);
      form.change('numeroRof', undefined);

      return;
    }

    if (isCoberturaCambialComCoberturaAcima360Dias(value)) {
      form.change('motivoImportacaoSemCoberturaCambial', undefined);
    }
  };

  const handleMoedaAcrescimoDeducaoIdChange = useCallback(
    (form) => (value) => {
      if (!(isConsult || isAcrescimoDeducaoUpdate)) {
        if (!value) {
          form.change('ignorableFields.acrescimoDeducao.valorMoeda', '');
          form.change('ignorableFields.acrescimoDeducao.valorReal', '');

          form.resetFieldState('ignorableFields.acrescimoDeducao.valorMoeda');
          form.resetFieldState('ignorableFields.acrescimoDeducao.valorReal');
        } else {
          form.change('ignorableFields.acrescimoDeducao.valorReal', '');
          form.resetFieldState('ignorableFields.acrescimoDeducao.valorReal');
        }
      }
    },
    [isConsult, isAcrescimoDeducaoUpdate]
  );

  const isTipoDeclaracaoInternacaoZFMPIBy = useMemo(
    () =>
      isSomeTipoDeclaracaoBy([
        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
      ]),
    [isSomeTipoDeclaracaoBy]
  );

  const handleRegimeTributacaoIIIdChange = useCallback(
    (form, values) => (value) => {
      if (!isConsult) {
        if (value && isRegimeTributacaoIISuspensaoBy(values)) {
          form.change('ipi.regimeTributacao.id', value);
        }

        if (isTipoDeclaracaoInternacaoZFMPIBy && (!value || isRegimeTributacaoIIImunidadeBy(values))) {
          form.change('ii.identificacao', undefined);
          form.change('ii.coeficienteReducao', undefined);
          form.change('ii.valorDolar', undefined);
          form.change('ii.valorReal', undefined);
          form.change('ii.valorDevidoReal', undefined);
          form.change('ii.valorRecolherReal', undefined);
        }

        form.change('ii.fundamentoLegalRegimeTributacaoIi.id', undefined);

        if (
          isSomeRegimeTributacaoIIBy(values, [REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE, REGIME_TRIBUTACAO_IMUNIDADE_CODE])
        ) {
          form.change('ipi', undefined);
        }
      }
    },
    [isConsult, isRegimeTributacaoIISuspensaoBy]
  );

  const handleRegimeTributacaoIPIIdChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (form, values) => (value) => {
      if (!isConsult) {
        form.change('ipi.tipoAtoLegalFundamentoLegal.id', undefined);
        form.change('ipi.orgaoEmissorFundamentoLegal.id', undefined);
        form.change('ipi.numeroAtoFundamentoLegal', '');
        form.change('ipi.anoFundamentoLegal', '');
        form.change('ipi.numeroExFundamentoLegal', '');
      }
    },
    [isConsult]
  );

  const handleRegimeTributacaoPISCOFINSIdChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (form, values) => (value) => {
      if (!isConsult) {
        form.change('pisCofins.fundamentoLegal.id', undefined);
        form.change('pisCofins.fundamentoLegalRegimeTributacaoPisCofins.id', undefined);
      }
    },
    [isConsult]
  );

  const handleRegimeTributacaoICMSIdChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (form, values) => (value) => {
      if (!isConsult) {
        form.change('icms.dispositivoLegal.id', undefined);
        form.change('icms.tipoReducao', undefined);
        form.change('icms.recolhimentoAntecipado', undefined);
        form.change('icms.aliquotaAntecipadaPercentual', unnormalizeNumeral(0, formatBrazilianNumericDecimal(7)));
      }
    },
    [isConsult]
  );

  const initialValues = useMemo(() => (!isSubCreate ? unnormalize(adicao) : {}), [isSubCreate, adicao, unnormalize]);

  const defaultRestartedValues = useMemo(
    () => ({
      // TODO: Definir os valores default para o restart do form
    }),
    []
  );

  const internalTabManagerProps = useMemo(
    () => ({
      ...tabManagerProps,
      scrollButtons: 'auto',
      variant: 'scrollable',
      tabs: [
        {
          label: t('com.muralis.qcx.lista'),
          disabled: isSubUpdate,
        },
        {
          label: t('com.muralis.qcx.fornecedor.label'),
          disabled: !hasItems || isSubNone,
        },
        {
          label: t('com.muralis.qcx.mercadoria.label'),
          disabled: !hasItems || isSubNone,
        },
        {
          label: t('com.muralis.qcx.mercadoria.valorAduaneiro'),
          disabled: !hasItems || isSubNone,
        },
        {
          label: t('com.muralis.qcx.imposto.tributos'),
          disabled: !hasItems || isSubNone,
        },
      ],
      onlyActiveTabEnabled: isSubUpdate,
    }),
    [hasItems, isSubNone, isSubUpdate]
  );

  const internalControlButtonsPaginationProps = useMemo(
    () => ({
      paginationInfoField: {
        label: t('com.muralis.qcx.adicao.label'),
      },
      buttonGroup: {
        first: {
          description: t('com.muralis.qcx.adicao.primeiraAdicao'),
        },
        next: {
          description: t('com.muralis.qcx.adicao.proximaAdicao'),
        },
        previous: {
          description: t('com.muralis.qcx.adicao.adicaoAnterior'),
        },
        last: {
          description: t('com.muralis.qcx.adicao.ultimaAdicao'),
        },
      },
    }),
    [formPaginationControlProps]
  );

  const tableProps = useMemo(
    () => ({
      title: t('com.muralis.qcx.adicao.adicoesAdicionadas'),
      columns: [
        {
          field: 'nroAdicao',
          headerName: 'Ad',
          headerAlign: 'center',
          align: 'center',
          flex: 120,
          valueGetter: ({ row }) => row?.item || '-',
        },
        {
          field: 'quantidadeItens',
          headerName: 'Qt It',
          headerAlign: 'center',
          align: 'center',
          flex: 120,
          valueGetter: ({ row }) => row?.mercadorias?.length || '-',
        },
        {
          field: 'ncm',
          headerName: 'Ncm',
          headerAlign: 'center',
          align: 'center',
          flex: 160,
          valueGetter: ({ row }) => row?.ncm?.code || '-',
        },
        {
          field: 'numeroLi',
          headerName: 'Nro. LI',
          headerAlign: 'center',
          align: 'center',
          flex: 160,
          valueGetter: ({ row }) => row?.li?.registro || row?.mercadorias?.[0]?.numeroLI || '-',
        },
        {
          field: 'ii',
          headerName: 'II',
          headerAlign: 'center',
          align: 'center',
          flex: 140,
          valueFormatter: ({ row }) => unnormalizeNumeral(row?.ii?.aliquota, formatBrazilianNumericDecimal(2)) || '-',
        },
        {
          field: 'ipi',
          headerName: 'IPI',
          headerAlign: 'center',
          align: 'center',
          flex: 140,
          valueFormatter: ({ row }) => unnormalizeNumeral(row?.ipi?.aliquota, formatBrazilianNumericDecimal(2)) || '-',
        },
        {
          field: 'pis',
          headerName: 'PIS',
          headerAlign: 'center',
          align: 'center',
          flex: 140,
          valueFormatter: ({ row }) =>
            unnormalizeNumeral(row?.pisCofins?.aliquotaPisPasep, formatBrazilianNumericDecimal(2)) || '-',
        },
        {
          field: 'cofins',
          headerName: 'COFINS',
          headerAlign: 'center',
          align: 'center',
          flex: 160,
          valueFormatter: ({ row }) =>
            unnormalizeNumeral(row?.pisCofins?.aliquotaCofins, formatBrazilianNumericDecimal(2)) || '-',
        },
        {
          field: 'icms',
          headerName: 'ICMS',
          headerAlign: 'center',
          align: 'center',
          flex: 140,
          valueFormatter: ({ row }) => unnormalizeNumeral(row?.icms?.aliquota, formatBrazilianNumericDecimal(2)) || '-',
        },
        {
          field: 'aplicacaoMercadoria',
          headerName: 'Aplicação',
          headerAlign: 'center',
          align: 'center',
          flex: 200,
          valueFormatter: ({ row }) => row?.aplicacaoMercadoria || '-',
        },
      ],
      renderOtherActions: ({ popup, params }) => (
        <MenuItem
          id={`btn-generate-li-by-adicao-${params?.row?.id}`}
          key={`btn-generate-li-by-adicao-${params?.row?.id}`}
          onClick={handleConfigureGenerateLiByAdicaoClick(params?.row, popup?.state)}
        >
          <QCXListItemIcon>
            <FileUploadIcon className={classes.generateLiIcon} fontSize="small" />
          </QCXListItemIcon>
          <Typography
            variant="inherit"
            style={{
              fontSize: 12,
            }}
          >
            {t('com.muralis.qcx.acoes.gerarLI').toUpperCase()}
          </Typography>
        </MenuItem>
      ),
    }),
    [handleConfigureGenerateLiByAdicaoClick]
  );

  const localeTableText = useMemo(
    () => ({
      noRowsLabel: t('com.muralis.qcx.validacao.nenhumaAdicaoEncontrada'),
    }),
    []
  );

  const currentTitleSubPage = useMemo(() => t('com.muralis.qcx.adicao.dadosAdicao'), []);

  const isRelatedLi = (values) => !!values?.li?.registro;

  const changeListeners = useMemo(
    () => [
      ...relacaoFornecedorListeners,
      ...catalogoProdutosAtributosChangeListeners,
      ...ipiOnChangeListeners,
      {
        name: 'coberturaCambial',
        fn: handleCoberturaCambialChange,
      },
      {
        name: 'ignorableFields.acrescimoDeducao.moeda.id',
        fn: handleMoedaAcrescimoDeducaoIdChange,
      },
      {
        name: 'ii.regimeTributacao.id',
        fn: handleRegimeTributacaoIIIdChange,
      },
      {
        name: 'ipi.regimeTributacao.id',
        fn: handleRegimeTributacaoIPIIdChange,
      },
      {
        name: 'pisCofins.regimeTributacao.id',
        fn: handleRegimeTributacaoPISCOFINSIdChange,
      },
      {
        name: 'icms.regimeTributacao.id',
        fn: handleRegimeTributacaoICMSIdChange,
      },
    ],
    [
      ipiOnChangeListeners,
      relacaoFornecedorListeners,
      catalogoProdutosAtributosChangeListeners,
      handleCoberturaCambialChange,
      handleMoedaAcrescimoDeducaoIdChange,
      handleRegimeTributacaoIIIdChange,
    ]
  );

  const renderRelacaoFornecedorSiblings = useCallback(
    (values) => (
      <Grid item className={classes.sibling} xs={12} sm={12} md={12} lg={12}>
        <QCXSelectVinculoCompradorVendedorAutocomplete
          id="autocomplete-select-vinculo-comprador-vendedor-field"
          key="autocomplete-select-vinculo-comprador-vendedor-field"
          name="vinculacaoCompradorVendedor"
          label={t('com.muralis.qcx.vinculacaoCompradorVendedor')}
          initialValues={values}
          disabled={isConsult || isSubConsult || isSubNone}
        />
      </Grid>
    ),
    [isConsult, isSubNone, isSubConsult, classes]
  );

  useEffect(() => {
    const cleanUpOnUnmount = () => {
      handleChangeToSubNone();
    };

    return cleanUpOnUnmount;
  }, []);

  const [isTecwinDialogOpen, setIsTecwinDialogOpen] = useState(false);
  const [showTecwinButton, setShowTecwinButton] = useState(true);

  const handleTabChange = (tabIndex) => setShowTecwinButton(tabIndex === 0);

  return (
    <>
      <QCXFormStepWizard
        initialValues={initialValues}
        onSubmit={handleSubmit}
        controlComponentProps={controlComponentProps}
        onChangeListeners={changeListeners}
        alternativeMode
        {...restProps}
      >
        {({ values, submitting, submitSucceeded }) => (
          <>
            <QCXCustomRegistrationTabControlTemplate
              initialValues={initialValues}
              title={currentTitleSubPage}
              tableProps={tableProps}
              tableData={adicoes}
              isPreparingAction={isPreparingAction}
              isIdle={isIdle}
              isLoading={isLoading}
              isCreate={isCreate}
              isConsult={isConsult}
              isUpdate={isUpdate}
              isSubNone={isSubNone}
              isSubCreate={isSubCreate}
              isSubConsult={isSubConsult}
              isSubUpdate={isSubUpdate}
              isBackgroundDelete={isBackgroundDelete}
              isSubBackgroundCreate={isSubBackgroundCreate}
              isSubBackgroundUpdate={isSubBackgroundUpdate}
              isSubBackgroundDelete={isSubBackgroundDelete}
              submitting={submitting}
              submitSucceeded={submitSucceeded}
              defaultItem={defaultItem}
              handleEdit={handleEdit}
              handleChangeToSubConsult={handleChangeToSubConsult}
              handleChangeModel={handleChangeModel}
              handleCancelUpdate={handleInternalCancelUpdate}
              handleChangeToConsult={handleChangeToConsult}
              handleRemoveByForm={handleRemoveByForm}
              handleRemoveByTable={handleRemoveByTable}
              handleClearForm={handleClearForm}
              defaultRestartedValues={defaultRestartedValues}
              handleChangeToSubNone={handleChangeToSubNone}
              handleChangeToUpdate={handleChangeToUpdate}
              handleLoadingStatus={handleLoadingStatus}
              handleResetStatus={handleResetStatus}
              localeTableText={localeTableText}
              handleAlternativeSave={handleAlternativeSaveAdicao}
              handleMetadataUpdate={handleMetadataUpdate}
              tabManagerProps={internalTabManagerProps}
              formPaginationControlProps={formPaginationControlProps}
              controlButtonsPaginationProps={internalControlButtonsPaginationProps}
              buttonGroupOptions={buttonGroupOptions}
              hiddenNewButton
              tabChangeCallback={handleTabChange}
            >
              {/* FORNECEDOR */}
              {() => (
                <>
                  <Grid item container xs={12} sm={12} md={12} lg={12}>
                    {isRelatedLi(values) && (
                      <QCXAloneInlineBoxWrapper>
                        {(fieldProps) => (
                          <QCXFinalTextField
                            id="text-field-registro-li"
                            key="text-field-registro-li"
                            name="li.registro"
                            label={t('com.muralis.qcx.numeroRegistroLI')}
                            disabled
                            {...fieldProps}
                          />
                        )}
                      </QCXAloneInlineBoxWrapper>
                    )}
                  </Grid>
                  {!isRelatedLi(values) && (
                    <Grid item container alignItems="center" spacing={2} xs={12} sm={12} md={12} lg={12}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <QCXFinalOnblurMaskedField
                          id="text-field-numeroLI"
                          key="text-field-numeroLI"
                          name="mercadorias[0].numeroLI"
                          label={t('com.muralis.qcx.mercadoria.numeroLI')}
                          format={formatLINumber}
                          parse={numberOnly}
                          maxLength={11}
                          disabled={isConsult || isSubConsult || isSubNone}
                          initialValues={values}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <QCXFinalDatePickerField
                          id="registro-date-field"
                          key="registro-date-field"
                          name="mercadorias[0].dataRegistro"
                          label={t('com.muralis.qcx.mercadoria.dataRegistro')}
                          disabled={isConsult || isSubConsult || isSubNone}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <QCXRelacaoFornecedorFieldGroup
                    isConsult={isConsult}
                    isAlternativeReasonToDisabled={isSubConsult || isSubNone}
                    relacao={values?.relacao}
                    fabricante={values?.fabricante}
                    exportador={values?.exportador}
                    required={false}
                    initialValues={values}
                    renderSiblings={renderRelacaoFornecedorSiblings}
                    {...restProps}
                  />
                  <QCXAloneInlineBoxWrapper>
                    {(fieldProps) => (
                      <QCXSelectCoberturaCambialAutocomplete
                        id="autocomplete-select-cobertura-cambial-field"
                        key="autocomplete-select-cobertura-cambial-field"
                        name="coberturaCambial"
                        label={t('com.muralis.qcx.coberturaCambial')}
                        initialValues={values}
                        disabled={isConsult || isSubConsult || isSubNone}
                        {...fieldProps}
                      />
                    )}
                  </QCXAloneInlineBoxWrapper>
                  {isSomeCoberturaCambial(values?.coberturaCambial, [
                    COBERTURA_CAMBIAL_SEM_COBERTURA,
                    COBERTURA_CAMBIAL_COM_COBERTURA_ACIMA_360_DIAS,
                  ]) && (
                    <Grid item container xs={12} sm={6} md={6} lg={6}>
                      {isCoberturaCambialComCoberturaAcima360Dias(values?.coberturaCambial) &&
                        !isSomeTipoDeclaracaoBy([
                          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
                          TIPO_DECLARACAO_ADMISSAO_EM_LOJA_FRANCA,
                          TIPO_DECLARACAO_ADMISSAO_EM_DAD_DEPOSITO_ADUANEIRO_DE_DISTRIBUICAO,
                          TIPO_DECLARACAO_ADMISSAO_EM_DE_DAF,
                          TIPO_DECLARACAO_SAIDA_DE_ENTREPOSTO_INDUSTRIAL,
                          TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
                          TIPO_DECLARACAO_INTERNACAO_DE_ALC_AREA_DE_LIVRE_COMERCIO,
                        ]) && (
                          <Grid
                            item
                            style={{
                              paddingBottom: '16px',
                            }}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                          >
                            <QCXSelectInstituicaoFinanciadoraAutocomplete
                              id="autocomplete-select-instituicao-financiadora-field"
                              key="autocomplete-select-instituicao-financiadora-field"
                              name="instituicaoFinanciadora.id"
                              label={t('com.muralis.qcx.instituicaoFinanceira')}
                              initialValues={values}
                              disabled={isConsult || isSubConsult || isSubNone}
                            />
                          </Grid>
                        )}
                      {isCoberturaCambialSemCobertura(values?.coberturaCambial) && (
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <QCXSelectMotivoImportacaoSemCoberturaCambialAutocomplete
                            id="autocomplete-select-motivo-importacao-sem-cobertura-cambial-field"
                            key="autocomplete-select-motivo-importacao-sem-cobertura-cambial-field"
                            name="motivoImportacaoSemCoberturaCambial.id"
                            label={t('com.muralis.qcx.motivo')}
                            initialValues={values}
                            disabled={isConsult || isSubConsult || isSubNone}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                  <Grid item container xs={12} sm={6} md={6} lg={6}>
                    {isCoberturaCambialComCoberturaAcima360Dias(values?.coberturaCambial) &&
                      !isSomeTipoDeclaracaoBy([
                        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
                        TIPO_DECLARACAO_ADMISSAO_EM_LOJA_FRANCA,
                        TIPO_DECLARACAO_ADMISSAO_EM_DAD_DEPOSITO_ADUANEIRO_DE_DISTRIBUICAO,
                        TIPO_DECLARACAO_ADMISSAO_EM_DE_DAF,
                        TIPO_DECLARACAO_SAIDA_DE_ENTREPOSTO_INDUSTRIAL,
                        TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
                        TIPO_DECLARACAO_INTERNACAO_DE_ALC_AREA_DE_LIVRE_COMERCIO,
                      ]) && (
                        <Grid
                          item
                          style={{
                            paddingBottom: '16px',
                          }}
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                        >
                          <QCXFinalAlternativeCurrencyField
                            id="currency-valor-fornecedor-field"
                            key="currency-valor-fornecedor-field"
                            name="valorFornecedor"
                            label={t('com.muralis.qcx.valor')}
                            disabled={isConsult || isSubConsult || isSubNone}
                            submitSucceeded={submitSucceeded}
                            scale={7}
                          />
                        </Grid>
                      )}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <QCXFinalTextField
                        id="text-field-numero-rof"
                        key="text-field-numero-rof"
                        name="numeroRof"
                        label={t('com.muralis.qcx.numeroROF')}
                        disabled={isConsult || isSubConsult || isSubNone}
                      />
                    </Grid>
                  </Grid>
                  {isSomeTipoDeclaracaoBy([TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA]) && (
                    <>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <QCXFinalNumericIntegerField
                          id="text-field-tempo-de-permanencia"
                          key="text-field-tempo-de-permanencia"
                          name="tempoPermanencia"
                          label={t('com.muralis.qcx.tempoPermanencia')}
                          allowNegative={false}
                          disabled={isConsult || isSubConsult || isSubNone}
                          format={formatNotZero}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
              {/* MERCADORIA */}
              {() => (
                <>
                  <MercadoriaAdicaoForm
                    isConsult={isConsult}
                    isSubConsult={isSubConsult}
                    isSubNone={isSubNone}
                    classes={classes}
                    submitSucceeded={submitSucceeded}
                    modes={modes}
                    isSomeTipoDeclaracaoBy={isSomeTipoDeclaracaoBy}
                  />
                </>
              )}
              {/* VALOR ADUANEIRO */}
              {() => (
                <>
                  <Grid item container xs={12} sm={12} md={12} lg={12}>
                    {isRelatedLi(values) && (
                      <QCXAloneInlineBoxWrapper>
                        {(fieldProps) => (
                          <QCXFinalTextField
                            id="text-field-registro-li"
                            key="text-field-registro-li"
                            name="li.registro"
                            label={t('com.muralis.qcx.numeroRegistroLI')}
                            disabled
                            {...fieldProps}
                          />
                        )}
                      </QCXAloneInlineBoxWrapper>
                    )}
                  </Grid>
                  <Grid item container alignItems="center" spacing={2} xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <QCXFinalOnblurMaskedField
                        id="text-field-numeroLI"
                        key="text-field-numeroLI"
                        name="mercadorias[0].numeroLI"
                        label={t('com.muralis.qcx.mercadoria.numeroLI')}
                        format={formatLINumber}
                        parse={numberOnly}
                        maxLength={11}
                        disabled={isConsult || isSubConsult || isSubNone}
                        initialValues={values}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <QCXFinalDatePickerField
                        id="registro-date-field"
                        key="registro-date-field"
                        name="mercadorias[0].dataRegistro"
                        label={t('com.muralis.qcx.mercadoria.dataRegistro')}
                        disabled={isConsult || isSubConsult || isSubNone}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <QCXMoveFocusInside>
                      <QCXSelectIncotermCondicaoVendaAutocomplete
                        id="select-field-incoterm"
                        key="select-field-incoterm"
                        name="incotermCondicaoVenda.id"
                        label={t('com.muralis.qcx.incoterm')}
                        disabled={isConsult || isSubConsult || isSubNone}
                        initialValues={values}
                      />
                    </QCXMoveFocusInside>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <QCXSelectMetodoValoracaoAutocomplete
                      id="select-field-metodo-de-valoracao"
                      key="select-field-metodo-de-valoracao"
                      name="metodoValoracaoAduaneira.id"
                      label={t('com.muralis.qcx.metodoValoracao')}
                      disabled={isConsult || isSubConsult || isSubNone}
                      initialValues={values}
                    />
                  </Grid>
                  <QCXAloneInlineBoxWrapper>
                    {(fieldProps) => (
                      <QCXSelectPortoAeroportoAutocomplete
                        id="select-field-local-condicao-venda"
                        key="select-field-local-condicao-venda"
                        name="portoAeroporto.id"
                        label={t('com.muralis.qcx.localCondicaoVenda')}
                        disabled={isConsult || isSubConsult || isSubNone}
                        initialValues={values}
                        {...fieldProps}
                      />
                    )}
                  </QCXAloneInlineBoxWrapper>

                  <Grid item container xs={12} sm={12} md={6} lg={6}>
                    <Grid
                      item
                      style={{
                        paddingBottom: '16px',
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <QCXFinalAlternativeCurrencyField
                        id="currency-valor-condicao-venda-field"
                        key="currency-valor-condicao-venda-field"
                        name="valorMcvMoeda"
                        label={t('com.muralis.qcx.mercadoria.valorTotalMercadoriaCondicaoVenda')}
                        disabled
                        submitSucceeded={submitSucceeded}
                        scale={7}
                      />
                    </Grid>
                    <Grid
                      item
                      style={{
                        paddingBottom: '16px',
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <QCXFinalAlternativeCurrencyField
                        id="currency-valor-condicao-venda-field"
                        key="currency-valor-condicao-venda-field"
                        name="valorFreteMoeda"
                        label={t('com.muralis.qcx.frete.valorTotalFreteInternacional')}
                        disabled
                        submitSucceeded={submitSucceeded}
                        scale={7}
                      />
                    </Grid>
                    {isMetodoValoracaoArt1By(values) && (
                      <Grid
                        item
                        style={{
                          paddingBottom: '16px',
                        }}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                      >
                        <QCXFinalAlternativeCurrencyField
                          id="currency-valor-seguro-internacional-field"
                          key="currency-valor-seguro-internacional-field"
                          name="valorSeguroMoeda"
                          label={t('com.muralis.qcx.seguro.valorTotalSeguroInternacional')}
                          disabled
                          submitSucceeded={submitSucceeded}
                          scale={7}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container xs={12} sm={12} md={6} lg={6}>
                    <Grid
                      item
                      style={{
                        paddingBottom: '16px',
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <QCXFinalAlternativeCurrencyField
                        id="currency-valor-condicao-venda-reais-field"
                        key="currency-valor-condicao-venda-reais-field"
                        name="valorMcvReal"
                        label={t('com.muralis.qcx.mercadoria.valorTotalMercadoriaCondicaoVendaReais')}
                        intl={BRAZILIAN}
                        disabled
                        submitSucceeded={submitSucceeded}
                        adorned
                        scale={7}
                      />
                    </Grid>
                    <Grid
                      item
                      style={{
                        paddingBottom: '16px',
                      }}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <QCXFinalAlternativeCurrencyField
                        id="currency-valor-frete-reais-field"
                        key="currency-valor-frete-reais-field"
                        name="valorFreteReal"
                        label={t('com.muralis.qcx.frete.valorTotalFreteInternacionalReais')}
                        intl={BRAZILIAN}
                        disabled
                        submitSucceeded={submitSucceeded}
                        adorned
                        scale={7}
                      />
                    </Grid>
                    {isMetodoValoracaoArt1By(values) && (
                      <Grid
                        item
                        style={{
                          paddingBottom: '16px',
                        }}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                      >
                        <QCXFinalAlternativeCurrencyField
                          id="currency-valor-seguro-reais-field"
                          key="currency-valor-seguro-reais-field"
                          name="valorSeguroReal"
                          label={t('com.muralis.qcx.seguro.valorTotalSeguroInternacionalReais')}
                          intl={BRAZILIAN}
                          disabled
                          submitSucceeded={submitSucceeded}
                          adorned
                          scale={7}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {isMetodoValoracaoArt1By(values) && (
                    <>
                      <Grid item xs={12}>
                        <QCXFormSubtitle title={t('com.muralis.qcx.acrescimosDeducoes')} />
                      </Grid>
                      {hasAcrescimosDeducoesNaCapa && (
                        <Grid
                          item
                          xs={12}
                          style={{
                            marginTop: '0.5rem',
                            paddingBottom: '!important 0px',
                          }}
                        >
                          <QCXInfoAlert>
                            Ao incluir Acréscimos / Deduções na ficha TOTAIS a inclusão do mesmo na aba VALOR ADUANEIRO
                            da ficha Adições será desabilitada.
                          </QCXInfoAlert>
                        </Grid>
                      )}
                      <AcrescimoDeducaoBondManager
                        name="acrescimosDeducoes"
                        hasAdicoesAcrescimosDeducoes={hasAcrescimosDeducoesNaCapa}
                        classes={classes}
                        isConsult={isConsult}
                        onBlurObjectHandlers={onBlurObjectHandlers}
                      />
                    </>
                  )}

                  <Grid
                    item
                    style={{
                      paddingTop: '16px',
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <QCXFinalMultilineTextField
                      id="text-complemento-multiline-field"
                      key="text-complemento-multiline-field"
                      name="complemento"
                      label={t('com.muralis.qcx.complemento')}
                      disabled={isConsult || isSubConsult || isSubNone}
                      rows={10}
                      maxLength={250}
                      remaningCharactersInfo
                    />
                  </Grid>
                </>
              )}
              {/* TRIBUTOS */}
              {() => (
                <>
                  <Grid item container xs={12} sm={12} md={12} lg={12}>
                    {isRelatedLi(values) && (
                      <QCXAloneInlineBoxWrapper>
                        {(fieldProps) => (
                          <QCXFinalTextField
                            id="text-field-registro-li"
                            key="text-field-registro-li"
                            name="li.registro"
                            label={t('com.muralis.qcx.numeroRegistroLI')}
                            disabled
                            {...fieldProps}
                          />
                        )}
                      </QCXAloneInlineBoxWrapper>
                    )}
                  </Grid>
                  <Grid item container alignItems="center" spacing={2} xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <QCXFinalOnblurMaskedField
                        id="text-field-numeroLI"
                        key="text-field-numeroLI"
                        name="mercadorias[0].numeroLI"
                        label={t('com.muralis.qcx.mercadoria.numeroLI')}
                        format={formatLINumber}
                        parse={numberOnly}
                        maxLength={11}
                        disabled={isConsult || isSubConsult || isSubNone}
                        initialValues={values}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <QCXFinalDatePickerField
                        id="registro-date-field"
                        key="registro-date-field"
                        name="mercadorias[0].dataRegistro"
                        label={t('com.muralis.qcx.mercadoria.dataRegistro')}
                        disabled={isConsult || isSubConsult || isSubNone}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    style={{
                      paddingTop: '0px',
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <QCXFormTitle
                      title={t('com.muralis.qcx.imposto.II')}
                      boxProps={{
                        pt: 0,
                      }}
                    />
                  </Grid>

                  {/* IMPOSTOS II */}
                  <QCXIIFormGroup
                    intl={BRAZILIAN}
                    modes={modes}
                    submitSucceeded={submitSucceeded}
                    parentModel={parentModel}
                  />
                  {!isSomeRegimeTributacaoIIBy(values, [
                    REGIME_TRIBUTACAO_IMUNIDADE_CODE,
                    REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE,
                  ]) &&
                    !isSomeTipoDeclaracaoBy([
                      TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
                      TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
                    ]) && (
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <QCXFormTitle title={t('com.muralis.qcx.imposto.IPI')} />
                        </Grid>
                        <QCXIPIFormGroup
                          intl={BRAZILIAN}
                          modes={modes}
                          parentModel={parentModel}
                          submitSucceeded={submitSucceeded}
                        />
                      </>
                    )}
                  {!isSomeTipoDeclaracaoBy([
                    TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
                    TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
                  ]) && (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <QCXFormTitle title={t('com.muralis.qcx.legislacao.PISCOFINS')} />
                      </Grid>
                      <QCXPisCofinsFormGroup
                        intl={BRAZILIAN}
                        modes={modes}
                        parentModel={parentModel}
                        submitSucceeded={submitSucceeded}
                      />
                    </>
                  )}
                  {!isSomeTipoDeclaracaoBy([
                    TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
                    TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
                  ]) && (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <QCXFormTitle title={t('com.muralis.qcx.exportacao.antidumping')} />
                      </Grid>
                      <QCXAntidumpingFormGroup intl={BRAZILIAN} modes={modes} submitSucceeded={submitSucceeded} />
                    </>
                  )}
                  {!isSomeTipoDeclaracaoBy([
                    TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
                    TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
                  ]) &&
                    (parentModel?.followUp?.servico?.viaTransporte?.code === '01' ||
                      parentModel?.followUp?.servico?.viaTransporte?.code === '02' ||
                      parentModel?.followUp?.servico?.viaTransporte?.code === '03') && (
                      <>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <QCXFormTitle title={t('com.muralis.qcx.frete.AFRMM')} />
                        </Grid>
                        <QCXAFRMMFormGroup intl={BRAZILIAN} modes={modes} submitSucceeded={submitSucceeded} />
                      </>
                    )}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <QCXFormTitle title="Despesas Acessórias" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <QCXFinalAlternativeCurrencyField
                      id="valor-devido-reais-field-icms"
                      key="valor-devido-reais-field-icms"
                      name="valorDespesaAcessoriaReal"
                      label={t('com.muralis.qcx.valorDevidoReais')}
                      disabled
                      adorned
                    />
                  </Grid>
                  {!isSomeTipoDeclaracaoBy([
                    TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
                    TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
                  ]) && (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <QCXFormTitle title={t('com.muralis.qcx.taxaSiscomex')} />
                      </Grid>
                      <QCXSisComexFormGroup intl={BRAZILIAN} modes={modes} submitSucceeded={submitSucceeded} />
                    </>
                  )}
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <QCXFormTitle title={t('com.muralis.qcx.multaLi.label')} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <QCXFinalCheckboxField
                        name="multaLi.calculaMultaLi"
                        label={t('com.muralis.qcx.multaLi.calculaMultaLi')}
                        disabled={isConsult}
                      />
                    </Grid>
                    {values?.multaLi?.calculaMultaLi && (
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <QCXFinalCheckboxField
                          name="multaLi.multaPossuiReducao"
                          label={t('com.muralis.qcx.multaLi.comReducao')}
                          disabled={isConsult}
                        />
                      </Grid>
                    )}
                    {values?.multaLi?.calculaMultaLi && (
                      <Grid item xs={12} sm={6} md={6} lg={6} style={{ paddingTop: '2vh' }}>
                        <QCXFinalAlternativeCurrencyField
                          id="valor-devido-reais-tarifario-field-icms"
                          key="valor-devido-reais-tarifario-field-icms"
                          name="multaLi.valorDevidoReal"
                          label={t('com.muralis.qcx.valorDevidoReais')}
                          adorned
                          disabled
                        />
                      </Grid>
                    )}
                    {values?.multaLi?.calculaMultaLi && (
                      <Grid item xs={12} sm={6} md={6} lg={6} style={{ paddingTop: '2vh' }}>
                        <QCXFinalAlternativeCurrencyField
                          id="valor-recolher-reais-tarifario-field-icms"
                          key="valor-recolher-tarifario-field-icms"
                          name="multaLi.valorRecolherReal"
                          label={t('com.muralis.qcx.valorRecolherReais')}
                          adorned
                          disabled
                        />
                      </Grid>
                    )}
                  </>
                  {!isSomeTipoDeclaracaoBy([
                    TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PI_PRODUTO_INDUSTRIALIZADO,
                    TIPO_DECLARACAO_INTERNACAO_DA_ZFM_PE_PRODUTO_ESTRANGEIRO,
                  ]) && (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <QCXFormTitle title={t('com.muralis.qcx.ICMS.label')} />
                      </Grid>
                      <QCXIcmsFormGroup
                        intl={BRAZILIAN}
                        modes={modes}
                        parentModel={parentModel}
                        submitSucceeded={submitSucceeded}
                      />
                    </>
                  )}
                </>
              )}
            </QCXCustomRegistrationTabControlTemplate>

            {showTecwinButton && (
              <>
                <Box width="100%" display="flex" justifyContent="flex-end" mt={3}>
                  <QCXButton variant="contained" color="secondary" onClick={() => setIsTecwinDialogOpen(true)}>
                    Consultar Tarifas Aduaneiras
                  </QCXButton>
                </Box>

                <AuxiliarTributosPopup
                  handleSubmit={handleSubmit}
                  open={isTecwinDialogOpen}
                  onClose={() => setIsTecwinDialogOpen(false)}
                  adicoes={adicoes}
                />
              </>
            )}
          </>
        )}
      </QCXFormStepWizard>
      <QCXConfirmDialog
        key="confirm-dialog-remove-adicao"
        id="confirm-dialog-remove-adicao"
        open={operationOfRemoveById?.active}
        title={operationOfRemoveById?.title}
        content={operationOfRemoveById?.message}
        endContent={operationOfRemoveById?.endMessage}
        onConfirm={operationOfRemoveById.confirm}
        onClose={operationOfRemoveById?.reset}
      />
      <QCXFinalFormDialog
        key="confirm-dialog-gerar-li-by-adicao"
        id="confirm-dialog-gerar-li-by-adicao"
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.continuar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        initialValues={gerarLiInitialValues}
        open={isFormDialogContinueToTransferOpen}
        title={t('com.muralis.qcx.acoes.gerarLI')}
        content={t('com.muralis.qcx.preencherCampoContinuar')}
        onSubmit={handleGerarLiByAdicao}
        onClose={handleCloseFormDialogGenerateLi}
      >
        {() => (
          <Grid item xs={12}>
            <QCXSelectServicoAutocomplete
              id="autocomplete-select-servico-field"
              key="autocomplete-select-servico-field"
              name="servico.id"
              label={t('com.muralis.qcx.servico.labelSingular')}
              initialValues={undefined}
              produto="LI"
              required
            />
          </Grid>
        )}
      </QCXFinalFormDialog>
    </>
  );
}
