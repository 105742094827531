/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuid, validate } from 'uuid';
import {
  BondManagerFormProps,
  BondManagerListProps,
} from '../../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerTypes';
import QCXFinalBondManager from '../../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManager';
import QCXFinalDatePickerField from '../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import { isCurrentOrFutureDate } from '../../../utils/validators/field/validator';
import QCXFinalTextField from '../../../shared-components/final-text-field/QCXFinalTextField';
import { formatDateToAAAAMMDD } from '../../../ts/pages/integracao/totvs-easy/escrita/totvsEscritaPage.helpers';
import { formatDate } from '../../../utils/general/general-utils';
import { convertLocalDateTimeStringToUTC } from '../../../utils/general/dateTime-utils';

type Props = {
  isParentConsult: boolean;
};

type Lote = {
  id: string;
  lote: string;
  quantidade: number;
  dataValidade?: Date;
  dataFabricacao?: Date;
};

export function normalizeLoteBond(loteBond: Lote) {
  const isLocalId = validate(loteBond?.id);

  const normalizedCSTBond = {
    ...loteBond,
    id: isLocalId ? null : loteBond?.id,
  };

  return normalizedCSTBond;
}

export function unnormalizeLoteBond(data: Lote): Lote {
  return {
    ...data,
    id: data?.id || uuid(),
    dataFabricacao: data?.dataFabricacao ? new Date(data?.dataFabricacao) : undefined,
    dataValidade: data?.dataValidade ? new Date(data?.dataValidade) : undefined,
  };
}

function QCXFinalLotesBondManager({ isParentConsult }: Props) {
  const { t } = useTranslation();
  const { values } = useFormState();

  const listProps: BondManagerListProps<Lote> = {
    name: 'adicionais.mercadoriaAdicionaisLote',
    columns: [
      {
        field: 'lote',
        headerName: t('com.muralis.qcx.lote'),
        headerAlign: 'left',
        align: 'left',
        flex: 90,
        valueGetter: ({ row }) => row?.lote || '-',
      },
      {
        field: 'quantidade',
        headerName: 'Quantidade',
        headerAlign: 'left',
        align: 'left',
        flex: 90,
        valueGetter: ({ row }) => String(row?.quantidade) || '-',
      },
      {
        field: 'dataFabricacao',
        headerName: 'Data de Fabricação',
        headerAlign: 'left',
        align: 'left',
        flex: 90,
        valueGetter: ({ row }) => formatDate(row?.dataFabricacao?.toISOString()) || '-',
      },
      {
        field: 'dataValidade',
        headerName: 'Data de Validade',
        headerAlign: 'left',
        align: 'left',
        flex: 90,
        valueGetter: ({ row }) => formatDate(row?.dataValidade?.toISOString()) || '-',
      },
    ],
  };

  const formProps: BondManagerFormProps = {
    rootName: 'ignorableFields',
    fields: [
      {
        name: 'lote',
        label: 'Lote',
        required: true,
      },
      {
        name: 'quantidade',
        label: 'Quantidade',
        required: false,
      },
      {
        name: 'dataValidade',
        label: 'Data de Validade',
        required: false,
      },
      {
        name: 'dataFabricacao',
        label: 'Data de Fabricação',
        required: false,
      },
    ],
  };

  const handleAddLote = useCallback(async (currentVinculo: any, currentVinculos: any, handleSuccessAddVinculo: any) => {
    const newVinculo = {
      ...currentVinculo,
      id: uuid(),
    };
    const updatedVinculos = [...currentVinculos, newVinculo];
    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const handleUpdateCST = useCallback(
    async (currentVinculo: any, currentVinculos: any, handleSuccessUpdateVinculo: any) => {
      const updatedVinculo = {
        ...currentVinculo,
      };
      const updatedVinculos =
        currentVinculos?.map((existingVinculo: any) =>
          existingVinculo?.id === updatedVinculo?.id ? updatedVinculo : existingVinculo
        ) || currentVinculos;
      handleSuccessUpdateVinculo(updatedVinculos);
    },
    []
  );

  // Claudemir disse que passaria a regra depois
  const handleCSTAlreadyExists = useCallback((currentVinculo: any, currentVinculosList: any) => false, []);

  return (
    <QCXFinalBondManager
      isParentConsult={isParentConsult}
      listProps={listProps}
      formProps={formProps}
      handleAdd={handleAddLote}
      handleUpdate={handleUpdateCST}
      handleAlreadyExists={handleCSTAlreadyExists}
    >
      <Grid item xs={12} sm={6} md={4}>
        <QCXFinalTextField
          id="text-field-lote"
          key="text-field-lote"
          name="ignorableFields.lote"
          label={t('com.muralis.qcx.lote')}
          disabled={isParentConsult}
          maxLength={10}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <QCXFinalTextField
          id="text-field-quantidade"
          key="text-field-quantidade"
          name="ignorableFields.quantidade"
          label="Quantidade"
          disabled={isParentConsult}
          maxLength={5}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <QCXFinalDatePickerField
          id="date-picker-data-validade-field"
          key="date-picker-data-validade-field"
          name="ignorableFields.dataValidade"
          label={t('com.muralis.qcx.dataValidade')}
          validate={!isParentConsult && isCurrentOrFutureDate}
          disabled={isParentConsult}
          required={false}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <QCXFinalDatePickerField
          id="date-picker-data-fabricacao-field"
          key="date-picker-data-fabricacao-field"
          name="ignorableFields.dataFabricacao"
          label="Data de Fabricação"
          validate={!isParentConsult && isCurrentOrFutureDate}
          disabled={isParentConsult}
          required={false}
        />
      </Grid>
    </QCXFinalBondManager>
  );
}

export default QCXFinalLotesBondManager;
