import { createAsyncThunk } from '@reduxjs/toolkit';
import saveAs from 'file-saver';
import { omit } from 'lodash';
import {
  fetchCriaDiagnostico,
  generateReportDiagnosticoDI,
  generateReportRegistroDI,
  fetchTransmissaoDuimp,
} from '../../ts/common/services/declaracaoImportacaoApi';
import { DECLARACAO_IMPORTACAO_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';
import { DEFAULT_TIPO_DI_DUIMP_PARAMETER } from '../../utils/general/fatura/faturaUtils';
import { finishLoading, startLoading } from '../application-state/applicationStateSlice';
import { faturaAPI } from '../fatura/faturaAPI';
import { setErrorFeedback, setSuccessFeedback } from '../feedback/feedbackSlice';
import { followUpAPI } from '../follow-up/followUpAPI';
import {
  calcularDisLote,
  gerarXMLDiagnosticoLote,
  releaseDisEmLote,
  retransmitirDisEmLote,
  liberarDisEmLote,
  declaracaoImportacaoAPI,
} from './declaracaoImportacaoAPI';

const fetchAllFromFaturaAsync = createAsyncThunk('declaracaoImportacao/fetchAllFromFatura', async () => {
  const { data } = await faturaAPI.fetchByFilter([DEFAULT_TIPO_DI_DUIMP_PARAMETER]);
  return { data };
});

const fetchAllDecImportWithPaginationAsync = createAsyncThunk(
  'declaracaoImportacao/fetchAllFromFaturaPaginated',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await faturaAPI.fetchByFilterPaginate([DEFAULT_TIPO_DI_DUIMP_PARAMETER, ...params]);
      return { data };
    } catch (error) {
      console.log('error', error);
      return rejectWithValue(error.response.data);
    }
  }
);

const generateReportDIDiagnosticoAsync = createAsyncThunk(
  'declaracaoImportacao/generateReportDIDiagnostics',
  async ({ id, processo }, { rejectWithValue, dispatch }) => {
    try {
      const response = await generateReportDiagnosticoDI(id);

      const blob = new Blob([response.data], { type: 'application/xml;charset=utf-8' });

      saveAs(blob, `DI - ${processo ?? ''} - Diagnóstico.xml`);

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const generateReportDIRegistroAsync = createAsyncThunk(
  'declaracaoImportacao/generateReportDIRegistro',
  async ({ id, processo }, { rejectWithValue, dispatch }) => {
    try {
      const response = await generateReportRegistroDI(id);

      const blob = new Blob([response.data], { type: 'application/xml;charset=utf-8' });

      saveAs(blob, `DI - ${processo ?? ''} - Registro.xml`);

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const fetchByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoImportacao/fetchByIdFromFatura',
  async (data, { dispatch, rejectWithValue }) => {
    let response;

    try {
      response = await faturaAPI.fetchById(data);
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }

    let followUpResponse;
    if (response.status === 200) {
      try {
        followUpResponse = await followUpAPI.fetchById(response?.data?.followUp?.id);
      } catch (error) {
        dispatch(setErrorFeedback({ message: error?.response?.data?.message }));
        return rejectWithValue(error?.response?.data);
      }
    }

    return {
      response: {
        status: response.status,
        data: { ...response.data, followUp: followUpResponse?.data ?? response.data.followUp },
      },
    };
  }
);

const fetchCapaDiDuimpByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoImportacao/fetchCapaDiDuimpByIdFromFaturaAsync',
  async (data, { dispatch, rejectWithValue }) => {
    let response;

    try {
      response = await faturaAPI.fetchCapaDiDuimpById(data);
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }

    let followUpResponse;
    if (response.status === 200) {
      try {
        followUpResponse = await followUpAPI.fetchById(response?.data?.followUp?.id);
      } catch (error) {
        dispatch(setErrorFeedback({ message: error?.response?.data?.message }));
        return rejectWithValue(error?.response?.data);
      }
    }

    return {
      response: {
        status: response.status,
        data: { ...response.data, followUp: followUpResponse?.data ?? response.data.followUp },
      },
    };
  }
);

const conferirByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoImportacao/conferirByIdFromFatura',
  async ({ id, onConflictCallback, ignoreAliquotas }, { rejectWithValue }) => {
    try {
      let response;

      if (ignoreAliquotas) {
        response = await faturaAPI.conferirIgnoreAliquotasBy(id);
      } else {
        response = await faturaAPI.conferirBy(id);
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      // não deve ser a melhor forma de fazer, mas foi o que consegui pensar no momento
      // se tiver conflito com as aliquotas, chama a callback definida no componente
      if (error?.response?.status === 409 && onConflictCallback) {
        onConflictCallback({
          args: { id },
          onOpenCallback: () => ({ options: { message: error?.response?.data?.message } }),
        });
      }

      return rejectWithValue(error?.response?.data);
    }
  }
);

const duplicarByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoImportacao/duplicarById',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await faturaAPI.duplicarBy(data?.id, [DECLARACAO_IMPORTACAO_ENDPOINT], data?.payload);

      if (response?.status === 200) {
        dispatch(setSuccessFeedback({ message: 'Duplicada com sucesso!' }));
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const liberarByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoImportacao/liberarByIdFromFatura',
  async (data, { rejectWithValue }) => {
    try {
      const response = await faturaAPI.liberarBy(data?.id);
      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const retransmitirByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoImportacao/retransmitirByIdFromFatura',
  async (data, { rejectWithValue }) => {
    try {
      const response = await faturaAPI.retransmitirBy(data?.id);
      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const activateByIdFromFaturaAsync = createAsyncThunk('declaracaoImportacao/activateByIdFromFatura', async (id) => {
  const { status, data } = await faturaAPI.activateById(id);
  return { response: { status, data } };
});

const inactivateByIdFromFaturaAsync = createAsyncThunk('declaracaoImportacao/inactivateByIdFromFatura', async (id) => {
  const { status, data } = await faturaAPI.inactivateById(id);
  return { response: { status, data } };
});

const reabrirByIdAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/reabrirById',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());

      const response = await faturaAPI.reabrirById(data?.id);

      if (response?.status === 200) {
        dispatch(finishLoading());
        dispatch(fetchAllFromFaturaAsync());
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const diagnosticoDiByIdAsync = createAsyncThunk(
  'declaracaoImportacao/diagnosticoDiById',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchCriaDiagnostico(data.declaracaoImportacao.id, data.declaracaoImportacao.tipo);

      if (response?.status === 200) {
        dispatch(setSuccessFeedback({ message: response?.data }));
      }

      if (response?.status === 500 || response?.status === 404 || response?.status === 401) {
        const message = response?.data ? response?.data : 'Erro não esperado!';

        dispatch(setErrorFeedback({ message }));
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data }));

      return rejectWithValue(error?.response?.data);
    }
  }
);

const transmissaoDuimpByIdAsync = createAsyncThunk(
  'declaracaoImportacao/transmissaoDuimpById',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchTransmissaoDuimp(
        data.declaracaoImportacao.id,
        data.declaracaoImportacao.tipo,
        data.declaracaoImportacao.transmitirComErros
      );

      if (response?.status === 200) {
        dispatch(setSuccessFeedback({ message: response?.data }));
      }

      if (response?.status === 500 || response?.status === 404 || response?.status === 401) {
        const message = response?.data ? response?.data : 'Erro não esperado!';

        dispatch(setErrorFeedback({ message }));
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data }));

      return rejectWithValue(error?.response?.data);
    }
  }
);

const calcularDisLoteAsync = createAsyncThunk(
  'declaracaoImportacao/calcularDisLote',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data, successCallback, errorCallback } = payload;

      const response = await calcularDisLote(data);

      if (response?.status === 200) {
        const { success = [], errors = [] } = response.data; // Default to empty arrays if undefined
        let combinedMessage = '';

        if (success.length) {
          combinedMessage += '                  SUCESSOS: \n';
          for (let i = 0; i < success.length; i += 1) {
            combinedMessage += `${success[i]}\n`;
          }
        }
        if (errors.length) {
          combinedMessage += '                   ERROS: \n';
          for (let i = 0; i < errors.length; i += 1) {
            combinedMessage += `${errors[i]}\n`;
          }
        }
        if (errors.length > 0) {
          dispatch(setErrorFeedback({ message: combinedMessage }));
          errorCallback();
        } else {
          dispatch(setSuccessFeedback({ message: combinedMessage }));
          successCallback();
        }
      } else {
        errorCallback();
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data?.message || 'An unknown error occurred' }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const releaseDisEmLoteAsync = createAsyncThunk(
  'declaracaoImportacao/releaseDisEmLote',
  async ({ data, successCallback }, { rejectWithValue, dispatch }) => {
    try {
      const response = await releaseDisEmLote(data);

      if (response?.status === 200) {
        dispatch(setSuccessFeedback({ message: 'Conferido com sucesso!' }));

        if (typeof successCallback === 'function') {
          successCallback();
        }
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const retransmitirDisEmLoteAsync = createAsyncThunk(
  'declaracaoImportacao/retransmitirDisEmLote',
  async ({ data, successCallback }, { rejectWithValue, dispatch }) => {
    try {
      const response = await retransmitirDisEmLote(data);

      if (response?.status === 200) {
        dispatch(setSuccessFeedback({ message: 'Retransmitido com sucesso!' }));

        if (typeof successCallback === 'function') {
          successCallback();
        }
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data.message }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const liberarDisEmLoteAsync = createAsyncThunk(
  'declaracaoImportacao/liberarDisEmLote',
  async ({ data, successCallback }, { rejectWithValue, dispatch }) => {
    try {
      const response = await liberarDisEmLote(data);

      if (response?.status === 200) {
        dispatch(setSuccessFeedback({ message: 'Liberado com sucesso!' }));

        if (typeof successCallback === 'function') {
          successCallback();
        }
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback('Erro ao liberar!'));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const gerarXMLDIagnosticoLoteAsync = createAsyncThunk(
  'declaracaoImportacao/gerarXMLDiagnosticoLote',
  async ({ data, successCallback }, { rejectWithValue, dispatch }) => {
    try {
      const { payload, tipo } = data;

      const response = await gerarXMLDiagnosticoLote(payload, tipo);

      if (response?.status === 200) {
        dispatch(setSuccessFeedback({ message: 'XMLs gerados com sucesso! Aguarde o download do arquivo' }));

        if (typeof successCallback === 'function') {
          successCallback();
        }
      }
      saveAs(new Blob([response.data], { type: 'application/xml;charset=utf-8' }), `relatorios-${tipo}.xml`);

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const fetchMercadoriasByFaturaIdAsync = createAsyncThunk(
  'declaracaoImportacao/fetchMercadoriasByFaturaIdAsync',
  async (id, { dispatch, rejectWithValue }) => {
    let response;

    try {
      response = await faturaAPI.fetchMercadoriasByFaturaId(id);
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }

    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const fetchAdicoesByDiDuimpIdAsync = createAsyncThunk(
  'declaracaoImportacao/fetchAdicoesByDiDuimpIdAsync',
  async (id, { dispatch, rejectWithValue }) => {
    let response;

    try {
      response = await declaracaoImportacaoAPI.fetchAdicoesByDiDuimpId(id);
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data?.message }));
      return rejectWithValue(error?.response?.data);
    }

    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const declaracaoImportacaoThunks = {
  fetchAllFromFaturaAsync,
  fetchByIdFromFaturaAsync,
  conferirByIdFromFaturaAsync,
  duplicarByIdFromFaturaAsync,
  activateByIdFromFaturaAsync,
  inactivateByIdFromFaturaAsync,
  liberarByIdFromFaturaAsync,
  retransmitirByIdFromFaturaAsync,
  reabrirByIdAsync,
  diagnosticoDiByIdAsync,
  transmissaoDuimpByIdAsync,
  generateReportDIDiagnosticoAsync,
  generateReportDIRegistroAsync,
  fetchAllDecImportWithPaginationAsync,
  gerarXMLDIagnosticoLoteAsync,
  calcularDisLoteAsync,
  releaseDisEmLoteAsync,
  retransmitirDisEmLoteAsync,
  liberarDisEmLoteAsync,
  fetchMercadoriasByFaturaIdAsync,
  fetchAdicoesByDiDuimpIdAsync,
  fetchCapaDiDuimpByIdFromFaturaAsync,
};

export {
  activateByIdFromFaturaAsync,
  conferirByIdFromFaturaAsync,
  declaracaoImportacaoThunks,
  diagnosticoDiByIdAsync,
  transmissaoDuimpByIdAsync,
  duplicarByIdFromFaturaAsync,
  fetchAllFromFaturaAsync,
  fetchByIdFromFaturaAsync,
  generateReportDIDiagnosticoAsync,
  inactivateByIdFromFaturaAsync,
  liberarByIdFromFaturaAsync,
  reabrirByIdAsync,
  retransmitirByIdFromFaturaAsync,
  generateReportDIRegistroAsync,
  fetchAllDecImportWithPaginationAsync,
  gerarXMLDIagnosticoLoteAsync,
  calcularDisLoteAsync,
  releaseDisEmLoteAsync,
  retransmitirDisEmLoteAsync,
  liberarDisEmLoteAsync,
  fetchMercadoriasByFaturaIdAsync,
  fetchAdicoesByDiDuimpIdAsync,
  fetchCapaDiDuimpByIdFromFaturaAsync,
};
