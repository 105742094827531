/* eslint-disable max-len */ // TODO: TIRAR ISSO ANTES DO COMMIT
import { Grid } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import i18n from '../../i18n';

import {
  fetchByFilterAtributoAsync,
  selectAtributosNcm,
} from '../../features/ncm-exportacao/ncmAtributoExportacaoSlice';

import { isStrictEquals } from '../../utils/general/general-utils';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';

import QCXFinalBondManager from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManager.tsx';
import QCXFormListenerManager from '../../shared-components/form-listener/QCXFormListenerManager';
import QCXSelectNcmAtributoAutocomplete from '../../shared-components/select-ncm-atributo-exportacao/QCXSelectNcmAtributoAutocomplete';
import QCXSelectNcmDominioBooleanAutocomplete from '../../shared-components/select-ncm-atributo-exportacao/QCXSelectNcmDominioBooleanAutocomplete';
import QCXSelectNcmDominioListaEstaticaExportacao from '../../shared-components/select-ncm-atributo-exportacao/QCXSelectNcmDominioListaEstaticaExportacao';
import QCXTextFieldDominioNcmExportacao from '../../shared-components/text-field-dominio-ncm-exportacao/QCXTextFieldDominioNcmExportacao';

export default function QCXFinalAtributoNcmMercadoriaGroup({
  isParentConsult,
  isSubConsult,
  disabled,
  shouldAllwaysDisplay = false,
  title = i18n.t('com.muralis.qcx.NCM.atributoNCM'),
}) {
  const { values } = useFormState();

  const form = useForm();

  const dispatch = useDispatch();

  const atributoNcmInput = 'ignorableFields.ncm.atributo.id';

  const ncmSHInput = 'ncm.id';

  const atributoNcmTableElement = 'due.ncmExportacao';

  const [tipoPreenchimento, setTipoPreenchimento] = useState();

  const [dadosAtributo, setDadosAtributo] = useState({});

  const listaAtributoNcm = useSelector(selectAtributosNcm);

  const codigoNcm = values?.ncm?.id;

  useEffect(() => {
    if (!codigoNcm) {
      return;
    }

    dispatch(
      fetchByFilterAtributoAsync([
        {
          name: 'ncm',
          value: codigoNcm,
        },
      ])
    );
  }, [codigoNcm]);

  const ncmListProps = useMemo(
    () => ({
      name: atributoNcmTableElement,
      columns: [
        {
          field: 'ncm.codigo',
          headerName: i18n.t('com.muralis.qcx.codigo'),
          headerAlign: 'left',
          align: 'left',
          flex: 100,
          valueGetter: ({ row }) => row?.atributo?.codigo || '-',
        },
        {
          field: 'ncm.nomeApresentacao',
          headerName: i18n.t('com.muralis.qcx.nomeApresentacao'),
          headerAlign: 'left',
          align: 'left',
          flex: 100,
          valueGetter: ({ row }) => row?.atributo?.nomeApresentacao || '-',
        },
        {
          field: 'ncm.descricao',
          headerName: i18n.t('com.muralis.qcx.descricao'),
          headerAlign: 'left',
          align: 'left',
          flex: 100,
          valueGetter: ({ row }) => row?.itemDominio?.descricao || row?.valorPreenchimento || '-',
        },
      ],
    }),
    []
  );

  const ncmFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.ncm',
      fields: [
        {
          name: 'atributo.id',
          label: i18n.t('com.muralis.qcx.nomeApresentacao'),
          required: true,
        },
        {
          name: 'itemDominio.id',
          label: i18n.t('com.muralis.qcx.descricao'),
          required: false,
        },
        {
          name: 'valorPreenchimento',
          label: dadosAtributo?.nomeApresentacao,
          required: false,
        },
      ],
    }),
    [dadosAtributo]
  );

  const handleAddAtributoNcm = useCallback(
    async (currentAtributo, currentAtributos, handleSuccessAddAtributoNcm) => {
      const findAtributo = listaAtributoNcm?.find((item) => item?.id === currentAtributo?.atributo?.id);
      const findDominio = findAtributo?.dominio?.find((item) => item?.id === currentAtributo?.itemDominio?.id);

      const atributo = {
        id: uuid(),
        atributo: findAtributo,
        itemDominio: findDominio,
        valorPreenchimento: currentAtributo?.valorPreenchimento,
      };

      const updatedVinculos = [...currentAtributos, atributo];

      handleSuccessAddAtributoNcm(updatedVinculos);
    },
    [listaAtributoNcm]
  );

  const handleUpdateAtributosNcm = useCallback(
    async (currentAtributo, currentAtributos, handleSuccessUpdateAtributoNcm) => {
      const findAtributo = listaAtributoNcm?.find((item) => item?.id === currentAtributo?.atributo?.id);
      const findDominio = findAtributo?.dominio?.find((item) => item?.id === currentAtributo?.itemDominio?.id);

      const updatedVinculo = {
        ...currentAtributo,
        atributo: findAtributo,
        itemDominio: findDominio,
        valorPreenchimento: currentAtributo?.valorPreenchimento,
      };

      const updatedAtributos =
        currentAtributos?.map((existingVinculo) =>
          isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
        ) || currentAtributos;

      handleSuccessUpdateAtributoNcm(updatedAtributos);
    },
    [listaAtributoNcm]
  );

  const handleAtributoNcmAlreadyExists = useCallback(
    (currentAtributo, currentAtributosList) =>
      currentAtributosList.some((ncmRow) => {
        const itemDominioIsEqual = isStrictEquals(ncmRow?.itemDominio?.id, currentAtributo?.itemDominio?.id);
        const atributoIsEqual = isStrictEquals(ncmRow?.atributo?.id, currentAtributo?.atributo?.id);
        const idIsEqual = isStrictEquals(ncmRow?.id, currentAtributo?.id);
        if (idIsEqual) {
          return false;
        }
        if (currentAtributo?.itemDominio?.id) {
          return atributoIsEqual && itemDominioIsEqual;
        }
        return atributoIsEqual;
      }),
    []
  );

  // const onNcmShChange = useCallback(
  //   () => () => {
  //     form.change(ncmSHInput, undefined);
  //     form.change(atributoNcmTableElement, []);
  //   },
  //   [form, ncmSHInput, atributoNcmTableElement]
  // );

  const onFormaPreenchimentoAtributoNcmDueChange = useCallback(
    () => (value) => {
      if (!isSubConsult) {
        form.change('ignorableFields.ncm.valorPreenchimento', '');
        form.change('ignorableFields.ncm.itemDominio.id', undefined);
      }
      const findAtributo = listaAtributoNcm.find((item) => item.id === value);
      setDadosAtributo(findAtributo);

      const formaPreenchimento = listaAtributoNcm
        .filter((item) => item.id === value)
        .map((item) => item.formaPreenchimento)
        .toString();

      setTipoPreenchimento(formaPreenchimento);
    },
    [form, listaAtributoNcm]
  );

  const onChangeListeners = useMemo(
    () => [
      {
        name: atributoNcmInput,
        fn: onFormaPreenchimentoAtributoNcmDueChange,
      },
      // {
      //   name: ncmSHInput,
      //   fn: onNcmShChange,
      // },
    ],
    [atributoNcmInput, onFormaPreenchimentoAtributoNcmDueChange, ncmSHInput]
  );

  function getFormaPreenchimentoComponent(key, loading) {
    switch (key) {
      case 'BOOLEANO':
        return (
          <QCXSelectNcmDominioBooleanAutocomplete
            key="select-ncm-dominio-booleano-exportacao"
            id="select-ncm-dominio-booleano-exportacao"
            name="ignorableFields.ncm.valorPreenchimento"
            disabled={disabled || loading}
            label={dadosAtributo?.nomeApresentacao}
          />
        );
      case 'TEXTO':
        return (
          <QCXTextFieldDominioNcmExportacao
            key="select-ncm-dominio-texto-exportacao"
            id="select-ncm-dominio-texto-exportacao"
            name="ignorableFields.ncm.valorPreenchimento"
            disabled={disabled || loading}
            maxLength={dadosAtributo?.tamanhoMaximo}
            label={dadosAtributo?.nomeApresentacao}
            helperText={dadosAtributo?.orientacaoPreenchimento}
          />
        );
      case 'LISTA_ESTATICA':
      default:
        return (
          <QCXSelectNcmDominioListaEstaticaExportacao
            key="select-ncm-dominio-lista-estatica-exportacao"
            id="select-ncm-dominio-lista-estatica-exportacao"
            name="ignorableFields.ncm.itemDominio.id"
            label={i18n.t('com.muralis.qcx.descricao')}
            list={dadosAtributo?.dominio}
            initialValues={values}
            disabled={disabled || loading}
          />
        );
    }
  }

  return (
    (shouldAllwaysDisplay || listaAtributoNcm.length > 0) && (
      <>
        <QCXFormListenerManager form={form} values={values} onChangeListeners={onChangeListeners} />
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <QCXFormSubtitle
            title={title}
            boxProps={{
              pt: 1,
              pb: 1,
            }}
          />
        </Grid>
        <QCXFinalBondManager
          isParentConsult={isParentConsult}
          listProps={ncmListProps}
          formProps={ncmFormProps}
          handleAdd={handleAddAtributoNcm}
          handleUpdate={handleUpdateAtributosNcm}
          handleAlreadyExists={handleAtributoNcmAlreadyExists}
        >
          {({ loading }) => (
            <>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <QCXSelectNcmAtributoAutocomplete
                  key="select-ncm-atributo-exportacao"
                  id="select-ncm-atributo-exportacao"
                  name="ignorableFields.ncm.atributo.id"
                  disabled={disabled || loading || codigoNcm === undefined}
                  initialValues={values}
                  list={listaAtributoNcm}
                />
              </Grid>

              {tipoPreenchimento && (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  {tipoPreenchimento && getFormaPreenchimentoComponent(tipoPreenchimento, loading)}
                </Grid>
              )}
            </>
          )}
        </QCXFinalBondManager>
      </>
    )
  );
}
