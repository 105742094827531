import { Grid, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { validateLatitudeLongitude, validCpfOrCnpj } from '../../utils/validators/field/validator';
import { formatCpfOrCnpj, formatLatitudeLongitude } from '../../utils/hooks/form/field/formatters';
import { parseCpfOrCnpj, parseLatitudelongitude } from '../../utils/hooks/form/field/parsers';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import useValidators from '../../utils/hooks/useValidators';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
  checkboxGridField: {
    paddingBottom: '30px',
  },
}));

export default function QCXOperacaoForaDoRecintoFormGroup({
  modes,
  fieldProps = {
    cpfCnpjResponsavelLocalDespacho: {
      id: 'masked-text-cpf-cnpj-responsavel-local-despacho-field',
      key: 'masked-text-cpf-cnpj-responsavel-local-despacho-field',
      name: 'cpfCnpjResponsavelLocalDespacho',
      label: i18n.t('com.muralis.qcx.CPFCNPJResponsavelLocalDespacho'),
    },
    latitude: {
      id: 'text-field-latitude',
      key: 'text-field-latitude',
      name: 'latitude',
    },
    longitude: {
      id: 'text-field-longitude',
      key: 'text-field-longitude',
      name: 'longitude',
    },
    endereco: {
      id: 'text-field-endereco',
      key: 'text-field-endereco',
      name: 'endereco',
    },
    despachoDomiciliar: {
      id: 'checkbox-despacho-domiciliar',
      key: 'checkbox-despacho-domiciliar',
      name: 'despachoDomiciliar',
    },
  },
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  const { isConsult } = modes;

  const validateCpfCnpj = useMemo(() => useValidators([validCpfOrCnpj]), []);

  return (
    <Grid container spacing={2} className={classes.gridFields}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <QCXFinalOnblurMaskedField
          {...fieldProps.cpfCnpjResponsavelLocalDespacho}
          format={formatCpfOrCnpj}
          parse={parseCpfOrCnpj}
          validate={validateCpfCnpj}
          disabled={isConsult}
        />
      </Grid>
      <Grid item className={classes.gridFields} xs={12} sm={12} md={6} lg={6}>
        <QCXFinalOnblurMaskedField
          {...fieldProps.latitude}
          label={t('com.muralis.qcx.coordenadas.latitude')}
          format={formatLatitudeLongitude}
          parse={parseLatitudelongitude}
          maxLength={10}
          validate={validateLatitudeLongitude}
          helperText={t('com.muralis.qcx.validacao.formatoLatitudeLongitude')}
          disabled={isConsult}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <QCXFinalOnblurMaskedField
          {...fieldProps.longitude}
          label={t('com.muralis.qcx.coordenadas.longitude')}
          format={formatLatitudeLongitude}
          parse={parseLatitudelongitude}
          maxLength={10}
          validate={validateLatitudeLongitude}
          helperText={t('com.muralis.qcx.validacao.formatoLatitudeLongitude')}
          disabled={isConsult}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <QCXFinalTextField {...fieldProps.endereco} label={t('com.muralis.qcx.endereco.label')} disabled={isConsult} />
      </Grid>
      <Grid item className={classes.checkboxGridField} xs={12} sm={12} md={12} lg={12}>
        <QCXFinalCheckboxField
          {...fieldProps.despachoDomiciliar}
          label={t('com.muralis.qcx.despachoDomiciliar')}
          disabled={isConsult}
        />
      </Grid>
    </Grid>
  );
}
