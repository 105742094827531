import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import QCXUsuarioForm from '../../../../../components/usuario/QCXUsuarioForm';
import { register, save } from '../../../../../features/usuario/usuarioAPI';
import {
  addToList,
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetModel,
  selectBackgroundMode,
  selectMode,
  selectStatus,
  selectUsuario,
  setError,
  setResponse,
  success,
  updateOnList,
} from '../../../../../features/usuario/usuarioSlice';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import {
  isBackgroundCreateMode,
  isConsultMode,
  isCreateMode,
  isNoneMode,
  isPreparingActionStatus,
  isUpdateMode,
} from '../../../../../utils/store/store-utils';
import { setErrorFeedback } from '../../../../../features/feedback/feedbackSlice';

export default function UsuarioRegistrationPage({ authInfo }) {
  const { id } = useParams();

  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const usuario = useSelector(selectUsuario);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);

  const handleChangeToPreparingAction = () => {
    dispatch(preparingAction());
  };

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToBackgroundCreate = () => {
    dispatch(changeToBackgroundCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  const handleResetBackgroundMode = () => {
    dispatch(resetBackgroundMode());
  };

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const handleCancelUpdate = () => {
    const currentId = isBackgroundCreate ? usuario?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
    handleChangeToConsult();
  };

  const normalize = useCallback((data) => data, []);

  const unnormalize = useCallback((data) => data, []);

  const create = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await register(data);
        if (response?.status === 201) {
          dispatch(resetModel());

          const handleResultWithDebounce = debounce(() => {
            history.push(t('com.muralis.qcx.url.moduloGeraisUsuario'));

            dispatch(success());

            const created = response?.data;

            dispatch(
              setResponse({
                status: response.status,
                data: created,
                message: t('com.muralis.qcx.mensagem.usuarioCadastrado', { nome: created?.nome }),
              })
            );

            dispatch(addToList({ data: created }));
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        const errorMessage =
          data?.nome && response?.data?.message
            ? t('com.muralis.qcx.erro.erroCadastroUsuarioEspecifico', {
                nome: data?.nome,
                mensagem: response?.data?.message,
              })
            : t('com.muralis.qcx.erro.erroCadastroUsuario');

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(
              setResponse({
                status: response.status,
                data: saved,
                message: t('com.muralis.qcx.mensagem.usuarioAlteracao', { nome: saved?.nome }),
              })
            );

            dispatch(updateOnList({ data: saved }));
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        const errorMessage =
          data?.nome && response?.data?.message
            ? t('com.muralis.qcx.erro.erroSalvarAlteracaoUsuarioEspecifico', {
                nome: data?.nome,
                mensagem: response?.data?.message,
              })
            : t('com.muralis.qcx.erro.erroSalvarAlteracaoUsuario');

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data) => {
    const normalizedData = normalize(data);

    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData);
    } else {
      await create(normalizedData);
    }
  };

  const model = useMemo(() => (!isCreate ? unnormalize(usuario) : {}), [isCreate, usuario, unnormalize]);

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.cadastros'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
          name: t('com.muralis.qcx.gerais'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloGeraisUsuario'),
          name: t('com.muralis.qcx.usuarios'),
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [actionName]
  );

  const pageTitle = useMemo(
    () =>
      isNone || isCreate || isBackgroundCreate
        ? t('com.muralis.qcx.usuario.novoUsuario')
        : t('com.muralis.qcx.usuario.usuarioIdentificacao', { identificacao: usuario?.nome || usuario?.id || '-' }),
    [isNone, isCreate, isBackgroundCreate, usuario]
  );

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isPreparingAction={isPreparingAction}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXUsuarioForm
          initialValues={model}
          authInfo={authInfo}
          requiredRoles={['usuario']}
          handleSubmit={handleSubmit}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
