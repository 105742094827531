import axios from 'axios';

const baseUrl = process.env.REACT_APP_QCX_API_BASE_URL! + '/' + process.env.REACT_APP_RELATORIOS_API_DEVOLUCAO_SALDO!;

const downloadSheet = async (
  token: string,
  unidadeId: number,
  unidadesNegocioID: string[],
  clientesID: string[],
  dataInicio: string,
  dataFim: string
): Promise<void | { error: boolean; message: string }> => {
  const params = new URLSearchParams();
  params.set('tenantId', unidadesNegocioID.join(','));
  params.set('clientesId', clientesID.join(','));
  params.set('inicio', dataInicio);
  params.set('fim', dataFim);

  let message = null;

  try {
    const response = await axios.get(`${baseUrl}?${params.toString()}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        quickcomexTenant: unidadeId,
      },
      responseType: 'blob',
    });

    if (response.status === 200) {
      const file = new File([response.data], `relatorioDevolucaoSaldo.xlsx`, {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      a.click();
    } else if (response.status === 404) {
      return { error: true, message: 'Vazio empty rows' };
    } else {
      message = await response.data;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        return { error: true, message: 'Nenhum resultado para os filtros selecionados.' };
      }

      if (error.response) {
        return { error: true, message: await error.response.data.text() };
      }
    }

    return { error: true, message: 'Erro inesperado' };
  }
};

export { downloadSheet };
