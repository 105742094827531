import { Grid } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { get } from 'lodash';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import i18n from '../../i18n';
import QCXFinalBondManagerOld from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import { formatCnpj } from '../../utils/hooks/form/field/formatters';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFormListenerManager from '../../shared-components/form-listener/QCXFormListenerManager';
import {
  vinculoAtoConcessorioDUEActions,
  vinculoAtoConcessorioDUESelectors,
} from '../../features/vinculo-ato-concessorio-due/vinculoAtoConcessorioDUESlice';
import { fetchById as fetchTipoAtoConcessorioById } from '../../features/tipo-ato-concessorio/tipoAtoConcessorioAPI';
import useFormDialogSync from '../../utils/hooks/form/dialog/useFormDialogSync';
import QCXSelectTipoAtoConcessorioAutocomplete from '../../shared-components/select-tipo-ato-concessorio/QCXSelectTipoAtoConcessorioAutocomplete';
import TipoAtoConcessorioUtils from '../../shared-components/select-tipo-ato-concessorio/TipoAtoConcessorioUtils';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import { parseCnpj } from '../../utils/hooks/form/field/parsers';
import { validCnpj } from '../../utils/validators/field/validator';
import QCXAloneInlineBoxWrapper from '../../shared-components/alone-inline-box-wrapper/QCXAloneInlineBoxWrapper';
import QCXButton from '../../shared-components/button/QCXButton';
import { isStrictEquals, MatcherConfigureUtils } from '../../utils/general/general-utils';
import { selectTiposAtoConcessorio } from '../../features/tipo-ato-concessorio/tipoAtoConcessorioSlice';
import QCXDocumentoImportacaoDialog from './QCXDocumentoImportacaoDialog';
import QCXNotaFiscalDialog from './QCXNotaFiscalDialog';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import useExternallyFilling from '../../utils/hooks/form/field/useExternallyFilling';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import { selectClienteAtual } from '../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSlice';

export default function QCXAtosConcessoriosFormGroup({
  title = i18n.t('com.muralis.qcx.atoConcessorio.labelPlural'),
  isParentConsult,
  disabled,
}) {
  const { t } = useTranslation();

  const form = useForm();
  const { values } = useFormState();
  const cliente = useSelector(selectClienteAtual);

  const [isFillingAtoConessorioForm, subscribeToAtoConcessorioFillingForm] = useExternallyFilling({
    timeout: 2000,
    automaticallyReset: true,
  });

  const tiposAtoConcessorio = useSelector(selectTiposAtoConcessorio);

  const isSomeTipoAtoConcessorioBy = useCallback(
    (path, codesToCompare) => {
      const value = get(values, path);

      return MatcherConfigureUtils.getGeneralByCodes(tiposAtoConcessorio)(value, codesToCompare);
    },
    [values, tiposAtoConcessorio]
  );

  const exportadorBenecifiario = useCallback((path) => get(values, path), [values]);

  const onConsultDecoratorAtoConcessorio = useCallback(
    () => subscribeToAtoConcessorioFillingForm(),
    [subscribeToAtoConcessorioFillingForm]
  );

  const atoConcessorioListProps = useMemo(
    () => ({
      name: 'due.atosConcessorios',
      columns: [
        {
          field: 'numero',
          headerName: t('com.muralis.qcx.NumeroAto'),
          headerAlign: 'center',
          align: 'center',
          flex: 190,
          valueGetter: ({ row }) => row?.numero || '-',
        },
        {
          field: 'tipoAtoConcessorio',
          headerName: t('com.muralis.qcx.tipo'),
          headerAlign: 'center',
          align: 'center',
          flex: 150,
          valueGetter: ({ row }) => row?.tipoAtoConcessorio?.description || '-',
        },
        {
          field: 'cnpj',
          headerName: t('com.muralis.qcx.empresa.CNPJ'),
          headerAlign: 'center',
          align: 'center',
          flex: 200,
          valueFormatter: ({ row }) => formatCnpj(row?.cnpj) || '-',
        },
        {
          field: 'item',
          headerName: t('com.muralis.qcx.item.labelSingular'),
          headerAlign: 'center',
          align: 'center',
          flex: 130,
          valueGetter: ({ row }) => row?.item || '-',
        },
        {
          field: 'ncm',
          headerName: t('com.muralis.qcx.NCM.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 200,
          valueGetter: ({ row }) => row?.ncm || '-',
        },
        {
          field: 'quantidade',
          headerName: t('com.muralis.qcx.quantidade.label'),
          headerAlign: 'center',
          align: 'center',
          flex: 200,
          valueGetter: ({ row }) => row?.quantidade || '-',
        },
        {
          field: 'vmleComCobertura',
          headerName: t('com.muralis.qcx.moeda.VMLEComCoberturaCambial'),
          headerAlign: 'center',
          align: 'center',
          flex: 200,
          valueGetter: ({ row }) => row?.vmleComCobertura || '-',
        },
        {
          field: 'vmleSemCobertura',
          headerName: t('com.muralis.qcx.moeda.VMLESemCoberturaCambial'),
          headerAlign: 'center',
          align: 'center',
          flex: 200,
          valueGetter: ({ row }) => row?.vmleSemCobertura || '-',
        },
      ],
    }),
    []
  );

  const atoConcessorioFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.atoConcessorio',
      fields: [
        {
          name: 'tipoAtoConcessorio.id',
          label: t('com.muralis.qcx.atoConcessorio.tipoAC'),
        },
        {
          name: 'exportadorBenecifiario',
          label: t('com.muralis.qcx.atoConcessorio.exportadorBeneficiarioAC'),
          required: false,
        },
        {
          name: 'numero',
          label: t('com.muralis.qcx.atoConcessorio.numeroAC'),
          required: isSomeTipoAtoConcessorioBy('ignorableFields.atoConcessorio.tipoAtoConcessorio.id', [
            TipoAtoConcessorioUtils.INTERMEDIARIO,
            TipoAtoConcessorioUtils.INTERMEDIARIO_GENERICO,
          ]),
        },
        {
          name: 'cnpj',
          label: t('com.muralis.qcx.atoConcessorio.CNPJBeneficiarioAC'),
          required: isSomeTipoAtoConcessorioBy('ignorableFields.atoConcessorio.tipoAtoConcessorio.id', [
            TipoAtoConcessorioUtils.INTERMEDIARIO,
            TipoAtoConcessorioUtils.INTERMEDIARIO_GENERICO,
          ]),
        },
        {
          name: 'item',
          label: t('com.muralis.qcx.atoConcessorio.numeroItemAC'),
        },
        {
          name: 'ncm',
          label: t('com.muralis.qcx.atoConcessorio.ncmItemAC'),
        },
        {
          name: 'quantidade',
          label: isSomeTipoAtoConcessorioBy('ignorableFields.atoConcessorio.tipoAtoConcessorio.id', [
            TipoAtoConcessorioUtils.INTERMEDIARIO,
            TipoAtoConcessorioUtils.INTERMEDIARIO_GENERICO,
          ])
            ? t('com.muralis.qcx.atoConcessorio.quantidadeUtilizada')
            : t('com.muralis.qcx.atoConcessorio.quantidadeDevolver'),
          required: false,
        },
        {
          name: 'vmleComCobertura',
          label: t('com.muralis.qcx.moeda.VMLEComCoberturaCambial'),
          required: false,
        },
        {
          name: 'vmleSemCobertura',
          label: t('com.muralis.qcx.moeda.VMLESemCoberturaCambial'),
          required: false,
        },
      ],
    }),
    [isSomeTipoAtoConcessorioBy]
  );

  const atoConcessorioReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoAtoConcessorioDUESelectors.selectStatus,
        selectMode: vinculoAtoConcessorioDUESelectors.selectMode,
        selectModel: vinculoAtoConcessorioDUESelectors.selectModel,
      },
      actions: {
        loading: vinculoAtoConcessorioDUEActions.loading,
        resetStatus: vinculoAtoConcessorioDUEActions.resetStatus,
        changeToUpdateMode: vinculoAtoConcessorioDUEActions.changeToUpdateMode,
        changeToCreateMode: vinculoAtoConcessorioDUEActions.changeToCreateMode,
        resetMode: vinculoAtoConcessorioDUEActions.resetMode,
        setModel: vinculoAtoConcessorioDUEActions.setModel,
        resetModel: vinculoAtoConcessorioDUEActions.resetModel,
      },
    }),
    []
  );

  const handleAddAtoConcessorio = useCallback(async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    const tipoAtoConcessorioResponse = await fetchTipoAtoConcessorioById(currentVinculo?.tipoAtoConcessorio?.id);

    if (tipoAtoConcessorioResponse?.status === 200) {
      const foundTipoAtoConcessorio = tipoAtoConcessorioResponse?.data;

      const vinculo = {
        id: uuid(),
        tipoAtoConcessorio: foundTipoAtoConcessorio,
        exportadorBenecifiario: currentVinculo?.exportadorBenecifiario,
        numero: currentVinculo?.numero,
        cnpj: currentVinculo?.cnpj,
        item: currentVinculo?.item,
        ncm: currentVinculo?.ncm,
        quantidade: currentVinculo?.quantidade,
        vmleComCobertura: currentVinculo?.vmleComCobertura,
        vmleSemCobertura: currentVinculo?.vmleSemCobertura,
        documentosImportacao: currentVinculo?.documentosImportacao,
        notasFiscais: currentVinculo?.notasFiscais,
      };

      const updatedVinculos = [...currentVinculos, vinculo];

      handleSuccessAddVinculo(updatedVinculos);
    }
  }, []);

  const handleUpdateAtoConcessorio = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      const tipoAtoConcessorioResponse = await fetchTipoAtoConcessorioById(currentVinculo?.tipoAtoConcessorio?.id);

      if (tipoAtoConcessorioResponse?.status === 200) {
        const foundTipoAtoConcessorio = tipoAtoConcessorioResponse?.data;

        const updatedVinculo = {
          ...currentVinculo,
          tipoAtoConcessorio: foundTipoAtoConcessorio,
        };

        const updatedVinculos =
          currentVinculos?.map((existingVinculo) =>
            isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
          ) || currentVinculos;

        handleSuccessUpdateVinculo(updatedVinculos);
      }
    },
    []
  );

  const handleAtoConcessorioAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(vinculo?.tipoAtoConcessorio?.id, currentVinculo?.tipoAtoConcessorio?.id) &&
          isStrictEquals(vinculo?.numero, currentVinculo?.numero) &&
          isStrictEquals(vinculo?.cnpj, currentVinculo?.cnpj) &&
          isStrictEquals(vinculo?.item, currentVinculo?.item) &&
          isStrictEquals(vinculo?.ncm, currentVinculo?.ncm) &&
          isStrictEquals(vinculo?.quantidade, currentVinculo?.quantidade) &&
          isStrictEquals(vinculo?.vmleComCobertura, currentVinculo?.vmleComCobertura) &&
          isStrictEquals(vinculo?.vmleSemCobertura, currentVinculo?.vmleSemCobertura)
      ),
    []
  );

  const [handleOpenDocumentoImportacao, dialogDocumentoImportacaoStatus, handleConsultDocumentoImportacaoStatus] =
    useFormDialogSync();

  const [handleOpenInformarNotaFiscal, dialogInformarNotaFiscalStatus, handleConsultInformarNotaFiscalStatus] =
    useFormDialogSync();

  const onTipoAtoConcessorioChange = useCallback(
    () => (value) => {
      if (isFillingAtoConessorioForm) {
        return;
      }

      const isComum = TipoAtoConcessorioUtils.isComum(tiposAtoConcessorio)(value);

      if (isComum) {
        form.change('ignorableFields.atoConcessorio.exportadorBenecifiario', isComum);
        form.change('ignorableFields.atoConcessorio.numero', undefined);
        form.change('ignorableFields.atoConcessorio.cnpj', undefined);
        form.change('ignorableFields.atoConcessorio.quantidade', undefined);
        form.change('ignorableFields.atoConcessorio.documentosImportacao', []);
        return;
      }

      const isIntermediarioOuIntermediarioGenerico = MatcherConfigureUtils.getGeneralByCodes(tiposAtoConcessorio)(
        value,
        [TipoAtoConcessorioUtils.INTERMEDIARIO, TipoAtoConcessorioUtils.INTERMEDIARIO_GENERICO]
      );

      if (isIntermediarioOuIntermediarioGenerico) {
        form.change('ignorableFields.atoConcessorio.exportadorBenecifiario', !isIntermediarioOuIntermediarioGenerico);
        form.change('ignorableFields.atoConcessorio.quantidade', undefined);
        return;
      }

      form.change('ignorableFields.atoConcessorio.numero', undefined);
      form.change('ignorableFields.atoConcessorio.cnpj', undefined);
      form.change('ignorableFields.atoConcessorio.quantidade', undefined);
    },
    [form, tiposAtoConcessorio, isFillingAtoConessorioForm]
  );

  const atoConcessorioDecorator = useCallback((tools) => {
    const finalBondManagerForm = tools?.formUtils?.form;

    finalBondManagerForm.change('ignorableFields.atoConcessorio.exportadorBenecifiario', true);
    finalBondManagerForm.change('ignorableFields.atoConcessorio.quantidade', '');
    finalBondManagerForm.change('ignorableFields.atoConcessorio.vmleComCobertura', '');
    finalBondManagerForm.change('ignorableFields.atoConcessorio.vmleSemCobertura', '');
  }, []);

  const onExportadorBeneficiarioChange = useCallback(
    () => (value) => {
      if (value) {
        form.change('ignorableFields.atoConcessorio.documentosImportacao', []);
      }
    },
    [form]
  );

  const controlButtonsGridProps = useMemo(
    () => ({
      add: {
        xs: 12,
        sm: 3,
        md: 3,
      },
      update: {
        xs: 6,
        sm: 2,
        md: 2,
      },
      clear: {
        xs: 6,
        sm: 1,
        md: 1,
      },
    }),
    []
  );

  const onChangeListeners = useMemo(
    () => [
      {
        name: 'ignorableFields.atoConcessorio.tipoAtoConcessorio.id',
        fn: onTipoAtoConcessorioChange,
      },
      {
        name: 'ignorableFields.atoConcessorio.exportadorBenecifiario',
        fn: onExportadorBeneficiarioChange,
      },
    ],
    [onTipoAtoConcessorioChange, onExportadorBeneficiarioChange]
  );

  return (
    <>
      <QCXFormListenerManager form={form} values={values} onChangeListeners={onChangeListeners} />
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <QCXFormSubtitle
          title={title}
          boxProps={{
            pt: 1,
          }}
        />
      </Grid>
      <QCXFinalBondManagerOld
        isParentConsult={isParentConsult}
        listProps={atoConcessorioListProps}
        formProps={atoConcessorioFormProps}
        reducerConfig={atoConcessorioReducerConfig}
        handleAdd={handleAddAtoConcessorio}
        handleUpdate={handleUpdateAtoConcessorio}
        handleAlreadyExists={handleAtoConcessorioAlreadyExists}
        controlButtonsGridProps={controlButtonsGridProps}
        {...{
          onClearDecorator: atoConcessorioDecorator,
          onAddDecorator: atoConcessorioDecorator,
          onConsultDecorator: onConsultDecoratorAtoConcessorio,
        }}
      >
        {({ loading }) => (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXSelectTipoAtoConcessorioAutocomplete
                id="select-field-tipo-ato-concessorio"
                key="select-field-tipo-ato-concessorio"
                name="ignorableFields.atoConcessorio.tipoAtoConcessorio.id"
                label={t('com.muralis.qcx.atoConcessorio.tipoAC')}
                disabled={disabled || loading}
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalCheckboxField
                id="checkbox-exportador-beneficiario-ac"
                key="checkbox-exportador-beneficiario-ac"
                name="ignorableFields.atoConcessorio.exportadorBenecifiario"
                label={t('com.muralis.qcx.atoConcessorio.exportadorBeneficiarioAC')}
                disabled={
                  disabled ||
                  isSomeTipoAtoConcessorioBy('ignorableFields.atoConcessorio.tipoAtoConcessorio.id', [
                    TipoAtoConcessorioUtils.INTERMEDIARIO,
                    TipoAtoConcessorioUtils.INTERMEDIARIO_GENERICO,
                  ])
                }
              />
            </Grid>
            {isSomeTipoAtoConcessorioBy('ignorableFields.atoConcessorio.tipoAtoConcessorio.id', [
              TipoAtoConcessorioUtils.COMUM,
              TipoAtoConcessorioUtils.INTERMEDIARIO,
              TipoAtoConcessorioUtils.INTERMEDIARIO_GENERICO,
            ]) && (
              <>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <QCXFinalTextField
                    id="text-field-numero-ac"
                    key="text-field-numero-ac"
                    name="ignorableFields.atoConcessorio.numero"
                    label={t('com.muralis.qcx.atoConcessorio.numeroAC')}
                    disabled={disabled || loading}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <QCXFinalOnblurMaskedField
                    id="masked-text-field-pessoa-juridica-cnpj"
                    key="masked-text-field-pessoa-juridica-cnpj"
                    name="ignorableFields.atoConcessorio.cnpj"
                    label={t('com.muralis.qcx.atoConcessorio.CNPJBeneficiarioAC')}
                    defaultValue={cliente?.pessoa && formatCnpj(cliente.pessoa.cnpj)}
                    maxLength={18}
                    format={formatCnpj}
                    parse={parseCnpj}
                    validate={validCnpj}
                    disabled={disabled || loading}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                id="text-field-numero-item-ac"
                key="text-field-numero-item-ac"
                name="ignorableFields.atoConcessorio.item"
                label={t('com.muralis.qcx.atoConcessorio.numeroItemAC')}
                disabled={disabled || loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                id="text-field-ncm-item-item-ac"
                key="text-field-ncm-item-item-ac"
                name="ignorableFields.atoConcessorio.ncm"
                label={t('com.muralis.qcx.atoConcessorio.ncmItemAC')}
                disabled={disabled || loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalNumericDecimalField
                id="numeric-quantidade-ac"
                key="numeric-quantidade-ac"
                name="ignorableFields.atoConcessorio.quantidade"
                label={
                  isSomeTipoAtoConcessorioBy('ignorableFields.atoConcessorio.tipoAtoConcessorio.id', [
                    TipoAtoConcessorioUtils.INTERMEDIARIO,
                    TipoAtoConcessorioUtils.INTERMEDIARIO_GENERICO,
                  ])
                    ? t('com.muralis.qcx.atoConcessorio.quantidadeUtilizada')
                    : t('com.muralis.qcx.atoConcessorio.quantidadeDevolver')
                }
                scale={5}
                disabled={disabled || loading}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalAlternativeCurrencyField
                id="currency-vmle-com-combertura-cambial"
                key="currency-vmle-com-combertura-cambial"
                name="ignorableFields.atoConcessorio.vmleComCobertura"
                label={t('com.muralis.qcx.moeda.VMLEComCoberturaCambial')}
                disabled={disabled || loading}
              />
            </Grid>
            <QCXAloneInlineBoxWrapper>
              {(fieldProps) => (
                <QCXFinalAlternativeCurrencyField
                  id="currency-vmle-sem-combertura-cambial"
                  key="currency-vmle-sem-combertura-cambial"
                  name="ignorableFields.atoConcessorio.vmleSemCobertura"
                  label={t('com.muralis.qcx.moeda.VMLESemCoberturaCambial')}
                  disabled={disabled || loading}
                  {...fieldProps}
                />
              )}
            </QCXAloneInlineBoxWrapper>
            {!exportadorBenecifiario('ignorableFields.atoConcessorio.exportadorBenecifiario') && (
              <Grid item xs={12} sm={6} md={3}>
                <QCXButton
                  id="control-box-informar-di"
                  key="control-box-informar-di"
                  color="primary"
                  variant="outlined"
                  onClick={handleOpenDocumentoImportacao}
                  fullWidth
                  tooltip
                  tooltipDescription={t('com.muralis.qcx.informarDI')}
                  disabled={disabled || loading}
                >
                  {t('com.muralis.qcx.informarDI')}
                </QCXButton>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={3}>
              <QCXButton
                id="control-box-informar-nota-fiscal"
                key="control-box-informar-nota-fiscal"
                color="primary"
                variant="outlined"
                onClick={handleOpenInformarNotaFiscal}
                fullWidth
                tooltip
                tooltipDescription={t('com.muralis.qcx.informarNotaFiscal')}
                disabled={disabled || loading}
              >
                {t('com.muralis.qcx.informarNotaFiscal')}
              </QCXButton>
            </Grid>
          </>
        )}
      </QCXFinalBondManagerOld>
      <QCXDocumentoImportacaoDialog
        isParentConsult={isParentConsult}
        status={dialogDocumentoImportacaoStatus}
        handleStatus={handleConsultDocumentoImportacaoStatus}
      />
      <QCXNotaFiscalDialog
        isParentConsult={isParentConsult}
        status={dialogInformarNotaFiscalStatus}
        handleStatus={handleConsultInformarNotaFiscalStatus}
      />
    </>
  );
}
