import axios from "axios";

const baseUrl = process.env.REACT_APP_QCX_API_BASE_URL! + "/"
  + process.env.REACT_APP_QCX_API_EXPORTADOR_FABRICANTE!

export interface UploadResponse {
  ok: boolean;
  message?: string;
}

const uploadFile = async (file: File, unidadeId: number, token: string) => {

  let formData = new FormData();
  formData.append("file", file);

  const response = await axios.post(`${baseUrl}/planilha`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        quickcomexTenant: unidadeId,
      },
    }
  ).then(response => {
    return response;
  })
  .catch(error => {
    if (error.response) {
      return error.response
    }
  });

  return response;
}

export { uploadFile };
