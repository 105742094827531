import { doGetQCXRequest } from "../../../utils/api/domain/domain-api-utils";
import { TransmissaoLiData } from "../../pages/importacao/licencaImportacao/components/TransmissaoModal/rendarDadosLiTransmissao";

export interface fetchDataTransmissaoResponse {
    message: string | null;
    data?: TransmissaoLiData | null;
}

async function fetchDataTransmissao(id: number): Promise<fetchDataTransmissaoResponse> {
    const url: string =
        process.env.REACT_APP_QCX_API_LICENCA_IMPORTACAO_ENDPOINT!
        + '/resultado-transmissao'

    let data: TransmissaoLiData | null = null;
    let message: string | null = null;

    try {
        const response = await doGetQCXRequest([url, id]);
        data = response.data;

    } catch (error: unknown) {

        if (error instanceof Error &&
            (error.message.includes('404') || error.message.includes('500'))) {

            message = 'com.muralis.qcx.extracaoDiagnostico.erroDeclaracaoNaoAtendeAosRequisitos';
        } else if (error instanceof Error && error.message.includes('401')) {
            message = 'com.muralis.qcx.extracaoDiagnostico.erro401';
        } else {
            message = 'com.muralis.qcx.extracaoDiagnostico.erroInesperado';
        }
    }

    return {
        message: message,
        data: data
    }
}

export {fetchDataTransmissao};
