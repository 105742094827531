import { doGetQCXRequest } from '../../utils/api/domain/domain-api-utils';

async function fetchByDayAndMoedaId(day, moedaId) {
  return doGetQCXRequest(['taxaconversaoexportacao', 'diaAnterior', day], [{ name: 'moedaId', value: moedaId }]);
}
const taxaConversaoExportacaoAPI = {
  fetchByDayAndMoedaId,
};

export { taxaConversaoExportacaoAPI, fetchByDayAndMoedaId };
