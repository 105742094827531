import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../../shared-components/dados-embarque/base-components/BaseSelectWithOptions';

function SelectAmbiente({ ...props }) {
  const list = useMemo(
    () => [
      {
        id: 'PRODUCAO',
        label: 'PRODUÇÃO',
        value: 'PRODUCAO',
      },
      {
        id: 'HOMOLOGACAO',
        label: 'HOMOLOGAÇÃO',
        value: 'HOMOLOGACAO',
      },
    ],
    []
  );

  return <BaseSelectWithOptions list={list} {...props} />;
}

export default SelectAmbiente;
