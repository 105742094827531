import React, { useEffect, useState, useRef } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from 'mui-rff';
import { v4 as uuid } from 'uuid';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { isFunction } from 'lodash';

import QCXConfirmDialog from '../dialog/QCXConfirmDialog';

// https://stackoverflow.com/questions/59832331/why-checkbox-is-not-checked-in-react
// https://codesandbox.io/s/cranky-swirles-y9bcj?file=/src/test.js:845-853

export default function QCXAutocompleteSelect({ ...props }) {
  const { t } = useTranslation();
  const inputRef = useRef();

  const {
    options = [],
    name,
    label,
    textFieldProps = {
      variant: 'outlined',
      size: 'small',
      InputProps: {},
      InputLabelProps: {
        shrink: true,
        style: {
          textTransform: 'uppercase',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      },
    },
    placeholder = '',
    fieldProps = {},
    defaultValue = {},
    getOptionValue = (item) => item?.id || '',
    getOptionLabel = (item) => item?.description || '',
    initialValues,
    externallyFillable = false,
    renderOptionDecorator,
    disabled,
    autoComplete = true,
    autoHighlight = true,
    blurOnSelect = true,
    disableClearable = false,
    helperText,
    noOptionsText = t('com.muralis.qcx.validacao.semOpcoes'),
    onEventActionPaiFunction,
    openDropdown = false,
    emptyListMsg = '',
    ...restProps
  } = props;

  const [key, setKey] = useState(name);
  const [currentValue, setCurrentValue] = useState('');
  const [openAlert, setOpenAlert] = useState('');

  // aparentemente isso força o componente a recarregar, porem não é uma boa prática
  // supostamente existe um bug no Autocomplete onde ele não reseta o valor do input
  // https://stackoverflow.com/questions/59790956/material-ui-autocomplete-clear-value
  useEffect(() => {
    if (externallyFillable || defaultValue || disabled) {
      setKey(`${name.replaceAll('.', '-')}-${uuid()}`);
    }

    if (defaultValue === null) {
      setKey(`${name.replaceAll('.', '-')}-${uuid()}`);
    }
  }, [defaultValue, externallyFillable, disabled]);

  const handleRenderOption = (option, state) => {
    const { inputValue } = state;
    const optionLabel = String(getOptionLabel(option));

    const matches = match(optionLabel, inputValue);
    const parts = parse(optionLabel, matches);

    const highlightStyle = {
      fontWeight: 700,
    };

    const optionComponent = (
      <div>
        {parts.map((part, partIndex) => {
          const partKey = `label-part-${partIndex}`;
          const isHighlightedWord = part.highlight;

          if (isHighlightedWord) {
            return (
              <span key={partKey} style={highlightStyle}>
                {part.text}
              </span>
            );
          }

          const charactersOfPart = part.text.split('');

          return charactersOfPart.map((characterOfPart, characterOfPartIndex) => {
            const characterOfPartKey = `character-from-part-${characterOfPartIndex}`;

            const inputValueInLowerCase = String(inputValue).toLowerCase();
            const characterOfPartInLowerCase = String(characterOfPart).toLowerCase();

            const isCharacterOfPartMatches = inputValueInLowerCase.includes(characterOfPartInLowerCase);

            const characterStyle = isCharacterOfPartMatches ? highlightStyle : {};

            return (
              <span key={characterOfPartKey} style={characterStyle}>
                {characterOfPart}
              </span>
            );
          });
        })}
      </div>
    );

    if (isFunction(renderOptionDecorator)) {
      return renderOptionDecorator({
        option,
        state,
        component: optionComponent,
      });
    }

    return optionComponent;
  };

  const focusInputForm = () => {
    inputRef.current.focus(); // coloca o foco no campo de pesquisa
  };

  const handleSearch = () => {
    if (!isFunction(onEventActionPaiFunction)) {
      return;
    }

    if (!currentValue || (currentValue && String(currentValue).length < 4)) {
      setOpenAlert(true);
      return;
    }
    onEventActionPaiFunction(currentValue);
    setCurrentValue('');
    setTimeout(focusInputForm, 1000);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && currentValue) {
      /* 
          Dispara a função desejada e pesquisa apenas se tiver valores para serem pesquisados,
        caso nao tenha nenhum currentValue o enter vai ter sua funcao padrao (selecionar valores no dropdown)
      */
      event.preventDefault();
      handleSearch();
    }
  };

  return (
    <Grid container alignItems="center">
      <Grid xs={11}>
        <Autocomplete
          key={key}
          name={name}
          label={label}
          defaultValue={defaultValue}
          textFieldProps={{
            ...textFieldProps,
            ...fieldProps,
            ...(helperText ? { helperText } : {}),
            InputProps: {
              ...textFieldProps?.InputProps,
              inputRef,
              onKeyDown: handleKeyPress,
            },
          }}
          openOnFocus
          options={options}
          freeSolo
          placeholder={placeholder}
          onInputChange={(event, newValue) => setCurrentValue(newValue)}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          renderOption={handleRenderOption}
          noOptionsText={noOptionsText}
          autoComplete={autoComplete}
          autoHighlight={autoHighlight}
          blurOnSelect={blurOnSelect}
          disableClearable={disableClearable}
          fieldProps={fieldProps}
          disabled={disabled}
          {...restProps}
        />
      </Grid>

      <Grid xs={1}>
        <IconButton onClick={() => handleSearch()} aria-label="search" size="small">
          <SearchIcon />
        </IconButton>
      </Grid>

      <QCXConfirmDialog
        key="confirm-dialog-remove-item"
        id="confirm-dialog-remove-item"
        open={openAlert}
        title="Alerta"
        content="O campo deve terno mínimo 4 dígitos para realizar a busca !"
        endContent=""
        onConfirm={() => setOpenAlert(false)}
        onClose={() => setOpenAlert(false)}
      />

      <p style={{ color: 'red', fontSize: 11 }}>{emptyListMsg}</p>
    </Grid>
  );
}
