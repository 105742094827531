import _, { isArrayLikeObject, isEmpty, isFunction, isUndefined, omit } from 'lodash';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { v4 as uuid, validate } from 'uuid';
import QCXFollowUpFinalForm from '../../../../components/follow-up/QCXFollowUpFinalForm';
import { clienteActions } from '../../../../features/cliente/clienteSlice';
import { IDLE_CONTROL } from '../../../../features/config-control';
import { changeToBackgroundUpdateMode } from '../../../../features/declaracao-importacao/declaracaoImportacaoSlice';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import { followUpAPI } from '../../../../features/follow-up/followUpAPI';
import {
  selectBackgroundMode,
  selectFollowUp,
  selectMode,
  selectStatus,
} from '../../../../features/follow-up/followUpSelectors';
import {
  addToList,
  changeModeTo,
  changeToBackgroundCreateMode,
  changeToBackgroundDeleteMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetStatus,
  setModel,
  setResponse,
  success,
  updateOnList,
} from '../../../../features/follow-up/followUpSlice';
import { fetchByIdAsync } from '../../../../features/follow-up/followUpThunks';
import { servicoActions } from '../../../../features/servico/servicoSlice';
import { transportadoraActions } from '../../../../features/transportadora/transportadoraSlice';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import QCXRegistrationFormPageTemplate from '../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import { MediaType } from '../../../../utils/api/api-utils';
import AfrmmUtils from '../../../../utils/general/afrmm/AfrmmUtils';
import FollowUpUtils from '../../../../utils/general/follow-up/FollowUpUtils';
import { normalizeData, unnormalizeData } from '../../../../utils/general/general-utils';
import useAutoChangeMode from '../../../../utils/hooks/mode/useAutoChangeMode';
import useOperationConfirm from '../../../../utils/hooks/operation/confirm/useOperationConfirm';
import useOperationMiddleware from '../../../../utils/hooks/operation/middleware/useOperationMiddleware';
import {
  isBackgroundCreateMode,
  isBackgroundDeleteMode,
  isBackgroundUpdateMode,
  isConsultMode,
  isCreateMode,
  isFailureStatus,
  isIdleStatus,
  isLoadingStatus,
  isLockedMode,
  isNoneMode,
  isPreparingActionStatus,
  isUpdateMode,
} from '../../../../utils/store/store-utils';

export default function FollowUpRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const params = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const followUp = useSelector(selectFollowUp);

  const [warning, setWarning] = useState({
    flag: FollowUpUtils.NOOP_WARNING,
    message: undefined,
  });

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);

  const isLoading = useMemo(() => isLoadingStatus(status), [status]);

  const isIdle = useMemo(() => isIdleStatus(status), [status]);

  const isFailure = useMemo(() => isFailureStatus(status), [status]);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isLocked = useMemo(() => isLockedMode(mode), [mode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const isBackgroundUpdate = useMemo(() => isBackgroundUpdateMode(backgroundMode), [backgroundMode]);

  const isBackgroundDelete = useMemo(() => isBackgroundDeleteMode(backgroundMode), [backgroundMode]);

  const handleChangeToPreparingAction = useCallback(() => {
    dispatch(preparingAction());
  }, []);

  const handleChangeToCreate = useCallback(() => {
    dispatch(changeToCreateMode());
  }, []);

  const handleChangeToBackgroundCreate = useCallback(() => {
    dispatch(changeToBackgroundCreateMode());
  }, []);

  const handleChangeToBackgroundDelete = useCallback(() => {
    dispatch(changeToBackgroundDeleteMode());
  }, []);

  const handleChangeToConsult = useCallback(() => {
    dispatch(changeToConsultMode());
  }, []);

  const handleChangeToUpdate = useCallback((additional = {}) => {
    dispatch(changeToUpdateMode());

    if (!isEmpty(additional) && isFunction(additional?.callback)) {
      additional.callback();
    }
  }, []);

  const handleResetBackgroundMode = useCallback(() => {
    dispatch(resetBackgroundMode());
  }, []);

  const fetchById = useCallback((followUpId) => {
    dispatch(fetchByIdAsync(followUpId));
  }, []);

  const handleBasicCancelUpdate = useCallback(
    (additional = {}) => {
      const currentId = isBackgroundCreate ? followUp?.id : params?.id;

      if (currentId) {
        fetchById(currentId);
      }

      handleChangeToConsult();

      if (!isEmpty(additional) && isFunction(additional?.callback)) {
        additional.callback();
      }
    },
    [params, followUp, isBackgroundCreate, fetchById, handleChangeToConsult]
  );

  const operationOfCancelUpdateOptions = useMemo(
    () => ({
      title: t('com.muralis.qcx.aviso').toUpperCase(),
      message: t('com.muralis.qcx.mensagem.alteracoesRealizadasSeraoPerdidas'),
      endMessage: t('com.muralis.qcx.mensagem.certezaDesejaCancelar'),
    }),
    []
  );

  const [handleCancelUpdate, operationOfCancelUpdate] = useOperationConfirm(
    handleBasicCancelUpdate,
    operationOfCancelUpdateOptions,
    [handleBasicCancelUpdate, operationOfCancelUpdateOptions]
  );

  const normalize = useCallback(
    (unnormalizedData) => {
      const normalizedCorrigido = FollowUpUtils.normalizeCorrigidoFollowUp(unnormalizedData?.corrigido);

      const normalizedIgnorarErroFatura = FollowUpUtils.normalizeIgnorarErroFatura(unnormalizedData?.ignorarErroFatura);
      const normalizedArquivos = (unnormalizedData?.arquivos || [])?.map((arquivo) =>
        validate(arquivo?.id)
          ? {
              ...arquivo,
              id: null,
              dataFatura: normalizeData(arquivo?.dataFatura),
            }
          : {
              ...arquivo,
              dataFatura: normalizeData(arquivo?.dataFatura),
            }
      );

      return {
        ...omit(unnormalizedData, ['ignorableFields']),
        dataFatura: unnormalizeData(unnormalizedData?.arquivos?.dataFatura),
        corrigido: isCreate ? FollowUpUtils.CORRIGIDO : normalizedCorrigido,
        ignorarErroFatura: isCreate ? FollowUpUtils.IGNORAR_ERRO_FATURA : normalizedIgnorarErroFatura,
        taxaAfrmm: AfrmmUtils.parseFromSingletonList(unnormalizedData?.taxaAfrmm),
        referencias: (unnormalizedData?.referencias || []).map((referenciaCliente) => referenciaCliente?.referencia),
        documentos: (unnormalizedData?.documentos || []).map((documento) =>
          validate(documento?.id)
            ? {
                ...documento,
                id: undefined,
              }
            : documento
        ),
        numeroDeclaracaoImportacao: unnormalizedData?.numeroDeclaracaoImportacao,
        arquivos: normalizedArquivos,
        novosArquivos: [
          ...(_.isArrayLikeObject(unnormalizedData?.novosArquivos) ? unnormalizedData?.novosArquivos : []),
          ...(_.isArrayLikeObject(unnormalizedData?.arquivos) ? unnormalizedData?.arquivos : []),
        ]
          .filter((novoArquivo) => validate(novoArquivo?.id))
          .reduce(
            (previous, current) =>
              previous.some((item) => item?.id === current?.id) ? [...previous] : [...previous, current],
            []
          ),
      };
    },
    [isCreate]
  );

  const unnormalize = useCallback(
    (normalizedData) => ({
      ...normalizedData,
      followUpSequencial: {
        ...normalizedData?.followUpSequencial,
        ...(normalizedData?.followUpSequencia?.servico
          ? {
              ...normalizedData?.followUpSequencial,
              servico: { id: normalizedData?.followUpSequencial?.servico?.id },
            }
          : {}),
      },
      corrigido: FollowUpUtils.unnormalizeCorrigidoFollowUp(normalizedData?.corrigido),
      ignorarErroFatura: FollowUpUtils.unnormalizeIgnorarErroFatura(normalizedData?.ignorarErroFatura),
      taxaAfrmm: AfrmmUtils.parseToSingletonList(normalizedData?.taxaAfrmm),
      referencias: (normalizedData?.referencias || []).map((referencia) => ({
        id: uuid(),
        referencia,
      })),
      documentos: (normalizedData?.documentos || []).map((documento) =>
        isUndefined(documento?.id)
          ? {
              ...documento,
              id: uuid(),
            }
          : documento
      ),
      arquivos: isEmpty(normalizedData?.novosArquivos)
        ? (normalizedData?.arquivos || []).map((arquivo) =>
            isUndefined(arquivo?.id) || validate(arquivo?.id)
              ? {
                  ...arquivo,
                  id: uuid(),
                  fileName: arquivo[0]?.name,
                }
              : {
                  ...arquivo,
                  fileName: arquivo?.documento?.nome,
                }
          )
        : normalizedData?.novosArquivos,
      modeloFaturamento: {
        id: normalizedData?.modeloFaturamento?.id,
      },
      modeloFollowUp: {
        id: normalizedData?.modeloFollowUp?.id,
      },
      modeloNumerario: {
        id: normalizedData?.modeloNumerario?.id,
      },
      tabelaSda: {
        id: normalizedData?.tabelaSda?.id,
      },
      tipoDeclaracao: {
        id: normalizedData?.tipoDeclaracao?.id,
      },
      unidadesDeNegocio: normalizedData?.unidadesDeNegocio?.map((unidadeNegocio) => ({
        id: unidadeNegocio?.id,
      })),
      viaTransporte: {
        id: normalizedData?.viaTransporte?.id,
        code: normalizedData?.viaTransporte?.code,
      },
    }),
    []
  );

  const parseToFormData = useCallback((data) => {
    const { novosArquivos, xml, ...restData } = data;
    const novoArquivoMeta = {
      idioma: null,
      totalItens: null,
      number: null,
      date: null,
    };
    const novosArquivosMeta = [];

    const formData = new FormData();
    if(xml && xml?.length > 0)
      formData.append("xml", xml[0]);

    if (isArrayLikeObject(novosArquivos)) {
      novosArquivos.forEach((arquivo) => {
        if (arquivo?.fatura?.length > 0) {
          formData.append('files[]', arquivo?.fatura[0]);
          const meta = _.clone(novoArquivoMeta);
          meta.idioma = arquivo?.idioma || null;
          meta.totalItens = arquivo?.totalItens || null;
          meta.number = arquivo?.numeroFatura || null;
          meta.date = arquivo?.dataFatura || null;
          novosArquivosMeta.push(meta);
        }
      });
    }

    if (!isArrayLikeObject(restData?.arquivos)) {
      restData.arquivos = [];
    }

    const json = JSON.stringify(restData);
    const blob = new Blob([json], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append('data', blob);

    const meta = { metadata: novosArquivosMeta };
    const jsonMeta = JSON.stringify(meta);
    const blobMeta = new Blob([jsonMeta], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append('metadata', blobMeta);

    return formData;
  }, []);

  const handleDispatchSetModel = useCallback(
    (data) => {
      const normalizedData = normalize(data);

      dispatch(setModel(normalizedData));
    },
    [normalize]
  );

  const create = useCallback(
    async (data) => {
      const executeDebounced = debounce(async () => {
        try {
          const formData = parseToFormData(data);

          const response = await followUpAPI.register(formData);

          if (response?.status === 201) {
            const created = response?.data;

            history.push(t('com.muralis.qcx.url.importacaoFollowUpQuery', { numero: created?.numero }));

            dispatch(success());
            dispatch(addToList({ data: created }));
            dispatch(
              setSuccessFeedback({
                message: t('com.muralis.qcx.mensagem.followUpRegistrado', { numero: created?.numero }),
              })
            );
          }
        } catch (error) {
          dispatch(setModel(data));
          dispatch(changeToBackgroundCreateMode());

          const responseErrorMessage =
            error?.response && error?.response?.data && error?.response?.data?.message
              ? error?.response?.data?.message
              : undefined;

          if (FollowUpUtils.hasWarningFlag(responseErrorMessage)) {
            dispatch(resetStatus());

            setWarning({
              flag: FollowUpUtils.WARNING_ON_CREATING,
              message: FollowUpUtils.getMessageWithoutFlag(responseErrorMessage),
            });

            return;
          }

          if (!responseErrorMessage) {
            dispatch(failure());
            dispatch(
              setErrorFeedback({
                message: t('com.muralis.qcx.erro.erroRegistrarFollowUp'),
              })
            );

            return;
          }

          const fullErrorMessage = t('com.muralis.qcx.erro.erroRegistrarFollowUpEspecifico', {
            mensagem: responseErrorMessage,
          });

          dispatch(failure());
          dispatch(
            setErrorFeedback({
              message: fullErrorMessage,
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    },
    [history, parseToFormData]
  );

  const update = useCallback(
    async (data) => {
      const executeDebounced = debounce(async () => {
        try {
          const formData = parseToFormData(data);

          const response = await followUpAPI.save(formData);

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              handleChangeToConsult();
              dispatch(success());

              const saved = response?.data;

              dispatch(
                setSuccessFeedback({
                  message: t('com.muralis.qcx.mensagem.followUpRegistrado', { numero: saved?.numero }),
                })
              );

              dispatch(setModel(saved));
              dispatch(updateOnList({ data: saved }));
              dispatch(fetchByIdAsync(saved?.id));
            }, 500);

            handleResultWithDebounce();
          }
        } catch (error) {
          const responseErrorMessage =
            error?.response && error?.response?.data && error?.response?.data?.message
              ? error?.response?.data?.message
              : undefined;

          if (!responseErrorMessage) {
            dispatch(failure());
            dispatch(
              setErrorFeedback({
                message: t('com.muralis.qcx.erro.erroSalvarFollowUp'),
              })
            );

            return;
          }

          if (FollowUpUtils.hasWarningFlag(responseErrorMessage)) {
            dispatch(resetStatus());
            dispatch(changeToBackgroundUpdateMode());
            dispatch(setModel(data));

            setWarning({
              flag: FollowUpUtils.WARNING_ON_UPDATING,
              message: FollowUpUtils.getMessageWithoutFlag(responseErrorMessage),
            });

            return;
          }

          const fullErrorMessage = t('com.muralis.qcx.erro.erroSalvarFollowUpEspecifico', {
            mensagem: responseErrorMessage,
          });

          dispatch(failure());
          dispatch(
            setErrorFeedback({
              message: fullErrorMessage,
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    },
    [handleChangeToConsult, parseToFormData]
  );

  const handleSubmit = useCallback(
    async (data) => {
      const normalizedData = normalize(data);

      if ((isUpdate && !isBackgroundCreate) || isBackgroundUpdate) {
        await update(normalizedData);

        return;
      }

      if (isCreate || isBackgroundCreate) {
        await create(normalizedData);
      }
    },
    [isCreate, isUpdate, isBackgroundCreate, isBackgroundUpdate, normalize, update, create]
  );

  const [handleAuthorizeOperationMiddleware, operationOfAuthorizeById] = useOperationMiddleware(
    async (data) => {
      await handleSubmit(data);

      dispatch(resetBackgroundMode());
    },
    [handleSubmit]
  );
  const updateReprocess = useCallback(
    async (data) => {
      const executeDebounced = debounce(async () => {
        try {
          const formData = parseToFormData(data);

          const response = await followUpAPI.save(formData);

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              handleChangeToConsult();
              dispatch(success());

              const saved = response?.data;

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: `Faturas do Follow-Up "${data?.numero}" estão sendo reprocessadas.`,
                })
              );

              dispatch(setModel(saved));
              dispatch(updateOnList({ data: saved }));
              dispatch(fetchByIdAsync(saved?.id));
            }, 500);

            handleResultWithDebounce();
          }
        } catch (error) {
          const responseErrorMessage =
            error?.response && error?.response?.data && error?.response?.data?.message
              ? error?.response?.data?.message
              : undefined;

          if (!responseErrorMessage) {
            dispatch(failure());
            dispatch(
              setErrorFeedback({
                message: 'Erro ao tentar salvar os dados do Follow-Up.',
              })
            );

            return;
          }

          if (FollowUpUtils.hasWarningFlag(responseErrorMessage)) {
            dispatch(resetStatus());
            dispatch(changeToBackgroundUpdateMode());
            dispatch(setModel(data));

            setWarning({
              flag: FollowUpUtils.WARNING_ON_UPDATING,
              message: FollowUpUtils.getMessageWithoutFlag(responseErrorMessage),
            });

            return;
          }

          const fullErrorMessage = `Erro ao tentar salvar os dados do Follow-Up: ${responseErrorMessage}.`;

          dispatch(failure());
          dispatch(
            setErrorFeedback({
              message: fullErrorMessage,
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    },
    [handleChangeToConsult, parseToFormData]
  );

  const handleSubmitReprocess = useCallback(async () => {
    if ((isUpdate && !isBackgroundCreate) || isBackgroundUpdate) {
      await updateReprocess(followUp);
    }
  }, [isUpdate, isBackgroundCreate, isBackgroundUpdate, updateReprocess]);

  const configureAuthorizeOperation = useCallback(
    (options) => {
      const handleConfigureOnClick = () => {
        const validatedObjectRule = FollowUpUtils.getValidatedResultIfPendingBy(options?.message);

        const configureOperation = () => ({
          options: {
            title: t('com.muralis.qcx.avisoOperacao').toUpperCase(),
            message: t('com.muralis.qcx.mensagem.mensagemContinuarOperacao', {
              mensagem: validatedObjectRule?.message,
            }),
          },
        });

        const dataOfAuthorizedOperation = {
          ...followUp,
          ...validatedObjectRule?.result,
        };

        handleAuthorizeOperationMiddleware(configureOperation, dataOfAuthorizedOperation);
      };

      return handleConfigureOnClick;
    },
    [followUp, handleAuthorizeOperationMiddleware]
  );

  const model = useMemo(() => {
    if (isCreate && !(isBackgroundCreate || isBackgroundUpdate)) {
      return {
        numero: followUp?.numero,
        numeroConhecimentoMasterValido: FollowUpUtils.CONFIRMACAO_REGRA_NAO_VALIDADO,
        numeroConhecimentoHouseValido: FollowUpUtils.CONFIRMACAO_REGRA_NAO_VALIDADO,
        restricaoFinanceiraValido: FollowUpUtils.CONFIRMACAO_REGRA_NAO_VALIDADO,
        vencimentoRadarValido: FollowUpUtils.CONFIRMACAO_REGRA_NAO_VALIDADO,
        vencimentoProcuracaoValido: FollowUpUtils.CONFIRMACAO_REGRA_NAO_VALIDADO,
        numeroFaturaValido: FollowUpUtils.CONFIRMACAO_REGRA_NAO_VALIDADO,
        ignorableFields: { anexo: { fatura_idioma: 'ENG' } },
      };
    }

    return unnormalize(followUp);
  }, [isCreate, isBackgroundCreate, isBackgroundUpdate, followUp, unnormalize]);

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloImportacao'),
          name: t('com.muralis.qcx.importacao.label'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.importacaoFollowUp'),
          name: t('com.muralis.qcx.followup.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.registro'),
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [isCreate, actionName]
  );

  const pageTitle = useMemo(
    () =>
      isNone || isCreate || isBackgroundCreate
        ? t('com.muralis.qcx.followup.novoRegistroFollowUpNumero', { numero: model?.numero ? model?.numero : '' })
        : t('com.muralis.qcx.followup.followUpModeloNumero', { numero: model?.numero || '-' }),
    [isNone, isCreate, isBackgroundCreate, model]
  );

  useAutoChangeMode((currentMode) => dispatch(changeModeTo(currentMode)), [isUpdate]);

  useEffect(() => {
    const configureDependencies = () => {
      dispatch(clienteActions.changeControlTo(IDLE_CONTROL));
      dispatch(servicoActions.changeControlTo(IDLE_CONTROL));
      dispatch(transportadoraActions.changeControlTo(IDLE_CONTROL));
    };

    configureDependencies();

    return () => {
      dispatch(clienteActions.resetControl());
      dispatch(servicoActions.resetControl());
      dispatch(transportadoraActions.resetControl());
    };
  }, []);

  useEffect(() => {
    const handleFetchById = () => {
      if (!isEmpty(params?.id)) {
        fetchById(params?.id);

        handleChangeToConsult();
      }
    };

    handleFetchById();
  }, [params, handleChangeToConsult]);

  useEffect(() => {
    if (FollowUpUtils.isWarningOperation(warning?.flag)) {
      const dispatchAuthorizationForOperation = configureAuthorizeOperation({
        message: warning?.message,
      });

      setWarning({
        flag: FollowUpUtils.NOOP_WARNING,
        message: undefined,
      });

      dispatchAuthorizationForOperation();
    }
  }, [warning, setWarning, configureAuthorizeOperation]);

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isIdle={isIdle}
      isLoading={isLoading}
      isFailure={isFailure}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isLocked={isLocked}
      isPreparingAction={isPreparingAction}
      isBackgroundCreate={isBackgroundCreate}
      isBackgroundDelete={isBackgroundDelete}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToBackgroundDelete={handleChangeToBackgroundDelete}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      authInfo={authInfo}
    >
      {(formProps) => (
        <>
          <QCXFollowUpFinalForm
            initialValues={model}
            handleChangeModel={handleDispatchSetModel}
            handleSubmit={handleSubmit}
            handleSubmitReprocess={handleSubmitReprocess}
            authInfo={authInfo}
            requiredRoles={['importacao-follow-up']}
            success={success}
            failure={failure}
            setError={setErrorFeedback}
            setLoading={loading}
            {...formProps}
          />
          <QCXConfirmDialog
            key="confirm-dialog-confirm-warning-follow-up"
            id="confirm-dialog-confirm-warning-follow-up"
            open={operationOfAuthorizeById?.active}
            title={operationOfAuthorizeById?.title}
            content={operationOfAuthorizeById?.message}
            endContent={operationOfAuthorizeById?.endMessage}
            onConfirm={operationOfAuthorizeById.confirm}
            onClose={operationOfAuthorizeById?.reset}
            buttonGroupOptions={{
              confirm: {
                description: t('com.muralis.qcx.acoes.continuar'),
              },
              cancel: {
                description: t('com.muralis.qcx.acoes.cancelar'),
              },
            }}
          />
          <QCXConfirmDialog
            open={operationOfCancelUpdate?.active}
            title={operationOfCancelUpdate?.title}
            content={operationOfCancelUpdate?.message}
            endContent={operationOfCancelUpdate?.endMessage}
            onConfirm={operationOfCancelUpdate.confirm}
            onClose={operationOfCancelUpdate?.reset}
            buttonGroupOptions={{
              confirm: {
                description: t('com.muralis.qcx.expressao.sim'),
              },
              cancel: {
                description: t('com.muralis.qcx.expressao.nao'),
              },
            }}
          />
        </>
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
