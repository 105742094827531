import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _, { isFunction } from 'lodash';
import { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectFollowUps } from '../../../../../features/follow-up/followUpSelectors';

import QCXAutocompleteSelectWithSearch from '../../../../../shared-components/autocomplete-select/QCXAutocompleteSelectWithSearch';
import { fetchByNumeroAsync } from '../../../../../features/follow-up/followUpThunks';

export default function FinalSelectFollowUpWithSearch({ 
  label, 
  name, 
  filterPredicate, 
  applyImportExportFilter = false,
  ...restProps 
}) {
  const dispatch = useDispatch();
  const list = useSelector(selectFollowUps);

  const { values } = useFormState();

  const [filteredFollowUps, setFilteredFollowUps] = useState([]);
  const [emptyListMsg, setEmptyListMsg] = useState('');

  const filterPredicateImportExport = (followUp) => {
    const produto = followUp?.servico?.produto?.toUpperCase();
    return produto === 'IMPORTACAO' || produto === 'EXPORTACAO';
  };

  useEffect(() => {
    let filtered = [];

    if (isFunction(filterPredicate)) {
      filtered = list.filter(filterPredicate);
    } else if (applyImportExportFilter) {
      filtered = list.filter(filterPredicateImportExport);
    } else {
      filtered = list;
    }

    setFilteredFollowUps(filtered);

    if (!filtered.length) {
      setEmptyListMsg('Nenhum followup encontrado');
    } else {
      setEmptyListMsg('');
    }
  }, [list, filterPredicate, applyImportExportFilter]);

  const [defaultValue, setDefaultValue] = useState(null);

  useEffect(() => {
    const value = list.find((item) => String(item.id) === String(_.get(values, name)));

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue(null);
      // Necessário se precisar iniciar o campo com um valor
      // if (_.get(values, name)) {
      //   const id = _.get(values, name);
      //   _.debounce(() => dispatch(fetchByIdAndInsertList(id)), 200)();
      // }
    }
  }, [list, values, name]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.numero || ''}`,
  });

  const searchFiltredFollowups = (value) => {
    dispatch(fetchByNumeroAsync(value)); // adicionar função de filtro do folllow
  };

  return (
    <QCXAutocompleteSelectWithSearch
      label={label}
      id={name}
      name={name}
      onEventActionPaiFunction={searchFiltredFollowups}
      options={filteredFollowUps}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (item?.id ? `${item?.numero || ''}` : '')}
      emptyListMsg={emptyListMsg}
      placeholder="Digite no mínimo 4 dígitos para realizar a busca !"
      {...restProps}
    />
  );
}
