import { useTranslation } from "react-i18next";
import PageLayout from "../../../common/layouts/pageLayout";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useDispatch, useSelector } from "react-redux";
import { useKeycloak } from '@react-keycloak/web';
import { Button, Grid } from '@mui/material';
import { useEffect } from "react";
import { setErrorFeedback, setSuccessFeedback } from "../../../../features/feedback/feedbackSlice";
import { generateMapaProcessosReport } from "./mapaProcessosPage.helpers";
import { KEYCLOAK_TOKEN_TIMEOUT } from "../../../../App";
import {
    selectUnidadeSelecionada,
} from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { useUnidadeNegocioGuard } from "../../../common/hooks/useUnidadeNegocioGuard";

const MapaProcessosPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { keycloak } = useKeycloak();
    const { token } = keycloak;
    keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

    useUnidadeNegocioGuard();  // Ensure the user has access to the necessary business units

    const selectedUnit = useSelector(selectUnidadeSelecionada); // Select the unit

    useEffect(() => {
        keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
    }, [keycloak]);

    const handleSubmit = async () => {
        keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

        if (token && selectedUnit) {
            try {
                const error = await generateMapaProcessosReport(token, selectedUnit);
                if (error) {
                    dispatch(setErrorFeedback({ message: error }));
                } else {
                    dispatch(setSuccessFeedback({ message: 'Relatório gerado com sucesso!' }));
                }
            } catch (error) {
                dispatch(setErrorFeedback({ message: 'Erro ao gerar o relatório.' }));
            }
        }
    };

    return (
        <PageLayout title={'Mapa de Processos'} icon={<InsertDriveFileIcon color={'secondary'} />}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmit}
                        fullWidth
                    >
                        Gerar relatório
                    </Button>
                </Grid>
            </Grid>
        </PageLayout>
    );
};

export default MapaProcessosPage;
