import { CircularProgress } from '@material-ui/core';
import PreviewIcon from '@mui/icons-material/Preview';
import { Button, Grid } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSubscription } from 'react-stomp-hooks';
import QCXMoveFocusInside from '../../../../components/focus-lock/QCXMoveFocusInside';
import { selectDemandasOcr } from '../../../../features/demanda-ocr/demandaOcrSelectors';
import { setList } from '../../../../features/demanda-ocr/demandaOcrSlice';
import { fetchAllAsync } from '../../../../features/demanda-ocr/demandaOcrThunks';
import { setErrorFeedback } from '../../../../features/feedback/feedbackSlice';
import { followUpAPI } from '../../../../features/follow-up/followUpAPI';
import QCXSelectClienteAutocomplete from '../../../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXFinalTextField from '../../../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectServicoAutocomplete from '../../../../shared-components/select-servico/QCXSelectServicoAutocomplete';
import { MediaType } from '../../../../utils/api/api-utils';
import useCurrentUnidadeNegocio from '../../../../utils/hooks/user/useCurrentUnidadeNegocio';
import { required } from '../../../../utils/validators/field/validator';
import FileDragAndDrop from '../../../common/components/fileDragAndDrop';
import FormHeaderMedium from '../../../common/components/forms/formHeaderMedium';
import PageLayout from '../../../common/layouts/pageLayout';
import OcrDemandProgessIndicator from './components/ocrDemandProgressIndicator';
import { useUnidadeNegocioGuard } from '../../../common/hooks/useUnidadeNegocioGuard';
export interface DemandaOCRMessage {
  id: number;
  code: string;
  description: string;
  followUp: {
    id: number;
    overridesTipoMascara: boolean;
  };
  status: string;
  faturaId: number;
  insertionDate: string;
}

const OcrPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const demandasOcr = useSelector(selectDemandasOcr);
  useUnidadeNegocioGuard();

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  const normalizeArquivo = useCallback((arquivo: File) => {
    const normalizedArquivo = {
      id: null,
      fileName: arquivo.name,
      fatura: [{}],
      status: 'NAO_INTEGRADO',
      idioma: 'ENG',
      dataFatura: undefined,
      numeroFatura: undefined,
      totalItens: undefined,
    };
    return normalizedArquivo;
  }, []);

  const parseToFormData = useCallback((data: any) => {
    const { arquivos, ...restData } = data;

    const formData = new FormData();

    if (data.arquivos) {
      formData.append('files[]', arquivos);
    }

    const normalizedArquivos = arquivos ? [normalizeArquivo(arquivos)] : [];
    const restDataWithArquivos = { ...restData, arquivos: normalizedArquivos };
    const json = JSON.stringify(restDataWithArquivos);
    const blob = new Blob([json], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append('data', blob);

    const meta = { metadata: [] };
    const jsonMeta = JSON.stringify(meta);
    const blobMeta = new Blob([jsonMeta], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append('metadata', blobMeta);

    return formData;
  }, []);

  const onSubmit = useCallback(async (values: any) => {
    const formData = parseToFormData(values);
    let response;
    try {
      response = await followUpAPI.register(formData);
    } catch (error) {
      dispatch(
        setErrorFeedback({
          message: 'Ocorreu um erro ao enviar a fatura para processamento: número do processo já cadastrado',
        })
      );
    }
  }, []);

  const unidadeDeNegocio = useCurrentUnidadeNegocio();

  useSubscription('/topic/demanda-ocr/' + unidadeDeNegocio?.id, (message: any) => {
    const demandaOCR: DemandaOCRMessage = JSON.parse(message.body);

    console.log('demandaOCR', demandaOCR);

    if (!demandaOCR?.insertionDate) {
      const now = new Date();
      demandaOCR.insertionDate = now.toLocaleString('PT-br');
    }

    const newDemandaOCRMessages = [...demandasOcr];
    const index = newDemandaOCRMessages.findIndex((message) => message.description === demandaOCR.description);
    if (index >= 0) {
      newDemandaOCRMessages[index] = demandaOCR;
    } else {
      newDemandaOCRMessages.unshift(demandaOCR);
    }

    dispatch(setList(newDemandaOCRMessages));
  });

  const sortedDemandasOcr = useMemo(() => {
    if (!demandasOcr || demandasOcr.length === 0) {
      return [];
    }
    const sortedDemandasOcr = [...demandasOcr];
    return sortedDemandasOcr.sort((a: DemandaOCRMessage, b: DemandaOCRMessage) => {
      return new Date(b.insertionDate).getTime() - new Date(a.insertionDate).getTime();
    });
  }, [demandasOcr]);

  return (
    <PageLayout title={'OCR'} icon={<PreviewIcon color={'secondary'} />}>
      <Form onSubmit={(values: any) => onSubmit(values)}>
        {({ handleSubmit, form, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <FormHeaderMedium>Informações básicas</FormHeaderMedium>
                <Grid item xs={4}>
                  <QCXMoveFocusInside>
                    <QCXSelectClienteAutocomplete
                      id="autocomplete-select-cliente-field"
                      key="autocomplete-select-cliente-field"
                      name="importador.id"
                      label={t('com.muralis.qcx.cliente.label')}
                      initialValues={undefined}
                      filter={undefined}
                      required
                    />
                  </QCXMoveFocusInside>
                </Grid>
                <Grid item xs={4}>
                  <QCXSelectServicoAutocomplete
                    id="autocomplete-select-servico-field"
                    key="autocomplete-select-servico-field"
                    name="servico.id"
                    label={t('com.muralis.qcx.servico.labelSingular')}
                    initialValues={undefined}
                    fieldProps={{ validate: required }}
                    produto="FATURA"
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <QCXFinalTextField
                    id="text-field-processo"
                    key="text-field-processo"
                    name="numero"
                    label={t('com.muralis.qcx.processo')}
                    initialValues={undefined}
                    fieldProps={{ validate: required }}
                    required
                  />
                </Grid>

                <FormHeaderMedium>Seleção de arquivo</FormHeaderMedium>
                <Field name="arquivos" validate={required}>
                  {({ input }) => <FileDragAndDrop input={input} accept={MediaType.APPLICATION_PDF}></FileDragAndDrop>}
                </Field>
                <Grid item xs={12} textAlign="right">
                  <Button type="submit" color="secondary" variant="contained" disabled={submitting}>
                    {submitting ? <CircularProgress size={'24px'} /> : 'Importar fatura'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Form>

      {demandasOcr?.length > 0 ? <FormHeaderMedium>Processamentos</FormHeaderMedium> : <></>}

      <Grid item container sx={{ marginTop: 2 }} spacing={1}>
        {sortedDemandasOcr.map((demandaOCRMessage: DemandaOCRMessage) => {
          return <OcrDemandProgessIndicator message={demandaOCRMessage} key={demandaOCRMessage.id} />;
        })}
      </Grid>
    </PageLayout>
  );
};

export default OcrPage;
