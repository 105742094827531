import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectCatalogosProdutos,
  selectCatalogosProdutosCount,
} from '../../../../../../../features/catalogo-produtos/catalogoProdutosSlice';

export default function usePartnumbersDataReplicator() {
  const [replicated, setReplicated] = useState(false);

  const catalogosProdutos = useSelector(selectCatalogosProdutos);
  const catalogosProdutosCount = useSelector(selectCatalogosProdutosCount);

  const tableProps = useMemo(
    () => ({
      title: 'Partnumbers',
      rowsCount: catalogosProdutosCount,
      columns: [
        {
          field: 'status',
          headerName: 'Status',
          headerAlign: 'center',
          align: 'center',
          width: 100,
          valueGetter: ({ row }: any) => row?.statusIntegracao || '-',
        },
        {
          field: 'partnumber',
          headerName: 'Partnumber',
          headerAlign: 'center',
          align: 'center',
          width: 180,
          valueGetter: ({ row }: any) => row?.partnumber || '-',
        },
        {
          field: 'codigo_secundario',
          headerName: 'Código secundário',
          headerAlign: 'center',
          align: 'center',
          width: 150,
          valueGetter: ({ row }: any) => row?.code || '-',
        },
        {
          field: 'especificacao',
          headerName: 'Especificação',
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          valueGetter: ({ row }: any) => row?.especificacao || '-',
        },
        {
          field: 'ncm',
          headerName: 'NCM',
          headerAlign: 'center',
          align: 'center',
          width: 100,
          valueGetter: ({ row }: any) => row?.ncm?.code || '-',
        },
      ],
      data: catalogosProdutos,
    }),
    [catalogosProdutos]
  );

  const filterableColumns = tableProps.columns.filter((column) => column.field === 'partnumber');

  const handleResetFormData = useCallback((form: any) => {
    form.restart({});
  }, []);

  const formatSelection = (item: any) => ({ ...item });

  return { tableProps, filterableColumns, handleResetFormData, formatSelection, replicated, setReplicated };
}
