import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
  } from 'react';
  import { useDispatch, useSelector } from 'react-redux';
  
  // Changed EstadoContext to TaxaConversaoCambioContext
  import TaxaConversaoCambioContext from '../../../../../contexts/registrations/taxa-conversao-cambio/TaxaConversaoCambioContext'; 
  
  // Changed estadoSlice to taxaConversaoCambioSlice
  import {
    clearResponse,
    resetStatus,
    selectError,
    selectStatus,
    selectResponse,
  } from '../../../../../features/taxa-conversao-cambio/taxaConversaoCambioSlice'; 
  
  // Changed paisSlice to moedaSlice
  import { selectStatus as selectStatusMoeda } from '../../../../../features/moeda/moedaSlice';
  
  import { ALTERNATIVE_LOADING_STATUS, FAILURE_STATUS, LOADING_STATUS, SUCCESS_STATUS } from '../../../../../features/status';
  import QCXPageTemplate from '../../../../../templates/page/QCXPageTemplate';
  
  export default function TaxaConversaoCambioPage({ children }) { 
    const dispatch = useDispatch();
    const status = useSelector(selectStatus);
    
    const statusMoeda = useSelector(selectStatusMoeda); 
    
    const error = useSelector(selectError);
    const response = useSelector(selectResponse);
  
    const isLoading = useMemo(() => (
      status === LOADING_STATUS || statusMoeda === ALTERNATIVE_LOADING_STATUS 
    ), [status, statusMoeda]); 
  
    const [successSnackbar, setSuccessSnackbar] = useState({
      open: false,
      message: '',
    });
  
    const [errorSnackbar, setErrorSnackbar] = useState({
      open: false,
      message: '',
    });
  
    const handleOpenSuccessSnackbar = useCallback((message) => {
      setSuccessSnackbar({
        open: true,
        message,
      });
    }, []);
  
    const handleOpenErrorSnackbar = useCallback((message) => {
      setErrorSnackbar({
        open: true,
        message,
      });
    }, []);
  
    const handleCloseSuccessSnackbar = useCallback(() => {
      setSuccessSnackbar({
        ...successSnackbar,
        open: false,
      });
    }, []);
  
    const handleCloseErrorSnackbar = useCallback(() => {
      setErrorSnackbar({
        ...errorSnackbar,
        open: false,
      });
    }, []);
  
    useEffect(() => {
      if (status === FAILURE_STATUS && error && error.message) {
        handleOpenErrorSnackbar(error.message);
        dispatch(resetStatus());
      } else if (status === SUCCESS_STATUS && response && response.message) {
        handleOpenSuccessSnackbar(response.message);
        dispatch(resetStatus());
        dispatch(clearResponse());
      }
    }, [response, status, error]);
  
    const providerData = useMemo(() => ({
      status,
      isLoading,
      successSnackbar,
      errorSnackbar,
      handleCloseSuccessSnackbar,
      handleCloseErrorSnackbar,
    }), [
      status,
      isLoading,
      successSnackbar,
      errorSnackbar,
    ]);
  
    return (
      <TaxaConversaoCambioContext.Provider value={providerData}> 
        <QCXPageTemplate contextComponent={TaxaConversaoCambioContext}>
          {children}
        </QCXPageTemplate>
      </TaxaConversaoCambioContext.Provider>
    );
  }
