import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IDLE_CONTROL } from '../config-control';
import { ALTERNATIVE_LOADING_STATUS, FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS } from '../status';
import { activateById, fetchAll, fetchByFilter, fetchByFilterList, fetchById, inactivateById } from './nveAPI';
import i18n from '../../i18n';

interface Vigencia {
  inicio: string;
  fim: string | null;
}

interface Especificacao {
  id: number;
  insertionDate: string;
  code: string;
  description: string;
  vigencia: Vigencia;
}

interface Atributo {
  id: number;
  insertionDate: string;
  code: string;
  description: string;
  especificacoes: Especificacao[];
}

export interface Nve {
  id: number;
  insertionDate: string;
  code: string;
  description: string;
  active: boolean;
  numeroAtoLegal: string;
  tipoAtoLegal: string;
  anoAtoLegal: number;
  orgaoEmissorAtoLegal: string;
  atributos: Atributo[];
}

interface NveState {
  status: string;
  error: any;
  response: {
    status: number | null;
    message: string | null;
    data: Nve | null;
  };
  list: Nve[];
  config: {
    control: string;
  };
  model: {
    id: string;
    code: string;
    description: string;
    active: boolean | null;
  };
}

const initialState: NveState = {
  status: IDLE_STATUS,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
  config: { control: IDLE_CONTROL },
  model: {
    id: '',
    code: '',
    description: '',
    active: null,
  },
};

const fetchAllAsync = createAsyncThunk('nve/fetchAll', async () => {
  const { data } = await fetchAll();
  return { data };
});

const fetchByFilterAsync = createAsyncThunk('nve/fetchByFilter', async (params: any[]) => {
  const { data } = await fetchByFilter(params);
  return { data };
});

const fetchNveByListFilterAsync = createAsyncThunk('nve/fetchNveByListFilter', async (nves: string[]) => {
  const params = []

  params.push({name: 'nvesCode', value: nves.join(',')});
  
  const { data } = await fetchByFilterList(params);

  return { data };
});

const fetchByIdAsync = createAsyncThunk('nve/fetchById', async (data) => {
  const response = await fetchById(data);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const activateByIdAsync = createAsyncThunk('nve/activateById', async (id) => {
  const { status, data } = await activateById(id);
  return { response: { status, data } };
});

const inactivateByIdAsync = createAsyncThunk('nve/inactivateById', async (id) => {
  const { status, data } = await inactivateById(id);
  return { response: { status, data } };
});

const nveSlice = createSlice({
  name: 'nve',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
    resetList: (state) => {
      state.list = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarListaNVE', { erro: action.error.message }),
        };
      })
      .addCase(fetchByFilterAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchByFilterAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchByFilterAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarListaNVE', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = action.payload.response.data;
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarDadosNVE', { erro: action.error.message }),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { description } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.NVEativado', { nve: description });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.ativarNVE', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { description } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.NVEinativado', { nve: description });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.ativarNVE', { erro: action.error.message }),
        };
      })
      .addCase(fetchNveByListFilterAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchNveByListFilterAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchNveByListFilterAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarListaNVE', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  addToList,
  updateOnList,
  setResponse,
  clearResponse,
  setError,
  resetModel,
  changeControlTo,
  setConfig,
  resetList,
} = nveSlice.actions;
const selectNves = (state: any) => state.nve.list as Nve[];
const selectIsNveLoading = (state: any) => state.nve.status === LOADING_STATUS;
const selectStatus = (state: any) => state.nve.status;
const selectError = (state: any) => state.nve.error;
const selectResponse = (state: any) => state.nve.response;
const selectNve = (state: any) => state.nve.model as Nve;
const selectConfigControl = (state: any) => state.nve.config.control;
const nveActions = {
  ...nveSlice.actions,
  fetchAllAsync,
  fetchByFilterAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  selectNves,
  selectStatus,
  selectError,
  selectResponse,
  selectNve,
  selectConfigControl,
};

export {
  nveSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  addToList,
  updateOnList,
  setResponse,
  clearResponse,
  setError,
  resetModel,
  fetchAllAsync,
  fetchByFilterAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  changeControlTo,
  setConfig,
  nveActions,
  resetList,
  fetchNveByListFilterAsync,
  selectIsNveLoading,
  selectNves,
  selectNve,
  selectStatus,
  selectError,
  selectResponse,
  selectConfigControl,
};

export default nveSlice.reducer;
