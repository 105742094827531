import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';

const RelatorioTransmissaoDuimpPopup = ({ open, onClose, relatorio }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const itemsPerPage = 3;
  const theme = useTheme();

  const handlePreviousPage = () => {
    setPageIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextPage = () => {
    setPageIndex((prevIndex) => Math.min(prevIndex + 1, Math.floor(relatorio.length / itemsPerPage)));
  };

  const paginatedRelatorio = Array.isArray(relatorio) ? relatorio.slice(pageIndex * itemsPerPage, (pageIndex + 1) * itemsPerPage) : [];

  const headerRelatorio = () => {
    console.log(relatorio)
    return (
      <>
        {
          `Capa Transmitida   : ${relatorio?.isCapaTransmitida ? "Sim" : "Não"}`
        }
        <br/>
        {
          `Qtd Itens Transmitidos com Sucesso: ${relatorio?.qtdTotalItensTransmitidos}`
        }
        <br/>
      </>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Relatório Transmissão DUIMP</DialogTitle>
      <DialogContent dividers style={{ maxHeight: '75vh', overflow: 'auto' }}>
        {
          headerRelatorio()
        }
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ backgroundColor: theme.palette.secondary.main, color: 'white' }}>Partnumber Item</TableCell>
                <TableCell style={{ color: 'red' }}>ALERTAS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRelatorio.map(item => (
                <TableRow key={item.id}>
                  <TableCell>{item.partNumber}</TableCell>
                  <TableCell>{item.cliente}</TableCell>
                  <TableCell>{item.erros}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <IconButton onClick={handlePreviousPage} disabled={pageIndex === 0}>
          <ArrowBack />
        </IconButton>
        <IconButton onClick={handleNextPage} disabled={(pageIndex + 1) * itemsPerPage >= relatorio.length}>
          <ArrowForward />
        </IconButton>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RelatorioTransmissaoDuimpPopup;
