// Não tenho certeza do que essa tela faz,
// porém ela existe no padrão da plataform então replique sem alterar nada
// se souber o q esta fazendo pode removela

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FollowUpContext from '../../../../contexts/import/follow-up/FollowUpContext';
import {
  selectStatus,
  selectMode,
  selectSubMode,
  selectBackgroundMode,
  selectError,
  selectResponse,
} from '../../../../features/follow-up/followUpSelectors';

import { selectStatus as selectStatusDadosEmbarque } from './api/dadosEmbarqueSelectors';

import { clearError, clearResponse, resetStatus } from '../../../../features/follow-up/followUpSlice';
import QCXPageTemplate from '../../../../templates/page/QCXPageTemplate';
import { isFailureStatus, isLoadingStatus, isSuccessStatus } from '../../../../utils/store/store-utils';

export default function DadosEmbarquePage({ children }) {
  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const statusDadosEmbarque = useSelector(selectStatusDadosEmbarque);
  const error = useSelector(selectError);
  const response = useSelector(selectResponse);

  const modeSelectors = useMemo(
    () => ({
      selectMode,
      selectSubMode,
      selectBackgroundMode,
    }),
    [selectMode, selectSubMode, selectBackgroundMode]
  );

  const isLoading = useMemo(() => isLoadingStatus(status) || isLoadingStatus(statusDadosEmbarque), [status, statusDadosEmbarque]);

  const [successSnackbar, setSuccessSnackbar] = useState({
    open: false,
    message: '',
  });

  const [errorSnackbar, setErrorSnackbar] = useState({
    open: false,
    message: '',
  });

  const handleOpenSuccessSnackbar = useCallback((message) => {
    setSuccessSnackbar({
      open: true,
      message,
    });
  }, []);

  const handleOpenErrorSnackbar = useCallback((message) => {
    setErrorSnackbar({
      open: true,
      message,
    });
  }, []);

  const handleCloseSuccessSnackbar = useCallback(() => {
    setSuccessSnackbar({
      ...successSnackbar,
      open: false,
    });
  }, []);

  const handleCloseErrorSnackbar = useCallback(() => {
    setErrorSnackbar({
      ...errorSnackbar,
      open: false,
    });
  }, []);

  useEffect(() => {
    const handleStatus = () => {
      if (isFailureStatus(status) && error && error.message) {
        handleOpenErrorSnackbar(error.message);
        dispatch(resetStatus());
        dispatch(clearError());

        return;
      }
      if (isSuccessStatus(status) && response && response.message) {
        handleOpenSuccessSnackbar(response.message);
        dispatch(resetStatus());
        dispatch(clearResponse());
      }
    };

    handleStatus();
  }, [response, status, error]);

  const providerData = useMemo(
    () => ({
      status,
      isLoading,
      successSnackbar,
      errorSnackbar,
      handleCloseSuccessSnackbar,
      handleCloseErrorSnackbar,
    }),
    [status, isLoading, successSnackbar, errorSnackbar]
  );

  return (
    <FollowUpContext.Provider value={providerData}>
      <QCXPageTemplate contextComponent={FollowUpContext} modeSelectors={modeSelectors}>
        {children}
      </QCXPageTemplate>
    </FollowUpContext.Provider>
  );
}
