/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { Grid } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import QCXMoveFocusInside from '../../../../../../../components/focus-lock/QCXMoveFocusInside';
import QCXFormTitle from '../../../../../../../components/form-title/QCXFormTitle';
import ReferenciaClienteBondManager from '../../../../../../../shared-components/dados-embarque/bond-managers/ReferenciaClienteBondManager';
import AnalistaResponsavelSelect from '../../../../../../../shared-components/dados-embarque/selects/AnalistaResponsavelSelect';
import TipoCargaSelect from '../../../../../../../shared-components/dados-embarque/selects/TipoCargaSelect';
import TipoEstufagemSelect from '../../../../../../../shared-components/dados-embarque/selects/TipoEstufagemSelect';
import QCXFinalAlternativeCurrencyField from '../../../../../../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalDateTimePickerField from '../../../../../../../shared-components/final-date-time-picker-field/QCXFinalDateTimePickerField';
import QCXSelectEmpresaEstrangeiraAutocomplete from '../../../../../../../shared-components/final-select-empresa-estrangeira-field/QCXSelectEmpresaEstrangeiraAutocomplete';
import QCXSelectIncotermCondicaoVendaAutocomplete from '../../../../../../../shared-components/final-select-incoterm-condicao-venda-field/QCXSelectIncotermCondicaoVendaAutocomplete';
import QCXFinalTextField from '../../../../../../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../../../../../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectPaisAutocomplete from '../../../../../../../shared-components/select-pais/QCXSelectPaisAutocomplete';
import QCXSelectRecintoAduaneiroAutocomplete from '../../../../../../../shared-components/select-recinto-aduaneiro/QCXSelectRecintoAduaneiroAutocomplete';
import QCXSelectTransportadorInternacionalAutocomplete from '../../../../../../../shared-components/select-transportador-internacional/QCXSelectTransportadorInternacionalAutocomplete';
import QCXSelectTransportadoraAutocomplete from '../../../../../../../shared-components/select-transportadora/QCXSelectTransportadoraAutocomplete';
import QCXSelectUnidadeNegocioAutocomplete from '../../../../../../../shared-components/select-unidade-negocio/QCXSelectUnidadeNegocioAutocomplete';
import SwitchStyled from '../../../../../../../ts/common/components/input/switchStyled';
import { isConsultMode, isCreateMode } from '../../../../../../../utils/store/store-utils';
import { required } from '../../../../../../../utils/validators/field/validator';
import QCXSelectFollowUpImportacaoAutocompleteField from '../../../DadosEmbarqueConsultPage/components/QCXSelectFollowUpExportacaoAutocompleteField';
import { selectMode } from '../../../api/dadosEmbarqueSelectors';
import TipoCanalDesembaracoSelect from '../selects/TipoCanalDesembaracoSelect';
import { BRAZILIAN } from '../../../../../../../utils/hooks/form/field/mask-types';
import QCXFinalNumericIntegerField from '../../../../../../../shared-components/final-numeric-integer-field/QCXFinalNumericIntegerField';
import EmpresaCourierSelect from '../../../../../../../shared-components/dados-embarque/selects/EmpresaCourierSelect';

export default function DadosEmbarqueBasicasStepForm({
  initialValues,
  handleSubmit,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();

  const mode = useSelector(selectMode);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      disablePristine
      {...restProps}
    >
      {({ values }) => (
        <>
          <QCXFormTitle title={t('com.muralis.qcx.fichaBasicas')} />
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            <Grid item xs={6}>
              <QCXMoveFocusInside>
                <QCXSelectFollowUpImportacaoAutocompleteField
                  key="select-autocomplete-processo-field"
                  id="select-autocomplete-processo-field"
                  name="followUp.id"
                  label={t('com.muralis.qcx.processo')}
                  validate={required}
                  isCreate={isCreate}
                  disabled
                  required
                />
              </QCXMoveFocusInside>
            </Grid>

            <Grid item container alignContent="flex-start" xs={12}>
              <Grid item xs={12}>
                <ReferenciaClienteBondManager isConsult={isConsult} />
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <AnalistaResponsavelSelect
                key="analista-responsavel-select-field"
                id="analista-responsavel-select-field"
                name="usuarioResponsavel"
                label={t('com.muralis.qcx.dadosEmbarque.analistaResponsavel')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXSelectUnidadeNegocioAutocomplete
                key="unidade-negocio-select-field"
                id="unidade-negocio-select-field"
                name="unidadeNegocioFaturamento.id"
                label={t('com.muralis.qcx.dadosEmbarque.unidadeNegocioFaturamento')}
                disabled={isConsult}
                autoLoad
              />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalTextField
                key="numera-due-text-field"
                id="numera-due-text-field"
                name="numeroDue"
                label={t('com.muralis.qcx.dadosEmbarque.numeroDue')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalDateTimePickerField
                key="data-due-field"
                id="data-due-field"
                name="dataRegistroDue"
                label={t('com.muralis.qcx.dadosEmbarque.dataRegistroDue')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalTextField
                key="ruc-field"
                id="ruc-field"
                name="ruc"
                label={t('com.muralis.qcx.dadosEmbarque.ruc')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <TipoCanalDesembaracoSelect
                key="canal-desembaraco-field"
                id="canal-desembaraco-field"
                name="canalDesembaraco"
                label={t('com.muralis.qcx.dadosEmbarque.canalDesembaraco')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalTextField
                key="nome-navio-field"
                id="nome-navio-field"
                name="nomeNavio"
                label={t('com.muralis.qcx.dadosEmbarque.nomeNavio')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={3}>
              <QCXFinalTextField
                key="nome-navio-field"
                id="nome-navio-field"
                name="numeroViagem"
                label={t('com.muralis.qcx.dadosEmbarque.numeroViagem')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={3}>
              <QCXFinalTextField
                key="numero-booking-field"
                id="numero-booking-field"
                name="numeroBookingReservaPraca"
                label={t('com.muralis.qcx.dadosEmbarque.numeroBookingReservaPraca')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXSelectTransportadoraAutocomplete
                id="transportador"
                key="transportador"
                name="followUp.transportadora"
                label={t('com.muralis.qcx.dadosEmbarque.transportadora')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={5}>
              <QCXFinalDateTimePickerField
                key="data-emissao-field"
                id="data-emissao-field"
                name="dataCarregamento"
                label={t('com.muralis.qcx.dadosEmbarque.dataCarregamento')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={1}>
              <SwitchStyled label={t('com.muralis.qcx.dadosEmbarque.pago')} />
            </Grid>

            <Grid item xs={6}>
              <QCXSelectTransportadoraAutocomplete
                id="transportador"
                key="transportador"
                name="transportadoraRemocao"
                label={t('com.muralis.qcx.dadosEmbarque.transportadoraRemocao')}
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>

            <Grid item xs={5}>
              <QCXFinalDateTimePickerField
                key="data-emissao-field"
                id="data-emissao-field"
                name="dataRemocao"
                label={t('com.muralis.qcx.dadosEmbarque.dataRemocao')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={1}>
              <SwitchStyled label={t('com.muralis.qcx.dadosEmbarque.pago')} />
            </Grid>

            <Grid item xs={3}>
              <QCXFinalDateTimePickerField
                key="dead-line-draft-field"
                id="dead-line-draft-field"
                name="deadLineDraft"
                label={t('com.muralis.qcx.dadosEmbarque.deadLineDraft')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={3}>
              <QCXFinalDateTimePickerField
                key="dead-line-carga-field"
                id="dead-line-carga-field"
                name="deadLineCarga"
                label={t('com.muralis.qcx.dadosEmbarque.deadLineCarga')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXSelectRecintoAduaneiroAutocomplete
                id="select-field-local-"
                key="select-field-local-"
                name="terminalEmbarque.id"
                label={t('com.muralis.qcx.dadosEmbarque.terminalEmbarque')}
                disabled={isConsult}
                initialValues={values}
                autoLoad
              />
            </Grid>

            <Grid item xs={6}>
              <QCXSelectRecintoAduaneiroAutocomplete
                id="select-field-local-"
                key="select-field-local-"
                name="terminalDespacho.id"
                label={t('com.muralis.qcx.dadosEmbarque.terminalDespacho')}
                disabled={isConsult}
                initialValues={values}
                autoLoad
              />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalTextField
                key="hawb-field"
                id="hawb-field"
                name="followUp.numeroConhecimentoHouse"
                label={t('com.muralis.qcx.dadosEmbarque.hawb')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalTextField
                key="awb-field"
                id="awb-field"
                name="followUp.numeroConhecimentoMaster"
                label={t('com.muralis.qcx.dadosEmbarque.awb_OBL_CRTNumero')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalDateTimePickerField
                key="data-emissao-field"
                id="data-emissao-field"
                name="dataEmissao"
                label={t('com.muralis.qcx.dadosEmbarque.dataEmissao')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <EmpresaCourierSelect
                key="transportadora-internacional-field"
                id="transportadora-internacional-field"
                name="empresaCourier"
                label={t('com.muralis.qcx.dadosEmbarque.empresaCourier')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalDateTimePickerField
                key="data-courier-field"
                id="data-courier-field"
                name="courier"
                label={t('com.muralis.qcx.dadosEmbarque.courierData')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalNumericIntegerField
                id="conta-courier-field"
                key="conta-courier-field"
                name="contaCourier"
                label={t('com.muralis.qcx.dadosEmbarque.numeroContaCourier')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={3}>
              <QCXFinalAlternativeCurrencyField
                key="valor-compra-field"
                id="valor-compra-field"
                name="valorCompra"
                label={t('com.muralis.qcx.dadosEmbarque.valorCompra')}
                disabled={isConsult}
                intl={BRAZILIAN}
                adorned
              />
            </Grid>

            <Grid item xs={3}>
              <QCXFinalAlternativeCurrencyField
                id="valor-venda-field"
                key="valor-venda-field"
                name="valorVenda"
                label={t('com.muralis.qcx.dadosEmbarque.valorVenda')}
                disabled={isConsult}
                intl={BRAZILIAN}
                adorned
              />
            </Grid>

            <Grid item xs={6}>
              <QCXSelectTransportadorInternacionalAutocomplete
                key="transportadora-internacional-field"
                id="transportadora-internacional-field"
                name="agenteCarga"
                label={t('com.muralis.qcx.dadosEmbarque.agenteCarga')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <TipoCargaSelect
                key="select-tipo-carga-field"
                id="select-tipo-carga-field"
                name="tipoCarga"
                label={t('com.muralis.qcx.dadosEmbarque.tipoCarga')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <TipoEstufagemSelect
                key="select-tipo-estufagem-field"
                id="select-tipo-estufagem-field"
                name="tipoEstufagem"
                label={t('com.muralis.qcx.dadosEmbarque.tipoEstufagem')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalTextField
                key="select-nota-fiscal-field"
                id="select-nota-fiscal-field"
                name="notaFiscalNumero"
                label={t('com.muralis.qcx.dadosEmbarque.numeroNotaFiscal')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalTextField
                key="bu-cliente-field"
                id="bu-cliente-field"
                name="buCliente"
                label={t('com.muralis.qcx.dadosEmbarque.buCliente')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXSelectEmpresaEstrangeiraAutocomplete
                key="empresa-estrangeira-field"
                id="empresa-estrangeira-field"
                name="importador.id"
                filterPredicate={(value) => value.importador}
                label={t('com.muralis.qcx.dadosEmbarque.importador')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXSelectPaisAutocomplete
                key="pais-destino-field"
                id="pais-destino-field"
                name="paisDestino.id"
                label={t('com.muralis.qcx.dadosEmbarque.paisDestino')}
                disabled={isConsult}
                autoLoad
                initialValues={values}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXSelectIncotermCondicaoVendaAutocomplete
                key="incoterm-select-field"
                id="incoterm-select-field"
                name="incoterm.id"
                label={t('com.muralis.qcx.dadosEmbarque.incoterms')}
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>

            <Grid item xs={12}>
              <QCXFinalTextField
                key="observacao-field"
                id="observacao-field"
                name="observacoes"
                label={t('com.muralis.qcx.dadosEmbarque.observacao')}
                disabled={isConsult}
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
