import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import FollowUpContext from '../../../../contexts/import/follow-up/FollowUpContext';
import {
  selectBackgroundMode,
  selectMode,
  selectStatus,
  selectSubMode,
} from '../../../../features/follow-up/followUpSelectors';
import QCXPageTemplate from '../../../../templates/page/QCXPageTemplate';
import { isLoadingStatus } from '../../../../utils/store/store-utils';

export default function FollowUpPage({ children }) {
  const status = useSelector(selectStatus);

  const modeSelectors = useMemo(
    () => ({
      selectMode,
      selectSubMode,
      selectBackgroundMode,
    }),
    [selectMode, selectSubMode, selectBackgroundMode]
  );

  const isLoading = useMemo(() => isLoadingStatus(status), [status]);

  const providerData = useMemo(
    () => ({
      status,
      isLoading,
    }),
    [status, isLoading]
  );

  return (
    <FollowUpContext.Provider value={providerData}>
      <QCXPageTemplate contextComponent={FollowUpContext} modeSelectors={modeSelectors}>
        {children}
      </QCXPageTemplate>
    </FollowUpContext.Provider>
  );
}
