/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {
  CancelOutlined as CancelOutlinedIcon,
  CheckCircle as CheckCircleIcon,
  Code as CodeIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  Delete as DeleteIcon,
  DoneAll as DoneAllIcon,
  FileCopy as FileCopyIcon,
  GridOn,
  MoreHoriz as MoreHorizIcon,
  Search as PageviewIcon,
  Print as PrintIcon,
  Receipt as ReceiptIcon,
  RotateLeft as RotateLeftIcon,
  SyncAlt as SyncAltIcon,
} from '@material-ui/icons';
import { saveAs } from 'file-saver';
import _, { isArrayLikeObject, isEmpty, isFunction } from 'lodash';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCalculator as FaCalculatorIcon } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { th } from 'date-fns/locale';
import QCXGenericDownloader from '../../../../components/downloader/QCXGenericDownloader';
import QCXMoveFocusInside from '../../../../components/focus-lock/QCXMoveFocusInside';
import { userHasDeleteRole, userHasUpdateRole } from '../../../../components/perfil/perfil-utils';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import LicencaImportacaoContext from '../../../../contexts/import/licenca-importacao/LicencaImportacaoContext';
import { IDLE_CONTROL } from '../../../../features/config-control';
import { setQuery } from '../../../../features/controller/controllerSlice';
import { setErrorFeedback, setSuccessFeedback, setWarningFeedback } from '../../../../features/feedback/feedbackSlice';
import { followUpAPI } from '../../../../features/follow-up/followUpAPI';
import { followUpActions } from '../../../../features/follow-up/followUpSlice';
import { licencaImportacaoAPI } from '../../../../features/licenca-importacao/licencaImportacaoAPI';
import {
  selectHistoryModel,
  selectLicencasImportacao,
  selectMode,
  selectRefresh,
  selectRelatedDuplicataModel,
  selectRelatedRegistroModel,
  selectRelatedReportModel,
  selectRelatedSelectionLicencaImportacaoList,
  selectCountLi,
  selectRelatedCalculoModel,
} from '../../../../features/licenca-importacao/licencaImportacaoSelectors';
import {
  changeLastHistoryModel,
  changeToBackgroundReversalMode,
  changeToBackgroundTransferMode,
  changeToCalculateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToRegisterMode,
  changeToReleaseMode,
  changeToReversalMode,
  changeToTransferMode,
  changeTypeTo,
  failure,
  loading,
  loadingLI,
  refresh as refreshList,
  resetBackgroundMode,
  resetHistoryModel,
  resetMode,
  resetModel,
  resetRefresh,
  resetRelatedMercadoriaModel,
  resetRelatedRegistroModel,
  resetRelatedReportModel,
  resetRelatedSelectionLicencaImportacaoList,
  resetSubBackgroundMode,
  resetSubMode,
  resetType,
  setProcesso,
  setRelatedCalculoModel,
  setRelatedDuplicataModel,
  setRelatedEstornoModel,
  setRelatedRegistroModel,
  setRelatedReportModel,
  setRelatedSelectionAvailableItemList,
  setRelatedSelectionLicencaImportacaoList,
  setRelatedTransferenciaModel,
  success,
  updateOnList,
} from '../../../../features/licenca-importacao/licencaImportacaoSlice';
import {
  activateByIdFromFaturaAsync,
  calculateByIdFromFaturaAsync,
  conferirByIdFromFaturaAsync,
  duplicarByIdFromFaturaAsync,
  fetchAllFromFaturaAsync,
  gerarSubstitutivaAsync,
  inactivateByIdFromFaturaAsync,
  transmissaoLiByIdAsync,
  fetchFilterPaginateAsync,
  calculateLisEmLoteAsync,
  releaseLisEmLoteAsync,
  gerarXMLLisEmLoteAsync,
} from '../../../../features/licenca-importacao/licencaImportacaoThunks';
import {
  FATURA_LI_META_TYPE,
  isFaturaLiMetaType,
  isGeneratedLiMetaType,
} from '../../../../features/licenca-importacao/licencaImportacaoUtils';
import { mercadoriaMovimentacaoAPI } from '../../../../features/mercadoria-movimentacao/mercadoriaMovimentacaoAPI';
import { changeControlTo as changeControlNaladiShTo } from '../../../../features/naladi-sh/naladiShSlice';
import { changeControlTo as changeControlNcmTo } from '../../../../features/ncm-subitem/ncmSubitemSlice';
import { changeControlTo as changeControlPaisTo } from '../../../../features/pais/paisSlice';
import { changeControlTo as changeControlUnidadeMedidaTo } from '../../../../features/unidade-medida/unidadeDeMedidaSlice';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import QCXFinalFormDialog from '../../../../shared-components/dialog/QCXFinalFormDialog';
import QCXFinalRadioField from '../../../../shared-components/final-radio-field/QCXFinalRadioField';
import QCXFinalTextField from '../../../../shared-components/final-text-field/QCXFinalTextField';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import QCXSelectFollowUpImportacaoAutocompleteField from '../../../../shared-components/select-follow-up/QCXSelectFollowUpImportacaoAutocompleteField';
import { TipoContaLiUtils } from '../../../../shared-components/select-tipo-conta/tipoContaUtils';
import QCXSimpleConsultPageTemplate from '../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import SimpleConsultPageTemplate from '../../../../templates/simple-consult-page/SimpleConsultPageTemplate/SimpleConsultPageTemplate';
import { TIPO_LI, isTipoFatura, isTipoLi } from '../../../../utils/general/fatura/faturaUtils';
import { formatDate, getCurrentDate, normalizeData } from '../../../../utils/general/general-utils';
import {
  OperationUtils,
  isCalculadaStatus,
  isConferidaStatus,
  // isIntegracaoSiscomexConcluidaStatus,
  isIntegracaoSiscomexErroStatus,
  isIntegradaSiscomex,
  isNaoCalculadaStatus,
} from '../../../../utils/general/operations/operationUtils';
import { ReportUtils } from '../../../../utils/general/report/reportUtils';
import useGenericDownloader from '../../../../utils/hooks/downloader/useGenericDownloader';
import {
  CLOSED_STATUS,
  DONE_STATUS,
  FAIL_STATUS,
  OPEN_STATUS,
  SUBMITTING_STATUS,
  isOpenStatus,
} from '../../../../utils/hooks/form/dialog/formDialogUtils';
import useFormDialogAsync from '../../../../utils/hooks/form/dialog/useFormDialogAsync';
import useFormDialogSync from '../../../../utils/hooks/form/dialog/useFormDialogSync';
import { formatCnpj } from '../../../../utils/hooks/form/field/formatters';
import useOperationMiddleware from '../../../../utils/hooks/operation/middleware/useOperationMiddleware';
import {
  isAlternativeLoadingStatus,
  isCalculateMode,
  isNoneMode,
  isRefresh,
  isRegisterMode,
  isReleaseMode,
  isReversalMode,
  isTransferMode,
} from '../../../../utils/store/store-utils';
import { required } from '../../../../utils/validators/field/validator';

import { clienteActions } from '../../../../features/cliente/clienteSlice';
import QCXSelectClienteAutocomplete from '../../../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXSelectServicoAutocomplete from '../../../../shared-components/select-servico/QCXSelectServicoAutocomplete';
import QCXSelectTipoContaLicencaImportacaoAutocomplete from '../../../../shared-components/select-tipo-conta/QCXSelectTipoContaLicencaImportacaoAutocomplete';
import TransmissaoModal from '../../../../ts/common/components/modals/transmissaoModal';
import useConfigurePopUp from '../../../../ts/common/hooks/popUps/useConfigurePopUp';
import { useGenerateLIXMLReport } from '../../../../ts/common/hooks/useGenerateLIXMLReport.ts';
import { GERARXML } from '../../../../ts/common/hooks/useHasPermission/permissionsEnum.ts';
import { useHasPermission } from '../../../../ts/common/hooks/useHasPermission/useHasPermission.ts';
import { fetchDataTransmissao } from '../../../../ts/common/services/licencaImportacaoApi';
import { RenderDadosLiTransmissao } from '../../../../ts/pages/importacao/licencaImportacao/components/TransmissaoModal/rendarDadosLiTransmissao';
import { MediaType } from '../../../../utils/api/api-utils';
import StatusIcon from './components/StatusIcon';
import { faturaAPI } from '../../../../features/fatura/faturaAPI';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';

export default function LicencaImportacaoConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const [idLi, setIdLi] = useState(null);
  const [titleTransmissaoLi, setTitleTransmissaoLi] = useState(null);

  const [tipoLi, setTipoLi] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [hasDownloaded, setHasDownloaded] = useState(false);
  const [dataTransmissao, setDataTransmissao] = useState(null);
  const [isRegistrada, setIsRegistrada] = useState(false);

  const { data: reportData } = useGenerateLIXMLReport(idLi, tipoLi);

  const canGenerateRegistro = useHasPermission(GERARXML.REGISTROLI);
  const canGenerateSubstitutiva = useHasPermission(GERARXML.SUBSTITUTIVALI);

  const memoizedReportData = useMemo(() => reportData, [reportData]);

  useEffect(() => {
    if (memoizedReportData && !hasDownloaded) {
      saveAs(memoizedReportData, `${idLi ?? 'DI'} - ${tipoLi}.xml`);
      setHasDownloaded(true);
    }
  }, [memoizedReportData]);

  const history = useHistory();

  const genericDownloader = useGenericDownloader({
    autoDownload: true,
  });

  const { status } = useContext(LicencaImportacaoContext);

  const dispatch = useDispatch();

  const mode = useSelector(selectMode);
  const refresh = useSelector(selectRefresh);
  const licencasImportacao = useSelector(selectLicencasImportacao);
  const duplicataRelatedModel = useSelector(selectRelatedDuplicataModel);
  const registroRelatedModel = useSelector(selectRelatedRegistroModel);
  const reportRelatedModel = useSelector(selectRelatedReportModel);
  const historyModel = useSelector(selectHistoryModel);
  const relatedSelectionLicencaImportacaoList = useSelector(selectRelatedSelectionLicencaImportacaoList);

  const pageRole = useMemo(() => 'importacao-li-lpco', []);

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);
  const isLoading = useMemo(() => isCalculateMode(status), [status]);
  const isFinalLoading = useMemo(() => isAlternativeLoading || isLoading, [isAlternativeLoading, isLoading]);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isTransfer = useMemo(() => isTransferMode(mode), [mode]);

  const isReversal = useMemo(() => isReversalMode(mode), [mode]);

  const isCalculate = useMemo(() => isCalculateMode(mode), [mode]);

  const isRelease = useMemo(() => isReleaseMode(mode), [mode]);

  const isRegister = useMemo(() => isRegisterMode(mode), [mode]);

  const isEmptySelectionList = useMemo(
    () =>
      !relatedSelectionLicencaImportacaoList ||
      (_.isArrayLikeObject(relatedSelectionLicencaImportacaoList) && _.isEmpty(relatedSelectionLicencaImportacaoList)),
    [relatedSelectionLicencaImportacaoList]
  );

  const fetchAll = useCallback(() => {
    dispatch(
      fetchFilterPaginateAsync([
        { name: 'page', value: 1 },
        { name: 'size', value: 10 },
        { name: 'numero', value: '' },
      ])
    );
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
    dispatch(resetSubMode());
    dispatch(resetSubBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetRelatedMercadoriaModel());
    dispatch(resetHistoryModel());
  }, []);

  const resetTypes = useCallback(() => {
    dispatch(resetType());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(changeControlPaisTo(IDLE_CONTROL));
    dispatch(changeControlNcmTo(IDLE_CONTROL));
    dispatch(changeControlNaladiShTo(IDLE_CONTROL));
    dispatch(changeControlUnidadeMedidaTo(IDLE_CONTROL));
  }, []);

  const configureDependencies = useCallback(() => {
    dispatch(clienteActions.changeControlTo(IDLE_CONTROL));
  }, []);

  useEffect(() => {
    fetchAll();

    resetModes();
    resetModels();
    resetTypes();
    resetDependencies();
  }, []);

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        // dispatch(fetchAllFromFaturaAsync());
        dispatch(
          fetchFilterPaginateAsync([
            { name: 'page', value: 1 },
            { name: 'size', value: 10 },
            { name: 'numero', value: '' },
          ])
        );
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  useEffect(() => {
    const checkIfExistsQuery = () => {
      if (!isEmpty(historyModel) && historyModel?.last?.processo) {
        dispatch(setQuery(historyModel?.last?.processo));
      }
    };

    checkIfExistsQuery();
  }, [historyModel]);

  useEffect(() => {
    if (isNone && !isEmptySelectionList) {
      dispatch(resetRelatedSelectionLicencaImportacaoList());
    }
  }, [isNone, isEmptySelectionList]);

  const handleConsultarClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        configureDependencies();

        dispatch(changeTypeTo(data?.metaFields?.type));
        dispatch(changeToConsultMode());

        if (isFaturaLiMetaType(data?.metaFields?.type)) {
          history.push(t('com.muralis.qcx.url.importacaoLicencaImportacaoDetalhes', { id: data?.id }));
          return;
        }

        if (isGeneratedLiMetaType(data?.metaFields?.type)) {
          history.push(
            t('com.muralis.qcx.url.importacaoLicencaImportacaoDetalhesOrigem', {
              id: data?.id,
              faturaId: data?.metaFields?.fatura?.id,
            })
          );
        }
      }
    },
    [configureDependencies, history]
  );

  const [handleImprimirClick, formDialogImpressaoStatus, handleFormDialogImpressaoStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();
        dispatch(
          setRelatedReportModel({
            ...data,
            tipo: ReportUtils.DRAFT_LI,
          })
        );
      }
    },
    []
  );

  const handleGerarXMLById = useCallback((event, values) => {
    setTipoLi(values.licencaImportacao.tipo);
    setIdLi(values.licencaImportacao.id);
    setHasDownloaded(false);
  }, []);

  const handleImprimirByIdSubmit = useCallback(
    (values) => {
      handleFormDialogImpressaoStatus(SUBMITTING_STATUS);

      const getTipoRelatorioLabel = (tipo) => {
        if (ReportUtils.isDraftLi(tipo)) {
          return t('com.muralis.qcx.licencaImportacao.DraftLI');
        }
        return '';
      };

      const tipoRelatorioLabel = getTipoRelatorioLabel(values?.tipo);

      const fetcher = async () => {
        if (ReportUtils.isDraftLi(values?.tipo)) {
          return licencaImportacaoAPI.generateReportById(values?.licencaImportacao?.id);
        }
        return undefined;
      };

      const handlers = {
        onSuccess: () => {
          dispatch(resetRelatedReportModel());
          handleFormDialogImpressaoStatus(DONE_STATUS);
          const successMessage = t('com.muralis.qcx.mensagem.relatorioTipoGerado', {
            tipoRelatorio: tipoRelatorioLabel,
            processo: values?.licencaImportacao?.processo,
          });

          dispatch(
            setSuccessFeedback({
              message: successMessage,
            })
          );
        },
        onNoContent: () => {
          handleFormDialogImpressaoStatus(OPEN_STATUS);
          const infoMessage = t('com.muralis.qcx.mensagem.naoFoiPossivelGerarRelatorio', {
            tipoRelatorio: tipoRelatorioLabel,
            processo: values?.licencaImportacao?.processo,
          });

          dispatch(
            setErrorFeedback({
              message: infoMessage,
            })
          );
        },
        onError: (reason) => {
          handleFormDialogImpressaoStatus(FAIL_STATUS);
          const errorMessage = t('com.muralis.qcx.erro.erroGerarRelatorio', {
            tipoRelatorio: tipoRelatorioLabel,
            processo: values?.licencaImportacao?.processo,
            mensagem: `${reason?.response && reason?.response?.data ? `: ${reason?.response?.data?.message}.` : '.'}`,
          });

          dispatch(
            setErrorFeedback({
              message: errorMessage,
            })
          );
        },
      };

      genericDownloader.downloadFileById(fetcher, handlers);
    },
    [handleFormDialogImpressaoStatus]
  );

  const handleCloseFormDialogImpressao = useCallback(
    () => handleFormDialogImpressaoStatus(CLOSED_STATUS),
    [handleFormDialogImpressaoStatus]
  );

  const isFormDialogImpressaoOpen = useMemo(() => isOpenStatus(formDialogImpressaoStatus), [formDialogImpressaoStatus]);

  const formDialogImpressaoContent = useMemo(
    () =>
      t('com.muralis.qcx.selecioneAbaixoTipoRelatorioGerarProcesso', {
        processo: reportRelatedModel?.licencaImportacao?.processo || '#######',
      }),
    [isFormDialogImpressaoOpen, reportRelatedModel]
  );

  const formDialogImpressaoSubmittingFeedback = useMemo(
    () =>
      t('com.muralis.qcx.gerandoRelatorioLI', {
        processo: reportRelatedModel?.licencaImportacao?.processo || '#######',
      }),
    [reportRelatedModel]
  );

  const [handleConferirByIdClickMiddleware, operationOfConferirById] = useOperationMiddleware(
    async (data) => {
      if (isRelease) {
        handleBulkReleaseSubmit({ ids: data?.ids });
        return;
      }

      dispatch(
        conferirByIdFromFaturaAsync({
          id: data?.id,
          fatura: {
            id: data?.metaFields?.fatura?.id,
          },
        })
      );
    },
    [isRelease]
  );

  const handleConfigureConferirByIdClick = useCallback(
    (row, popupState) => {
      const { id, atributosAdicionais, metaFields } = row;

      const handleConferirByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoConferencia').toUpperCase(),
              message: t('com.muralis.qcx.mensagem.confirmarOperacaoLIConferida', {
                processo: atributosAdicionais?.processo,
              }),
              endMessage: t('com.muralis.qcx.mensagem.certezaDesejaConferila'),
            },
          });

          const dataOfOperationConferir = {
            id,
            metaFields,
          };

          handleConferirByIdClickMiddleware(configureOperation, dataOfOperationConferir);
        }
      };

      return handleConferirByIdClick;
    },
    [handleConferirByIdClickMiddleware]
  );

  const [handleDuplicarClick, formDialogDuplicacaoStatus, handleFormDialogDuplicacaoStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        if (isFaturaLiMetaType(data?.metaFields?.type)) {
          dispatch(
            setRelatedDuplicataModel({
              id: data?.id,
              atributosAdicionais: {
                tipo: TIPO_LI,
              },
              meta: {
                type: data?.metaFields?.type,
                from: {
                  processo: data?.processo,
                },
              },
            })
          );

          return;
        }

        if (isGeneratedLiMetaType(data?.metaFields?.type)) {
          dispatch(
            setRelatedDuplicataModel({
              id: data?.id,
              fatura: {
                id: data?.metaFields?.fatura?.id,
              },
              atributosAdicionais: {
                tipo: TIPO_LI,
              },
              meta: {
                type: data?.metaFields?.type,
                from: {
                  processo: data?.processo,
                },
              },
            })
          );
        }
      }
    },
    []
  );

  const [handleActivateOrInactivateByIdClickMiddleware, operationOfActivateOrInactivateById] = useOperationMiddleware(
    async (data) => {
      const isInactivation = data?.active;

      if (isInactivation) {
        dispatch(inactivateByIdFromFaturaAsync(data?.id));

        return;
      }

      dispatch(activateByIdFromFaturaAsync(data?.id));
    },
    [licencasImportacao]
  );

  const handleConfigureActivateOrInactivateByIdClick = useCallback(
    (row, popupState) => {
      const { id, atributosAdicionais, active } = row;

      const handleActivateOrInactivateByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.acoes.confirmarOperacaoExclusao').toUpperCase(),
              message: t('com.muralis.qcx.mensagem.confirmarOperacaoLIConferida', {
                processo: atributosAdicionais?.processo,
                acao: active ? t('com.muralis.qcx.estado.excluida') : t('com.muralis.qcx.estado.recuperada'),
              }),
              endMessage: t('com.muralis.qcx.acoes.confirmacaoAcao', {
                acao: active
                  ? t('com.muralis.qcx.excluila').toLowerCase()
                  : t('com.muralis.qcx.recuperala').toLowerCase(),
              }),
            },
          });

          const dataOfOperationActivateOrInactivate = {
            id,
            active,
          };

          handleActivateOrInactivateByIdClickMiddleware(configureOperation, dataOfOperationActivateOrInactivate);
        }
      };

      return handleActivateOrInactivateByIdClick;
    },
    [handleActivateOrInactivateByIdClickMiddleware]
  );

  const [
    handleAlterarNumeroRegistroSiscomexClick,
    formDialogAlterarNumeroRegistroSiscomexStatus,
    handleFormDialogAlterarNumeroRegistroSiscomexStatus,
  ] = useFormDialogSync(async (event, data) => {
    if (isFunction(event?.stopPropagation)) {
      event.stopPropagation();

      dispatch(
        setRelatedRegistroModel({
          id: data?.id,
          registro: data?.registro,
        })
      );
    }
  }, []);

  const handleAlterarNumeroRegistroSiscomexSubmit = useCallback(
    async (values) => {
      try {
        const responseOfFetch = await licencaImportacaoAPI.fetchById(values?.id);

        if (responseOfFetch?.status === 200) {
          const updated = {
            ...responseOfFetch?.data,
            registro: values?.registro,
          };

          const responseOfSave = await licencaImportacaoAPI.save(updated);

          if (responseOfSave?.status === 200) {
            handleFormDialogAlterarNumeroRegistroSiscomexStatus(DONE_STATUS);
            dispatch(resetRelatedRegistroModel());
            dispatch(refreshList());

            dispatch(success());
            dispatch(
              setSuccessFeedback({
                message: t('com.muralis.qcx.mensagem.registroLISalvo', { processo: updated?.processo }),
              })
            );
          }
        }
      } catch (error) {
        handleFormDialogAlterarNumeroRegistroSiscomexStatus(FAIL_STATUS);

        let errorMessage = t('com.muralis.qcx.erro.erroSalvarNumeroRegistroLI');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroSalvarNumeroRegistroLIEspecifico', {
            erro: error?.response?.data?.message,
          });
        }

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      }
    },
    [handleFormDialogAlterarNumeroRegistroSiscomexStatus]
  );

  const handleCloseFormDialogAlterarNumeroRegistroSiscomex = useCallback(() => {
    handleFormDialogAlterarNumeroRegistroSiscomexStatus(CLOSED_STATUS);
    dispatch(resetRelatedRegistroModel());
  }, [handleFormDialogAlterarNumeroRegistroSiscomexStatus]);

  const isFormDialogAlteracaoNumeroRegistroSiscomexOpen = useMemo(() =>
    isOpenStatus(formDialogAlterarNumeroRegistroSiscomexStatus)
  );

  const formDialogAlteracaoNumeroRegistroSiscomexContent = useMemo(
    () => t('com.muralis.qcx.mensagem.numeroRegistroLISiscomex'),
    []
  );

  const handleDuplicarByProcessoSubmit = useCallback(
    async (values) => {
      let followUpId;

      try {
        followUpId = await handleProcessoNewSubmit({ ...values, fromDuplicar: true });
      } catch (e) {
        handleFormDialogDuplicacaoStatus(FAIL_STATUS);
        return;
      }

      if (isTipoFatura(values?.atributosAdicionais?.tipo)) {
        dispatch(
          duplicarByIdFromFaturaAsync({
            id: values?.id,
            meta: {
              type: values?.meta?.type,
              from: {
                processo: values?.meta?.from?.processo,
              },
            },
            payload: {
              atributosAdicionais: {
                tipo: values?.atributosAdicionais?.tipo,
              },
            },
          })
        );
      }

      if (isTipoLi(values?.atributosAdicionais?.tipo)) {
        dispatch(
          duplicarByIdFromFaturaAsync({
            id: values?.id,
            fatura: {
              id: values?.fatura?.id,
            },
            meta: {
              type: values?.meta?.type,
              from: {
                processo: values?.meta?.from?.processo,
              },
            },
            payload: {
              followUp: {
                id: followUpId,
              },
              atributosAdicionais: {
                tipo: values?.atributosAdicionais?.tipo,
              },
            },
          })
        );
      }

      handleFormDialogDuplicacaoStatus(DONE_STATUS);
    },
    [handleFormDialogDuplicacaoStatus]
  );

  const handleCloseFormDialogDuplicacao = useCallback(
    () => handleFormDialogDuplicacaoStatus(CLOSED_STATUS),
    [handleFormDialogDuplicacaoStatus]
  );

  const isFormDialogDuplicacaoOpen = useMemo(() => isOpenStatus(formDialogDuplicacaoStatus));

  const formDialogDuplicacaoContent = useMemo(
    () =>
      t('com.muralis.qcx.mensagem.preenchaProcessoDuplicarLI', {
        processo: duplicataRelatedModel?.meta?.from?.processo,
      }),
    [duplicataRelatedModel]
  );

  const [handleButtonNewClick, formDialogNewStatus, handleFormDialogNewStatus] = useFormDialogAsync(
    (event) => {
      event.stopPropagation();

      configureDependencies();

      dispatch(changeTypeTo(FATURA_LI_META_TYPE));
      dispatch(changeToCreateMode());

      history.push(t('com.muralis.qcx.url.importacaoLicencaImportacaoNovo'));
    },
    [configureDependencies, history]
  );

  const parseToFormData = useCallback((data) => {
    const { novosArquivos, ...restData } = data;
    const novoArquivoMeta = {
      idioma: null,
      totalItens: null,
      number: null,
      date: null,
    };
    const novosArquivosMeta = [];

    const formData = new FormData();

    if (isArrayLikeObject(novosArquivos)) {
      novosArquivos.forEach((arquivo) => {
        if (arquivo?.fatura?.length > 0) {
          formData.append('files[]', arquivo?.fatura[0]);
          const meta = _.clone(novoArquivoMeta);
          meta.idioma = arquivo?.idioma || null;
          meta.totalItens = arquivo?.totalItens || null;
          meta.number = arquivo?.numeroFatura || null;
          meta.date = arquivo?.dataFatura || null;
          novosArquivosMeta.push(meta);
        }
      });
    }

    if (!isArrayLikeObject(restData?.arquivos)) {
      restData.arquivos = [];
    }

    const json = JSON.stringify(restData);
    const blob = new Blob([json], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append('data', blob);

    const meta = { metadata: novosArquivosMeta };
    const jsonMeta = JSON.stringify(meta);
    const blobMeta = new Blob([jsonMeta], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append('metadata', blobMeta);

    return formData;
  }, []);

  const handleProcessoNewSubmit = useCallback(
    async (values) => {
      try {
        dispatch(loading());

        const clienteId = values?.cliente?.id;
        const servicoId = values?.servico?.id;

        const newFollowUp = {
          numero: values?.processo,
          importador: {
            id: clienteId,
          },
          servico: {
            id: servicoId,
          },
        };
        try {
          const formData = parseToFormData(newFollowUp);
          const response = await followUpAPI.register(formData);
          if (response?.status === 201) {
            const created = response?.data;
            dispatch(
              setProcesso({
                processo: created.numero,
                followUp: created,
              })
            );

            // o ideal seria separar esse handle dessa função,
            // refatorar quando possível
            if (!values.fromDuplicar) {
              handleFormDialogNewStatus(DONE_STATUS);
            }
            dispatch(success());
            return created.id;
          }
        } catch (error) {
          handleFormDialogNewStatus(FAIL_STATUS);
          let errorMessage = t('com.muralis.qcx.erro.erroSalvarNumeroRegistroLI');
          if (error?.response && error?.response?.data) {
            errorMessage = t('com.muralis.qcx.erro.erroSalvarNumeroRegistroLIEspecifico', {
              erro: error?.response?.data?.message,
            });
          }
          dispatch(failure());
          dispatch(
            setErrorFeedback({
              message: errorMessage,
            })
          );
        }
      } catch (error) {
        handleFormDialogNewStatus(FAIL_STATUS);

        let errorMessage = t('com.muralis.qcx.erro.erroCarregarProcesso');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroCarregarProcessoEspecifico', {
            erro: error?.response?.data?.message,
          });
        }

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
        throw error;
      }
      return 0;
    },
    [handleFormDialogNewStatus]
  );

  const handleCloseFormDialogNew = useCallback(
    () => handleFormDialogNewStatus(CLOSED_STATUS),
    [handleFormDialogNewStatus]
  );

  const isFormDialogNewOpen = useMemo(() => isOpenStatus(formDialogNewStatus), [formDialogNewStatus]);

  const isCurrentNotCalculated = useCallback((current) => !OperationUtils.isCalculadaStatus(current?.calculada), []);

  const hasDifferentImporters = useCallback((current, index, array) => {
    if (index === 0) {
      return false;
    }

    const pivot = array[0];

    return current?.importador?.id !== pivot?.importador?.id;
  }, []);

  const hasNoItemsAdded = useCallback(
    (current) => _.isArrayLikeObject(current?.mercadorias) && current?.mercadorias?.length === 0,
    []
  );

  const hasBalanceToTransfer = useCallback((conta) => conta?.saldoAtual > 0, []);

  const canBeTransactionReversed = useCallback(
    (movimentacao) => movimentacao?.estornada === 'NAO_ESTORNADA' && movimentacao?.tipo === 'SAIDA',
    []
  );

  const hasTransactionsToReverse = useCallback(
    (conta) =>
      conta?.movimentacoes && conta?.movimentacoes?.length > 0
        ? conta?.movimentacoes?.some(canBeTransactionReversed)
        : false,
    [canBeTransactionReversed]
  );

  const handleEnableTransferMode = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToTransferMode());
  }, []);

  const [handleContinueToTransfer, formDialogContinueToTransferStatus, handleFormDialogContinueToTransferStatus] =
    useFormDialogAsync(() => {
      dispatch(changeToBackgroundTransferMode());

      history.push(t('com.muralis.qcx.url.importacaoLicencaImportacaoTransferencia'));
    }, [history]);

  const handleContinueToTransferSubmit = useCallback(
    async (values) => {
      dispatch(loading());

      handleFormDialogContinueToTransferStatus(SUBMITTING_STATUS);

      const tipoOrigem = values.tipo.description;

      const mappedSelectionLicencaImportacaoList = licencasImportacao.filter((licencaImportacao) =>
        relatedSelectionLicencaImportacaoList.includes(licencaImportacao?.id)
      );

      const reducerToContaItemList = (previous, selectedLicencaImportacao) => {
        const adaptToContaItem = (item) => ({
          tipo: {
            description: tipoOrigem,
          },
          proprietario: {
            id: item.id,
          },
        });

        const items = selectedLicencaImportacao?.mercadorias?.map(adaptToContaItem) || [];

        return [...previous, ...items];
      };

      const contaItemList = mappedSelectionLicencaImportacaoList?.reduce(reducerToContaItemList, []);

      let suspendExecution = false;

      const response = await mercadoriaMovimentacaoAPI.fetchBalancesByOwners(contaItemList).catch((reason) => {
        const errorMessage = t('com.muralis.qcx.erro.erroConsultarSaldoMercadoriasLI', {
          mensagem: reason?.response && reason?.response?.data ? `: ${reason?.response?.data?.message}.` : '.',
        });

        handleFormDialogContinueToTransferStatus(FAIL_STATUS);

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );

        suspendExecution = true;
      });

      if (suspendExecution) {
        return;
      }

      if (response?.status === 200) {
        const contasEncontradas = response?.data;

        const noBalanceToTransfer = contasEncontradas?.every((conta) => !hasBalanceToTransfer(conta));

        if (noBalanceToTransfer) {
          dispatch(failure());

          handleFormDialogContinueToTransferStatus(FAIL_STATUS);

          dispatch(
            setErrorFeedback({
              message: t('com.muralis.qcx.mensagem.nenhumItemLISaldoInsuficiente'),
            })
          );

          return;
        }

        const mappedMinifiedSelectionLicencaImportacaoList = mappedSelectionLicencaImportacaoList?.map(
          (selectedLicencaImportacao) => ({
            id: selectedLicencaImportacao?.id,
            atributosAdicionais: {
              processo: selectedLicencaImportacao?.atributosAdicionais?.processo,
            },
            importador: {
              id: selectedLicencaImportacao?.importador?.id,
              pessoa: {
                nome: selectedLicencaImportacao?.importador?.pessoa?.nome,
              },
            },
            mercadorias: selectedLicencaImportacao?.mercadorias?.map(
              (itemFromSelectedLicencaImportacao) => itemFromSelectedLicencaImportacao?.id
            ),
          })
        );

        const adaptToItemLicencaImportacao = (conta) => {
          const relatedLicencaImportacao = mappedMinifiedSelectionLicencaImportacaoList?.find(
            (selectedLicencaImportacao) => selectedLicencaImportacao?.mercadorias?.includes(conta?.proprietario?.id)
          );

          return {
            ...conta,
            id: conta?.proprietario?.id,
            tipo: {
              description: conta.tipo.description,
            },
            proprietario: conta?.proprietario,
            saldoInicial: conta?.saldoInicial,
            saldoAtual: conta?.saldoAtual,
            fatura: {
              id: relatedLicencaImportacao?.id,
              atributosAdicionais: {
                tipo: relatedLicencaImportacao?.atributosAdicionais?.tipo,
                processo: relatedLicencaImportacao?.atributosAdicionais?.processo,
                registro: relatedLicencaImportacao?.atributosAdicionais?.registro,
              },
            },
          };
        };

        const availableContas = contasEncontradas?.filter(hasBalanceToTransfer);

        const mappedAvailableContaItemList = (availableContas?.map(adaptToItemLicencaImportacao) || []).sort(
          (contaA, contaB) => {
            const primaryFactorA = contaA?.fatura?.numero;
            const primaryFactorB = contaB?.fatura?.numero;

            const secondaryFactorA = contaA?.proprietario?.item;
            const secondaryFactorB = contaB?.proprietario?.item;

            return primaryFactorA === primaryFactorB
              ? secondaryFactorA - secondaryFactorB
              : primaryFactorA - primaryFactorB;
          }
        );

        const { importador: commonImportador } = mappedMinifiedSelectionLicencaImportacaoList[0];

        const payloadFollowUpsCheck = {
          importador: {
            id: commonImportador?.id,
          },
          tipo: {
            description: tipoOrigem,
          },
        };

        const availableFollowUpsResponse = await mercadoriaMovimentacaoAPI
          .fetchAvailableFollowUpsByImporterAndType(payloadFollowUpsCheck)
          .catch((reason) => {
            const errorMessage = t('com.muralis.qcx.erro.erroConsultarProcessoFollowUpTransferencia', {
              mensagem: reason?.response && reason?.response?.data ? `: ${reason?.response?.data?.message}.` : '.',
            });

            handleFormDialogContinueToTransferStatus(FAIL_STATUS);

            dispatch(failure());
            dispatch(
              setErrorFeedback({
                message: errorMessage,
              })
            );

            suspendExecution = true;
          });

        if (suspendExecution) {
          return;
        }

        if (availableFollowUpsResponse?.status === 200) {
          const availableFollowUps = availableFollowUpsResponse?.data;

          if (_.isArrayLikeObject(availableFollowUps) && _.isEmpty(availableFollowUps)) {
            dispatch(failure());

            handleFormDialogContinueToTransferStatus(FAIL_STATUS);

            dispatch(
              setErrorFeedback({
                message: t('com.muralis.qcx.mensagem.naoFoiEncontradoProcessoDispFollowUpLI', {
                  origem: TipoContaLiUtils.getLabelLiByValue(tipoOrigem),
                  importador: commonImportador?.pessoa?.nome,
                }),
              })
            );

            return;
          }

          dispatch(followUpActions.resetControl());
          dispatch(
            followUpActions.setList({
              data: availableFollowUps,
            })
          );

          dispatch(
            setRelatedTransferenciaModel({
              conta: {
                tipo: {
                  description: tipoOrigem,
                },
              },
            })
          );

          dispatch(setRelatedSelectionAvailableItemList(mappedAvailableContaItemList));

          handleFormDialogContinueToTransferStatus(DONE_STATUS);
        }
      }
    },
    [
      licencasImportacao,
      hasBalanceToTransfer,
      relatedSelectionLicencaImportacaoList,
      handleFormDialogContinueToTransferStatus,
    ]
  );

  const handleCloseFormDialogContinueToTransfer = useCallback(
    () => handleFormDialogContinueToTransferStatus(CLOSED_STATUS),
    [handleFormDialogContinueToTransferStatus]
  );

  const isFormDialogContinueToTransferOpen = useMemo(
    () => isOpenStatus(formDialogContinueToTransferStatus),
    [formDialogContinueToTransferStatus]
  );

  const handleConfirmTransfer = useCallback(
    (event) => {
      event.stopPropagation();

      if (isEmptySelectionList) {
        return;
      }

      const mappedLicencasImportacao = licencasImportacao.filter((fatura) =>
        relatedSelectionLicencaImportacaoList.includes(fatura?.id)
      );

      const licencasImportacaoNaoCalculadas = mappedLicencasImportacao.some(isCurrentNotCalculated);

      if (licencasImportacaoNaoCalculadas) {
        dispatch(
          setWarningFeedback({
            message: t('com.muralis.qcx.mensagem.LISelecionadasCalculadasTransferencia'),
          })
        );

        return;
      }

      const importadoresDiferentes = mappedLicencasImportacao.some(hasDifferentImporters);

      if (importadoresDiferentes) {
        dispatch(
          setWarningFeedback({
            message: t('com.muralis.qcx.mensagem.LISelecionadasMesmoImportador'),
          })
        );

        return;
      }

      const licencasImportacaoSemItensAdicionados = mappedLicencasImportacao.some(hasNoItemsAdded);

      if (licencasImportacaoSemItensAdicionados) {
        dispatch(
          setWarningFeedback({
            message: t('com.muralis.qcx.mensagem.LISelecionadasItensAdicionadosTransferencia'),
          })
        );

        return;
      }

      handleContinueToTransfer();
    },
    [
      licencasImportacao,
      isEmptySelectionList,
      isCurrentNotCalculated,
      hasDifferentImporters,
      hasNoItemsAdded,
      handleContinueToTransfer,
      relatedSelectionLicencaImportacaoList,
    ]
  );

  // Usada para cancelar o calcular, liberar e registrar em lote
  const handleCancelSelectOperation = useCallback((event) => {
    event?.stopPropagation();
    dispatch(resetMode());
  }, []);

  const handleCancelTransfer = useCallback((event) => {
    event.stopPropagation();
    dispatch(resetMode());
  }, []);

  // REVERSAL
  const handleEnableReversalMode = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToReversalMode());
  }, []);

  // CALCULATE
  const handleEnableCalculateMode = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToCalculateMode());
  }, []);

  // RELEASE
  const handleEnableReleaseMode = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToReleaseMode());
  }, []);

  // REGISTER
  const handleEnableRegisterMode = useCallback((event) => {
    event.stopPropagation();
    dispatch(changeToRegisterMode());
  }, []);

  const [handleContinueToReversal, formDialogContinueToReversalStatus, handleFormDialogContinueToReversalStatus] =
    useFormDialogAsync(() => {
      dispatch(changeToBackgroundReversalMode());

      history.push(t('com.muralis.qcx.url.importacaoLicencaImportacaoEstorno'));
    }, [history]);

  const handleContinueToReversalSubmit = useCallback(
    async (values) => {
      dispatch(loading());

      handleFormDialogContinueToReversalStatus(SUBMITTING_STATUS);

      const tipoOrigem = values.tipo.description;

      const mappedSelectionLicencaImportacaoList = licencasImportacao.filter((licencaImportacao) =>
        relatedSelectionLicencaImportacaoList.includes(licencaImportacao?.id)
      );

      const reducerToContaItemList = (previous, selectedLicencaImportacao) => {
        const adaptToContaItem = (item) => ({
          tipo: {
            description: tipoOrigem,
          },
          proprietario: {
            id: item.id,
          },
        });

        const items = selectedLicencaImportacao?.mercadorias?.map(adaptToContaItem) || [];

        return [...previous, ...items];
      };

      const contaItemList = mappedSelectionLicencaImportacaoList?.reduce(reducerToContaItemList, []);

      let suspendExecution = false;

      const response = await mercadoriaMovimentacaoAPI.fetchBalancesByOwners(contaItemList).catch((reason) => {
        const errorMessage = t('com.muralis.qcx.erro.erroConsultarSaldoMercadoriaLIEstorno', {
          mensagem: reason?.response && reason?.response?.data ? `: ${reason?.response?.data?.message}.` : '.',
        });

        handleFormDialogContinueToReversalStatus(FAIL_STATUS);

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );

        suspendExecution = true;
      });

      if (suspendExecution) {
        return;
      }

      if (response?.status === 200) {
        const contasEncontradas = response?.data;

        const noTransactionsToReverse = contasEncontradas?.every((conta) => !hasTransactionsToReverse(conta));

        if (noTransactionsToReverse) {
          dispatch(failure());

          handleFormDialogContinueToReversalStatus(FAIL_STATUS);

          dispatch(
            setErrorFeedback({
              message: t('com.muralis.qcx.erro.nenhumItemLISelecionadaTransferenciaEstornada', {
                origem: TipoContaLiUtils.getLabelLiByValue(tipoOrigem),
              }),
            })
          );

          return;
        }

        const reduceToItemMovimentacaoList = (previous, current) => {
          const adaptToItemMovimentacao = (movimentacao) => ({
            ...current,
            id: movimentacao?.id,
            tipo: movimentacao?.tipo,
            proprietario: current?.proprietario,
            valor: movimentacao?.valor,
            estornada: movimentacao?.estornada,
            operacao: movimentacao?.operacao,
            fatura: {
              id: movimentacao?.fatura?.id,
              atributosAdicionais: {
                tipo: movimentacao?.fatura?.atributosAdicionais?.tipo,
                processo: movimentacao?.fatura?.atributosAdicionais?.processo,
                registro: movimentacao?.fatura?.atributosAdicionais?.registro,
              },
            },
          });

          const mappedItemMovimentacaoList = current?.movimentacoes
            ?.filter(canBeTransactionReversed)
            ?.map(adaptToItemMovimentacao);

          return [...previous, ...mappedItemMovimentacaoList];
        };

        const availableContas = contasEncontradas?.filter(hasTransactionsToReverse);

        const mappedAvailableContaItemList = availableContas
          ?.reduce(reduceToItemMovimentacaoList, [])
          .sort((contaA, contaB) => {
            const primaryFactorA = contaA?.fatura?.atributosAdicionais?.processo;
            const primaryFactorB = contaB?.fatura?.atributosAdicionais?.processo;

            const secondaryFactorA = contaA?.proprietario?.item;
            const secondaryFactorB = contaB?.proprietario?.item;

            return primaryFactorA === primaryFactorB
              ? secondaryFactorA - secondaryFactorB
              : primaryFactorA - primaryFactorB;
          });

        dispatch(
          setRelatedEstornoModel({
            conta: {
              tipo: {
                description: tipoOrigem,
              },
            },
          })
        );

        dispatch(setRelatedSelectionAvailableItemList(mappedAvailableContaItemList));

        handleFormDialogContinueToReversalStatus(DONE_STATUS);
      }
    },
    [
      licencasImportacao,
      canBeTransactionReversed,
      relatedSelectionLicencaImportacaoList,
      handleFormDialogContinueToReversalStatus,
    ]
  );

  const handleCloseFormDialogContinueToReversal = useCallback(
    () => handleFormDialogContinueToReversalStatus(CLOSED_STATUS),
    [handleFormDialogContinueToReversalStatus]
  );

  const isFormDialogContinueToReversalOpen = useMemo(
    () => isOpenStatus(formDialogContinueToReversalStatus),
    [formDialogContinueToReversalStatus]
  );

  const handleConfirmReversal = useCallback(
    (event) => {
      event.stopPropagation();

      if (isEmptySelectionList) {
        return;
      }

      const mappedLicencasImportacao = licencasImportacao.filter((fatura) =>
        relatedSelectionLicencaImportacaoList.includes(fatura?.id)
      );

      const licencasImportacaoNaoCalculadas = mappedLicencasImportacao.some(isCurrentNotCalculated);

      if (licencasImportacaoNaoCalculadas) {
        dispatch(
          setWarningFeedback({
            message: t('com.muralis.qcx.mensagem.LISelecionadasCalaculadasEstorno'),
          })
        );

        return;
      }

      const isImportadoresDiferentes = mappedLicencasImportacao.some(hasDifferentImporters);

      if (isImportadoresDiferentes) {
        dispatch(
          setWarningFeedback({
            message: t('com.muralis.qcx.mensagem.LISelecionadasMesmoImportador'),
          })
        );

        return;
      }

      handleContinueToReversal();
    },
    [
      licencasImportacao,
      isEmptySelectionList,
      isCurrentNotCalculated,
      hasDifferentImporters,
      relatedSelectionLicencaImportacaoList,
      handleContinueToReversal,
    ]
  );

  const handleCancelReversal = useCallback((event) => {
    event.stopPropagation();
    dispatch(resetMode());
  }, []);

  const handleSelectionModelChange = useCallback(
    ({ selectionModel }) => {
      if (isTransfer || isReversal || isCalculate || isRelease || isRegister) {
        dispatch(setRelatedSelectionLicencaImportacaoList(selectionModel));
      }
    },
    [isTransfer, isReversal, isCalculate, isRelease, isRegister]
  );

  const handleConsultarListClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(followUpActions.changeToConsultMode());

        history.push(`/importacao/follow-up/${data.id}/lista`);
      }
    },
    [history]
  );

  const handleMenuItemCrawlerClick = ({ licencaImportacao, statusTransmissao, registrada }) => {
    if (statusTransmissao === undefined || statusTransmissao === 'ERRO') {
      handleRegistroLiCrawlerClick(licencaImportacao);
    } else {
      handleOpenModalTransmissao(licencaImportacao.id, registrada);
    }
  };

  const handleRegistroLiCrawlerClick = useCallback((data) => {
    handleOpenPopUpTransmissaoLi({ args: { data } });
  });

  const [handleOpenPopUpTransmissaoLi, transmissaoLiPopUpConfig] = useConfigurePopUp({
    primaryConfirmCallback: async ({ data }) => {
      dispatch(transmissaoLiByIdAsync({ data }));
    },
    initialOptions: {
      title: t('com.muralis.qcx.transmissaoLi.titlePopUp'),
      message: t('com.muralis.qcx.transmissaoLi.messagePopUp'),
    },
  });

  const handleOpenModalTransmissao = async (id, registrada) => {
    if (id) {
      const response = await fetchDataTransmissao(id);

      if (response.message !== null) {
        dispatch(
          setErrorFeedback({
            message: t(`${response.message}`),
          })
        );
      } else {
        setIsRegistrada(registrada);
        setDataTransmissao(response.data);
        setTitleTransmissaoLi(id);
        setIsModalOpen(true);
      }
    }
  };

  const [handleCalcularClick, formDialogCalculoStatus, handleFormDialogCalculoStatus] = useFormDialogSync(
    async (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(
          setRelatedCalculoModel({
            id: data?.id,
            ids: data?.ids,
            calculada: data?.calculada,
            atributosAdicionais: {
              tipo: 'LI',
              processo: data?.atributosAdicionais?.processo,
            },
            active: data?.active,
          })
        );
      }
    },
    []
  );

  const handleConfigureCalcularByIdClick = useCallback(
    (row, popupState) => {
      const { id, calculada, atributosAdicionais } = row;

      const handleCalcularByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          handleCalcularClick(event, {
            id,
            calculada,
            atributosAdicionais,
          });
        }
      };

      return handleCalcularByIdClick;
    },
    [handleCalcularClick]
  );

  const handleConfirmCalculate = useCallback(
    (event) => {
      event.stopPropagation();

      if (isEmptySelectionList) {
        return;
      }

      const mappedLicencasImportacao = licencasImportacao.filter((fatura) =>
        relatedSelectionLicencaImportacaoList.includes(fatura?.id)
      );

      const idsToCalculate = mappedLicencasImportacao.map((fatura) => {
        if (isCalculadaStatus(fatura?.calculada)) {
          return fatura?.metaFields?.fatura?.id;
        }

        return fatura?.id;
      });

      // remove duplicated ids
      const uniqueIdsToCalculate = [...new Set(idsToCalculate)];

      handleCalcularClick(event, {
        ids: uniqueIdsToCalculate,
        calculada: 'NAO_CALCULADA', // Se for necessario verificar se alguma fatura não está calculada
      });
    },
    [licencasImportacao, isEmptySelectionList, handleCalcularClick, relatedSelectionLicencaImportacaoList]
  );

  const calculoRelatedModel = useSelector(selectRelatedCalculoModel);

  const formDialogCalculoContent = useMemo(
    () =>
      t('com.muralis.qcx.mensagem.inserirDataCalcularRecalcularDIDUIMP', {
        acao: isNaoCalculadaStatus(calculoRelatedModel?.calculada)
          ? t('com.muralis.qcx.acoes.calcular').toLowerCase()
          : t('com.muralis.qcx.acoes.recalcular').toLowerCase(),
        processo: calculoRelatedModel?.atributosAdicionais?.processo,
      }),
    [calculoRelatedModel]
  );

  const isFormDialogCalculoOpen = useMemo(() => isOpenStatus(formDialogCalculoStatus));

  const handleCloseFormDialogCalculo = useCallback(
    () => handleFormDialogCalculoStatus(CLOSED_STATUS),
    [handleFormDialogCalculoStatus]
  );

  const handleSuccessBulkOperation = useCallback(() => {
    handleFormDialogCalculoStatus(DONE_STATUS);
    dispatch(resetMode());
    dispatch(setRelatedSelectionLicencaImportacaoList([]));
    fetchAll();
  });

  const handleBulkCalculateSubmit = useCallback(
    (values) => {
      const data = values?.ids.map((id) => ({
        id,
        dataCalculo: normalizeData(values?.atributosAdicionais?.dataCalculo),
      }));
      handleCloseFormDialogCalculo();
      dispatch(loadingLI());
      dispatch(calculateLisEmLoteAsync({ data, successCallback: handleSuccessBulkOperation }));
    },
    [licencasImportacao, handleFormDialogCalculoStatus]
  );

  const handleBulkReleaseSubmit = useCallback(async (values) => {
    dispatch(loadingLI());
    dispatch(releaseLisEmLoteAsync({ data: values?.ids, successCallback: handleSuccessBulkOperation }));
  }, []);

  const handleCalcularByDataSubmit = useCallback(
    async (values) => {
      try {
        dispatch(loading());
        handleFormDialogCalculoStatus(SUBMITTING_STATUS);

        const payload = {
          atributosAdicionais: {
            tipo: values?.atributosAdicionais?.tipo,
          },
          dataCalculo: normalizeData(values?.atributosAdicionais?.dataCalculo),
        };

        const response = await faturaAPI.calculateById(values?.id, ['li'], payload);

        if (response.status === 200) {
          const isNaoCalculada = isNaoCalculadaStatus(values?.calculada);
          const processo = values?.atributosAdicionais?.processo;
          const responseMessage = t('com.muralis.qcx.mensagem.DIEstado', {
            processo,
            estado: isNaoCalculada ? t('com.muralis.qcx.estado.calculada') : t('com.muralis.qcx.estado.recalculada'),
          });

          dispatch(success());
          dispatch(
            updateOnList({
              data: response?.data,
            })
          );
          dispatch(setSuccessFeedback({ message: responseMessage }));

          if (isNaoCalculada) {
            dispatch(
              changeLastHistoryModel({
                processo: values?.atributosAdicionais?.processo,
              })
            );
          }

          fetchAll();

          handleFormDialogCalculoStatus(DONE_STATUS);
        }
      } catch (error) {
        handleFormDialogCalculoStatus(FAIL_STATUS);

        const isUnknownError =
          isEmpty(error?.response) || isEmpty(error?.response?.data) || isEmpty(error?.response?.data?.message);

        const isNaoCalculada = isNaoCalculadaStatus(values?.calculada);
        const errorMessage = isUnknownError
          ? t('com.muralis.qcx.erro.erroCalcularRecalcular', {
              estado: isNaoCalculada ? t('com.muralis.qcx.acoes.calcular') : t('com.muralis.qcx.acoes.recalcular'),
            })
          : t('com.muralis.qcx.erro.erroCalcularRecalcularEspecifico', {
              estado: isNaoCalculada ? t('com.muralis.qcx.acoes.calcular') : t('com.muralis.qcx.acoes.recalcular'),
              mensagem: error?.response?.data?.message,
            });

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      }
    },
    [licencasImportacao, handleFormDialogCalculoStatus]
  );

  const defaultDateValue = useMemo(() => getCurrentDate(), []);

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('com.muralis.qcx.processo'),
        headerAlign: 'center',
        align: 'center',
        flex: 180,
        valueGetter: ({ row }) => row?.atributosAdicionais?.processo || '-',
      },
      {
        field: 'importador',
        headerName: t('com.muralis.qcx.importador.label'),
        flex: 140,
        valueGetter: ({ row: { importador } }) => importador?.pessoa?.nomeResumido || '-',
      },
      {
        field: 'cnpj',
        headerName: t('com.muralis.qcx.empresa.CNPJ'),
        flex: 80,
        valueGetter: ({ row: { importador } }) => formatCnpj(importador?.pessoa?.cnpj, true) || '-',
      },
      {
        field: 'atributosAdicionais.registro',
        headerName: t('com.muralis.qcx.NumeroLI'),
        flex: 100,
        valueGetter: ({ row }) => row?.atributosAdicionais?.registro || '-',
      },
      {
        field: 'atributosAdicionais.data',
        headerName: t('com.muralis.qcx.data'),
        flex: 100,
        valueGetter: ({ row }) => formatDate(row?.atributosAdicionais?.data) || '-',
      },
      {
        field: 'numeroLiOriginal',
        headerName: 'Nº LI Original',
        flex: 100,
        valueGetter: ({ row }) => row?.numeroLiOriginal || '-',
      },
      {
        field: 'dataLiOriginal',
        headerName: 'Data LI Original',
        flex: 100,
        valueGetter: ({ row }) => formatDate(row?.dataLiOriginal) || '-',
      },
      {
        field: 'status',
        headerName: t('com.muralis.qcx.status'),
        flex: 120,
        valueGetter: ({ row }) => row?.atributosAdicionais?.status || '-',
      },
      {
        field: 'calculada',
        headerName: t('com.muralis.qcx.calculada'),
        headerAlign: 'center',
        align: 'center',
        type: 'boolean',
        flex: 100,
        valueGetter: ({ row }) => isCalculadaStatus(row?.calculada) || !!row?.atributosAdicionais?.registro,
      },
      {
        field: 'atributosAdicionais.conferida',
        headerName: t('com.muralis.qcx.conferida'),
        headerAlign: 'center',
        align: 'center',
        type: 'boolean',
        flex: 100,
        valueGetter: ({ row: { atributosAdicionais } }) =>
          isConferidaStatus(atributosAdicionais?.conferida) || !!atributosAdicionais?.registro,
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'center',
        flex: 140,
        renderCell: ({ row: { id, calculada, atributosAdicionais, active, metaFields, numeroLiOriginal } }) => (
          <>
            <QCXPopupState popupId="popup-menu-li">
              {(popupState) => (
                <>
                  <Tooltip title="Visualizar Lista" arrow>
                    <IconButton
                      key={`btn-lista-view-${id}`}
                      name={`btn-lista-view-${id}`}
                      onClick={(event) => {
                        const followUp = atributosAdicionais?.followUp;
                        handleConsultarListClick(event, followUp);
                      }}
                    >
                      <GridOn color="secondary" size={20} />
                    </IconButton>
                  </Tooltip>
                  <IconButton
                    key={`btn-more-options-${id}`}
                    name={`btn-more-options-${id}`}
                    disabled={isTransfer || isReversal}
                    {...bindTrigger(popupState)}
                  >
                    <MoreHorizIcon
                      color={
                        isTransfer || isReversal || isCalculate || isRelease || isRegister ? 'disabled' : 'secondary'
                      }
                      size={20}
                    />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleConsultarClick(event, {
                          id,
                          metaFields,
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <PageviewIcon fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);
                        handleImprimirClick(event, {
                          licencaImportacao: {
                            id,
                            processo: atributosAdicionais?.processo,
                          },
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <PrintIcon color="primary" fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.imprimir').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      disabled={
                        !canGenerateRegistro ||
                        !(isCalculadaStatus(calculada) && isConferidaStatus(atributosAdicionais?.conferida)) ||
                        isIntegradaSiscomex(
                          atributosAdicionais?.registro,
                          atributosAdicionais?.integracaoRegistroSiscomex
                        )
                      }
                      onClick={(event) => {
                        handleGerarXMLById(event, {
                          licencaImportacao: {
                            id,
                            processo: atributosAdicionais?.processo,
                            tipo: 'LI',
                          },
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <CodeIcon color="primary" fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {'Gerar XML de Registro'.toUpperCase()}
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      disabled={
                        !canGenerateSubstitutiva ||
                        !(
                          isCalculadaStatus(calculada) &&
                          isConferidaStatus(atributosAdicionais?.conferida) &&
                          !!numeroLiOriginal
                        )
                      }
                      onClick={(event) => {
                        handleGerarXMLById(event, {
                          licencaImportacao: {
                            id,
                            processo: atributosAdicionais?.processo,
                            tipo: 'SUBSTITUTIVA',
                          },
                        });
                      }}
                    >
                      <QCXListItemIcon>
                        <CodeIcon color="primary" fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {'Gerar XML Substitutiva'.toUpperCase()}
                      </Typography>
                    </MenuItem>

                    <MenuItem
                      disabled={
                        !canGenerateRegistro ||
                        !(isCalculadaStatus(calculada) && isConferidaStatus(atributosAdicionais?.conferida))
                      }
                      onClick={(event) => {
                        popupState.close(event);
                        handleMenuItemCrawlerClick({
                          licencaImportacao: {
                            id,
                            processo: atributosAdicionais?.processo,
                            tipo: 'LI',
                          },
                          statusTransmissao: atributosAdicionais.statusTransmissao,
                          registrada: isIntegradaSiscomex(
                            atributosAdicionais?.registro,
                            atributosAdicionais?.integracaoRegistroSiscome
                          ),
                        });
                      }}
                    >
                      <StatusIcon statusTransmissao={atributosAdicionais?.statusTransmissao} />
                    </MenuItem>

                    {userHasUpdateRole(authInfo, pageRole) && (
                      <>
                        <MenuItem
                          onClick={handleConfigureCalcularByIdClick(
                            {
                              id,
                              calculada,
                              atributosAdicionais,
                              active,
                              metaFields,
                            },
                            popupState
                          )}
                          disabled={
                            isIntegradaSiscomex(
                              atributosAdicionais?.registro,
                              atributosAdicionais?.integracaoRegistroSiscomex
                            ) || isIntegracaoSiscomexErroStatus(atributosAdicionais?.integracaoRegistroSiscomex)
                          }
                        >
                          <QCXListItemIcon>
                            <FaCalculatorIcon color="green" size={20} />
                          </QCXListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {isNaoCalculadaStatus(calculada)
                              ? t('com.muralis.qcx.acoes.calcular').toUpperCase()
                              : t('com.muralis.qcx.acoes.recalcular').toUpperCase()}
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={handleConfigureConferirByIdClick(
                            {
                              id,
                              atributosAdicionais,
                              metaFields,
                            },
                            popupState
                          )}
                          disabled={
                            isNaoCalculadaStatus(calculada) || isConferidaStatus(atributosAdicionais?.conferida)
                          }
                        >
                          <QCXListItemIcon>
                            <DoneAllIcon
                              fontSize="small"
                              htmlColor={
                                isNaoCalculadaStatus(calculada) || isConferidaStatus(atributosAdicionais?.conferida)
                                  ? 'rgba(0, 0, 0, 0.38)'
                                  : 'green'
                              }
                            />
                          </QCXListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {t('com.muralis.qcx.acoes.conferir').toUpperCase()}
                          </Typography>
                        </MenuItem>
                        {isCalculadaStatus(calculada) && isConferidaStatus(atributosAdicionais?.conferida) && (
                          <MenuItem
                            onClick={(event) => {
                              popupState.close(event);
                              handleAlterarNumeroRegistroSiscomexClick(event, {
                                id,
                                registro: atributosAdicionais?.registro,
                              });
                            }}
                          >
                            <QCXListItemIcon>
                              <ConfirmationNumberIcon fontSize="small" color="secondary" />
                            </QCXListItemIcon>
                            <Typography
                              variant="inherit"
                              style={{
                                fontSize: 12,
                              }}
                            >
                              {atributosAdicionais?.registro
                                ? t('com.muralis.qcx.acoes.alterarRegistro').toUpperCase()
                                : t('com.muralis.qcx.acoes.informarRegistro').toUpperCase()}
                            </Typography>
                          </MenuItem>
                        )}
                        <MenuItem
                          onClick={(event) => {
                            popupState.close(event);
                            handleDuplicarClick(event, {
                              id,
                              processo: atributosAdicionais?.processo,
                              metaFields,
                            });
                          }}
                        >
                          <QCXListItemIcon>
                            <FileCopyIcon fontSize="small" color="primary" />
                          </QCXListItemIcon>
                          <Typography
                            variant="inherit"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {t('com.muralis.qcx.acoes.duplicar').toUpperCase()}
                          </Typography>
                        </MenuItem>
                      </>
                    )}
                    <MenuItem
                      disabled={false}
                      onClick={(event) => {
                        dispatch(gerarSubstitutivaAsync({ id, faturaId: metaFields?.fatura?.id }));
                      }}
                    >
                      <QCXListItemIcon>
                        <FileCopyIcon color="primary" fontSize="small" />
                      </QCXListItemIcon>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {'Gerar Substitutiva'.toUpperCase()}
                      </Typography>
                    </MenuItem>
                    {isFaturaLiMetaType(metaFields?.type) && userHasDeleteRole(authInfo, pageRole) && (
                      <MenuItem
                        onClick={handleConfigureActivateOrInactivateByIdClick(
                          {
                            id,
                            atributosAdicionais,
                            active,
                          },
                          popupState
                        )}
                        disabled={!active}
                      >
                        <QCXListItemIcon>
                          <DeleteIcon
                            fontSize="small"
                            color={active && isFaturaLiMetaType(metaFields?.type) ? 'error' : 'disabled'}
                          />
                        </QCXListItemIcon>
                        <Typography
                          variant="inherit"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {t('com.muralis.qcx.acoes.excluir').toUpperCase()}
                        </Typography>
                      </MenuItem>
                    )}
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    [
      isTransfer,
      isReversal,
      isCalculate,
      isRelease,
      isRegister,
      handleConsultarClick,
      handleConfigureCalcularByIdClick,
      handleConfigureConferirByIdClick,
      handleAlterarNumeroRegistroSiscomexClick,
      handleDuplicarClick,
      handleConfigureActivateOrInactivateByIdClick,
    ]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloImportacao'),
          name: t('com.muralis.qcx.importacao.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.licencasImportacaoLI'),
        },
        default: true,
      },
    ],
    []
  );

  const handleConfigChange = useCallback(({ currentPage, pageSize, filterInputValue }) => {
    dispatch(
      fetchFilterPaginateAsync([
        { name: 'page', value: currentPage },
        { name: 'size', value: pageSize },
        { name: 'numero', value: filterInputValue },
      ])
    );
  }, []);

  const rowsCount = useSelector(selectCountLi);

  const handleConfirmRelease = useCallback(
    (event) => {
      event?.stopPropagation();

      if (isEmptySelectionList) {
        return;
      }

      const mappedLicencasImportacao = licencasImportacao.filter((fatura) =>
        relatedSelectionLicencaImportacaoList.includes(fatura?.id)
      );

      // if not calculada then error
      const licencasImportacaoNaoCalculadas = mappedLicencasImportacao.some(isCurrentNotCalculated);

      if (licencasImportacaoNaoCalculadas) {
        dispatch(
          setErrorFeedback({
            message: "Não é possivel liberar LI's não calculadas.",
          })
        );

        return;
      }

      const idsToRelease = mappedLicencasImportacao.map((fatura) => ({
        id: fatura?.metaFields?.fatura?.id,
        lis: [{ id: fatura?.id }],
      })); // deve enviar a fatura contendo o id e a li com o id para o back

      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.acoes.confirmarOperacaoConferencia').toUpperCase(),
          message: "Ao confirmar a operação as LI's serão conferidas",
          endMessage: 'Tem certeza que deseja conferi-las?',
        },
      });

      handleConferirByIdClickMiddleware(configureOperation, { ids: idsToRelease });
    },
    [licencasImportacao, isEmptySelectionList, isCurrentNotCalculated, relatedSelectionLicencaImportacaoList]
  );

  const handleConfirmRegister = useCallback(
    (event) => {
      event.stopPropagation();

      if (isEmptySelectionList) {
        return;
      }

      const mappedLicencasImportacao = licencasImportacao.filter((fatura) =>
        relatedSelectionLicencaImportacaoList.includes(fatura?.id)
      );

      const payloadToGerar = mappedLicencasImportacao.map((fatura) => ({
        id: fatura?.id,
      }));

      dispatch(loadingLI());
      dispatch(gerarXMLLisEmLoteAsync({ data: payloadToGerar, successCallback: handleSuccessBulkOperation }));
      dispatch(success());
    },
    [isEmptySelectionList, relatedSelectionLicencaImportacaoList, licencasImportacao]
  );

  const handleConfirmSelection = useCallback(
    (event) => {
      if (isTransfer) {
        handleConfirmTransfer(event);
      }

      if (isReversal) {
        handleConfirmReversal(event);
      }

      if (isCalculate) {
        handleConfirmCalculate(event);
      }

      if (isRelease) {
        handleConfirmRelease(event);
      }

      if (isRegister) {
        handleConfirmRegister(event);
      }
    },
    [
      isTransfer,
      isReversal,
      isCalculate,
      isRelease,
      isRegister,
      handleConfirmTransfer,
      handleConfirmReversal,
      handleConfirmCalculate,
    ]
  );

  const handleCancelSelection = useCallback(
    (event) => {
      if (isTransfer) {
        handleCancelTransfer(event);
      }

      if (isReversal) {
        handleCancelReversal(event);
      }

      if (isCalculate) {
        handleCancelSelectOperation(event);
      }

      if (isRelease) {
        handleCancelSelectOperation(event);
      }

      if (isRegister) {
        handleCancelSelectOperation(event);
      }
    },
    [isTransfer, isReversal, isCalculate, isRelease, isRegister]
  );

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.licencasImportacaoLI'),
        icon: <ReceiptIcon />,
        breadcrumbs,
      },
      control: {
        ...(isNone
          ? {
              buttonNew: {
                description: t('com.muralis.qcx.licencaImportacao.novoRegistroManualLi'),
                onClick: handleButtonNewClick,
              },
            }
          : {}),
        others: [
          ...(isNone
            ? [
                {
                  description: 'Transferir',
                  startIcon: <SyncAltIcon />,
                  color: 'secondary',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.transferir'),
                  },
                  onClick: handleEnableTransferMode,
                },
                {
                  description: 'Estornar',
                  startIcon: <RotateLeftIcon />,
                  color: 'secondary',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.estornar'),
                  },
                  onClick: handleEnableReversalMode,
                },
                {
                  description: 'Calcular',
                  startIcon: <RotateLeftIcon />,
                  tooltip: {
                    description: "Calcular LI's em Lote",
                  },
                  onClick: handleEnableCalculateMode,
                },
                {
                  description: 'Conferir',
                  startIcon: <RotateLeftIcon />,
                  tooltip: {
                    description: "Transferir LI's em Lote",
                  },
                  onClick: handleEnableReleaseMode,
                },
                {
                  description: 'Registrar',
                  startIcon: <RotateLeftIcon />,
                  tooltip: {
                    description: "Registrar LI's em Lote",
                  },
                  onClick: handleEnableRegisterMode,
                },
              ]
            : []),
          ...(isTransfer || isReversal || isCalculate || isRelease || isRegister
            ? [
                {
                  description: t('com.muralis.qcx.acoes.confirmarSelecao'),
                  startIcon: <CheckCircleIcon />,
                  color: 'secondary',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.confirmarSelecaoDestino', {
                      destino: isTransfer
                        ? t('com.muralis.qcx.transferencia.envioTransferencia')
                        : t('com.muralis.qcx.estorno.envioEstorno'),
                    }),
                  },
                  onClick: handleConfirmSelection,
                  disabled: isEmptySelectionList,
                },
                {
                  description: <CancelOutlinedIcon />,
                  variant: 'outlined',
                  color: 'default',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.cancelar'),
                  },
                  onClick: handleCancelSelection,
                },
              ]
            : []),
        ],
      },
      table: {
        columns,
        rowsCount,
        onConfigChangeCallback: handleConfigChange,
        checkboxSelection: isTransfer || isReversal || isCalculate || isRelease || isRegister,
        selectionModel: relatedSelectionLicencaImportacaoList,
        onSelectionModelChange: handleSelectionModelChange,
      },
    }),
    [
      columns,
      rowsCount,
      breadcrumbs,
      isNone,
      isTransfer,
      isReversal,
      isCalculate,
      isRelease,
      isRegister,
      isEmptySelectionList,
      relatedSelectionLicencaImportacaoList,
      handleConfigChange,
      handleSelectionModelChange,
      handleButtonNewClick,
      handleEnableTransferMode,
      handleConfirmTransfer,
      handleCancelTransfer,
      handleEnableReversalMode,
      handleConfirmReversal,
      handleCancelReversal,
    ]
  );

  /*
    SimpleConsultPageTemplate
  */

  return (
    <SimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={licencasImportacao}
      isLoading={isFinalLoading}
      authInfo={authInfo}
      requiredRoles={[pageRole]}
    >
      <QCXFinalFormDialog
        key="form-dialog-processo-registro-manual-li"
        id="form-dialog-processo-registro-manual-li"
        title={t('com.muralis.qcx.licencaImportacao.novoRegistroManualLi')}
        content={t('com.muralis.qcx.preencherCampoContinuar')}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.continuar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogNewOpen}
        initialValues={{}}
        onSubmit={handleProcessoNewSubmit}
        onClose={handleCloseFormDialogNew}
      >
        {() => (
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12}>
              <QCXMoveFocusInside>
                <QCXFinalTextField
                  id="text-field-processo"
                  key="text-field-processo"
                  name="processo"
                  label={t('com.muralis.qcx.processo')}
                  validate={required}
                />
              </QCXMoveFocusInside>
            </Grid>
            <Grid item xs={12}>
              <QCXSelectClienteAutocomplete
                id="autocomplete-select-cliente-field"
                key="autocomplete-select-cliente-field"
                name="cliente.id"
                label={t('com.muralis.qcx.cliente.label')}
                initialValues={undefined}
              />
            </Grid>
            <Grid item xs={12}>
              <QCXSelectServicoAutocomplete
                id="autocomplete-select-servico-field"
                key="autocomplete-select-servico-field"
                name="servico.id"
                label={t('com.muralis.qcx.servico.labelSingular')}
                initialValues={undefined}
                fieldProps={{ validate: required }}
                produto="LI"
                required
              />
            </Grid>
          </Grid>
        )}
      </QCXFinalFormDialog>
      <QCXFinalFormDialog
        key="form-dialog-alteracao-numero-registro-li"
        id="form-dialog-alteracao-numero-registro-li"
        title={t('com.muralis.qcx.numeroRegistroSiscomexLI')}
        content={formDialogAlteracaoNumeroRegistroSiscomexContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.salvar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogAlteracaoNumeroRegistroSiscomexOpen}
        initialValues={registroRelatedModel}
        onSubmit={handleAlterarNumeroRegistroSiscomexSubmit}
        onClose={handleCloseFormDialogAlterarNumeroRegistroSiscomex}
      >
        {() => (
          <QCXMoveFocusInside>
            <QCXFinalTextField
              key="numero-registro-field"
              id="numero-registro-field"
              name="registro"
              label={t('com.muralis.qcx.numeroRegistro')}
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
      <QCXFinalFormDialog
        key="form-dialog-duplicacao-li"
        id="form-dialog-duplicacao-li"
        title={t('com.muralis.qcx.duplicacaoLicencaImportacaoLI')}
        content={formDialogDuplicacaoContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.duplicar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogDuplicacaoOpen}
        initialValues={duplicataRelatedModel}
        onSubmit={handleDuplicarByProcessoSubmit}
        onClose={handleCloseFormDialogDuplicacao}
      >
        {() => (
          <Grid container xs={12} spacing={2}>
            <Grid item xs={12}>
              <QCXMoveFocusInside>
                <QCXFinalTextField
                  id="text-field-processo"
                  key="text-field-processo"
                  name="processo"
                  label={t('com.muralis.qcx.processo')}
                  validate={required}
                />
              </QCXMoveFocusInside>
            </Grid>
            <Grid item xs={12}>
              <QCXSelectClienteAutocomplete
                id="autocomplete-select-cliente-field"
                key="autocomplete-select-cliente-field"
                name="cliente.id"
                label={t('com.muralis.qcx.cliente.label')}
                initialValues={undefined}
              />
            </Grid>
            <Grid item xs={12}>
              <QCXSelectServicoAutocomplete
                id="autocomplete-select-servico-field"
                key="autocomplete-select-servico-field"
                name="servico.id"
                label={t('com.muralis.qcx.servico.labelSingular')}
                initialValues={undefined}
                fieldProps={{ validate: required }}
                produto="LI"
                required
              />
            </Grid>
          </Grid>
        )}
      </QCXFinalFormDialog>
      <QCXFinalFormDialog
        key="form-dialog-licenca-importacao-tranferencia"
        id="form-dialog-licenca-importacao-tranferencia"
        title={t('com.muralis.qcx.transferencia.transferenciaDestino')}
        // content="Preencha o campo abaixo para continuar."
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.continuar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogContinueToTransferOpen}
        initialValues={{
          tipo: {
            description: TipoContaLiUtils.TIPO_LI_DI,
          },
        }}
        onSubmit={handleContinueToTransferSubmit}
        onClose={handleCloseFormDialogContinueToTransfer}
        disablePristine
      >
        {() => (
          <QCXMoveFocusInside>
            <QCXSelectTipoContaLicencaImportacaoAutocomplete
              id="select-field-tipo-conta-licenca-importacao-transferencia"
              key="select-field-tipo-conta-licenca-importacao-transferencia"
              name="tipo.description"
              label={t('com.muralis.qcx.transferencia.destino')}
              fieldProps={{
                validate: required,
              }}
              required
              disabled
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
      <QCXFinalFormDialog
        key="form-dialog-licenca-importacao-estorno"
        id="form-dialog-licenca-importacao-estorno"
        title={t('com.muralis.qcx.estorno.origemItens')}
        // content="Preencha o campo abaixo para continuar."
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.continuar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogContinueToReversalOpen}
        initialValues={{
          tipo: {
            description: TipoContaLiUtils.TIPO_LI_DI,
          },
        }}
        onSubmit={handleContinueToReversalSubmit}
        onClose={handleCloseFormDialogContinueToReversal}
        disablePristine
      >
        {() => (
          <QCXMoveFocusInside>
            <QCXSelectTipoContaLicencaImportacaoAutocomplete
              id="select-field-tipo-conta-licenca-importacao-estorno"
              key="select-field-tipo-conta-licenca-importacao-estorno"
              name="tipo.description"
              label={t('com.muralis.qcx.origem')}
              fieldProps={{
                validate: required,
              }}
              required
              disabled
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
      <QCXFinalFormDialog
        key="form-dialog-impressao-li"
        id="form-dialog-impressao-li"
        title={t('com.muralis.qcx.licencaImportacao.relatorioLi')}
        content={formDialogImpressaoContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.gerar'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogImpressaoOpen}
        initialValues={reportRelatedModel}
        onSubmit={handleImprimirByIdSubmit}
        onClose={handleCloseFormDialogImpressao}
        disablePristine
      >
        {() => (
          <Grid container>
            <Grid item xs={12}>
              <QCXFinalRadioField
                id="checkbox-tipo-relatorio-li"
                key="checkbox-tipo-relatorio-li"
                name="tipo"
                label={t('com.muralis.qcx.licencaImportacao.DraftLI')}
                value={ReportUtils.DRAFT_LI}
                required
              />
            </Grid>
          </Grid>
        )}
      </QCXFinalFormDialog>
      <QCXFinalFormDialog
        key="form-dialog-calculo-li"
        id="form-dialog-calculo-li"
        title="Confirmar Operação de Cálculo"
        content={formDialogCalculoContent}
        dialogActionsProps={{
          button: {
            submit: {
              description: isNaoCalculadaStatus(calculoRelatedModel?.calculada)
                ? t('com.muralis.qcx.acoes.calcular')
                : t('com.muralis.qcx.acoes.recalcular'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={isFormDialogCalculoOpen}
        initialValues={calculoRelatedModel}
        onSubmit={isCalculate ? handleBulkCalculateSubmit : handleCalcularByDataSubmit}
        onClose={handleCloseFormDialogCalculo}
      >
        {() => (
          <QCXMoveFocusInside>
            <QCXFinalDatePickerField
              id="date-picker-data-calculo-field"
              key="date-picker-data-calculo-field"
              name="atributosAdicionais.dataCalculo"
              label={t('com.muralis.qcx.dataCalculo')}
              defaultValue={defaultDateValue}
              validate={required}
              required
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
      <QCXConfirmDialog
        key="confirm-dialog-conferencia-li"
        id="confirm-dialog-conferencia-li"
        open={operationOfConferirById?.active}
        title={operationOfConferirById?.title}
        content={operationOfConferirById?.message}
        endContent={operationOfConferirById?.endMessage}
        onConfirm={operationOfConferirById.confirm}
        onClose={operationOfConferirById?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-activate-inactivate-li"
        id="confirm-dialog-activate-inactivate-li"
        open={operationOfActivateOrInactivateById?.active}
        title={operationOfActivateOrInactivateById?.title}
        content={operationOfActivateOrInactivateById?.message}
        endContent={operationOfActivateOrInactivateById?.endMessage}
        onConfirm={operationOfActivateOrInactivateById.confirm}
        onClose={operationOfActivateOrInactivateById?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-transmissao-crawler-li"
        id="confirm-dialog-transmissao-crawler-li"
        open={transmissaoLiPopUpConfig?.isPopUpOpen}
        title={transmissaoLiPopUpConfig?.title}
        content={transmissaoLiPopUpConfig?.message}
        endContent={transmissaoLiPopUpConfig?.endMessage}
        onConfirm={transmissaoLiPopUpConfig.confirmPrimaryAction}
        onClose={transmissaoLiPopUpConfig?.reset}
      />

      <TransmissaoModal
        disabled={isRegistrada}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        title={`${t('com.muralis.qcx.extracaoDiagnostico.tituloExtracao')}: ${titleTransmissaoLi}`}
        buttonTitle={t('com.muralis.qcx.transmissaoLi.transmitirNovamente')}
        onConfirm={() => {
          setIsModalOpen(false);
          handleRegistroLiCrawlerClick({ id: titleTransmissaoLi, tipo: 'LI' });
        }}
      >
        {dataTransmissao ? <RenderDadosLiTransmissao transmissao={dataTransmissao} /> : null}
      </TransmissaoModal>

      <QCXGenericDownloader status={formDialogImpressaoStatus} message={formDialogImpressaoSubmittingFeedback} />
    </SimpleConsultPageTemplate>
  );
}
