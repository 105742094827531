import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';

import { CLOSED_STATUS, isOpenStatus } from '../../utils/hooks/form/dialog/formDialogUtils';
import QCXFinalAlternativeCurrencyField from '../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';

export default function QCXPartialValuesDialogForm({
  handleOnSubmit,
  handleFormStatus,
  formDialogStatus,
  initialValues,
}) {
  const { t } = useTranslation();

  useEffect(() => {}, [initialValues]);

  const isFormDialogOpen = useMemo(() => isOpenStatus(formDialogStatus), [formDialogStatus]);

  const handleCloseFormDialogNew = useCallback(() => {
    handleFormStatus(CLOSED_STATUS);
    _.debounce(() => {}, 200)();
  }, [handleFormStatus]);

  return (
    <QCXFinalFormDialog
      key="form-dialog-relatorio-conta-pagar-receber"
      id="form-dialog-relatorio-conta-pagar-receber"
      title="Receber Valor Parcial"
      dialogActionsProps={{
        button: {
          submit: {
            description: 'Receber',
            size: 'small',
            color: 'secondary',
          },
          cancel: {
            description: t('com.muralis.qcx.acoes.cancelar'),
            size: 'small',
          },
        },
      }}
      open={isFormDialogOpen}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      onClose={handleCloseFormDialogNew}
    >
      {() => (
        <>
          <Grid>
            <QCXFinalAlternativeCurrencyField name="valorParcial" label="Valor Recebido" required />
          </Grid>
          <Grid style={{ paddingTop: '1rem' }}>
            <QCXFinalDatePickerField name="dataPagamento" label="Data Pagamento" required />
          </Grid>
        </>
      )}
    </QCXFinalFormDialog>
  );
}
