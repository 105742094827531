import { Grid, makeStyles } from '@material-ui/core';
import { some } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectMoedas } from '../../../features/moeda/moedaSlice';
import QCXInfoAlert from '../../../shared-components/alert/QCXInfoAlert';
import QCXFormStepWizard from '../../../shared-components/form-step-wizard/QCXFormStepWizard';
import useFaturaTotaisListeners from '../../../utils/general/fatura/faturaTotaisListeners';
import { BRAZILIAN } from '../../../utils/hooks/form/field/mask-types';
import useValidators from '../../../utils/hooks/useValidators';
import { isUpdateMode } from '../../../utils/store/store-utils';
import { maxValue } from '../../../utils/validators/field/validator';
import QCXFormSubtitle from '../../form-title/QCXFormSubtitle';
import QCXFormTitle from '../../form-title/QCXFormTitle';
import QCXDeclaracaoImportacaoTotaisFormGroup from '../QCXDeclaracaoImportacaoTotaisFormGroup';
import { acrescimoDeducaoDeclaracaoImportacaoSelectors } from '../../../features/acrescimo-deducao-declaracao-importacao/acrescimoDeducaoDeclaracaoImportacaoSlice';
import AcrescimoDeducaoBondManager from './components/AcrescimoDeducaoBondManager';
import QCXButton from '../../../shared-components/button/QCXButton';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  moedaInternacionalFieldLeft: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '5px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingBottom: '16px',
    },
  },
  moedaInternacionalFieldRight: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '11px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
}));

export default function QCXDeclaracaoImportacaoTotaisForm({
  initialValues,
  handleSubmit,
  isConsult,
  parentModel,
  controlComponentProps,
  openAskRepassarMoedasPopUp,
  openAskLimparAcrescimosDeducoes,
  ...restProps
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  const acrescimoDeducaoStatus = useSelector(acrescimoDeducaoDeclaracaoImportacaoSelectors.selectStatus);
  const moedas = useSelector(selectMoedas);

  const isAcrescimoDeducaoUpdate = useMemo(() => isUpdateMode(acrescimoDeducaoStatus), [acrescimoDeducaoStatus]);

  const adicoes = parentModel?.atributosAdicionais?.adicoes;

  const hasAdicoesAcrescimosDeducoes = some(
    adicoes.map((a) => {
      const resultado = a.acrescimosDeducoes.length > 0 && some(a.acrescimosDeducoes.map((d) => !d.sofreuRateio));
      return resultado;
    })
  );

  const [onBlurObjectHandlers, faturaTotaisChangeListeners] = useFaturaTotaisListeners(isConsult, moedas);

  const handleMoedaAcrescimoDeducaoIdChange = useCallback(
    (form) => (value) => {
      if (!(isConsult || isAcrescimoDeducaoUpdate)) {
        if (!value) {
          form.change('ignorableFields.acrescimoDeducao.valorMoeda', '');
          form.change('ignorableFields.acrescimoDeducao.valorReal', '');

          form.resetFieldState('ignorableFields.acrescimoDeducao.valorMoeda');
          form.resetFieldState('ignorableFields.acrescimoDeducao.valorReal');
        } else {
          form.change('ignorableFields.acrescimoDeducao.valorReal', '');
          form.resetFieldState('ignorableFields.acrescimoDeducao.valorReal');
        }
      }
    },
    [isConsult, isAcrescimoDeducaoUpdate]
  );

  const changeListeners = useMemo(
    () => [
      ...faturaTotaisChangeListeners,
      {
        name: 'ignorableFields.acrescimoDeducao.moeda.id',
        fn: handleMoedaAcrescimoDeducaoIdChange,
      },
    ],
    [handleMoedaAcrescimoDeducaoIdChange, faturaTotaisChangeListeners]
  );

  const valorValidators = useMemo(() => useValidators([maxValue(999999999999.99)]), []);

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      onChangeListeners={changeListeners}
      disablePristine
      {...restProps}
    >
      {({ submitSucceeded }) => (
        <>
          <QCXFormTitle title={t('com.muralis.qcx.fatura.fichaTotais')} />
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            <QCXDeclaracaoImportacaoTotaisFormGroup
              intl={BRAZILIAN}
              classes={classes}
              isConsult={isConsult}
              valorValidators={valorValidators}
              submitSucceeded={submitSucceeded}
              onBlurObjectHandlers={onBlurObjectHandlers}
              initialValues={initialValues}
              openAskRepassarMoedasPopUp={openAskRepassarMoedasPopUp}
            />
            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.acrescimosDeducoes')} />
            </Grid>
            {hasAdicoesAcrescimosDeducoes && (
              <Grid
                item
                xs={12}
                style={{
                  marginTop: '0.5rem',
                  paddingBottom: '!important 0px',
                }}
              >
                <QCXInfoAlert>{t('com.muralis.qcx.inclusaoAcrescimosDeducoesValorTotaisDesabilitada')}</QCXInfoAlert>
              </Grid>
            )}
            <Grid item xs={12}>
              <QCXButton
                color="secondary"
                variant="contained"
                onClick={() => openAskLimparAcrescimosDeducoes()}
                style={{ margin: '0 10px 0 0' }}
                disabled={isConsult}
              >
                Excluir Acréscimos/Deduções
              </QCXButton>
            </Grid>
            <AcrescimoDeducaoBondManager
              name="atributosAdicionais.acrescimosDeducoes"
              hasAdicoesAcrescimosDeducoes={hasAdicoesAcrescimosDeducoes}
              classes={classes}
              isConsult={isConsult}
              onBlurObjectHandlers={onBlurObjectHandlers}
            />
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
