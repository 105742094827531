import { combineReducers } from '@reduxjs/toolkit';
import acordoAladiReducer from '../features/acordo-aladi/acordoAladiSlice';
import acrescimoDeducaoDeclaracaoImportacaoReducer from '../features/acrescimo-deducao-declaracao-importacao/acrescimoDeducaoDeclaracaoImportacaoSlice';
import acrescimoValoracaoAduaneiraReducer from '../features/acrescimo-valoracao-aduaneira/acrescimoValoracaoAduaneiraSlice';
import aprovarRecusarPagamentoSlice from '../features/aprovar-recusar-pagamento/aprovarRecusarPagamentoSlice';
import armazemDeclaracaoImportacaoReducer from '../features/armazem-declaracao-importacao/armazemDeclaracaoImportacaoSlice';
import armazemReducer from '../features/armazens/armazemSlice';
import atributoNveDeclaracaoImportacaoReducer from '../features/atributo-nve-declaracao-importacao/atributoNveDeclaracaoImportacaoSlice';
import bancoReducer from '../features/banco/BancoSlice';
import catalogoProdutosReducer from '../features/catalogo-produtos/catalogoProdutosSlice';
import cfopOperacaoFiscalReducer from '../features/cfop-operacoes-fiscais/cfopOperacoesFiscaisSlice';
import cidadeReducer from '../features/cidade/cidadeSlice';
import clienteReducer from '../features/cliente/clienteSlice';
import cnaeReducer from '../features/cnae/cnaeSlice';
import conciliacaoBancariaReducer from '../features/conciliacao-bancaria/conciliacaoBancariaSlice';
import codigoReceitaReducer from '../features/codigo-receita/codigoReceitaSlice';
import contaBancariaReducer from '../features/conta-bancaria/contaBancariaSlice';
import contaContabilReducer from '../features/conta-contabil/contaContabilSlice';
import contaPagarReducer from '../features/contas-pagar/contasPagarSlice';
import contaReceberReducer from '../features/contas-receber/contasReceberSlice';
import controllerReducer from '../features/controller/controllerSlice';
import fornecedorFavorecidoReducer from '../features/fornecedor-favorecido/FornecedorSlice';
import cotacaoMoedaReducer from '../features/cotacao-moeda/cotacaoMoedaSlice';
import danfeReducer from '../features/danfe/danfeSlice';
import danfeVolumesReducer from '../features/danfe-volumes/danfeVolumesSlice';
import declaracaoImportacaoReducer from '../features/declaracao-importacao/declaracaoImportacaoSlice';
import declaracaoUnicaExportacaoReducer from '../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSlice';
import despachanteReducer from '../features/despachante/despachanteSlice';
import despesaReceitaReducer from '../features/despesa-receita/despesaReceitaSlice';
import detalhamentoOperacaoSemNotaFiscalReducer from '../features/detalhamento-operacao-sem-nota-fiscal/detalhamentoOperacaoSemNotaFiscalSlice';
import dispositivoLegalReducer from '../features/dispositivo-legal/dispositivoLegalSlice';
import documentoInstrucaoDespachoDeclaracaoImportacaoReducer from '../features/documento-instrucao-despacho-declaracao-importacao/documentoInstrucaoDespachoDeclaracaoImportacaoSlice';
import documentoInstrucaoDespachoReducer from '../features/documento-instrucao-despacho/documentoInstrucaoDespachoSlice';
import downloaderReducer from '../features/downloader/downloaderSlice';
import embalagemDeclaracaoImportacaoReducer from '../features/embalagem-declaracao-importacao/embalagemDeclaracaoImportacaoSlice';
import embarcacoesSlice from '../features/embarcacoes/embarcacoesSlice';
import empresaEstrangeiraReducer from '../features/empresa-estrangeira/empresaEstrangeiraSlice';
import enquadramentoOperacaoReducer from '../features/enquadramento-operacao/enquadramentoOperacaoSlice';
import estadoReducer from '../features/estado/estadoSlice';
import etapaFollowUpReducer from '../features/etapa-follow-up/etapaFollowUpSlice';
import etapaReducer from '../features/etapa/etapaSlice';
import ocorrenciaReducer from '../pages/module/registrations/general/ocorrencias/api/ocorrenciaSlice';
import desviosReducer from '../pages/module/registrations/general/desvios/api/desvioSlice';
import deducaoValoracaoAduaneiraReducer from '../features/deducao-valoracao-aduaneira/deducaoValoracaoAduaneiraSlice';
import faturaReducer from '../features/fatura/faturaSlice';
import faturamentoReducer from '../features/faturamento/faturamentoSlice';
import feedbackReducer from '../features/feedback/feedbackSlice';
import focusLockReducer from '../features/focus-lock/focusLockSlice';
import followUpExportacaoReducer from '../features/follow-up-exportacao/followUpExportacaoSlice';
import followUpDestinoFaturamentoReducer from '../features/follow-up-destino-faturamento/followUpDestinoFaturamentoSlice';
import followUpOrigemFaturamentoReducer from '../features/follow-up-origem-faturamento/followUpOrigemFaturamentoSlice';
import followUpReducer from '../features/follow-up/followUpSlice';
import followUpStorageIdiomaReducer from '../features/follow-up-storage-idioma/followUpStorageIdiomaSlice';
import formaExportacaoReducer from '../features/forma-exportacao/formaExportacoSlice';
import fundamentacaoEspecificaReducer from '../features/fundamentacao-especifica/fundamentacaoEspecificaSlice';
import fundamentoLegalPisCofinsReducer from '../features/fundamento-legal-pis-cofins/fundamentoLegalPisCofinsSlice';
import fundamentoLegalReducaoPisCofinsReducer from '../features/fundamento-legal-reducao-pis-cofins/fundamentoLegalReducaoPisCofinsSlice';
import fundamentoLegalRegimeTributacaoIiReducer from '../features/fundamento-legal-regime-tributacao-ii/fundamentoLegalRegimeTributacaoIiSlice';
import grupoUnidadeNegocioReducer from '../features/grupo-unidade-negocio/grupoUnidadeNegocioSlice';
import incotermCondicaoVendaReducer from '../features/incoterm-condicao-venda/incotermCondicaoVendaSlice';
import inspectorReducer from '../features/inspector/inspectorSlice';
import instituicaoFinanciadoraReducer from '../features/instituicao-financiadora/instituicaoFinanciadoraSlice';
import licencaImportacaoReducer from '../features/licenca-importacao/licencaImportacaoSlice';
import metodoValoracaoReducer from '../features/metodo-valoracao/metodoValoracaoSlice';
import modalidadePagamentoReducer from '../features/modalidade-pagamento/modalidadePagamentoSlice';
import modeloDanfeReducer from '../features/modelo-danfe/modeloDanfeSlice';
import modeloFaturamentoReducer from '../features/modelo-faturamento/modeloFaturamentoSlice';
import modeloFollowUpReducer from '../features/modelo-follow-up/modeloFollowUpSlice';
import modeloInformacoesComplementaresReducer from '../features/modelo-informacoes-complementares/modeloInformacoesComplementaresSlice';
import modeloNotaFiscalReducer from '../features/modelo-nota-fiscal/modeloNotaFiscalSlice';
import modeloNumerarioReducer from '../features/modelo-numerario/modeloNumerarioSlice';
import moedaReducer from '../features/moeda/moedaSlice';
import motivoAdmissaoTemporariaReducer from '../features/motivo-admissao-temporaria/motivoAdmissaoTemporariaSlice';
import motivoImportacaoSemCoberturaCambialReducer from '../features/motivo-importacao-sem-cobertura-cambial/motivoImportacaoSemCoberturaCambialSlice';
import naladiNccaReducer from '../features/naladi-ncca/naladiNccaSlice';
import naladiShReducer from '../features/naladi-sh/naladiShSlice';
import ncmSubitemReducer from '../features/ncm-subitem/ncmSubitemSlice';
import notificacaoReducer from '../features/notificacao/notificacaoSlice';
import numerarioImportacaoReducer from '../features/numerario-importacao/numerarioImportacaoSlice';
import nveAtributoReducer from '../features/nve-atributo/nveAtributoSlice';
import ncmExportacaoReducer from '../features/ncm-exportacao/ncmExportacaoSlice';
import ncmAtributoExportacaoReducer from '../features/ncm-exportacao/ncmAtributoExportacaoSlice';
import ncmDominioExportacaoReducer from '../features/ncm-exportacao/ncmDominioExportacaoSlice';
import nveEspecificacaoReducer from '../features/nve-especificacao/nveEspecificacaoSlice';
import nveReducer from '../features/nve/nveSlice';
import orgaoAnuenteReducer from '../features/orgao-anuente/orgaoAnuenteSlice';
import orgaoEmissorReducer from '../features/orgao-emissor/orgaoEmissorSlice';
import paisReducer from '../features/pais/paisSlice';
import perfilReducer from '../features/perfil/perfilSlice';
import planoContaReducer from '../features/plano-conta/planoContaSlice';
import portoAeroportoReducer from '../features/porto-aeroporto/PortoAeroportoSlice';
import recintoAduaneiroReducer from '../features/recinto-aduaneiro/recintoAduaneiroSlice';
import regimeTributacaoReducer from '../features/regime-tributacao/regimeTributacaoSlice';
import servicoReducer from '../features/servico/servicoSlice';
import setorLotacaoReducer from '../features/setor-lotacao/setorLotacaoSlice';
import situacaoEspecialDespachoReducer from '../features/situacao-especial-despacho/situacaoEspecialDespachoSlice';
import solicitacaoPagamentoReducer from '../features/solicitacao-pagamento/solicitacaoPagamentoSlice';
import tabelaArmazenagemReducer from '../features/tabela-armazenagem/tabelaArmazenagemSlice';
import tabelaSdaReducer from '../features/tabela-sda/tabelaSdaSlice';
import taxaConversaoCambioReducer from '../features/taxa-conversao-cambio/taxaConversaoCambioSlice';
import tipoAtoConcessorioReducer from '../features/tipo-ato-concessorio/tipoAtoConcessorioSlice';
import tipoAtoLegalReducer from '../features/tipo-ato-legal/tipoAtoLegalSlice';
import tipoCalculoReducer from '../features/tipo-calculo/tipoCalculoSlice';
import tipoCEReducer from '../features/tipo-ce/tipoCESlice';
import tipoContainerReducer from '../features/tipo-container/tipoContainerSlice';
import tipoDeclaracaoReducer from '../features/tipo-declaracao/tipoDeclaracaoSlice';
import tipoDocumentoReducer from '../features/tipo-documento/tipoDocumentoSlice';
import tipoDocumentoDueReducer from '../features/tipo-documento-due/tipoDocumentoDueSlice';
import tipoDocumentoDueConsignacaoReducer from '../features/tipo-documento-due-consignacao/tipoDocumentoDueConsignacaoSlice';
import tipoDocumentoDueTemporariaReducer from '../features/tipo-documento-due-temporaria/tipoDocumentoDueTemporariaSlice';
import tipoDocumentoFiscalReducer from '../features/tipo-documento-fiscal/tipoDocumentoFiscalSlice';
import tipoEmbalagemNacionalReducer from '../features/tipo-embalagem-nacional/tipoEmbalagemNacionalSlice';
import tipoRecipienteReducer from '../features/tipo-recipiente/tipoRecipienteSlice';
import transportadoraReducer from '../features/transportadora/transportadoraSlice';
import transportadorInternacionalReducer from '../features/transportador-internacional/transportadorInternacionalSlice';
import tratamentoPrioritarioReducer from '../features/tratamento-prioritario/tratamentoPrioritarioSlice';
import tratamentoTributarioReducer from '../features/tratamento-tributario/tratamentoTributarioSlice';
import unidadeDeMedidaReducer from '../features/unidade-medida/unidadeDeMedidaSlice';
import unidadeLocalRfbReducer from '../features/unidade-local-rfb/unidadeLocalRfbSlice';
import unidadeNegocioReducer from '../features/unidade-negocio/unidadeNegocioSlice';
import usuarioLogadoReducer from '../features/usuario-logado/usuarioLogadoSlice';
import usuarioReducer from '../features/usuario/usuarioSlice';
import viaEspecialTransporteReducer from '../features/via-especial-transporte/viaEspecialTransporteSlice';
import viaTransporteReducer from '../features/via-transporte/viaTransporteSlice';
import vinculoAtoConcessorioDUEReducer from '../features/vinculo-ato-concessorio-due/vinculoAtoConcessorioDUESlice';
import vinculoAnexoFaturaFollowUpReducer from '../features/vinculo-anexo-fatura-follow-up/vinculoAnexoFaturaFollowUpSlice';
import vinculoDespesaReceitaReducer from '../features/vinculo-despesa-receita/vinculoDespesaReceitaSlice';
import vinculoFollowUpCapaNumerarioReducer from '../features/vinculo-follow-up-capa-numerario/vinculoFollowUpCapaNumerarioSlice';
import vinculoFollowUpReducer from '../features/vinculo-follow-up/vinculoFollowUpSlice';
import vinculoLPCODUEReducer from '../features/vinculo-lpco-due/vinculoLPCODUESlice';
import vinculoMercadoriaAmparadaPorDUEReducer from '../features/vinculo-mercadoria-amparada-por-due/vinculoMercadoriaAmparadaPorDUESlice';
import vinculoProcessoDeclaracaoImportacaoReducer from '../features/vinculo-processo-declaracao-importacao/vinculoProcessoDeclaracaoImportacaoSlice';
import vinculoProcessoLicencaImportacaoReducer from '../features/vinculo-processo-licenca-importacao/vinculoProcessoLicencaImportacaoSlice';
import vinculoReferenciaClienteFollowUpReducer from '../features/vinculo-referencia-cliente-follow-up/vinculoReferenciaClienteFollowUpSlice';
import vinculoDocumentoImportacaoDUEReducer from '../features/vinculo-documento-importacao-due/vinculoDocumentoImportacaoDUESlice';
import vinculoNotaFiscalDUEReducer from '../features/vinculo-nota-fiscal-due/vinculoNotaFiscalDUESlice';
import vinculoOperacaoExportacaoConsignacaoVinculadaDUEReducer from '../features/vinculo-operacao-exportacao-consignacao-vinculada-due/vinculoOperacaoExportacaoConsignacaoVinculadaDUESlice';
import vinculoOperacaoExportacaoTemporariaVinculadaDUEReducer from '../features/vinculo-operacao-exportacao-temporaria-vinculada-due/vinculoOperacaoExportacaoTemporariaVinculadaDUESlice';
import vinculoFollowUpCapaFaturamentoReducer from '../features/vinculo-followup-capa-faturamento/vinculoFollowUpCapaFaturamentoSlice';
import exportacaoEstrangeiraDeclaracaoImportacaoReducer from '../features/exportacao-estrangeira-declaracao-importacao/exportacaoEstrangeiraDeclaracaoImportacaoSlice';
import camposAdicionaisReducer from '../pages/module/registrations/general/campos-adicionais/api/camposAdicionaisSlice';
import dadosEmbarqueImportacaoReducer from '../pages/module/import/dados-embarque/api/dadosEmbarqueSlice';
import dadosEmbarqueExportacaoReducer from '../pages/module/export/dados-embarque/api/dadosEmbarqueSlice';
import custeiosReducer from '../ts/pages/importacao/custeios/api/custeiosSlice.ts';
import applicationStateReducer from '../features/application-state/applicationStateSlice.ts';
import demandaOcrReducer from '../features/demanda-ocr/demandaOcrSlice.ts';
import documentoAnexacaoReducer from '../features/documento-anexacao/documentoAnexacaoSlice';

const reducer = combineReducers({
  applicationState: applicationStateReducer,
  acordoAladi: acordoAladiReducer,
  acrescimoDeducaoDeclaracaoImportacao: acrescimoDeducaoDeclaracaoImportacaoReducer,
  acrescimoValoracaoAduaneira: acrescimoValoracaoAduaneiraReducer,
  aprovarRecusarPagamento: aprovarRecusarPagamentoSlice,
  armazem: armazemReducer,
  armazemDeclaracaoImportacao: armazemDeclaracaoImportacaoReducer,
  exportacaoEstrangeiraDeclaracaoImportacao: exportacaoEstrangeiraDeclaracaoImportacaoReducer,
  atributoNveDeclaracaoImportacao: atributoNveDeclaracaoImportacaoReducer,
  banco: bancoReducer,
  catalogoProdutos: catalogoProdutosReducer,
  cfopOperacaoFiscal: cfopOperacaoFiscalReducer,
  cidade: cidadeReducer,
  cliente: clienteReducer,
  cnae: cnaeReducer,
  codigoReceita: codigoReceitaReducer,
  conciliacaoBancaria: conciliacaoBancariaReducer,
  contaBancaria: contaBancariaReducer,
  contaContabil: contaContabilReducer,
  contasPagar: contaPagarReducer,
  contasReceber: contaReceberReducer,
  controller: controllerReducer,
  cotacaoMoeda: cotacaoMoedaReducer,
  custeiosImportacao: custeiosReducer,
  dadosEmbarqueImportacao: dadosEmbarqueImportacaoReducer,
  dadosEmbarqueExportacao: dadosEmbarqueExportacaoReducer,
  danfe: danfeReducer,
  danfeVolumes: danfeVolumesReducer,
  declaracaoImportacao: declaracaoImportacaoReducer,
  declaracaoUnicaExportacao: declaracaoUnicaExportacaoReducer,
  deducaoValoracaoAduaneira: deducaoValoracaoAduaneiraReducer,
  demandaOcr: demandaOcrReducer,
  despachante: despachanteReducer,
  despesaReceita: despesaReceitaReducer,
  desvio: desviosReducer,
  detalhamentoOperacaoSemNotaFiscal: detalhamentoOperacaoSemNotaFiscalReducer,
  dispositivoLegal: dispositivoLegalReducer,
  documentoAnexacao: documentoAnexacaoReducer,
  documentoInstrucaoDespacho: documentoInstrucaoDespachoReducer,
  documentoInstrucaoDespachoDeclaracaoImportacao: documentoInstrucaoDespachoDeclaracaoImportacaoReducer,
  downloader: downloaderReducer,
  embalagemDeclaracaoImportacao: embalagemDeclaracaoImportacaoReducer,
  embarcacao: embarcacoesSlice,
  empresaEstrangeira: empresaEstrangeiraReducer,
  enquadramentoOperacao: enquadramentoOperacaoReducer,
  estado: estadoReducer,
  etapa: etapaReducer,
  ocorrencia: ocorrenciaReducer,
  camposAdicionais: camposAdicionaisReducer,
  etapaFollowUp: etapaFollowUpReducer,
  fatura: faturaReducer,
  faturamento: faturamentoReducer,
  feedback: feedbackReducer,
  focusLock: focusLockReducer,
  followUp: followUpReducer,
  followUpExportacao: followUpExportacaoReducer,
  followUpDestinoFaturamento: followUpDestinoFaturamentoReducer,
  followUpOrigemFaturamento: followUpOrigemFaturamentoReducer,
  followUpStorageIdioma: followUpStorageIdiomaReducer,
  formaExportacao: formaExportacaoReducer,
  fornecedorFavorecido: fornecedorFavorecidoReducer,
  fundamentacaoEspecifica: fundamentacaoEspecificaReducer,
  fundamentoLegalPisCofins: fundamentoLegalPisCofinsReducer,
  fundamentoLegalReducaoPisCofins: fundamentoLegalReducaoPisCofinsReducer,
  fundamentoLegalRegimeTributacaoIi: fundamentoLegalRegimeTributacaoIiReducer,
  grupoUnidadeNegocio: grupoUnidadeNegocioReducer,
  incotermCondicaoVenda: incotermCondicaoVendaReducer,
  inspector: inspectorReducer,
  instituicaoFinanciadora: instituicaoFinanciadoraReducer,
  licencaImportacao: licencaImportacaoReducer,
  metodoValoracao: metodoValoracaoReducer,
  modalidadePagamento: modalidadePagamentoReducer,
  modeloDanfe: modeloDanfeReducer,
  modeloFaturamento: modeloFaturamentoReducer,
  modeloFollowUp: modeloFollowUpReducer,
  modeloInformacoesComplementares: modeloInformacoesComplementaresReducer,
  modeloNotaFiscal: modeloNotaFiscalReducer,
  modeloNumerario: modeloNumerarioReducer,
  moeda: moedaReducer,
  motivoAdmissaoTemporaria: motivoAdmissaoTemporariaReducer,
  motivoImportacaoSemCoberturaCambial: motivoImportacaoSemCoberturaCambialReducer,
  naladiNcca: naladiNccaReducer,
  naladiSh: naladiShReducer,
  ncmSubitem: ncmSubitemReducer,
  notificacao: notificacaoReducer,
  numerarioImportacao: numerarioImportacaoReducer,
  tipoDocumento: tipoDocumentoReducer,
  ncmExportacao: ncmExportacaoReducer,
  ncmAtributoExportacao: ncmAtributoExportacaoReducer,
  ncmDominioExportacao: ncmDominioExportacaoReducer,
  nveAtributo: nveAtributoReducer,
  nve: nveReducer,
  nveEspecificacao: nveEspecificacaoReducer,
  orgaoAnuente: orgaoAnuenteReducer,
  orgaoEmissor: orgaoEmissorReducer,
  pais: paisReducer,
  perfil: perfilReducer,
  planoConta: planoContaReducer,
  portoAeroporto: portoAeroportoReducer,
  recintoAduaneiro: recintoAduaneiroReducer,
  regimeTributacao: regimeTributacaoReducer,
  servico: servicoReducer,
  setorLotacao: setorLotacaoReducer,
  situacaoEspecialDespacho: situacaoEspecialDespachoReducer,
  solicitacaoPagamento: solicitacaoPagamentoReducer,
  tabelaArmazenagem: tabelaArmazenagemReducer,
  tabelaSda: tabelaSdaReducer,
  taxaConversaoCambio: taxaConversaoCambioReducer,
  tipoAtoConcessorio: tipoAtoConcessorioReducer,
  tipoAtoLegal: tipoAtoLegalReducer,
  tipoCalculo: tipoCalculoReducer,
  tipoCE: tipoCEReducer,
  tipoContainer: tipoContainerReducer,
  tipoDeclaracao: tipoDeclaracaoReducer,
  tipoDocumentoDue: tipoDocumentoDueReducer,
  tipoDocumentoDueConsignacao: tipoDocumentoDueConsignacaoReducer,
  tipoDocumentoDueTemporaria: tipoDocumentoDueTemporariaReducer,
  tipoDocumentoFiscal: tipoDocumentoFiscalReducer,
  tipoEmbalagemNacional: tipoEmbalagemNacionalReducer,
  tipoRecipiente: tipoRecipienteReducer,
  transportadora: transportadoraReducer,
  transportadorInternacional: transportadorInternacionalReducer,
  tratamentoPrioritario: tratamentoPrioritarioReducer,
  tratamentoTributario: tratamentoTributarioReducer,
  unidadeLocalRfb: unidadeLocalRfbReducer,
  unidadeMedida: unidadeDeMedidaReducer,
  unidadeNegocio: unidadeNegocioReducer,
  usuario: usuarioReducer,
  usuarioLogado: usuarioLogadoReducer,
  viaEspecialTransporte: viaEspecialTransporteReducer,
  viaTransporte: viaTransporteReducer,
  vinculoAtoConcessorioDUE: vinculoAtoConcessorioDUEReducer,
  vinculoAnexoFaturaFollowUp: vinculoAnexoFaturaFollowUpReducer,
  vinculoDespesaReceita: vinculoDespesaReceitaReducer,
  vinculoFollowUp: vinculoFollowUpReducer,
  vinculoFollowUpCapaNumerario: vinculoFollowUpCapaNumerarioReducer,
  vinculoLPCODUE: vinculoLPCODUEReducer,
  vinculoMercadoriaAmparadaPorDUE: vinculoMercadoriaAmparadaPorDUEReducer,
  vinculoProcessoDeclaracaoImportacao: vinculoProcessoDeclaracaoImportacaoReducer,
  vinculoProcessoLicencaImportacao: vinculoProcessoLicencaImportacaoReducer,
  vinculoReferenciaClienteFollowUp: vinculoReferenciaClienteFollowUpReducer,
  vinculoDocumentoImportacaoDUE: vinculoDocumentoImportacaoDUEReducer,
  vinculoNotaFiscalDUE: vinculoNotaFiscalDUEReducer,
  vinculoOperacaoExportacaoConsignacaoVinculadaDUE: vinculoOperacaoExportacaoConsignacaoVinculadaDUEReducer,
  vinculoOperacaoExportacaoTemporariaVinculadaDUE: vinculoOperacaoExportacaoTemporariaVinculadaDUEReducer,
  vinculoFollowUpCapaFaturamento: vinculoFollowUpCapaFaturamentoReducer,
});

export default reducer;
