import { Container, Grid, Typography } from '@material-ui/core';
import { ViewList as ViewListIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import QCXFormTitle from '../../../../../../components/form-title/QCXFormTitle';
import QCXMainLayout from '../../../../../../components/main-layout/QCXMainLayout';
import LogEntry from './components/LogEntry';
import breadcrumbs from './constants/breacrumbs';
import useLogs from './hooks/useLogs';

export default function CatalogoProdutosLogsPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { logs } = useLogs(id);

  return (
    <QCXMainLayout
      pageTitle={t('com.muralis.qcx.alteracoes')}
      pageIcon={<ViewListIcon />}
      breadcrumbs={breadcrumbs}
      breadcrumbsProps={{
        maxItems: 6,
      }}
      authInfo={authInfo}
    >
      <Container style={{ padding: '0rem 3rem 3rem 3rem' }}>
        <QCXFormTitle title={t('com.muralis.qcx.alteracoes')} />

        {logs.length === 0 && <Typography>Não há nenhum registro de alteração para este partnumber.</Typography>}

        <Grid container sm={12}>
          {logs.map((l) => (
            <LogEntry key={l.id} log={l} />
          ))}
        </Grid>
      </Container>
    </QCXMainLayout>
  );
}
