import { Box, Collapse, Grid, IconButton, makeStyles } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import QCXButton from '../../../../../shared-components/button/QCXButton';
import QCXFinalRadioField from '../../../../../shared-components/final-radio-field/QCXFinalRadioField';
import QCXForm from '../../../../../shared-components/form/QCXForm';
import { MediaType } from '../../../../../utils/api/api-utils';
import { required } from '../../../../../utils/validators/field/validator';
import FileDragAndDrop from '../../../../common/components/fileDragAndDrop';
import FormHeaderMedium from '../../../../common/components/forms/formHeaderMedium';
import { useEffect, useState } from 'react';

export type DashboardSgtFormValues = {
  tipoPlanilha: string;
  arquivo: File;
};

type DashboardSgtUploadFormProps = {
  handleSubmit: (values: DashboardSgtFormValues) => Promise<void> | void;
  shouldCollapse?: boolean;
};

const useStyles = makeStyles(() => ({
  inlineBlockGrid: {
    display: 'inline-block !important',
  },
}));

const DashboardSgtUploadForm = ({ handleSubmit, shouldCollapse }: DashboardSgtUploadFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (shouldCollapse) setIsCollapsed(true);
  }, [shouldCollapse]);

  const toggleCollapsed = () => setIsCollapsed((prev) => !prev);

  return (
    <Form onSubmit={handleSubmit}>
      {({ form, handleSubmit, values }) => (
        <QCXForm onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <FormHeaderMedium className={classes.inlineBlockGrid}>Registros</FormHeaderMedium>

              <IconButton onClick={toggleCollapsed}>{isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}</IconButton>
            </Grid>

            <Collapse style={{ width: '100%' }} in={!isCollapsed} timeout="auto" unmountOnExit>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <QCXFinalRadioField
                    id="radio-tipo-conhecimento"
                    key="radio-tipo-conhecimento"
                    name="tipo"
                    checked={values?.tipoPlanilha === 'CONHECIMENTO'}
                    onClick={() => form.change('tipoPlanilha', 'CONHECIMENTO')}
                    label={t('com.muralis.qcx.conhecimento')}
                    value={undefined}
                    required={undefined}
                    children={undefined}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <QCXFinalRadioField
                    id="radio-tipo-fatura"
                    key="radio-tipo-fatura"
                    name="tipo"
                    checked={values?.tipoPlanilha === 'FATURA'}
                    onClick={() => form.change('tipoPlanilha', 'FATURA')}
                    label={t('com.muralis.qcx.fatura.labelSingular')}
                    value={undefined}
                    required={undefined}
                    children={undefined}
                  />
                </Grid>
                <FormHeaderMedium>Seleção de arquivo</FormHeaderMedium>
                <Field name="arquivo" validate={required}>
                  {({ input }) => <FileDragAndDrop input={input} accept={MediaType.EXCEL} />}
                </Field>

                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <QCXButton
                      type="submit"
                      color="secondary"
                      variant="contained"
                      tooltipDescription={undefined}
                      disabled={undefined}
                    >
                      Importar planilha
                    </QCXButton>
                  </Box>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </QCXForm>
      )}
    </Form>
  );
};

export default DashboardSgtUploadForm;
