import { Box, Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import QCXFinalFormDataReplicator from '../../../../../../../components/form-data-replicator/QCXFinalFormDataReplicator';
import QCXFormTitle from '../../../../../../../components/form-title/QCXFormTitle';
import QCXRelacaoFornecedorFieldGroup from '../../../../../../../components/relacao-fornecedor/QCXRelacaoFornecedorFieldGroup';
import { AUTO_LOAD_CONTROL } from '../../../../../../../features/config-control';
import { changeControlTo } from '../../../../../../../features/pais/paisSlice';
import QCXButton from '../../../../../../../shared-components/button/QCXButton';
import QCXConfirmDialog from '../../../../../../../shared-components/dialog/QCXConfirmDialog';
import QCXFinalCheckboxField from '../../../../../../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalForm from '../../../../../../../shared-components/final-form/QCXFinalForm';
import { TIPO_DUIMP } from '../../../../../../../utils/general/catalogo-produtos/catalogoProdutosUtils';
import usePartnumbersDataReplicator from '../hooks/usePartnumbersDataReplicator.hook';
import useReplicateAtributos from '../hooks/useReplicateAtributos.hook';
import { CatalogoProdutosAtributosFormValues } from '../types/CatalogoProdutosAtributosFormValues';
import { CatalogoProdutosPartnumberFiltroFormValues } from '../types/CatalogoProdutosPartnumberFiltroFormValues';
import { DataGridControlConfigChange } from '../types/DataGridControlConfigChange';
import CatalogoProdutosNcmAtributosForm from './CatalogoProdutosNcmAtributosForm';

type CatalogoProdutosPartnumberDataReplicatorFormProps = {
  isLoading: boolean;
  onConfigChange: (config: DataGridControlConfigChange) => void;
  handleTransmitirSubmit: (values: CatalogoProdutosAtributosFormValues) => void;
  ncm: CatalogoProdutosPartnumberFiltroFormValues['ncm'];
};

export default function CatalogoProdutosPartnumberDataReplicatorForm({
  isLoading,
  onConfigChange,
  handleTransmitirSubmit,
  ncm,
}: CatalogoProdutosPartnumberDataReplicatorFormProps) {
  const { t } = useTranslation();

  const [isTransmitirDisabled, setIsTransmitirDisabled] = useState(true);

  const { replicated, setReplicated, formatSelection, handleResetFormData, filterableColumns, tableProps } =
    usePartnumbersDataReplicator();
  const { handleReplicateSubmit, replicateOperation } = useReplicateAtributos(() => setIsTransmitirDisabled(false));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeControlTo(AUTO_LOAD_CONTROL));
  }, []);

  return (
    <QCXFinalForm
      initialValues={{}}
      onSubmit={handleReplicateSubmit}
      isConsult={false}
      isUpdate={false}
      isCreate={false}
      handleChangeToUpdate={() => {}}
      handleChangeToCreate={() => {}}
      handleChangeToConsult={() => {}}
      handleCancelUpdate={() => {}}
      handleSubmitValues={() => {}}
      handleAlternativeSave={undefined}
      controlButtons={{
        cancel: {},
        edit: {},
        save: {},
        create: {
          hidden: true,
        },
      }}
      validate={undefined}
      disablePristine
      debugOn={false}
      focusLockProps={{
        disabled: true,
      }}
    >
      {/* eslint-disable-next-line */}
      {({ values }: { values: CatalogoProdutosAtributosFormValues }) => (
        <>
          <Grid container>
            <QCXFinalFormDataReplicator
              isConsult={false}
              isLoading={isLoading}
              tableProps={tableProps}
              onReset={handleResetFormData}
              replicated={replicated}
              handleSetReplicated={setReplicated}
              clearSelection={false}
              formatSelection={formatSelection}
              dataGridProps={{
                onConfigChangeCallback: onConfigChange,
                filterableColumns,
              }}
              hideControlButtons
              isPaginated
            >
              {ncm && ncm.listaAtributosMetadados && (
                <Box marginTop={6}>
                  {/* @ts-expect-error boxProps */}
                  <QCXFormTitle boxProps={{ mb: 3 }} title={t('com.muralis.qcx.NCM.atributosNCM')} />

                  <CatalogoProdutosNcmAtributosForm ncm={ncm} />

                  {/* @ts-expect-error boxProps */}
                  <QCXFormTitle boxProps={{ my: 3 }} title={t('com.muralis.qcx.dadosTecnicos')} />

                  <Box marginTop={1}>
                    <QCXFinalCheckboxField
                      id="tipo-field"
                      key="tipo-field"
                      name="tipo"
                      value={TIPO_DUIMP}
                      label={t('com.muralis.qcx.DIDUIMP.DUIMP')}
                      disabled={false}
                      required={undefined}
                    />
                  </Box>

                  {values.tipo?.includes('DUIMP') && (
                    <Grid item sm={12} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                      {/* @ts-expect-error boxProps */}
                      <QCXFormTitle boxProps={{ my: 3 }} title="Código interno do produto" />

                      <Box marginTop={1}>
                        <QCXFinalCheckboxField
                          id="usar-partnumber-field"
                          key="usar-partnumber-field"
                          name="usePartnumberAsInternalCode"
                          label={t('com.muralis.qcx.repassarPartnumberAsInternalCode')}
                          disabled={false}
                          required={undefined}
                        />
                      </Box>

                      <Box marginTop={1}>
                        <QCXFinalCheckboxField
                          id="usar-codigo-secundario-field"
                          key="usar-codigo-secundario-field"
                          name="useSecondaryCodeAsInternalCode"
                          label={t('com.muralis.qcx.repassarSecondaryCodeAsInternalCode')}
                          disabled={false}
                          required={undefined}
                        />
                      </Box>

                      <Box marginTop={3}>
                        <QCXRelacaoFornecedorFieldGroup
                          isConsult={false}
                          relacao={values?.relacao}
                          fabricante={(values as any)?.fabricante}
                          exportador={(values as any)?.exportador}
                          isAlternativeReasonToDisabled={false}
                          required={false}
                          manyFornecedores
                          hideCodigoInterno
                        />
                      </Box>
                    </Grid>
                  )}
                </Box>
              )}
            </QCXFinalFormDataReplicator>

            <Box marginTop={4} width="100%" display="flex" flexDirection="row" justifyContent="flex-end">
              <Box marginRight={4}>
                <QCXButton
                  tooltipDescription={t('com.muralis.qcx.acoes.repassar')}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!ncm || (values?.meta?.selected?.length ?? 0) === 0}
                >
                  {t('com.muralis.qcx.acoes.repassar')}
                </QCXButton>
              </Box>

              <QCXButton
                tooltipDescription={t('com.muralis.qcx.acoes.transmitir')}
                variant="contained"
                color="secondary"
                onClick={() => handleTransmitirSubmit(values)}
                disabled={isTransmitirDisabled || !ncm || (values?.meta?.selected?.length ?? 0) === 0}
              >
                {t('com.muralis.qcx.acoes.transmitir')}
              </QCXButton>
            </Box>
          </Grid>
          <QCXConfirmDialog
            key="confirm-dialog-remove-adicao"
            id="confirm-dialog-remove-adicao"
            open={(replicateOperation as any)?.active}
            title={(replicateOperation as any)?.title}
            content={(replicateOperation as any)?.message}
            endContent={(replicateOperation as any)?.endMessage}
            onConfirm={(replicateOperation as any).confirm}
            onClose={(replicateOperation as any)?.reset}
          />
        </>
      )}
    </QCXFinalForm>
  );
}
