/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { Grid } from '@material-ui/core';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { useTranslation } from 'react-i18next';
import QCXFormSubtitle from '../../../components/form-title/QCXFormSubtitle';
import QCXFormTitle from '../../../components/form-title/QCXFormTitle';
import QCXFinalDatePickerField from '../../final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalNumericDecimalField from '../../final-numeric-decimal-field/QCXFinalNumericDecimalField';
import QCXFinalTextField from '../../final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../form-step-wizard/QCXFormStepWizard';

function ConditionalField({ fieldType, ...restProps }) {
  switch (fieldType) {
    case 'STRING':
      return <QCXFinalTextField {...restProps} />;
    case 'DATA':
      return <QCXFinalDatePickerField {...restProps} />;
    case 'NUMERO':
    case 'INT':
      return <QCXFinalNumericDecimalField {...restProps} />;
    case undefined:
      return <></>
    default:
      throw new Error('Campos adicional informado não é de um tipo valido!');
  }
}

export default function CamposAdicionaisStepForm({
  initialValues,
  handleSubmit,
  isConsult,
  controlComponentProps,
  ...restProps
}) {
  const { t } = useTranslation();

  return (
    <QCXFormStepWizard
      mutators={{ ...arrayMutators }}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      disablePristine
      {...restProps}
    >
      {({ values }) => (
        <>
          <QCXFormTitle title={t('com.muralis.qcx.dadosEmbarque.camposAdicionaisTitulo')} />
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            {!values?.camposAdicionais?.length && (
              <QCXFormSubtitle title={t('com.muralis.qcx.dadosEmbarque.mensagemSemCamposAdicionais')} />
            )}
            <FieldArray name="camposAdicionais">
              {({ fields }) => (
                <>
                  {fields.map((name, index) => (
                    <>
                      <Grid item container alignContent="flex-start" xs={6}>
                        <ConditionalField
                          fieldType={values?.camposAdicionais[index]?.camposAdicionais?.tipoCampo}
                          label={values?.camposAdicionais[index]?.camposAdicionais?.description}
                          name={`${name}.valor`}
                          disabled={isConsult}
                        />
                      </Grid>
                    </>
                  ))}
                </>
              )}
            </FieldArray>
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
