import { Grid, Box } from '@material-ui/core';
import ProcessosInfoForm from '../InformaçõesTabSection/ProcessosInfoForm';
import QCXFinalMultilineInputWithChips from '../QCXFinalMultilineInputWIthChips';
import InfoMaritimasForms from './InfoMaritimasForms';
import SelectCanal from './SelectCanal';

function DadosSection() {
  return (
    <>
      <QCXFinalMultilineInputWithChips name="processos" label="Números dos Processos:" />

      <Box marginTop={2}>
        <ProcessosInfoForm />
      </Box>

      <InfoMaritimasForms />
      <Grid item container spacing={1} xs={12} style={{ paddingTop: '2vh' }}>
        <Grid item xs={4}>
          <SelectCanal label="Canal de Liberação" name="atributosAdicionais.canalLiberacao" />
        </Grid>
      </Grid>
    </>
  );
}

export default DadosSection;
