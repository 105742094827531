import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OnChange } from 'react-final-form-listeners';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import { required as requiredValidator } from '../../utils/validators/field/validator';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import TipoDocumentoUtils from '../../utils/general/tipo-documento/TipoDocumentoUtils';
import QCXAutocompleteSelect from '../../shared-components/autocomplete-select/QCXAutocompleteSelect';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';

export default function QCXTipoDocumentoFinalForm({
  isCreate,
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();

  const tipoDocumentoEnum = [
    {
      value: "GARE",
      id: "GARE",
    },
    {
      value: "GNRE",
      id: "GNRE",
    },
    {
      value: "EXONERACÃO",
      id: "EXONERACAO",
    },
    {
      value: "EXTRATO DA CONSULTA DO SISCARGA",
      id: "EXTRATO_DA_CONSULTA_DO_SISCARGA",
    },
    {
      value: "EXTRATO DA CONSULTA DO MARCANTE",
      id: "EXTRATO_DA_CONSULTA_DO_MARCANTE",
    },
    {
      value: "EXTRATO DA DECLARACAO DE IMPORTACAO",
      id: "EXTRATO_DA_DECLARACAO_DE_IMPORTACAO",
    },
    {
      value: "EXTRATO DA CI",
      id: "EXTRATO_DA_CI",
    },
    {
      value: "EXTRATO DA DI",
      id: "EXTRATO_DA_DI",
    },
    {
      value: "HBL",
      id: "HBL",
    },
    {
      value: "AEREO",
      id: "AEREO",
    },
    {
      value: "EXTRATO CCT",
      id: "EXTRATO_CCT",
    },
    {
      value: "HAWB",
      id: "HAWB",
    },
    {
      value: "CRT",
      id: "CRT",
    },
    {
      value: "DANFE",
      id: "DANFE",
    },
    {
      value: "DI",
      id: "DI",
    },
    {
      value: "GLME",
      id: "GLME",
    },
    {
      value: "DARJ",
      id: "DARJ",
    },
    {
      value: "NUMERÁRIO",
      id: "NUMERARIO",
    },
  ]

  return (
    <QCXFinalForm
      isConsult={isConsult}
      isUpdate={isUpdate}
      initialValues={initialValues}
      handleSubmitValues={handleSubmit}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
      {...restProps}
    >
      {({ form }) => (
        <>
          {!isCreate && (
            <Grid item xs={12} sm={4} md={2}>
              <QCXFinalTextField
                id="final-text-field-id"
                key="text-field-id"
                label={t('com.muralis.qcx.codigo')}
                name="id"
                disabled
              />
            </Grid>
          )}
          <Grid item xs={12} sm={8} md={6}>
            <QCXFinalTextField
              id="text-field-description"
              key="text-field-description"
              label={t('com.muralis.qcx.documento.nomeDocumento')}
              name="description"
              validate={requiredValidator}
              disabled={isConsult}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <QCXAutocompleteSelect
              label={t('com.muralis.qcx.documento.tipoDocumento')}
              id="select-field-modelo"
              name="tipoDocumento"
              options={tipoDocumentoEnum}
              getOptionLabel={(option) => option.value}
              defaultValue={() => []}
              disabled={isConsult}
            />
          </Grid>
          <Grid item md={6}>
            <QCXFinalTextField
              id="text-field-nomepadrao"
              key="text-field-nomepadrao"
              label={t('com.muralis.qcx.documento.nomePadrao')}
              name="nomePadrao"
              validate={requiredValidator}
              disabled={isConsult}
              required
            />
            <OnChange name="nomePadrao">
              {(value) => {
                if (!value) {
                  return;
                }

                const nomeExemplo = `<numero_processo>_${value}.pdf`;
                form.change('ignorableFields.nomeExemplo', nomeExemplo);
              }}
            </OnChange>
          </Grid>
          <Grid item md={6}>
            <QCXFinalTextField
              id="text-field-nomepadrao"
              key="text-field-nomepadrao"
              label="Exemplo Arquivo PDF:"
              name="ignorableFields.nomeExemplo"
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={4} md={isCreate ? 6 : 4}>
            <QCXFinalCheckboxField
              id="text-field-publico"
              key="text-field-publico"
              label={t('com.muralis.qcx.publico')}
              name="publico"
              value={TipoDocumentoUtils.PUBLICO}
              disabled={isConsult}
            />
          </Grid>
          
          <Grid item xs={12}>
            <QCXFormSubtitle title="Enviar como anexo no email de :" />
          </Grid>
          

          <Grid item xs={12} sm={4} md={isCreate ? 6 : 4}>
            <QCXFinalCheckboxField
              id="text-field-publico"
              key="text-field-publico"
              label={t('com.muralis.qcx.documento.tipoEnvioIcms')}
              name="envioEmailIcms"
              disabled={isConsult}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={isCreate ? 6 : 4}>
            <QCXFinalCheckboxField
              id="text-field-publico"
              key="text-field-publico"
              label={t('com.muralis.qcx.documento.tipoEnvioRegsitroDi')}
              name="envioEmailRegistroDi"
              disabled={isConsult}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={isCreate ? 6 : 4}>
            <QCXFinalCheckboxField
              id="text-field-publico"
              key="text-field-publico"
              label={t('com.muralis.qcx.documento.tipoEnvioDesembaraco')}
              name="envioEmailDesembaraco"
              disabled={isConsult}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={isCreate ? 6 : 4}>
            <QCXFinalCheckboxField
              id="text-field-publico"
              key="text-field-publico"
              label={t('com.muralis.qcx.documento.tipoEnvioTransporte')}
              name="envioEmailTransporte"
              disabled={isConsult}
            />
          </Grid>
        </>
      )}
    </QCXFinalForm>
  );
}
