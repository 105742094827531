import { Grid, Hidden } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalRadioField from '../../shared-components/final-radio-field/QCXFinalRadioField';
import QCXSelectMoedaAutocomplete from '../../shared-components/final-select-moeda-field/QCXSelectMoedaAutocomplete';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectContaContabilAutocomplete from '../../shared-components/select-conta-contabil/QCXSelectContaContabilAutocomplete';
import QCXSelectDespesaReceitaAutocomplete from '../../shared-components/select-despesa-receita/QCXSelectDespesaReceitaAutocomplete';
import QCXSelectEtapaAutocomplete from '../../shared-components/select-etapa/QCXSelectEtapaAutocomplete';
import QCXSelectViaTransporteAutocomplete from '../../shared-components/select-via-transporte/QCXSelectViaTransporteAutocomplete';
import ModeloFaturamentoUtils from '../../utils/general/modelo-faturamento/ModeloFaturamentoUtils';
import useValidators from '../../utils/hooks/useValidators';
import { isValidDate, required as requiredValidator } from '../../utils/validators/field/validator';
import QCXFormTitle from '../form-title/QCXFormTitle';

export default function QCXModeloFaturamentoTecnicalDataForm({
  isCreate,
  isConsult,
  initialValues,
  handleSubmit,
  controlComponentProps,
  handleAutomaticoChange,
  ...restProps
}) {
  const { t } = useTranslation();
  const isFaturamentoAutomatico = useCallback((values) => !!values?.automatico, []);

  const isFaturamentoPorEtapa = useCallback((values) => !!values?.agenda?.porEtapa, []);

  const isFaturamentoPorDespesaReceita = useCallback((values) => !!values?.agenda?.porDespesaReceita, []);

  const handleFaturamentoAutomaticoChange = useCallback((form) => {
    const handleValueChange = (value) => {
      if (!value) {
        form.change('agenda.id', undefined);
        form.change('agenda.porEtapa', undefined);
        form.change('agenda.etapa', undefined);
        form.change('agenda.porDespesaReceita', undefined);
        form.change('agenda.despesaReceita', undefined);
        form.change('agenda', undefined);
      }
      handleAutomaticoChange(value);
    };

    return handleValueChange;
  }, []);

  const handleAgendaTipoChange = useCallback((form, values) => {
    const handleValueChange = (value) => {
      if (ModeloFaturamentoUtils.isPorEtapa(value)) {
        form.restart({
          ...values,
          agenda: {
            ...values?.agenda,
            porEtapa: true,
            porDespesaReceita: false,
            despesaReceita: undefined,
          },
        });
      }
      if (ModeloFaturamentoUtils.isPorDespesaReceita(value)) {
        form.restart({
          ...values,
          agenda: {
            ...values?.agenda,
            porEtapa: false,
            porDespesaReceita: true,
            etapa: undefined,
          },
        });
      }
    };

    return handleValueChange;
  }, []);

  const changeListeners = useMemo(
    () => [
      {
        name: 'automatico',
        fn: handleFaturamentoAutomaticoChange,
      },
      {
        name: 'agenda.tipo',
        fn: handleAgendaTipoChange,
      },
    ],
    [handleAgendaTipoChange, handleFaturamentoAutomaticoChange]
  );

  const configureCheckboxPorEtapaFieldComponent = useCallback(
    (values, gridBreakpoints = {}) => (
      <Grid item xs={12} {...gridBreakpoints}>
        <QCXFinalRadioField
          id="checkbox-por-etapa"
          key="checkbox-por-etapa"
          name="agenda.tipo"
          label={t('com.muralis.qcx.porEtapa')}
          value={ModeloFaturamentoUtils.POR_ETAPA}
          disabled={isConsult}
          validate={values?.automatico && !values?.agenda?.porDespesaReceita && requiredValidator}
          required
        />
      </Grid>
    ),
    [isConsult]
  );

  const configureCheckboxPorDespesaReceitaFieldComponent = useCallback(
    (values, gridBreakpoints = {}) => (
      <Grid item xs={12} {...gridBreakpoints}>
        <QCXFinalRadioField
          id="checkbox-por-despesa-receita"
          key="checkbox-por-despesa-receita"
          name="agenda.tipo"
          label={t('com.muralis.qcx.porDespesaReceita')}
          value={ModeloFaturamentoUtils.POR_DESPESA_RECEITA}
          disabled={isConsult}
          validate={values?.automatico && !values?.agenda?.porEtapa && requiredValidator}
          required
        />
      </Grid>
    ),
    [isConsult]
  );

  const configureSelectAgendaEtapaFieldComponent = useCallback(
    (values, gridBreakpoints = {}) =>
      isFaturamentoPorEtapa(values) && (
        <Grid item xs={12} {...gridBreakpoints}>
          <QCXSelectEtapaAutocomplete
            id="text-field-agenda-etapa-follow-up"
            key="text-field-agenda-etapa-follow-up"
            name="agenda.etapa.id"
            label={t('com.muralis.qcx.etapa.label')}
            fieldProps={{ validate: isFaturamentoPorEtapa(values) && requiredValidator }}
            initialValues={values}
            disabled={isConsult || isFaturamentoPorDespesaReceita(values)}
            required
          />
        </Grid>
      ),
    [isConsult, isFaturamentoPorEtapa, requiredValidator]
  );

  const faturamentoPorDespesaReceitaValidators = useMemo(() => useValidators([requiredValidator, isValidDate]), []);

  const configureSelectAgendaDespesaReceitaFieldComponent = useCallback(
    (values, gridBreakpoints = {}) =>
      isFaturamentoPorDespesaReceita(values) && (
        <Grid item xs={12} {...gridBreakpoints}>
          <QCXSelectDespesaReceitaAutocomplete
            id="autocomplete-select-field-despesa-receita"
            key="autocomplete-select-field-despesa-receita"
            name="agenda.despesaReceita.id"
            label={t('com.muralis.qcx.despesa.despesaReceita')}
            initialValues={values}
            fieldProps={{
              validate: isFaturamentoPorDespesaReceita(values) && faturamentoPorDespesaReceitaValidators,
            }}
            disabled={isConsult || isFaturamentoPorEtapa(values)}
            required
          />
        </Grid>
      ),
    [isConsult, isFaturamentoPorDespesaReceita, faturamentoPorDespesaReceitaValidators]
  );

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onChangeListeners={changeListeners}
      controlComponentProps={controlComponentProps}
      {...restProps}
    >
      {({ values }) => (
        <>
          <QCXFormTitle title={t('com.muralis.qcx.dadosTecnicos')} />
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            {!isCreate && (
              <Grid item xs={12} sm={4} md={2}>
                <QCXFinalTextField
                  id="text-field-id-servico"
                  key="text-field-id-servico"
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={12} sm={isCreate ? 12 : 8} md={4}>
              <QCXFinalTextField
                id="text-field-description"
                key="text-field-description"
                name="description"
                label={t('com.muralis.qcx.descricao')}
                maxLength={25}
                disabled={isConsult}
                validate={requiredValidator}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={isCreate ? 8 : 6}>
              <QCXSelectContaContabilAutocomplete
                id="select-field-conta-contabil"
                key="select-field-conta-contabil"
                name="planoDeConta.id"
                label={t('com.muralis.qcx.empresa.contaContabil')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectViaTransporteAutocomplete
                id="select-field-via-transporte"
                key="select-field-via-transporte"
                name="viaTransporte.id"
                label={t('com.muralis.qcx.transporte.viaTransporte')}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectMoedaAutocomplete
                id="select-field-moeda-negociada"
                key="select-field-moeda-negociada"
                name="moeda.id"
                label={t('com.muralis.qcx.moeda.moedaNegociada')}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXSelectEtapaAutocomplete
                id="text-field-etapa-follow-up"
                key="text-field-etapa-follow-up"
                name="etapa"
                label={t('com.muralis.qcx.etapa.etapaFollowUp')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12}>
              <QCXFinalCheckboxField
                id="checkbox-automatico"
                key="checkbox-automatico"
                name="automatico"
                label={t('com.muralis.qcx.empresa.faturamentoAutomatico')}
                disabled={isConsult}
              />
            </Grid>
            {isFaturamentoAutomatico(values) && (
              <>
                <Hidden implementation="js" only={['xs', 'sm']}>
                  {configureCheckboxPorEtapaFieldComponent(values, { md: 6 })}
                  {configureCheckboxPorDespesaReceitaFieldComponent(values, { md: 6 })}
                  {configureSelectAgendaEtapaFieldComponent(values, { md: 6 })}
                  {configureSelectAgendaDespesaReceitaFieldComponent(values, { md: 6 })}
                </Hidden>
                <Hidden implementation="js" only={['md', 'lg', 'xl']}>
                  {configureCheckboxPorEtapaFieldComponent(values)}
                  {configureSelectAgendaEtapaFieldComponent(values)}
                  {configureCheckboxPorDespesaReceitaFieldComponent(values)}
                  {configureSelectAgendaDespesaReceitaFieldComponent(values)}
                </Hidden>
              </>
            )}
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
