import QCXFinalTextField from '../final-text-field/QCXFinalTextField';

export default function QCXFinalContaCorrenteTextField({
  id,
  key,
  name,
  label,
  disabled,
  disableFormat,
  ...restProps
}) {
  return (
    <QCXFinalTextField
      id={id}
      key={key}
      name={name}
      label={label}
      // validate={validateContaCorrente}
      // parse={parseContaCorrente} // Removido temporariamente pra permitir o dig x
      // format={disableFormat ? (value) => value : formatContaCorrente}
      // formatOnBlur
      maxLength={11}
      disabled={disabled}
      {...restProps}
    />
  );
}
