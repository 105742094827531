export interface CorrespondenciasListItem
  extends CorrespondenciaImportadorTotvs {
  correspondenciasGerais: CorrespondenciaGeralTotvs[];
}
export interface CorrespondenciaImportadorTotvs {
  id?: number;
  totvsId: string;
  quickcomexId: string;
  nome: string;
  tenantId: string;
}
export interface CorrespondenciaGeralTotvs {
  id?: number;
  correspondenciaImportadorId: number;
  tipo: string;
  totvsId: string;
  quickcomexId: string;
  nome: string;
}
export interface ISubCorrelation {
  tipo: string;
  nome: string;
  codigo: string;
  codigoTotvs: string;
}

export interface ICorrelation {
  id: string;
  tenant?: string;
  codigoImportadorTotvs: string;
  importador: string;
  codigoImportador: string;
  correlations?: {
    [key: string]: {
      [key: string]: ISubCorrelation;
    };
  };
}

export enum CorrelationsType {
  "Agente de Carga",
  "CFOP",
  "Despachante",
  "Despesas/Receitas",
  "Dispositivo Legal de Exoneração do ICMS",
  "Exportador",
  "Fabricante",
  "Local de Embarque",
  "Local do Incoterm",
  "Transportadora",
  "Transportador Internacional",
  "Unidade de Medida",
}
