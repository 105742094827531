import { useSelector } from 'react-redux';
import { selectFornecedoresFavorecidos } from '../../../../features/fornecedor-favorecido/FornecedorSlice';
import MultiSelectFormInput from '../../../common/components/forms/MultiSelectFormInput';
import { SimpleMultiSelectOption } from '../../../common/components/input/multiSelectStyled';

type MultiSelectFornecedorProps = {
  onChangeCallback?: (selected: SimpleMultiSelectOption[]) => void;
};

export function MultiSelectFornecedor({ onChangeCallback }: MultiSelectFornecedorProps) {
  const fornecedores = useSelector(selectFornecedoresFavorecidos);

  const options = fornecedores.map((fornecedor: any) => ({
    id: fornecedor.id,
    value: fornecedor.pessoa.nomeResumido,
  }));

  return (
    <MultiSelectFormInput
      onChangeCallback={onChangeCallback}
      name="fornecedores"
      options={options}
      label="Fornecedor"
      disablePortal={false}
    />
  );
}
