import { Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXFinalDatePickerField from '../../../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';

export default function TaxaConversaoCambioAdvancedFilterForm() {
  const { t } = useTranslation();

  return (
    <Box style={{ minWidth: '500px' }}>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <QCXFinalDatePickerField
            id="date-picker-field-insertion-date"
            key="date-picker-field-insertion-date"
            label={t('com.muralis.qcx.dateRange0')}
            name="insertionDateRangeStart"
            required={undefined}
            validate={undefined}
          />
        </Grid>

        <Grid item sm={6}>
          <QCXFinalDatePickerField
            id="date-picker-field-insertion-date"
            key="date-picker-field-insertion-date"
            label={t('com.muralis.qcx.dateRange1')}
            name="insertionDateRangeEnd"
            required={undefined}
            validate={undefined}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
