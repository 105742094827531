import { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectEstadoAutocomplete from '../../shared-components/select-estado/QCXSelectEstadoAutocomplete';
import { AUTO_LOAD_CONTROL } from '../../features/config-control';
import { estadoActions } from '../../features/estado/estadoSlice';
import { required as requiredValidator } from '../../utils/validators/field/validator';


export default function QCXCidadeForm({
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToUpdate,
  handleCancelUpdate,
  ...restProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(estadoActions.changeControlTo(AUTO_LOAD_CONTROL));
  }, []);

  return (
    <QCXFinalForm
      isConsult={isConsult}
      isUpdate={isUpdate}
      initialValues={initialValues}
      handleSubmitValues={handleSubmit}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      {...restProps}
    >
      {() => (
        <>
          <Grid item xs={12} sm={4} md={2}>
            <QCXFinalTextField
              id="text-field-code"
              key="text-field-code"
              name="code"
              label={t('com.muralis.qcx.codigo')}
              required
              disabled={isConsult} 
            />
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <QCXFinalTextField
              name="description"
              label={t('com.muralis.qcx.descricao')}
              disabled={isConsult} 
              maxLength={100}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <QCXSelectEstadoAutocomplete
              id="select-estado"
              key="select-estado"
              name="estado.id"
              label='Estado'
              fieldProps={{ validate: requiredValidator }} // Required field
              initialValues={initialValues}
              disabled={isConsult}
              required
            />
          </Grid>
        </>
      )}
    </QCXFinalForm>
  );
}
