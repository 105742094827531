import {
  doGetQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import { DOCUMENTO_INSTRUCAO_DESPACHO_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAll() {
  return doGetQCXRequest([
    `${DOCUMENTO_INSTRUCAO_DESPACHO_ENDPOINT}/documentos-anexacao-duimp`
  ]);
}


const documentoAnexacaoAPI = {
  fetchAll,
};

export {
  documentoAnexacaoAPI,
  fetchAll,
};
