import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotasDanfeByProcessoAsync } from '../../../../../features/danfe/danfeThunks';
import { clearNotas } from '../../../../../features/danfe/danfeSlice';

export const useListAllNotas = (processo) => {
  const dispatch = useDispatch();

  const notas = useSelector(function (state) {
    return state.danfe.notas;
  });

  useEffect(() => {
    if (processo) {
      dispatch(fetchNotasDanfeByProcessoAsync({ processo }));
    } else {
      dispatch(clearNotas());
    }
  }, [processo]);

  return notas;
};
