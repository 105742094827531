/* eslint-disable @typescript-eslint/no-unused-vars */
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectServicos, selectConfigControl } from '../../features/servico/servicoSlice';
import { isAutoLoadControl } from '../../utils/store/store-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import { fetchServicoByClienteAsync } from '../../features/cliente/clienteSlice';

export default function QCXSelectServicoAutocomplete({
  servicosList = undefined,
  label,
  name,
  initialValues,
  produto,
  cliente = undefined,
  isAutoLoad = true,
  ...restProps
}) {
  const dispatch = useDispatch();

  let list = servicosList ?? useSelector(selectServicos);

  const filtredList = useMemo(() => {
    if (produto) {
      list = list?.filter((item) => item?.produto === produto) ?? [];
    }

    return list;
  }, [list, produto]);

  const configControl = useSelector(selectConfigControl);

  useEffect(() => {
    if (isAutoLoad && isAutoLoadControl(configControl)) {
      dispatch(fetchAllAsync());
    }

    if (cliente) {
      dispatch(fetchServicoByClienteAsync(cliente));
    }
  }, [configControl, cliente, isAutoLoad]);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = filtredList?.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.id} - ${item?.sigla} - ${item?.nome}`,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={filtredList}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) =>
        item?.code && item?.sigla && item?.nome ? `${item?.id} - ${item?.sigla} - ${item?.nome}` : ''
      }
      {...restProps}
    />
  );
}
