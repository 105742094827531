import { Person as PersonIcon, ArrowRightAltRounded as ArrowRightIcon } from '@material-ui/icons';
import { Paper, Typography, Grid, Box } from '@material-ui/core';
import { CatalogoProdutosLog } from '../hooks/useLogs';
import QCXFormSubtitle from '../../../../../../../components/form-title/QCXFormSubtitle';

type LogEntryProps = {
  log: CatalogoProdutosLog;
};

const LogEntry = ({ log }: LogEntryProps) => (
  <Paper style={{ padding: '16px', width: '100%', marginTop: '1rem', marginBottom: '1rem' }}>
    <Box display="flex" flexDirection="row" alignItems="center" style={{ gap: 8 }}>
      <PersonIcon color="secondary" />
      <Typography variant="subtitle1" color="textPrimary">
        <strong style={{ fontWeight: 'bold' }}>{log.userFullname}</strong> fez uma alteração em{' '}
        <strong style={{ fontWeight: 'bold' }}>{log.insertionDate}</strong>
      </Typography>
    </Box>

    {Object.keys(log.changes).map((modifiedKey) => (
      <Box my={3}>
        <QCXFormSubtitle title={modifiedKey} />

        <Box mt={1}>
          <Grid alignItems="center" container sm={12}>
            <Grid item sm={5}>
              <strong style={{ fontWeight: 'bold' }}>Valor anterior: </strong>
              <Typography style={{ whiteSpace: 'pre-wrap' }}>
                {log.changes[modifiedKey].previousValue || '-'}
              </Typography>
            </Grid>

            <Grid item sm={2}>
              <ArrowRightIcon color="secondary" />
            </Grid>

            <Grid item sm={5}>
              <strong style={{ fontWeight: 'bold' }}>Valor alterado: </strong>
              <Typography style={{ whiteSpace: 'pre-wrap' }}>{log.changes[modifiedKey].newValue}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    ))}
  </Paper>
);

export default LogEntry;
