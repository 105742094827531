import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getTipoModeloLabelByValue } from '../../shared-components/select-tipo-modelo/QCXFinalSelectTipoModeloAutocomplete';
import { CONSULT_MODE, CREATE_MODE, NONE_MODE, UPDATE_MODE } from '../mode';
import { ALTERNATIVE_LOADING_STATUS, FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS } from '../status';
import { activateById, fetchAll, fetchById, inactivateById } from './despesaReceitaAPI';
import i18n from '../../i18n';
import DespesaReceitaUtils from '../../utils/general/despesa-receita/DespesaReceitaUtils';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
  },
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
  model: {
    id: undefined,
    description: undefined,
    tipo: undefined,
    planoDeConta: undefined,
    tipoRateio: undefined,
    tipoCalculo: undefined,
    moeda: undefined,
    operacional: undefined,
    aduaneira: undefined,
  },
};

const fetchAllAsync = createAsyncThunk('despesaReceita/fetchAll', async () => {
  const { data } = await fetchAll();
  return { data };
});

const fetchByIdAsync = createAsyncThunk('despesaReceita/fetchById', async (data) => {
  const response = await fetchById(data);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const activateByIdAsync = createAsyncThunk('despesaReceita/activateById', async (id) => {
  const { status, data } = await activateById(id);
  return { response: { status, data } };
});

const inactivateByIdAsync = createAsyncThunk('despesaReceita/inactivateById', async (id) => {
  const { status, data } = await inactivateById(id);
  return { response: { status, data } };
});

const despesaReceitaSlice = createSlice({
  name: 'despesaReceita',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeModeTo: (state, action) => {
      state.mode.main = action.payload;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    resetList: (state) => {
      state.list = [];
    },
    setList: (state, action) => {
      state.list = action.payload.data;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaDespesaReceita', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = {
            ...action.payload.response.data,
          };
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarDespesaReceita', { erro: action.error.message }),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { description, tipo } = action.payload.response.data;
        const tipoDespesaReceitaLabel = getTipoModeloLabelByValue(tipo);

        state.response.message = i18n.t('com.muralis.qcx.mensagem.despesaReceitaAtivada', {
          receitaDespesa: tipoDespesaReceitaLabel,
          descricao: description,
        });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroAtivarDespesaReceita', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { description, tipo } = action.payload.response.data;
        const tipoDespesaReceitaLabel = getTipoModeloLabelByValue(tipo);
        state.response.message = i18n.t('com.muralis.qcx.mensagem.DespesaReceitaInativada', {
          receitaDespesa: tipoDespesaReceitaLabel,
          descricao: description,
        });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroInativarDespesaReceita', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  setModel,
  resetModel,
  addToList,
  updateOnList,
  resetList,
  setList,
  setResponse,
  clearResponse,
  setError,
  clearError,
} = despesaReceitaSlice.actions;

const selectStatus = (state) => state.despesaReceita.status;
const selectMode = (state) => state.despesaReceita.mode.main;
const selectDespesasReceitas = (state) => state.despesaReceita.list.filter((despesaReceita) => despesaReceita.active);
const selectDespesas = (state) =>
  state.despesaReceita.list.filter((despesaReceita) => DespesaReceitaUtils.isDespesa(despesaReceita?.tipo));
const selectReceitas = (state) =>
  state.despesaReceita.list.filter((despesaReceita) => DespesaReceitaUtils.isReceita(despesaReceita?.tipo));
const selectDespesaReceita = (state) => state.despesaReceita.model;
const selectError = (state) => state.despesaReceita.error;
const selectResponse = (state) => state.despesaReceita.response;

export {
  despesaReceitaSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  setModel,
  resetModel,
  addToList,
  updateOnList,
  resetList,
  setList,
  setResponse,
  clearResponse,
  setError,
  clearError,
  fetchAllAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  selectStatus,
  selectMode,
  selectDespesasReceitas,
  selectDespesas,
  selectReceitas,
  selectDespesaReceita,
  selectError,
  selectResponse,
};

export default despesaReceitaSlice.reducer;
