import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { TIPO_DUE } from '../../utils/general/fatura/faturaUtils';
import {
  // CALCULADA_STATUS,
  isNaoCalculadaStatus,
} from '../../utils/general/operations/operationUtils';
import {
  CONSULT_MODE,
  CREATE_MODE,
  UPDATE_MODE,
  REVERSAL_MODE,
  LOCKED_MODE,
  NONE_MODE,
  BACKGROUND_CREATE_MODE,
  BACKGROUND_UPDATE_MODE,
  BACKGROUND_DELETE_MODE,
  BACKGROUND_REVERSAL_MODE,
  NONE_BACKGROUND_MODE,
  SUB_CREATE_MODE,
  SUB_CONSULT_MODE,
  SUB_UPDATE_MODE,
  NONE_SUB_MODE,
  BACKGROUND_CONSULT_MODE,
  NONE_SUB_BACKGROUND_MODE,
  SUB_BACKGROUND_CREATE_MODE,
  SUB_BACKGROUND_CONSULT_MODE,
  SUB_BACKGROUND_UPDATE_MODE,
  SUB_BACKGROUND_DELETE_MODE,
} from '../mode';
import { NO_REFRESH, REFRESH } from '../refresh';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  fetchAllFromFaturaAsync,
  fetchByIdFromFaturaAsync,
  activateByIdFromFaturaAsync,
  inactivateByIdFromFaturaAsync,
  conferirByIdFromFaturaAsync,
  calculateByIdFromFaturaAsync,
  liberarByIdFromFaturaAsync,
  calculaDUEAposZerarCamposAsync,
  reabrirByIdAsync,
  retificarByIdFromFaturaAsync,
  fetchDuesByFilterAsync,
} from './declaracaoUnicaExportacaoThunks';
import { FATURA_DUE_META_TYPE, NONE_META_TYPE } from './declaracaoUnicaExportacaoUtils';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
    subBackground: NONE_SUB_BACKGROUND_MODE,
    lastSubBackground: undefined,
  },
  type: FATURA_DUE_META_TYPE,
  refresh: NO_REFRESH,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {
    conditionalSteps: [i18n.t('com.muralis.qcx.importacao.importacaoXMLDANFE')],
  },
  list: [],
  model: {
    code: undefined,
    description: undefined,
    possuiPendencia: undefined,
    followUp: undefined,
    atributosAdicionais: {
      tipo: TIPO_DUE,
      processo: undefined,
      conferida: undefined,
      transmitida: undefined,
      numero: undefined,
      data: undefined,
    },
    pesoBruto: undefined,
    pesoLiquido: undefined,
    rateiaPesoLiquidoItem: undefined,
    dadosComumFornecedor: undefined,
    importador: undefined,
    paisProcedencia: undefined,
    urfDespacho: undefined,
    urfEntrada: undefined,
    incotermCondicaoVenda: undefined,
    mle: {
      valorMoeda: undefined,
      valorReal: undefined,
      moeda: undefined,
    },
    desconto: {
      valorMoeda: undefined,
      valorReal: undefined,
      moeda: undefined,
    },
    despesa: {
      valorMoeda: undefined,
      valorReal: undefined,
      moeda: undefined,
    },
    frete: {
      prepaid: undefined,
      embutirItem: undefined,
      valorMoedaCollect: undefined,
      valorRealCollect: undefined,
      valorMoedaPrepaid: undefined,
      valorRealPrepaid: undefined,
      moeda: undefined,
    },
    seguro: {
      embutirItem: undefined,
      calculaSobreMleFrete: undefined,
      valorPercentual: undefined,
      valorMoeda: undefined,
      valorReal: undefined,
      moeda: undefined,
    },
    relacao: undefined,
    exportador: undefined,
    fabricante: undefined,
    mercadorias: [],
  },
  related: {
    model: {
      mercadoria: {},
      duplicata: {
        id: undefined,
        atributosAdicionais: {
          tipo: undefined,
          processo: undefined,
        },
      },
      registro: {},
      transferenciaItem: {},
      followUp: {},
    },
    list: {
      selectionAvailableItem: [],
      selectionChangedItem: [],
      selectionItem: [],
    },
    totalNumberOfItens: 0,
    itensXmlDanfeMetadata: undefined,
    clienteAtual: undefined,
  },
  historyModel: {
    last: {},
  },
};

const declaracaoUnicaExportacaoSlice = createSlice({
  name: 'declaracaoUnicaExportacao',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    refresh: (state) => {
      state.refresh = REFRESH;
    },
    resetRefresh: (state) => {
      state.refresh = NO_REFRESH;
    },
    changeModeTo: (state, action) => {
      state.mode.main = action.payload;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    changeToReversalMode: (state) => {
      state.mode.main = REVERSAL_MODE;
    },
    changeToLockMode: (state) => {
      state.mode.main = LOCKED_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    changeToBackgroundConsultMode: (state) => {
      state.mode.background = BACKGROUND_CONSULT_MODE;
    },
    changeToBackgroundUpdateMode: (state) => {
      state.mode.background = BACKGROUND_UPDATE_MODE;
    },
    changeToBackgroundDeleteMode: (state) => {
      state.mode.background = BACKGROUND_DELETE_MODE;
    },
    changeToBackgroundReversalMode: (state) => {
      state.mode.background = BACKGROUND_REVERSAL_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    changeToSubBackgroundCreateMode: (state) => {
      state.mode.lastSubBackground = state.mode.subBackground;
      state.mode.subBackground = SUB_BACKGROUND_CREATE_MODE;
    },
    changeToSubBackgroundConsultMode: (state) => {
      state.mode.lastSubBackground = state.mode.subBackground;
      state.mode.subBackground = SUB_BACKGROUND_CONSULT_MODE;
    },
    changeToSubBackgroundUpdateMode: (state) => {
      state.mode.lastSubBackground = state.mode.subBackground;
      state.mode.subBackground = SUB_BACKGROUND_UPDATE_MODE;
    },
    changeToSubBackgroundDeleteMode: (state) => {
      state.mode.lastSubBackground = state.mode.subBackground;
      state.mode.subBackground = SUB_BACKGROUND_DELETE_MODE;
    },
    resetSubBackgroundMode: (state) => {
      state.mode.lastSubBackground = state.mode.subBackground;
      state.mode.subBackground = NONE_SUB_BACKGROUND_MODE;
    },
    changeToLastSubBackgroundMode: (state) => {
      if (state.mode.lastSubBackground) {
        state.mode.subBackground = state.mode.lastSubBackground;
      }
    },
    changeTypeTo: (state, action) => {
      state.type = action.payload;
    },
    resetType: (state) => {
      state.type = NONE_META_TYPE;
    },
    changeLastHistoryModel: (state, action) => {
      state.historyModel.last = action.payload;
    },
    resetHistoryModel: (state) => {
      state.historyModel = {};
    },
    resetLastHistoryModel: (state) => {
      state.historyModel.last = {};
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    setProcesso: (state, action) => {
      state.model.followUp = action.payload.followUp;
      state.model.atributosAdicionais.processo = action.payload.processo;
    },
    updateSelectedMercadoria: (state) => {
      const currentProdutoPartnumber = state.related.model?.mercadoria?.produto?.partnumber;
      if (!_.isEmpty(currentProdutoPartnumber)) {
        const { mercadorias } = state.model;
        const mercadoria = mercadorias.find((item) => item?.produto?.partnumber === currentProdutoPartnumber);

        state.related.model.mercadoria = mercadoria;
      }
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setRelatedMercadoriaModel: (state, action) => {
      state.related.model.mercadoria = {
        ...action.payload,
      };
    },
    resetRelatedMercadoriaModel: (state) => {
      state.related.model.mercadoria = {};
    },
    setRelatedDuplicataModel: (state, action) => {
      state.related.model.duplicata = {
        ...action.payload,
      };
    },
    resetRelatedDuplicataModel: (state) => {
      state.related.model.duplicata = {};
    },
    setRelatedRegistroModel: (state, action) => {
      state.related.model.registro = {
        ...action.payload,
      };
    },
    resetRelatedRegistroModel: (state) => {
      state.related.model.registro = {};
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
    setRelatedModelFollowUp: (state, action) => {
      state.related.model.followUp = action.payload;
    },
    resetRelatedModelFollowUp: (state) => {
      state.related.model.followUp = initialState.related.model.followUp;
    },
    setRelatedSelectionAvailableItemList: (state, action) => {
      state.related.list.selectionAvailableItem = action.payload;
    },
    resetRelatedSelectionAvailableItemList: (state) => {
      state.related.list.selectionAvailableItem = [];
    },
    setRelatedSelectionChangedItemList: (state, action) => {
      state.related.list.selectionChangedItem = action.payload;
    },
    resetRelatedSelectionChangedItemList: (state) => {
      state.related.list.selectionChangedItem = [];
    },
    setRelatedSelectionItemList: (state, action) => {
      state.related.list.selectionItem = action.payload;
    },
    resetRelatedSelectionItemList: (state) => {
      state.related.list.selectionItem = [];
    },
    setRelatedTransferenciaItem: (state, action) => {
      state.related.model.transferenciaItem = action.payload;
    },
    resetRelatedTransferenciaItem: (state) => {
      state.related.model.transferenciaItem = initialState.related.model.transferenciaItem;
    },
    setRelatedItensXmlDanfeMetadata: (state, action) => {
      state.related.itensXmlDanfeMetadata = action.payload;
    },
    resetRelatedItensXmlDanfeMetadata: (state) => {
      state.related.itensXmlDanfeMetadata = initialState.related.itensXmlDanfeMetadata;
    },
    addItemToChangedSelectionList: (state, action) => {
      const item = action.payload;

      if (state.related.list.selectionChangedItem?.indexOf(item) === -1) {
        state.related.list.selectionChangedItem = [...state.related.list.selectionChangedItem, item];
      }
    },
    removeItemFromChangedSelectionList: (state, action) => {
      state.related.list.selectionChangedItem = state.related.list.selectionChangedItem?.filter(
        (item) => item !== action.payload
      );
    },
    setRelatedClienteAtual: (state, action) => {
      state.related.clienteAtual = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdFromFaturaAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = {
            ...action.payload.response.data,
          };
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarDUE', { erro: action.error.message }),
        };
      })
      .addCase(fetchAllFromFaturaAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllFromFaturaAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaDUE', { erro: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(calculateByIdFromFaturaAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const requestMetaArg = action.meta.arg;
        const isNaoCalculada = isNaoCalculadaStatus(requestMetaArg?.meta?.calculada);

        const { atributosAdicionais } = action.payload.response.data;
        const processo = atributosAdicionais?.processo;

        state.response.message = i18n.t('com.muralis.qcx.mensagem.DUEcalculada', {
          due: processo,
          estado: isNaoCalculada
            ? i18n.t('com.muralis.qcx.estado.calculada').toLowerCase()
            : i18n.t('com.muralis.qcx.estado.recalculada').toLowerCase(),
        });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );

          state.historyModel.last = {
            processo,
          };

          state.refresh = REFRESH;
        }
      })
      .addCase(calculateByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.calcularDUE', { erro: action.payload?.message }),
        };
      })
      .addCase(calculaDUEAposZerarCamposAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const requestMetaArg = action.meta.arg;
        const isNaoCalculada = isNaoCalculadaStatus(requestMetaArg?.meta?.calculada);

        const { atributosAdicionais } = action.payload.response.data;
        const processo = atributosAdicionais?.processo;

        state.response.message = i18n.t('com.muralis.qcx.mensagem.DUEcalculada', {
          due: processo,
          estado: isNaoCalculada
            ? i18n.t('com.muralis.qcx.estado.calculada').toLowerCase()
            : i18n.t('com.muralis.qcx.estado.recalculada').toLowerCase(),
        });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );

          state.historyModel.last = {
            processo,
          };

          state.refresh = REFRESH;
        }
      })
      .addCase(calculaDUEAposZerarCamposAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(calculaDUEAposZerarCamposAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.calcularDUE', { erro: action.payload?.message }),
        };
      })
      .addCase(conferirByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(conferirByIdFromFaturaAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const declaracaoUnicaExportacaoConferida = action.payload.response.data;

        const { processo } = declaracaoUnicaExportacaoConferida.atributosAdicionais;

        state.list = state.list.map((declaracaoUnicaExportacao) =>
          declaracaoUnicaExportacao?.id === declaracaoUnicaExportacaoConferida?.id
            ? declaracaoUnicaExportacaoConferida
            : declaracaoUnicaExportacao
        );

        state.response.message = i18n.t('com.muralis.qcx.mensagem.DUEConferida', { due: processo });

        state.historyModel.last = {
          processo,
        };

        if (state.response.status === 200) {
          state.refresh = REFRESH;
        }
      })
      .addCase(conferirByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.conferirDUE', { erro: action.payload?.message }),
        };
      })
      .addCase(liberarByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(liberarByIdFromFaturaAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const declaracaoUnicaExportacaoLiberada = action.payload.response.data;

        const { processo } = declaracaoUnicaExportacaoLiberada.atributosAdicionais;

        state.list = state.list.map((declaracaoUnicaExportacao) =>
          declaracaoUnicaExportacao?.id === declaracaoUnicaExportacaoLiberada?.id
            ? declaracaoUnicaExportacaoLiberada
            : declaracaoUnicaExportacao
        );

        state.response.message = i18n.t('com.muralis.qcx.mensagem.DUELiberadaRegistro', { processo });

        state.historyModel.last = {
          processo,
        };

        if (state.response.status === 200) {
          state.refresh = REFRESH;
        }
      })
      .addCase(liberarByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: action.payload?.message
            ? i18n.t('com.muralis.qcx.erro.erroLiberarDUERegistroEspecifico', { registro: action.payload?.message })
            : i18n.t('com.muralis.qcx.erro.erroLiberarDUERegistro'),
        };
      })
      .addCase(activateByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdFromFaturaAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { atributosAdicionais } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.DUEAtivada', {
          numero: atributosAdicionais?.processo,
        });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(activateByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroAtivarDUE', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdFromFaturaAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { atributosAdicionais } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.DUEExcluida', { due: atributosAdicionais?.processo });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(inactivateByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroExcluirDUE', { erro: action.error.message }),
        };
      })
      .addCase(calculateByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(reabrirByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(reabrirByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;
      })
      .addCase(reabrirByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: action.payload?.message,
        };
      })
      .addCase(retificarByIdFromFaturaAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(retificarByIdFromFaturaAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { atributosAdicionais } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.DUERetificada', {
          due: atributosAdicionais?.processo,
        });
      })
      .addCase(retificarByIdFromFaturaAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: action.payload?.message,
        };
      })
      .addCase(fetchDuesByFilterAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchDuesByFilterAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.list = action.payload.data.itens;
        state.count = action.payload.data.totalNumberOfItens;
      })
      .addCase(fetchDuesByFilterAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: action.payload?.message,
        };
      });
  },
});

const selectClienteAtual = (state) => state.declaracaoUnicaExportacao.related.clienteAtual;

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  refresh,
  resetRefresh,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToTransferMode,
  changeToReversalMode,
  changeToLockedMode,
  resetMode,
  changeToBackgroundCreateMode,
  changeToBackgroundConsultMode,
  changeToBackgroundUpdateMode,
  changeToBackgroundDeleteMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  changeToSubBackgroundCreateMode,
  changeToSubBackgroundConsultMode,
  changeToSubBackgroundUpdateMode,
  changeToSubBackgroundDeleteMode,
  changeToBackgroundTransferMode,
  changeToBackgroundReversalMode,
  changeToLastSubBackgroundMode,
  resetSubBackgroundMode,
  changeTypeTo,
  resetType,
  changeLastHistoryModel,
  resetHistoryModel,
  resetLastHistoryModel,
  setModel,
  setProcesso,
  resetModel,
  addToList,
  updateOnList,
  setRelatedMercadoriaModel,
  updateSelectedMercadoria,
  resetRelatedMercadoriaModel,
  setRelatedDuplicataModel,
  resetRelatedDuplicataModel,
  setRelatedRegistroModel,
  resetRelatedRegistroModel,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
  setRelatedModelFollowUp,
  resetRelatedModelFollowUp,
  setRelatedSelectionAvailableItemList,
  resetRelatedSelectionAvailableItemList,
  setRelatedSelectionChangedItemList,
  resetRelatedSelectionChangedItemList,
  setRelatedSelectionItemList,
  resetRelatedSelectionItemList,
  setRelatedTransferenciaItem,
  resetRelatedTransferenciaItem,
  setRelatedItensXmlDanfeMetadata,
  resetRelatedItensXmlDanfeMetadata,
  addItemToChangedSelectionList,
  removeItemFromChangedSelectionList,
  setRelatedClienteAtual,
} = declaracaoUnicaExportacaoSlice.actions;

const declaracaoUnicaExportacaoActions = declaracaoUnicaExportacaoSlice.actions;

export {
  declaracaoUnicaExportacaoSlice,
  declaracaoUnicaExportacaoActions,
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  refresh,
  resetRefresh,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToTransferMode,
  changeToReversalMode,
  changeToLockedMode,
  resetMode,
  changeToBackgroundCreateMode,
  changeToBackgroundConsultMode,
  changeToBackgroundUpdateMode,
  changeToBackgroundDeleteMode,
  changeToBackgroundTransferMode,
  changeToBackgroundReversalMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  changeToSubBackgroundCreateMode,
  changeToSubBackgroundConsultMode,
  changeToSubBackgroundUpdateMode,
  changeToSubBackgroundDeleteMode,
  changeToLastSubBackgroundMode,
  resetSubBackgroundMode,
  changeTypeTo,
  resetType,
  changeLastHistoryModel,
  resetHistoryModel,
  resetLastHistoryModel,
  setModel,
  setProcesso,
  resetModel,
  addToList,
  updateOnList,
  setRelatedMercadoriaModel,
  updateSelectedMercadoria,
  resetRelatedMercadoriaModel,
  setRelatedDuplicataModel,
  resetRelatedDuplicataModel,
  setRelatedRegistroModel,
  resetRelatedRegistroModel,
  setResponse,
  setResponseMessage,
  clearResponse,
  setError,
  clearError,
  setRelatedModelFollowUp,
  resetRelatedModelFollowUp,
  setRelatedSelectionAvailableItemList,
  resetRelatedSelectionAvailableItemList,
  setRelatedSelectionChangedItemList,
  resetRelatedSelectionChangedItemList,
  setRelatedSelectionItemList,
  resetRelatedSelectionItemList,
  setRelatedTransferenciaItem,
  resetRelatedTransferenciaItem,
  setRelatedItensXmlDanfeMetadata,
  resetRelatedItensXmlDanfeMetadata,
  addItemToChangedSelectionList,
  removeItemFromChangedSelectionList,
  setRelatedClienteAtual,
  selectClienteAtual,
};

export default declaracaoUnicaExportacaoSlice.reducer;
