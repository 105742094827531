import { Autocomplete, ListItem, TextField } from '@mui/material';
import { SyntheticEvent, useMemo, useState } from 'react';

export type SimpleMultiSelectOption = {
  id: string | number;
  value: string;
};

const MultiSelectStyled = ({
  options,
  label,
  onChangeAction,
  controlledValues,
  disabled,
  disablePortal = true,
  onInputChange,
  placeholder,
}: {
  options: readonly SimpleMultiSelectOption[];
  label: string;
  onChangeAction?: (event: SyntheticEvent<Element, Event>, value: SimpleMultiSelectOption[] | null) => void;
  controlledValues?: SimpleMultiSelectOption[];
  disabled?: boolean;
  disablePortal?: boolean;
  onInputChange?: (inputValue: string) => void;
  placeholder?: string;
}) => {
  const [internalValues, setValue] = useState<SimpleMultiSelectOption[]>([]);

  const values = useMemo(() => controlledValues ?? internalValues, [controlledValues, internalValues]);

  return (
    <Autocomplete
      disabled={disabled}
      disablePortal={disablePortal}
      size="small"
      options={options}
      multiple
      selectOnFocus
      handleHomeEndKeys
      value={values}
      onChange={(event, newValues) => {
        setValue(newValues);
        if (onChangeAction) {
          onChangeAction(event, newValues); // Calling external change action
        }
      }}
      onInputChange={(event, inputValue) => {
        if (onInputChange) {
          onInputChange(inputValue); // Capturing input change
        }
      }}
      getOptionLabel={(option: SimpleMultiSelectOption) => option.value.toUpperCase()}
      renderOption={(props, option) => <ListItem {...props}>{option.value?.toUpperCase()}</ListItem>}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
          label={label.toUpperCase()}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default MultiSelectStyled;
