import { validateQueryParams } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import { NCM_SUBITEM, FILTER_ENDPOINT, CUSTOM_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAll() {
  return doGetQCXRequest([NCM_SUBITEM]);
}

async function fetchNCMsByFilterPagination(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([
      NCM_SUBITEM, FILTER_ENDPOINT, 'pagination'
    ], validatedParams);
  }
  return { data: [] };
}

async function fetchByFilter(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([NCM_SUBITEM, FILTER_ENDPOINT], validatedParams);
  }
  return { data: [] };
}

async function fetchNCMSubItemByCodes(codes = []) {
  return doPostQCXRequest([NCM_SUBITEM, FILTER_ENDPOINT, 'codes'], { codes });
}

async function fetchByCustomFilter(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([NCM_SUBITEM, FILTER_ENDPOINT, CUSTOM_ENDPOINT], validatedParams);
  }
  return { data: [] };
}

async function fetchById(id) {
  return doGetQCXRequest([NCM_SUBITEM, id]);
}

async function register(data) {
  return doPostQCXRequest([NCM_SUBITEM], data);
}

async function save(data) {
  return doPutQCXRequest([NCM_SUBITEM], data);
}

async function activateById(id) {
  return doPutQCXActivateRequest([NCM_SUBITEM], id);
}

async function inactivateById(id) {
  return doPutQCXInactivateRequest([NCM_SUBITEM], id);
}

const ncmSubitemAPI = {
  fetchAll,
  fetchByFilter,
  fetchByCustomFilter,
  fetchById,
  register,
  save,
  activateById,
  inactivateById,
  fetchNCMsByFilterPagination,
  fetchNCMSubItemByCodes,
};

export {
  ncmSubitemAPI,
  fetchAll,
  fetchByFilter,
  fetchByCustomFilter,
  fetchById,
  register,
  save,
  activateById,
  inactivateById,
  fetchNCMsByFilterPagination,
  fetchNCMSubItemByCodes,
};
