import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import { Grid, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectDocumentoAnexacao } from '../../features/documento-anexacao/documentoAnexacaoSlice';
import { isAutoLoadControl } from '../../utils/store/store-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';


import QCXPalavraChaveTextField     from './components/QCXPalavraChaveTextField';
import QCXPalavraChaveNumeroInteiroField     from './components/QCXPalavraChaveNumeroInteiroField';
import QCXPalavraChaveNumeroRealField     from './components/QCXPalavraChaveNumeroRealField';
import QCXPalavraChaveListaField     from './components/QCXPalavraChaveListaField';
import QCXPalavraChaveDataField     from './components/QCXPalavraChaveDataField';
import QCXPalavraChaveDataHoraField     from './components/QCXPalavraChaveDataHoraField';

export default function QCXPalavraChaveInput({
  label,
  name,
  autoLoad,
  initialValues,
  palavraChave,
  disabled,
  ...restProps
}) {
  
  function getComponentRenderPalavraChave() {
    const dictComponents = {
      'TEXTO' : () => (
          <Grid item xs={12} sm={6} md={4}>
              <QCXPalavraChaveTextField
                  palavraChave={palavraChave}
                  disabled={disabled}
              />
          </Grid>
      ),
      'NUMERO_INTEIRO' : () => (
        <Grid item xs={12} sm={6} md={4}>
            <QCXPalavraChaveNumeroInteiroField
                palavraChave={palavraChave}
                disabled={disabled}
            />
        </Grid>
      ),
      'NUMERO_REAL' : () => (
        <Grid item xs={12} sm={6} md={4}>
            <QCXPalavraChaveNumeroRealField
                palavraChave={palavraChave}
                disabled={disabled}
            />
        </Grid>
      ),
      'LISTA' : () => (
        <Grid item xs={12} sm={6} md={4}>
            <QCXPalavraChaveListaField
                palavraChave={palavraChave}
                disabled={disabled}
            />
        </Grid>
      ),
      'DATA' : () => (
        <Grid item xs={12} sm={6} md={4}>
            <QCXPalavraChaveDataField
                palavraChave={palavraChave}
                disabled={disabled}
            />
        </Grid>
      ),
      'DATA_HORA' : () => (
        <Grid item xs={12} sm={6} md={4}>
            <QCXPalavraChaveDataHoraField
                palavraChave={palavraChave}
                disabled={disabled}
            />
        </Grid>
      ),

    }

    if (palavraChave && palavraChave.tipoDado) {
      const componentRender = dictComponents[palavraChave.tipoDado];

      if (componentRender) {
        return componentRender();
      }      
    }

    return (
      <></>
    )
  }
  
  return (
    <>
      {getComponentRenderPalavraChave()}
    </>
  );
}
