import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Button, Grid, TextField } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUnidadeNegocioGuard } from "../../../../common/hooks/useUnidadeNegocioGuard";
import { ClientIdAndDescriptionDTO } from "../../../../common/types/clientData";
import { FollowUpDto } from "../../../../common/types/followUp";
import { selectUnidadeSelecionada } from "../../../../../features/usuario-logado/usuarioLogadoSlice";
import { KEYCLOAK_TOKEN_TIMEOUT } from "../../../../../App";
import { setErrorFeedback, setSuccessFeedback } from "../../../../../features/feedback/feedbackSlice";
import { postCrawler } from "../crawlers.helpers";
import { fetchClientesWithFilterDto } from "../../../../../features/cliente/clienteAPI";
import PageLayout from "../../../../common/layouts/pageLayout";
import LoadingIndicator from "../../../../common/components/loadingIndicator";
import FormHeaderMedium from "../../../../common/components/forms/formHeaderMedium";
import SelectStyled from "../../../../common/components/input/selectStyled";
import ErrorBadge from "../../../../common/components/forms/errorBadge";
import { SimpleServicoDTO } from "../../../../common/types/servico";
import { fetchByFilterDto } from "../../../../../features/servico/servicoAPI";
import TextFieldStyled from "../../../../common/components/input/textFieldStyled";

const CrawlersLiPage = () => {
  useUnidadeNegocioGuard();

  const { keycloak } = useKeycloak();
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);
  const { token } = keycloak;
  const dispatch = useDispatch();
  const [error, setError] = useState<string | string[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const url = process.env.REACT_APP_QCX_API_BASE_URL! + "/"
    + process.env.REACT_APP_QCX_API_LICENCA_IMPORTACAO_ENDPOINT!


  const [servico, setServico] = useState<number | undefined>(undefined);
  const [cliente, setCliente] = useState<number | undefined>(undefined);
  const [clientes, setClientes] = useState<ClientIdAndDescriptionDTO[] | undefined>(undefined);
  const [servicosData, setServicosData] = useState<SimpleServicoDTO[] | undefined>(undefined);
  const [numeroLi, setNumeroLi] = useState<string | undefined>(undefined);


  useEffect(() => {
    getClientsAndServicos();
  }, []);


  const clearData = () => {
    setIsLoading(false);
    setCliente(undefined);
    setNumeroLi(undefined)
    setServico(undefined)
  };

  const handleClienteChange = (event: SyntheticEvent, newValue: any) => {
    setCliente(newValue?.id);
  };

  const handleClienteServico = (event: SyntheticEvent, newValue: any) => {
    setServico(newValue?.id);
  };


  const getClientsAndServicos = async () => {
    setIsLoading(true);
    try {
      const { data: clientesData } = await fetchClientesWithFilterDto([{ name: 'filterDadosMinimos', value: 'TRUE' }]);
      setClientes(clientesData);
    } catch (error) {
      dispatch(setErrorFeedback({ message: 'Erro ao obter clientes' }));
    }

    try {
      const { data: servicosData } = await fetchByFilterDto([{ name: 'produto', value: "LI" }, { name: 'filterDadosMinimos', value: 'TRUE' }]);

      setServicosData(servicosData);
    } catch (error) {
      dispatch(setErrorFeedback({ message: 'Erro ao obter serviços' }));
    }

    setIsLoading(false);
  };

  const servicos = useMemo(() => servicosData?.map(servico => ({
    id: servico.id,
    nome: servico.nome,
  })) || [], [servicosData]);


  const handleSubmit = async () => {
    if (servico && token && selectedUnidade) {
      keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
      setIsLoading(true)

      const body = {
        cliente,
        servicoId: servico,
        numeroLi: numeroLi,
      }

      const message = await postCrawler(token, selectedUnidade.toString(), `${url}/xml-recuperacao-li`, body);

      if (message.statusCode === 500) {
        dispatch(setErrorFeedback({
          message: message.message
        }));
      } else {
        dispatch(setSuccessFeedback({
          message: message.message
        }));
      }

      clearData();
    }
  };


  return (
    <PageLayout
      title={"Crawlers - Li"}
      icon={<InsertDriveFileIcon color={"secondary"} />}
    >
      {isLoading ? (
        <LoadingIndicator message="Carregando Dados..." />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormHeaderMedium>
              Selecione as informações para recuperar a LI
            </FormHeaderMedium>
          </Grid>
          <Grid item sm={6}>
            <SelectStyled
              options={clientes?.map(cliente => ({
                id: cliente.id,
                value: `${cliente.description} - ${cliente.id}`,
              })) || []}
              onChangeAction={handleClienteChange}
              label={"Cliente"}
            />
          </Grid>
          <Grid item sm={6}>
            <SelectStyled
              options={servicos?.map((servico, index) => ({
                id: servico.id,
                value: `${servico.nome} - ${servico.id}`,
              })) || []}
              onChangeAction={handleClienteServico}
              label={"Serviços"}
            />
          </Grid>

          <Grid item sm={6}>

          <TextFieldStyled
              value={numeroLi || ""}
              label="Número do Registro da LI"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNumeroLi(e.target.value)}
            />
          </Grid>

          <Grid item sm={6}>
          <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={!cliente || !servico || !numeroLi || isLoading || !!error}
            >
              Gerar
            </Button>
          </Grid>


          {error && (
            <Grid item xs={12}>
              <ErrorBadge error={error} />
            </Grid>
          )}
    
        </Grid>
      )}
    </PageLayout>
  );
};

export default CrawlersLiPage;