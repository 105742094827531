import { Custeio } from 'quickcomex-api-types';

const selectCusteios = (state: any) => state.custeiosImportacao.list as Custeio[];
const selectCusteioCount = (state: any) => state.custeiosImportacao.count as number;
const selectCusteio = (state: any) => state.custeiosImportacao.model as Custeio;
const selectRelatedReportModel = (state: any) => state.custeiosImportacao.related.model.report;
const selectRelatedItemModel = (state: any) => state.custeiosImportacao.related.model.item;
const selectRelatedAdicaoModel = (state: any) => state.custeiosImportacao.related.model.adicao;
const selectRelatedCalculoModel = (state: any) => state.custeiosImportacao.related.model.calculo;
const selectRelatedDuplicataModel = (state: any) => state.custeiosImportacao.related.model.duplicata;
const selectRelatedRegistroModel = (state: any) => state.custeiosImportacao.related.model.registro;
const selectRelatedEmissaoIcmsModel = (state: any) => state.custeiosImportacao.related.model.emissaoIcms;
const selectHistoryModel = (state: any) => state.custeiosImportacao.historyModel;
const selectStatus = (state: any) => state.custeiosImportacao.status;
const selectRefresh = (state: any) => state.custeiosImportacao.refresh;
const selectMode = (state: any) => state.custeiosImportacao.mode.main;
const selectBackgroundMode = (state: any) => state.custeiosImportacao.mode.background;
const selectSubMode = (state: any) => state.custeiosImportacao.mode.sub;
const selectSubBackgroundMode = (state: any) => state.custeiosImportacao.mode.subBackground;
const selectError = (state: any) => state.custeiosImportacao.error;
const selectResponse = (state: any) => state.custeiosImportacao.response;
const selectConditionalSteps = (state: any) => state.custeiosImportacao.config.conditionalSteps;

const CusteiosSelectors = {
  selectCusteios,
  selectCusteioCount,
  selectCusteio,
  selectRelatedReportModel,
  selectRelatedItemModel,
  selectRelatedAdicaoModel,
  selectRelatedCalculoModel,
  selectRelatedDuplicataModel,
  selectRelatedRegistroModel,
  selectRelatedEmissaoIcmsModel,
  selectHistoryModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectSubBackgroundMode,
  selectError,
  selectResponse,
  selectConditionalSteps,
};

export {
  CusteiosSelectors,
  selectCusteios,
  selectCusteioCount,
  selectCusteio,
  selectRelatedReportModel,
  selectRelatedItemModel,
  selectRelatedAdicaoModel,
  selectRelatedCalculoModel,
  selectRelatedDuplicataModel,
  selectRelatedRegistroModel,
  selectRelatedEmissaoIcmsModel,
  selectHistoryModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectSubBackgroundMode,
  selectError,
  selectResponse,
  selectConditionalSteps,
};
