import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import debounce from 'lodash/debounce';
import QCXTaxaConversaoCambioForm from '../../../../../components/taxa-conversao-cambio/QCXTaxaConversaoCambioForm'; 
import { register, save, checkForDuplicates } from '../../../../../features/taxa-conversao-cambio/taxaConversaoCambioAPI'; 
import {
    addToList,
    changeToConsultMode,
    changeToCreateMode,
    changeToUpdateMode,
    fetchByIdAsync,
    resetModel,
    setModel,
    success,
    failure,
    selectTaxaConversaoCambio, 
    selectMode,
    loading,
    setError,
    updateOnList,
    setResponse,
} from '../../../../../features/taxa-conversao-cambio/taxaConversaoCambioSlice';
import { userHasCreateRole } from '../../../../../components/perfil/perfil-utils';
import { useGetUserEmail } from '../../../../../ts/common/hooks/keycloak/useGetUserEmail';

import {
    isConsultMode,
    isCreateMode,
    isUpdateMode,
} from '../../../../../utils/store/store-utils';
import { setSuccessFeedback, setErrorFeedback } from '../../../../../features/feedback/feedbackSlice';
import { selectMoedas } from '../../../../../features/moeda/moedaSlice';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';

export default function TaxaConversaoCambioRegistrationPage({ authInfo = {} }) {
    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const taxaConversaoCambio = useSelector(selectTaxaConversaoCambio); 
    const mode = useSelector(selectMode);
    const moedaList = useSelector(selectMoedas); 
    const usuarioLogado = useGetUserEmail();


    const isCreate = useMemo(() => isCreateMode(mode), [mode]);
    const isConsult = useMemo(() => isConsultMode(mode), [mode]);
    const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

    useEffect(() => {
        if (id) {
            dispatch(fetchByIdAsync(id));
            dispatch(changeToConsultMode());
        }
    }, [id, dispatch]);

    const handleChangeToCreate = () => {
        dispatch(changeToCreateMode());
    };

    const handleChangeToConsult = () => {
        dispatch(changeToConsultMode());
    };

    const handleChangeToUpdate = () => {
        dispatch(changeToUpdateMode());
    };

    const handleCancelUpdate = () => {
        const currentId = taxaConversaoCambio?.id || id; 

        if (currentId) {
            dispatch(fetchByIdAsync(currentId));
        }
        handleChangeToConsult();
    };

    const create = async (data) => {
        const executeDebounced = debounce(async () => {
            try {
                const response = await register(data);

                if (response.status === 201) {
                    dispatch(resetModel());
                    dispatch(setSuccessFeedback({ message: 'Taxa criada com sucesso!' }));
                    const created = response.data;
                    dispatch(addToList({ data: created }));
                    dispatch(success());
                    dispatch(
                        setResponse({
                            status: response.status,
                            data: created,
                            message: 'Taxa de Conversão de Câmbio Cadastrada com Sucesso', 
                        })
                    );
                    history.push(t('com.muralis.qcx.url.moduloOperacionaisTaxaConversaoCambio')); 
                }
            } catch (error) {
                dispatch(setErrorFeedback({ message: 'Erro ao cadastrar Taxa' }));

                dispatch(failure());
                dispatch(
                    setError({
                        message: 'Erro ao Cadastrar Taxa de Conversão de Câmbio', 
                    })
                );
            }
        }, 500);

        dispatch(loading());
        executeDebounced();
    };

    const update = async (data) => {
        const executeDebounced = debounce(async () => {
            try {
                const response = await save(data);

                if (response.status === 200) {
                    const saved = response.data;
                    dispatch(setSuccessFeedback({ message: 'Taxa atualizada com sucesso!' }));

                    dispatch(success());
                    dispatch(setModel(saved));
                    dispatch(updateOnList({ data: saved }));
                    dispatch(fetchByIdAsync(saved.id));
                    dispatch(
                        setResponse({
                            status: response.status,
                            data: saved,
                            message: 'Taxa de Conversão de Câmbio Atualizada com Sucesso', 
                        })
                    );
                    handleChangeToConsult(); 
                }
            } catch (error) {
                dispatch(setErrorFeedback({ message: 'Erro ao atualizar Taxa' }));

                dispatch(failure());
                dispatch(
                    setError({
                        message: 'Erro ao Atualizar Taxa de Conversão de Câmbio', 
                    })
                );
            }
        }, 500);

        dispatch(loading());
        executeDebounced();
    };

    const handleSubmit = async (data) => {
        data.active = true;
        data.user_email = usuarioLogado;
        
        const inicioData = new Date(data.vigencia.inicio);
        const fimData = new Date(data.vigencia.fim);
        const inicioDataString = inicioData.toISOString().slice(0, 10);
        const fimDataString = fimData.toISOString().slice(0, 10);
    
        const updatedVigencia = {
            ...data.vigencia,
            inicio: inicioDataString,
            fim: fimDataString,
        };
        
        data.vigencia = updatedVigencia;  // Re-assign the new vigencia object
    
        if (data.taxaConversao) {
            data.taxaConversao = String(data.taxaConversao).replace(',', '.');
        }
    
        if (Number.isNaN(Number(data.taxaConversao))) {
            dispatch(setErrorFeedback({ message: 'Taxa de Conversão deve ser um número válido no formato correto, por exemplo: 1.3342' }));
            return;
        }
    
        // Handle moeda and code generation
        if (data.moeda && data.moeda.id) {
            const selectedMoeda = moedaList.find(moeda => moeda.id === data.moeda.id); 
            if (selectedMoeda) {
                data.description = selectedMoeda.description;
            }
        }
    
        if (data.moeda && data.moeda.code && data.vigencia.inicio && data.vigencia.fim) {
            data.code = `${data.moeda.code}-${data.vigencia.inicio}-${data.vigencia.fim}`;
        }
        
        // Ensure inicio is before fim
        if (data.vigencia.inicio > data.vigencia.fim) {
            dispatch(setErrorFeedback({ message: 'A data de início não pode ser depois da data de fim.' }));
            return; 
        }
    
        try {
            const duplicateCheckResponse = await checkForDuplicates(data);
            const isDuplicate = duplicateCheckResponse?.data?.isDuplicate; 
            if (isDuplicate) {
                dispatch(setErrorFeedback({ message: 'Já existe uma Taxa de Conversão de Câmbio com esses dados. Por favor, verifique.' }));
                return; 
            }
        } catch (error) {
            dispatch(setErrorFeedback({ message: 'Erro ao verificar duplicatas. Por favor, tente novamente.' }));
        }
    
        // Call create or update based on mode
        if (isUpdate) {
            await update(data);
        } else {
            await create(data);
        }
    };
    

    const model = useMemo(() => (isCreate ? {} : taxaConversaoCambio), [isCreate, taxaConversaoCambio]); 
    const breadcrumbs = useMemo(() => [
        { link: { to: '/', name: t('com.muralis.qcx.inicio') } },
        { text: { name: t('com.muralis.qcx.cadastros') } },
        { link: { to: t('com.muralis.qcx.url.moduloOperacionais'), name: t('com.muralis.qcx.operacionais') } },
        { link: { to: t('com.muralis.qcx.url.moduloOperacionaisTaxaConversaoCambio'), name: t('com.muralis.qcx.taxaConversaoCambio') } }, 
        { text: { name: isCreate ? t('com.muralis.qcx.acoes.novo') : t('com.muralis.qcx.acoes.visualizar') } },
    ], [isCreate, t]);

    const pageTitle = useMemo(() =>
        isCreate
            ? 'Nova Taxa de Conversão de Câmbio' 
            : 'Editar Taxa de Conversão de Câmbio', 
        [isCreate, t]
    );

    return (
        <QCXRegistrationFormPageTemplate
            pageTitle={pageTitle}
            breadcrumbs={breadcrumbs}
            isCreate={isCreate}
            isConsult={isConsult}
            isUpdate={isUpdate}
            handleChangeToCreate={handleChangeToCreate}
            handleChangeToConsult={handleChangeToConsult}
            handleChangeToUpdate={handleChangeToUpdate}
            handleCancelUpdate={handleCancelUpdate}
            authInfo={authInfo}
        >
            {(formProps) => (
                <QCXTaxaConversaoCambioForm
                    initialValues={model}
                    handleSubmit={handleSubmit}
                    isConsult={isConsult}
                    isUpdate={isUpdate}
                    authInfo={authInfo}
                    {...formProps}
                />
            )}
        </QCXRegistrationFormPageTemplate>
    );
}
