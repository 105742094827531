import { createSlice } from '@reduxjs/toolkit';

interface LoadingState {
  isLoading: boolean;
}

interface FormState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previousState: any;
}

interface ApplicationState extends LoadingState, FormState {}

const initialState: ApplicationState = {
  isLoading: false,
  previousState: null,
};

const applicationStateSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    finishLoading: (state) => {
      state.isLoading = false;
    },
    setPreviousForm: (state, action) => {
      state.previousState = action.payload;
    },
  },
});

export const { startLoading, finishLoading, setPreviousForm } = applicationStateSlice.actions;

export default applicationStateSlice.reducer;
