import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { Alert, Button, Checkbox, FormControlLabel, Grid, Snackbar } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from 'react';
import DataTable from '../../../../common/components/dataTable';
import FileDragAndDrop from '../../../../common/components/fileDragAndDrop';
import FormHeader from '../../../../common/components/forms/formHeader';
import FormHeaderMedium from '../../../../common/components/forms/formHeaderMedium';
import { useUnidadeNegocioGuard } from '../../../../common/hooks/useUnidadeNegocioGuard';
import PageLayout from '../../../../common/layouts/pageLayout';
import { useCreateFaturaTotvs, useListAllCorrespondencias } from '../totvs.hooks';
import TotvsLeituraPageError from './components/totvsLeituraPageError';
import TotvsLeituraPageResult, { TotvsResponse } from './components/totvusLeituraPageResult';
import { useVerifyCorrelacaoImportador } from './totvsLeituraPage.hooks';
import { Fatura, TXTResult, processTXTFile, removerAcentos } from './utils/processTXTFile';
import QCXSelectServicoAutocomplete from '../../../../../shared-components/select-servico/QCXSelectServicoAutocomplete';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';
import { FormApi, SubmissionErrors } from 'final-form';
import { useDispatch } from 'react-redux';
import { setErrorFeedback, setSuccessFeedback } from '../../../../../features/feedback/feedbackSlice';
import QCXSelectBoolean from '../../../../../shared-components/select-boolean/QCXSelectBoolean';
import QCXFinalCheckboxField from '../../../../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXConfirmDialog from '../../../../../shared-components/dialog/QCXConfirmDialog';
import useConfigurePopUp from '../../../../common/hooks/popUps/useConfigurePopUp';
import { OnChange } from 'react-final-form-listeners';

const TotvsLeituraPage = () => {
  useUnidadeNegocioGuard();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [file, setFile] = useState<File>();
  const [txtData, setTxtData] = useState<TXTResult>();
  const [correspondencias, isCorrespondenciasLoading, isCorrespondenciasError] = useListAllCorrespondencias();
  const [limparExportadorFabricante, setLimparExportadorFabricante] = useState(false);

  const [leituraErrors, leituraWarnings, processedResult] = useVerifyCorrelacaoImportador(
    txtData,
    correspondencias,
    limparExportadorFabricante
  );
  const codigoImportador = useMemo(
    () => processedResult?.faturas?.[Object.keys(processedResult?.faturas ?? {})[0]].codigoImportador,
    [processedResult]
  );
  const [createFaturaTotvs] = useCreateFaturaTotvs({
    defaultErrorMessage: 'Erro ao criar fatura',
    defaultSuccessMessage: 'Fatura criada com sucesso',
  });

  const [faturas, setFaturas] = useState<string>();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>, file: File) => {
    setFile(file);
  };

  const limpaExportadorFabricante = useMemo(() => {}, []);

  const processFile = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const arrayBuffer = event.target?.result as ArrayBuffer;
          const decoder = new TextDecoder('utf-8');
          let text = decoder.decode(arrayBuffer);
          text = removerAcentos(text);
          const result = processTXTFile(text);
          setTxtData(result);
        };
        reader.readAsArrayBuffer(file);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (values: any) => {
    if (processedResult && codigoImportador && processedResult.faturas) {
      const agregaProduto = values?.agregaProduto || false;

      if (agregaProduto) {
        Object.keys(processedResult.faturas).forEach((key) => {
          const fatura: Fatura = processedResult.faturas![key];

          fatura.itens?.forEach((item) => {
            item.descricaoDetalhadaDaMercadoria = `${item.codigoDoProduto} - ${item.descricaoDetalhadaDaMercadoria}`;
          });
        });
      }

      let itensSemDescricaoDetalhada = new Set<string>();
      Object.values(processedResult.faturas).forEach((fatura) => {
        fatura.itens?.forEach((item) => {
          if (item.descricaoDetalhadaDaMercadoria === '') {
            itensSemDescricaoDetalhada.add(item.codigoDoProduto ?? 'Código não Informado');
          }
        });
      });

      if (itensSemDescricaoDetalhada.size > 0) {
        openConfirmFaltaDescricaoDetalhada({
          args: { values, processedResult },
          options: {
            message: `Descrição detalhada não informada para os itens: ${Array.from(itensSemDescricaoDetalhada).join(
              ', '
            )}`,
          },
        });
        return;
      }

      createFaturaTotvs({
        requestPayload: { ...processedResult, servicoId: values.servico.id },
        queryParams: { clientId: codigoImportador },
      });
      setFaturas(Object.keys(processedResult.faturas ?? {}).toString());
      setTxtData(undefined);
      setFile(undefined);
    }
  };

  const faturasColumns = useMemo(() => {
    return [
      { field: 'numeroDaFatura', headerName: 'N° da Fatura', flex: 1 },
      { field: 'codigoImportador', headerName: 'CNPJ Importador', flex: 1 },
      { field: 'codigoExportador', headerName: 'Cód. Exportador', flex: 1 },
      { field: 'codigoFabricante', headerName: 'Cód. Fabricante', flex: 1 },
      {
        field: 'codigoTransportadorInternacional',
        headerName: 'Cód. Transportador Internacional',
        flex: 1,
      },
      {
        field: 'codigoLocalEmbarque',
        headerName: 'Cód. Local Embarque',
        flex: 1,
      },
      { field: 'codigoAgenteCarga', headerName: 'Cód. Agente Carga', flex: 1 },
      { field: 'numeroItens', headerName: 'N° de Itens', flex: 1 },
    ] as GridColDef[];
  }, []);

  const faturasData = useMemo(() => {
    return Object.keys(txtData?.faturas ?? {}).map((key, index) => {
      if (txtData?.faturas) {
        return {
          id: index,
          numeroDaFatura: key,
          codigoImportador: txtData.itdi?.cnpj,
          // codigoImportador: txtData.faturas[key].codigoImportador,
          codigoExportador: txtData.faturas[key].codigoExportador,
          codigoFabricante: txtData.faturas[key].codigoFabricante,
          codigoTransportadorInternacional: txtData.faturas[key].codigoTransportadorInternacional,
          numeroItens: txtData?.faturas[key].numeroItens ?? 0,
          codigoLocalEmbarque: txtData.faturas[key].codigoLocalEmbarque,
          codigoAgenteCarga: txtData.faturas[key].codigoAgenteCarga,
        };
      } else {
        return {};
      }
    });
  }, [txtData]);

  const [openConfirmFaltaDescricaoDetalhada, configureConfirmFaltaDescricaoDetalhada] = useConfigurePopUp({
    initialOptions: {
      title: 'Falta Descrição Detalhada',
      message: 'Existem itens sem descrição detalhada, deseja continuar?',
    },
    primaryConfirmCallback: (data) => {
      createFaturaTotvs({
        requestPayload: { ...processedResult, servicoId: data.values.servico.id },
        queryParams: { clientId: codigoImportador },
      });
      setFaturas(Object.keys(data.processedResult.faturas ?? {}).toString());
      setTxtData(undefined);
      setFile(undefined);
    },
  });

  return (
    <PageLayout title={'Leitura'} icon={<HistoryEduIcon color={'secondary'} />}>
      <>
        <Form onSubmit={processFile}>
          {({ values }) => (
            <>
              <form action="" onSubmit={(e) => processFile(e)}>
                <Grid container spacing={2}>
                  <FormHeader>Documentos a serem criados / editados</FormHeader>
                  <Grid item xs={12}>
                    <QCXSelectServicoAutocomplete
                      id="autocomplete-select-servico-field"
                      key="autocomplete-select-servico-field"
                      name="servico.id"
                      label={t('com.muralis.qcx.servico.labelSingular')}
                      initialValues={undefined}
                      produto="FATURA"
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <QCXFinalCheckboxField
                      id="agregaProduto"
                      key="agregaProduto"
                      name="agregaProduto"
                      label="Agrega Código do Produto na Descrição"
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <OnChange name="limpaExportadorFabricante">
                      {(value, previous) => setLimparExportadorFabricante(value)}
                    </OnChange>
                    <QCXFinalCheckboxField
                      id="limpaExportadorFabricante"
                      key="limpaExportadorFabricante"
                      name="limpaExportadorFabricante"
                      label="Limpar Exportador e Fabricante dos itens"
                      required={false}
                    />
                  </Grid>
                  <FormHeaderMedium>Seleção de arquivo</FormHeaderMedium>
                  <FileDragAndDrop accept={'.txt'} onChangeAction={handleFileChange}></FileDragAndDrop>
                  <Grid item xs={12} textAlign="right">
                    <Button type="submit" color="secondary" variant="contained">
                      Importar arquivo
                    </Button>
                  </Grid>
                </Grid>
              </form>
              {txtData && !isCorrespondenciasLoading && !isCorrespondenciasError && (
                <Grid container spacing={2}>
                  <FormHeader>Revisão dos dados</FormHeader>
                  <FormHeaderMedium>Faturas</FormHeaderMedium>
                  <Grid item xs={12}>
                    <DataTable columns={faturasColumns} rows={faturasData}></DataTable>
                  </Grid>
                  {(leituraErrors.length > 0 || leituraWarnings.length > 0) && (
                    <TotvsLeituraPageError errors={leituraErrors} warnings={leituraWarnings} />
                  )}
                  {isCorrespondenciasError && (
                    <TotvsLeituraPageError
                      errors={[{ mensagem: 'Erro ao carregar as dependencias', tipo: 'Erro', origem: 'Correlação' }]}
                      warnings={[]}
                    />
                  )}
                </Grid>
              )}
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} textAlign="right">
                  <Button
                    color="secondary"
                    variant="contained"
                    style={{ margin: '0 10px 0 0' }}
                    onClick={() => {
                      setTxtData(undefined);
                      setFile(undefined);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={leituraErrors.length > 0 || Object.keys(processedResult ?? {}).length === 0}
                    color="secondary"
                    variant="contained"
                    onClick={() => handleSubmit(values)}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Form>
        <QCXConfirmDialog
          key="confirm-dialog-activate-inactivate-di-duimp"
          id="confirm-dialog-activate-inactivate-di-duimp"
          open={configureConfirmFaltaDescricaoDetalhada?.isPopUpOpen}
          title={configureConfirmFaltaDescricaoDetalhada?.title}
          content={configureConfirmFaltaDescricaoDetalhada?.message}
          endContent={configureConfirmFaltaDescricaoDetalhada?.endMessage}
          onConfirm={configureConfirmFaltaDescricaoDetalhada.confirmPrimaryAction}
          onClose={configureConfirmFaltaDescricaoDetalhada?.reset}
        />
      </>
    </PageLayout>
  );
};

export default TotvsLeituraPage;
