import { Box, IconButton } from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import { Autocomplete } from 'mui-rff';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchByFilterAsync, selectNcmSubitens } from '../../features/ncm-subitem/ncmSubitemSlice';
import QCXFinalTextField from '../final-text-field/QCXFinalTextField';

export default function QCXSelectNcmSubItemAutocomplete({
  id,
  key,
  label,
  name,
  textFieldProps = {
    variant: 'outlined',
    size: 'small',
    InputLabelProps: {
      shrink: true,
      style: {
        textTransform: 'uppercase',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  fieldProps,
  required,
  ...restProps
}) {
  const list = useSelector(selectNcmSubitens);
  const form = useForm();
  const { initialValues } = useFormState();
  const { values } = useFormState();

  const valueFromForm = useMemo(() => _.get(values, 'ncm'), [values]);

  useEffect(() => {
    // não é a solução ideal
    // isso garante que não vai atualizar caso seja um valor de escrita manual
    // mas ainda deixa atualizar se mudar o valor do forms
    if (!valueFromForm?.id) return;

    // se o valor for preenchido como side effect ao preencher o partnumber, cai nesse if
    if (valueFromForm.id && valueFromForm.code && valueFromForm.description && valueFromForm.unidadeDeMedida) {
      setValue(valueFromForm);
      return;
    }

    const ncmFromStore = list.find((n) => n.id === valueFromForm.id);

    if (!ncmFromStore) return;

    form.change('ncm', ncmFromStore);
    setValue(ncmFromStore);
  }, [valueFromForm]);

  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState('');

  const handleSetInputValue = (_event, newInputValue, reason) => {
    if (reason !== 'clear') {
      setInputValue(newInputValue);
      return;
    }

    setInputValue('');
  };

  const [value, setValue] = useState(null);

  useEffect(() => {
    const initialValue = _.get(initialValues, 'ncm');
    setValue(initialValue);
  }, [initialValues]);

  const handleAutocompleteChange = (_event, selectedValue) => {
    form.change('ncm', selectedValue);
  };

  const handleFilter = useCallback((filterValue) => {
    if (filterValue.length !== 8) {
      return;
    }
    dispatch(fetchByFilterAsync([{ name: 'code', value: filterValue }]));
  }, []);

  const handleSearch = useCallback((filterValue) => {
    dispatch(
      fetchByFilterAsync([
        { name: 'code', value: filterValue },
        { name: 'search', value: true },
      ])
    );
  }, []);

  const getOptionLabel = useCallback((option) => `${option?.code} - ${option?.description}`, []);

  const getOptionValue = useCallback((option) => option?.id, []);

  const filterOptions = useMemo(
    () =>
      createFilterOptions({
        stringify: (option) => `${option?.code} / ${option?.description}`,
      }),
    []
  );

  useEffect(() => {
    if (list.length !== 1 || inputValue === '') {
      return;
    }

    if (values?.ncm?.id === list[0].id && values?.ncm?.unidadeDeMedida?.id === list[0].unidadeDeMedida?.id) {
      return;
    }

    setValue(list[0]);
    form.change('ncm.id', list[0].id);
    form.change('ncm.unidadeDeMedida', list[0]?.unidadeDeMedida);
  }, [list]);

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box width="100%">
        <Autocomplete
          name={name}
          label={label}
          options={list}
          freeSolo
          renderInput={(params) => (
            <QCXFinalTextField
              {...params}
              {...textFieldProps}
              name="ncm.code"
              label={label}
              fieldProps={fieldProps}
              required={required}
            />
          )}
          value={value}
          inputValue={inputValue}
          onChange={handleAutocompleteChange}
          onInputChange={handleSetInputValue}
          onBlur={() => handleFilter(inputValue)}
          textFieldProps={textFieldProps}
          filterOptions={filterOptions}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          fieldProps={fieldProps}
          {...restProps}
        />
      </Box>
      <Box style={{ marginRight: -16 }}>
        <IconButton onClick={() => handleSearch(inputValue)} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
