import { createSlice } from '@reduxjs/toolkit';
import {
  BACKGROUND_CREATE_MODE,
  CONSULT_MODE,
  CREATE_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  UPDATE_MODE,
} from '../mode';
import {
  activateByIdAsync,
  fetchAllAsync,
  fetchByIdAsync,
  inactivateByIdAsync,
  registerAsync,
  saveAsync,
} from './modeloDanfeThunks';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
  },
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
  model: {
    formatoImpressao: '',
    agruparExportador: null,
    agruparFabricante: null,
    agruparCfop: null,
    agruparAliquotaIcms: null,
    exibirCif: null,
    quantidadeCasasDecimais: null,
    despesasAcessoriasPis: false,
    despesasAcessoriasCofins: false,
    despesasAcessoriasTaxaSiscomex: false,
    despesasAcessoriasOutrasDespesasAduaneiras: false,
    clientes: [],
  },
};

const modeloDanfeSlice = createSlice({
  name: 'modeloDanfe',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    resetList: (state) => {
      state.list = [];
    },
    updateClientesAssociados: (state, action) => {
      state.model.clientes = [...action.payload];
    },
    setClientes: (state, action) => {
      state.model.clientes = [...action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.response.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregamentoFormulariosDANFE', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.model = action.payload.response.data;
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroBuscaModeloDANFE', { erro: action.error.message }),
        };
      })
      .addCase(registerAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(registerAsync.fulfilled, (state) => {
        state.status = IDLE_STATUS;
      })
      .addCase(registerAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCadastrarModeloDANFE', { erro: action.error.message }),
        };
      })
      .addCase(saveAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(saveAsync.fulfilled, (state) => {
        state.status = IDLE_STATUS;
      })
      .addCase(saveAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroSalvarModeloDANFE', { erro: action.error.message }),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { description } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.modeloDANFEAtivado', { modelo: description });

        if (state.response.status === 200) {
          state.list = state.list.map((current) => {
            if (current.id === action.payload.response.data.id) {
              return action.payload.response.data;
            }
            return current;
          });
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erroAtivarModeloDANFE', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { description } = action.payload.response.data;

        state.response.message = i18n.t('com.muralis.qcx.mensagem.modeloDANFEInativado', { modelo: description });

        if (state.response.status === 200) {
          state.list = state.list.map((current) => {
            if (current.id === action.payload.response.data.id) {
              return action.payload.response.data;
            }
            return current;
          });
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroInativarModeloDANFE', { erro: action.error.message }),
        };
      });
  },
});

const {
  addToList,
  changeStatusTo,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  clearError,
  clearResponse,
  failure,
  loading,
  resetList,
  resetMode,
  setModel,
  resetModel,
  resetStatus,
  setError,
  setResponse,
  success,
  preparingAction,
  updateOnList,
  updateClientesAssociados,
  setClientes,
} = modeloDanfeSlice.actions;

const modeloDanfeActions = {
  ...modeloDanfeSlice.actions,
};

export {
  modeloDanfeActions,
  addToList,
  changeStatusTo,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  clearError,
  clearResponse,
  failure,
  loading,
  resetList,
  resetMode,
  setModel,
  resetModel,
  resetStatus,
  setError,
  setResponse,
  success,
  preparingAction,
  updateOnList,
  updateClientesAssociados,
  setClientes,
};

export default modeloDanfeSlice.reducer;
