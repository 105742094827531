import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import BaseSelectWithOptions from '../../../../../shared-components/dados-embarque/base-components/BaseSelectWithOptions';

export type FinalSelectMotivoCancelamentoFollowupProps = {
  label: string;
  name: string;
  required?: boolean;
};

export enum FinalSelectMotivoCancelamentoFollowupEnum {
  EmbarqueCancelado = 'EMBARQUE_CANCELADO',
  EmbarqueAbertoNaFilialIncorreta = 'EMBARQUE_ABERTO_NA_FILIAL_INCORRETA',
  EmbarqueAbertoComServicoIncorreto = 'EMBARQUE_ABERTO_COM_SERVICO_INCORRETO',
  EmbarqueAbertoComClienteIncorreto = 'EMBARQUE_ABERTO_COM_CLIENTE_INCORRETO',
}

const FinalSelectMotivoCancelamentoFollowup = ({
  required = false,
  ...props
}: FinalSelectMotivoCancelamentoFollowupProps) => {
  const { t } = useTranslation();

  function getLabelFrom(value: FinalSelectMotivoCancelamentoFollowupEnum) {
    switch (value) {
      case FinalSelectMotivoCancelamentoFollowupEnum.EmbarqueCancelado:
        return t('com.muralis.qcx.followup.embarqueCancelado');
      case FinalSelectMotivoCancelamentoFollowupEnum.EmbarqueAbertoNaFilialIncorreta:
        return t('com.muralis.qcx.followup.embarqueFilialIncorreta');
      case FinalSelectMotivoCancelamentoFollowupEnum.EmbarqueAbertoComServicoIncorreto:
        return t('com.muralis.qcx.followup.embarqueServicoIncorreto');
      case FinalSelectMotivoCancelamentoFollowupEnum.EmbarqueAbertoComClienteIncorreto:
        return t('com.muralis.qcx.followup.embarqueClienteIncorreto');
      default:
        return '';
    }
  }

  const list = useMemo(
    () => [
      {
        id: getLabelFrom(FinalSelectMotivoCancelamentoFollowupEnum.EmbarqueCancelado),
        value: FinalSelectMotivoCancelamentoFollowupEnum.EmbarqueCancelado,
        label: getLabelFrom(FinalSelectMotivoCancelamentoFollowupEnum.EmbarqueCancelado),
      },
      {
        id: getLabelFrom(FinalSelectMotivoCancelamentoFollowupEnum.EmbarqueAbertoNaFilialIncorreta),
        value: FinalSelectMotivoCancelamentoFollowupEnum.EmbarqueAbertoNaFilialIncorreta,
        label: getLabelFrom(FinalSelectMotivoCancelamentoFollowupEnum.EmbarqueAbertoNaFilialIncorreta),
      },
      {
        id: getLabelFrom(FinalSelectMotivoCancelamentoFollowupEnum.EmbarqueAbertoComServicoIncorreto),
        value: FinalSelectMotivoCancelamentoFollowupEnum.EmbarqueAbertoComServicoIncorreto,
        label: getLabelFrom(FinalSelectMotivoCancelamentoFollowupEnum.EmbarqueAbertoComServicoIncorreto),
      },
      {
        id: getLabelFrom(FinalSelectMotivoCancelamentoFollowupEnum.EmbarqueAbertoComClienteIncorreto),
        value: FinalSelectMotivoCancelamentoFollowupEnum.EmbarqueAbertoComClienteIncorreto,
        label: getLabelFrom(FinalSelectMotivoCancelamentoFollowupEnum.EmbarqueAbertoComClienteIncorreto),
      },
    ],
    []
  );

  return <BaseSelectWithOptions required={required} list={list} {...props} />;
};

export default FinalSelectMotivoCancelamentoFollowup;
