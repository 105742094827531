import _, { isArrayLikeObject, isEmpty, isFunction } from 'lodash';
import moment, { isMoment } from 'moment';
import { forceParseToFixedNumber, forceParseToNumber, parseToNumber } from './parse-utils';

const isStrictEquals = (right, left) => right === left;

const isValid = (value) => ![undefined, null, NaN].includes(value);

const padLeadingZeros = (num, size = 0) => {
  const s = `${num || ''}`;
  const zeros = '0'.repeat(size - s.length);

  return zeros.concat(s);
};

const padTrailingZeros = (num, size = 0) => {
  const s = `${num || ''}`;
  const zeros = '0'.repeat(size - s.length);

  return s.concat(zeros);
};

const normalizePercentual = (value, digitsBeforeComma = 1) => {
  const decimalPercentualDigits = String(value).substring(digitsBeforeComma);
  const plainPercentual = `${String(value).substring(0, digitsBeforeComma)}.${decimalPercentualDigits}`;

  return Number(plainPercentual);
};

const unnormalizePercentual = (value, size = 3) => padTrailingZeros(String(value).replace('.', ''), size);

const normalizeNumeral = (
  numeral,
  currentDecimalSeparator = ',',
  currentThousantSeparator = '.',
  defaultDecimalSeparator = '.'
) => {
  const parsedNumeral = parseToNumber(numeral);

  const isNormalizedNumber = isValid(parsedNumeral);

  if (isNormalizedNumber) {
    return parsedNumeral;
  }
  const normalized = String(numeral)
    .replace(/[^\d,.]/g, '')
    .replaceAll(currentThousantSeparator, '')
    .replace(currentDecimalSeparator, defaultDecimalSeparator);

  return forceParseToNumber(normalized);
};

const normalizeDigits = (value) => String(value).replace(/\D/g, '');

const normalizeAlphanumeric = (value) => String(value).replace(/[^A-z0-9]/g, '');

const normalizeCPF = (cpf) => normalizeDigits(cpf);

const normalizeCNPJ = (cnpj) => normalizeDigits(cnpj);

const normalizeCEP = (cep) => normalizeDigits(cep);

const normalizeZipCode = (zipCode) => normalizeDigits(zipCode);

const normalizePhoneNumber = (phoneNumber) => normalizeDigits(phoneNumber);

/**
 * Normaliza um objeto de data (data de calendário).
 */
/**
 * @deprecated This function is no longer recommended for use.
 * Consider using convertLocalDateTimeStringToUTC from dateTime-utils.ts instead.
 */
const normalizeData = (data, dateFormat = 'YYYY-MM-DD') => {
  if (isMoment(data) && data.isValid()) {
    return data.format(dateFormat);
  }

  if (isMoment(data) && !data.isValid()) {
    return null;
  }

  return data;
};

/**
 * Formata uma data e hora, combinando com o timezone, que deve ser uma string
 * que vai aparecer depois da data/hora formatada.
 */
const normalizeZonedDateTime = (datetime, timezone) => {
  const format = 'YYYY-MM-DDThh:mm:ss';

  if (!datetime) {
    return null;
  }

  /** @type {string} */
  let isoFormatted = '';

  if (isMoment(datetime) && datetime.isValid()) {
    isoFormatted = datetime.format(format);
  } else if (isMoment(datetime) && !datetime.isValid()) {
    return null;
  } else if (moment(datetime).isValid()) {
    isoFormatted = moment(datetime).format(format);
  } else {
    return null;
  }

  // Add timezone.
  return isoFormatted + timezone;
};

const normalizeYear = (date, format = 'YYYY') => normalizeData(date, format);

/**
 * @deprecated This function is no longer recommended for use.
 * Consider using convertUTCStringToLocalTime from dateTime-utils.ts instead.
 */
const unnormalizeData = (data, inputFormat = 'YYYY-MM-DD') =>
  typeof data === 'string' ? moment(data, inputFormat) : data;

const formatDate = (date, inputFormat = 'YYYY-MM-DD', dateFormat = 'DD/MM/YYYY') =>
  normalizeData(unnormalizeData(date, inputFormat), dateFormat);

const unnormalizeNumeral = (value, format, currentDecimalSeparator = '.', defaultDecimalSeparator = ',') => {
  if (value && String(value).includes('e') && !Number.isNaN(value)) {
    // fix numeros muito pequenos ou muito grandes (ex: 8.079495905384191e-9)
    value = Number(value).toFixed(7);
  }

  const unnormalized = String(value).replace(currentDecimalSeparator, defaultDecimalSeparator);
  if (isFunction(format)) {
    return format(unnormalized);
  }
  return unnormalized;
};

const forceUnnormalizeNumeral = (value, format, currentDecimalSeparator = '.', defaultDecimalSeparator = ',') => {
  const fixedNumber = forceParseToFixedNumber(value);
  const unnormalized = String(fixedNumber).replace(currentDecimalSeparator, defaultDecimalSeparator);
  if (isFunction(format)) {
    return format(unnormalized);
  }
  return unnormalized;
};

const unnormalizeCurrency = (value, decimals = 2) => unnormalizeNumeral(value, decimals);

const debounce = (callback, millis = 1000) => setTimeout(callback, millis);

const executeFunctionIfValid = (fn) => {
  if (isFunction(fn)) {
    fn();
  }
};

function getOptionByValueInList(list = [], value) {
  if (isEmpty(value)) {
    return undefined;
  }

  return list.find((option) => option?.value === value);
}

const trimValue = (value) => {
  if (value) {
    return String(value).trim();
  }
  return value;
};

const padStart = (value, targetLength, padString) => {
  if (value) {
    return String(value).padStart(targetLength, padString);
  }
  return value;
};

const getCurrentDate = () => moment();

const getValueOrDefault = (validate, value, defaultValue) => {
  if (isArrayLikeObject(value)) {
    return value.length > 0 && validate(value[0]) ? value : defaultValue;
  }

  return validate(value) ? value : defaultValue;
};

const getValueFromSingletonList = (validate, defaultValue, singletonList = []) => {
  const [value] = singletonList;

  return validate(value) ? value : defaultValue;
};

const parseValueToSingletonList = (validate, value, defaultValue = undefined) => {
  if (isArrayLikeObject(value) && value.length > 0) {
    return [value[0]];
  }

  if (validate(value) || !value) {
    return defaultValue ? [defaultValue] : [];
  }

  return [value];
};

const sortCollectionBy = (collection, ...iterators) => {
  const finalCollection = collection || [];

  if (_.isEmpty(iterators)) {
    return finalCollection;
  }

  const sortedCollection = _.sortBy(finalCollection, iterators);

  return sortedCollection;
};

const MatcherConfigureUtils = Object.freeze({
  getSpecificByCode: (defaultCode) => {
    const configureSpecificMatcherWith = (list = []) => {
      const filteredList = (list || [])?.map((item) => ({
        id: item?.id,
        code: item?.code,
      }));

      const isConfiguredById = (id) => {
        const found = filteredList?.find((item) => item?.id === id);

        return defaultCode === found?.code;
      };

      return isConfiguredById;
    };

    return configureSpecificMatcherWith;
  },
  getGeneralByCodes: (list = []) => {
    const filteredList = (list || [])?.map((item) => ({
      id: item?.id,
      code: item?.code,
    }));

    const isSomeConfiguredById = (id, codesToCompare = []) => {
      const found = filteredList?.find((item) => item?.id === id);

      return codesToCompare?.includes(found?.code);
    };

    return isSomeConfiguredById;
  },
});

const omitVoidPropertiesBy = (obj) => {
  const entries = Object.entries(obj);

  for (let i = entries.length - 1; i > -1; i -= 1) {
    const [key, value] = entries[i];

    if (value && _.isObjectLike(value)) {
      omitVoidPropertiesBy(value);
    }

    if (
      (value && _.isObjectLike(value) && !Object.keys(value).length) ||
      value === null ||
      value === undefined ||
      value.length === 0
    ) {
      if (Array.isArray(obj)) {
        obj.splice(key, 1);
      } else {
        delete obj[key];
      }
    }
  }

  return obj;
};

const isWeekend = (date) => date.day() % 6 === 0;

const getLocalidadeOrDefault = (endereco, defaultValue = '') => {
  if (endereco?.cidade?.code && endereco?.cidade?.estado?.code && endereco?.cidade?.estado?.pais?.code) {
    return `${endereco?.cidade?.code} - ${endereco?.cidade?.description}, ${endereco?.cidade?.estado?.code} - ${endereco?.cidade?.estado?.description}, ${endereco?.cidade?.estado?.pais?.code} - ${endereco?.cidade?.estado?.pais?.description}`;
  }

  if (endereco?.cidade?.code && endereco?.cidade?.estado?.code) {
    return `${endereco?.cidade?.code} - ${endereco?.cidade?.description}, ${endereco?.cidade?.estado?.code} - ${endereco?.cidade?.estado?.description}`;
  }

  if (endereco?.cidade?.code) {
    return `${endereco?.cidade?.code} - ${endereco?.cidade?.description}`;
  }

  if (endereco?.pais?.code && endereco?.estado?.code) {
    return `${endereco?.pais?.code} - ${endereco?.pais?.description}, ${endereco?.estado?.code} - ${endereco?.estado?.description}`;
  }

  if (endereco?.pais?.code) {
    return `${endereco?.pais?.code} - ${endereco?.pais?.description}`;
  }

  return defaultValue;
};

const SingletonUtils = {
  getValueOrDefault,
  fromList: getValueFromSingletonList,
  toList: parseValueToSingletonList,
};

export {
  isStrictEquals,
  padLeadingZeros,
  padTrailingZeros,
  normalizePercentual,
  normalizeNumeral,
  normalizeDigits,
  normalizeAlphanumeric,
  normalizeCPF,
  normalizeCNPJ,
  normalizeCEP,
  normalizeZipCode,
  normalizePhoneNumber,
  normalizeData,
  normalizeZonedDateTime,
  normalizeYear,
  unnormalizeData,
  unnormalizeNumeral,
  unnormalizeCurrency,
  unnormalizePercentual,
  formatDate,
  forceUnnormalizeNumeral,
  isValid,
  debounce,
  executeFunctionIfValid,
  getOptionByValueInList,
  trimValue,
  padStart,
  getCurrentDate,
  SingletonUtils,
  MatcherConfigureUtils,
  getValueFromSingletonList,
  parseValueToSingletonList,
  sortCollectionBy,
  omitVoidPropertiesBy,
  isWeekend,
  getLocalidadeOrDefault,
};
