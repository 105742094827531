import { isArray } from 'lodash';

const parseToNumber = (value, fractionDigits = 0) => {
  const number = Number(value);
  return fractionDigits ? Number(number.toFixed(fractionDigits)) : number;
};

const forceParseToNumber = (value, fractionDigits = 0) => {
  const parsed = parseToNumber(value, fractionDigits);
  const isParsedNaN = Number.isNaN(parsed);
  return isParsedNaN ? parseToNumber(0, fractionDigits) : parsed;
};

const forceParseToFixedNumber = (value) => {
  const number = forceParseToNumber(value);
  const result = String(number).includes('e-') ? String(number).match(/[0-9]+$/g) : null;

  if (isArray(result) && result.length) {
    const fractionDigits = result[0];
    return number.toFixed(fractionDigits);
  }

  return number;
};

const parseToBoolean = (value) => Boolean(value);

const parseToString = (value) => (value ? String(value) : '');

const getStringOrUndefined = (value) => (value ? parseToString(value) : undefined);

export {
  parseToNumber,
  forceParseToNumber,
  forceParseToFixedNumber,
  parseToBoolean,
  parseToString,
  getStringOrUndefined,
};
