import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../../shared-components/dados-embarque/base-components/BaseSelectWithOptions';

function SelectNumeroProcessoEasy({ isNumerario, ...props }) {
  const fullList = [
    {
      id: 'NUMERO_FATURA',
      label: 'Número da Fatura',
      value: 'NUMERO_FATURA',
    },
    {
      id: 'NUMERO_LICENCIAMENTO',
      label: 'Número do Processo', // Apenas alteramos a tag para facilitar para o usuário
      value: 'NUMERO_LICENCIAMENTO',
    },
    {
      id: 'NUMERO_CONHECIMENTO',
      label: 'Número do Conhecimento',
      value: 'NUMERO_CONHECIMENTO',
    },
    {
      id: 'REFERENCIA_CLIENTE',
      label: 'Referência do Cliente',
      value: 'REFERENCIA_CLIENTE',
    },
  ];

  const reducedList = [
    {
      id: 'NUMERO_CONHECIMENTO',
      label: 'Número do Conhecimento',
      value: 'NUMERO_CONHECIMENTO',
    },
    {
      id: 'REFERENCIA_CLIENTE',
      label: 'Referência do Cliente',
      value: 'REFERENCIA_CLIENTE',
    },
  ];

  const list = useMemo(() => (isNumerario ? reducedList : fullList), [isNumerario]);

  return <BaseSelectWithOptions list={list} {...props} />;
}

export default SelectNumeroProcessoEasy;
