import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from 'react-router';
import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import { generateSpreadsheetLivroContas } from './relatorioLivrocontasPage.helpers';
import { useUnidadeNegocioGuard } from '../../../common/hooks/useUnidadeNegocioGuard';
import { SelectChangeEvent } from '@mui/material';

import {
  selectUnidadeSelecionada,
  selectUnidadesDeNegocioAssociadas,
} from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { GridItemModalProps } from '../../../common/components/cardsGrid/cardsModalGrid';
import { Field, Form } from 'react-final-form';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXTextField from '../../../../shared-components/text-field/QCXTextField';
import theme from '../../../common/themes/theme';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      minWidth: '50vw',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    gap: '10px',
  },
  input: {
    height: 40,
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  button: {
    borderRadius: '4px',
  },
});

const RelatorioLivroContasPage = ({ title, isOpen, closeModal }: GridItemModalProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const { token } = keycloak;

  keycloak.updateToken(300);

  const selectedUnit = useSelector(selectUnidadeSelecionada);
  const businessUnits = useSelector(selectUnidadesDeNegocioAssociadas);

  const [datas, setDatas] = useState({
    dataInicio: '',
    dataFim: '',
  });
  useUnidadeNegocioGuard();

  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState<string[]>([]);
  const [selectedFornecedores, setSelectedFornecedores] = useState<string[]>([]); // Fornecedores multi-select
  const [origem, setOrigem] = useState('');

  const validarDatas = (datas: { dataInicio: string; dataFim: string }): boolean => {
    if (!datas.dataInicio || !datas.dataFim) {
      return false;
    }
    const dataInicio = new Date(datas.dataInicio);
    const dataFim = new Date(datas.dataFim);
    return dataInicio <= dataFim;
  };

  const handleChangeDataInicio = (isoDate: string) => {
    const value = isoDate.split('T')[0];

    setDatas((prevDatas) => ({ ...prevDatas, dataInicio: value }));
  };

  const handleChangeDataFim = (isoDate: string) => {
    const value = isoDate.split('T')[0];

    setDatas((prevDatas) => ({ ...prevDatas, dataFim: value }));
  };

  const handleBusinessChange = (event: SelectChangeEvent<string | string[]>) => {
    const value = event.target.value as string[];
    setSelectedBusinessUnits(value);
  };

  const handleFornecedorChange = (event: SelectChangeEvent<string | string[]>) => {
    const value = event.target.value as string[];
    setSelectedFornecedores(value);
  };

  const handleOrigemChange = (event: SelectChangeEvent<string>) => {
    setOrigem(event.target.value);
  };

  const handleSubmit = useCallback(async () => {
    keycloak.updateToken(300);

    if (!validarDatas(datas)) {
      dispatch(
        setErrorFeedback({
          message: 'Preencha as datas de início e fim corretamente.',
        })
      );
      return;
    }

    if (token && selectedUnit) {
      try {
        const error = await generateSpreadsheetLivroContas(
          datas.dataInicio,
          datas.dataFim,
          token,
          selectedUnit.id, // Tenant
          selectedBusinessUnits, // Business Units
          selectedFornecedores, // Fornecedores
          origem // Origem
        );
        if (error != null) {
          dispatch(
            setErrorFeedback({
              message: error ? error : 'Erro ao gerar a planilha.',
            })
          );
        } else {
          dispatch(
            setSuccessFeedback({
              message: 'Planilha gerada com sucesso!',
            })
          );
        }
      } catch (error) {
        dispatch(
          setErrorFeedback({
            message: 'Erro inesperado ao gerar o relatório.',
          })
        );
      }
    }
    keycloak.updateToken(300);
    history.push('/financeiro/relatorios');
  }, [datas, token, selectedUnit, selectedBusinessUnits, selectedFornecedores, origem, dispatch, keycloak, history]);

  return (
    <Dialog className={classes.root} open={isOpen} onClose={closeModal}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Form onSubmit={() => {}}>
          {() => (
            <form>
              <Grid container spacing={2}>
                {/* Unidade de Negócio multi-select */}
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl fullWidth style={{ borderColor: 'green' }}>
                    <InputLabel shrink sx={{ paddingLeft: '4px', paddingRight: '4px', marginLeft: '-4px' }}>
                      UNIDADE DE NEGÓCIO
                    </InputLabel>
                    <Select
                      style={{
                        borderColor: 'red',
                      }}
                      multiple
                      value={selectedBusinessUnits}
                      onChange={handleBusinessChange}
                      input={
                        <OutlinedInput
                          className={
                            classes.input +
                            ' MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense'
                          }
                        />
                      }
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      {businessUnits.map((business: { id: string; pessoa: { nomeResumido: string } }) => (
                        <MenuItem key={business.id} value={business.id}>
                          <Checkbox checked={selectedBusinessUnits.indexOf(business.id) > -1} />
                          <ListItemText primary={business.pessoa.nomeResumido} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Fornecedor multi-select */}
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel shrink sx={{ paddingLeft: '4px', paddingRight: '4px', marginLeft: '-4px' }}>
                      FORNECEDOR
                    </InputLabel>
                    <Select
                      multiple
                      value={selectedFornecedores}
                      onChange={handleFornecedorChange}
                      input={
                        <OutlinedInput
                          className={
                            classes.input +
                            ' MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense'
                          }
                        />
                      }
                      renderValue={(selected) => (selected as string[]).join(', ')}
                    >
                      {/* Replace these with actual Fornecedor options */}
                      <MenuItem value="1">
                        <Checkbox checked={selectedFornecedores.indexOf('1') > -1} />
                        <ListItemText primary="Fornecedor 1" />
                      </MenuItem>
                      <MenuItem value="2">
                        <Checkbox checked={selectedFornecedores.indexOf('2') > -1} />
                        <ListItemText primary="Fornecedor 2" />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Origem select */}
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl fullWidth>
                    <QCXTextField
                      style={{ width: '100%' }}
                      label="Origem"
                      key="select-origem-livro-contas"
                      id="select-origem-livro-contas"
                      select
                      value={origem}
                      onChange={handleOrigemChange}
                      {...({} as any)}
                    >
                      <MenuItem value="NUMERARIO">NUMERARIO</MenuItem>
                      <MenuItem value="OPERACIONAL">OPERACIONAL</MenuItem>
                      <MenuItem value="ADMINISTRATIVO">ADMINISTRATIVO</MenuItem>
                      <MenuItem value="FATURAMENTO">FATURAMENTO</MenuItem>
                    </QCXTextField>
                  </FormControl>
                </Grid>

                <Grid item xs={6} style={{ marginBottom: '25px' }}>
                  <Field
                    name="startDate"
                    render={({ input }) => (
                      <QCXFinalDatePickerField
                        id="contas-pagar-vencimento-start-date"
                        name={input.name}
                        label={'Data de Início'}
                        required={true}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        onChange={(date: any) => {
                          handleChangeDataInicio(date.toISOString());
                          input.onChange(date);
                        }}
                        validate={undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} style={{ marginBottom: '25px' }}>
                  <Field
                    name="endDate"
                    render={({ input }) => (
                      <QCXFinalDatePickerField
                        id="contas-pagar-vencimento-end-date"
                        name={input.name}
                        label={'Data final'}
                        required={true}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        onChange={(date: any) => {
                          handleChangeDataFim(date.toISOString());
                          input.onChange(date);
                        }}
                        validate={undefined}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Box padding={2} display="flex" gridGap={4}>
          <Button variant="outlined" color="primary" onClick={closeModal}>
            Fechar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            fullWidth
            disabled={datas.dataFim === '' || datas.dataInicio === '' || !selectedBusinessUnits.length}
          >
            Gerar Relatório
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default RelatorioLivroContasPage;
