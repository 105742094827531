
import { FormControlLabel, Switch, styled, SwitchProps } from "@material-ui/core";
import { ChangeEvent } from "react";

interface StyledSwitchProps extends SwitchProps {
  label?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

// '10px 5px 10px 10px'

const SwitchStyled = styled(({label, checked}: StyledSwitchProps) => {
  return (
    <FormControlLabel
      control={<Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        checked={checked} />}
      label={label?.toUpperCase()} />
  );
})(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          "#F39200",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA" ,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default SwitchStyled;
