import { useSelector } from 'react-redux';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import { useForm } from 'react-final-form';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { Chip, Grid, Box } from '@material-ui/core';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalBondManager from '../../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManager';
import { getTipoModeloLabelByValue } from '../../../shared-components/select-tipo-modelo/QCXFinalSelectTipoModeloAutocomplete';
import theme from '../../../ts/common/themes/theme';
import { selectCliente } from '../../../features/cliente/clienteSlice';
import QCXAutocompleteSelect from '../../../shared-components/autocomplete-select/QCXAutocompleteSelect';
import { normalizeNumeral, forceUnnormalizeNumeral, isStrictEquals } from '../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../utils/hooks/form/field/formatters';
import QCXFinalNumericDecimalFieldRender from '../../fatura/QCXFinalNumericDecimalFieldRender';

export default function QCXClienteServicoDespesaReceitaBondManager({
  tipo = 'NUMERARIO', // "NUMERARIO" | "FATURAMENTO"
  despesasReceitas,
  servicos,
  modes,
  classes,
  handleAdd,
  handleUpdate,
  handleAlreadyExists,
}) {
  const form = useForm();
  const { values } = form.getState();
  const { t } = useTranslation();

  const { isConsult, isSubConsult } = modes;

  const despesaReceitaId = values?.ignorableFields?.despesaReceita?.[tipo]?.despesaReceita?.id;
  const despesaReceitaSelected = despesasReceitas.find((d) => d.id === despesaReceitaId);

  const listRootName = `clienteServicoDespesaReceitaByServicoId.${tipo}`;
  const servicoId = _.get(values, 'servico.id');
  const servico = servicos?.find((s) => s.id === servicoId);
  const listName = `${listRootName}.${servico?.code}`;

  const cliente = useSelector(selectCliente);

  useEffect(() => {
    const defaultRelacoes = cliente?.relacaoClienteServicoDespesaReceitas ?? [];
    const defaultRelacoesFiltered = defaultRelacoes.filter((r) => r.tipo === tipo);

    const reduced = defaultRelacoesFiltered.reduce((acc, curr) => {
      const key = curr.servico.code;

      if (key in acc) acc[key].push(curr);
      else acc[key] = [curr];

      return acc;
    }, {});

    setTimeout(() => {
      form.change(listRootName, reduced);
    }, 150);
  }, [form, cliente, tipo]);

  const handleInternalAdd = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
      const despesaReceita = despesasReceitas?.find((m) => m.id === currentVinculo.despesaReceita.id);

      const valor = _.get(form.getState().values, `ignorableFields.despesaReceita.${tipo}.valor`);
      const normalizedValor = normalizeNumeral(valor);

      const vinculo = {
        id: uuid(),
        servico,
        despesaReceita,
        tipo,
        valor: normalizedValor,
      };

      const updatedVinculos = [...currentVinculos, vinculo];

      handleSuccessAddVinculo(updatedVinculos);
    },
    [form, despesasReceitas, servico, tipo]
  );

  const handleInternalUpdate = useCallback(async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
    const despesaReceita = despesasReceitas?.find((m) => m.id === currentVinculo.despesaReceita.id);

    const valor = normalizeNumeral(_.get(form.getState().values, `ignorableFields.despesaReceita.${tipo}.valor`));

    const updatedVinculo = {
      ...currentVinculo,
      despesaReceita,
      valor,
    };

    const updatedVinculos =
      currentVinculos?.map((existingVinculo) =>
        isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
      ) || currentVinculos;

    handleSuccessUpdateVinculo(updatedVinculos);
  }, []);

  const handleInternalAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(vinculo?.despesaReceita?.id, currentVinculo?.despesaReceita?.id) &&
          !isStrictEquals(vinculo?.id, currentVinculo?.id)
      ),
    []
  );

  const handlers = useMemo(
    () => ({
      handleAdd: _.isFunction(handleAdd) ? handleAdd : handleInternalAdd,
      handleUpdate: _.isFunction(handleUpdate) ? handleUpdate : handleInternalUpdate,
      handleAlreadyExists: _.isFunction(handleAlreadyExists) ? handleAlreadyExists : handleInternalAlreadyExists,
    }),
    [handleAdd, handleAlreadyExists, handleInternalAdd, handleInternalUpdate, handleInternalAlreadyExists]
  );

  const clienteServicoDespesaReceitaListProps = useMemo(
    () => ({
      name: listName,
      columns: [
        {
          field: 'modelo-numerario-description',
          headerName: t('com.muralis.qcx.codigo'),
          headerAlign: 'left',
          align: 'left',
          flex: 200,
          valueGetter: ({ row }) => row?.despesaReceita?.id ?? '-',
        },
        {
          field: 'despesa-receita-description',
          headerName:
            tipo === 'NUMERARIO' ? t('com.muralis.qcx.despesa.despesaReceita') : t('com.muralis.qcx.despesa.receita'),
          flex: 200,
          valueGetter: ({ row }) => row?.despesaReceita?.description ?? '-',
        },
        {
          field: 'tipo',
          headerName: t('com.muralis.qcx.tipoDoModelo'),
          headerAlign: 'center',
          align: 'center',
          type: 'string',
          flex: 120,
          valueGetter: ({ row }) => getTipoModeloLabelByValue(row?.despesaReceita?.tipo),
          renderCell: ({ value }) => {
            const isReceita = value === t('com.muralis.qcx.receita');
            return (
              <Chip
                size="small"
                label={value}
                style={{
                  backgroundColor: isReceita ? theme.palette.success.main : theme.palette.error.dark,
                  color: isReceita ? theme.palette.success.contrastText : theme.palette.error.contrastText,
                }}
              />
            );
          },
        },
        {
          field: 'valor',
          headerName: t('com.muralis.qcx.valor'),
          headerAlign: 'right',
          align: 'right',
          width: 160,
          valueFormatter: ({ row: { valor } }) => forceUnnormalizeNumeral(valor, formatBrazilianNumericDecimal(2)),
        },
      ],
    }),
    [listName]
  );

  const clienteServicoDespesaReceitaFormProps = useMemo(
    () => ({
      rootName: `ignorableFields.despesaReceita.${tipo}`,
      fields: [
        {
          name: 'despesaReceita.id',
          label: t('com.muralis.qcx.despesa.despesaReceita').toUpperCase(),
        },
      ],
    }),
    []
  );

  const filterOptions = createFilterOptions({
    stringify: (option) => `${option.id} - ${option.description}`,
  });

  const getOptionLabel = (item) => (item?.id && item?.description ? `${item?.id} - ${item?.description}` : '');

  return (
    <Box style={{ pointerEvents: modes?.isConsult ? 'none' : '' }}>
      <QCXFinalBondManager
        isParentConsult={isConsult || isSubConsult}
        listProps={clienteServicoDespesaReceitaListProps}
        formProps={clienteServicoDespesaReceitaFormProps}
        controlButtonsGridProps={{
          add: {
            xs: 12,
            sm: 2,
            md: 2,
          },
          update: {
            xs: 6,
            sm: 6,
            md: 1,
          },
          clear: {
            xs: 6,
            sm: 6,
            md: 1,
          },
        }}
        {...handlers}
      >
        <Grid item sm={8}>
          <QCXAutocompleteSelect
            key={JSON.stringify(values?.ignorableFields?.despesaReceita?.[tipo])}
            filterOptions={filterOptions}
            defaultValue=""
            getOptionLabel={getOptionLabel}
            options={despesasReceitas}
            id="text-field-modelo-lancamento"
            name={`ignorableFields.despesaReceita.${tipo}.despesaReceita.id`}
            label={
              tipo === 'NUMERARIO' ? t('com.muralis.qcx.despesa.despesaReceita') : t('com.muralis.qcx.despesa.receita')
            }
            required={undefined}
            disabled={isConsult}
          />
        </Grid>
        <Grid item sm={2}>
          <QCXFinalNumericDecimalFieldRender
            id={`numeric-decimal-${tipo}-valor-field`}
            name={`ignorableFields.despesaReceita.${tipo}.valor`}
            label={t('com.muralis.qcx.valor')}
            disabled={isConsult || despesaReceitaSelected?.tipo !== 'RECEITA'}
            scale={7}
            validate={undefined}
            required={undefined}
          />
        </Grid>
      </QCXFinalBondManager>
    </Box>
  );
}
