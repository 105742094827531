import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { isArray, isEmpty, isFunction } from 'lodash';
import QCXTab from './QCXTab';
import QCXTabContext from './QCXTabContext';
import QCXTabList from './QCXTabList';
import QCXTabPanel from './QCXTabPanel';
import TabManagerContext from '../../contexts/components/tab-manager/TabManagerContext';

const useStyles = makeStyles((theme) => ({
  tabList: {
    paddingLeft: '4px',
  },
  tabPanel: {
    width: '100%',
    padding: '24px 0px 0px 0px',
  },
  tab: {
    borderBottom: `2px solid ${theme.palette.grey[400]}`,
  },
}));

export default function QCXTabsManager({
  id,
  key,
  tabs = [],
  defaultActiveTab = 0,
  forceSwitchTab = undefined,
  onChangeDecorator,
  propsForAllTabs = {},
  autoHandle = true,
  onlyActiveTabEnabled = false,
  tabGrid = {
    xs: 12,
    sm: 10,
    md: 10,
  },
  renderControlButtons,
  children,
  tabChangeCallback = undefined,
  ...restProps
}) {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(defaultActiveTab || 0);

  useEffect(() => {
    tabChangeCallback?.(activeTab);
  }, [activeTab, tabChangeCallback]);

  const lastTabValue = useMemo(() => tabs.length - 1, [tabs]);

  const handleChangeToNextTab = useCallback(() => {
    const nextTab = activeTab + 1;

    if (nextTab <= lastTabValue) {
      setActiveTab(nextTab);
    }
  }, [activeTab, setActiveTab]);

  const handleChangeToPreviousTab = useCallback(() => {
    const previousTab = activeTab - 1;

    if (previousTab > -1) {
      setActiveTab(previousTab);
    }
  }, [activeTab, setActiveTab]);

  const firstTab = useMemo(() => activeTab === 0, [activeTab]);

  const lastTab = useMemo(() => activeTab === lastTabValue, [activeTab, lastTabValue]);

  const tabManagerStateValue = useMemo(
    () => ({
      activeTab,
      setActiveTab,
      firstTab,
      lastTab,
      onlyActiveTabEnabled,
      changeToNext: handleChangeToNextTab,
      changeToPrevious: handleChangeToPreviousTab,
    }),
    [activeTab, setActiveTab, firstTab, lastTab, onlyActiveTabEnabled, handleChangeToNextTab, handleChangeToPreviousTab]
  );

  const handleChangeDecorated = useCallback(
    (event, newActiveTab) => {
      event.stopPropagation();

      function handleChange() {
        setActiveTab(newActiveTab);
      }

      if (autoHandle && isFunction(onChangeDecorator)) {
        handleChange();
        onChangeDecorator(newActiveTab);
        return;
      }
      if (!autoHandle && isFunction(onChangeDecorator)) {
        onChangeDecorator(newActiveTab, handleChange, setActiveTab);
        return;
      }

      handleChange();
    },
    [autoHandle, onChangeDecorator]
  );

  useEffect(() => {
    if (forceSwitchTab && !isEmpty(forceSwitchTab) && forceSwitchTab?.force) {
      setActiveTab(forceSwitchTab?.targetTab);
    }
  }, [forceSwitchTab, setActiveTab]);

  return (
    <QCXTabContext value={activeTab}>
      <TabManagerContext.Provider value={tabManagerStateValue}>
        <Grid container>
          <Grid container item justify="space-between" alignItems="center">
            <Grid item container justify="flex-start" xs={tabGrid?.xs} sm={tabGrid?.sm} md={tabGrid?.md}>
              <QCXTabList id={id} key={key} className={classes.tabList} onChange={handleChangeDecorated} {...restProps}>
                {isArray(tabs) &&
                  tabs.map(({ label, disabled, ...restPropsTab }, tabIndex) => (
                    <QCXTab
                      {...propsForAllTabs}
                      label={label}
                      className={classes.tab}
                      disabled={disabled || (onlyActiveTabEnabled && activeTab !== tabIndex)}
                      {...restPropsTab}
                      data-id={`tabs-manager-tab-${tabIndex}`}
                    />
                  ))}
              </QCXTabList>
            </Grid>
            {isFunction(renderControlButtons) &&
              renderControlButtons({
                tab: {
                  active: activeTab,
                },
              })}
          </Grid>
          <Grid container item justify="flex-start">
            {isArray(children) &&
              children.map((child, index) =>
                isArray(child) && child.length > 1 ? (
                  child.map((subChild, subIndex) => (
                    <QCXTabPanel className={classes.tabPanel} value={Math.min(index, 1) + subIndex}>
                      {subChild}
                    </QCXTabPanel>
                  ))
                ) : (
                  <QCXTabPanel className={classes.tabPanel} value={index}>
                    {child}
                  </QCXTabPanel>
                )
              )}
          </Grid>
        </Grid>
      </TabManagerContext.Provider>
    </QCXTabContext>
  );
}
