const erroLabel = 'Erro';
const successLabel = 'Sucesso';
const defaultLabel = 'Pendente';

const statusICMS = {
  NAO_CONCLUIDO: defaultLabel,
  ERRO: erroLabel,
  CONCLUIDO: successLabel,
};

const hexaColorStatusICMS = {
  NAO_CONCLUIDO: '#ffff00',
  ERRO: '#ff0000',
  CONCLUIDO: '#00ff00',
};

const getHexadecimalColorByValue = (value) => hexaColorStatusICMS[value] || '#ffff00';

const getLabelByValue = (value) => statusICMS[value] || defaultLabel;

const StatusICMSUtils = {
  getHexadecimalColorByValue,
  getLabelByValue,
};

export default StatusICMSUtils;
