import React, { useCallback, useEffect, useMemo } from 'react';
import { validate } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import debounce from 'lodash/debounce';
import { isEmpty } from 'lodash';
import QCXEmpresaEstrangeiraForm from '../../../../../components/empresa-estrangeira/QCXEmpresaEstrangeiraForm';
import { register, save } from '../../../../../features/empresa-estrangeira/empresaEstrangeiraAPI';
import {
  addToList,
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetModel,
  selectBackgroundMode,
  selectEmpresaEstrangeira,
  selectMode,
  selectStatus,
  setError,
  setModel,
  setResponse,
  success,
  updateOnList,
} from '../../../../../features/empresa-estrangeira/empresaEstrangeiraSlice';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import { normalizeZipCode } from '../../../../../utils/general/general-utils';
import {
  isBackgroundCreateMode,
  isConsultMode,
  isCreateMode,
  isNoneMode,
  isPreparingActionStatus,
  isUpdateMode,
} from '../../../../../utils/store/store-utils';
import { formatCep } from '../../../../../utils/hooks/form/field/formatters';

export default function EmpresaEstrangeiraRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const empresaEstrangeira = useSelector(selectEmpresaEstrangeira);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);

  const handleChangeToPreparingAction = () => {
    dispatch(preparingAction());
  };

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToBackgroundCreate = () => {
    dispatch(changeToBackgroundCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  const handleResetBackgroundMode = () => {
    dispatch(resetBackgroundMode());
  };

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const handleCancelUpdate = () => {
    const currentId = isBackgroundCreate ? empresaEstrangeira?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
    handleChangeToConsult();
  };

  const normalizeIds = (data) => {
    if (!data || (data && !Array.isArray)) return [];

    return data.map(el => {
      const isUuid = validate(el?.id);
      return isUuid ? {
        ...el,
        id: null,
      } : {...el}
    });
  }

  const normalize = useCallback(
    (data) => ({
      ...data,
      endereco: {
        ...data?.endereco,
        cep: normalizeZipCode(data?.endereco?.cep),
        cidade: data?.endereco?.cidade?.id ? data?.endereco?.cidade : null,
        estado: data?.endereco?.estado?.id ? data?.endereco?.estado : null,
        pais: data?.endereco?.pais?.id ? data?.endereco?.pais : null,
      },
      relacaoClientes: normalizeIds(data?.relacaoClientes),
    }),
    []
  );

  const unnormalize = useCallback((normalizedData) => {
    const unnormalizedData = {
      ...normalizedData,
      endereco: {
        ...normalizedData?.endereco,
        estado: normalizedData?.endereco?.estado ?? normalizedData?.endereco?.cidade?.estado,
        pais:
          normalizedData?.endereco?.pais ??
          normalizedData?.endereco?.estado?.pais ??
          normalizedData?.endereco?.cidade?.estado?.pais,
        cep: formatCep(normalizedData?.endereco?.cep),
      },
    };

    return unnormalizedData;
  }, []);

  const handleDispatchSetModel = useCallback(
    (data) => {
      dispatch(setModel(normalize(data)));
    },
    [normalize]
  );

  const create = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await register(data);

        if (response?.status === 201) {
          dispatch(resetModel());

          const created = response?.data;

          handleDispatchSetModel(created);
          dispatch(addToList({ data: created }));

          const handleResultWithDebounce = debounce(() => {
            history.push(t('com.muralis.qcx.url.moduloOperacionaisEmpresaEstrangeira'));

            dispatch(success());
            dispatch(
              setResponse({
                status: response.status,
                data: created,
                message: t('com.muralis.qcx.mensagem.empresaEstrangeiraCadastrada', {
                  empresa: created?.pessoaJuridica?.nome,
                }),
              })
            );
          }, 500);

          handleResultWithDebounce();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(
          setError({
            message: t('com.muralis.qcx.erro.erroCadastrarEmpresaEstrangeiraMensagem', { erro: response.data.message }),
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(
              setResponse({
                status: response.status,
                data: saved,
                message: t('com.muralis.qcx.mensagem.empresaEstrangeiraAlteracaoSalva', {
                  pessoaJuridica: saved?.pessoaJuridica?.nome,
                }),
              })
            );

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
          handleChangeToConsult();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(
          setError({
            message: t('com.muralis.qcx.erro.erroSalvarAlteracoesEmpresaEstrangeiraEspecifico', {
              empresa: data?.pessoaJuridica?.nome,
              erro: response.data.message,
            }),
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data) => {
    const normalizedData = normalize(data);

    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData);
    } else {
      await create(normalizedData);
    }
  };

  const model = useMemo(
    () => (!isCreate ? unnormalize(empresaEstrangeira) : {}),
    [isCreate, empresaEstrangeira, unnormalize]
  );

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.nova');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.cadastros'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloOperacionais'),
          name: t('com.muralis.qcx.operacionais'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloOperacionaisEmpresaEstrangeira'),
          name: t('com.muralis.qcx.empresaEstrangeira.labelPlural'),
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [actionName]
  );

  const pageTitle = useMemo(
    () =>
      isNone || isCreate || isBackgroundCreate
        ? t('com.muralis.qcx.empresaEstrangeira.novaEmpresaEstrangeira')
        : t('com.muralis.qcx.empresaEstrangeira.empresaEstrangeiraExistente', {
            ID: empresaEstrangeira?.pessoaJuridica?.nomeResumido || empresaEstrangeira?.id,
          }),
    [isNone, isCreate, isBackgroundCreate, empresaEstrangeira]
  );

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isPreparingAction={isPreparingAction}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXEmpresaEstrangeiraForm
          initialValues={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          authInfo={authInfo}
          requiredRoles={['exportador-fabricante']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
