import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, useTheme, makeStyles } from '@material-ui/core';
import { etapaFollowUpActions } from '../../features/etapa-follow-up/etapaFollowUpSlice';
import {
  CLOSED_STATUS,
  DONE_STATUS,
  FAIL_STATUS,
  isOpenStatus,
  SUBMITTING_STATUS,
} from '../../utils/hooks/form/dialog/formDialogUtils';
import { etapaFollowUpAPI } from '../../features/etapa-follow-up/etapaFollowUpAPI';
import { etapaFollowUpSelectors } from '../../features/etapa-follow-up/etapaFollowUpSelectors';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';
import QCXMoveFocusInside from '../focus-lock/QCXMoveFocusInside';
import { followUpActions } from '../../features/follow-up/followUpSlice';
import QCXFinalMultilineTextField from '../../shared-components/final-text-field/QCXFinalMultilineTextField';
import { prune } from '../../utils/hooks/form/field/parsers';
import EtapaFollowUpUtils from '../../utils/general/follow-up/EtapaFollowUpUtils';
import { setErrorFeedback, setSuccessFeedback } from '../../features/feedback/feedbackSlice';

const useStyles = makeStyles(() => ({
  rootPaperFullWidth: {
    width: '100% !important',
  },
}));

export default function QCXObservacaoEtapaFollowUpFormDialog({ status, handleStatus, handleSubmit }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const theme = useTheme();

  const dispatch = useDispatch();

  const etapaFollowUp = useSelector(etapaFollowUpSelectors.selectEtapaFollowUp);

  const hasObservacoes = useMemo(
    () => etapaFollowUp?.observacao && etapaFollowUp?.observacao?.length > 0,
    [etapaFollowUp]
  );

  const isCompleted = useMemo(() => EtapaFollowUpUtils.isStatusConcluida(etapaFollowUp?.status), [etapaFollowUp]);

  const color = useMemo(() => {
    if (isCompleted) {
      return 'secondary';
    }

    return 'primary';
  }, [isCompleted]);

  const defaultHandleSubmit = useCallback(
    async ({ metadata, ...values }) => {
      try {
        dispatch(followUpActions.loading());
        dispatch(etapaFollowUpActions.loading());

        handleStatus(SUBMITTING_STATUS);

        const payload = {
          ...values,
          observacao: prune(values?.observacao),
        };

        dispatch(etapaFollowUpActions.setModel(payload));

        const response = await etapaFollowUpAPI.save(payload);

        if (response.status === 200) {
          const responseMessage = t('com.muralis.qcx.mensagem.observacoesEtapaSalvas', {
            descricao: values?.etapa?.description,
          });

          dispatch(followUpActions.success());
          dispatch(etapaFollowUpActions.success());
          dispatch(setSuccessFeedback({ message: responseMessage }));

          handleStatus(DONE_STATUS);
        }
      } catch (error) {
        handleStatus(FAIL_STATUS);

        const isUnknownError =
          _.isEmpty(error?.response) || _.isEmpty(error?.response?.data) || _.isEmpty(error?.response?.data?.message);

        const errorMessage = isUnknownError
          ? t('com.muralis.qcx.erro.erroSalvarObservacoesEtapa', { descricao: values?.etapa?.description })
          : t('com.muralis.qcx.erro.erroSalvarObservacoesEtapaEspecifico', {
              descricao: values?.etapa?.description,
              mensagem: error?.response?.data?.message,
            });

        dispatch(followUpActions.failure());
        dispatch(etapaFollowUpActions.failure());
        dispatch(setErrorFeedback({ message: errorMessage }));
      }
    },
    [etapaFollowUp, handleStatus]
  );

  const handleCloseFormDialog = useCallback(() => handleStatus(CLOSED_STATUS), [handleStatus]);

  const isFormDialogOpen = useMemo(() => isOpenStatus(status), [status]);

  const formDialogTitle = useMemo(
    () => (
      <>
        {hasObservacoes
          ? t('com.muralis.qcx.editarObservacoes').toUpperCase()
          : t('com.muralis.qcx.adicionarObservacoes').toUpperCase()}

        {t('com.muralis.qcx.etapaComplemento').toUpperCase()}
        <br />
        <Typography variant="subtitle1">
          <strong>{etapaFollowUp?.etapa?.description}</strong>
        </Typography>
      </>
    ),
    [hasObservacoes, etapaFollowUp]
  );

  const formDialogContent = useMemo(
    () => (
      <>
        {hasObservacoes ? t('com.muralis.qcx.edite') : t('com.muralis.qcx.preencha')}
        {t('com.muralis.qcx.observacoesAbaixoSalvar')}
      </>
    ),
    [hasObservacoes]
  );

  return (
    <QCXFinalFormDialog
      key="form-dialog-alterar-observacoes-etapa-follow-up"
      id="form-dialog-alterar-observacoes-etapa-follow-up"
      title={formDialogTitle}
      content={formDialogContent}
      dialogActionsProps={{
        button: {
          submit: {
            description: t('com.muralis.qcx.acoes.salvar'),
            size: 'small',
          },
          cancel: {
            description: t('com.muralis.qcx.acoes.cancelar'),
            size: 'small',
          },
        },
      }}
      maxWidth="sm"
      fullWidth
      open={isFormDialogOpen}
      initialValues={etapaFollowUp}
      onSubmit={handleSubmit ?? defaultHandleSubmit}
      onClose={handleCloseFormDialog}
      classes={{
        paperFullWidth: classes.rootPaperFullWidth,
      }}
      PaperProps={{
        style: {
          borderLeft: `6px solid ${theme.palette[color].main}`,
          width: '100% !important',
        },
      }}
    >
      {() => (
        <QCXMoveFocusInside>
          <QCXFinalMultilineTextField
            id="multiline-text-field-observacoes-etapa"
            key="multiline-text-field-observacoes-etapa"
            name="observacao"
            label={t('com.muralis.qcx.observacoes')}
            rows={10}
            maxLength={3000}
            remaningCharactersInfo
          />
        </QCXMoveFocusInside>
      )}
    </QCXFinalFormDialog>
  );
}
