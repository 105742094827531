import React, { useEffect, useMemo, useState } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import { OnChange } from 'react-final-form-listeners';
import _ from 'lodash';

import QCXFinalDatePickerField from '../../final-date-picker-field/QCXFinalDatePickerField';

export default function QCXPalavraChaveDataField({
    palavraChave,
    disabled,
    ...restProps
}) {
    const basePath = `ignorableFields.documentoAnexacao.palavrasChave`
    return (
        <>
              <QCXFinalDatePickerField
                id={`cod_${palavraChave.idPalavraChave}`}
                key={`cod_${palavraChave.idPalavraChave}`}
                name={`${basePath}.cod_${palavraChave.idPalavraChave}`}
                label={palavraChave.nomePalavraChave}
                disabled={disabled}
                required={palavraChave.obrigatoria}
              />
       

            <OnChange name={`${basePath}.cod_${palavraChave.idPalavraChave}`}>
                {(value, previous) => console.log(value)}
            </OnChange>
        </>

    );
}
