import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import QCXButton from '../../../shared-components/button/QCXButton';
import QCXDataGrid from '../../../shared-components/data-grid/QCXDataGrid';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      maxWidth: 'none',
      width: '70vw',
    },
  },
});

export default function ExTarifarioPopup({ tipo, selectedAdicaoId, exTarifarios, open, onClose }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const form = useForm();

  const [checkedId, setCheckedId] = useState(null);

  const handleCheckboxChange = (newCheckedId) => {
    setCheckedId((prev) => {
      if (prev === newCheckedId) return null;

      return newCheckedId;
    });
  };

  const columns = [
    {
      field: 'ex-tarifarios-selected',
      headerName: t('com.muralis.qcx.acoes.selecionar'),
      width: 140,
      renderCell: ({ row }) => (
        <Checkbox checked={checkedId === row.id} onChange={() => handleCheckboxChange(row.id)} />
      ),
    },
    {
      field: 'ex-tarifarios-sequencial',
      headerName: t('com.muralis.qcx.sequencial'),
      width: 140,
      valueGetter: ({ row }) => row?.ncmSequencial,
    },
    {
      field: 'ex-tarifarios-descricao',
      headerName: t('com.muralis.qcx.descricao'),
      flex: 1,
      valueGetter: ({ row }) => row?.ncmDescricao,
    },
    tipo === 'ii'
      ? {
          field: 'ex-tarifarios-ii',
          headerName: t('com.muralis.qcx.ii'),
          flex: 1,
          valueGetter: ({ row }) => row?.ii,
        }
      : {
          field: 'ex-tarifarios-ipi',
          headerName: t('com.muralis.qcx.ipi'),
          flex: 1,
          valueGetter: ({ row }) => row?.ipi,
        },
  ];

  const handleConfirmButton = () => {
    const exTarifarioChecked = exTarifarios.find((e) => checkedId === e.id);

    form.change(`metaAliquotas.adicao_${selectedAdicaoId}.exTarifario.${tipo}`, exTarifarioChecked);

    onClose();
  };

  return (
    <Dialog className={classes.root} open={open} onClose={onClose} fullWidth>
      <DialogTitle>{t('com.muralis.qcx.exTarifarioPlural')}</DialogTitle>
      <DialogContent dividers style={{ maxHeight: '75vh', overflow: 'auto' }}>
        <QCXDataGrid columns={columns} rows={exTarifarios} />
      </DialogContent>
      <DialogActions>
        <Box padding={3} display="flex" gridGap={16}>
          <QCXButton variant="outlined" color="primary" onClick={onClose}>
            {t('com.muralis.qcx.acoes.fechar')}
          </QCXButton>

          <QCXButton variant="contained" color="secondary" onClick={handleConfirmButton}>
            Selecionar
          </QCXButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
