import { debounce, differenceWith, isEmpty, isEqual, isFunction, isNil, omit, omitBy } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid, validate } from 'uuid';
import {
  changeToLastSubBackgroundMode,
  changeToSubBackgroundConsultMode,
  changeToSubBackgroundDeleteMode,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  loading,
  resetRelatedMercadoriaModel,
  resetStatus,
  resetSubBackgroundMode,
  resetSubMode,
  setRelatedMercadoriaModel,
} from '../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSlice';
import {
  selectRelatedItensXmlDanfeMetadata,
  selectConditionalSteps,
  selectRelatedMercadoriaModel,
  selectSubMode,
} from '../../features/declaracao-unica-exportacao/declaracaoUnicaExportacaoSelectors';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';
import { isNoneSubMode, isSubConsultMode, isSubCreateMode, isSubUpdateMode } from '../../utils/store/store-utils';
import QCXDeclaracaoUnicaExportacaoBasicasForm from './QCXDeclaracaoUnicaExportacaoBasicasForm';
import QCXFaturaTotaisForm from '../fatura/QCXFaturaTotaisForm';
import { TIPO_DUE } from '../../utils/general/fatura/faturaUtils';
import useQueryParam from '../../utils/hooks/router/useQueryParam';
import { parseToNumber } from '../../utils/general/parse-utils';
import { isCalculadaStatus } from '../../utils/general/operations/operationUtils';
import useOperationMiddleware from '../../utils/hooks/operation/middleware/useOperationMiddleware';
import QCXConfirmDialog from '../../shared-components/dialog/QCXConfirmDialog';
import { useMercadoriaUtils } from '../fatura/mercadoriaUtils';
import { isValid, normalizeData, normalizeNumeral, unnormalizeNumeral } from '../../utils/general/general-utils';
import QCXDeclaracaoUnicaExportacaoAuxiliarForm from './QCXDeclaracaoUnicaExportacaoAuxiliarForm';
import QCXFaturaMercadoriaExportacaoForm from '../fatura/QCXFaturaMercadoriaExportacaoForm';
import { numberOnly } from '../../utils/hooks/form/field/parsers';
import { formatBrazilianNumericDecimal, formatCnpj, formatCpfOrCnpj } from '../../utils/hooks/form/field/formatters';
import QCXImportacaoXmlDanfeForm from './QCXImportacaoXmlDanfeForm';
import { QUANTIDADE_X_VALOR } from '../../shared-components/select-forma-calculo/formaCalculoUtils';

export default function QCXDeclaracaoUnicaExportacaoWizardFinalForm({
  isIdle,
  isLoading,
  isFailure,
  isCreate,
  isConsult,
  isUpdate,
  isLocked,
  isBackgroundCreate,
  isBackgroundDelete,
  isSubBackgroundCreate,
  isSubBackgroundConsult,
  isSubBackgroundUpdate,
  isSubBackgroundDelete,
  isPreparingAction,
  handleSubmit,
  handleCustomSaveBy,
  handleAlternativeSave,
  handleMercadoria,
  handleChangeToPreparingAction,
  model,
  handleChangeModel,
  refreshSelectedModel,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleChangeToBackgroundDelete,
  handleResetBackgroundMode,
  handleMetadataUpdate,
  children,
  authInfo = {},
  requiredRoles = [],
  isCreated,
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const stepQueryParam = useQueryParam('step');
  const itemQueryParam = useQueryParam('item');
  const tabQueryParam = useQueryParam('tab');

  const subMode = useSelector(selectSubMode);
  const mercadoria = useSelector(selectRelatedMercadoriaModel);
  const itensXmlDanfeMetadata = useSelector(selectRelatedItensXmlDanfeMetadata);
  const conditionalSteps = useSelector(selectConditionalSteps);

  const mercadoriaUtils = useMercadoriaUtils();

  const stepNumberQueryParam = useMemo(
    () => (isValid(stepQueryParam) ? parseToNumber(stepQueryParam) - 1 : undefined),
    [stepQueryParam]
  );

  const itemNumberQueryParam = useMemo(() => parseToNumber(itemQueryParam) || undefined, [itemQueryParam]);

  const tabNumberQueryParam = useMemo(() => parseToNumber(tabQueryParam) || undefined, [tabQueryParam]);

  const hasNoConditionalStep = useMemo(() => {
    console.log(itensXmlDanfeMetadata);
    return !isEmpty(itensXmlDanfeMetadata) && (isCreate || isCreated);
  }, [isCreate, isCreated, itensXmlDanfeMetadata]);

  const checkIfIsAlternativeSaveByStep = useCallback(
    (currentStep) => (hasNoConditionalStep ? [3, 4] : [2, 3]).includes(currentStep),
    [hasNoConditionalStep]
  );

  const isSubNone = useMemo(() => isNoneSubMode(subMode), [subMode]);

  const isSubCreate = useMemo(() => isSubCreateMode(subMode), [subMode]);

  const isSubConsult = useMemo(() => isSubConsultMode(subMode), [subMode]);

  const isSubUpdate = useMemo(() => isSubUpdateMode(subMode), [subMode]);

  const handleLoadingStatus = useCallback(() => dispatch(loading()), []);

  const handleResetStatus = useCallback(() => dispatch(resetStatus()), []);

  const handleChangeToSubCreate = useCallback(() => {
    dispatch(changeToSubCreateMode());
  }, []);

  const handleChangeToSubConsult = useCallback(() => {
    dispatch(changeToSubConsultMode());
  }, []);

  const handleChangeToSubUpdate = useCallback(() => {
    dispatch(changeToSubUpdateMode());
  }, []);

  const handleChangeToSubNone = useCallback(() => {
    if (isSubBackgroundCreate) {
      handleChangeToSubCreate();
      dispatch(resetSubBackgroundMode());

      return;
    }

    if (isSubBackgroundConsult) {
      handleChangeToSubConsult();
      dispatch(resetSubBackgroundMode());

      return;
    }
    if (isSubBackgroundUpdate) {
      handleChangeToSubUpdate();
      dispatch(changeToSubBackgroundConsultMode());

      return;
    }

    dispatch(resetSubMode());
  }, [
    isSubBackgroundCreate,
    isSubBackgroundConsult,
    isSubBackgroundUpdate,
    handleChangeToSubCreate,
    handleChangeToSubConsult,
    handleChangeToSubUpdate,
  ]);

  const isSavedAllMercadorias = useMemo(() => model?.mercadorias?.every((item) => !validate(item?.id)), [model]);

  const handleNewMercadoria = useCallback(
    (additional = {}) => {
      const additionalCallback = () => {
        if (!isEmpty(additional) && isFunction(additional?.init)) {
          additional.init();
        }

        handleChangeToSubCreate();
      };

      if (isFunction(handleMetadataUpdate)) {
        handleMetadataUpdate('tab', 1);

        handleMetadataUpdate('item', 1);
      }

      if (!isCreate) {
        handleChangeToUpdate({
          callback: additionalCallback,
        });

        return;
      }

      additionalCallback.call();
    },
    [isCreate, handleMetadataUpdate, handleChangeToUpdate, handleChangeToSubCreate]
  );

  const handleCancelMercadoriaCreate = useCallback(
    (additional = {}) => {
      const additionalCallback = () => {
        if (!isEmpty(additional) && isFunction(additional?.callback)) {
          additional.callback();
        }

        if (isSubBackgroundCreate || isSubBackgroundConsult) {
          dispatch(resetSubBackgroundMode());
        }
      };

      if (!isCreate) {
        handleCancelUpdate({
          callback: additionalCallback,
        });

        return;
      }

      additionalCallback.call();

      if (isSubBackgroundConsult) {
        dispatch(resetSubBackgroundMode());
      }
    },
    [isCreate, isSubBackgroundCreate, isSubBackgroundConsult, handleCancelUpdate]
  );

  const handleCancelMercadoriaUpdate = useCallback(() => {
    const handleSubConsultAndSubBackground = () => {
      handleChangeToSubConsult();

      if (isSubBackgroundConsult || isSubBackgroundUpdate) {
        dispatch(resetSubBackgroundMode());
      }
    };

    if (isUpdate && isSavedAllMercadorias) {
      handleCancelUpdate({
        callback: handleSubConsultAndSubBackground,
      });

      return;
    }

    handleSubConsultAndSubBackground();
  }, [
    isUpdate,
    isSubBackgroundConsult,
    isSubBackgroundUpdate,
    isSavedAllMercadorias,
    handleCancelUpdate,
    handleChangeToSubConsult,
  ]);

  const handleClearMercadoriaForm = useCallback(() => {
    dispatch(resetRelatedMercadoriaModel());
  }, []);

  const [handleRemoveMercadoriaByIdMiddleware, operationOfRemoveMercadoriaById] = useOperationMiddleware(
    async (data, refreshCallback) => {
      handleMercadoria(data, refreshCallback);
    },
    [handleMercadoria]
  );

  const handleRemoveMercadoriaByForm = useCallback(
    (refreshCallback) => {
      dispatch(changeToSubBackgroundDeleteMode());

      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.acoes.confirmarOperacaoRemocao').toUpperCase(),
          message: t('com.muralis.qcx.acoes.operacaoRemocaoItemLista', {
            item: mercadoria?.item,
          }),
          endMessage: t('com.muralis.qcx.alerta.remocao'),
        },
        cleanUp: () => {
          dispatch(changeToLastSubBackgroundMode());
        },
      });

      handleRemoveMercadoriaByIdMiddleware(configureOperation, mercadoria, refreshCallback);
    },
    [mercadoria, handleRemoveMercadoriaByIdMiddleware]
  );

  const handleRemoveMercadoriaByTable = useCallback(
    ({ row }) => {
      handleChangeToBackgroundDelete();

      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.acoes.confirmarOperacaoRemocao').toUpperCase(),
          message: t('com.muralis.qcx.acoes.operacaoRemocaoItemLista', {
            item: row?.item,
          }),
          endMessage: t('com.muralis.qcx.alerta.remocao'),
        },
        cleanUp: () => {
          dispatch(changeToLastSubBackgroundMode());
        },
      });

      handleRemoveMercadoriaByIdMiddleware(configureOperation, row);
    },
    [handleChangeToBackgroundDelete, handleRemoveMercadoriaByIdMiddleware]
  );

  const handleDispatchSetMercadoriaModel = useCallback((updatedModel) => {
    dispatch(setRelatedMercadoriaModel(updatedModel));
  }, []);

  const handleEditMercadoria = useCallback(() => {
    if (!isCreate) {
      handleChangeToUpdate({
        callback: handleChangeToSubUpdate,
      });

      return;
    }

    handleChangeToSubUpdate();
  }, [isCreate, handleChangeToUpdate, handleChangeToSubUpdate]);

  const tabManagerStepMercadoriaProps = useMemo(
    () => ({
      defaultActiveTab: tabNumberQueryParam,
    }),
    [tabNumberQueryParam]
  );

  const formPaginationControlProps = useMemo(
    () => ({
      autoLoadItem:
        isCalculadaStatus(model?.calculada) && (isSubNone || isSubConsult || isSubUpdate || isSubBackgroundCreate),
    }),
    [model, isSubNone, isSubConsult, isSubBackgroundCreate]
  );

  useEffect(() => {
    const handleQueryParamsMetadataUpdate = () => {
      if (isFunction(handleMetadataUpdate)) {
        if (isValid(tabNumberQueryParam)) {
          handleMetadataUpdate('tab', tabNumberQueryParam);
        }

        if (isValid(itemNumberQueryParam)) {
          handleMetadataUpdate('item', itemNumberQueryParam);
          return;
        }

        if (isValid(mercadoria?.item)) {
          handleMetadataUpdate('item', mercadoria?.item);
        }
      }
    };

    handleQueryParamsMetadataUpdate();
  }, [mercadoria, tabNumberQueryParam, itemNumberQueryParam, handleMetadataUpdate]);

  const normalize = useCallback(
    (unnormalizedData) => {
      const normalizedData = mercadoriaUtils.normalize(unnormalizedData);

      const normalizedNotaFiscal = omitBy(
        {
          ...unnormalizedData?.due?.notaFiscal,
          data: normalizeData(unnormalizedData?.due?.notaFiscal?.data),
          numero: isEmpty(unnormalizedData?.due?.notaFiscal?.numero)
            ? undefined
            : unnormalizedData?.due?.notaFiscal?.numero,
          serie: isEmpty(unnormalizedData?.due?.notaFiscal?.serie)
            ? undefined
            : unnormalizedData?.due?.notaFiscal?.serie,
          cpfCnpjEmitente: isEmpty(unnormalizedData?.due?.notaFiscal?.cpfCnpjEmitente)
            ? undefined
            : numberOnly(unnormalizedData?.due?.notaFiscal?.cpfCnpjEmitente),
          estado: unnormalizedData?.due?.notaFiscal?.estado?.id ? unnormalizedData?.due?.notaFiscal?.estado : undefined,
          modeloNotaFiscal: unnormalizedData?.due?.notaFiscal?.modeloNotaFiscal?.id
            ? unnormalizedData?.due?.notaFiscal?.modeloNotaFiscal
            : undefined,
        },
        isNil
      );

      return {
        ...omit(normalizedData, ['ignorableFields']),
        valorTotalMcvReal: normalizeNumeral(unnormalizedData?.valorTotalMcvReal),
        operacaoFiscal: unnormalizedData?.operacaoFiscal?.id ? unnormalizedData?.operacaoFiscal : undefined,
        due: {
          ...unnormalizedData?.due,
          prazoSolicitado: normalizeNumeral(unnormalizedData?.due?.prazoSolicitado),
          percentualComissaoAgente: normalizeNumeral(unnormalizedData?.due?.percentualComissaoAgente),
          incotermCondicaoVenda: unnormalizedData?.due?.incotermCondicaoVenda?.id
            ? unnormalizedData?.due?.incotermCondicaoVenda
            : undefined,
          fornecedor: unnormalizedData?.due?.fornecedor?.id ? unnormalizedData?.due?.fornecedor : undefined,
          primeiroEnquadramento: unnormalizedData?.due?.primeiroEnquadramento?.id
            ? unnormalizedData?.due?.primeiroEnquadramento
            : undefined,
          segundoEnquadramento: unnormalizedData?.due?.segundoEnquadramento?.id
            ? unnormalizedData?.due?.segundoEnquadramento
            : undefined,
          terceiroEnquadramento: unnormalizedData?.due?.terceiroEnquadramento?.id
            ? unnormalizedData?.due?.terceiroEnquadramento
            : undefined,
          quartoEnquadramento: unnormalizedData?.due?.quartoEnquadramento?.id
            ? unnormalizedData?.due?.quartoEnquadramento
            : undefined,
          tratamentoPrioritario: unnormalizedData?.due?.tratamentoPrioritario?.id
            ? unnormalizedData?.due?.tratamentoPrioritario
            : undefined,
          lpcos: (unnormalizedData?.due?.lpcos || []).map((lpcoDue) => lpcoDue?.lpco),
          ncms: (unnormalizedData?.due?.ncms || [])?.map((ncmDue) =>
            validate(ncmDue?.id)
              ? {
                  ...omit(ncmDue, ['unidadeDeMedida']),
                  tipoCe: ncmDue?.tipoCe?.id ? ncmDue?.tipoCe : undefined,
                  ncm: ncmDue?.ncm?.id ? ncmDue?.ncm : undefined,
                  quantidade: normalizeNumeral(ncmDue?.quantidade),
                  id: null,
                }
              : {
                  ...omit(ncmDue, ['unidadeDeMedida']),
                  tipoCe: ncmDue?.tipoCe?.id ? ncmDue?.tipoCe : undefined,
                  ncm: ncmDue?.ncm?.id ? ncmDue?.ncm : undefined,
                  quantidade: normalizeNumeral(ncmDue?.quantidade),
                }
          ),
          ncmExportacao: (unnormalizedData?.due?.ncmExportacao || [])?.map((ncm) =>
            validate(ncm?.id)
              ? {
                  ...ncm,
                  id: null,
                }
              : ncm
          ),
          operacoesExportacaoConsignacoes: (unnormalizedData?.due?.operacoesExportacaoConsignacoes || [])?.map(
            (operacaoExportacaoConsignacao) =>
              validate(operacaoExportacaoConsignacao?.id)
                ? {
                    ...operacaoExportacaoConsignacao,
                    tipoDocumentoDue: operacaoExportacaoConsignacao?.tipoDocumentoDue?.id
                      ? operacaoExportacaoConsignacao?.tipoDocumentoDue
                      : null,
                    quantidadeEstatistica: normalizeNumeral(operacaoExportacaoConsignacao?.quantidadeEstatistica),
                    id: null,
                  }
                : {
                    ...operacaoExportacaoConsignacao,
                    tipoDocumentoDue: operacaoExportacaoConsignacao?.tipoDocumentoDue?.id
                      ? operacaoExportacaoConsignacao?.tipoDocumentoDue
                      : null,
                    quantidadeEstatistica: normalizeNumeral(operacaoExportacaoConsignacao?.quantidadeEstatistica),
                  }
          ),
          operacoesExportacaoTemporarias: (unnormalizedData?.due?.operacoesExportacaoTemporarias || [])?.map(
            (operacaoExportacaoTemporaria) =>
              validate(operacaoExportacaoTemporaria?.id)
                ? {
                    ...operacaoExportacaoTemporaria,
                    tipoDocumentoDue: operacaoExportacaoTemporaria?.tipoDocumentoDue?.id
                      ? operacaoExportacaoTemporaria?.tipoDocumentoDue
                      : null,
                    quantidadeEstatistica: normalizeNumeral(operacaoExportacaoTemporaria?.quantidadeEstatistica),
                    id: null,
                  }
                : {
                    ...operacaoExportacaoTemporaria,
                    tipoDocumentoDue: operacaoExportacaoTemporaria?.tipoDocumentoDue?.id
                      ? operacaoExportacaoTemporaria?.tipoDocumentoDue
                      : null,
                    quantidadeEstatistica: normalizeNumeral(operacaoExportacaoTemporaria?.quantidadeEstatistica),
                  }
          ),
          notaFiscal: !isEmpty(normalizedNotaFiscal) ? normalizedNotaFiscal : undefined,
          atosConcessorios: (unnormalizedData?.due?.atosConcessorios || [])?.map((atoConcessorio) =>
            validate(atoConcessorio?.id)
              ? {
                  ...atoConcessorio,
                  cnpj: numberOnly(atoConcessorio?.cnpj),
                  quantidade: normalizeNumeral(atoConcessorio?.quantidade),
                  vmleComCobertura: normalizeNumeral(atoConcessorio?.vmleComCobertura),
                  vmleSemCobertura: normalizeNumeral(atoConcessorio?.vmleSemCobertura),
                  tipoAtoConcessorio: atoConcessorio?.tipoAtoConcessorio?.id
                    ? atoConcessorio?.tipoAtoConcessorio
                    : null,
                  notasFiscais: (atoConcessorio?.notasFiscais || [])?.map((notaFiscal) =>
                    validate(notaFiscal?.id)
                      ? {
                          ...notaFiscal,
                          quantidade: normalizeNumeral(notaFiscal?.quantidade),
                          valor: normalizeNumeral(notaFiscal?.valor),
                          data: normalizeData(notaFiscal?.data),
                          id: null,
                        }
                      : {
                          ...notaFiscal,
                          quantidade: normalizeNumeral(notaFiscal?.quantidade),
                          valor: normalizeNumeral(notaFiscal?.valor),
                          data: normalizeData(notaFiscal?.data),
                        }
                  ),
                  documentosImportacao: (atoConcessorio?.documentosImportacao || [])?.map((documentoImportacao) =>
                    validate(documentoImportacao?.id)
                      ? {
                          ...documentoImportacao,
                          quantidade: normalizeNumeral(documentoImportacao?.quantidade),
                          valor: normalizeNumeral(documentoImportacao?.valor),
                          id: null,
                        }
                      : {
                          ...documentoImportacao,
                          quantidade: normalizeNumeral(documentoImportacao?.quantidade),
                          valor: normalizeNumeral(documentoImportacao?.valor),
                        }
                  ),
                  id: null,
                }
              : {
                  ...atoConcessorio,
                  cnpj: numberOnly(atoConcessorio?.cnpj),
                  quantidade: normalizeNumeral(atoConcessorio?.quantidade),
                  vmleComCobertura: normalizeNumeral(atoConcessorio?.vmleComCobertura),
                  vmleSemCobertura: normalizeNumeral(atoConcessorio?.vmleSemCobertura),
                  tipoAtoConcessorio: atoConcessorio?.tipoAtoConcessorio?.id
                    ? atoConcessorio?.tipoAtoConcessorio
                    : null,
                  notasFiscais: (atoConcessorio?.notasFiscais || [])?.map((notaFiscal) =>
                    validate(notaFiscal?.id)
                      ? {
                          ...notaFiscal,
                          quantidade: normalizeNumeral(notaFiscal?.quantidade),
                          valor: normalizeNumeral(notaFiscal?.valor),
                          data: normalizeData(notaFiscal?.data),
                          id: null,
                        }
                      : {
                          ...notaFiscal,
                          quantidade: normalizeNumeral(notaFiscal?.quantidade),
                          valor: normalizeNumeral(notaFiscal?.valor),
                          data: normalizeData(notaFiscal?.data),
                        }
                  ),
                  documentosImportacao: (atoConcessorio?.documentosImportacao || [])?.map((documentoImportacao) =>
                    validate(documentoImportacao?.id)
                      ? {
                          ...documentoImportacao,
                          quantidade: normalizeNumeral(documentoImportacao?.quantidade),
                          valor: normalizeNumeral(documentoImportacao?.valor),
                          id: null,
                        }
                      : {
                          ...documentoImportacao,
                          quantidade: normalizeNumeral(documentoImportacao?.quantidade),
                          valor: normalizeNumeral(documentoImportacao?.valor),
                        }
                  ),
                }
          ),
          notasReferenciadas: (unnormalizedData?.due?.notasReferenciadas || [])?.map((notaReferenciada) => ({
            ...notaReferenciada,
            quantidadeAssociada: normalizeNumeral(notaReferenciada?.quantidadeAssociada),
            itemNfe: parseInt(notaReferenciada?.itemNfe, 10),
            id: null,
          })),
          notasComplementares: (unnormalizedData?.due?.notasComplementares || [])?.map((notaComplementar) => ({
            ...notaComplementar,
            quantidadeAssociada: normalizeNumeral(notaComplementar?.quantidadeAssociada),
            id: null,
          })),
          notasReferenciadasFormulario: (unnormalizedData?.due?.notasReferenciadasFormulario || [])?.map(
            (notaReferenciadaFormulario) => ({
              ...notaReferenciadaFormulario,
              quantidade: normalizeNumeral(notaReferenciadaFormulario?.quantidade),
              id: null,
            })
          ),
        },
      };
    },
    [mercadoriaUtils]
  );

  const unnormalize = useCallback(
    (normalizedData) => {
      const unnormalizedData = mercadoriaUtils.unnormalize(normalizedData);

      return {
        ...unnormalizedData,
        valorTotalMcvReal: isValid(normalizedData?.valorTotalMcvReal)
          ? unnormalizeNumeral(normalizedData?.valorTotalMcvReal, formatBrazilianNumericDecimal(2))
          : undefined,
        operacaoFiscal: normalizedData?.operacaoFiscal?.id ? normalizedData?.operacaoFiscal : undefined,
        due: {
          ...normalizedData?.due,
          percentualComissaoAgente: unnormalizeNumeral(
            normalizedData?.due?.percentualComissaoAgente,
            formatBrazilianNumericDecimal(7)
          ),
          incotermCondicaoVenda: normalizedData?.due?.incotermCondicaoVenda?.id
            ? normalizedData?.due?.incotermCondicaoVenda
            : undefined,
          fornecedor: normalizedData?.due?.fornecedor?.id ? normalizedData?.due?.fornecedor : undefined,
          primeiroQuadrante: normalizedData?.due?.primeiroQuadrante?.id
            ? normalizedData?.due?.primeiroQuadrante
            : undefined,
          segundoEnquadramento: normalizedData?.due?.segundoEnquadramento?.id
            ? normalizedData?.due?.segundoEnquadramento
            : undefined,
          terceiroEnquadramento: normalizedData?.due?.terceiroEnquadramento?.id
            ? normalizedData?.due?.terceiroEnquadramento
            : undefined,
          quartoEnquadramento: normalizedData?.due?.quartoEnquadramento?.id
            ? normalizedData?.due?.quartoEnquadramento
            : undefined,
          tratamentoPrioritario: normalizedData?.due?.tratamentoPrioritario?.id
            ? normalizedData?.due?.tratamentoPrioritario
            : undefined,
          lpcos: (normalizedData?.due?.lpcos || []).map((lpco) => ({
            id: uuid(),
            lpco,
          })),
          ncms: (normalizedData?.due?.ncms || [])?.map((ncmDue) =>
            ncmDue?.id
              ? {
                  ...ncmDue,
                  tipoCe: ncmDue?.tipoCe?.id ? ncmDue?.tipoCe : undefined,
                  ncm: ncmDue?.ncm?.id ? ncmDue?.ncm : undefined,
                  quantidade: isValid(ncmDue?.quantidade)
                    ? unnormalizeNumeral(ncmDue?.quantidade, formatBrazilianNumericDecimal(5))
                    : undefined,
                }
              : {
                  ...ncmDue,
                  tipoCe: ncmDue?.tipoCe?.id ? ncmDue?.tipoCe : undefined,
                  ncm: ncmDue?.ncm?.id ? ncmDue?.ncm : undefined,
                  quantidade: isValid(ncmDue?.quantidade)
                    ? unnormalizeNumeral(ncmDue?.quantidade, formatBrazilianNumericDecimal(5))
                    : undefined,
                  id: uuid(),
                }
          ),
          ncmExportacao: (unnormalizedData?.due?.ncmExportacao || [])?.map((ncm) =>
            !ncm?.id
              ? {
                  ...ncm,
                  id: uuid(),
                }
              : ncm
          ),
          operacoesExportacaoConsignacoes: (normalizedData?.due?.operacoesExportacaoConsignacoes || [])?.map(
            (operacaoExportacaoConsignacao) =>
              operacaoExportacaoConsignacao?.id
                ? {
                    ...operacaoExportacaoConsignacao,
                    tipoDocumentoDue: operacaoExportacaoConsignacao?.tipoDocumentoDue?.id
                      ? operacaoExportacaoConsignacao?.tipoDocumentoDue
                      : undefined,
                    quantidadeEstatistica: isValid(operacaoExportacaoConsignacao?.quantidadeEstatistica)
                      ? unnormalizeNumeral(
                          operacaoExportacaoConsignacao?.quantidadeEstatistica,
                          formatBrazilianNumericDecimal(5)
                        )
                      : undefined,
                  }
                : {
                    ...operacaoExportacaoConsignacao,
                    tipoDocumentoDue: operacaoExportacaoConsignacao?.tipoDocumentoDue?.id
                      ? operacaoExportacaoConsignacao?.tipoDocumentoDue
                      : undefined,
                    quantidadeEstatistica: isValid(operacaoExportacaoConsignacao?.quantidadeEstatistica)
                      ? unnormalizeNumeral(
                          operacaoExportacaoConsignacao?.quantidadeEstatistica,
                          formatBrazilianNumericDecimal(5)
                        )
                      : undefined,
                    id: uuid(),
                  }
          ),
          operacoesExportacaoTemporarias: (normalizedData?.due?.operacoesExportacaoTemporarias || [])?.map(
            (operacaoExportacaoTemporaria) =>
              operacaoExportacaoTemporaria?.id
                ? {
                    ...operacaoExportacaoTemporaria,
                    tipoDocumentoDue: operacaoExportacaoTemporaria?.tipoDocumentoDue?.id
                      ? operacaoExportacaoTemporaria?.tipoDocumentoDue
                      : null,
                    quantidadeEstatistica: isValid(operacaoExportacaoTemporaria?.quantidadeEstatistica)
                      ? unnormalizeNumeral(
                          operacaoExportacaoTemporaria?.quantidadeEstatistica,
                          formatBrazilianNumericDecimal(5)
                        )
                      : undefined,
                  }
                : {
                    ...operacaoExportacaoTemporaria,
                    tipoDocumentoDue: operacaoExportacaoTemporaria?.tipoDocumentoDue?.id
                      ? operacaoExportacaoTemporaria?.tipoDocumentoDue
                      : null,
                    quantidadeEstatistica: isValid(operacaoExportacaoTemporaria?.quantidadeEstatistica)
                      ? unnormalizeNumeral(
                          operacaoExportacaoTemporaria?.quantidadeEstatistica,
                          formatBrazilianNumericDecimal(5)
                        )
                      : undefined,
                    id: uuid(),
                  }
          ),
          notaFiscal: normalizedData?.due?.notaFiscal
            ? {
                ...normalizedData?.due?.notaFiscal,
                cpfCnpjEmitente: formatCpfOrCnpj(normalizedData?.due?.notaFiscal?.cpfCnpjEmitente),
                estado: normalizedData?.due?.notaFiscal?.estado?.id ? normalizedData?.due?.notaFiscal?.estado : null,
                modeloNotaFiscal: normalizedData?.due?.notaFiscal?.modeloNotaFiscal?.id
                  ? normalizedData?.due?.notaFiscal?.modeloNotaFiscal
                  : null,
              }
            : undefined,
          atosConcessorios: (normalizedData?.due?.atosConcessorios || [])?.map((atoConcessorio) =>
            atoConcessorio?.id
              ? {
                  ...atoConcessorio,
                  cnpj: formatCnpj(atoConcessorio?.cnpj),
                  quantidade: isValid(atoConcessorio?.quantidade)
                    ? unnormalizeNumeral(atoConcessorio?.quantidade, formatBrazilianNumericDecimal(5))
                    : undefined,
                  vmleComCobertura: isValid(atoConcessorio?.vmleComCobertura)
                    ? unnormalizeNumeral(atoConcessorio?.vmleComCobertura, formatBrazilianNumericDecimal(2))
                    : undefined,
                  vmleSemCobertura: isValid(atoConcessorio?.vmleSemCobertura)
                    ? unnormalizeNumeral(atoConcessorio?.vmleSemCobertura, formatBrazilianNumericDecimal(2))
                    : undefined,
                  tipoAtoConcessorio: atoConcessorio?.tipoAtoConcessorio?.id
                    ? atoConcessorio?.tipoAtoConcessorio
                    : null,
                  notasFiscais: (atoConcessorio?.notasFiscais || [])?.map((notaFiscal) =>
                    notaFiscal?.id
                      ? {
                          ...notaFiscal,
                          quantidade: isValid(notaFiscal?.quantidade)
                            ? unnormalizeNumeral(notaFiscal?.quantidade, formatBrazilianNumericDecimal(5))
                            : undefined,
                          valor: isValid(notaFiscal?.valor)
                            ? unnormalizeNumeral(notaFiscal?.valor, formatBrazilianNumericDecimal(2))
                            : undefined,
                        }
                      : {
                          ...notaFiscal,
                          quantidade: isValid(notaFiscal?.quantidade)
                            ? unnormalizeNumeral(notaFiscal?.quantidade, formatBrazilianNumericDecimal(5))
                            : undefined,
                          valor: isValid(notaFiscal?.valor)
                            ? unnormalizeNumeral(notaFiscal?.valor, formatBrazilianNumericDecimal(2))
                            : undefined,
                          id: uuid(),
                        }
                  ),
                  documentosImportacao: (atoConcessorio?.documentosImportacao || [])?.map((documentoImportacao) =>
                    documentoImportacao?.id
                      ? {
                          ...documentoImportacao,
                          quantidade: isValid(documentoImportacao?.quantidade)
                            ? unnormalizeNumeral(documentoImportacao?.quantidade, formatBrazilianNumericDecimal(5))
                            : undefined,
                          valor: isValid(documentoImportacao?.valor)
                            ? unnormalizeNumeral(documentoImportacao?.valor, formatBrazilianNumericDecimal(2))
                            : undefined,
                        }
                      : {
                          ...documentoImportacao,
                          quantidade: isValid(documentoImportacao?.quantidade)
                            ? unnormalizeNumeral(documentoImportacao?.quantidade, formatBrazilianNumericDecimal(5))
                            : undefined,
                          valor: isValid(documentoImportacao?.valor)
                            ? unnormalizeNumeral(documentoImportacao?.valor, formatBrazilianNumericDecimal(2))
                            : undefined,
                          id: uuid(),
                        }
                  ),
                }
              : {
                  ...atoConcessorio,
                  cnpj: formatCnpj(atoConcessorio?.cnpj),
                  quantidade: isValid(atoConcessorio?.quantidade)
                    ? unnormalizeNumeral(atoConcessorio?.quantidade, formatBrazilianNumericDecimal(5))
                    : undefined,
                  vmleComCobertura: isValid(atoConcessorio?.vmleComCobertura)
                    ? unnormalizeNumeral(atoConcessorio?.vmleComCobertura, formatBrazilianNumericDecimal(2))
                    : undefined,
                  vmleSemCobertura: isValid(atoConcessorio?.vmleSemCobertura)
                    ? unnormalizeNumeral(atoConcessorio?.vmleSemCobertura, formatBrazilianNumericDecimal(2))
                    : undefined,
                  tipoAtoConcessorio: atoConcessorio?.tipoAtoConcessorio?.id
                    ? atoConcessorio?.tipoAtoConcessorio
                    : null,
                  notasFiscais: (atoConcessorio?.notasFiscais || [])?.map((notaFiscal) =>
                    notaFiscal?.id
                      ? {
                          ...notaFiscal,
                          quantidade: isValid(notaFiscal?.quantidade)
                            ? unnormalizeNumeral(notaFiscal?.quantidade, formatBrazilianNumericDecimal(5))
                            : undefined,
                          valor: isValid(notaFiscal?.valor)
                            ? unnormalizeNumeral(notaFiscal?.valor, formatBrazilianNumericDecimal(2))
                            : undefined,
                        }
                      : {
                          ...notaFiscal,
                          quantidade: isValid(notaFiscal?.quantidade)
                            ? unnormalizeNumeral(notaFiscal?.quantidade, formatBrazilianNumericDecimal(5))
                            : undefined,
                          valor: isValid(notaFiscal?.valor)
                            ? unnormalizeNumeral(notaFiscal?.valor, formatBrazilianNumericDecimal(2))
                            : undefined,
                          id: uuid(),
                        }
                  ),
                  documentosImportacao: (atoConcessorio?.documentosImportacao || [])?.map((documentoImportacao) =>
                    documentoImportacao?.id
                      ? {
                          ...documentoImportacao,
                          quantidade: isValid(documentoImportacao?.quantidade)
                            ? unnormalizeNumeral(documentoImportacao?.quantidade, formatBrazilianNumericDecimal(5))
                            : undefined,
                          valor: isValid(documentoImportacao?.valor)
                            ? unnormalizeNumeral(documentoImportacao?.valor, formatBrazilianNumericDecimal(2))
                            : undefined,
                        }
                      : {
                          ...documentoImportacao,
                          quantidade: isValid(documentoImportacao?.quantidade)
                            ? unnormalizeNumeral(documentoImportacao?.quantidade, formatBrazilianNumericDecimal(5))
                            : undefined,
                          valor: isValid(documentoImportacao?.valor)
                            ? unnormalizeNumeral(documentoImportacao?.valor, formatBrazilianNumericDecimal(2))
                            : undefined,
                          id: uuid(),
                        }
                  ),
                  id: uuid(),
                }
          ),
          notasReferenciadas: (normalizedData?.due?.notasReferenciadas || [])?.map((notaReferenciada) =>
            notaReferenciada?.id
              ? {
                  ...notaReferenciada,
                }
              : {
                  ...notaReferenciada,
                  id: uuid(),
                }
          ),
          notasComplementares: (normalizedData?.due?.notasComplementares || [])?.map((notaComplementar) =>
            notaComplementar?.id
              ? {
                  ...notaComplementar,
                }
              : {
                  ...notaComplementar,
                  id: uuid(),
                }
          ),
          notasReferenciadasFormulario: (normalizedData?.due?.notasReferenciadasFormulario || [])?.map(
            (notaReferenciadaFormulario) =>
              notaReferenciadaFormulario?.id
                ? {
                    ...notaReferenciadaFormulario,
                  }
                : {
                    ...notaReferenciadaFormulario,
                    id: uuid(),
                  }
          ),
        },
      };
    },
    [mercadoriaUtils]
  );

  const handleMercadoriaSubmit = useCallback(
    async (data, form) => {
      const normalizedMercadoria = normalize(data);

      if (isSubCreate) {
        const restartForm = () => {
          handleLoadingStatus();

          const executeDebouncedContinueCreating = debounce(() => {
            if (!isEmpty(form) && isFunction(form.restart)) {
              form.restart({
                partnumberAutocompleteSelector: '',
                partNumberSelector: '',
                partnumber: '',
                valorUnitarioMoeda: '',
                pesoLiquido: '',
                tipoCalculo: QUANTIDADE_X_VALOR,
                icms: '',
                quantidade: '',
                valorTotalMoeda: '',
                valorTotalMcvReal: '',
                quantidadeEstatistica: '',
                percentualDesconto: '',
              });
            }

            handleClearMercadoriaForm();
            handleNewMercadoria();

            handleResetStatus();
          }, 500);

          executeDebouncedContinueCreating();
        };

        await handleMercadoria(normalizedMercadoria, restartForm);
        return;
      }

      handleMercadoria(normalizedMercadoria);
    },
    [
      isSubCreate,
      normalize,
      handleLoadingStatus,
      handleClearMercadoriaForm,
      handleNewMercadoria,
      handleResetStatus,
      handleMercadoria,
    ]
  );

  const mercadoriaModel = useMemo(
    () => (!isSubCreate ? unnormalize(mercadoria) : {}),
    [isSubCreate, mercadoria, unnormalize]
  );

  const wizardSteps = useMemo(() => {
    const steps = [
      t('com.muralis.qcx.importacao.importacaoXMLDANFE'),
      t('com.muralis.qcx.basicas'),
      t('com.muralis.qcx.totais'),
      t('com.muralis.qcx.auxiliar'),
      t('com.muralis.qcx.mercadoria.label'),
    ];

    const filteredUnconditionalSteps = differenceWith(steps, conditionalSteps, isEqual);

    return hasNoConditionalStep ? steps : filteredUnconditionalSteps;
  }, [hasNoConditionalStep, conditionalSteps]);

  const ignoreRulesForSpecialSteps = useMemo(() => [t('com.muralis.qcx.mercadoria.label')], []);

  const wizardConditionalSteps = useMemo(
    () => (hasNoConditionalStep ? [] : conditionalSteps),
    [hasNoConditionalStep, conditionalSteps]
  );

  return (
    <>
      <QCXFinalFormWizard
        steps={wizardSteps}
        conditionalSteps={wizardConditionalSteps}
        defaultStep={stepNumberQueryParam}
        ignoreRulesForSpecialSteps={ignoreRulesForSpecialSteps}
        initialValues={model}
        handleChangeValues={handleChangeModel}
        isCreate={isCreate}
        isConsult={isConsult}
        isUpdate={isUpdate}
        isBackgroundCreate={isBackgroundCreate}
        isSubNone={isSubNone}
        isSubCreate={isSubCreate}
        isSubConsult={isSubConsult}
        isSubUpdate={isSubUpdate}
        handleSubmit={handleSubmit}
        checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
        handleAlternativeSave={handleAlternativeSave}
        handleChangeToCreate={handleChangeToCreate}
        handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
        handleChangeToConsult={handleChangeToConsult}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        handleResetBackgroundMode={handleResetBackgroundMode}
        handleChangeToSubNone={handleChangeToSubNone}
        handleMetadataUpdate={handleMetadataUpdate}
        {...restProps}
      >
        {hasNoConditionalStep &&
          ((formProps) => (
            <QCXImportacaoXmlDanfeForm
              key="ficha-importacao-xml-danfe"
              isCreate={isCreate}
              isConsult={isConsult}
              isUpdate={isUpdate}
              authInfo={authInfo}
              requiredRoles={requiredRoles}
              {...formProps}
            />
          ))}
        {(formProps) => (
          <QCXDeclaracaoUnicaExportacaoBasicasForm
            key="ficha-dados-basicos-declaracao-unica-exportacao"
            isConsult={isConsult}
            isCreate={isCreate}
            isLocked={isLocked}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            {...formProps}
          />
        )}
        {(formProps) => (
          <QCXFaturaTotaisForm
            key="ficha-totais-declaracao-unica-exportacao"
            isConsult={isConsult}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            {...formProps}
          />
        )}
        {({ controlComponentProps, ...restFormProps }) => (
          <QCXDeclaracaoUnicaExportacaoAuxiliarForm
            key="ficha-auxiliar-declaracao-unica-exportacao"
            isLoading={isLoading}
            isCreate={isCreate}
            isConsult={isConsult}
            isUpdate={isUpdate}
            parentModel={model}
            handleCustomSaveBy={handleCustomSaveBy}
            handleChangeToConsult={handleChangeToConsult}
            handleChangeToUpdate={handleChangeToUpdate}
            handleCancelUpdate={handleCancelUpdate}
            controlComponentProps={controlComponentProps}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
            {...omit(restFormProps, ['initialValues', 'handleSubmit'])}
          />
        )}
        {({ initialValues, controlComponentProps }) => (
          <QCXFaturaMercadoriaExportacaoForm
            key="ficha-mercadoria-declaracao-unica-exportacao"
            isIdle={isIdle}
            isLoading={isLoading}
            isFailure={isFailure}
            isPreparingAction={isPreparingAction}
            isCreate={isCreate}
            isConsult={isConsult}
            isUpdate={isUpdate}
            isSubNone={isSubNone}
            isSubCreate={isSubCreate}
            isSubConsult={isSubConsult}
            isSubUpdate={isSubUpdate}
            isBackgroundDelete={isBackgroundDelete}
            isSubBackgroundCreate={isSubBackgroundCreate}
            isSubBackgroundUpdate={isSubBackgroundUpdate}
            isSubBackgroundDelete={isSubBackgroundDelete}
            tipo={TIPO_DUE}
            faturaValues={initialValues}
            initialValues={mercadoriaModel}
            fatura={model}
            defaultItem={itemNumberQueryParam}
            handleSubmit={handleMercadoriaSubmit}
            handleChangeModel={handleDispatchSetMercadoriaModel}
            handleNew={handleNewMercadoria}
            handleEdit={handleEditMercadoria}
            handleChangeToSubConsult={handleChangeToSubConsult}
            handleChangeToConsult={handleChangeToConsult}
            handleCancelCreate={handleCancelMercadoriaCreate}
            handleCancelUpdate={handleCancelMercadoriaUpdate}
            handleRemoveByForm={handleRemoveMercadoriaByForm}
            handleRemoveByTable={handleRemoveMercadoriaByTable}
            handleChangeToSubNone={handleChangeToSubNone}
            handleClearForm={handleClearMercadoriaForm}
            handleChangeToUpdate={handleChangeToUpdate}
            handleMetadataUpdate={handleMetadataUpdate}
            handleLoadingStatus={handleLoadingStatus}
            handleResetStatus={handleResetStatus}
            tabManagerProps={tabManagerStepMercadoriaProps}
            formPaginationControlProps={formPaginationControlProps}
            controlComponentProps={controlComponentProps}
            authInfo={authInfo}
            requiredRoles={requiredRoles}
          />
        )}
      </QCXFinalFormWizard>
      {children}
      <QCXConfirmDialog
        key="confirm-dialog-remove-mercadoria-li"
        id="confirm-dialog-remove-mercadoria-li"
        open={operationOfRemoveMercadoriaById?.active}
        title={operationOfRemoveMercadoriaById?.title}
        content={operationOfRemoveMercadoriaById?.message}
        renderMiddleMessage={operationOfRemoveMercadoriaById?.renderMiddleMessage}
        endContent={operationOfRemoveMercadoriaById?.endMessage}
        onConfirm={operationOfRemoveMercadoriaById.confirm}
        onClose={operationOfRemoveMercadoriaById?.reset}
      />
    </>
  );
}
