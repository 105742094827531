import { Box, Container, Grid, makeStyles, Typography, useTheme, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import QCXFooter from '../footer/QCXFooter';
import QCXBreadcrumbs from '../breadcrumbs/QCXBreadcrumbs';

const useStyles = makeStyles((theme) => ({
  box: {
    width: 'inherit',
  },
  grow: {
    flexGrow: 1,
  },
  card: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    width: '100%',
    marginLeft: '-12px',
  },
}));

export default function QCXNavigatorContent({
  additionalClasses,
  page,
  children,
  breadcrumbs = [],
  breadcrumbsProps,
  ...restProps
}) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  return (
    <Grid container direction="row" justify="flex-start" className="fixed-footer">
      <Grid item container direction="row" alignItems="center" className="title-bar">
        <Grid item container xs={9} sm={9} md={9} xl={9} alignItems="center" style={{ marginLeft: theme.spacing(1) }}>
          <Grid item container alignItems="center" xs={10} sm={10} md={10} xl={10}>
            {/* {
              history.location.pathname !== '/' && (
                <Grid
                  item
                  xs={3}
                  sm={2}
                  md={1}
                  xl={1}
                >
                  <IconButton onClick={history.goBack}>
                    <ArrowBack />
                  </IconButton>
                </Grid>
              )
            } */}
            <Grid item xs={12}>
              {page?.icon}
              <Typography variant="h1" color="textPrimary">
                {page?.title}
              </Typography>
              {/* <Typography fontWeight="fontWeightBold" style={{ color: 'yellow' }}>
                Atenção!!! A plataforma ficará indisponível no dia 01 de Agosto a partir das 19h para que seja realizada
                a atualização da versão.
              </Typography> */}
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.grow} />
        <Grid
          item
          container
          xs={1}
          sm={1}
          md={1}
          xl={1}
          justify="flex-end"
          alignItems="center"
          style={{ marginRight: theme.spacing(3) }}
        >
          <Typography color="secondary" fontWeight="fontWeightBold">
            <a
              href="https://modelos-relatorios.s3.amazonaws.com/integracao/faturas/Itens+liberados+da+Vers%C3%A3o+Quick+Comex+1.pdf"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              {process.env?.REACT_APP_QCX_VERSION}
            </a>
          </Typography>
        </Grid>
      </Grid>

      <>
        {breadcrumbs?.length > 0 ? (
          <Grid item container direction="row" alignItems="center" justify="space-between" className="breadcrumbs-bar">
            <Grid item container xs={11} alignItems="center" style={{ marginLeft: theme.spacing(1) }}>
              <QCXBreadcrumbs data={breadcrumbs} {...breadcrumbsProps} />
            </Grid>
            {history.location.pathname !== '/' && (
              <IconButton onClick={history.goBack}>
                <ArrowBack color="secondary" />
              </IconButton>
            )}
          </Grid>
        ) : (
          <> </>
        )}
        <Grid item container direction="row" className={classes.card}>
          <Container {...restProps} style={{ display: 'flex', minHeight: 'calc(100vh - 250px)' }}>
            <Box component={Grid} container className={classes.box} justify="center" alignItems="flex-start">
              {children}
            </Box>
          </Container>
        </Grid>
      </>
      <QCXFooter />
    </Grid>
  );
}
