import { Box, Grid, FormControl, Typography, Button } from '@mui/material';
import BasicLayout from '../../../common/layouts/basicLayout';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useState, useEffect, SyntheticEvent } from 'react';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import { useDispatch, useSelector } from 'react-redux';
import { useListClientes } from '../../../common/hooks/useListClientes';
import { loading, success } from '../../../../features/danfe/danfeSlice';
import {
  selectUnidadesDeNegocioAssociadas,
  selectUnidadeSelecionada,
} from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { useUnidadeNegocioGuard } from '../../../common/hooks/useUnidadeNegocioGuard';
import { Form, Field } from 'react-final-form';
import moment from 'moment';
import { required as requiredValidator } from '../../../../utils/validators/field/validator';
import MultiSelectStyled, { SimpleMultiSelectOption } from '../../../common/components/input/multiSelectStyled';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../../App';
import { useKeycloak } from '@react-keycloak/web';
import { downloadSheet } from './relatorioLancamentosPage.helpers';
import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { GridItemModalProps } from '../../../common/components/cardsGrid/cardsModalGrid';
import { setErrorFeedback } from '../../../../features/feedback/feedbackSlice';

interface ControlledValue {
  id: string | number;
  value: string;
}

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      minWidth: '50vw',
    },
  },
});

const RelatorioLancamentosPage = ({ title, isOpen, closeModal }: GridItemModalProps) => {
  useUnidadeNegocioGuard();
  const classes = useStyles();
  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  const selectedUnit = useSelector(selectUnidadeSelecionada);
  const list = useSelector(selectUnidadesDeNegocioAssociadas);
  const [selectedBusiness, setSelectedBusiness] = useState<SimpleMultiSelectOption[]>([]);
  const [businessError, setBusinessError] = useState(false);
  const [clientes] = useListClientes();
  const [selectedClients, setSelectedClients] = useState<ControlledValue[]>([]);
  const dispatch = useDispatch();

  const handleClientsChange = (
    event: SyntheticEvent<Element, Event>,
    value: { id: string | number | undefined; value: string | undefined }[] | null
  ) => {
    const newSelectedClients: ControlledValue[] = [];
    value?.forEach((value) => {
      const selectedClient = clientes.find((cliente) => cliente.id === value.id);
      if (selectedClient && selectedClient.id && !selectedClients.some((client) => client.id === selectedClient.id)) {
        newSelectedClients.push({
          id: selectedClient.id.toString(),
          value: `(${selectedClient.pessoa!.cnpj}) ${selectedClient.pessoa!.nome}`,
        });
      }
    });
    setSelectedClients(newSelectedClients);
  };

  const handleSubmit = async (values: any) => {
    keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

    if (selectedBusiness.length === 0) {
      setBusinessError(true);
      return;
    } else {
      setBusinessError(false);
    }
    if (token && selectedUnit) {
      try {
        dispatch(loading());
        const dataInicio = values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : '';
        const dataFim = values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : '';

        const downloadResponse = await downloadSheet(
          token,
          selectedUnit,
          selectedBusiness.map((b) => b.id.toString()),
          selectedClients.map((c) => c.id.toString()),
          dataInicio,
          dataFim
        );

        dispatch(success());

        if (downloadResponse?.error) {
          dispatch(setErrorFeedback({ message: downloadResponse.message }));
          return;
        }
      } catch (error) {
        console.error('Erro ao baixar a planilha geral:', error);
      }
    }
  };

  return (
    <Dialog className={classes.root} open={isOpen} onClose={closeModal}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Grid item lg={12} xs={12}>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: 2 }}>
              <Box sx={{ flex: 2 }}>
                <Form
                  onSubmit={handleSubmit}
                  initialValues={{
                    startDate: moment().subtract(5, 'days').format('YYYY-MM-DD'),
                    endDate: moment().add(5, 'days').format('YYYY-MM-DD'),
                  }}
                  render={({ handleSubmit, values }) => (
                    <Box
                      component="form"
                      onSubmit={handleSubmit}
                      mt={2}
                      p={2}
                      border={1}
                      borderRadius={4}
                      borderColor="grey.300"
                    >
                      <Typography variant="h6">Filtros</Typography>
                      <Grid container spacing={2}>
                        <Grid item sm={4} style={{ margin: '5px 0 0 0' }}>
                          <FormControl fullWidth error={businessError}>
                            <MultiSelectStyled
                              disablePortal={false}
                              controlledValues={selectedBusiness}
                              options={list.map((business: { id: any; pessoa: { nomeResumido: any } }) => ({
                                id: business.id,
                                value: business.pessoa.nomeResumido,
                              }))}
                              onChangeAction={(event, value) => {
                                setSelectedBusiness(value || []);
                                values.business = value;
                              }}
                              label={'Unidade de Negócio'}
                            ></MultiSelectStyled>
                            {businessError && (
                              <Typography variant="body2" color="error">
                                Este campo é obrigatório
                              </Typography>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item sm={4} style={{ margin: '5px 0 0 0' }}>
                          <FormControl fullWidth>
                            <MultiSelectStyled
                              disablePortal={false}
                              options={clientes.map((client) => ({
                                id: client.id ?? '',
                                value: `(${client.pessoa!.cnpj}) ${client.pessoa!.nome}`,
                              }))}
                              onChangeAction={(event, value) => {
                                handleClientsChange(event, value);
                                values.clients = value;
                              }}
                              label={'Clientes'}
                            ></MultiSelectStyled>
                          </FormControl>
                        </Grid>
                        <Grid item sm={4} style={{ margin: '0px 0 0 0' }}>
                          <Field
                            name="startDate"
                            render={({ input }) => (
                              <QCXFinalDatePickerField
                                id="date-picker-start-date"
                                key="date-picker-start-date"
                                name={input.name}
                                label={'Data de Início'}
                                required={false}
                                validate={requiredValidator}
                                format="DD/MM/YYYY"
                                placeholder="DD/MM/YYYY"
                                disablePast={false}
                                disableFuture={false}
                                minDate={undefined}
                                onChange={(date: any) => {
                                  input.onChange(date);
                                  values.startDate = date;
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item sm={4} style={{ margin: '0px 0 0 0' }}>
                          <Field
                            name="endDate"
                            render={({ input }) => (
                              <QCXFinalDatePickerField
                                id="date-picker-end-date"
                                key="date-picker-end-date"
                                name={input.name}
                                label={'Data de Fim'}
                                required={false}
                                validate={requiredValidator}
                                format="DD/MM/YYYY"
                                placeholder="DD/MM/YYYY"
                                disablePast={false}
                                disableFuture={false}
                                minDate={undefined}
                                onChange={(date: any) => {
                                  input.onChange(date);
                                  values.endDate = date;
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item sm={4} style={{ display: 'flex', alignItems: 'flex-end', margin: '0px 0 0 0' }}>
                          <Button type="submit" variant="contained" color="secondary" fullWidth>
                            Buscar
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            padding: 2,
            display: 'flex',
            gap: 1,
          }}
        >
          <Button variant="outlined" color="primary" onClick={closeModal}>
            Fechar
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default RelatorioLancamentosPage;
