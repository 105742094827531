import React, { useCallback, useMemo } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import _ from 'lodash';
import { etapaFollowUpActions } from '../../features/etapa-follow-up/etapaFollowUpSlice';
import { CLOSED_STATUS, DONE_STATUS, FAIL_STATUS, isOpenStatus } from '../../utils/hooks/form/dialog/formDialogUtils';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';
import QCXMoveFocusInside from '../focus-lock/QCXMoveFocusInside';
import { followUpActions } from '../../features/follow-up/followUpSlice';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectTipoDocumentoAutocomplete from '../../shared-components/select-tipo-documento/QCXSelectTipoDocumentoAutocomplete';
import { MediaType } from '../../utils/api/api-utils';
import QCXFinalCustomInputFile from '../../shared-components/input-file/QCXFinalCustomInputFile';
import { followUpAPI } from '../../features/follow-up/followUpAPI';
import { isPerformingActionStatus } from '../../utils/store/store-utils';
import { followUpSelectors } from '../../features/follow-up/followUpSelectors';
import { PERFORMING_ACTION_STATUS } from '../../features/status';
import QCXCircularProgress from '../../shared-components/progress/QCXCircularProgress';
import { required } from '../../utils/validators/field/validator';
import TipoDocumentoUtils from '../../utils/general/tipo-documento/TipoDocumentoUtils';
import { selectTiposDocumentos } from '../../features/tipo-documento/tipoDocumentoSlice';
import QCXInfoAlert from '../../shared-components/alert/QCXInfoAlert';
import { setErrorFeedback, setSuccessFeedback } from '../../features/feedback/feedbackSlice';

const useStyles = makeStyles((theme) => ({
  gridFields: {
    paddingBottom: '16px',
  },
  customGridFields: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '16px',
    },
    paddingBottom: '16px',
  },
}));

export default function QCXNovoDocumentoFollowUpFormDialog({ parentModel, status, handleStatus }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch = useDispatch();

  const statusFollowUp = useSelector(followUpSelectors.selectStatus);
  const tiposDocumento = useSelector(selectTiposDocumentos);

  const performingAction = useMemo(() => isPerformingActionStatus(statusFollowUp), [statusFollowUp]);

  const parseToFormData = useCallback((data) => {
    const { documento, ...restData } = data;

    const jsonData = {
      ...restData,
      ...(documento?.id
        ? {
            documento,
          }
        : {}),
    };

    const json = JSON.stringify(jsonData);
    const blob = new Blob([json], {
      type: MediaType.APPLICATION_JSON,
    });

    const file = documento?.length > 0 ? documento[0] : undefined;

    const formData = new FormData();

    formData.append('data', blob);

    if (file) {
      formData.append('file', file);
    }

    return formData;
  }, []);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const formData = parseToFormData(values);

        dispatch(
          followUpActions.changeStatusTo({
            status: PERFORMING_ACTION_STATUS,
          })
        );

        const followUp = {
          id: parentModel?.id,
        };

        const response = await followUpAPI.addDocumentTo(followUp, formData);

        if (response.status === 201) {
          dispatch(followUpActions.success());
          dispatch(
            setSuccessFeedback({
              message: t('com.muralis.qcx.mensagem.followUpRegistrado', { numero: parentModel?.numero }),
            })
          );
          dispatch(followUpActions.refresh());

          handleStatus(DONE_STATUS);
        }
      } catch (error) {
        handleStatus(FAIL_STATUS);

        const isUnknownError =
          _.isEmpty(error?.response) || _.isEmpty(error?.response?.data) || _.isEmpty(error?.response?.data?.message);
        const errorMessage = isUnknownError
          ? t('com.muralis.qcx.erro.erroAdicionarDocumentoProcessoFollowUp', { numero: parentModel?.numero })
          : t('com.muralis.qcx.erro.erroAdicionarDocumentoProcessoFollowUpEspecifico', {
              numero: parentModel?.numero,
              mensagem: error?.response?.data?.message,
            });

        dispatch(followUpActions.failure());
        dispatch(setErrorFeedback({ message: errorMessage }));
      }
    },
    [parentModel, handleStatus, etapaFollowUpActions]
  );

  const handleCloseFormDialog = useCallback(() => handleStatus(CLOSED_STATUS), [handleStatus]);

  const open = useMemo(() => isOpenStatus(status), [status]);

  const formDialogTitle = useMemo(
    () => (
      <>
        ADICIONAR DOCUMENTO NO PROCESSO:
        <br />
        <Typography variant="subtitle1">
          <strong>{parentModel?.numero}</strong>
        </Typography>
      </>
    ),
    [parentModel]
  );

  const formDialogContent = useMemo(() => t('com.muralis.qcx.preenchaCamposAnexoArquivoNovoDocumento'), []);

  const formDialogActionsProps = useMemo(
    () => ({
      button: {
        submit: {
          description: t('com.muralis.qcx.acoes.adicionar'),
          size: 'small',
          disabled: performingAction,
          children: performingAction && <QCXCircularProgress color="disabled" size={20} />,
        },
        cancel: {
          description: t('com.muralis.qcx.acoes.cancelar'),
          size: 'small',
          disabled: performingAction,
        },
      },
    }),
    [performingAction]
  );

  const isSomeTipoDocumentoBy = useCallback(
    (tipoDocumentoId, codesToCompare) =>
      TipoDocumentoUtils.configureGeneralTipoDocumentoMatcherWith(tiposDocumento)(tipoDocumentoId, codesToCompare),
    [tiposDocumento]
  );

  return (
    <QCXFinalFormDialog
      key="form-dialog-adicionar-documento-follow-up"
      id="form-dialog-adicionar-documento-follow-up"
      title={formDialogTitle}
      content={formDialogContent}
      dialogActionsProps={formDialogActionsProps}
      open={open}
      onSubmit={handleSubmit}
      onClose={handleCloseFormDialog}
    >
      {({ submitting, values }) => (
        <Grid item container xs={12}>
          {isSomeTipoDocumentoBy(values?.tipoDocumento?.id, TipoDocumentoUtils.TIPO_DOCUMENTO_FATURA_CODE) && (
            <Grid
              item
              style={{
                paddingBottom: '24px',
              }}
              xs={12}
            >
              <QCXInfoAlert>
                <Trans i18nKey="com.muralis.qcx.mensagem.arquivosAdicionadosTimelineFollowUpImportacao">
                  <strong />
                  <strong />
                  <strong />
                  <strong />
                </Trans>
              </QCXInfoAlert>
            </Grid>
          )}
          <Grid item className={classes.gridFields} xs={12} sm={8}>
            <QCXMoveFocusInside>
              <QCXSelectTipoDocumentoAutocomplete
                id="autocomplete-select-tipo-documento-field"
                key="autocomplete-select-tipo-documento-field"
                name="tipoDocumento.id"
                label={t('com.muralis.qcx.documento.tipoDocumento')}
                initialValues={values}
                fieldProps={{ validate: required }}
                disabled={submitting || performingAction}
                required
              />
            </QCXMoveFocusInside>
          </Grid>
          <Grid item className={classes.customGridFields} xs={12} sm={4}>
            <QCXFinalTextField
              id="text-field-numero-documento"
              key="text-field-numero-documento"
              name="numero"
              label={t('com.muralis.qcx.numero')}
              maxLength={20}
              disabled={submitting || performingAction}
            />
          </Grid>
          <Grid item className={classes.gridFields} xs={12}>
            <QCXFinalCustomInputFile
              id="upload-input-documento-field"
              key="upload-input-documento-field"
              name="documento"
              label={t('com.muralis.qcx.origem')}
              tooltipTitle={t('com.muralis.qcx.documento.uploadDocumento')}
              accept={MediaType.ALL}
              fieldProps={{ validate: required }}
              // fileGetter
              // fileNameGetter
              disableRemoveControl
              disabled={submitting || performingAction}
              required
            />
          </Grid>
        </Grid>
      )}
    </QCXFinalFormDialog>
  );
}
