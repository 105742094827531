import { Box, CssBaseline, Paper, styled, ThemeProvider } from '@mui/material';
import { useAtom } from 'jotai';
import { isDrawerOpenAtom } from '../atoms/atoms';
import BreadCrumbs from '../components/breadCrumbs';
import LeftDrawer from '../components/leftDrawer/leftDrawer';
import LoadingIndicator from '../components/loadingIndicator';
import PageTitle from '../components/pageTitle';
import TopBar from '../components/topBar';
import theme from '../themes/theme';

type BasicLayoutProps = {
  title: string;
  children: JSX.Element | JSX.Element[];
  icon: JSX.Element;
  loading?: boolean;
};

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  // padding: theme.spacing(1),
  paddingTop: theme.spacing(8),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${239}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

// The theme provider has temporarily been inserted here to
// allow using Material UI V5. This theme provider overrides
// the theme provider in the App.js file.
// When Material UI V5 is fully supported, this theme provider
// can be removed and the new theme applied to App.ts.

const PageLayout = ({ children, title, icon, loading }: BasicLayoutProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useAtom(isDrawerOpenAtom);
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <TopBar></TopBar>
        <LeftDrawer></LeftDrawer>
        <Main open={isDrawerOpen}>
          <PageTitle icon={icon} title={title}></PageTitle>
          <BreadCrumbs></BreadCrumbs>
          <div style={{ padding: '10px' }}>
            <Paper style={{ padding: '25px', margin: '0px 0 0 0' }} sx={{ boxShadow: 4 }}>
              {loading ? <LoadingIndicator message="Carregando dados..." /> : children}
            </Paper>
          </div>
        </Main>
      </Box>
    </ThemeProvider>
  );
};

export default PageLayout;
