import { Grid } from '@material-ui/core';
import React, { useMemo, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useValidators from '../../utils/hooks/useValidators';
import { cidadeActions } from '../../features/cidade/cidadeSlice';
import { estadoActions } from '../../features/estado/estadoSlice';
import { paisActions } from '../../features/pais/paisSlice';
import { AUTO_LOAD_CONTROL } from '../../features/config-control';

import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectCidadeAutocomplete from '../../shared-components/select-cidade/QCXSelectCidadeAutocomplete';
import QCXSelectEstadoAutocomplete from '../../shared-components/select-estado/QCXSelectEstadoAutocomplete';
import QCXSelectPaisAutocomplete from '../../shared-components/select-pais/QCXSelectPaisAutocomplete';
import QCXSelectContaContabilAutocomplete from '../../shared-components/select-conta-contabil/QCXSelectContaContabilAutocomplete';
import QCXSelectContaBancariaAutocompleteField from '../../shared-components/select-conta-bancaria/QCXSelectContaBancariaAutocompleteField';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';

import {
  formatCep,
  formatCpfOrCnpj,
  formatTelefoneFixo,
  formatTelefoneCelular,
} from '../../utils/hooks/form/field/formatters';

import {
  parseTelefoneCelular,
  parseCpfOrCnpj,
  parseCep,
  numberOnly,
  parseTelefoneFixoWithDDD,
} from '../../utils/hooks/form/field/parsers';

import {
  validTelefoneCelularLength,
  configureNumberNotBetweenMinAndMaxValidator,
  required as requiredValidator,
  validTelefoneLength,
  validTelefoneFixoWithDDDLength,
  validCpfOrCnpj,
  validCnpj,
  validCep,
} from '../../utils/validators/field/validator';

export default function QCXFornecedorForm({
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(paisActions.changeControlTo(AUTO_LOAD_CONTROL));
  }, []);

  const isBrasilSelected = (value) => value === 1;

  const telefoneFixoValidators = useMemo(
    () =>
      useValidators([
        validTelefoneLength,
        configureNumberNotBetweenMinAndMaxValidator(8, 10),
        configureNumberNotBetweenMinAndMaxValidator(10, 12),
      ]),
    []
  );

  const telefoneFixoRequiredValidators = useMemo(
    () =>
      useValidators([
        requiredValidator,
        validTelefoneFixoWithDDDLength,
        configureNumberNotBetweenMinAndMaxValidator(10, 12),
      ]),
    []
  );

  const celularValidators = useMemo(
    () =>
      useValidators([
        validTelefoneCelularLength,
        configureNumberNotBetweenMinAndMaxValidator(9, 11),
        configureNumberNotBetweenMinAndMaxValidator(11, 13),
      ]),
    []
  );

  const validateCpfCnpj = useMemo(() => useValidators([validCpfOrCnpj]), []);

  const validateCep = useMemo(() => useValidators([validCep(8, 9)]), []);

  const isPessoaJuridicaBy = useCallback((cpfCnpj) => {
    const sanitizedCpfCnpj = numberOnly(cpfCnpj);
    const isValidCnpj = validCnpj(sanitizedCpfCnpj);
    return isValidCnpj === undefined;
  }, []);

  const cidadeOnchange = (form) => (value) => {
    form.change('endereco.cidade.id', value);
  };

  const estadoOnchange = (form) => (value) => {
    form.change('endereco.cidade.id', undefined);
    form.change('endereco.cidade.estado.id', value);
    dispatch(cidadeActions.fetchByFilterAsync([{ name: 'estado', value }]));
  };

  const onCpfCnpjChange = useCallback(
    (form) => (value) => {
      if (!isPessoaJuridicaBy(value)) {
        form.change('pessoa.inscricaoEstadual', undefined);
        form.change('pessoa.inscricaoMunicipal', undefined);
      }
    },
    [isPessoaJuridicaBy]
  );

  const paisOnchange = (form, values) => (value) => {
    const isCurrentBrazilSelected = isBrasilSelected(value);
    const postalCode = values?.endereco?.cep;
    const isEmptyPostaCode = !postalCode;

    form.change('endereco.cidade.id', undefined);
    form.change('endereco.cidade.estado.id', undefined);
    form.change('endereco.cidade.estado.pais.id', value);
    dispatch(estadoActions.fetchByFilterAsync([{ name: 'pais', value }]));
    form.resetFieldState('endereco.cep');

    if (isEmptyPostaCode) {
      return;
    }

    const postalCodeString = String(postalCode);
    if (!isCurrentBrazilSelected) {
      const sanitizedPostalCode = postalCodeString.replace('-', '');
      form.change('endereco.cep', sanitizedPostalCode);
      return;
    }

    if (postalCodeString.length <= 9) {
      form.change('endereco.cep', formatCep(postalCodeString));
      return;
    }

    const ajustedPostalCode = postalCodeString.slice(0, 8);
    form.change('endereco.cep', formatCep(ajustedPostalCode));
  };

  const listenerList = useMemo(
    () => [
      {
        name: 'endereco.cidade.estado.id',
        fn: estadoOnchange,
      },
      {
        name: 'endereco.cidade.id',
        fn: cidadeOnchange,
      },
      {
        name: 'endereco.cidade.estado.pais.id',
        fn: paisOnchange,
      },
      {
        name: 'cpfCnpj',
        fn: onCpfCnpjChange,
      },
    ],
    [cidadeOnchange, estadoOnchange, paisOnchange, onCpfCnpjChange]
  );

  const formControlButtons = useMemo(
    () => ({
      create: {
        description: t('com.muralis.qcx.acoes.salvar').toUpperCase(),
      },
      cancel: {
        description: t('com.muralis.qcx.acoes.cancelar').toUpperCase(),
        size: 'small',
        hidden: false,
      },
    }),
    []
  );

  return (
    <>
      <QCXFinalForm
        isConsult={isConsult}
        isUpdate={isUpdate}
        initialValues={initialValues}
        controlButtons={formControlButtons}
        handleSubmitValues={handleSubmit}
        handleChangeToUpdate={handleChangeToUpdate}
        handleCancelUpdate={handleCancelUpdate}
        onChangeListeners={listenerList}
        {...restProps}
      >
        {({ values }) => (
          <>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalOnblurMaskedField
                id="masked-text-cpf-cnpj-fornecedor-field"
                key="masked-text-cpf-cnpj-fornecedor-field"
                name="cpfCnpj"
                label={t('com.muralis.qcx.CPFCNPJ')}
                format={formatCpfOrCnpj}
                parse={parseCpfOrCnpj}
                validate={validateCpfCnpj}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                id="text-field-razao-social"
                key="text-field-razao-social"
                name="pessoa.nome"
                label={t('com.muralis.qcx.empresa.razaoSocial')}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                id="text-field-pessoa-nome-resumido"
                key="text-field-pessoa-nome-resumido"
                name="pessoa.nomeResumido"
                label={t('com.muralis.qcx.nomeResumido')}
                maxLength={30}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalOnblurMaskedField
                id="masked-text-field-endereco-cep"
                key="masked-text-field-endereco-cep"
                label={t('com.muralis.qcx.endereco.CEP')}
                name="endereco.cep"
                maxLength={9}
                format={formatCep}
                parse={parseCep}
                validate={validateCep}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                id="text-field-endereco-logradouro"
                key="text-field-endereco-logradouro"
                name="endereco.logradouro"
                label={t('com.muralis.qcx.endereco.logradouro')}
                disabled={isConsult}
                maxLength={150}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                id="text-field-endereco-numero"
                key="text-field-endereco-numero"
                name="endereco.numero"
                label={t('com.muralis.qcx.endereco.numero')}
                maxLength={10}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                id="text-field-endereco-complemento"
                key="text-field-endereco-complemento"
                name="endereco.complemento"
                label={t('com.muralis.qcx.endereco.complemento')}
                maxLength={50}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                id="text-field-endereco-bairro"
                key="text-field-endereco-bairro"
                name="endereco.bairro"
                label={t('com.muralis.qcx.endereco.bairro')}
                maxLength={70}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXSelectPaisAutocomplete
                id="select-endereco-pais-autocomplete"
                key="select-endereco-pais-autocomplete"
                name="endereco.cidade.estado.pais.id"
                label={t('com.muralis.qcx.endereco.pais')}
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXSelectEstadoAutocomplete
                id="select-endereco-estado-autocomplete"
                key="select-endereco-estado-autocomplete"
                name="endereco.cidade.estado.id"
                label={t('com.muralis.qcx.endereco.estado')}
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXSelectCidadeAutocomplete
                id="select-endereco-cidade-autocomplete"
                key="select-endereco-cidade-autocomplete"
                name="endereco.cidade.id"
                label={t('com.muralis.qcx.endereco.cidade')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalCheckboxField
                id="checkbox-favorecido"
                key="checkbox-favorecido"
                name="favorecido"
                label={t('com.muralis.qcx.favorecido')}
                disabled={isConsult}
              />
            </Grid>
            {isPessoaJuridicaBy(values?.cpfCnpj) && (
              <>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <QCXFinalTextField
                    id="input-pessoa-inscricao-estadual"
                    key="input-pessoa-inscricao-estadual"
                    name="pessoa.inscricaoEstadual"
                    label={t('com.muralis.qcx.empresa.inscricaoEstadual')}
                    parse={numberOnly}
                    maxLength={15}
                    disabled={isConsult}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <QCXFinalTextField
                    id="input-pessoa-inscricao-municipal"
                    key="input-pessoa-inscricao-municipal"
                    name="pessoa.inscricaoMunicipal"
                    label={t('com.muralis.qcx.empresa.inscricaoMunicipal')}
                    parse={numberOnly}
                    maxLength={15}
                    disabled={isConsult}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXSelectContaContabilAutocomplete
                id="select-field-conta-contabil"
                key="select-field-conta-contabil"
                name="contaContabil.id"
                label={t('com.muralis.qcx.empresa.contaContabil')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXSelectContaBancariaAutocompleteField
                id="autocomplete-select-conta-bancaria-field"
                key="autocomplete-select-conta-bancaria-field"
                name="contaBancaria.id"
                label={t('com.muralis.qcx.contaBancaria.label')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalOnblurMaskedField
                id="text-field-celular"
                key="text-field-celular"
                name="contato.celular"
                label={t('com.muralis.qcx.contato.label')}
                format={formatTelefoneCelular}
                parse={parseTelefoneCelular}
                maxLength={13}
                disabled={isConsult}
                validate={celularValidators}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalOnblurMaskedField
                id="text-field-telefone"
                key="text-field-telefone"
                name="contato.telefone"
                label={t('com.muralis.qcx.telefone')}
                format={formatTelefoneFixo}
                parse={parseTelefoneFixoWithDDD}
                maxLength={12}
                disabled={isConsult}
                {...(values?.configuracoes?.responsavelIcms
                  ? { validate: telefoneFixoRequiredValidators }
                  : { validate: telefoneFixoValidators })}
                required={values?.configuracoes?.responsavelIcms}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalTextField
                id="text-field-email"
                key="text-field-email"
                name="contato.email"
                label={t('com.muralis.qcx.email')}
                type="email"
                disabled={isConsult}
              />
            </Grid>
          </>
        )}
      </QCXFinalForm>
    </>
  );
}
