import { useKeycloak } from '@react-keycloak/web';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { selectUnidadeSelecionada } from '../../../features/usuario-logado/usuarioLogadoSlice';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../App';
import { setSuccessFeedback } from '../../../features/feedback/feedbackSlice';
import { finishLoading, startLoading } from '../../../features/application-state/applicationStateSlice';

export enum QueriesTypes {
  AgentesDeCarga = 'agentesDeCarga',
  AcordoAladi = 'acordoAladi',
  CadastroInProposta = 'cadastroInProposta',
  CadastrosProposta = 'cadastroProposta',
  Cfops = 'cfops',
  Clientes = 'clientes',
  ServicosClientes = 'servicosCliente',
  Danfe = 'danfe',
  DanfeDonwloadXML = 'danfeDownloadXml',
  DanfeDonwloadXMLAssinada = 'danfeDownloadXmlAssinada',
  DanfeDonwloadXMLSefaz = 'danfeDonwloadXMLSefaz',
  DanfeDonwloadTriangulusXML = 'danfeDonwloadTriangulusXML',
  DanfeDonwloadTXT = 'danfeDownloadTxt',
  DeclaracaoImportacaoXMLReport = 'declaracaoImportacaoXMLReport',
  DeclaracaoImportacaoXMLReportDiagnostico = 'declaracaoImportacaoXMLReportDiagnostico',
  Despachantes = 'despachantes',
  DespachantesFilter = 'despachantesFilter',
  DespesasReceitas = 'despesasReceitas',
  DespesasReceitasFilter = 'despesasReceitasFilter',
  DispositivosLegais = 'dispositivosLegais',
  FabricanteExportador = 'fabricanteExportador',
  FabricanteExportadorSummary = 'FabricanteExportadorSummary',
  FabricanteExportadorPlanilha = 'fabricanteExportadorPlanilha',
  FaturasDIDuimp = 'faturasDIDuimp',
  FollowUps = 'followUps',
  FollowUpsFilter = 'followUpsFilter',
  Indices = 'indices',
  LocaisDeEmbarque = 'locaisDeEmbarque',
  LicencaImportacaoXMLReport = 'licencaImportacaoXMLReport',
  MercadoriasProposta = 'mercadoriaProposta',
  PerfilAdicionarPermissao = 'perfilAdicionarPermissao',
  PropostaFollowUp = 'propostaFollowUp',
  Propostas = 'propostas',
  PropostasAnexos = 'propostasAnexos',
  Receitas = 'receitas',
  RelatorioDraftDanfe = 'relatorioDraftDanfe',
  RelatorioDraftDanfeFaxe = 'relatorioDraftDanfeFaxe',
  RelatorioHalliburton = 'relatorioHalliburton',
  RelatorioLa = 'relatorioLa',
  RelatorioMercadoriasDue = 'relatorioMercadoriasDue',
  ServicoInProposta = 'servicoInProposta',
  ServicoProposta = 'servicoProposta',
  TotvsCorrespondenciasGerais = 'totvsCorrespondenciasGerais',
  TotvsCorrespondenciasImportador = 'totvsCorrespondenciasImportador',
  TotvsDanfesCapa = 'totvsDanfesCapa',
  TotvsDanfesItens = 'totvsDanfesItens',
  TotvsDespesasCapa = 'totvsDespesasCapa',
  TotvsDespesasItens = 'totvsDespesasItens',
  TotvsFaturas = 'totvsFaturas',
  TotvsNumerarios = 'totvsNumerarios',
  Transportadora = 'transportadora',
  TransportadorInternacional = 'transportadorInternacional',
  CrodaDanfesGeraTxt = 'crodaDanfesGeraTxt',
}

export const useGetRequest = (type: QueriesTypes, pathVariables?: string | number, queryParams?: string) => {
  const { keycloak } = useKeycloak();
  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  const { token } = keycloak;
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);
  let baseUrl = process.env.REACT_APP_QCX_API_BASE_URL;

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.append('Authorization', `Bearer ${token}`);
  requestHeaders.append('quickcomexTenant', selectedUnidade.toString());

  let endPoint = '';

  switch (type) {
    case QueriesTypes.FabricanteExportador:
      endPoint = process.env.REACT_APP_QCX_API_EXPORTADOR_FABRICANTE!;
      break;
    case QueriesTypes.FabricanteExportadorSummary:
      endPoint = process.env.REACT_APP_QCX_API_EXPORTADOR_FABRICANTE! + '/summary';
      break;
    case QueriesTypes.FabricanteExportadorPlanilha:
      endPoint = process.env.REACT_APP_QCX_API_EXPORTADOR_FABRICANTE! + '/planilha';
      break;
    case QueriesTypes.TransportadorInternacional:
      endPoint = process.env.REACT_APP_QCX_TRANSPORTADOR_INTERNACIONAL_ENDPOINT!;
      break;
    case QueriesTypes.LocaisDeEmbarque:
      endPoint = process.env.REACT_APP_QCX_PORTO_AEROPORTO_ENDPOINT!;
      break;
    case QueriesTypes.AgentesDeCarga:
      endPoint = process.env.REACT_APP_QCX_TRANSPORTADOR_INTERNACIONAL_ENDPOINT!;
      break;
    case QueriesTypes.DispositivosLegais:
      endPoint = process.env.REACT_APP_QCX_DISPOSITIVO_LEGAL_ENDPOINT!;
      break;
    case QueriesTypes.FollowUps:
      endPoint = process.env.REACT_APP_QCX_API_FOLLOW_UP_ENDPOINT!;
      break;
    case QueriesTypes.FollowUpsFilter:
      endPoint = process.env.REACT_APP_QCX_API_FOLLOW_UP_ENDPOINT! + '/filter';
      break;
    case QueriesTypes.FaturasDIDuimp:
      endPoint = process.env.REACT_APP_QCX_API_FATURA_ENDPOINT! + '/filter?tipo=DI_DUIMP';
      break;
    case QueriesTypes.Danfe:
      endPoint = process.env.REACT_APP_QCX_API_DANFE_ENDPOINT! + '/filter?active=true';
      break;
    case QueriesTypes.DanfeDonwloadXML:
      endPoint = process.env.REACT_APP_QCX_API_DANFE_ENDPOINT! + '/download-xml';
      break;
    case QueriesTypes.DanfeDonwloadXMLAssinada:
      endPoint = process.env.REACT_APP_QCX_API_DANFE_ENDPOINT! + '/download-xml-assinado';
      break;
    case QueriesTypes.DanfeDonwloadXMLSefaz:
      endPoint = process.env.REACT_APP_QCX_API_DANFE_ENDPOINT! + '/download-xml-sefaz';
      break;
    case QueriesTypes.DanfeDonwloadTriangulusXML:
      endPoint = process.env.REACT_APP_TRIANGULUS_API_DANFE_ENDPOINT! + '/xml';
      break;
    case QueriesTypes.DanfeDonwloadTXT:
      endPoint = process.env.REACT_APP_QCX_API_DANFE_ENDPOINT! + '/download-txt';
      break;
    case QueriesTypes.RelatorioDraftDanfe:
      baseUrl = process.env.REACT_APP_RELATORIOS_API_BASE_URL!;
      endPoint = process.env.REACT_APP_RELATORIOS_API_TITAN_ENDPOINT!;
      break;
    case QueriesTypes.RelatorioDraftDanfeFaxe:
      baseUrl = process.env.REACT_APP_RELATORIOS_API_BASE_URL!;
      endPoint = process.env.REACT_APP_RELATORIOS_API_TITAN_ENDPOINT! + '/faxe';
      break;
    case QueriesTypes.RelatorioLa:
      baseUrl = process.env.REACT_APP_RELATORIOS_API_BASE_URL!;
      endPoint = process.env.REACT_APP_RELATORIOS_API_LA_ENDPOINT!;
      break;
    case QueriesTypes.RelatorioHalliburton:
      baseUrl = process.env.REACT_APP_RELATORIOS_API_BASE_URL!;
      endPoint = process.env.REACT_APP_RELATORIOS_API_HALLIBURTON_ENDPOINT!;
      break;
    case QueriesTypes.RelatorioMercadoriasDue:
      baseUrl = process.env.REACT_APP_RELATORIOS_API_BASE_URL!;
      endPoint = process.env.REACT_APP_RELATORIOS_API_DUE_MERCADORIAS_ENDPOINT!;
      break;
    case QueriesTypes.Propostas:
      baseUrl = process.env.REACT_APP_QCX_PROPOSTAS_BASE_URL!;
      endPoint = process.env.REACT_APP_QCX_PROPOSTAS_PROPOSTAS_ENDPOINT!;
      break;
    case QueriesTypes.ServicoProposta:
      baseUrl = process.env.REACT_APP_QCX_PROPOSTAS_BASE_URL!;
      endPoint = process.env.REACT_APP_QCX_PROPOSTAS_SERVICOS_ENDPOINT!;
      break;
    case QueriesTypes.CadastrosProposta:
      baseUrl = process.env.REACT_APP_QCX_PROPOSTAS_BASE_URL!;
      endPoint = process.env.REACT_APP_QCX_PROPOSTAS_CADASTROS_ENDPOINT!;
      break;
    case QueriesTypes.MercadoriasProposta:
      baseUrl = process.env.REACT_APP_QCX_PROPOSTAS_BASE_URL!;
      endPoint = process.env.REACT_APP_QCX_PROPOSTAS_MERCADORIAS_ENDPOINT!;
      break;
    case QueriesTypes.Clientes:
      baseUrl = process.env.REACT_APP_QCX_API_BASE_URL!;
      endPoint = process.env.REACT_APP_QCX_API_CLIENTE_ENDPOINT!;
      break;
    case QueriesTypes.Indices:
      baseUrl = process.env.REACT_APP_QCX_PROPOSTAS_BASE_URL!;
      endPoint = process.env.REACT_APP_QCX_PROPOSTAS_INDICES_ENDPOINT!;
      break;
    case QueriesTypes.Despachantes:
      endPoint = process.env.REACT_APP_QCX_API_DESPACHANTE_ENDPOINT!;
      break;
    case QueriesTypes.DespachantesFilter:
      endPoint = process.env.REACT_APP_QCX_API_DESPACHANTE_ENDPOINT! + '/filter';
      break;
    case QueriesTypes.DespesasReceitas:
      endPoint = process.env.REACT_APP_QCX_API_DESPESA_RECEITA_ENDPOINT!;
      break;
    case QueriesTypes.DespesasReceitasFilter:
      endPoint = process.env.REACT_APP_QCX_API_DESPESA_RECEITA_ENDPOINT! + '/filter';
      break;
    case QueriesTypes.Receitas:
      endPoint = process.env.REACT_APP_QCX_API_CODIGO_RECEITA_ENDPOINT!;
      break;
    case QueriesTypes.Cfops:
      endPoint = process.env.REACT_APP_QCX_API_CFOP_OPERACAO_FISCAL_ENDPOINT!;
      break;
    case QueriesTypes.Transportadora:
      endPoint = process.env.REACT_APP_QCX_TRANSPORTADORA_ENDPOINT!;
      break;
    case QueriesTypes.TotvsCorrespondenciasImportador:
      baseUrl = process.env.REACT_APP_TOTVS_API_BASE_URL!;
      endPoint = process.env.REACT_APP_TOTVS_API_CORRESPONDENCIAS_IMPORTADORES_ENDPOINT!;
      break;
    case QueriesTypes.TotvsCorrespondenciasGerais:
      baseUrl = process.env.REACT_APP_TOTVS_API_BASE_URL!;
      endPoint = process.env.REACT_APP_TOTVS_API_CORRESPONDENCIAS_GERAIS_ENDPOINT!;
      break;
    case QueriesTypes.TotvsDespesasCapa:
      baseUrl = process.env.REACT_APP_TOTVS_API_BASE_URL!;
      endPoint = process.env.REACT_APP_TOTVS_API_DESPESAS_ENDPOINT!;
      break;
    case QueriesTypes.TotvsDespesasItens:
      baseUrl = process.env.REACT_APP_TOTVS_API_BASE_URL!;
      endPoint = process.env.REACT_APP_TOTVS_API_DESPESAS_ENDPOINT! + '/itens';
      break;
    case QueriesTypes.TotvsDanfesCapa:
      baseUrl = process.env.REACT_APP_TOTVS_API_BASE_URL!;
      endPoint = process.env.REACT_APP_TOTVS_API_DANFE_ENDPOINT!;
      break;
    case QueriesTypes.TotvsDanfesItens:
      baseUrl = process.env.REACT_APP_TOTVS_API_BASE_URL!;
      endPoint = process.env.REACT_APP_TOTVS_API_DANFE_ENDPOINT! + '/itens';
      break;
    case QueriesTypes.DeclaracaoImportacaoXMLReport:
      endPoint = process.env.REACT_APP_QCX_API_DECLARACAO_IMPORTACAO_ENDPOINT! + '/xml-report/di';
      break;
    case QueriesTypes.DeclaracaoImportacaoXMLReportDiagnostico:
      endPoint = process.env.REACT_APP_QCX_API_DECLARACAO_IMPORTACAO_ENDPOINT! + '/xml-transmissao-diagnostico/di';
      break;
    case QueriesTypes.LicencaImportacaoXMLReport:
      endPoint = process.env.REACT_APP_QCX_API_LICENCA_IMPORTACAO_ENDPOINT! + '/xml-report';
      break;
    case QueriesTypes.AcordoAladi:
      endPoint = process.env.REACT_APP_QCX_API_ACORDO_ALADI_ENDPOINT!;
      break;
    case QueriesTypes.ServicosClientes:
      baseUrl = process.env.REACT_APP_QCX_API_BASE_URL;
      endPoint = process.env.REACT_APP_QCX_API_CLIENTE_SERVICO_ENDPOINT!;
      break;
    case QueriesTypes.TotvsNumerarios:
      baseUrl = process.env.REACT_APP_TOTVS_API_BASE_URL!;
      endPoint = 'numerarios';
      break;
    case QueriesTypes.CrodaDanfesGeraTxt:
      baseUrl = process.env.REACT_APP_QCX_API_BASE_URL!;
      endPoint = process.env.REACT_APP_CRODA_API_DANFE_ENDPOINT! + '/escreve/txt';
      break;
    default:
      break;
  }

  return useQuery(
    [type, queryParams, pathVariables],
    async () => {
      let url = `${baseUrl}/${endPoint}`;
      if (pathVariables) {
        url += `/${pathVariables}`;
      }
      if (queryParams) {
        url += `?${queryParams}`;
      }

      const response = await fetch(url, {
        method: 'GET',
        headers: requestHeaders,
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      // Check if response disposition is attachment
      const contentDisposition = response.headers.get('content-disposition');
      if (
        type === QueriesTypes.DanfeDonwloadXML ||
        type === QueriesTypes.DanfeDonwloadXMLAssinada ||
        type === QueriesTypes.DanfeDonwloadXMLSefaz ||
        type === QueriesTypes.DanfeDonwloadTXT ||
        type === QueriesTypes.DanfeDonwloadTriangulusXML ||
        (contentDisposition && contentDisposition.indexOf('attachment') !== -1)
      ) {
        const blob = await response.blob();
        return blob;
      }

      const result = await response.json();
      return result;
    },
    {
      refetchOnWindowFocus: false,
      enabled: type !== QueriesTypes.ServicosClientes || !!pathVariables,
    }
  );
};

function getUrlForQuery(type: QueriesTypes) {
  let baseUrl = process.env.REACT_APP_QCX_API_BASE_URL!;
  let endPoint = '';

  switch (type) {
    case QueriesTypes.FollowUps:
      endPoint = process.env.REACT_APP_QCX_API_FOLLOW_UP_ENDPOINT!;
      break;
    case QueriesTypes.MercadoriasProposta:
      baseUrl = process.env.REACT_APP_QCX_PROPOSTAS_BASE_URL!;
      endPoint = process.env.REACT_APP_QCX_PROPOSTAS_MERCADORIAS_ENDPOINT!;
      break;
    case QueriesTypes.Propostas:
      baseUrl = process.env.REACT_APP_QCX_PROPOSTAS_BASE_URL!;
      endPoint = process.env.REACT_APP_QCX_PROPOSTAS_PROPOSTAS_ENDPOINT!;
      break;
    case QueriesTypes.ServicoProposta:
      baseUrl = process.env.REACT_APP_QCX_PROPOSTAS_BASE_URL!;
      endPoint = process.env.REACT_APP_QCX_PROPOSTAS_SERVICOS_ENDPOINT!;
      break;
    case QueriesTypes.CadastrosProposta:
      baseUrl = process.env.REACT_APP_QCX_PROPOSTAS_BASE_URL!;
      endPoint = process.env.REACT_APP_QCX_PROPOSTAS_CADASTROS_ENDPOINT!;
      break;
    case QueriesTypes.Clientes:
      baseUrl = process.env.REACT_APP_QCX_API_BASE_URL!;
      endPoint = process.env.REACT_APP_QCX_API_CLIENTE_ENDPOINT!;
      break;
    case QueriesTypes.TotvsCorrespondenciasImportador:
      baseUrl = process.env.REACT_APP_TOTVS_API_BASE_URL!;
      endPoint = process.env.REACT_APP_TOTVS_API_CORRESPONDENCIAS_IMPORTADORES_ENDPOINT!;
      break;
    case QueriesTypes.TotvsCorrespondenciasGerais:
      baseUrl = process.env.REACT_APP_TOTVS_API_BASE_URL!;
      endPoint = process.env.REACT_APP_TOTVS_API_CORRESPONDENCIAS_GERAIS_ENDPOINT!;
      break;
    case QueriesTypes.TotvsFaturas:
      baseUrl = process.env.REACT_APP_TOTVS_API_BASE_URL!;
      endPoint = process.env.REACT_APP_TOTVS_API_FATURAS_ENDPOINT!;
      break;
    case QueriesTypes.PropostaFollowUp:
      baseUrl = process.env.REACT_APP_QCX_PROPOSTAS_BASE_URL!;
      endPoint =
        process.env.REACT_APP_QCX_PROPOSTAS_PROPOSTAS_ENDPOINT! +
        '/' +
        process.env.REACT_APP_QCX_PROPOSTAS_FOLLOWUPS_ENDPOINT!;
      break;
    case QueriesTypes.Indices:
      baseUrl = process.env.REACT_APP_QCX_PROPOSTAS_BASE_URL!;
      endPoint = process.env.REACT_APP_QCX_PROPOSTAS_INDICES_ENDPOINT!;
      break;
    case QueriesTypes.CadastroInProposta:
      baseUrl = process.env.REACT_APP_QCX_PROPOSTAS_BASE_URL!;
      endPoint =
        process.env.REACT_APP_QCX_PROPOSTAS_PROPOSTAS_ENDPOINT! +
        '/' +
        process.env.REACT_APP_QCX_PROPOSTAS_CADASTROS_ENDPOINT!;
      break;
    case QueriesTypes.ServicoInProposta:
      baseUrl = process.env.REACT_APP_QCX_PROPOSTAS_BASE_URL!;
      endPoint =
        process.env.REACT_APP_QCX_PROPOSTAS_PROPOSTAS_ENDPOINT! +
        '/' +
        process.env.REACT_APP_QCX_PROPOSTAS_SERVICOS_ENDPOINT!;
      break;
    case QueriesTypes.PropostasAnexos:
      baseUrl = process.env.REACT_APP_QCX_PROPOSTAS_BASE_URL!;
      endPoint =
        process.env.REACT_APP_QCX_PROPOSTAS_PROPOSTAS_ENDPOINT! +
        '/' +
        process.env.REACT_APP_QCX_PROPOSTAS_ANEXOS_ENDPOINT!;
      break;
    case QueriesTypes.PerfilAdicionarPermissao:
      endPoint = 'perfil/adicionar-permissao';
      break;
    default:
      throw new Error('Unimplemented query');
  }

  return { baseUrl, endPoint };
}

export const usePostRequestFormData = (
  type: QueriesTypes,
  options?: { onSettledCallback: (payload?: any) => void }
) => {
  const queryClient = useQueryClient();

  const { keycloak } = useKeycloak();
  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  const { token } = keycloak;
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.append('Authorization', `Bearer ${token}`);
  requestHeaders.append('quickcomexTenant', selectedUnidade.toString());

  const { baseUrl, endPoint } = getUrlForQuery(type);

  return useMutation(
    type,
    async ({ requestFormData }: { requestFormData: FormData }) => {
      let url = `${baseUrl}/${endPoint}`;

      const response = await fetch(url, {
        method: 'POST',
        headers: requestHeaders,
        body: requestFormData,
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return JSON.parse(await response.text());
    },
    {
      onSettled: (data) => {
        queryClient.invalidateQueries(type);
        options?.onSettledCallback(data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

export const usePutRequestFormData = (type: QueriesTypes, options?: { onSettledCallback: (payload?: any) => void }) => {
  const queryClient = useQueryClient();

  const { keycloak } = useKeycloak();
  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  const { token } = keycloak;
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.append('Authorization', `Bearer ${token}`);
  requestHeaders.append('quickcomexTenant', selectedUnidade.toString());

  const { baseUrl, endPoint } = getUrlForQuery(type);

  return useMutation(
    type,
    async ({ requestFormData, queryParams }: { requestFormData: FormData; queryParams?: any }) => {
      let url = `${baseUrl}/${endPoint}`;

      if (!!queryParams && !!queryParams['step']) {
        url += `?step=${queryParams['step']}`;
      }

      const response = await fetch(url, {
        method: 'PUT',
        headers: requestHeaders,
        body: requestFormData,
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return JSON.parse(await response.text());
    },
    {
      onSettled: (data) => {
        queryClient.invalidateQueries(type);
        options?.onSettledCallback(data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

export const usePostRequest = (
  type: QueriesTypes,
  options?: {
    onSettledCallback?: () => void;
    onSuccessCallback?: (value: any) => void;
    defaultErrorMessage?: string;
    defaultSuccessMessage?: string;
    cleanEmptyValues?: boolean;
  }
) => {
  const queryClient = useQueryClient();

  const { keycloak } = useKeycloak();
  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  const { token } = keycloak;
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);

  const dispatch = useDispatch();

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.append('Authorization', `Bearer ${token}`);
  requestHeaders.append('quickcomexTenant', selectedUnidade.toString());
  requestHeaders.append('Content-type', 'application/json; charset=UTF-8');

  const { baseUrl, endPoint } = getUrlForQuery(type);

  return useMutation(
    type,
    async ({
      requestPayload,
      queryParams,
    }: {
      requestPayload: { [key: string]: any };
      queryParams?: { clientId?: string | number; step?: number };
    }) => {
      let url = `${baseUrl}/${endPoint}`;

      if (queryParams) {
        const { clientId, step } = queryParams;
        if (clientId) {
          url += `?clienteId=${clientId}`;
        } else if (step) {
          url += `?step=${step}`;
        }
      }

      let body;

      if (options?.cleanEmptyValues) {
        body = JSON.stringify(requestPayload, (key, value) => {
          if (value === null || value === undefined || value === '') {
            return undefined;
          }
          return value;
        });
      } else {
        body = JSON.stringify(requestPayload);
      }

      const response = await fetch(url, {
        method: 'POST',
        headers: requestHeaders,
        body: body,
      });

      if (!response.ok) {
        const error = await response.json();

        throw new Error(error?.message ?? response.statusText);
      }

      return response.text();
    },
    {
      onSettled: () => {
        console.log('Settled');
        queryClient.invalidateQueries(type);
        if (options?.onSettledCallback) {
          options.onSettledCallback();
        }
      },
      onSuccess: (data) => {
        console.log('Success');
        queryClient.invalidateQueries(type);
        if (options?.onSuccessCallback) {
          options?.onSuccessCallback(data);
        }
        if (options?.defaultSuccessMessage) {
          dispatch(setSuccessFeedback({ message: options?.defaultSuccessMessage }));
        }
      },
      onError: (error: { message: string }) => {
        if (options?.defaultErrorMessage) {
          dispatch(setSuccessFeedback({ message: error?.message ?? options?.defaultErrorMessage }));
        }
        console.log(error);
      },
    }
  );
};

export const useDeleteRequest = (
  type: QueriesTypes,
  options?: {
    onSettledCallback: () => void;
  }
) => {
  const queryClient = useQueryClient();

  const { keycloak } = useKeycloak();

  const dispatch = useDispatch();

  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  const { token } = keycloak;
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.append('Authorization', `Bearer ${token}`);
  requestHeaders.append('quickcomexTenant', selectedUnidade.toString());

  const { baseUrl, endPoint } = getUrlForQuery(type);

  return useMutation(
    type,
    async ({
      id,
      nome,
      clienteId,
      propostaId,
      servicoInPropostaQuery,
      cadastroInPropostaQuery,
    }: {
      id?: number | string;
      nome?: string;
      clienteId?: number;
      propostaId?: number | string;
      servicoInPropostaQuery?: string;
      cadastroInPropostaQuery?: string;
    }) => {
      let url = `${baseUrl}/${endPoint}`;
      if (id !== undefined) {
        url += `/${id}`;
      }

      if (nome) {
        url += `/${nome}`;
      }

      if (clienteId) {
        url += `?clienteId=${clienteId}`;
      }

      if (propostaId) {
        url += `?propostaId=${propostaId}`;
      }

      if (servicoInPropostaQuery) {
        url += servicoInPropostaQuery;
      }

      if (cadastroInPropostaQuery) {
        url += cadastroInPropostaQuery;
      }

      const response = await fetch(url, {
        method: 'DELETE',
        headers: requestHeaders,
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },
    {
      onSettled: () => {
        console.log('Settled');
        queryClient.invalidateQueries(type);
        options?.onSettledCallback();
        dispatch(finishLoading());
      },
      onMutate: () => {
        dispatch(startLoading());
      },
    }
  );
};

export const usePutRequest = (
  type: QueriesTypes,
  options?: {
    onSettledCallback: () => void;
  }
) => {
  const queryClient = useQueryClient();

  const { keycloak } = useKeycloak();
  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  const { token } = keycloak;
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.append('Authorization', `Bearer ${token}`);
  requestHeaders.append('quickcomexTenant', selectedUnidade.toString());
  requestHeaders.append('Content-type', 'application/json; charset=UTF-8');

  const { baseUrl, endPoint } = getUrlForQuery(type);

  return useMutation(
    type,
    async (requestPayload: { [key: string]: any }) => {
      const response = await fetch(`${baseUrl}/${endPoint}`, {
        method: 'PUT',
        headers: requestHeaders,
        body: JSON.stringify(requestPayload),
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },
    {
      onSettled: () => {
        console.log('Settled');
        queryClient.invalidateQueries(type);
        options?.onSettledCallback();
      },
    }
  );
};

export enum ServerlessQueriesTypes {
  CorrelacoesImportadoresTOTVS = 'correlacoesImportadoresTOTVS',
  CorrealacaoImportadorTOTVS = 'correalacaoImportadorTOTVS',
  FaturaTOTVS = 'faturaTOTVS',
}

export const useServerlessGetRequest = (type: ServerlessQueriesTypes, props?: { additionalPath?: string }) => {
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.append('quickcomexTenant', selectedUnidade.toString());

  let endPoint = '';

  switch (type) {
    case ServerlessQueriesTypes.CorrelacoesImportadoresTOTVS:
      endPoint = process.env.REACT_APP_TOTVS_CORRELATIONS_ENDPOINT!;
      break;
    case ServerlessQueriesTypes.CorrealacaoImportadorTOTVS:
      endPoint = process.env.REACT_APP_TOTVS_CORRELATIONS_ENDPOINT!;
      break;
    default:
      break;
  }

  if (props?.additionalPath) {
    endPoint = `${endPoint}/${props?.additionalPath}`;
  }

  return useQuery(
    type,
    async () => {
      try {
        const response = await fetch(endPoint, {
          method: 'GET',
          headers: requestHeaders,
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return error;
      }
    },
    { refetchOnWindowFocus: false }
  );
};
