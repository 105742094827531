import { Grid } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFinalBondManager from '../../../../../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManager.tsx';
import EtapaOcorrenciaAutoComplete from './EtapaOcorrenciaAutoComplete';

function EtapaOcorrenciasBondManager({ name = 'ocorrencias', etapaId, isConsult }) {
  const { t } = useTranslation();

  const ocorrenciaEtapaListProps = useMemo(
    () => ({
      name,
      columns: [
        {
          field: t('com.muralis.qcx.listaFollowUp.ocorrenciaComOminusculo'),
          headerName: t('com.muralis.qcx.listaFollowUp.ocorrencia'),
          headerAlign: 'left',
          align: 'left',
          flex: 140,
          valueGetter: ({ row }) => row?.description || '',
        },
      ],
    }),
    []
  );

  const ocorrenciaEtapaFormProps = useMemo(() => ({
    rootName: 'ignorableFields.ocorrencias.form',
    fields: [{ name: 'ocorrencia', label: t('com.muralis.qcx.listaFollowUp.ocorrencia'), required: true }],
  }));

  const handleAdd = useCallback((currentVinculo, currentVinculosList, handleSuccessAddVinculo) => {
    const updatedVinculos = [...currentVinculosList, currentVinculo.ocorrencia];

    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const handleAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) => currentVinculosList.some((v) => v.id === currentVinculo.ocorrencia.id),
    []
  );

  return (
    <>
      <QCXFinalBondManager
        isParentConsult={isConsult}
        listProps={ocorrenciaEtapaListProps}
        formProps={ocorrenciaEtapaFormProps}
        handleAdd={handleAdd}
        handleAlreadyExists={handleAlreadyExists}
        disableConsult
      >
        <Grid item style={{ paddingBottom: '1rem' }} xs={8}>
          <EtapaOcorrenciaAutoComplete
            etapaId={etapaId}
            disabled={isConsult}
            name="ignorableFields.ocorrencias.form.ocorrencia"
          />
        </Grid>
      </QCXFinalBondManager>
    </>
  );
}

export default EtapaOcorrenciasBondManager;
