import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import {
  Search as PageviewIcon,
  Delete as DeleteIcon,
  Explore as ExploreIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import SimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/SimpleConsultPageTemplate/SimpleConsultPageTemplate';

import {
  activateByIdAsync,
  inactivateByIdAsync,
  fetchPaginateAsync,
  selectTaxasConversaoCambio, 
  selectTaxaConversaoCambioCount, 
  selectStatus,
  resetMode,
  resetModel,
} from '../../../../../features/taxa-conversao-cambio/taxaConversaoCambioSlice'; 

import { ALTERNATIVE_LOADING_STATUS } from '../../../../../features/status';
import { userHasDeleteRole, userHasUpdateRole, userHasCreateRole } from '../../../../../components/perfil/perfil-utils';
import TaxaConversaoCambioAdvancedFilterForm from './components/TaxaConversaoCambioAdvancedFilterForm';
import getISODateOrTodaysISODate from '../catalogo-produtos/utils/getISODateOrTodaysISODate';

export default function TaxaConversaoCambioConsultPage({ authInfo = {} }) { 
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useDispatch();
  
  const taxaConversaoCambios = useSelector(selectTaxasConversaoCambio); 
  
  const rowsCount = useSelector(selectTaxaConversaoCambioCount); 
  
  const status = useSelector(selectStatus);
  const isAlternativeLoading = useMemo(() => status === ALTERNATIVE_LOADING_STATUS, [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchPaginateAsync([
      { name: 'page', value: 1 },
      { name: 'size', value: 10 }
    ]));
  }, [dispatch]);

  useEffect(() => {
    fetchAll();
    dispatch(resetMode());
    dispatch(resetModel());
  }, [fetchAll, dispatch]);

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      flex: 110,
      valueGetter: ({ row }) => row?.code?.substring(0, 3),
    },
    {
      field: 'descricao',
      headerName: t('com.muralis.qcx.descricao'),
      type: 'string',
      flex: 275,
      valueGetter: ({ row }) => row?.description,
    },
    {
      field: 'sigla', 
      headerName: 'Sigla', 
      type: 'string',
      flex: 165,
      valueGetter: ({ row }) => row?.moeda?.sigla,
    },
    {
      field: 'inicio-vigencia', 
      headerName: 'Início Vigência', 
      type: 'string',
      flex: 165,
      valueGetter: ({ row }) => row?.vigencia?.inicio,
    },
    {
      field: 'fim-vigencia', 
      headerName: 'Fim Vigência', 
      type: 'string',
      flex: 165,
      valueGetter: ({ row }) => row?.vigencia?.fim,
    },
    {
      field: 'taxa-conversao', 
      headerName: 'Taxa de conversão', 
      type: 'string',
      flex: 205,
      valueGetter: ({ row }) => row?.taxaConversao,
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'taxa-conversao-cambio') && (
            <IconButton
              key={`stw-active${id}`}
              value={active}
              name={`stw-active-${id}`}
              onClick={(event) => handleActivateInactivate(event, id)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          )}
          {userHasUpdateRole(authInfo, 'taxa-conversao-cambio') && (
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
          )}
        </>
      ),
    },
  ];

  const handleActivateInactivate = useCallback((event, id) => {
    event.preventDefault();

    const selectedTaxaConversaoCambio = taxaConversaoCambios.find((taxa) => taxa.id === id); 
    const isActivation = !selectedTaxaConversaoCambio.active;

    if (isActivation) {
      dispatch(activateByIdAsync(id)).then(() => {
        fetchAll(); 
      });
    } else {
      dispatch(inactivateByIdAsync(id)).then(() => {
        fetchAll(); 
      });
    }
  }, [dispatch, taxaConversaoCambios, fetchAll]); 

  const handleClickOnEditButton = useCallback(
    (event, id) => {
      history.push(t('com.muralis.qcx.url.moduloOperacionaisTaxaConversaoCambioDetalhes', { id })); 
    },
    [history, t]
  );

  const handleConfigChange = useCallback(
    ({ currentPage, pageSize, filterInputValue, advancedFilter }) => {
      const startDate = getISODateOrTodaysISODate(advancedFilter?.insertionDateRangeStart);
      const endDate = getISODateOrTodaysISODate(advancedFilter?.insertionDateRangeEnd);
      const dateRange =
        advancedFilter?.insertionDateRangeStart || advancedFilter?.insertionDateRangeEnd
          ? `${startDate};${endDate}`
          : undefined;

      dispatch(
        fetchPaginateAsync([
          { name: 'page', value: currentPage },
          { name: 'size', value: pageSize },
          { name: 'descricao', value: filterInputValue },
          { name: 'dateRange', value: dateRange },
        ])
      );
    },
    [dispatch]
  );

  let controlProps = {};
  if (userHasCreateRole(authInfo, 'taxa-conversao-cambio')) {
    controlProps={
      buttonNew: { description: 'Novo Taxa de Conversão de Câmbio', path: t('com.muralis.qcx.url.moduloOperacionaisTaxaConversaoCambioNovo') },// Changed 'Novo Estado' to 'Novo Taxa de Conversão de Câmbio'
    };}

  return (
    <SimpleConsultPageTemplate
      pageProps={{
        title: t('com.muralis.qcx.taxaConversaoCambio'), 
        icon: <ExploreIcon />,
        breadcrumbs: [
          { link: { to: '/', name: t('com.muralis.qcx.inicio') } },
          { text: { name: t('com.muralis.qcx.cadastros') } },
          { link: { to: t('com.muralis.qcx.url.moduloOperacionais'), name: t('com.muralis.qcx.operacionais') } },
          { text: { name: t('com.muralis.qcx.taxaConversaoCambio') } }, 
        ],
      }}
      controlProps={controlProps}
      tableProps={{ columns, rowsCount, onConfigChangeCallback: handleConfigChange }}
      tableData={taxaConversaoCambios} 
      isLoading={isAlternativeLoading}
      filterPropGetter={(taxa) => taxa.descricao} 
      buttonNewText={t('com.muralis.qcx.acoes.novo').toUpperCase()}
      authInfo={authInfo}
      advancedFilterFormChildren={TaxaConversaoCambioAdvancedFilterForm}
      advancedFilterKeyAccessors={['insertionDateRangeStart', 'insertionDateRangeEnd']}
      requiredRoles={['taxa-conversao-cambio']}
    />
  );
}