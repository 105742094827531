import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
} from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { PrimitiveAtom, useAtom } from "jotai";
import {
  ChangeEvent,
  FormEvent,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { selectClientes } from "../../../../../../features/cliente/clienteSlice";
import { selectPortosAeroportos } from "../../../../../../features/porto-aeroporto/PortoAeroportoSlice";
import DataTable from "../../../../../common/components/dataTable";
import { ClientData } from "../../../../../common/types/clientData";
import { PortoAeroporto } from "../../../../../common/types/portoAeroporto";
import {
  PropostaData,
  TIPO_PESSOA,
  propostaDataAtom,
  propostasActivePage,
  propostasNewPageBulletProps,
} from "../propostasNewPage";

import DeleteIcon from "@mui/icons-material/Delete";
import FormHeader from "../../../../../common/components/forms/formHeader";
import FormHeaderMedium from "../../../../../common/components/forms/formHeaderMedium";
import MultiSelectStyled from "../../../../../common/components/input/multiSelectStyled";
import SelectStyled from "../../../../../common/components/input/selectStyled";
import SwitchStyled from "../../../../../common/components/input/switchStyled";
import TextFieldStyled from "../../../../../common/components/input/textFieldStyled";
import { changeBulletProps } from "../../../../../common/layouts/paginationLayout/helpers/paginationLayoutHelpers";
import { PaginationBulletProps } from "../../../../../common/layouts/paginationLayout/paginationLayout";
import { brlFormatter } from "../../../../../common/utils/formatters/currencyFormatters";
import {
  useListAllCadastroProposta,
  useListAllServicoProposta,
  useListPropostaMercadoria,
} from "../../common/hooks";
import { MercadoriaProposta } from "../../common/mercadoriaProposta.type";
import {
  ServicoCover,
  ServicoProposta,
} from "../../common/propostaServicos.type";
import {
  CadastroCover,
  CadastroProposta,
} from "../../common/propostasCadastros.type";
import { useListCadastroPropostaById } from "../../propostasCadastrosPage/propostasCadastros.hooks";
import { useListServicoPropostaById } from "../../propostasServicosPage/propostasServicos.hooks";
import CPFTextField from "../../../../../common/components/input/cpfTextField";
import CNPJTextField from "../../../../../common/components/input/cnpjTextField";
import { GridValidRowModel } from "@mui/x-data-grid";

export type ModalProposta = {
  id: number;
  name: string;
};

const FirstPage = () => {
  const [propostaData, setPropostaData] =
    useAtom<PrimitiveAtom<PropostaData>>(propostaDataAtom);
  const [activePage, setActivePage] =
    useAtom<PrimitiveAtom<number>>(propostasActivePage);
  const [bulletProps, setBulletProps] = useAtom<
    PrimitiveAtom<PaginationBulletProps[]>
  >(propostasNewPageBulletProps);

  // Cliente ---------------------------------------------------

  const [isNewCliente, setIsNewCliente] = useState(false);

  const clientes: ClientData[] = useSelector(selectClientes);

  const handleClienteChange = (
    event: SyntheticEvent<Element, Event>,
    value: {
      id: string | number | undefined;
      value: string | undefined;
    } | null
  ) => {
    const selectedClient = clientes.find(
      (clientes) => clientes.id === value?.id
    );
    const clientWithNoHistory = { ...selectedClient, historicoCliente: [] };
    setPropostaData((prev) => ({
      ...prev,
      cliente: clientWithNoHistory,
      novoClienteNome: undefined,
      novoClienteCPF: undefined,
      novoClienteTipo: undefined,
      servico: null,
    }));
  };

  const [tipo, setTipo] = useState<TIPO_PESSOA | undefined>();

  const handleNewTipoPessoaChange = (
    event: SyntheticEvent<Element, Event>,
    value: {
      id: string | number | undefined;
      value: string | undefined;
    } | null
  ) => {
    const tipoPessoa = value?.value as TIPO_PESSOA;

    setTipo(tipoPessoa);
  };

  useEffect(() => {
    setPropostaData((prev) => ({
      ...prev,
      cliente: undefined,
      servico: null,
      novoClienteTipo: tipo,
    }));
  }, [tipo]);

  const handleNewClienteChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;

    setPropostaData((prev) => ({
      ...prev,
      cliente: undefined,
      servico: null,
      novoClienteNome: name,
    }));
  };

  function handleNewClienteCPFChange(values: {
    maskedValue: string;
    unmaskedValue: string;
  }) {
    const value = values.unmaskedValue;

    setPropostaData((prev) => ({
      ...prev,
      cliente: undefined,
      servico: null,
      novoClienteCPF: value,
    }));
  }

  function handleNewClienteCNPJChange(values: {
    maskedValue: string;
    unmaskedValue: string;
  }) {
    const value = values.unmaskedValue;

    setPropostaData((prev) => ({
      ...prev,
      cliente: undefined,
      servico: null,
      novoClienteCPF: value,
    }));
  }

  // Locais --------------------------------------------------------------------

  const portosAeroportos: PortoAeroporto[] = useSelector(
    selectPortosAeroportos
  );

  const locais = useMemo(() => {
    return portosAeroportos.filter((portoAeroporto) => {
      return (
        portoAeroporto.local === "LOCAL_DE_ORIGEM" ||
        portoAeroporto.local === "LOCAL_DE_DESTINO" ||
        portoAeroporto.local === "LOCAL_DE_ENTREGA"
      );
    });
  }, [portosAeroportos]);

  const [selectedLocais, setSelectedLocais] = useState<PortoAeroporto[]>([]);

  const handleLocaisChange = (
    event: SyntheticEvent<Element, Event>,
    value:
      | {
          id: string | number | undefined;
          value: string | undefined;
        }[]
      | null
  ) => {
    let newSelectedLocais: PortoAeroporto[] = [];
    value?.forEach((value) => {
      const selectedLocal = locais.find((local) => local.id === value.id);
      selectedLocal && newSelectedLocais.push(selectedLocal);
    });
    setSelectedLocais(newSelectedLocais);
  };

  // Inserir Valores ---------------------------------------------------

  const [cadastros] = useListAllCadastroProposta(propostaData.cliente?.id);
  const [servicos] = useListAllServicoProposta(propostaData.cliente?.id);
  const [mercadorias] = useListPropostaMercadoria();

  const [selectedMercadoria, setselectedMercadoria] =
    useState<MercadoriaProposta>();
  const handleMercadoriaChange = (
    event: SyntheticEvent<Element, Event>,
    value: { id: string | number | undefined; value: string | undefined } | null
  ) => {
    const selectedMercadoria = mercadorias?.find(
      (mercadoria) => mercadoria.id === value?.id
    );
    setselectedMercadoria(selectedMercadoria);
  };

  const modais: ModalProposta[] = [
    {
      id: 1,
      name: "Aéreo",
    },
    {
      id: 2,
      name: "Ferroviário",
    },
    {
      id: 3,
      name: "Marítimo",
    },
    {
      id: 4,
      name: "Rodoviário",
    },
  ];
  const filteredModais = useMemo(() => {
    return modais.filter((modal) =>
      servicos
        .filter((s) => s.mercadoria === selectedMercadoria?.nome)
        .map((s) => s.modal)
        .includes(modal.name)
    );
  }, [selectedMercadoria]);

  const [selectedModal, setselectedModal] = useState<ModalProposta>();
  const handleModalChange = (
    event: SyntheticEvent<Element, Event>,
    value: { id: string | number | undefined; value: string | undefined } | null
  ) => {
    const selectedModal = modais.find((modal) => modal.id === value?.id);
    setselectedModal(selectedModal);
  };

  const filteredServicos = useMemo(() => {
    return servicos.filter((servico) => {
      return (
        servico.mercadoria === selectedMercadoria?.nome &&
        servico.modal === selectedModal?.name
      );
    });
  }, [selectedModal, selectedMercadoria]);
  const [selectedServico, setSelectedServico] = useState<ServicoCover>();
  const handleServicoChange = (
    event: SyntheticEvent<Element, Event>,
    value: { id: string | number | undefined; value: string | undefined } | null
  ) => {
    const selectedServico = servicos.find(
      (servico) => servico.id === value?.id
    );
    setSelectedServico(selectedServico);
  };

  const [servicosValores] = useListServicoPropostaById(
    selectedServico?.id,
    propostaData.cliente?.id === undefined
      ? undefined
      : `clienteId=${propostaData.cliente?.id}`
  );
  const [selectedServicos, setSelectedServicos] = useState<ServicoProposta[]>(
    []
  );

  const [cadastrosToggled, setCadastrosToggled] = useState(
    new Set<CadastroCover>()
  );
  const handleSelectCadastro = (cadastro: CadastroCover) => {
    if (!cadastrosToggled.has(cadastro)) {
      setCadastrosToggled((prev) => {
        const newSet = new Set(prev);
        newSet.add(cadastro);
        return newSet;
      });
    } else {
      setCadastrosToggled((prev) => {
        const newSet = new Set(prev);
        newSet.delete(cadastro);
        return newSet;
      });
    }
    setSelectedCadastro(cadastro);
  };
  const [selectedCadastro, setSelectedCadastro] = useState<CadastroCover>();
  const [selectedCadastros, setSelectedCadastros] = useState<
    CadastroProposta[]
  >([]);

  const [cadastrosValores] = useListCadastroPropostaById(
    selectedCadastro?.id,
    propostaData.cliente?.id === undefined
      ? undefined
      : `clienteId=${propostaData.cliente?.id}`
  );

  useEffect(() => {
    if (cadastrosToggled.has(selectedCadastro!)) {
      setSelectedCadastros((prev) => [...prev, ...cadastrosValores]);
    } else {
      setSelectedCadastros((prev) =>
        prev.filter((value) => !cadastrosValores.includes(value))
      );
    }

    return () => {};
  }, [cadastrosValores, cadastrosToggled]);

  const handleValorAddition = () => {
    if (!selectedServico) {
      return;
    }
    const toAdd = [...servicosValores.filter(
      (s) => !selectedServicos.some(ss => ss.id === s.id && ss.nomeValor === s.nomeValor)
    )];

    const filtred = toAdd.filter((s) => !s.cobrancaUnica || !selectedServicos.some((ss) => ss.nome === s.nome && ss.nomeValor === s.nomeValor));

    setSelectedServicos([
      ...selectedServicos,
      ...filtred.map((s) => ({...s, locaisEmbarque: selectedLocais})),
    ]);
  };

  const handleValorDeletion = ({
    id,
    nomeValor,
    isServico,
    isCadastro,
  }: {
    id: number | string;
    isServico: boolean;
    isCadastro: boolean;
    nomeValor: string;
  }) => {
    console.log({ id, nomeValor, isServico, isCadastro });

    if ((!isCadastro && !isServico) || (isCadastro && isServico)) {
      return;
    }

    if (isCadastro) {
      const toUpdate = selectedCadastros.filter(
        (c) => c.id !== id || c.nomeValor !== nomeValor
      );

      setSelectedCadastros([...toUpdate]);
    }

    if (isServico) {
      const toUpdate = selectedServicos.filter(
        (s) => s.id !== id || s.nomeValor !== nomeValor
      );

      setSelectedServicos([...toUpdate]);
    }
  };

  useEffect(() => {
    setPropostaData({
      ...propostaData,
      serviços: [...selectedServicos],
      cadastros: [...selectedCadastros],
    });
  }, [selectedServicos, selectedCadastros]);

  // Valores ---------------------------------------------------

  function handleEditValor({ row, value }: any) {
    const { isCadastro, isServico } = row;

    if (isServico) {
      const servicoToEdit = selectedServicos.find(
        (servico) => servico.id + servico.nomeValor + "s" === row.id
      )!;

      servicoToEdit.valor = value;

      setSelectedServicos([...selectedServicos]);
    }

    if (isCadastro) {
      const cadastroToEdit = selectedCadastros.find(
        (cadastro) => cadastro.id + cadastro.nomeValor + "c" === row.id
      )!;

      cadastroToEdit.valor = value;

      setSelectedCadastros([...selectedCadastros]);
    }

    return row;
  }

  const modelosLancamentosColumns: GridColDef[] = [
    {
      field: "mercadoria",
      headerName: "Mercadoria",
      flex: 1,
      headerClassName: "header-theme",
    },
    {
      field: "modal",
      headerName: "Modal",
      flex: 1,
      headerClassName: "header-theme",
    },
    {
      field: "serviço",
      headerName: "Serviço",
      flex: 1,
      headerClassName: "header-theme",
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 2,
      headerClassName: "header-theme",
    },
    {
      field: "locaisEmbarque",
      headerName: "Locais de Embarque",
      flex: 2,
      headerClassName: "header-theme",
      valueFormatter: (params: GridValueFormatterParams<PortoAeroporto[]>) => {
        return params.value.map((local) => local.description).join(" - ");
      },
    },
    {
      field: "valor",
      headerName: "Valor",
      flex: 1,
      headerClassName: "header-theme",
      type: "number",
      align: "right",
      headerAlign: "right",
      valueFormatter: (params: GridValueFormatterParams<any>) => {
        return brlFormatter.format(params.value);
      },
      editable: true,
      valueSetter: handleEditValor,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      headerClassName: "header-theme",
      // @ts-ignore
      getActions: (params: any) => [
        <GridActionsCellItem
placeholder=""
          icon={<DeleteIcon color="error" />}
          label="Deletar"
          onClick={() =>
            handleValorDeletion({
              ...params.row,
              id: params.row.groupId,
              nomeValor: params.row.nome,
            })
          }
        />,
      ],
    },
  ];
  const rows: readonly GridValidRowModel[] = useMemo(() => {
    let rows = selectedServicos.map((valor) => ({
      id: valor.id + valor.nomeValor + "s",
      groupId: valor.id,
      isServico: true,
      isCadastro: false,
      mercadoria: valor.mercadoria,
      modal: valor.modal,
      serviço: valor.nome,
      nome: valor.nomeValor,
      valor: valor.valor,
      locaisEmbarque: valor.locaisEmbarque,
    }));

    rows = rows.concat(
      selectedCadastros.map((valor) => ({
        id: valor.id + valor.nomeValor + "c",
        groupId: valor.id,
        isServico: false,
        isCadastro: true,
        mercadoria: "N/A",
        modal: "N/A",
        serviço: valor.nome,
        nome: valor.nomeValor,
        valor: valor.valor,
        locaisEmbarque: [],
      }))
    );

    return rows;
  }, [selectedServicos, selectedCadastros]);

  // Navigation ----------------------------------------------

  const handleNext = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    changeBulletProps(2, setBulletProps);
    setActivePage(2);
  };

  const totalValue = useMemo(() => {
    let total = 0;

    total = selectedServicos.reduce((sum, s) => sum + s.valor, total);
    total = selectedCadastros.reduce((sum, c) => sum + c.valor, total);

    return brlFormatter.format(total);
  }, [selectedServicos, selectedCadastros]);

  const canContinue = useMemo(() => {
    return !!propostaData.novoClienteNome || !!propostaData.cliente;
  }, [propostaData.novoClienteNome, propostaData.cliente]);

  return (
    <form
      onSubmit={handleNext}
      style={{ display: activePage == 1 ? "inherit" : "none" }}
    >
      <Grid container spacing={2} marginTop={2}>
        {isNewCliente && (
          <>
            <Grid item sm={12}>
              <TextFieldStyled
                onChange={handleNewClienteChange}
                label={"Nome do Cliente"}
              ></TextFieldStyled>
            </Grid>
            <Grid item sm={6}>
              <SelectStyled
                options={[
                  { id: 0, value: TIPO_PESSOA.FISICA },
                  { id: 1, value: TIPO_PESSOA.JURIDICA },
                ]}
                label={"Tipo de Pessoa"}
                onChangeAction={handleNewTipoPessoaChange}
              />
            </Grid>
            <Grid item sm={6}>
              {tipo === TIPO_PESSOA.FISICA && (
                <CPFTextField onChange={handleNewClienteCPFChange} />
              )}

              {tipo === TIPO_PESSOA.JURIDICA && (
                <CNPJTextField onChange={handleNewClienteCNPJChange} />
              )}
            </Grid>
          </>
        )}
        {!isNewCliente && (
          <Grid item sm={12}>
            <FormControl fullWidth required>
              <SelectStyled
                options={clientes.map((cliente) => ({
                  id: cliente.id,
                  value: cliente.description,
                }))}
                onChangeAction={handleClienteChange}
                label={"Cliente"}
              ></SelectStyled>
            </FormControl>

            <Grid item marginTop={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isNewCliente}
                    onChange={(e) => setIsNewCliente(!isNewCliente)}
                    color="secondary"
                  />
                }
                label="Cliente não cadastrado"
              />
            </Grid>
          </Grid>
        )}
        <Grid item sm={12}>
          <FormHeader marginTop="0px">Nova Proposta</FormHeader>
        </Grid>
        {cadastros.length > 0 && (
          <>
            <FormHeaderMedium marginTop="0px" marginBottom="0px">
              Inserir Cadastros
            </FormHeaderMedium>
            {cadastros.map((cadastro) => (
              <Grid item sm={12} marginTop="0px">
                <SwitchStyled
                  key={cadastro.id}
                  label={cadastro.nome}
                  onChange={(e) => handleSelectCadastro(cadastro)}
                ></SwitchStyled>
              </Grid>
            ))}
          </>
        )}
        <FormHeaderMedium marginTop="0px" marginBottom="10px">
          Inserir valores de serviços
        </FormHeaderMedium>
        <Grid item sm={2}>
          <FormControl fullWidth required>
            <SelectStyled
              options={
                mercadorias?.map((mercadoria) => ({
                  id: mercadoria.id,
                  value: mercadoria.nome,
                })) ?? []
              }
              onChangeAction={handleMercadoriaChange}
              label={"Mercadoria"}
            ></SelectStyled>
          </FormControl>
        </Grid>
        <Grid item sm={2}>
          <FormControl fullWidth required>
            <SelectStyled
              options={filteredModais.map((modal) => ({
                id: modal.id,
                value: modal.name,
              }))}
              onChangeAction={handleModalChange}
              label={"Modal"}
            ></SelectStyled>
          </FormControl>
        </Grid>
        <Grid item sm={3}>
          <FormControl fullWidth required>
            <SelectStyled
              options={filteredServicos.map((servico) => ({
                id: servico.id,
                value: servico.nome,
              }))}
              onChangeAction={handleServicoChange}
              label={"Serviço"}
            ></SelectStyled>
          </FormControl>
        </Grid>
        <Grid item sm={3}>
          <FormControl fullWidth>
            <MultiSelectStyled
              options={locais.map((local) => ({
                id: local.id,
                value: local.description + " - " + local.sigla,
              }))}
              onChangeAction={handleLocaisChange}
              label={"Locais de Embarque / Desembarque"}
            ></MultiSelectStyled>
          </FormControl>
        </Grid>
        <Grid item sm={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleValorAddition}
            disabled={!selectedServico}
          >
            Inserir
          </Button>
        </Grid>
        <Grid item xs={12}>
          <FormHeader marginTop="0px" marginBottom="10px">
            Valores
          </FormHeader>
          <DataTable
            columns={modelosLancamentosColumns}
            rows={rows}
          ></DataTable>
        </Grid>
        <FormHeader marginTop="0px" textAlign="right">
          {"Valor total: " + totalValue}
        </FormHeader>
        <Grid item xs={12} textAlign="right">
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={!canContinue}
          >
            Próximo
          </Button>
        </Grid>{" "}
      </Grid>
    </form>
  );
};

export default FirstPage;
