import { createAsyncThunk } from '@reduxjs/toolkit';
import { demandaOcrAPI } from './demandaOcrAPI';

const fetchAllAsync = createAsyncThunk('demandaOcr/fetchAllAsync', async () => {
  const { data } = await demandaOcrAPI.fetchAllAsync();

  return { data };
});

const inactivateByIdAsync = createAsyncThunk('demandaOcr/inactivateById', async (id: number) => {
  const { status, data } = await demandaOcrAPI.inactivateById(id);
  return { response: { status, data } };
});

const demandaOcrThunks = {
  fetchAllAsync,
  inactivateByIdAsync,
};

export { demandaOcrThunks, fetchAllAsync, inactivateByIdAsync };
