import { validateQueryParams } from '../../../../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../../../../utils/api/domain/domain-api-utils';
import { DADOS_EMBARQUE_ENDPOINT, FILTER_ENDPOINT } from '../../../../../utils/api/domain/domain-endpoint-utils';

export async function fetchDadosEmbarqueExportacaoByFilter(params = []) {
  const validatedParams = validateQueryParams(params);

  if (validatedParams.length === 0) {
    throw new Error('Parâmetros inválidos');
  }

  validatedParams.push({ name: 'servicoProduto', value: 'EXPORTACAO' });

  return doGetQCXRequest([DADOS_EMBARQUE_ENDPOINT, FILTER_ENDPOINT, 'pagination'], validatedParams);
}

export async function fetchAll() {
  return doGetQCXRequest([DADOS_EMBARQUE_ENDPOINT]);
}
export async function fetchByFilter(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([DADOS_EMBARQUE_ENDPOINT, FILTER_ENDPOINT], validatedParams);
  }
  return { data: [] };
}

export async function fetchById(id) {
  return doGetQCXRequest([DADOS_EMBARQUE_ENDPOINT, id]);
}

export async function register(data) {
  return doPostQCXRequest([DADOS_EMBARQUE_ENDPOINT], data);
}

export async function save(data) {
  return doPutQCXRequest([DADOS_EMBARQUE_ENDPOINT], data);
}

export async function activateById(id) {
  return doPutQCXActivateRequest([DADOS_EMBARQUE_ENDPOINT], id);
}

export async function inactivateById(id) {
  return doPutQCXInactivateRequest([DADOS_EMBARQUE_ENDPOINT], id);
}
