import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Button, Grid } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUnidadeNegocioGuard } from "../../../../common/hooks/useUnidadeNegocioGuard";
import { ClientIdAndDescriptionDTO } from "../../../../common/types/clientData";
import { FollowUpDto } from "../../../../common/types/followUp";
import { selectUnidadeSelecionada } from "../../../../../features/usuario-logado/usuarioLogadoSlice";
import { KEYCLOAK_TOKEN_TIMEOUT } from "../../../../../App";
import { setErrorFeedback, setSuccessFeedback } from "../../../../../features/feedback/feedbackSlice";
import { downloadFile } from "../triangulus.helpers";
import { fetchClientesWithFilterDto } from "../../../../../features/cliente/clienteAPI";
import { fetchByFilterDto } from "../../../../../features/follow-up/followUpAPI";
import PageLayout from "../../../../common/layouts/pageLayout";
import LoadingIndicator from "../../../../common/components/loadingIndicator";
import FormHeaderMedium from "../../../../common/components/forms/formHeaderMedium";
import SelectStyled from "../../../../common/components/input/selectStyled";
import ErrorBadge from "../../../../common/components/forms/errorBadge";

const TriangulusDanfePage = () => {
  useUnidadeNegocioGuard();
  const dispatch = useDispatch();
  const [error, setError] = useState<string | string[] | undefined>();
  const [cliente, setCliente] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [clientes, setClientes] = useState<ClientIdAndDescriptionDTO[] | undefined>(undefined);
  const [followUpsData, setFollowUpsData] = useState<FollowUpDto[] | undefined>(undefined);


  const [processo, setProcesso] = useState<string | undefined>(undefined);
  const { keycloak } = useKeycloak();
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);
  const { token } = keycloak;

  const url = process.env.REACT_APP_QCX_API_BASE_URL! + "/"
    + process.env.REACT_APP_TRIANGULUS_API_DANFE_ENDPOINT!


  useEffect(() => {
    if (!cliente) {
      setError(undefined);
      return;
    }

    return undefined;
  }, [cliente]);

  const processos = useMemo(() => {
    if (!followUpsData || typeof followUpsData != "object") {
      return [];
    }

    const processosFilteredByClient = followUpsData.filter(
      (followUp: FollowUpDto) => followUp.importador.id === cliente
    );

    const processos: string[] = processosFilteredByClient.map(
      (followUp: FollowUpDto) => followUp.numero
    );

    return processos.filter((processo, index) => {
      return processos.indexOf(processo) === index;
    });
  }, [followUpsData, cliente]);

  const clearData = () => {
    setCliente(undefined);
    setProcesso(undefined);
    setIsLoading(false);
  };

  const handleClienteChange = (
    event: SyntheticEvent<Element, Event>,
    value: {
      id: string | number | undefined;
      value: string | undefined;
    } | null
  ) => {
    if (clientes) {
      const selectedClient = clientes.find(
        (cliente) => cliente.id === value?.id
      );

      if (selectedClient) {
        setCliente(selectedClient.id);
        setProcesso(undefined);
      }
    }
  };

  const handleSubmit = async () => {
    if (processo && token && selectedUnidade) {
      keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
      setIsLoading(true)

      const message = await downloadFile(token, selectedUnidade.toString(), `${url}/xml?processo=${processo}`, "danfe-triangulus-" + processo);

      if (message.statusCode === 500) {
        dispatch(setErrorFeedback({
          message: message.message
        }));

      } else {

        dispatch(setSuccessFeedback({
          message: message.message
        }));

      }

      clearData();
    }
  };

  const getClientsAndFollowUps = async () => {
    try {
      const { data } = await fetchClientesWithFilterDto([{ name: 'filterDadosMinimos', value: 'TRUE' }]);
      setClientes(data);

    } catch (error) {
      dispatch(setErrorFeedback({
        message: 'Erro ao obter clientes:'
      }));
    }

    try {
      const { data } = await fetchByFilterDto([{ name: 'filterDadosMinimos', value: 'TRUE' }]);
      setFollowUpsData(data);

    } catch (error) {
      dispatch(setErrorFeedback({
        message: 'Erro ao obter FollowUps:'
      }));
    }

  };

  useEffect(() => {
    getClientsAndFollowUps();
  }, []);

  return (
    <PageLayout
      title={"Triangulus - Danfe"}
      icon={<InsertDriveFileIcon color={"secondary"} />}
    >
      {isLoading ? (
        <LoadingIndicator
          message={isLoading ? "Gerando Zip" : "Carregando Dados..."}
        />
      ) : (
        <Grid container spacing={2}>
          <FormHeaderMedium>
            Selecione as informações para gerar o arquivo
          </FormHeaderMedium>
          <Grid item sm={6}>
            <SelectStyled
              options={(clientes || []).map((cliente, index) => ({
                id: cliente.id,
                value: cliente.description + " - " + cliente.id,
              }))}
              onChangeAction={handleClienteChange}
              label={"Cliente"}
            />

          </Grid>
          <Grid item sm={6}>
            <SelectStyled
              options={processos.map((processo, index) => ({
                id: index,
                value: processo,
              }))}
              label={"Processo"}
              onChangeAction={(event, newValue) => {
                setProcesso(newValue?.value);
              }}
            />
          </Grid>
          <ErrorBadge error={error} />
          <Grid item sm={12} textAlign={"right"}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={!cliente || !processo || isLoading || !!error}
            >
              Gerar
            </Button>
          </Grid>
        </Grid>
      )}
    </PageLayout>
  );
};

export default TriangulusDanfePage;