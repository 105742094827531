import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { saveAtributos } from '../../../../../../../features/catalogo-produtos/catalogoProdutosAPI';
import { setSuccessFeedback } from '../../../../../../../features/feedback/feedbackSlice';
import useOperationMiddleware from '../../../../../../../utils/hooks/operation/middleware/useOperationMiddleware';
import { CatalogoProdutosAtributosFormValues } from '../types/CatalogoProdutosAtributosFormValues';
import atributosNormalization from '../utils/atributosNormalization';

export default function useReplicateAtributos(successCallback: () => void) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [handleReplicateOperation, replicateOperation] = useOperationMiddleware(
    async (values: CatalogoProdutosAtributosFormValues) => {
      const normalized = atributosNormalization.normalizeAtributos(values);
      const response = await saveAtributos(normalized);

      if (response.status === 204) {
        dispatch(
          setSuccessFeedback({
            message: 'Dados repassados com sucesso!',
          })
        );
        successCallback();
      }
    },
    []
  );

  const handleReplicateOnSubmitMiddleware = useCallback(
    (data: any) => {
      const configureOperation = () => ({
        options: {
          title: t('com.muralis.qcx.confirmarOperacaoRepasseDados').toUpperCase(),
          message: t('com.muralis.qcx.mensagem.confirmarOperacaoDadosPreenchidosPartnumbersSelecionadas'),
          endMessage: t('com.muralis.qcx.certezaDesejaRepassar'),
        },
      });

      /* @ts-expect-error handleReplicateOperation signature */
      handleReplicateOperation(configureOperation, data);
    },
    [handleReplicateOperation]
  );

  return {
    handleReplicateSubmit: handleReplicateOnSubmitMiddleware,
    replicateOperation,
  };
}
