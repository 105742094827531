import i18n from '../../i18n';

const ModuloPerfilUtils = {
  CADASTROS: 'CADASTROS',
  IMPORTACAO: 'IMPORTACAO',
  EXPORTACAO: 'EXPORTACAO',
  FINANCEIRO: 'FINANCEIRO',
  INTEGRACAO: 'INTEGRACAO',
  RELATORIOS: 'RELATORIOS',
  COMERCIAL: 'COMERCIAL',
};

const MODULOS_DE_ACESSO = [
  ModuloPerfilUtils.CADASTROS,
  ModuloPerfilUtils.IMPORTACAO,
  ModuloPerfilUtils.EXPORTACAO,
  ModuloPerfilUtils.FINANCEIRO,
  ModuloPerfilUtils.INTEGRACAO,
  ModuloPerfilUtils.RELATORIOS,
  ModuloPerfilUtils.COMERCIAL,
];

const ACESSOS_CADASTROS_GERAIS = [
  { label: i18n.t('com.muralis.qcx.etapa.label'), name: 'etapa' },
  { label: i18n.t('com.muralis.qcx.desvio.label'), name: 'desvio' },
  { label: i18n.t('com.muralis.qcx.campoAdicional.label'), name: 'camposadicionais' },
  { label: i18n.t('com.muralis.qcx.ocorrencia.label'), name: 'ocorrencia' },
  { label: i18n.t('com.muralis.qcx.faturamento.modeloFaturamento'), name: 'modelo-faturamento' },
  { label: i18n.t('com.muralis.qcx.modeloFollowUp.label'), name: 'modelo-follow-up' },
  { label: i18n.t('com.muralis.qcx.numerario.modeloNumerario'), name: 'modelo-numerario' },
  { label: i18n.t('com.muralis.qcx.perfis.labelSingular'), name: 'perfil' },
  { label: i18n.t('com.muralis.qcx.servico.labelSingular'), name: 'servico' },
  { label: i18n.t('com.muralis.qcx.tabelaSDA.label'), name: 'tabela-sda' },
  { label: i18n.t('com.muralis.qcx.documento.tipoDocumento'), name: 'tipo-documento' },
  { label: i18n.t('com.muralis.qcx.unidadeNegocio.label'), name: 'unidade-de-negocio' },
  { label: i18n.t('com.muralis.qcx.usuario.labelSingular'), name: 'usuario' },
];

const ACESSOS_CADASTROS_OPERACIONAIS = [
  { label: i18n.t('com.muralis.qcx.armazem.labelSingular'), name: 'armazem' },
  { label: i18n.t('com.muralis.qcx.catalogoProdutoMercadoria'), name: 'catalogo-produtos' },
  { label: i18n.t('com.muralis.qcx.CFOP.label'), name: 'operacao-fiscal' },
  { label: i18n.t('com.muralis.qcx.endereco.cidade'), name: 'cidade' },
  { label: i18n.t('com.muralis.qcx.cliente.label'), name: 'cliente' },
  { label: i18n.t('com.muralis.qcx.empresa.CNAE'), name: 'cnae' },
  { label: i18n.t('com.muralis.qcx.despachante.label'), name: 'despachante' },
  { label: i18n.t('com.muralis.qcx.dispositivoLegal.label'), name: 'dispositivo-legal' },
  { label: i18n.t('com.muralis.qcx.embarcacao.label'), name: 'embarcacao' },
  { label: i18n.t('com.muralis.qcx.endereco.estado'), name: 'estado' },
  { label: i18n.t('com.muralis.qcx.exportadorFabricante'), name: 'exportador-fabricante' },
  { label: i18n.t('com.muralis.qcx.modeloInformacaoComplementar.label'), name: 'modelo-informacoes-complementares' },
  { label: i18n.t('com.muralis.qcx.NCM.label'), name: 'ncm' },
  { label: i18n.t('com.muralis.qcx.endereco.pais'), name: 'pais' },
  { label: i18n.t('com.muralis.qcx.portoAeroporto.label'), name: 'porto-aeroporto' },
  { label: i18n.t('com.muralis.qcx.tabelaArmazenagem.label'), name: 'tabela-armazenagem' },
  // { label: 'Tabela Transporte', name: 'tabela-transporte' },
  { label: i18n.t('com.muralis.qcx.tipoCalculo'), name: 'tipo-calculo' },
  { label: i18n.t('com.muralis.qcx.tipoContainer'), name: 'tipo-container' },
  { label: i18n.t('com.muralis.qcx.transportadorInternacional.label'), name: 'transportador-internacional' },
  { label: i18n.t('com.muralis.qcx.transportadora.label'), name: 'transportadora' },
  { label: i18n.t('com.muralis.qcx.unidadeMedida.label'), name: 'unidade-de-medida' },
  { label: i18n.t('com.muralis.qcx.modeloDANFE.label'), name: 'modelo-danfe' },
  { label: i18n.t('com.muralis.qcx.estado.labelPlural'), name: 'estado-cadastro' },
  { label: i18n.t('com.muralis.qcx.endereco.cidade'), name: 'cidade-cadastro' },
  // { label: 'Transportadora'},
];

const ACESSOS_CADASTROS_FINANCEIROS = [
  { label: i18n.t('com.muralis.qcx.banco.label'), name: 'banco' },
  { label: i18n.t('com.muralis.qcx.contaBancaria.label'), name: 'conta-bancaria' },
  { label: i18n.t('com.muralis.qcx.cotacaoMoeda.label'), name: 'cotacao' },
  { label: i18n.t('com.muralis.qcx.despesa.despesasReceitas'), name: 'despesa-receita' },
  { label: i18n.t('com.muralis.qcx.moeda.label'), name: 'moeda' },
  { label: i18n.t('com.muralis.qcx.planoConta.label'), name: 'plano-de-conta' },
  { label: i18n.t('com.muralis.qcx.fornecedor.label'), name: 'fornecedor-favorecido' },
  // { label: 'Centro-custo' },
];

const ACESSOS_IMPORTACAO = [
  { label: i18n.t('com.muralis.qcx.fatura.labelSingular'), name: 'importacao-fatura' },
  { label: i18n.t('com.muralis.qcx.LILPCO'), name: 'importacao-li-lpco' },
  { label: 'Transmissão LI Registro', name: 'gera-xml-li-registro' },
  { label: 'Transmissão LI Substitutiva', name: 'gera-xml-substitutiva-li' },
  { label: i18n.t('com.muralis.qcx.DIDUIMP.label'), name: 'importacao-di-duimp' },
  { label: 'Gerar Xml DI Registro', name: 'gera-xml-di-registro' },
  { label: 'Gerar Xml Diagnostico', name: 'gera-xml-di-diagnostico' },
  { label: 'Transmissão DI Registro', name: 'transmissao-di-registro' },
  { label: 'Transmissão DI Diagnostico', name: 'transmissao-di-diagnostico' },
  { label: 'Tela de Pagamentos DI', name: 'pagamento-di' },
  { label: 'DI após Registro ', name: 'di-pos-registro' },
  { label: i18n.t('com.muralis.qcx.followup.label'), name: 'importacao-follow-up' },
  { label: i18n.t('com.muralis.qcx.DANFE.label'), name: 'danfe' },
  { label: 'Dados de Embarque', name: 'importacao-dados-embarque' },
  { label: i18n.t('com.muralis.qcx.numerarioImportacao.label'), name: 'importacao-solicitacao-numerario' },
  { label: i18n.t('com.muralis.qcx.custeios.labelPlural'), name: 'importacao-custeio' },
  { label: 'Cancelar DANFE', name: 'cancelar-danfe' },
];

const ACESSSOS_ASSESSORIA = [{ label: 'Follow-Up', name: 'assessoria-follow-up' }];

const ACESSOS_EXPORTACAO = [
  { label: i18n.t('com.muralis.qcx.followUpExportacao.label'), name: 'exportacao-follow-up' },
  { label: 'Dados de Embarque', name: 'exportacao-dados-embarque' },
  { label: i18n.t('com.muralis.qcx.dueExportacao.label'), name: 'exportacao-due' },
];

const ACESSOS_FINANCEIRO = [
  {
    label: i18n.t('com.muralis.qcx.solicitacaoPagamento.labelAdministrativo'),
    name: 'exportacao-solicitacao-pagamento-administrativo',
  },
  {
    label: i18n.t('com.muralis.qcx.solicitacaoPagamento.labelOperacional'),
    name: 'exportacao-solicitacao-pagamento-operacional',
  },
  { label: i18n.t('com.muralis.qcx.solicitacaoPagamento.label'), name: 'solicitacao-pagamento' },
  { label: i18n.t('com.muralis.qcx.financeiro.aprovarRecusarPagamento'), name: 'aprovar-recusar-pagamento' },
  { label: i18n.t('com.muralis.qcx.financeiro.contasPagar'), name: 'conta-pagar' },
  { label: i18n.t('com.muralis.qcx.financeiro.contasReceber'), name: 'conta-receber' },
  { label: i18n.t('com.muralis.qcx.financeiro.conciliacaoBancaria'), name: 'conciliacao-bancaria' },
  { label: i18n.t('com.muralis.qcx.financeiro.faturamento'), name: 'faturamento' },
  { label: i18n.t('com.muralis.qcx.financeiro.relatorios'), name: 'relatorios-financeiros' },
];

const ACESSOS_INTEGRACAO = [
  { label: 'Totvs', name: 'totvs' },
  { label: 'Croda', name: 'croda' },
  { label: 'Triangulus', name: 'triangulus' },
  { label: i18n.t('com.muralis.qcx.integracao.planilhas'), name: 'planilhas' },
  { label: 'Roche', name: 'roche' },
  { label: 'Thermo-Fisher', name: 'thermo-fisher' },
];

const ACESSOS_COMERCIAL = [{ label: i18n.t('com.muralis.qcx.comercial.propostas'), name: 'propostas' }];

const ACESSOS_RELATORIOS = [
  { label: i18n.t('com.muralis.qcx.relatorios.halliburton'), name: 'relatorio-halliburton' },
  { label: i18n.t('com.muralis.qcx.relatorios.draft-danfe-excel'), name: 'relatorio-draft-danfe-excel' },
  { label: i18n.t('com.muralis.qcx.relatorios.planilha-la'), name: 'relatorio-planilha-la' },
  { label: i18n.t('com.muralis.qcx.relatorios.associated'), name: 'relatorio-associated' },
  { label: i18n.t('com.muralis.qcx.relatorios.planilha-transportadoras'), name: 'relatorio-planilha-transportadoras' },
  { label: i18n.t('com.muralis.qcx.relatorios.red-bull'), name: 'red-bull' },
  { label: i18n.t('com.muralis.qcx.relatorios.gerencial'), name: 'relatorio-gerencial' },
  { label: i18n.t('com.muralis.qcx.relatorios.processos'), name: 'relatorio-processos' },
  { label: i18n.t('com.muralis.qcx.relatorios.dashboard'), name: 'relatorio-dashboard' },
  { label: 'Relatório Draft Danfe Faxe', name: 'relatorio-draft-danfe-faxe' },
  { label: 'Relatório NF Emitidas', name: 'relatorio-nfemitidas' },
  { label: 'Relatório Livro Contas', name: 'relatorio-livrocontas' },
  { label: 'Relatório Devolução Saldo', name: 'relatorio-devolucao-saldo' },
  { label: 'Relatório Processos Faturados', name: 'relatorio-processos-faturados' },
  { label: 'Relatório Processos Andamento', name: 'relatorio-processos-andamento' },
  { label: i18n.t('com.muralis.qcx.relatorios.movimentacao'), name: 'movimentacao' },
  { label: 'Relatório Conta Corrente', name: 'relatorio-conta-corrente' },
  { label: 'Relatório Lançamentos', name: 'relatorio-lancamentos' },
];

const ACESSOS_TABELA_ADUANEIRA = [
  { label: i18n.t('com.muralis.qcx.aladi.acordoALADI'), name: 'acordo-aladi' },
  { label: i18n.t('com.muralis.qcx.acrescimoValoracaoAduaneira'), name: 'acrescimo-valoracao-aduaneira' },
  { label: i18n.t('com.muralis.qcx.codigoReceita'), name: 'codigo-receita' },
  {
    label: i18n.t('com.muralis.qcx.documento.documentoInstrucaoDespachoImportacao'),
    name: 'documento-instrucao-despacho-importacao',
  },
  {
    label: i18n.t('com.muralis.qcx.legislacao.fundamentoLegalReducaoPISCOFINS'),
    name: 'fundamento-legal-reducao-pis-cofins',
  },
  {
    label: i18n.t('com.muralis.qcx.legislacao.fundamentoLegalRegimeTributacaoLI'),
    name: 'fundamento-legal-regime-tributacao-ii',
  },
  {
    label: i18n.t('com.muralis.qcx.legislacao.fundamentoLegalRegimeTributacaoPISCOFINS'),
    name: 'fundamento-legal-regime-tributacao-pis-cofins',
  },
  { label: i18n.t('com.muralis.qcx.incontermCondicaoVenda'), name: 'incoterm-condicao-venda' },
  { label: i18n.t('com.muralis.qcx.instituicaoFinanciadora'), name: 'instituicao-financiadora' },
  { label: i18n.t('com.muralis.qcx.metodoValoracaoAduaneira'), name: 'metodo-valoracao-aduaneira' },
  { label: i18n.t('com.muralis.qcx.modalidadePagamento'), name: 'modalidade-pagamento' },
  { label: i18n.t('com.muralis.qcx.motivoAdmissaoTemporaria'), name: 'motivo-admissao-temporaria' },
  {
    label: i18n.t('com.muralis.qcx.motivoImportacaoSemCoberturaCambial'),
    name: 'motivo-importacao-sem-cobertura-cambial',
  },
  { label: i18n.t('com.muralis.qcx.naladi.naladiNCCA'), name: 'naladi-ncca' },
  { label: i18n.t('com.muralis.qcx.naladi.naladiSH'), name: 'naladi-sh' },
  { label: i18n.t('com.muralis.qcx.NCM.NCMnivel6subitem'), name: 'ncm-sub-item' },
  { label: i18n.t('com.muralis.qcx.NVE.label'), name: 'nve' },
  { label: i18n.t('com.muralis.qcx.NVE.NVEatributos'), name: 'nve-atributo' },
  { label: i18n.t('com.muralis.qcx.NVE.NVEespecificacao'), name: 'nve-especificacao' },
  { label: i18n.t('com.muralis.qcx.orgao.orgaoAnuente'), name: 'orgao-anuente' },
  { label: i18n.t('com.muralis.qcx.orgao.orgaoEmissorAtoLegal'), name: 'orgao-emissor-ato-legal' },
  { label: i18n.t('com.muralis.qcx.recintoAduaneiro'), name: 'recinto-aduaneiro' },
  { label: i18n.t('com.muralis.qcx.regimeTributacao'), name: 'regime-tributacao' },
  { label: i18n.t('com.muralis.qcx.setorLocacao'), name: 'setor-lotacao' },
  { label: i18n.t('com.muralis.qcx.taxaConversaoCambio'), name: 'taxa-conversao-cambio' },
  { label: i18n.t('com.muralis.qcx.tipoDeclaracao'), name: 'tipo-declaracao' },
  { label: i18n.t('com.muralis.qcx.tipoAtoLegal'), name: 'tipo-ato-legal' },
  { label: i18n.t('com.muralis.qcx.embalagem.tipoEmbalagem'), name: 'tipo-embalagem-nacional' },
  { label: i18n.t('com.muralis.qcx.tipoRecipiente'), name: 'tipo-recipiente' },
  { label: i18n.t('com.muralis.qcx.unidadeLocalRFB'), name: 'unidade-local-rfb' },
  { label: i18n.t('com.muralis.qcx.transporte.viaTransporte'), name: 'via-transporte' },
  { label: i18n.t('com.muralis.qcx.aduaneiraNcmTec'), name: 'aduaneiras-ncm-tec' },
];

const ADMINISTRADOR_ROLE = 'administrador-sistema';

const ACESSOS_CADASTROS_OPERACIONAIS_NAME_MAP = ACESSOS_CADASTROS_OPERACIONAIS.flatMap((i) => [
  `${i.name}-visualizar`,
  `${i.name}-incluir`,
  `${i.name}-alterar`,
  `${i.name}-excluir`,
]);
const ACESSOS_CADASTROS_GERAIS_NAME_MAP = ACESSOS_CADASTROS_GERAIS.flatMap((i) => [
  `${i.name}-visualizar`,
  `${i.name}-incluir`,
  `${i.name}-alterar`,
  `${i.name}-excluir`,
]);
const ACESSOS_CADASTROS_FINANCEIROS_NAME_MAP = ACESSOS_CADASTROS_FINANCEIROS.flatMap((i) => [
  `${i.name}-visualizar`,
  `${i.name}-incluir`,
  `${i.name}-alterar`,
  `${i.name}-excluir`,
]);
const ACESSOS_IMPORTACAO_NAME_MAP = ACESSOS_IMPORTACAO.flatMap((i) => [
  `${i.name}-visualizar`,
  `${i.name}-incluir`,
  `${i.name}-alterar`,
  `${i.name}-excluir`,
]);
const ACESSSOS_ASSESSORIA_NAME_MAP = ACESSSOS_ASSESSORIA.flatMap((i) => [
  `${i.name}-visualizar`,
  `${i.name}-incluir`,
  `${i.name}-alterar`,
  `${i.name}-excluir`,
]);
const ACESSOS_EXPORTACAO_NAME_MAP = ACESSOS_EXPORTACAO.flatMap((i) => [
  `${i.name}-visualizar`,
  `${i.name}-incluir`,
  `${i.name}-alterar`,
  `${i.name}-excluir`,
]);
const ACESSOS_FINANCEIRO_NAME_MAP = ACESSOS_FINANCEIRO.flatMap((i) => [
  `${i.name}-visualizar`,
  `${i.name}-incluir`,
  `${i.name}-alterar`,
  `${i.name}-excluir`,
]);

const ACESSOS_ACESSOS_TABELA_ADUANEIRA_NAME_MAP = ACESSOS_TABELA_ADUANEIRA.flatMap((i) => [`${i.name}-visualizar`]);

const ACESSOS_INTEGRACAO_NAME_MAP = ACESSOS_INTEGRACAO.flatMap((i) => [
  `${i.name}-visualizar`,
  `${i.name}-incluir`,
  `${i.name}-alterar`,
  `${i.name}-excluir`,
]);

const ACESSOS_RELATORIOS_NAME_MAP = ACESSOS_RELATORIOS.flatMap((i) => [
  `${i.name}-visualizar`,
  `${i.name}-incluir`,
  `${i.name}-alterar`,
  `${i.name}-excluir`,
]);

const ACESSOS_COMERCIAL_NAME_MAP = ACESSOS_COMERCIAL.flatMap((i) => [
  `${i.name}-visualizar`,
  `${i.name}-incluir`,
  `${i.name}-alterar`,
  `${i.name}-excluir`,
]);

const ACESSOS_CADASTROS_OPERACIONAIS_BASE_ROLE_NAME = ACESSOS_CADASTROS_OPERACIONAIS.map((item) => item.name);

const ACESSOS_CADASTROS_GERAIS_BASE_ROLE_NAME = ACESSOS_CADASTROS_GERAIS.map((item) => item.name);

const ACESSOS_ASSESSORIA_BASE_ROLE_NAME = ACESSSOS_ASSESSORIA.map((item) => item.name);

const ACESSOS_CADASTROS_FINANCEIROS_BASE_ROLE_NAME = ACESSOS_CADASTROS_FINANCEIROS.map((item) => item.name);

const ACESSOS_IMPORTACAO_BASE_ROLE_NAME = ACESSOS_IMPORTACAO.map((item) => item.name);

const ACESSOS_EXPORTACAO_BASE_ROLE_NAME = ACESSOS_EXPORTACAO.map((item) => item.name);

const ACESSOS_FINANCEIRO_BASE_ROLE_NAME = ACESSOS_FINANCEIRO.map((item) => item.name);

const ACESSOS_INTEGRACAO_BASE_ROLE_NAME = ACESSOS_INTEGRACAO.map((item) => item.name);

const ACESSOS_COMERCIAL_BASE_ROLE_NAME = ACESSOS_COMERCIAL.map((item) => item.name);

const ACESSOS_RELATORIOS_BASE_ROLE_NAME = ACESSOS_RELATORIOS.map((item) => item.name);

const ACESSOS_ACESSOS_TABELA_ADUANEIRA_BASE_ROLE_NAME = ACESSOS_TABELA_ADUANEIRA.map((item) => item.name);

const userHasRole = (authInfo = {}, role = '') => authInfo?.roles?.includes(role);

const userHasDeleteRole = (authInfo = {}, role = '') => userHasRole(authInfo, `${role}-excluir`);
const userHasUpdateRole = (authInfo = {}, role = '') => userHasRole(authInfo, `${role}-alterar`);
const userHasCreateRole = (authInfo = {}, role = '') => userHasRole(authInfo, `${role}-incluir`);

export {
  ACESSOS_ACESSOS_TABELA_ADUANEIRA_BASE_ROLE_NAME,
  ACESSOS_ACESSOS_TABELA_ADUANEIRA_NAME_MAP,
  ACESSOS_ASSESSORIA_BASE_ROLE_NAME,
  ACESSOS_CADASTROS_FINANCEIROS,
  ACESSOS_CADASTROS_FINANCEIROS_BASE_ROLE_NAME,
  ACESSOS_CADASTROS_FINANCEIROS_NAME_MAP,
  ACESSOS_CADASTROS_GERAIS,
  ACESSOS_CADASTROS_GERAIS_BASE_ROLE_NAME,
  ACESSOS_CADASTROS_GERAIS_NAME_MAP,
  ACESSOS_CADASTROS_OPERACIONAIS,
  ACESSOS_CADASTROS_OPERACIONAIS_BASE_ROLE_NAME,
  ACESSOS_CADASTROS_OPERACIONAIS_NAME_MAP,
  ACESSOS_COMERCIAL,
  ACESSOS_COMERCIAL_BASE_ROLE_NAME,
  ACESSOS_COMERCIAL_NAME_MAP,
  ACESSOS_EXPORTACAO,
  ACESSOS_EXPORTACAO_BASE_ROLE_NAME,
  ACESSOS_EXPORTACAO_NAME_MAP,
  ACESSOS_FINANCEIRO,
  ACESSOS_FINANCEIRO_BASE_ROLE_NAME,
  ACESSOS_FINANCEIRO_NAME_MAP,
  ACESSOS_IMPORTACAO,
  ACESSOS_IMPORTACAO_BASE_ROLE_NAME,
  ACESSOS_IMPORTACAO_NAME_MAP,
  ACESSOS_INTEGRACAO,
  ACESSOS_INTEGRACAO_BASE_ROLE_NAME,
  ACESSOS_INTEGRACAO_NAME_MAP,
  ACESSOS_RELATORIOS,
  ACESSOS_RELATORIOS_BASE_ROLE_NAME,
  ACESSOS_RELATORIOS_NAME_MAP,
  ACESSOS_TABELA_ADUANEIRA,
  ACESSSOS_ASSESSORIA,
  ACESSSOS_ASSESSORIA_NAME_MAP,
  ADMINISTRADOR_ROLE,
  MODULOS_DE_ACESSO,
  ModuloPerfilUtils,
  userHasCreateRole,
  userHasDeleteRole,
  userHasRole,
  userHasUpdateRole,
};
