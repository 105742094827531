import {
  Grid,
} from '@material-ui/core';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXFormTitle from '../form-title/QCXFormTitle';
import {
  RELACAO_FABRICANTE_DESCONHECIDO,
  useFornecedorListenersGroup,
} from '../relacao-fornecedor/relacao-fornecedor-utils';
import QCXRelacaoFornecedorFieldGroup from '../relacao-fornecedor/QCXRelacaoFornecedorFieldGroup';
import QCXFaturaFornecedorFlagsFormGroup from './QCXFaturaFornecedorFlagsFormGroup';
import QCXWarningAlert from '../../shared-components/alert/QCXWarningAlert';

export default function QCXFaturaFornecedorForm({
  initialValues,
  handleSubmit,
  isConsult,
  controlComponentProps,
  ...restProps
}) {
  const onChangeListeners = useFornecedorListenersGroup();

  /* eslint react/no-unknown-property: 0 */
  const { t } = useTranslation();

  const validatePendencias = (fatura) => {
    const pendencias = [];

    if (fatura.origem !== 'OCR') return <></>;

    // exportador
    if (!fatura.exportador) {
      pendencias.push(t('com.muralis.qcx.exportador.label'));
    }

    // fabricante
    if (!fatura.fabricante
      && fatura.relacao !== RELACAO_FABRICANTE_DESCONHECIDO
    ) {
      pendencias.push(t('com.muralis.qcx.fabricante.label'));
    }

    if (pendencias.length > 0) {
      return (
        <Grid xs={12} style={{ marginTop: '2rem' }}>
          <QCXWarningAlert closable>
            <Trans i18nKey="com.muralis.qcx.mensagem.pendenciaOCRFatura">
              Verifique as seguintes pendências detectadas pelo OCR:
              <ul i18nIsDynamicList>
                {pendencias.map((pendencia) => (
                  <li>{pendencia}</li>
                ))}
              </ul>
            </Trans>
          </QCXWarningAlert>
        </Grid>
      );
    }

    return <></>;
  };
  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      onChangeListeners={onChangeListeners}
      {...restProps}
    >
      {({ values }) => (
        <>
          {validatePendencias(values)}
          <QCXFormTitle title={t('com.muralis.qcx.fornecedor.fichaFornecedor')} />
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            <QCXRelacaoFornecedorFieldGroup
              isConsult={isConsult}
              relacao={values?.relacao}
              fabricante={values?.fabricante}
              exportador={values?.exportador}
              required={false}
              initialValues={values}
              {...restProps}
            />
            <QCXFaturaFornecedorFlagsFormGroup
              isConsult={isConsult}
            />
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
