import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Cliente, PessoaJuridica } from 'quickcomex-api-types';
import { fetchAllAsync, selectClientes } from '../../../../../features/cliente/clienteSlice';
import MultiSelectFormInput from '../../../../common/components/forms/MultiSelectFormInput';
import { formatCnpj } from '../../../../../utils/hooks/form/field/formatters';
import { ClientIdAndDescriptionDTO } from '../../../../common/types/clientData';

type Props = {
  label: string;
  name: string;
  required?: boolean;
  clientes?: ClientIdAndDescriptionDTO[];
  fetchData?: boolean; 
};

function MultiSelectClientes({ label, name, required, clientes, fetchData = true }: Props) { 
  const dispatch = useDispatch();
  const clientesFromState: Cliente[] = useSelector(selectClientes);

  const clientesToUse = clientes?.length ? clientes : clientesFromState;

  const options = useMemo(
    () =>
      clientesToUse.map((cliente) => {
        const pessoaJuridica = cliente.pessoa as PessoaJuridica | undefined;
        const cnpj = pessoaJuridica?.cnpj ? formatCnpj(pessoaJuridica.cnpj) : 'CNPJ não informado';
        
        return {
          ...cliente,
          id: cliente.id,
          value: cliente.id + ' - ' + cliente.description + ' - ' + cnpj,
        };
      }),
    [clientesToUse]
  );

  useEffect(() => {
    if (fetchData && (!clientes || clientes.length === 0)) {
      dispatch(fetchAllAsync());
    }
  }, [clientes, dispatch, fetchData]);

  return <MultiSelectFormInput name={name} options={options} label={label} />;
}

export default MultiSelectClientes;
