/* eslint-disable import/extensions */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import { DEFAULT_TIPO_DUE_PARAMETER } from '../../utils/general/fatura/faturaUtils';
import { faturaAPI } from '../fatura/faturaAPI';
import { finishLoading, startLoading } from '../application-state/applicationStateSlice';
import { setErrorFeedback, setSuccessFeedback } from '../feedback/feedbackSlice';

const fetchAllFromFaturaAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/fetchAllFromFatura',
  async (_, { dispatch }) => {
    dispatch(startLoading());

    const { data, status } = await faturaAPI.fetchByFilter([DEFAULT_TIPO_DUE_PARAMETER]);

    if (status === 200) {
      dispatch(finishLoading());
    }

    return { data };
  }
);

const fetchDuesByFilterAsync = createAsyncThunk('declaracaoUnicaExportacao/fetchDuesByFilter', async (params) => {
  const { data } = await faturaAPI.fetchDuesByFilter(params);

  return { data };
});

const fetchByIdFromFaturaAsync = createAsyncThunk('declaracaoUnicaExportacao/fetchByIdFromFatura', async (data) => {
  const response = await faturaAPI.fetchById(data);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const activateByIdFromFaturaAsync = createAsyncThunk('declaracaoUnicaExportacao/activateByIdFromFatura', async (id) => {
  const { status, data } = await faturaAPI.activateById(id);
  return { response: { status, data } };
});

const inactivateByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/inactivateByIdFromFatura',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const { status, data } = await faturaAPI.inactivateById(id);

      if (status === 200) {
        dispatch(setSuccessFeedback({ message: 'Due inativada com sucesso!' }));
      }

      return { response: { status, data } };
    } catch (e) {
      dispatch(setErrorFeedback({ message: `Erro ao inativar a DUE: ${e?.response?.data?.message}` }));
      return rejectWithValue(e?.response?.data);
    }
  }
);

const calculaDUEAposZerarCamposAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/calculaDueZerandoCampos',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await faturaAPI.calculateById(data?.id, [], { shouldZeroFields: true });

      if (response?.status === 200) {
        dispatch(setSuccessFeedback({ message: 'Campos zerados com sucesso! Due recalculada com sucesso!' }));
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: `Erro ao zerar campos da DUE: ${error?.response?.data?.message}` }));

      return rejectWithValue(error.response.data);
    }
  }
);

const calculateByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/calculateByIdFromFatura',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await faturaAPI.calculateById(data?.id);

      if (response?.status === 200) {
        dispatch(setSuccessFeedback({ message: 'Due recalculada com sucesso!' }));
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: `Erro ao calcular a DUE: ${error?.response?.data?.message}` }));

      return rejectWithValue(error.response.data);
    }
  }
);

const conferirByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/conferirByIdFromFatura',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await faturaAPI.conferirBy(data?.id);

      if (response?.status === 200) {
        dispatch(setSuccessFeedback({ message: 'Conferido com sucesso' }));
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: `Erro ao conferir a DUE: ${error?.response?.data?.message}` }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const liberarByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/liberarByIdFromFatura',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await faturaAPI.liberarBy(data?.id);

      if (response?.status === 200) {
        dispatch(setSuccessFeedback({ message: 'Liberado com sucesso' }));
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: `Erro ao liberar a DUE: ${error?.response?.data?.message}` }));

      return rejectWithValue(error?.response?.data);
    }
  }
);

const reabrirByIdAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/reabrirById',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      dispatch(startLoading());

      const response = await faturaAPI.reabrirById(data?.id);

      if (response?.status === 200) {
        dispatch(finishLoading());
        dispatch(fetchAllFromFaturaAsync());
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: `Erro ao reabrir a DUE: ${error?.response?.data?.message}` }));
      dispatch(finishLoading());
      return rejectWithValue(error?.response?.data);
    }
  }
);

const retificarByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/retificarByIdFromFatura',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await faturaAPI.retificarById(data?.id, [], { motivo: data?.motivo });

      if (response?.status === 200) {
        dispatch(setSuccessFeedback('Retificação realizada com sucesso'));
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: `Erro ao retificar a DUE: ${error?.response?.data?.message}` }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const duplicarByIdFromFaturaAsync = createAsyncThunk(
  'declaracaoUnicaExportacao/duplicarById',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await faturaAPI.duplicarBy(data?.id, [], data?.payload);

      if (response?.status === 200) {
        dispatch(setSuccessFeedback({ message: 'Duplicado com sucesso' }));
      }

      return {
        response: {
          status: response?.status,
          data: response?.data,
          ...omit(response, ['request', 'config']),
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: `Erro ao duplicar a DUE: ${error?.response?.data?.message}` }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const declaracaoUnicaExportacaoThunks = {
  fetchAllFromFaturaAsync,
  fetchByIdFromFaturaAsync,
  activateByIdFromFaturaAsync,
  inactivateByIdFromFaturaAsync,
  calculateByIdFromFaturaAsync,
  conferirByIdFromFaturaAsync,
  liberarByIdFromFaturaAsync,
  duplicarByIdFromFaturaAsync,
  retificarByIdFromFaturaAsync,
  calculaDUEAposZerarCamposAsync,
  reabrirByIdAsync,
  fetchDuesByFilterAsync,
};

export {
  fetchDuesByFilterAsync,
  activateByIdFromFaturaAsync,
  calculaDUEAposZerarCamposAsync,
  calculateByIdFromFaturaAsync,
  conferirByIdFromFaturaAsync,
  declaracaoUnicaExportacaoThunks,
  duplicarByIdFromFaturaAsync,
  fetchAllFromFaturaAsync,
  fetchByIdFromFaturaAsync,
  inactivateByIdFromFaturaAsync,
  liberarByIdFromFaturaAsync,
  reabrirByIdAsync,
  retificarByIdFromFaturaAsync,
};
