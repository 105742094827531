import React, { useEffect, useMemo, useState } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import { OnChange } from 'react-final-form-listeners';
import _ from 'lodash';

import QCXFinalTextField from '../../../shared-components/final-text-field/QCXFinalTextField'; // texto


export default function QCXAtributoTextField({
    atributo,
    avaliaCondicionantesEvent,
    isCondicionado,
    isConsult,
    isComposto = false,
    codAtributoPai,
    basePath="",
    ...restProps
}) {

    const [label, setLabel] = useState("")
    const [name,  setName] = useState("")
    const [obrigatorio, setObrigatorio] = useState(false);
    const [codigo, setCodigo] = useState("");
    const [tamanhoMaximo, setTamanhoMaximo] = useState(null);
    let path = `atributosPreenchidos`
    const form = useForm();

    if (basePath) {
        path = `${basePath}.${path}`
    }

    const verificaCondicionante = (value) => {
        const condicionados =  _.get(atributo, "detalhesAtributos.condicionados")
        if(condicionados && _.isArray(condicionados) && condicionados.length && _.isFunction(avaliaCondicionantesEvent)) {
            avaliaCondicionantesEvent(value, codigo)
        }
    }

    useEffect(() => {
        
        if(atributo && atributo.detalhesAtributos) {
            const detalhes = atributo.detalhesAtributos;
            const condicionados =  _.get(atributo, "detalhesAtributos.condicionados")

            setLabel(detalhes.nomeApresentacao)
            setCodigo(detalhes.codigo)
            setName(`${path}.atributos.${detalhes.codigo}.valor`)
            setObrigatorio(detalhes.obrigatorio)
            setTamanhoMaximo(detalhes.tamanhoMaximo)

            if(isComposto && codAtributoPai) {
                setName(`${path}.atributosCompostos.${codAtributoPai}.valores.${detalhes.codigo}.valor`)
            }

        }

        return null;
    }, [])

    if(!name) {
        return null
    }

    return (
        <>
            <QCXFinalTextField
                id={codigo}
                key={codigo}
                name={name}
                label={label}
                disabled={isConsult}
                maxLength={tamanhoMaximo}
                required={obrigatorio}
                {...restProps}
            />

            <OnChange name={name}>
                {(value, previous) => verificaCondicionante(value)}
            </OnChange>
        </>

    );
}
