import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../dados-embarque/base-components/BaseSelectWithOptions';

function SelectTipoRateioDesconto({ ...props }) {
  const list = useMemo(
    () => [
      {
        id: 'PESO',
        label: 'PESO',
        value: 'PESO',
      },
      {
        id: 'VALOR',
        label: 'VALOR',
        value: 'VALOR',
      },
    ],
    []
  );

  return <BaseSelectWithOptions list={list} {...props} />;
}

export default SelectTipoRateioDesconto;
