const catalogoProdutosStatusIntegracaoOptions = [
  {
    id: 'PENDENTE',
    value: 'PENDENTE',
  },
  {
    id: 'ERRO',
    value: 'ERRO',
  },
  {
    id: 'ATIVADO',
    value: 'ATIVADO',
  },
  {
    id: 'DESATIVADO',
    value: 'DESATIVADO',
  },
  {
    id: 'RASCUNHO',
    value: 'RASCUNHO',
  },
];

export default catalogoProdutosStatusIntegracaoOptions;
