import { Grid } from '@material-ui/core';
import _ from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import QCXInfoAlert from '../../shared-components/alert/QCXInfoAlert';
import QCXWarningAlert from '../../shared-components/alert/QCXWarningAlert';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalNumericDecimalField from '../../shared-components/final-numeric-decimal-field/QCXFinalNumericDecimalField';
import QCXSelectClienteAutocomplete from '../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXSelectIncotermCondicaoVendaAutocomplete from '../../shared-components/final-select-incoterm-condicao-venda-field/QCXSelectIncotermCondicaoVendaAutocomplete';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectPaisAutocomplete from '../../shared-components/select-pais/QCXSelectPaisAutocomplete';
import QCXSelectUnidadeLocalRFBAutocomplete from '../../shared-components/select-unidade-local-rfb/QCXSelectUnidadeLocalRFBAutocomplete';
import { FaturaUtils } from '../../utils/general/fatura/faturaUtils';
import { required as requiredValidator } from '../../utils/validators/field/validator';
import QCXMoveFocusInside from '../focus-lock/QCXMoveFocusInside';
import QCXFormTitle from '../form-title/QCXFormTitle';
import QCXSelectPortoAeroportoAutocomplete from '../../shared-components/select-porto-aeroporto/QCXSelectPortoAeroportoAutocomplete';
import { LocalPortoAeroportoUtils } from '../../shared-components/final-select-local-porto-aeroporto/localPortoAeroportoUtils';
import QCXSelectViaTransporteAutocomplete from '../../shared-components/select-via-transporte/QCXSelectViaTransporteAutocomplete';
import SelectTipoImportacao from '../declaracao-importacao/SelectTipoImportacao';
import QCXSelectTransportadorInternacionalAutocomplete from '../../shared-components/select-transportador-internacional/QCXSelectTransportadorInternacionalAutocomplete';

export default function QCXFaturaImportadorForm({
  initialValues,
  handleSubmit,
  isConsult,
  controlComponentProps,
  authInfo = {},
  ...restProps
}) {
  /* eslint react/no-unknown-property: 0 */
  const { t } = useTranslation();

  const validatePendencias = (fatura) => {
    const pendencias = [];

    if (fatura.origem !== 'OCR') return <></>;

    // erro importador
    let metadata = {};
    try {
      metadata = JSON.parse(fatura?.metadata);
      // eslint-disable-next-line no-empty
    } catch (error) {}
    const pendenciaImportador = _.get(metadata, 'pendencias.importador');

    // numero
    if (!fatura.numero) {
      pendencias.push(t('com.muralis.qcx.fatura.numeroFatura'));
    }

    if (!FaturaUtils.numericoValido(fatura.pesoBruto)) {
      pendencias.push(t('com.muralis.qcx.pesoBruto'));
    }

    // peso liquido
    if (!FaturaUtils.numericoValido(fatura.pesoLiquido)) {
      pendencias.push(t('com.muralis.qcx.pesoLiquido'));
    }

    if (pendencias.length > 0 || pendenciaImportador) {
      return (
        <Grid xs={12}>
          {pendenciaImportador && (
            <QCXInfoAlert style={{ marginTop: '2rem' }} closable>
              {pendenciaImportador.split('\n').map((p) => (
                <>
                  {p}
                  <br />
                </>
              ))}
            </QCXInfoAlert>
          )}
          {pendencias.length > 0 && (
            <QCXWarningAlert style={{ marginTop: '2rem' }} closable>
              <Trans i18nKey="com.muralis.qcx.mensagem.pendenciaOCRFatura">
                Verifique as seguintes pendências detectadas pelo OCR:
                <ul i18nIsDynamicList>
                  {pendencias.map((pendencia) => (
                    <li>{pendencia}</li>
                  ))}
                </ul>
              </Trans>
            </QCXWarningAlert>
          )}
        </Grid>
      );
    }

    return <></>;
  };
  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      authInfo={authInfo}
      {...restProps}
    >
      {({ values }) => (
        <>
          {validatePendencias(values)}
          <QCXFormTitle title={t('com.muralis.qcx.importador.fichaImportador')} />
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <QCXMoveFocusInside>
                <QCXFinalTextField
                  id="text-field-numero-fatura"
                  key="text-field-numero-fatura"
                  name="numero"
                  label={t('com.muralis.qcx.fatura.numeroFatura')}
                  disabled={isConsult}
                  validate={requiredValidator}
                  required
                />
              </QCXMoveFocusInside>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <QCXFinalTextField
                id="text-field-numero-pedido"
                key="text-field-numero-pedido"
                name="numeroPedido"
                label={t('com.muralis.qcx.fatura.numeroPedido')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <QCXFinalTextField
                id="text-field-numero-ordem-compra"
                key="text-field-numero-ordem-compra"
                name="numeroOrdemCompra"
                label={t('com.muralis.qcx.fatura.numeroOrdemCompra')}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4}>
              <QCXFinalCheckboxField
                id="checkbox-is-npi"
                key="checkbox-is-npi-field"
                name="isNpi"
                label="NPI"
                disabled={isConsult}
              />
            </Grid>
            
            <Grid item xs={12} sm={6} md={3} lg={4}>
              <QCXFinalDatePickerField
                id="date-picker-data-field"
                key="date-picker-data-field"
                name="data"
                label={t('com.muralis.qcx.fatura.data')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <QCXSelectClienteAutocomplete
                id="select-field-importador"
                key="select-field-importador"
                name="importador.id"
                label={t('com.muralis.qcx.importador.label')}
                initialValues={values}
                filter={{
                  importador: true,
                }}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <QCXSelectPaisAutocomplete
                id="select-field-pais-procedencia"
                key="select-field-pais-procedencia"
                name="paisProcedencia.id"
                label={t('com.muralis.qcx.paisProcedencia')}
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <QCXSelectUnidadeLocalRFBAutocomplete
                id="select-field-urf-despacho"
                key="select-field-urf-despacho"
                name="urfDespacho.id"
                label={t('com.muralis.qcx.fatura.URFdespacho')}
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <QCXSelectUnidadeLocalRFBAutocomplete
                id="select-field-urf-entrada"
                key="select-field-urf-entrada"
                name="urfEntrada.id"
                label={t('com.muralis.qcx.fatura.URFentrada')}
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <QCXFinalNumericDecimalField
                id="text-number-peso-bruto-field"
                key="text-number-peso-bruto-field"
                name="pesoBruto"
                label={t('com.muralis.qcx.pesoBruto')}
                disabled={isConsult}
                scale={7}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <QCXFinalNumericDecimalField
                id="text-number-peso-liquido-field"
                key="text-number-peso-liquido-field"
                name="pesoLiquido"
                label={t('com.muralis.qcx.pesoLiquido')}
                disabled={isConsult}
                scale={7}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <QCXSelectIncotermCondicaoVendaAutocomplete
                id="select-field-incoterm"
                key="select-field-incoterm"
                name="incotermCondicaoVenda.id"
                label={t('com.muralis.qcx.incoterm')}
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <QCXFinalCheckboxField
                id="checkbox-ratear-peso-liquido-itens-field"
                key="checkbox-ratear-peso-liquido-itens-field"
                name="rateiaPesoLiquidoItem"
                label={t('com.muralis.qcx.ratearPesoLiquidoItens')}
                disabled={isConsult}
              />
            </Grid>


            <Grid item xs={12} sm={12} md={6}>
              <QCXSelectPortoAeroportoAutocomplete
                id="select-field-local-embarque"
                key="select-field-local-embarque"
                name="localEmbarque.id"
                label={t('com.muralis.qcx.localEmbarque')}
                initialValues={values}
                filter={{
                  local: LocalPortoAeroportoUtils.DESTINO,
                }}
                disabled={isConsult}
              />
            </Grid>

                <Grid item xs={6}>
                  <QCXFinalTextField
                    id="text-field-auxiliar-invoice"
                    key="text-field-auxiliar-invoice"
                    label="Auxiliar Invoice"
                    name="auxiliarInvoice"
                    disabled={isConsult}
                  />
                </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXSelectViaTransporteAutocomplete
                id="autocomplete-select-via-transporte-field"
                key="autocomplete-select-via-transporte-field"
                name="viaTransporte.id"
                label={t('com.muralis.qcx.transporte.viaTransporte')}
                disabled={isConsult}
                initialValues={values}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalTextField
                id="text-field-auxiliar-invoice"
                key="text-field-auxiliar-invoice"
                label="Analista Externo"
                name="analistaExterno"
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
                <SelectTipoImportacao
                  name="tipoImportacao"
                  label="Tipo de Importação"
                  disabled={isConsult}
                />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalTextField
                  id="text-field-area"
                  key="text-field-area"
                  label="Área"
                  name="area"
                  disabled={isConsult}
                />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalTextField
                  id="text-field-numeroEmbarqueVinculado"
                  key="text-field-numeroEmbarqueVinculado"
                  label="Número Embarque Vinculado"
                  name="numeroEmbarqueVinculado"
                  disabled={isConsult}
                />
            </Grid>

            <Grid item xs={6}>
              <QCXSelectTransportadorInternacionalAutocomplete
                key="transportadora-internacional-field"
                id="transportadora-internacional-field"
                name="agenteCarga"
                label={t('com.muralis.qcx.dadosEmbarque.agenteCarga')}
                initialValues={initialValues}
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={6}>
              <QCXFinalTextField
                  id="text-field-volume"
                  key="text-field-volume"
                  label="Quantidade Volume"
                  name="quantidadeVolume"
                  disabled={isConsult}
                />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
