/* eslint-disable @typescript-eslint/no-explicit-any */
import { DadosConteiner, DadosEmbarque, DadosEmbarqueCamposAdicionais } from 'quickcomex-api-types';
import { useCallback } from 'react';
import { validate, v4 as uuid } from 'uuid';
import { normalizeNumeral, unnormalizeNumeral } from '../../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../../../utils/hooks/form/field/formatters';

export interface DadosEmbarqueFormValues
  extends Omit<DadosEmbarque, 'id' | 'dadosEmbarqueCamposAdicionais' | 'containers'> {
  id: string;
  ignorableFields: any;
  camposAdicionais: DadosEmbarqueCamposAdicionais[];
  containers: DadosEmbarqueContainerFormValues[];
}

export interface DadosEmbarqueContainerFormValues extends Omit<DadosConteiner, 'id'> {
  id: string;
}

// representa a request que será enviada para a API
// o id pode ser null indicando que é um dado novo a ser inserido
export interface DadosEmbarqueContainerAPIRequest extends Omit<DadosConteiner, 'id'> {
  id: number | null;
}

export const useNormalizeDadosEmbarque = (dadosEmbarque: any) => {
  const normalize = useCallback(
    (unnormalizedData: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { ignorableFields, containers, camposAdicionais, followUp, ...data } = unnormalizedData;

      const normalizedContainers: DadosEmbarqueContainerAPIRequest[] = containers?.map(
        (c: DadosEmbarqueContainerFormValues) => ({
          ...c,
          id: validate(c?.id) ? null : Number(c?.id),
        })
      );

      const normalizedCamposAdicionais = camposAdicionais?.map((ca: DadosEmbarqueCamposAdicionais) => ({
        ...ca,
        camposAdicionais: { id: ca?.camposAdicionais?.id },
      }));

      return {
        ...dadosEmbarque,
        ...data,
        containers: normalizedContainers,
        valorVenda: normalizeNumeral(data?.valorVenda),
        valorCompra: normalizeNumeral(data?.valorCompra),
        terminalDespacho: data?.terminalDespacho?.id ? { id: data?.terminalDespacho?.id } : undefined,
        terminalEmbarque: data?.terminalEmbarque?.id ? { id: data?.terminalEmbarque?.id } : undefined,
        terminalRedestinacao: data?.terminalRedestinacao?.id ? { id: data?.terminalRedestinacao?.id } : undefined,
        followUp: {
          id: followUp.id,
          servico: followUp.servico,
          numeroConhecimentoHouse: followUp?.numeroConhecimentoHouse === '-' ? null : followUp?.numeroConhecimentoHouse,
          numeroConhecimentoMaster:
            followUp?.numeroConhecimentoMaster === '-' ? null : followUp?.numeroConhecimentoMaster,
          transportadora: { ...followUp?.transportadora },
        },
        dadosEmbarqueCamposAdicionais: normalizedCamposAdicionais,
      };
    },
    [dadosEmbarque]
  );

  // TODO: corrigir os tipos e deixar de usar any
  const unnormalize = useCallback((normalizedDadosEmbarque: any) => {
    const camposAdicionaisFromServico = normalizedDadosEmbarque?.followUp?.servico?.camposAdicionais;

    const unnormalizedCamposAdicionais = normalizedDadosEmbarque?.dadosEmbarqueCamposAdicionais
      ? normalizedDadosEmbarque?.dadosEmbarqueCamposAdicionais?.map((ca: any) => ({
          ...ca,
          }))
      : camposAdicionaisFromServico?.map((ca: any) => ({ camposAdicionais: { ...ca }, valor: '' }));

    const unnormalizedFollowup = {
      ...normalizedDadosEmbarque?.followUp,
      numeroConhecimentoHouse: normalizedDadosEmbarque?.followUp?.numeroConhecimentoHouse ?? '-',
      numeroConhecimentoMaster: normalizedDadosEmbarque?.followUp?.numeroConhecimentoMaster ?? '-',
      referencias:
        normalizedDadosEmbarque?.followUp?.referencias?.map((r: any) => ({ id: uuid(), referencia: r })) ?? [],
    };

    const unnormalizedDadosEmbarque = {
      ...normalizedDadosEmbarque,
      camposAdicionais: unnormalizedCamposAdicionais as any,
      valorVenda: unnormalizeNumeral(normalizedDadosEmbarque?.valorVenda, formatBrazilianNumericDecimal(2)),
      valorCompra: unnormalizeNumeral(normalizedDadosEmbarque?.valorCompra, formatBrazilianNumericDecimal(2)),
      followUp: unnormalizedFollowup as any,
      ignorableFields: {
        flags: {
          desova: normalizedDadosEmbarque?.tipoDesova !== undefined ?? false,
          necessidadeLi: normalizedDadosEmbarque?.orgaoAnuente?.length > 0 ?? false,
        },
      },
    };

    return unnormalizedDadosEmbarque as any as DadosEmbarqueFormValues;
  }, []);

  return { normalize, unnormalize };
};
