import CardsGridSubmenu from '../../../common/components/cardsGrid/cardsGridSubmenu';
import BasicLayout from '../../../common/layouts/basicLayout';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { CardsGridItem } from '../../../common/components/cardsGrid/cardsGrid';
import EjectIcon from '@mui/icons-material/Eject';

const cover: CardsGridItem = {
  title: 'Red Bull',
  icon: EjectIcon,
  path: '/relatorios',
};

const gridItems: CardsGridItem[] = [
  {
    title: 'Latas',
    icon: DriveFileRenameOutlineIcon,
    path: '/relatorios/redbull/latas',
  },
  {
    title: 'Comum',
    icon: DriveFileRenameOutlineIcon,
    path: '/relatorios/redbull/comum',
  },
];

const RedBullPage = () => {
  return (
    <BasicLayout
      title={'Red Bull'}
      icon={<EjectIcon color={'secondary'} />}
    >
      <CardsGridSubmenu cover={cover} items={gridItems}></CardsGridSubmenu>
    </BasicLayout>
  );
};

export default RedBullPage;
