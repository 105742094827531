import { Dialog, Grid, makeStyles } from '@material-ui/core';
import { useMemo } from 'react';
import QCXButton from '../button/QCXButton';
import QCXDialogActions from '../dialog/QCXDialogActions';
import QCXDialogContent from '../dialog/QCXDialogContent';
import QCXDialogContentText from '../dialog/QCXDialogContentText';
import QCXDialogTitle from '../dialog/QCXDialogTitle';

const useStyles = makeStyles(() => ({
  dialogContentText: {
    marginBottom: '18px',
  },
  dialogActions: {
    padding: '12px 24px',
  },
  scrollableDialogContent: {
    maxHeight: '400px', 
    minWidth: '500px',
    overflowY: 'auto', 
  },
  paper: {
    boxShadow: 'none', 
    border: '1px solid #ccc', 
  },
}));

export default function ModalRelatorio({ open, content, onClose, severity, ...restProps }) {
  const classes = useStyles();

  const localTitle = useMemo(() =>  determineTitle(severity), [severity]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropProps={{ invisible: true }}
      PaperProps={{ classes: { root: classes.paper } }}
      {...restProps}
    >
      {localTitle && <QCXDialogTitle>{localTitle}</QCXDialogTitle>}
      <QCXDialogContent className={classes.scrollableDialogContent}>
        {content && (
          <QCXDialogContentText
            id="final-form-dialog-description"
            className={classes.dialogContentText}
            dangerouslySetInnerHTML={{ __html: content }} 
          />
        )}
      </QCXDialogContent>
      <QCXDialogActions className={classes.dialogActions}>
        <Grid item container justify="flex-end" spacing={1}>
          <Grid item xs={4}>
            <QCXButton variant="contained" color="secondary" type="submit" fullWidth autoFocus onClick={onClose}>
              Fechar
            </QCXButton>
          </Grid>
        </Grid>
      </QCXDialogActions>
    </Dialog>
  );
}

function determineTitle(severity) {
  switch (severity) {
    case 'error':
      return 'Erro:';
    case 'warning':
      return 'Aviso:';
    case 'info':
      return 'Informação:';
    case 'success':
      return 'Sucesso:';
    default:
      return 'Atenção:';
  }
}
