import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import _, { isArrayLikeObject, isEmpty, isFunction, omit } from 'lodash';
import { v4 as uuid } from 'uuid';
import { followUpExportacaoActions } from '../../../../features/follow-up-exportacao/followUpExportacaoSlice';
import followUpExportacaoSelectors from '../../../../features/follow-up-exportacao/followUpExportacaoSelectors';
import followUpExportacaoThunks from '../../../../features/follow-up-exportacao/followUpExportacaoThunks';
import {
  isCreateMode,
  isBackgroundCreateMode,
  isConsultMode,
  isUpdateMode,
  isBackgroundDeleteMode,
  isNoneMode,
  isLoadingStatus,
  isIdleStatus,
  isFailureStatus,
  isLockedMode,
  isPreparingActionStatus,
  isBackgroundUpdateMode,
} from '../../../../utils/store/store-utils';
import { followUpAPI } from '../../../../features/follow-up/followUpAPI';
import QCXRegistrationFormPageTemplate from '../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import QCXFollowUpExportacaoFinalForm from '../../../../components/follow-up-exportacao/QCXFollowUpExportacaoFinalForm';
import useOperationConfirm from '../../../../utils/hooks/operation/confirm/useOperationConfirm';
import TipoDocumentoUtils from '../../../../utils/general/tipo-documento/TipoDocumentoUtils';
import useOperationMiddleware from '../../../../utils/hooks/operation/middleware/useOperationMiddleware';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import FollowUpUtils from '../../../../utils/general/follow-up/FollowUpUtils';
import useAutoChangeMode from '../../../../utils/hooks/mode/useAutoChangeMode';
import { MediaType } from '../../../../utils/api/api-utils';
import { clienteActions } from '../../../../features/cliente/clienteSlice';
import { servicoActions } from '../../../../features/servico/servicoSlice';
import { IDLE_CONTROL } from '../../../../features/config-control';
import { setErrorFeedback } from '../../../../features/feedback/feedbackSlice';

export default function FollowUpExportacaoRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const params = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const status = useSelector(followUpExportacaoSelectors.selectStatus);
  const mode = useSelector(followUpExportacaoSelectors.selectMode);
  const backgroundMode = useSelector(followUpExportacaoSelectors.selectBackgroundMode);
  const followUp = useSelector(followUpExportacaoSelectors.selectFollowUp);

  const followUpAlreadyCreated = useSelector(followUpExportacaoSelectors.selectAlreadyCreated);

  const [warning, setWarning] = useState({
    flag: FollowUpUtils.NOOP_WARNING,
    message: undefined,
  });

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);

  const isLoading = useMemo(() => isLoadingStatus(status), [status]);

  const isIdle = useMemo(() => isIdleStatus(status), [status]);

  const isFailure = useMemo(() => isFailureStatus(status), [status]);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isLocked = useMemo(() => isLockedMode(mode), [mode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const isBackgroundUpdate = useMemo(() => isBackgroundUpdateMode(backgroundMode), [backgroundMode]);

  const isBackgroundDelete = useMemo(() => isBackgroundDeleteMode(backgroundMode), [backgroundMode]);

  const handleChangeToPreparingAction = useCallback(() => {
    dispatch(followUpExportacaoActions.preparingAction());
  }, []);

  const handleChangeToCreate = useCallback(() => {
    dispatch(followUpExportacaoActions.changeToCreateMode());
  }, []);

  const handleChangeToBackgroundCreate = useCallback(() => {
    dispatch(followUpExportacaoActions.changeToBackgroundCreateMode());
  }, []);

  const handleChangeToBackgroundDelete = useCallback(() => {
    dispatch(followUpExportacaoActions.changeToBackgroundDeleteMode());
  }, []);

  const handleChangeToConsult = useCallback(() => {
    dispatch(followUpExportacaoActions.changeToConsultMode());
  }, []);

  const handleChangeToUpdate = useCallback((additional = {}) => {
    dispatch(followUpExportacaoActions.changeToUpdateMode());

    if (!isEmpty(additional) && isFunction(additional?.callback)) {
      additional.callback();
    }
  }, []);

  const handleResetBackgroundMode = useCallback(() => {
    dispatch(followUpExportacaoActions.resetBackgroundMode());
  }, []);

  const fetchById = useCallback((followUpId) => {
    dispatch(followUpExportacaoThunks.fetchByIdAsync(followUpId));
  }, []);

  const handleBasicCancelUpdate = useCallback(
    (additional = {}) => {
      const currentId = isBackgroundCreate ? followUp?.id : params?.id;

      if (currentId) {
        fetchById(currentId);
      }

      handleChangeToConsult();

      if (!isEmpty(additional) && isFunction(additional?.callback)) {
        additional.callback();
      }
    },
    [params, followUp, isBackgroundCreate, fetchById, handleChangeToConsult]
  );

  const operationOfCancelUpdateOptions = useMemo(
    () => ({
      title: t('com.muralis.qcx.aviso').toUpperCase(),
      message: t('com.muralis.qcx.mensagem.alteracoesRealizadasSeraoPerdidas'),
      endMessage: t('com.muralis.qcx.mensagem.certezaDesejaCancelar'),
    }),
    []
  );

  const [handleCancelUpdate, operationOfCancelUpdate] = useOperationConfirm(
    handleBasicCancelUpdate,
    operationOfCancelUpdateOptions,
    [handleBasicCancelUpdate, operationOfCancelUpdateOptions]
  );

  const normalize = useCallback(
    (unnormalizedData) => {
      const normalizedCorrigido = FollowUpUtils.normalizeCorrigidoFollowUp(unnormalizedData?.corrigido);

      const unnormalizedArquivos = unnormalizedData?.arquivos || [];

      return {
        ...omit(unnormalizedData, ['ignorableFields']),
        corrigido: isCreate ? FollowUpUtils.CORRIGIDO : normalizedCorrigido,
        referencias: (unnormalizedData?.referencias || []).map((referenciaCliente) => referenciaCliente?.referencia),
        arquivosXmlDanfe: [
          ...(_.isArrayLikeObject(unnormalizedData?.arquivosXmlDanfe) ? unnormalizedData?.arquivosXmlDanfe : []),
          ...(_.isArrayLikeObject(unnormalizedArquivos) ? unnormalizedArquivos : []),
        ],
      };
    },
    [isCreate]
  );

  const unnormalize = useCallback((normalizedData) => {
    const unnormalizedArquivosXmlDanfe = isEmpty(normalizedData?.arquivosXmlDanfe)
      ? (normalizedData?.arquivos || [])
          .filter((arquivo) => TipoDocumentoUtils.isTipoDocumentoXmlDanfe(arquivo?.tipoDocumento?.code))
          .map((arquivo) => ({
            ...arquivo,
            path: arquivo?.documento?.nome,
          }))
      : normalizedData?.arquivosXmlDanfe;

    return {
      ...normalizedData,
      followUpSequencial: {
        ...normalizedData?.followUpSequencial,
        ...(normalizedData?.followUpSequencia?.servico
          ? {
              ...normalizedData?.followUpSequencial,
              servico: { id: normalizedData?.followUpSequencial?.servico?.id },
            }
          : {}),
      },
      corrigido: FollowUpUtils.unnormalizeCorrigidoFollowUp(normalizedData?.corrigido),
      referencias: (normalizedData?.referencias || []).map((referencia) => ({
        id: uuid(),
        referencia,
      })),
      arquivos: normalizedData?.arquivos,
      arquivosXmlDanfe: unnormalizedArquivosXmlDanfe,
      modeloFaturamento: {
        id: normalizedData?.modeloFaturamento?.id,
      },
      modeloFollowUp: {
        id: normalizedData?.modeloFollowUp?.id,
      },
      modeloNumerario: {
        id: normalizedData?.modeloNumerario?.id,
      },
      tabelaSda: {
        id: normalizedData?.tabelaSda?.id,
      },
      tipoDeclaracao: {
        id: normalizedData?.tipoDeclaracao?.id,
      },
      unidadesDeNegocio: normalizedData?.unidadesDeNegocio?.map((unidadeNegocio) => ({
        id: unidadeNegocio?.id,
      })),
      viaTransporte: {
        id: normalizedData?.viaTransporte?.id,
        code: normalizedData?.viaTransporte?.code,
      },
    };
  }, []);

  const parseToFormData = useCallback((data) => {
    const { arquivosXmlDanfe, ...restData } = data;
    const arquivosXmlDanfeMeta = [];

    const formData = new FormData();

    if (!isArrayLikeObject(restData?.arquivos)) {
      restData.arquivos = [];
    }

    const json = JSON.stringify(restData);
    const blob = new Blob([json], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append('data', blob);

    const meta = { metadata: arquivosXmlDanfeMeta };
    const jsonMeta = JSON.stringify(meta);
    const blobMeta = new Blob([jsonMeta], {
      type: MediaType.APPLICATION_JSON,
    });

    formData.append('metadata', blobMeta);

    return formData;
  }, []);

  const handleDispatchSetModel = useCallback(
    (data) => {
      const normalizedData = normalize(data);

      dispatch(followUpExportacaoActions.setModel(normalizedData));
    },
    [normalize]
  );

  const executeSuccessCreateFollowUp = useCallback(
    ({ created, response }) => {
      history.push(t('com.muralis.qcx.url.exportacaoFollowUpQuery', { numero: created?.numero }));

      dispatch(followUpExportacaoActions.resetAlreadyCreated());
      dispatch(followUpExportacaoActions.success());
      dispatch(followUpExportacaoActions.addToList({ data: created }));
      dispatch(
        followUpExportacaoActions.setResponse({
          status: response?.status,
          data: created,
          message: t('com.muralis.qcx.mensagem.followUpRegistrado', { numero: created?.numero }),
        })
      );
    },
    [history]
  );

  const processXmlDanfeList = useCallback(async ({ data, response }) => {
    try {
      if (_.isEmpty(data?.arquivosXmlDanfe)) {
        executeSuccessCreateFollowUp({
          created: data,
          response,
        });
        return;
      }

      const formData = FollowUpUtils.parseXmlDanfeListToFormData(data?.arquivosXmlDanfe);

      const processXmlResponse = await followUpAPI.processXml(data, formData);

      if (processXmlResponse?.status === 200) {
        executeSuccessCreateFollowUp({
          created: data,
          response: response || processXmlResponse,
        });
      }
    } catch (error) {
      dispatch(
        followUpExportacaoActions.changeToAlreadyCreated({
          id: data?.id,
          created: true,
        })
      );
      dispatch(
        followUpExportacaoActions.setModel({
          ...data,
          id: undefined,
        })
      );
      dispatch(followUpExportacaoActions.changeToBackgroundCreateMode());
      dispatch(followUpExportacaoActions.failure());
      dispatch(
        setErrorFeedback({
          message: t('com.muralis.qcx.erro.erroProcessarArquivosXML', {
            erro: error?.response?.data?.message,
          }),
        })
      );
    }
  }, []);

  const create = useCallback(
    async (data) => {
      const executeDebounced = debounce(async () => {
        try {
          let response;

          if (!followUpAlreadyCreated?.created) {
            const formData = parseToFormData(data);
            response = await followUpAPI.register(formData);
          }

          if (response?.status === 201 || followUpAlreadyCreated?.created) {
            const created = followUpAlreadyCreated?.created
              ? {
                  ...data,
                  id: followUpAlreadyCreated?.id,
                }
              : {
                  ...response?.data,
                  arquivosXmlDanfe: data?.arquivosXmlDanfe,
                };

            processXmlDanfeList({
              data: created,
              response,
            });
          }
        } catch (error) {
          dispatch(followUpExportacaoActions.setModel(data));
          dispatch(followUpExportacaoActions.changeToBackgroundCreateMode());

          const responseErrorMessage =
            error?.response && error?.response?.data && error?.response?.data?.message
              ? error?.response?.data?.message
              : undefined;

          if (FollowUpUtils.hasWarningFlag(responseErrorMessage)) {
            dispatch(followUpExportacaoActions.resetStatus());

            setWarning({
              flag: FollowUpUtils.WARNING_ON_CREATING,
              message: FollowUpUtils.getMessageWithoutFlag(responseErrorMessage),
            });

            return;
          }

          if (!responseErrorMessage) {
            dispatch(followUpExportacaoActions.failure());
            dispatch(
              setErrorFeedback({
                message: t('com.muralis.qcx.erro.erroRegistrarFollowUp'),
              })
            );

            return;
          }

          const fullErrorMessage = t('com.muralis.qcx.erro.erroRegistrarFollowUpEspecifico', {
            mensagem: responseErrorMessage,
          });

          dispatch(followUpExportacaoActions.failure());
          dispatch(
            setErrorFeedback({
              message: fullErrorMessage,
            })
          );
        }
      }, 500);

      dispatch(followUpExportacaoActions.loading());
      executeDebounced();
    },
    [history, followUp, followUpAlreadyCreated, parseToFormData]
  );

  const update = useCallback(
    async (data) => {
      const executeDebounced = debounce(async () => {
        try {
          const formData = parseToFormData(data);

          const response = await followUpAPI.save(formData);

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              handleChangeToConsult();
              dispatch(followUpExportacaoActions.success());

              const saved = response?.data;

              dispatch(
                followUpExportacaoActions.setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.followUpSalvo', { numero: data?.numero }),
                })
              );

              dispatch(followUpExportacaoActions.setModel(saved));
              dispatch(followUpExportacaoActions.updateOnList({ data: saved }));
              dispatch(followUpExportacaoThunks.fetchByIdAsync(saved?.id));
            }, 500);

            handleResultWithDebounce();
          }
        } catch (error) {
          const responseErrorMessage =
            error?.response && error?.response?.data && error?.response?.data?.message
              ? error?.response?.data?.message
              : undefined;

          if (!responseErrorMessage) {
            dispatch(followUpExportacaoActions.failure());
            dispatch(
              setErrorFeedback({
                message: t('com.muralis.qcx.erro.erroSalvarFollowUp'),
              })
            );

            return;
          }

          if (FollowUpUtils.hasWarningFlag(responseErrorMessage)) {
            dispatch(followUpExportacaoActions.resetStatus());
            dispatch(followUpExportacaoActions.changeToBackgroundUpdateMode());
            dispatch(followUpExportacaoActions.setModel(data));

            setWarning({
              flag: FollowUpUtils.WARNING_ON_UPDATING,
              message: FollowUpUtils.getMessageWithoutFlag(responseErrorMessage),
            });

            return;
          }

          const fullErrorMessage = t('com.muralis.qcx.erro.erroSalvarFollowUpEspecifico', {
            mensagem: responseErrorMessage,
          });

          dispatch(followUpExportacaoActions.failure());
          dispatch(
            setErrorFeedback({
              message: fullErrorMessage,
            })
          );
        }
      }, 500);

      dispatch(followUpExportacaoActions.loading());
      executeDebounced();
    },
    [handleChangeToConsult, parseToFormData]
  );

  const handleSubmit = useCallback(
    async (data) => {
      const normalizedData = normalize(data);

      if ((isUpdate && !isBackgroundCreate) || isBackgroundUpdate) {
        await update(normalizedData);

        return;
      }

      if (isCreate || isBackgroundCreate) {
        await create(normalizedData);
      }
    },
    [isCreate, isUpdate, isBackgroundCreate, isBackgroundUpdate, normalize, update, create]
  );

  const [handleAuthorizeOperationMiddleware, operationOfAuthorizeById] = useOperationMiddleware(
    async (data) => {
      await handleSubmit(data);

      dispatch(followUpExportacaoActions.resetBackgroundMode());
    },
    [handleSubmit]
  );

  const configureAuthorizeOperation = useCallback(
    (options) => {
      const handleConfigureOnClick = () => {
        const validatedObjectRule = FollowUpUtils.getValidatedResultIfPendingBy(options?.message);

        const configureOperation = () => ({
          options: {
            title: t('com.muralis.qcx.avisoOperacao').toUpperCase(),
            message: t('com.muralis.qcx.mensagem.mensagemContinuarOperacao', {
              mensagem: validatedObjectRule?.message,
            }),
          },
        });

        const dataOfAuthorizedOperation = {
          ...followUp,
          ...validatedObjectRule?.result,
        };

        handleAuthorizeOperationMiddleware(configureOperation, dataOfAuthorizedOperation);
      };

      return handleConfigureOnClick;
    },
    [followUp, handleAuthorizeOperationMiddleware]
  );

  const model = useMemo(() => {
    if (isCreate && !(isBackgroundCreate || isBackgroundUpdate)) {
      return {
        numero: followUp?.numero,
        numeroConhecimentoMasterValido: FollowUpUtils.CONFIRMACAO_REGRA_NAO_VALIDADO,
        numeroConhecimentoHouseValido: FollowUpUtils.CONFIRMACAO_REGRA_NAO_VALIDADO,
        restricaoFinanceiraValido: FollowUpUtils.CONFIRMACAO_REGRA_NAO_VALIDADO,
        vencimentoRadarValido: FollowUpUtils.CONFIRMACAO_REGRA_NAO_VALIDADO,
        vencimentoProcuracaoValido: FollowUpUtils.CONFIRMACAO_REGRA_NAO_VALIDADO,
      };
    }

    return unnormalize(followUp);
  }, [isCreate, isBackgroundCreate, isBackgroundUpdate, followUp, unnormalize]);

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return t('com.muralis.qcx.acoes.novo');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloExportacao'),
          name: t('com.muralis.qcx.exportacao.label'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.exportacaoFollowUp'),
          name: t('com.muralis.qcx.followUpExportacao.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.registro'),
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [isCreate, actionName]
  );

  const pageTitle = useMemo(
    () =>
      isNone || isCreate || isBackgroundCreate
        ? t('com.muralis.qcx.followUpExportacao.novoRegistroFollowUpNumero', {
            numero: model?.numero ? model?.numero : '',
          })
        : t('com.muralis.qcx.followUpExportacao.followUpNumero', {
            numero: model?.numero || '-',
          }),
    [isNone, isCreate, isBackgroundCreate, model]
  );

  useAutoChangeMode((currentMode) => dispatch(followUpExportacaoActions.changeModeTo(currentMode)), [isUpdate]);

  useEffect(() => {
    const configureDependencies = () => {
      dispatch(clienteActions.changeControlTo(IDLE_CONTROL));
      dispatch(servicoActions.changeControlTo(IDLE_CONTROL));
    };

    configureDependencies();

    return () => {
      dispatch(clienteActions.resetControl());
      dispatch(servicoActions.resetControl());
      dispatch(followUpExportacaoActions.resetAlreadyCreated());
    };
  }, []);

  useEffect(() => {
    const handleFetchById = () => {
      if (!isEmpty(params?.id)) {
        fetchById(params?.id);

        handleChangeToConsult();
      }
    };

    handleFetchById();
  }, [params, handleChangeToConsult]);

  useEffect(() => {
    if (FollowUpUtils.isWarningOperation(warning?.flag)) {
      const dispatchAuthorizationForOperation = configureAuthorizeOperation({
        message: warning?.message,
      });

      setWarning({
        flag: FollowUpUtils.NOOP_WARNING,
        message: undefined,
      });

      dispatchAuthorizationForOperation();
    }
  }, [warning, setWarning, configureAuthorizeOperation]);

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isIdle={isIdle}
      isLoading={isLoading}
      isFailure={isFailure}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isLocked={isLocked}
      isPreparingAction={isPreparingAction}
      isBackgroundCreate={isBackgroundCreate}
      isBackgroundDelete={isBackgroundDelete}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToBackgroundDelete={handleChangeToBackgroundDelete}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      authInfo={authInfo}
    >
      {(formProps) => (
        <>
          <QCXFollowUpExportacaoFinalForm
            initialValues={model}
            handleChangeModel={handleDispatchSetModel}
            handleSubmit={handleSubmit}
            authInfo={authInfo}
            requiredRoles={['exportacao-follow-up']}
            {...formProps}
          />
          <QCXConfirmDialog
            key="confirm-dialog-confirm-warning-follow-up"
            id="confirm-dialog-confirm-warning-follow-up"
            open={operationOfAuthorizeById?.active}
            title={operationOfAuthorizeById?.title}
            content={operationOfAuthorizeById?.message}
            endContent={operationOfAuthorizeById?.endMessage}
            onConfirm={operationOfAuthorizeById.confirm}
            onClose={operationOfAuthorizeById?.reset}
            buttonGroupOptions={{
              confirm: {
                description: t('com.muralis.qcx.acoes.continuar'),
              },
              cancel: {
                description: t('com.muralis.qcx.acoes.cancelar'),
              },
            }}
          />
          <QCXConfirmDialog
            open={operationOfCancelUpdate?.active}
            title={operationOfCancelUpdate?.title}
            content={operationOfCancelUpdate?.message}
            endContent={operationOfCancelUpdate?.endMessage}
            onConfirm={operationOfCancelUpdate.confirm}
            onClose={operationOfCancelUpdate?.reset}
            buttonGroupOptions={{
              confirm: {
                description: t('com.muralis.qcx.expressao.sim'),
              },
              cancel: {
                description: t('com.muralis.qcx.expressao.sim'),
              },
            }}
          />
        </>
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
