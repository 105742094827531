import { useMemo } from 'react';
import { CorrespondenciasListItem, ICorrelation } from '../totvs.types';
import { ILeituraError, LeituraErrorFields } from './totvsLeituraPage.types';
import { TXTResult } from './utils/processTXTFile';

export const useVerifyCorrelacaoImportador = (
  txtData: TXTResult | undefined,
  correspondencias: CorrespondenciasListItem[] | undefined,
  limparExportadorFabricante: boolean | undefined
): [ILeituraError[], ILeituraError[], TXTResult | undefined] => {
  const result = useMemo((): [ILeituraError[], ILeituraError[], TXTResult | undefined] => {
    let result: TXTResult = { ...txtData };
    let warnings: ILeituraError[] = [];
    let errors: ILeituraError[] = [];

    if (!result?.faturas || !correspondencias) return [errors, warnings, result];

    Object.keys(result.faturas).forEach((key) => {
      const importador = result.itdi?.cnpj;
      // Descomente abaixo em caso de código ao invés de CNPJ
      // const importador =
      //   result.faturas?.[key as keyof TXTResult['faturas']].codigoImportador;
      const correspondencia = correspondencias.find((item) => item.totvsId == importador);

      if (!correspondencia) {
        errors.push({
          tipo: 'Erro',
          origem: 'Correlação',
          mensagem: `Correlação de Importador CNPJ ${importador} não encontrada`,
        });
      } else {
        result.faturas![key as keyof TXTResult['faturas']].codigoImportador = correspondencia.quickcomexId;

        const codigoExportador = result.faturas?.[key as keyof TXTResult['faturas']].codigoExportador;
        const codigoFabricante = result.faturas?.[key as keyof TXTResult['faturas']].codigoFabricante;
        const codigoTransportadorInternacional =
          result.faturas?.[key as keyof TXTResult['faturas']].codigoTransportadorInternacional;
        const codigoLocalEmbarque = result.faturas?.[key as keyof TXTResult['faturas']].codigoLocalEmbarque;
        const codigoAgenteCarga = result.faturas?.[key as keyof TXTResult['faturas']].codigoAgenteCarga;
        const codigoLocalIncoterm = result.faturas?.[key as keyof TXTResult['faturas']].codigoLocalIncoterm;

        if (!codigoExportador) {
          warnings.push({
            tipo: 'Aviso',
            origem: 'Fatura',
            campo: LeituraErrorFields.Exportador,
            fatura: key,
            mensagem: `Exportador não informado na fatura ${key}. Certifique-se de que o campo "Cód. Exportador" está preenchido em ao menos um item da fatura.`,
          });
        } else {
          const correspondenciaExportador = correspondencia.correspondenciasGerais.find(
            (item) => item.tipo == 'Exportador' && item.totvsId == codigoExportador
          );
          if (!correspondenciaExportador) {
            errors.push({
              tipo: 'Erro',
              origem: 'Fatura',
              campo: LeituraErrorFields.Exportador,
              fatura: key,
              mensagem: `Correspondência de Exportador ${codigoExportador} não encontrado`,
            });
          } else {
            result.faturas![key as keyof TXTResult['faturas']].codigoExportador =
              correspondenciaExportador.quickcomexId;
          }
        }

        if (!codigoFabricante) {
          warnings.push({
            tipo: 'Aviso',
            origem: 'Fatura',
            campo: LeituraErrorFields.Fabricante,
            fatura: key,
            mensagem: `Fabricante não informado na fatura ${key}. Certifique-se de que o campo "Cód. Fabricante" está preenchido em ao menos um item da fatura.`,
          });
        } else {
          const correspondenciaFabricante = correspondencia.correspondenciasGerais.find(
            (item) => item.tipo == 'Fabricante' && item.totvsId == codigoFabricante
          );
          if (!correspondenciaFabricante) {
            errors.push({
              tipo: 'Erro',
              origem: 'Fatura',
              campo: LeituraErrorFields.Fabricante,
              fatura: key,
              mensagem: `Correspondência de Fabricante ${codigoFabricante} não encontrado`,
            });
          } else {
            result.faturas![key as keyof TXTResult['faturas']].codigoFabricante =
              correspondenciaFabricante.quickcomexId;
          }
        }

        if (!codigoTransportadorInternacional) {
          warnings.push({
            tipo: 'Aviso',
            origem: 'Fatura',
            campo: LeituraErrorFields['Transportador Internacional'],
            fatura: key,
            mensagem: `Transportador Internacional não informado na fatura ${key}`,
          });
        } else {
          const correspondenciaTransportadorInternacional = correspondencia.correspondenciasGerais.find(
            (item) => item.tipo == 'Transportador Internacional' && item.totvsId == codigoTransportadorInternacional
          );
          if (!correspondenciaTransportadorInternacional) {
            errors.push({
              tipo: 'Erro',
              origem: 'Fatura',
              campo: LeituraErrorFields['Transportador Internacional'],
              fatura: key,
              mensagem: `Correspondência de Transportador Internacional ${codigoTransportadorInternacional} não encontrado`,
            });
          } else {
            result.faturas![key as keyof TXTResult['faturas']].codigoTransportadorInternacional =
              correspondenciaTransportadorInternacional.quickcomexId;
          }
        }

        if (!codigoLocalEmbarque) {
          warnings.push({
            tipo: 'Aviso',
            origem: 'Fatura',
            campo: LeituraErrorFields['Local de Embarque'],
            fatura: key,
            mensagem: `Local de Embarque não informado na fatura ${key}`,
          });
        } else {
          const correspondenciaLocalEmbarque = correspondencia.correspondenciasGerais.find(
            (item) => item.tipo == 'Local de Embarque' && item.totvsId == codigoLocalEmbarque
          );
          if (!correspondenciaLocalEmbarque) {
            errors.push({
              tipo: 'Erro',
              origem: 'Fatura',
              campo: LeituraErrorFields['Local de Embarque'],
              fatura: key,
              mensagem: `Correspondência de Local de Embarque ${codigoLocalEmbarque} não encontrado`,
            });
          } else {
            result.faturas![key as keyof TXTResult['faturas']].codigoLocalEmbarque =
              correspondenciaLocalEmbarque.quickcomexId;
          }
        }

        if (!codigoAgenteCarga) {
          warnings.push({
            tipo: 'Aviso',
            origem: 'Fatura',
            campo: LeituraErrorFields['Agente de Carga'],
            fatura: key,
            mensagem: `Agente de Carga não informado na fatura ${key}`,
          });
        } else {
          const correspondenciaAgenteCarga = correspondencia.correspondenciasGerais.find(
            (item) => item.tipo == 'Agente de Carga' && item.totvsId == codigoAgenteCarga
          );
          if (!correspondenciaAgenteCarga) {
            errors.push({
              tipo: 'Erro',
              origem: 'Fatura',
              campo: LeituraErrorFields['Agente de Carga'],
              fatura: key,
              mensagem: `Correspondência de Agente de Carga ${codigoAgenteCarga} não encontrado`,
            });
          } else {
            result.faturas![key as keyof TXTResult['faturas']].codigoAgenteCarga =
              correspondenciaAgenteCarga.quickcomexId;
          }
        }

        if (!codigoLocalIncoterm) {
          warnings.push({
            tipo: 'Aviso',
            origem: 'Fatura',
            campo: LeituraErrorFields['Local do Incoterm'],
            fatura: key,
            mensagem: `Local do Incoterm não informado na fatura ${key}`,
          });
        } else {
          const correspondenciaIncoterm = correspondencia.correspondenciasGerais.find(
            (item) => item.tipo == 'Local do Incoterm' && item.totvsId == codigoLocalIncoterm
          );
          if (!correspondenciaIncoterm) {
            errors.push({
              tipo: 'Erro',
              origem: 'Fatura',
              campo: LeituraErrorFields['Local do Incoterm'],
              fatura: key,
              mensagem: `Correspondência de Local do Incoterm ${codigoLocalIncoterm} não encontrado`,
            });
          } else {
            result.faturas![key as keyof TXTResult['faturas']].codigoLocalIncoterm =
              correspondenciaIncoterm.quickcomexId;
          }
        }
      }

      const itens = result.faturas?.[key as keyof TXTResult['faturas']].itens;

      itens?.forEach((item) => {
        if (!item.codigoExportador) {
          const correspondenciaAviso = warnings.find(
            (warning) => warning.origem === 'Fatura' && warning.campo === LeituraErrorFields.Exportador
          );
          if (!correspondenciaAviso) {
            errors.push({
              tipo: 'Erro',
              origem: 'Item',
              campo: LeituraErrorFields.Exportador,
              fatura: key,
              item: item.codigoDoProduto,
              mensagem: `Exportador não informado na capa da fatura e em nenhum item`,
            });
          }
        } else {
          warnings = warnings.filter((warning) => warning.campo !== LeituraErrorFields.Exportador);
          const correspondenciaExportador = correspondencia?.correspondenciasGerais.find(
            (mercadoria) => mercadoria.tipo == 'Exportador' && mercadoria.totvsId == item.codigoExportador
          );
          if (limparExportadorFabricante) {
            item.codigoExportador = null;
          } else if (!correspondenciaExportador) {
            errors.push({
              tipo: 'Erro',
              origem: 'Fatura',
              campo: LeituraErrorFields.Exportador,
              fatura: key,
              item: item.codigoDoProduto,
              mensagem: `Correspondência de Exportador ${item.codigoExportador} não encontrado`,
            });
          } else {
            item.codigoExportador = correspondenciaExportador.quickcomexId;
          }
        }

        if (!item.codigoFabricante) {
          const correspondenciaAviso = warnings.find(
            (warning) => warning.origem === 'Fatura' && warning.campo === LeituraErrorFields.Fabricante
          );
          if (!correspondenciaAviso) {
            errors.push({
              tipo: 'Erro',
              origem: 'Item',
              campo: LeituraErrorFields.Fabricante,
              mensagem: `Fabricante não informado na capa da fatura e em nenhum item. (Fatura ${key})`,
            });
          }
        } else {
          warnings = warnings.filter((warning) => warning.campo !== LeituraErrorFields.Fabricante);
          const correspondenciaFabricante = correspondencia?.correspondenciasGerais.find(
            (mercadoria) => mercadoria.tipo == 'Fabricante' && mercadoria.totvsId == item.codigoFabricante
          );
          if (limparExportadorFabricante) {
            item.codigoFabricante = null;
          } else if (!correspondenciaFabricante) {
            errors.push({
              tipo: 'Erro',
              origem: 'Fatura',
              campo: LeituraErrorFields.Fabricante,
              fatura: key,
              item: item.codigoDoProduto,
              mensagem: `Correspondência de Fabricante ${item.codigoFabricante} não encontrado`,
            });
          } else {
            item.codigoFabricante = correspondenciaFabricante.quickcomexId;
          }
        }

        if (!item.codigoDispositivoLegalDeExoneracaoDoIcms) {
          const correspondenciaAviso = warnings.find(
            (warning) =>
              warning.origem === 'Fatura' &&
              warning.campo === LeituraErrorFields['Dispositivo Legal de Exoneração do ICMS']
          );
          if (!correspondenciaAviso) {
            warnings.push({
              tipo: 'Aviso',
              origem: 'Item',
              campo: LeituraErrorFields['Dispositivo Legal de Exoneração do ICMS'],
              fatura: key,
              item: item.codigoDoProduto,
              mensagem: `Dispositivo Legal de Exoneração do ICMS não informado na capa da fatura e em nenhum item. (Fatura ${key})`,
            });
          }
        } else {
          warnings = warnings.filter(
            (warning) => warning.campo !== LeituraErrorFields['Dispositivo Legal de Exoneração do ICMS']
          );
          const correspondenciaDispositivoLegal = correspondencia?.correspondenciasGerais.find(
            (mercadoria) =>
              mercadoria.tipo == 'Dispositivo Legal de Exoneração do ICMS' &&
              mercadoria.totvsId == item.codigoDispositivoLegalDeExoneracaoDoIcms
          );
          if (!correspondenciaDispositivoLegal) {
            errors.push({
              tipo: 'Erro',
              origem: 'Fatura',
              campo: LeituraErrorFields['Dispositivo Legal de Exoneração do ICMS'],
              fatura: key,
              item: item.codigoDoProduto,
              mensagem: `Correspondência de Dispositivo Legal de Exoneração do ICMS ${item.codigoDispositivoLegalDeExoneracaoDoIcms} não encontrado`,
            });
          } else {
            item.codigoDispositivoLegalDeExoneracaoDoIcms = correspondenciaDispositivoLegal.quickcomexId;
          }
        }

        if (!item.codigoUnidadeDeMedida) {
          const correspondenciaAviso = warnings.find(
            (warning) => warning.origem === 'Fatura' && warning.campo === LeituraErrorFields['Unidade de Medida']
          );
          if (!correspondenciaAviso) {
            warnings.push({
              tipo: 'Aviso',
              origem: 'Item',
              campo: LeituraErrorFields['Unidade de Medida'],
              fatura: key,
              item: item.codigoDoProduto,
              mensagem: `Unidade de Medida não informado na capa da fatura e em nenhum item. (Fatura ${key})`,
            });
          }
        } else {
          warnings = warnings.filter((warning) => warning.campo !== LeituraErrorFields['Unidade de Medida']);

          const correspondenciaUnidadeDeMedida = correspondencia?.correspondenciasGerais.find(
            (mercadoria) => mercadoria.tipo == 'Unidade de Medida' && mercadoria.totvsId == item.codigoUnidadeDeMedida
          );

          if (!correspondenciaUnidadeDeMedida) {
            errors.push({
              tipo: 'Erro',
              origem: 'Fatura',
              campo: LeituraErrorFields['Unidade de Medida'],
              fatura: key,
              item: item.codigoDoProduto,
              mensagem: `Correspondência de Unidade de Medida ${item.codigoUnidadeDeMedida} não encontrado`,
            });
          } else {
            item.codigoUnidadeDeMedida = correspondenciaUnidadeDeMedida.quickcomexId;
          }
        }
      });
    });

    errors = errors.filter((error, index, self) => index === self.findIndex((t) => t.mensagem === error.mensagem));

    errors = errors.sort((a, b) => {
      const messageA = a.mensagem.toUpperCase();
      const messageB = b.mensagem.toUpperCase();
      if (messageA < messageB) {
        return -1;
      }
      if (messageA > messageB) {
        return 1;
      }
      return 0;
    });

    return [errors, warnings, result];
  }, [txtData, correspondencias]);
  return result;
};
