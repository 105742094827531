import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Grid } from '@material-ui/core';
import { Redo as RedoIcon } from '@material-ui/icons';
import { useForm, useFormState } from 'react-final-form';
import { get, isEmpty, isFunction } from 'lodash';
import QCXSimpleSelectManyPageTemplate from '../../templates/simple-select-many-page/QCXSimpleSelectManyPageTemplate';

const DEFAULT_META_LIST_NAME = 'meta.selected[]';

export default function QCXFinalFormDataReplicator({
  isConsult,
  dataGridProps = {},
  hideControlButtons = false,
  tableProps,
  defaultMetaListName = DEFAULT_META_LIST_NAME,
  onReset,
  replicated,
  handleSetReplicated,
  clearSelection = false,
  children,
  formatSelection,
  isPaginated = false,
  ...restProps
}) {
  const form = useForm();
  const { values } = useFormState();

  const selectedItems = useMemo(() => {
    if (replicated) {
      return [];
    }

    const sanitizedMetaListName = defaultMetaListName.replace(
      /\[\]+/g,
      ''
    );

    return get(
      values,
      sanitizedMetaListName
    ) || [];
  }, [
    values,
    replicated,
    defaultMetaListName,
  ]);

  const isEmptySelection = useMemo(() => (
    isEmpty(selectedItems)
  ), [selectedItems]);

  const handleChangeSelected = useCallback((selected) => {
    form.change(defaultMetaListName, selected);
  }, [
    form,
    defaultMetaListName,
  ]);

  const controlButtons = useMemo(
    () =>
      hideControlButtons
        ? []
        : [
            {
              description: 'Repassar',
              type: 'submit',
              color: 'secondary',
              startIcon: <RedoIcon />,
              tooltip: {
                description: 'Repassar Dados',
              },
              disabled: isEmptySelection,
            },
          ],
    [isEmptySelection, hideControlButtons]
  );

  useEffect(() => {
    if (replicated) {
      if (isFunction(onReset)) {
        onReset(form);
      }

      handleSetReplicated(false);
    }
  }, [
    form,
    onReset,
    replicated,
    handleSetReplicated,
  ]);

  return (
    <Grid
      item
      container
    >
      <QCXSimpleSelectManyPageTemplate
        title={tableProps?.title}
        tableColumns={tableProps?.columns}
        tableData={tableProps?.data}
        isConsult={isConsult}
        handleSetSelected={handleChangeSelected}
        selected={selectedItems}
        controlButtons={controlButtons}
        hideControlButtons={hideControlButtons}
        clearSelection={clearSelection || replicated}
        multipleFilter
        disableFullObject
        formatSelection={formatSelection}
        isPaginated={isPaginated}
        dataGridProps={{
          ...dataGridProps,
          ...tableProps,
        }}
        {...restProps}
      />
      <Grid
        item
        xs={12}
      >
        {children}
      </Grid>
    </Grid>
  );
}
