import { Grid, Typography } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import QCXFinalSelectgGeneroPessoaAutocomplete from '../../shared-components/select-genero-pessoa/QCXFinalSelectGeneroPessoaAutocomplete';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXRegistrationOneToManyPageTemplate from '../../templates/registration-one-to-many-page/QCXRegistrationOneToManyPageTemplate';
import { formatTelefoneCelular, formatTelefoneFixo } from '../../utils/hooks/form/field/formatters';
import { parseTelefoneCelular, parseTelefoneFixoWithDDD } from '../../utils/hooks/form/field/parsers';
import {
  configureNumberNotBetweenMinAndMaxValidator,
  isCurrentOrPastDate,
  required as requiredValidator,
  validTelefoneCelularLength,
  validateEmail,
} from '../../utils/validators/field/validator';

import useValidators from '../../utils/hooks/useValidators';

import {
  addContato,
  changeToSubCreateMode,
  changeToSubConsultMode,
  updateContato,
  resetSubMode,
  resetRelatedContatoModel,
  setRelatedContatoModel,
  changeToSubUpdateMode,
  selectRelatedContatoModel,
  removeContatoById,
} from '../../features/transportadora/transportadoraSlice';

export default function QCXContatoTransportadoraForm({
  isConsult,
  isUpdate,
  isSubNone,
  isSubCreate,
  isSubConsult,
  isSubUpdate,
  initialValues,
  list,
  controlComponentProps,
  authInfo = {},
  requiredRoles = [],
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const contatoTransportadora = useSelector(selectRelatedContatoModel);

  const celularValidators = useMemo(
    () =>
      useValidators([
        validTelefoneCelularLength,
        configureNumberNotBetweenMinAndMaxValidator(9, 11),
        configureNumberNotBetweenMinAndMaxValidator(11, 13),
      ]),
    []
  );

  const validateEmailLocal = (value) => {
    const emailPattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    
    if (!value) {
      return undefined;
    }

    if (emailPattern.test(value)) {
      return undefined;
    }
  
    return t('com.muralis.qcx.validacao.emailInformadoValido');
  };

  const emailValidators = useMemo(() => useValidators([validateEmailLocal]), []);

  const tableProps = useMemo(
    () => ({
      title: t('com.muralis.qcx.contato.contatosAdicionados'),
      columns: [
        {
          field: 'dadosPessoais.nome',
          headerName: t('com.muralis.qcx.nome'),
          width: 300,
          valueGetter: (params) => params?.row?.dadosPessoais?.nome,
        },
        {
          field: 'departamento',
          headerName: t('com.muralis.qcx.departamento'),
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          valueGetter: ({ row }) => row?.departamento || '-',
        },
        {
          field: 'cargo',
          headerName: t('com.muralis.qcx.cargo'),
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          valueGetter: ({ row }) => row?.cargo || '-',
        },
        {
          field: 'contato.telefone',
          headerName: t('com.muralis.qcx.telefone'),
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          valueFormatter: (params) => params?.row?.contato?.telefone || '-',
        },
        {
          field: 'contato.email',
          headerName: t('com.muralis.qcx.email'),
          headerAlign: 'center',
          align: 'center',
          flex: 2,
          valueGetter: (params) => params?.row?.contato?.email,
        },
      ],
    }),
    []
  );

  const localeTableText = useMemo(
    () => ({
      noRowsLabel: t('com.muralis.qcx.nenhumContatoAdicionado'),
    }),
    []
  );

  /* HANDLERS */

  const handleNewContato = () => {
    dispatch(changeToSubCreateMode());
  };

  const handleCancelContatoCreate = () => {
    dispatch(resetSubMode());
  };

  const handleCancelContatoUpdate = () => {
    dispatch(changeToSubConsultMode());
  };

  const handleClearContatoForm = () => {
    dispatch(resetRelatedContatoModel());
    dispatch(resetSubMode());
  };

  const handleRemoveContato = () => {
    dispatch(removeContatoById({ data: contatoTransportadora }));
    handleClearContatoForm();
  };

  const handleContatoSubmit = (data) => {
    if (isSubCreate) {
      dispatch(addContato({ data }));
      handleClearContatoForm();
    } else if (isSubUpdate) {
      dispatch(updateContato({ data }));
      dispatch(changeToSubConsultMode());
    }
  };

  const handleContatoRowClick = ({ row }) => {
    if (!isSubUpdate && !isSubCreate && (contatoTransportadora?.id !== row?.id)) {
      dispatch(setRelatedContatoModel({
        ...row,
        contato: {
          ...row?.contato,
          telefone: formatTelefoneFixo(row?.contato?.telefone),
          celular: formatTelefoneCelular(row?.contato?.celular),
        },
      }));
    }
  };

  const handleEditContato = () => {
    dispatch(changeToSubUpdateMode());
  };


  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleContatoSubmit}
      controlComponentProps={controlComponentProps}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
    >
      {({ form, submitSucceeded, values }) => (
        <QCXRegistrationOneToManyPageTemplate
          title={t('com.muralis.qcx.contato.label')}
          tableProps={tableProps}
          tableData={list}
          isConsult={isConsult}
          isUpdate={isUpdate}
          isSubNone={isSubNone}
          isSubCreate={isSubCreate}
          isSubConsult={isSubConsult}
          isSubUpdate={isSubUpdate}
          submitSucceeded={submitSucceeded}
          handleNew={handleNewContato}
          handleEdit={handleEditContato}
          handleCancelCreate={handleCancelContatoCreate}
          handleCancelUpdate={handleCancelContatoUpdate}
          handleRemove={handleRemoveContato}
          handleClearForm={handleClearContatoForm}
          handleRestartForm={() =>
            form.restart({
              contato: {
                telefone: '',
                celular: '',
              },
            })
          }
          handleRowClick={handleContatoRowClick}
          localeTableText={localeTableText}
        >
          <Grid item xs={12} sm={6} md={6}>
            <QCXFinalTextField
              label={t('com.muralis.qcx.contato.nomeContato')}
              id="text-field-nome"
              key="text-field-nome"
              name="dadosPessoais.nome"
              disabled={isConsult || isSubConsult || isSubNone}
              maxLength={40}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <QCXFinalTextField
              label={t('com.muralis.qcx.nomeResumido')}
              id="text-field-nomeResumido"
              key="text-field-nomeResumido"
              name="dadosPessoais.nomeResumido"
              disabled={isConsult || isSubConsult || isSubNone}
              maxLength={20}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <QCXFinalSelectgGeneroPessoaAutocomplete
              id="select-field-genero"
              key="select-field-genero"
              name="genero"
              label={t('com.muralis.qcx.genero.label')}
              initialValues={values}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <QCXFinalTextField
              label={t('com.muralis.qcx.saudacao.label')}
              id="text-field-saudacao"
              key="text-field-saudacao"
              name="saudacao"
              placeholder={t('com.muralis.qcx.saudacao.exemploFormaTratamento')}
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <QCXFinalDatePickerField
              id="date-picker-dataNascimento"
              key="text-field-dataNascimento"
              name="dadosPessoais.dataNascimento"
              label={t('com.muralis.qcx.dataNascimento')}
              validate={isCurrentOrPastDate}
              disabled={isConsult || isSubConsult || isSubNone}
              disableFuture
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <QCXFinalOnblurMaskedField
              id="text-field-telefone"
              key="text-field-telefone"
              name="contato.telefone"
              label={t('com.muralis.qcx.telefone')}
              format={formatTelefoneFixo}
              parse={parseTelefoneFixoWithDDD}
              maxLength={12}
              disabled={isConsult || isSubConsult || isSubNone}
              submitSucceeded={submitSucceeded}
              required={values?.configuracoes?.responsavelIcms}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <QCXFinalOnblurMaskedField
              id="text-field-celular"
              key="text-field-celular"
              name="contato.celular"
              label={t('com.muralis.qcx.celular')}
              format={formatTelefoneCelular}
              parse={parseTelefoneCelular}
              maxLength={13}
              disabled={isConsult || isSubConsult || isSubNone}
              submitSucceeded={submitSucceeded}
              validate={celularValidators}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <QCXFinalTextField
              id="text-field-email"
              key="text-field-email"
              label={t('com.muralis.qcx.email')}
              name="contato.email"
              validate={emailValidators}
              maxLength={100}
              required
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={4}>
            <QCXFinalTextField
              label={t('com.muralis.qcx.departamento')}
              id="text-field-departamento"
              key="text-field-departamento"
              name="departamento"
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <QCXFinalTextField
              label={t('com.muralis.qcx.cargo')}
              id="text-field-cargo"
              key="text-field-cargo"
              name="cargo"
              disabled={isConsult || isSubConsult || isSubNone}
            />
          </Grid>

        </QCXRegistrationOneToManyPageTemplate>
      )}
    </QCXFormStepWizard>
  );
}
