import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import { TipoProdutoUtils } from './tipoProdutoUtils';

export default function QCXFinalSelectTipoProdutoAutocomplete({ label, name, initialValues, ...restProps }) {
  const { t } = useTranslation();
  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = TipoProdutoUtils.LIST.find((item) => item?.value === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue('');
    }
  }, [initialValues]);

  const filterOptions = useMemo(
    () =>
      createFilterOptions({
        stringify: (option) => option.label + option.value,
      }),
    []
  );

  const getOptionLabel = useCallback((item) => t(item?.label) || '', []);

  const getOptionValue = useCallback((item) => item?.value || '', []);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={TipoProdutoUtils.LIST}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      {...restProps}
    />
  );
}
