import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchCatalogoProdutosByFilterAsync,
  resetCatalogoProdutos,
} from '../../../../../../features/catalogo-produtos/catalogoProdutosSlice';
import QCXActionsManagerPageTemplate from '../../../../../../templates/actions-manager-page/QCXActionsManagerPageTemplate';
import CatalogoProdutosPartnumberDataReplicatorForm from './components/CatalogoProdutosPartnumberDataReplicatorForm';
import CatalogoProdutosPartnumberFiltroForm from './components/CatalogoProdutosPartnumberFiltroForm';
import breadcrumbs from './constants/breadcrumbs';
import { CatalogoProdutosPartnumberFiltroFormValues } from './types/CatalogoProdutosPartnumberFiltroFormValues';
import CatalogoProdutosContext from '../../../../../../contexts/registrations/catalogo-produtos/CatalogoProdutosContext';
import { isLoadingStatus } from '../../../../../../utils/store/store-utils';
import { DataGridControlConfigChange } from './types/DataGridControlConfigChange';
import CatalogoProdutosTransmitirModal from './components/CatalogoProdutosTransmitirModal';
import useTransmitirModal from './hooks/useTransmitirModal.hook';
import { CatalogoProdutosAtributosFormValues } from './types/CatalogoProdutosAtributosFormValues';
import getISODateOrTodaysISODate from '../utils/getISODateOrTodaysISODate';

type FetchCatalogosProdutosPageFilters = {
  page: number;
  pageSize: number;
  partnumber?: string;
};

export default function CatalogoProdutosFiltroRepassePage({ authInfo = {} }) {
  const dispatch = useDispatch();
  const { isModalOpen, openModal, closeModal, catalogoState, handleCatalogoStateChange, handleConfirm } =
    useTransmitirModal();

  const { status } = useContext(CatalogoProdutosContext);
  const isLoading = useMemo(() => isLoadingStatus(status), [status]);

  const [selectionIds, setSelectionIds] = useState<number[]>([]);
  const [selectedNcm, setSelectedNcm] = useState<CatalogoProdutosPartnumberFiltroFormValues['ncm']>(null);
  const [filter, setFilter] = useState<CatalogoProdutosPartnumberFiltroFormValues>({});

  const fetchCatalogosProdutos = useCallback(
    ({ page, pageSize, partnumber }: FetchCatalogosProdutosPageFilters) => {
      const startDate = getISODateOrTodaysISODate(filter.insertionDateRangeStart);
      const endDate = getISODateOrTodaysISODate(filter.insertionDateRangeEnd);
      const dateRange =
        filter.insertionDateRangeStart || filter.insertionDateRangeEnd ? `${startDate};${endDate}` : undefined;
      const normalizedTags = filter.tags?.reduce((acc, prev) => `${prev}~${acc}`, '');
      const statusFilters = filter.statusFilters?.map((s) => s.value) ?? [];
      const partnumberParam = (filter.partnumbersTags?.join(';') || partnumber) ?? '';

      dispatch(
        fetchCatalogoProdutosByFilterAsync([
          { name: 'page', value: page },
          { name: 'size', value: pageSize },
          { name: 'ncm', value: filter.ncm?.id },
          { name: 'partnumber', value: partnumberParam },
          { name: 'insertionDateRange', value: dateRange },
          { name: 'clienteId', value: filter.cliente?.id },
          { name: 'tags', value: normalizedTags },
          { name: 'statuses', value: statusFilters },
        ])
      );
    },
    [filter]
  );

  useEffect(() => {
    dispatch(resetCatalogoProdutos());
  }, []);

  const handleConfigChange = useCallback(
    ({ currentPage, pageSize, filterInputValue }: DataGridControlConfigChange) => {
      if (Object.keys(filter).length === 0) return;

      fetchCatalogosProdutos({ page: currentPage, pageSize, partnumber: filterInputValue });
    },
    [fetchCatalogosProdutos, filter]
  );

  const handleTransmitirSubmit = (values: CatalogoProdutosAtributosFormValues) => {
    setSelectionIds(values?.meta?.selected?.map((s) => s.id) ?? []);

    openModal();
  };

  return (
    <QCXActionsManagerPageTemplate
      pageTitle="Repassar e transmitir"
      breadcrumbs={breadcrumbs}
      breadcrumbsProps={{ maxItems: 6 }}
      authInfo={authInfo}
      isLoading={isLoading}
      isUpdate={undefined}
      isConsult={undefined}
    >
      {() => (
        <>
          <CatalogoProdutosPartnumberFiltroForm
            handleSubmit={(values) => setFilter({ ...values })}
            onNcmChange={setSelectedNcm}
          />

          <CatalogoProdutosPartnumberDataReplicatorForm
            isLoading={isLoading}
            onConfigChange={handleConfigChange}
            handleTransmitirSubmit={handleTransmitirSubmit}
            ncm={selectedNcm}
          />

          <CatalogoProdutosTransmitirModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            catalogoState={catalogoState}
            handleCatalogoStateChange={handleCatalogoStateChange}
            handleConfirm={() => handleConfirm(selectionIds)}
          />
        </>
      )}
    </QCXActionsManagerPageTemplate>
  );
}
