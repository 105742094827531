/* eslint-disable no-plusplus */
import { Grid, IconButton, InputBase, makeStyles, Paper, useMediaQuery, useTheme, Box, Badge } from '@material-ui/core';
import { Search as SearchIcon, Tune as TuneIcon } from '@material-ui/icons';
import _, { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import QCXMoveFocusInside from '../../../components/focus-lock/QCXMoveFocusInside';
import QCXButton from '../../../shared-components/button/QCXButton';
import QCXFinalFormDialog from '../../../shared-components/dialog/QCXFinalFormDialog';
import FinalDataGrid from './DataGrid';

const useStyles = makeStyles((theme) => ({
  onlySearchBar: {
    padding: '4px 8px 4px 0px',
  },
  search: {
    paddingLeft: '0.3rem',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0',
    background: 'transparent',
    border: '0',
    borderBottom: '1px solid rgb(0 0 0 / 54%)',
    '&:hover': {
      borderBottomColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    '&::placeholder': {
      textTransform: 'uppercase',
    },
  },
  iconButton: {
    padding: 10,
  },
  firstButton: {
    minWidth: '150px',
    width: '100%',
    minHeight: '44px',
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
  },
  otherButton: {
    minWidth: '150px',
    width: '100%',
    minHeight: '44px',
  },
  table: {
    paddingTop: theme.spacing(3),
  },
}));

export default function DataGridControl({
  columns = [],
  rows = [],
  disableColumnMenu = true,
  disableColumnReorder = true,
  disableColumnResize = true,
  loading = false,
  controlButtons = [],
  filterableColumns = [], // Colunas que podem ser filtradas
  defaultQuerySearch = '',
  rowsCount,
  onConfigChangeCallback, // Função que será chamada quando o usuário alterar o filtro ou a paginação
  advancedFilterFormChildren: AdvancedFilterFormChildren = null,
  advancedFilterKeyAccessors = [],
  ...restProps
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const handlePageChange = (data) => {
    setCurrentPage(data.page + 1);
  };

  const handleOnPageSizeChange = (data) => {
    setPageSize(data.pageSize);
  };

  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  // Valor do campos de texto que realizara o filtro
  const [filterInputValue, setFilterInputValues] = useState(defaultQuerySearch || '');
  const [advancedFilterDialogOpen, setAdvancedFilterDialogOpen] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState({});
  const amountOfAdvancedFilters = advancedFilterKeyAccessors.filter((key) => {
    const value = _.get(advancedFilter, key, null);

    return _.isObjectLike(value) ? !_.isEmpty(value) : !!value;
  }).length;

  // ovelay de carregamento enquanto funcao de filtrar executa

  const buttonsQuantity = useMemo(() => controlButtons?.length, [controlButtons]);

  const handleChangeInputFilterField = useCallback(
    ({ target: { value } }) => {
      setFilterInputValues(value);
    },
    [setFilterInputValues]
  );

  const debouncedConfigChange = useCallback(
    _.debounce((configChange) => {
      if (onConfigChangeCallback) onConfigChangeCallback(configChange);
    }, 300),
    [onConfigChangeCallback]
  );

  useEffect(() => {
    debouncedConfigChange({ currentPage, pageSize, filterInputValue, advancedFilter });
  }, [onConfigChangeCallback, currentPage, pageSize, filterInputValue, advancedFilter]);

  const handleSearch = useCallback(() => {
    debouncedConfigChange({ currentPage, pageSize, filterInputValue, advancedFilter });
  }, [debouncedConfigChange, currentPage, pageSize, filterInputValue, advancedFilter]);

  const handleKeyUp = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleSearch();
      }
    },
    [handleSearch]
  );

  const handleAdvancedFilterSubmit = useCallback((values) => {
    setAdvancedFilter(values);
    setAdvancedFilterDialogOpen(false);
  }, []);

  return (
    <Grid container item justify="space-between" alignItems="center" spacing={0} xs={12} sm={12} md={12} xl={12}>
      <Box width="100%" display="flex" flexDirection="row" flexWrap="wrap" style={{ gap: '0.75rem' }}>
        <Box
          flex={1}
          minWidth="600px"
          style={{
            paddingLeft: '5px',
            ...(isSmDown || buttonsQuantity === 0
              ? {
                  paddingRight: '12px',
                }
              : {}),
          }}
        >
          <Paper className={classes.search} elevation={0}>
            <InputBase
              className={classes.input}
              placeholder={t('com.muralis.qcx.filtro.placeholder', {
                colunas: filterableColumns.map((item) => item.headerName.toUpperCase()).join(' | '),
              }).toUpperCase()}
              onChange={handleChangeInputFilterField}
              onKeyUp={handleKeyUp}
              value={filterInputValue}
              inputProps={{ 'aria-label': t('com.muralis.qcx.pesquisar').toUpperCase() }}
            />
            <IconButton className={classes.iconButton} aria-label="search" onClick={() => handleSearch()}>
              <SearchIcon />
            </IconButton>
            {AdvancedFilterFormChildren && (
              <IconButton
                className={classes.iconButton}
                aria-label="search"
                onClick={() => setAdvancedFilterDialogOpen(true)}
              >
                <Box position="relative">
                  <TuneIcon />

                  <Box position="absolute" top={-16} right={0}>
                    <Badge color="secondary" badgeContent={amountOfAdvancedFilters} />
                  </Box>
                </Box>
              </IconButton>
            )}
          </Paper>
        </Box>
        <Box
          style={{ gap: '0.5rem' }}
          display="flex"
          justify="flex-end"
          alignItems="center"
          flexWrap="wrap"
          spacing={1}
        >
          {controlButtons.map(({ to, description, startIcon, tooltip, onClick, ...restControlButtonProps }, index) => {
            const isFirstButton = index === 0;

            return (
              <Box key={`control-button-${index + 1}`}>
                <QCXButton
                  className={isFirstButton ? classes.firstButton : classes.otherButton}
                  color={isFirstButton ? 'primary' : 'secondary'}
                  startIcon={startIcon}
                  tooltip={tooltip && !isEmpty(tooltip)}
                  tooltipDescription={tooltip?.description}
                  {...(to ? { to, component: Link } : {})}
                  {...(onClick ? { onClick } : {})}
                  {...restControlButtonProps}
                >
                  {description}
                </QCXButton>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Grid container item direction="row" className={classes.table}>
        <FinalDataGrid
          columns={columns}
          rows={rows}
          disableColumnMenu={disableColumnMenu}
          disableColumnReorder={disableColumnReorder}
          disableColumnResize={disableColumnResize}
          loading={loading}
          page={currentPage - 1}
          pageSize={pageSize}
          defaultPageSize={pageSize}
          paginationMode="server"
          rowCount={rowsCount}
          onPageChange={handlePageChange}
          onPageSizeChange={handleOnPageSizeChange}
          {...restProps}
        />
      </Grid>

      {AdvancedFilterFormChildren && (
        <QCXFinalFormDialog
          key="form-dialog-filtro-avançado"
          id="form-dialog-filtro-avançado"
          title="Filtro avançado"
          dialogActionsProps={{
            button: {
              submit: {
                description: t('com.muralis.qcx.acoes.filtrar'),
                size: 'small',
              },
              cancel: {
                description: t('com.muralis.qcx.acoes.cancelar'),
                size: 'small',
              },
            },
          }}
          open={advancedFilterDialogOpen}
          initialValues={advancedFilter}
          disablePristine
          onSubmit={handleAdvancedFilterSubmit}
          onClose={() => setAdvancedFilterDialogOpen(false)}
        >
          {() => (
            <QCXMoveFocusInside>
              <AdvancedFilterFormChildren />
            </QCXMoveFocusInside>
          )}
        </QCXFinalFormDialog>
      )}
    </Grid>
  );
}
