import { IconButton } from '@material-ui/core';
import {
  Search as PageviewIcon,
} from '@material-ui/icons';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import NcmSubItemContext from '../../../../../contexts/registrations/ncm-subitem/NcmSubItemContext';
import {
  fetchNCMsByFilterPaginationAsync,
  resetModel,
  selectNcmSubitemsCount,
  selectNcmSubitens,
} from '../../../../../features/ncm-subitem/ncmSubitemSlice';
import {
  ALTERNATIVE_LOADING_STATUS,
} from '../../../../../features/status';
import SimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/SimpleConsultPageTemplate/SimpleConsultPageTemplate';
import { formatNcmSubItemRemoveDash } from '../../../../../utils/hooks/form/field/formatters';

export default function NcmSubitemConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const history = useHistory();

  const {
    status,
  } = useContext(NcmSubItemContext);

  const dispatch = useDispatch();

  const ncmSubitems = useSelector(selectNcmSubitens);

  const isAlternativeLoading = useMemo(() => status === ALTERNATIVE_LOADING_STATUS, [status]);

  const fetchAll = async () => {
    dispatch(fetchNCMsByFilterPaginationAsync([
        { name: 'page', value: 1 },
        { name: 'size', value: 10 },
      ])
    );
  };

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModels();
  }, []);

  const handleClickOnEditButton = useCallback(
    (_event, id) => (
      history.push(
        t('com.muralis.qcx.url.modulosOperacionaiNcmNivelSeisDetalhes', { id })
      )
    ), [history]
  );

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.catalogoProdutosMercadoria'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.NCM.NCMnivel6'),
      },
    },
  ];

  const columns = [
    {
      field: 'code',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      flex: 80,
      valueGetter: ({ row }) => (
        row?.code || '-'
      ),
    },
    {
      field: 'description',
      headerName: t('com.muralis.qcx.descricao'),
      type: 'string',
      flex: 175,
      valueGetter: ({ row }) => (
        formatNcmSubItemRemoveDash(row?.description)
        || '-'
      ),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 90,
      renderCell: ({ row: { id } }) => (
        <IconButton
          key={`btn-view-${id}`}
          name={`btn-view-${id}`}
          onClick={(event) => handleClickOnEditButton(event, id)}
        >
          <PageviewIcon color="primary" />
        </IconButton>
      ),
    }
  ];

  const handleConfigChange = useCallback(({ currentPage, pageSize, filterInputValue }) => {
    dispatch(
      fetchNCMsByFilterPaginationAsync([
        { name: 'page', value: currentPage },
        { name: 'size', value: pageSize },
        { name: 'numero', value: filterInputValue },
      ])
    );
  }, []);

  const rowsCount = useSelector(selectNcmSubitemsCount);

  const templateProps = useMemo(() => ({
    page: {
      title: t('com.muralis.qcx.NCM.NCMnivel6'),
      breadcrumbs,
    },
    control: {
    },
    table: {
      columns,
      rowsCount,
      onConfigChangeCallback: handleConfigChange,
    },
  }), [t, breadcrumbs, columns, rowsCount, handleConfigChange]);

  const filterPropGetter = useCallback(
    (unidadeDeMedida) => unidadeDeMedida.description,
    []
  );

  return (
    <SimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={ncmSubitems}
      isLoading={isAlternativeLoading}
      filterPropGetter={filterPropGetter}
      buttonNewText=""
      authInfo={authInfo}
      requiredRoles={['tabela-armazenagem']}
    />
  );
}
