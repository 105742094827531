import { useEffect, useMemo, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';
import QCXFormListenerManager from '../../shared-components/form-listener/QCXFormListenerManager';
import { CLOSED_STATUS, isOpenStatus } from '../../utils/hooks/form/dialog/formDialogUtils';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';

export default function QCXConfirmPaymentDateForm({
  handleOnSubmit,
  handleFormStatus,
  formDialogStatus,
  initialValues,
}) {
  const { t } = useTranslation();

  const isFormDialogOpen = useMemo(() => isOpenStatus(formDialogStatus), [formDialogStatus]);

  const handleCloseFormDialog = useCallback(() => {
    handleFormStatus(CLOSED_STATUS);
  }, [handleFormStatus]);

  const handleSubmitAndClose = useCallback(
    async (values) => {
      await handleOnSubmit(values);
      handleCloseFormDialog();
    },
    [handleOnSubmit, handleCloseFormDialog]
  );

  return (
    <QCXFinalFormDialog
      key="form-dialog-confirmar-data-pagamento"
      id="form-dialog-confirmar-data-pagamento"
      title={t('com.muralis.qcx.financeiro.confirmarPagamento')}
      dialogActionsProps={{
        button: {
          submit: {
            description: t('com.muralis.qcx.acoes.confirmar'),
            size: 'small',
            color: 'secondary',
          },
          cancel: {
            description: t('com.muralis.qcx.acoes.cancelar'),
            size: 'small',
          },
        },
      }}
      open={isFormDialogOpen}
      initialValues={initialValues}
      onSubmit={handleSubmitAndClose}
      onClose={handleCloseFormDialog}
    >
      {({ values, form }) => (
        <>
          <QCXFormListenerManager form={form} values={values} onChangeListeners={[]} />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFinalDatePickerField
                id="date-picker-data-pagamento"
                key="date-picker-data-pagamento"
                name="dataPagamento"
                label={t('com.muralis.qcx.dataPagamento')}
                required
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFinalFormDialog>
  );
}
