import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  addEspecificacao,
  catalogoProdutosActions,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  removeEspecificacaoById,
  resetRelatedEspecificacaoModel,
  resetSubMode,
  selectRelatedEspecificacaoModel,
  selectSubMode,
  setClientes,
  setRelatedEspecificacaoModel,
  updateEspecificacao,
} from '../../features/catalogo-produtos/catalogoProdutosSlice';
import { nveEspecificacaoActions } from '../../features/nve-especificacao/nveEspecificacaoSlice';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';
import { isTipoDuimp } from '../../utils/general/catalogo-produtos/catalogoProdutosUtils';
import { isNoneSubMode, isSubConsultMode, isSubCreateMode, isSubUpdateMode } from '../../utils/store/store-utils';
import QCXSelectManyClienteFormTableManager from '../cliente/QCXSelectManyClienteFormTableManager';
import QCXAtributoCatalogoProdutoManyToOneFormManager from './QCXAtributoCatalogoProdutoManyToOneFormManager';
import QCXCatalogoProdutosTecnicalDataForm from './QCXCatalogoProdutosTecnicalDataForm';
import { setErrorFeedback } from '../../features/feedback/feedbackSlice';

export default function QCXCatalogoProdutosWizardFinalForm({
  handleSubmit,
  handleAlternativeSave,
  model,
  handleChangeModel,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleResetBackgroundMode,
  isCreate,
  isConsult,
  isUpdate,
  isBackgroundCreate,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const subMode = useSelector(selectSubMode);

  const atributo = useSelector(selectRelatedEspecificacaoModel);
  const especificacaoList = useSelector(nveEspecificacaoActions.selectEspecificacoesNve);
  const catalogoProduto = useSelector(catalogoProdutosActions.selectCatalogoProdutos);

  const isSubNone = useMemo(() => isNoneSubMode(subMode), [subMode]);

  const isSubCreate = useMemo(() => isSubCreateMode(subMode), [subMode]);

  const isSubConsult = useMemo(() => isSubConsultMode(subMode), [subMode]);

  const isSubUpdate = useMemo(() => isSubUpdateMode(subMode), [subMode]);

  const isTipoDUIMP = useMemo(() => isTipoDuimp(model?.tipo), [model]);

  const handleEspecificacaoSubmit = (data) => {
    if (catalogoProduto.especificacoes?.length) {
      const found = catalogoProduto.especificacoes.find((item) => item.especificacao.id === data.especificacao.id);
      if (found?.especificacao.id && !isSubUpdate) {
        dispatch(catalogoProdutosActions.failure());
        const errorMessage = t('com.muralis.qcx.mensagem.atributoAdicionadoCatalogoProdutos');

        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
        return;
      }
    }
    const foundEspecificacao = especificacaoList.find((item) => item.id === data.especificacao.id);

    const dataWithEspecificacao = {
      ...data,
      especificacao: {
        ...data.especificacao,
        code: foundEspecificacao.code,
        description: foundEspecificacao.description,
      },
    };

    if (isSubCreate) {
      dispatch(addEspecificacao({ data: dataWithEspecificacao }));
    } else if (isSubUpdate) {
      dispatch(updateEspecificacao({ data: dataWithEspecificacao }));
    }
  };
  const handleNewEspecificacao = () => {
    dispatch(changeToSubCreateMode());
  };
  const handleCancelEspecificacaoCreate = () => {
    dispatch(resetSubMode());
  };

  const handleCancelEspecificacaoUpdate = () => {
    dispatch(changeToSubConsultMode());
  };

  const handleRemoveEspecificacao = () => {
    dispatch(removeEspecificacaoById({ data: atributo }));
  };

  const handleEspecificacaoRowClick = ({ row }) => {
    if (!isSubUpdate && !isSubCreate && atributo?.id !== row?.id) {
      dispatch(setRelatedEspecificacaoModel(row));
    }
  };

  const handleEditEspecificacao = () => {
    dispatch(changeToSubUpdateMode());
  };

  const handleClearEspecificacaoForm = () => {
    dispatch(resetRelatedEspecificacaoModel());
  };

  const checkIfIsAlternativeSaveByStep = (currentStep) => {
    const alternativeSteps = isTipoDUIMP ? [1] : [1, 2];
    return alternativeSteps.includes(currentStep);
  };

  const handleSetClientes = (data) => {
    dispatch(setClientes(data));
  };

  const atributoCatalogoProdutosInitialValues = useMemo(
    () => (isTipoDUIMP ? model : atributo),
    [model, atributo, isTipoDUIMP]
  );

  const atributoCatalogoProdutosHandleSubmit = useMemo(
    () => (isTipoDUIMP ? handleSubmit : handleEspecificacaoSubmit),
    [isTipoDUIMP, handleSubmit, handleEspecificacaoSubmit]
  );

  const wizardSteps = [
    t('com.muralis.qcx.dadosTecnicos'),
    t('com.muralis.qcx.cliente.labelPlural'),
    t('com.muralis.qcx.atributos'),
  ];

  return (
    <QCXFinalFormWizard
      steps={wizardSteps}
      initialValues={model}
      handleChangeValues={handleChangeModel}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isSubNone={isSubNone}
      isSubCreate={isSubCreate}
      isSubConsult={isSubConsult}
      isSubUpdate={isSubUpdate}
      handleSubmit={handleSubmit}
      checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      {...restProps}
    >
      {(formProps) => (
        <QCXCatalogoProdutosTecnicalDataForm
          key="dados-tecnicos-catalogo-produtos"
          isConsult={isConsult}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...formProps}
        />
      )}
      {({ controlComponentProps, ...restFormProps }) => (
        <QCXSelectManyClienteFormTableManager
          key="catalogo-produto-clientes"
          list={model?.clientes}
          isConsult={isConsult}
          handleChange={handleSetClientes}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...restFormProps}
        />
      )}
      {({ controlComponentProps, ...restFormProps }) => (
        <QCXAtributoCatalogoProdutoManyToOneFormManager
          key="catalogo-produto-atributos"
          isConsult={isConsult}
          isUpdate={isUpdate}
          isSubNone={isSubNone}
          isSubCreate={isSubCreate}
          isSubConsult={isSubConsult}
          isSubUpdate={isSubUpdate}
          initialValues={atributoCatalogoProdutosInitialValues}
          list={model?.especificacoes}
          handleSubmit={atributoCatalogoProdutosHandleSubmit}
          handleNew={handleNewEspecificacao}
          handleCancelCreate={handleCancelEspecificacaoCreate}
          handleCancelUpdate={handleCancelEspecificacaoUpdate}
          handleRemove={handleRemoveEspecificacao}
          handleRowClick={handleEspecificacaoRowClick}
          handleEdit={handleEditEspecificacao}
          handleClearForm={handleClearEspecificacaoForm}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          isTipoDUIMP={isTipoDUIMP}
          {...(isTipoDUIMP && {
            ...restFormProps,
          })}
        />
      )}
    </QCXFinalFormWizard>
  );
}
