import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import {
  fetchAllAsync,
  fetchByFilterAsync,
  selectAgentesTransportadores,
  selectTransportadoresInternacionais,
} from '../../features/transportador-internacional/transportadorInternacionalSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectTransportadorInternacionalAutocomplete({
  label,
  name,
  initialValues,
  agenteTransportador = false,
  disableFilter = true,
  getFilters,
  ...restProps
}) {
  const dispatch = useDispatch();

  const { values } = useFormState();

  const list = useSelector(agenteTransportador ? selectAgentesTransportadores : selectTransportadoresInternacionais);

  useEffect(() => {
    const handleFetch = () => {
      if (disableFilter) {
        dispatch(fetchAllAsync());
      } else {
        dispatch(fetchByFilterAsync(getFilters(values)));
      }
    };

    handleFetch();
  }, []);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = list.find((item) => item?.id === _.get(initialValues, name)?.id);
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.code} - ${item?.description}`,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (item?.description ? `${item?.description}` : '')}
      getOptionValue={(item) => item}
      {...restProps}
    />
  );
}
