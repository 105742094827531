import { createSlice } from '@reduxjs/toolkit';
import FollowUpUtils from '../../utils/general/follow-up/FollowUpUtils';
import {
  CONSULT_MODE,
  CREATE_MODE,
  UPDATE_MODE,
  LOCKED_MODE,
  NONE_MODE,
  BACKGROUND_CREATE_MODE,
  BACKGROUND_UPDATE_MODE,
  BACKGROUND_DELETE_MODE,
  NONE_BACKGROUND_MODE,
  SUB_CREATE_MODE,
  SUB_CONSULT_MODE,
  SUB_UPDATE_MODE,
  NONE_SUB_MODE,
  BACKGROUND_CONSULT_MODE,
} from '../mode';
import { NO_REFRESH, REFRESH } from '../refresh';
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, PREPARING_ACTION_STATUS, SUCCESS_STATUS } from '../status';
import { fetchAllByFollowUpIdAsync, repasseByProcessosAsync, uploadMultipleFilesAsync } from './etapaFollowUpThunks.ts';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
  },
  refresh: NO_REFRESH,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {},
  model: {},
  list: [],
  related: {
    model: {
      followUp: {},
    },
  },
};

const etapaFollowUpSlice = createSlice({
  name: 'etapaFollowUp',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    refresh: (state) => {
      state.refresh = REFRESH;
    },
    resetRefresh: (state) => {
      state.refresh = NO_REFRESH;
    },
    changeModeTo: (state, action) => {
      state.mode.main = action.payload;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    changeToLockMode: (state) => {
      state.mode.main = LOCKED_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    changeToBackgroundConsultMode: (state) => {
      state.mode.background = BACKGROUND_CONSULT_MODE;
    },
    changeToBackgroundUpdateMode: (state) => {
      state.mode.background = BACKGROUND_UPDATE_MODE;
    },
    changeToBackgroundDeleteMode: (state) => {
      state.mode.background = BACKGROUND_DELETE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    setList: (state, action) => {
      state.list = action.payload.data;
    },
    resetList: (state) => {
      state.list = [];
    },
    setRelatedFollowUpModel: (state, action) => {
      state.related.model.followUp = {
        ...action.payload,
      };
    },
    resetRelatedDuplicataModel: (state) => {
      state.related.model.followUp = {};
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllByFollowUpIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchAllByFollowUpIdAsync.fulfilled, (state, action) => {
        const sortedList = FollowUpUtils.sortEtapasBySequencia(action.payload.data || []);

        state.list = sortedList;
        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchAllByFollowUpIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.carregarListaEtapasFollowUp', { erro: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(repasseByProcessosAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(repasseByProcessosAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response = action.payload.response;
      })
      .addCase(repasseByProcessosAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('Erro ao repassar processos', { erro: action.error.message }),
        };
      })
      .addCase(uploadMultipleFilesAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(uploadMultipleFilesAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response = action.payload.response;
      })
      .addCase(uploadMultipleFilesAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('Erro ao enviar arquivos', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  refresh,
  resetRefresh,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToLockedMode,
  resetMode,
  changeToBackgroundCreateMode,
  changeToBackgroundConsultMode,
  changeToBackgroundUpdateMode,
  changeToBackgroundDeleteMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  setModel,
  resetModel,
  setList,
  resetList,
  setResponse,
  clearResponse,
  setError,
  clearError,
} = etapaFollowUpSlice.actions;

const etapaFollowUpActions = etapaFollowUpSlice.actions;

export {
  etapaFollowUpSlice,
  etapaFollowUpActions,
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  refresh,
  resetRefresh,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToLockedMode,
  resetMode,
  changeToBackgroundCreateMode,
  changeToBackgroundConsultMode,
  changeToBackgroundUpdateMode,
  changeToBackgroundDeleteMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  setModel,
  resetModel,
  setList,
  resetList,
  setResponse,
  clearResponse,
  setError,
  clearError,
};

export default etapaFollowUpSlice.reducer;
