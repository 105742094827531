import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _, { isFunction } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import {
  fetchAllAsync,
  selectEmpresasEstrangeiras,
  fetchFilteredAsync,
  fetchByIdAndInsertList,
} from '../../features/empresa-estrangeira/empresaEstrangeiraSlice';

import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import QCXAutocompleteSelectWithSearch from '../autocomplete-select/QCXAutocompleteSelectWithSearch';
import { getLocalidadeOrDefault } from '../../utils/general/general-utils';

export default function QCXSelectEmpresaEstrangeiraAutocomplete({ label, name, filterPredicate, ...restProps }) {
  const dispatch = useDispatch();
  const list = useSelector(selectEmpresasEstrangeiras);

  const { values } = useFormState();

  const [filteredEmpresasEstrangeiras, setFilteredEmpresasEstrangeiras] = useState([]);
  const [emptyListMsg, setEmptyListMsg] = useState('');
  useEffect(() => {
    dispatch(fetchFilteredAsync());
  }, []);

  useEffect(() => {
    if (isFunction(filterPredicate)) {
      const filtered = list?.filter(filterPredicate) ?? [];
      setFilteredEmpresasEstrangeiras(filtered);

      if (!filtered.length) {
        setEmptyListMsg('Nenhuma empresa encontrada');
      } else {
        setEmptyListMsg('');
      }
    }
  }, [list, filterPredicate]);

  const [defaultValue, setDefaultValue] = useState(null);

  useEffect(() => {
    const value = list?.find((item) => String(item.id) === String(_.get(values, name)));

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue(null);
      if (_.get(values, name)) {
        const id = _.get(values, name);
        _.debounce(() => dispatch(fetchByIdAndInsertList(id)), 200)();
      }
    }
  }, [list, values, name]);

  const filterOptions = createFilterOptions({
    stringify: (item) =>
      `${item?.id} - ${item?.pessoaJuridica?.nomeResumido || item?.pessoaJuridica?.nome || item?.description || ''}`,
  });

  const searchFilteredEmpresasList = (value) => {
    dispatch(fetchFilteredAsync(value));
  };

  return (
    <QCXAutocompleteSelectWithSearch
      label={label}
      id={name}
      name={name}
      onEventActionPaiFunction={searchFilteredEmpresasList}
      options={filteredEmpresasEstrangeiras}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) =>
        item?.id
          ? `${item?.id} - ${
              item?.pessoaJuridica?.nomeResumido || item?.pessoaJuridica?.nome || item?.description || ''
            } - ${getLocalidadeOrDefault(item?.endereco)}`
          : ''
      }
      emptyListMsg={emptyListMsg}
      placeholder="Digite no mínimo 4 dígitos para realizar a busca !"
      {...restProps}
    />
  );
}
