import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme } from '@material-ui/core';
import { etapaFollowUpActions } from '../../features/etapa-follow-up/etapaFollowUpSlice';
import {
  CLOSED_STATUS,
  DONE_STATUS,
  FAIL_STATUS,
  isOpenStatus,
  SUBMITTING_STATUS,
} from '../../utils/hooks/form/dialog/formDialogUtils';
import { etapaFollowUpAPI } from '../../features/etapa-follow-up/etapaFollowUpAPI';
import { etapaFollowUpSelectors } from '../../features/etapa-follow-up/etapaFollowUpSelectors';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';
import QCXMoveFocusInside from '../focus-lock/QCXMoveFocusInside';
import { isValidDate, required } from '../../utils/validators/field/validator';
import { followUpActions } from '../../features/follow-up/followUpSlice';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import { formatDate } from '../../utils/general/general-utils';
import EtapaFollowUpUtils from '../../utils/general/follow-up/EtapaFollowUpUtils';
import useValidators from '../../utils/hooks/useValidators';
import { setErrorFeedback } from '../../features/feedback/feedbackSlice';

export default function QCXDataEtapaFollowUpFormDialog({ status, handleStatus }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const dispatch = useDispatch();

  const etapaFollowUp = useSelector(etapaFollowUpSelectors.selectEtapaFollowUp);

  const isCompleted = useMemo(() => EtapaFollowUpUtils.isStatusConcluida(etapaFollowUp?.status), [etapaFollowUp]);

  const dataValidators = useMemo(() => useValidators([required, isValidDate]), []);

  const color = useMemo(() => {
    if (isCompleted) {
      return 'secondary';
    }

    return 'primary';
  }, [isCompleted]);

  const handleSubmit = useCallback(
    async ({ metadata, ...values }) => {
      try {
        dispatch(followUpActions.loading());
        dispatch(etapaFollowUpActions.loading());

        handleStatus(SUBMITTING_STATUS);

        const payload = {
          ...values,
          dataConclusao: values?.dataConclusao,
          status: 'CONCLUIDA',
        };

        dispatch(etapaFollowUpActions.setModel(payload));

        const response = await etapaFollowUpAPI.save(payload);

        if (response.status === 200) {
          const updatedDate = formatDate(values?.dataConclusao);
          const responseMessage = t('com.muralis.qcx.mensagem.dataConclusaoEtapaAlterada', {
            descricao: values?.etapa?.description,
            data: updatedDate,
          });

          const simplifiedResponse = {
            status: response?.status,
            message: responseMessage,
            data: response?.data,
          };

          dispatch(followUpActions.success());
          dispatch(etapaFollowUpActions.success());
          dispatch(followUpActions.setResponse(simplifiedResponse));

          dispatch(etapaFollowUpActions.refresh());

          handleStatus(DONE_STATUS);
        }
      } catch (error) {
        handleStatus(FAIL_STATUS);

        const isUnknownError =
          _.isEmpty(error?.response) || _.isEmpty(error?.response?.data) || _.isEmpty(error?.response?.data?.message);

        const errorMessage = isUnknownError
          ? t('com.muralis.qcx.erro.erroSalvarEtapa', { descricao: values?.etapa?.description })
          : t('com.muralis.qcx.erro.erroSalvarEtapaEspecifico', {
              descricao: values?.etapa?.description,
              mensagem: error?.response?.data?.message,
            });

        dispatch(followUpActions.failure());
        dispatch(etapaFollowUpActions.failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
        dispatch(followUpActions.setResponse(error.response));
      }
    },
    [etapaFollowUp, handleStatus]
  );

  const handleCloseFormDialog = useCallback(() => handleStatus(CLOSED_STATUS), [handleStatus]);

  const isFormDialogOpen = useMemo(() => isOpenStatus(status), [status]);

  const formDialogTitle = useMemo(
    () => (
      <>
        {t('com.muralis.qcx.etapa.editarDataEtapa').toUpperCase()}
        <br />
        <Typography variant="subtitle1">
          <strong>{etapaFollowUp?.etapa?.description}</strong>
        </Typography>
      </>
    ),
    [etapaFollowUp]
  );

  const formDialogContent = useMemo(() => t('com.muralis.qcx.preenchaCamposEtapaSalvar'), []);

  return (
    <QCXFinalFormDialog
      key="form-dialog-alterar-data-conclusao-etapa-follow-up"
      id="form-dialog-alterar-data-conclusao-etapa-follow-up"
      title={formDialogTitle}
      content={formDialogContent}
      dialogActionsProps={{
        button: {
          submit: {
            description: t('com.muralis.qcx.acoes.salvar'),
            size: 'small',
          },
          cancel: {
            description: t('com.muralis.qcx.acoes.cancelar'),
            size: 'small',
          },
        },
      }}
      open={isFormDialogOpen}
      initialValues={etapaFollowUp}
      onSubmit={handleSubmit}
      onClose={handleCloseFormDialog}
      PaperProps={{
        style: {
          borderLeft: `6px solid ${theme.palette[color].main}`,
        },
      }}
    >
      {() => (
        <QCXMoveFocusInside>
          <QCXFinalDatePickerField
            id="date-picker-data-conclusao-etapa-field"
            key="date-picker-data-conclusao-etapa-field"
            name="dataConclusao"
            label={t('com.muralis.qcx.etapa.dataConclusao')}
            validate={dataValidators}
            required
          />
        </QCXMoveFocusInside>
      )}
    </QCXFinalFormDialog>
  );
}
