import { Grid } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  vinculoReferenciaClienteFollowUpActions,
  vinculoReferenciaClienteFollowUpSelectors,
} from '../../../../../../features/vinculo-referencia-cliente-follow-up/vinculoReferenciaClienteFollowUpSlice';
import QCXFinalBondManagerOld from '../../../../../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import DesviosAutoComplete from './DesviosAutoComplete';

function DesviosBondManager({ isConsult }) {
  const { t } = useTranslation();

  const desvio = t('com.muralis.qcx.listaFollowUp.desvio');

  const desviosListProps = useMemo(
    () => ({
      name: 'desvios',
      columns: [
        {
          field: 'desvio',
          headerName: t('com.muralis.qcx.listaFollowUp.desvioD'),
          headerAlign: 'left',
          align: 'left',
          flex: 140,
          valueGetter: ({ row }) => row?.description || '',
        },
      ],
    }),
    []
  );

  const desviosFormProps = useMemo(() => ({
    rootName: 'ignorableFields.desvios.form',
    fields: [{ name: 'desvio', label: desvio, required: true }],
  }));

  const handleAdd = useCallback((currentVinculo, currentVinculosList, handleSuccessAddVinculo) => {
    const updatedVinculos = [...currentVinculosList, currentVinculo.desvio];

    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const referenciaClienteReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoReferenciaClienteFollowUpSelectors.selectStatus,
        selectMode: vinculoReferenciaClienteFollowUpSelectors.selectMode,
        selectModel: vinculoReferenciaClienteFollowUpSelectors.selectModel,
      },
      actions: {
        loading: vinculoReferenciaClienteFollowUpActions.loading,
        resetStatus: vinculoReferenciaClienteFollowUpActions.resetStatus,
        changeToUpdateMode: vinculoReferenciaClienteFollowUpActions.changeToUpdateMode,
        changeToCreateMode: vinculoReferenciaClienteFollowUpActions.changeToCreateMode,
        resetMode: vinculoReferenciaClienteFollowUpActions.resetMode,
        setModel: vinculoReferenciaClienteFollowUpActions.setModel,
        resetModel: vinculoReferenciaClienteFollowUpActions.resetModel,
      },
    }),
    []
  );

  return (
    <>
      <QCXFinalBondManagerOld
        isParentConsult={isConsult}
        listProps={desviosListProps}
        formProps={desviosFormProps}
        reducerConfig={referenciaClienteReducerConfig}
        handleAdd={handleAdd}
        disableConsult
        disabled
      >
        <Grid item style={{ paddingBottom: '1rem' }} xs={8}>
          <DesviosAutoComplete disabled={isConsult} name="ignorableFields.desvios.form.desvio" />
        </Grid>
      </QCXFinalBondManagerOld>
    </>
  );
}

export default DesviosBondManager;
