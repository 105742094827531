import { createSlice } from '@reduxjs/toolkit';
import { ERROR_SEVERITY, INFO_SEVERITY, SUCCESS_SEVERITY, WARNING_SEVERITY } from '../severity';

const initialState = {
  root: {
    show: false,
    severity: '',
    message: '',
  },
};

const feedbackSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setFeedback: (state, action) => {
      state.root = action.payload;
    },
    setSuccessFeedback: (state, action) => {
      state.root.show = true;
      state.root.severity = SUCCESS_SEVERITY;
      state.root.message = action.payload.message;
    },
    setInfoFeedback: (state, action) => {
      state.root.show = true;
      state.root.severity = INFO_SEVERITY;
      state.root.message = action.payload.message;
    },
    setWarningFeedback: (state, action) => {
      state.root.show = true;
      state.root.severity = WARNING_SEVERITY;
      state.root.message = action.payload.message;
    },
    setErrorFeedback: (state, action) => {
      state.root.show = true;
      state.root.severity = ERROR_SEVERITY;
      state.root.message = action.payload.message;
    },
    resetFeedback: (state) => {
      state.root = initialState.root;
    },
  },
});

const { setFeedback, setSuccessFeedback, setInfoFeedback, setWarningFeedback, setErrorFeedback, resetFeedback } =
  feedbackSlice.actions;

const selectFeedback = (state) => state.feedback.root;

export {
  feedbackSlice,
  setFeedback,
  setSuccessFeedback,
  setInfoFeedback,
  setWarningFeedback,
  setErrorFeedback,
  resetFeedback,
  selectFeedback,
};

export default feedbackSlice.reducer;
