import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Button, Grid, Modal, Box, Typography } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUnidadeNegocioGuard } from "../../../../common/hooks/useUnidadeNegocioGuard";
import { FollowUpDto } from "../../../../common/types/followUp";
import { selectUnidadeSelecionada } from "../../../../../features/usuario-logado/usuarioLogadoSlice";
import { KEYCLOAK_TOKEN_TIMEOUT } from "../../../../../App";
import { setErrorFeedback } from "../../../../../features/feedback/feedbackSlice";
import { downloadFile } from "../redbull.helpers";
import { fetchByFilterDto } from "../../../../../features/follow-up/followUpAPI";
import PageLayout from "../../../../common/layouts/pageLayout";
import LoadingIndicator from "../../../../common/components/loadingIndicator";
import FormHeaderMedium from "../../../../common/components/forms/formHeaderMedium";
import SelectStyled from "../../../../common/components/input/selectStyled";
import ErrorBadge from "../../../../common/components/forms/errorBadge";
import { useTranslation } from "react-i18next";

const RedBullRelatorioPadraoPage = () => {
  useUnidadeNegocioGuard();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [error, setError] = useState<string | string[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [followUpsData, setFollowUpsData] = useState<FollowUpDto[] | undefined>(undefined);
  const [processo, setProcesso] = useState<string | undefined>(undefined);
  const { keycloak } = useKeycloak();
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);
  const { token } = keycloak;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const url = process.env.REACT_APP_QCX_API_BASE_URL! + "/"
    + process.env.REACT_APP_RELATORIOS_API_RED_BULL_ENDPOINT!

  const processos = useMemo(() => {
    if (!followUpsData || typeof followUpsData != "object") {
      return [];
    }

    const processos: string[] = followUpsData.map(
      (followUp: FollowUpDto) => followUp.numero
    );

    return processos.filter((processo, index) => {
      return processos.indexOf(processo) === index;
    });
  }, [followUpsData]);

  const clearData = () => {
    setProcesso(undefined);
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    if (processo && token && selectedUnidade) {
      setIsLoading(true);

      try {
        const message: string = await downloadFile(token, selectedUnidade.toString(), `${url}/relatorio?processo=${processo}`, `${processo}_relatório_nota_fiscal.xlsx`);

        setIsLoading(false);

        if (message) {
          setModalMessage(message);
          setModalOpen(true);
        } else {
          setModalMessage(t('com.muralis.qcx.integracao.Planilhas.sucesso.planilhaComSuceso'));
          setModalOpen(true);
        }
      } catch (error) {
        dispatch(setErrorFeedback({
          message: t('translation.com.muralis.qcx.integracao.Planilhas.erros.erroInesperado')
        }));
      }
    }

    keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
    clearData();
  };

  const getFollowUps = async () => {
    try {
      const { data } = await fetchByFilterDto([
        { name: 'produto', value: 'IMPORTACAO' },
        { name: 'filterDadosMinimos', value: 'TRUE' }
      ]);

      setFollowUpsData(data);
    } catch (error) {
      dispatch(setErrorFeedback({
        message: 'Erro ao obter FollowUps:'
      }));
    }
  };

  useEffect(() => {
    getFollowUps();
  }, []);

  return (
    <PageLayout
      title={"Relatório Red Bull - Comum"}
      icon={<InsertDriveFileIcon color={"secondary"} />}
    >
      {isLoading ? (
        <LoadingIndicator
          message={isLoading ? "Gerando Arquivo" : "Carregando Dados..."}
        />
      ) : (
        <Grid container spacing={2}>
          <FormHeaderMedium>
            Selecione as informações para gerar o arquivo
          </FormHeaderMedium>

          <Grid item sm={6}>
            <SelectStyled
              options={processos.map((processo, index) => ({
                id: index,
                value: processo,
              }))}
              label={"Processo"}
              onChangeAction={(event, newValue) => {
                setProcesso(newValue?.value);
              }}
            />
          </Grid>

          <ErrorBadge error={error} />
          <Grid item sm={12} textAlign={"right"}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit}
              disabled={!processo || isLoading || !!error}
            >
              Gerar Relatório
            </Button>
          </Grid>
        </Grid>
      )}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, maxHeight: '80vh', overflowY: 'auto', bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <Typography variant="h5" component="h2">
            Relatório Planilha
          </Typography>
          <Typography sx={{ mt: 2 }} dangerouslySetInnerHTML={{ __html: modalMessage }} />
        </Box>
      </Modal>
    </PageLayout>
  );
};

export default RedBullRelatorioPadraoPage;