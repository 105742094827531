import { MediaType, validateQueryParams } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import { CLIENTE_ENDPOINT, FILTER_ENDPOINT, SERVICOS_ENDPOINT } from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAll() {
  return doGetQCXRequest([
    CLIENTE_ENDPOINT
  ]);
}

async function fetchById(id) {
  return doGetQCXRequest([
    CLIENTE_ENDPOINT,
    id
  ]);
}

async function fetchServicosByClienteId(id, queryParams = []) {
  return doGetQCXRequest(
    [
      CLIENTE_ENDPOINT,
      SERVICOS_ENDPOINT,
      id
    ],
    queryParams
  );
}

async function fetchByFilter(params = []) {
  const validatedParams = validateQueryParams(params);
  if (validatedParams.length) {
    return doGetQCXRequest([
      CLIENTE_ENDPOINT, FILTER_ENDPOINT
    ], validatedParams);
  }
  return { data: [] };
}

async function fetchClientesAssociadosACatalogoProdutos(params = []) {
  const validatedParams = validateQueryParams(params);
  return doGetQCXRequest([
    CLIENTE_ENDPOINT, "has/catalogoprodutos"
  ], validatedParams);
}

async function register(data, queryParams = []) {
  return doPostQCXRequest(
    [CLIENTE_ENDPOINT],
    data,
    queryParams
  );
}

/**
 * O endpoint PUT, somente, usa request multipart por causa do certificado.
 * Requests multiparts não são usadas no POST porque o POST só ocorre na primeira
 * etapa e o campo do certificado fica em uma etapa posterior.
 */
async function save(data, queryParams = [], config = {}) {
  if (data instanceof FormData) {
    return doPutQCXRequest(
      [CLIENTE_ENDPOINT, 'updatewithcert'],
      data,
      queryParams,
      {
        ...config,
        headers: {
          'Content-Type': MediaType.MULTIPART_FORM_DATA,
        },
      }
    );
  }
  //
  // else:
  return doPutQCXRequest(
    [CLIENTE_ENDPOINT],
    data,
    queryParams,
    config
  );
}

async function associateModeloDanfe(idCliente, idModeloDanfe) {
  if (typeof idModeloDanfe !== 'string' && typeof idModeloDanfe !== 'number') {
    idModeloDanfe = -1; // Enviamos -1 para desassociar.
  }
  return doPutQCXRequest(
    [CLIENTE_ENDPOINT, 'updatemodelodanfe', idCliente, idModeloDanfe]
  );
}

async function activateById(id) {
  return doPutQCXActivateRequest(
    [CLIENTE_ENDPOINT],
    id
  );
}

async function inactivateById(id) {
  return doPutQCXInactivateRequest(
    [CLIENTE_ENDPOINT],
    id
  );
}

async function fetchClientesWithFilterDto(params = []) {
    const validatedParams = validateQueryParams(params);

    if (validatedParams.length) {
      return doGetQCXRequest([
        CLIENTE_ENDPOINT, "filter/dto"
      ], validatedParams);
    }
    return { data: [] };
}

const clienteAPI = {
  fetchAll,
  fetchById,
  fetchByFilter,
  fetchServicosByClienteId,
  register,
  save,
  associateModeloDanfe,
  activateById,
  inactivateById,
  fetchClientesWithFilterDto,
};

export {
  clienteAPI,
  fetchAll,
  fetchById,
  fetchByFilter,
  fetchServicosByClienteId,
  register,
  save,
  associateModeloDanfe,
  activateById,
  inactivateById,
  fetchClientesWithFilterDto,
  fetchClientesAssociadosACatalogoProdutos,
};
