import { Link } from '@material-ui/core';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { Button, Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import * as _ from 'lodash';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { read, utils } from 'xlsx';
import QCXMoveFocusInside from '../../../../../components/focus-lock/QCXMoveFocusInside';
import { fetchByFilterAsync as fetchCatalogosProdutosByFilterAsync } from '../../../../../features/catalogo-produtos/catalogoProdutosSlice';
import { setErrorFeedback, setSuccessFeedback } from '../../../../../features/feedback/feedbackSlice';
import { fetchByFilter as fetchNcmByFilter } from '../../../../../features/ncm-subitem/ncmSubitemAPI';
import { fetchNveByListFilterAsync } from '../../../../../features/nve/nveSlice';
import QCXInfoAlert from '../../../../../shared-components/alert/QCXInfoAlert';
import QCXFinalCheckboxField from '../../../../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXSelectClienteAutocomplete from '../../../../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXSelectServicoAutocomplete from '../../../../../shared-components/select-servico/QCXSelectServicoAutocomplete';
import { MediaType } from '../../../../../utils/api/api-utils';
import { required } from '../../../../../utils/validators/field/validator';
import DataTable from '../../../../common/components/dataTable';
import FileDragAndDrop from '../../../../common/components/fileDragAndDrop';
import FormHeaderMedium from '../../../../common/components/forms/formHeaderMedium';
import PageLayout from '../../../../common/layouts/pageLayout';
import MultiSelectClientes from '../components/MultiSelectClientes';
import ConfirmationModal from '../components/confirmationModal';
import ProgressBarModal, { ProgressBarStep } from '../components/progressModal';
import planilhasPageHelpers from './faturasPlanilhasPage.helpers';
import { useProcessedSpreadsheetData } from './useProcessedSpreadsheetData';
import { useKeycloak } from '@react-keycloak/web';
import { selectUnidadeSelecionada } from '../../../../../features/usuario-logado/usuarioLogadoSlice';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../../../App';
import SelectStyled from '../../../../common/components/input/selectStyled';
import { SimpleServicoDTO } from '../../../../common/types/servico';
import { ClientIdAndDescriptionDTO } from '../../../../common/types/clientData';
import { fetchClientesWithFilterDto } from '../../../../../features/cliente/clienteAPI';
import { fetchByFilterDto } from '../../../../../features/servico/servicoAPI';

export interface BasicFormData {
  arquivo: File;
  cliente: {
    id: number;
  };
  clientes: { id: number }[];
  processo: string;
  servico: {
    id: number;
  };
  catalogoProdutos: boolean;
  fatura: boolean;
}

const FaturasPlanilhaPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [spreadsheetData, setSpreadsheetData] = useState<any[][]>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentFollowUp, setCurrentFollowUp] = useState<string | null | undefined>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { keycloak } = useKeycloak();
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);
  const { token } = keycloak;
  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

  const [cliente, setCliente] = useState<number | undefined>(undefined);
  const [clientes, setClientes] = useState<ClientIdAndDescriptionDTO[] | undefined>(undefined);
  const [error, setError] = useState<string | string[] | undefined>();
  const [servico, setServico] = useState<number | undefined>(undefined);
  const [servicosData, setServicosData] = useState<SimpleServicoDTO[] | undefined>(undefined);

  const [currentStep, setCurrentStep] = useState<ProgressBarStep>({
    step: 0,
    description: 'Criando Follow Ups',
  });

  const URL_MODELO = useMemo(() => process.env.REACT_APP_MODELOS_RELATORIOS_INTEGRACAO_FATURAS_URL, []);

  const resetSpreadsheetData = () => {
    setSpreadsheetData([]);
  };


  useEffect(() => {
    if (!cliente) {
      setError(undefined);
      return;
    }

    return undefined;
  }, [cliente]);

  useEffect(() => {
    getClients();
  }, []);

  const clearData = () => {
    setCliente(undefined);
    setServico(undefined);
    setIsLoading(false);
    setIsModalOpen(false);
    resetProgressBar();
    resetSpreadsheetData();
  };

  useEffect(() => {
    if (cliente !== undefined) {
      fetchServicos(cliente);
    }
  }, [cliente, dispatch]);

  const fetchServicos = async (clienteId: number) => {
    try {
      const { data: servicosData } = await fetchByFilterDto([
        { name: 'clienteId', value: clienteId },
        { name: 'produto', value: 'FATURA' },
        { name: 'filterDadosMinimos', value: 'TRUE' }
      ]);
      setServicosData(servicosData);
    } catch (error) {
      dispatch(setErrorFeedback({ message: 'Erro ao obter serviços' }));
    }
  };


  const handleServicoChange = (
    event: SyntheticEvent<Element, Event>,
    value: newValueIdValue | null,
    form: any
  ) => {

      if (value) {
        setServico(Number(value.id));
        form.change('servico', { id: value.id });
      }
  };

  interface newValueIdValue {
    id?: string | number | undefined;
    value?: string;
  }

  const handleClienteChange = (
    event: SyntheticEvent<Element, Event>,
    value: newValueIdValue | null,
    form: any
  ) => {
    if (clientes) {
      const selectedClient = clientes.find(
        (cliente) => cliente.id === value?.id
      );

      if (selectedClient) {
        setCliente(selectedClient.id);
        setServico(undefined);
        form.change('cliente', { id: selectedClient.id });
      }
    }
  };



  const getClients = async () => {
    try {
      const { data } = await fetchClientesWithFilterDto([{ name: 'filterDadosMinimos', value: 'TRUE' }]);
      setClientes(data);

    } catch (error) {
      dispatch(setErrorFeedback({
        message: 'Erro ao obter clientes:'
      }));
    }
  };


  const parseSpreadsheet = (file: File, callback: (data: any[][]) => void): void => {
    if (!FileReader) {
      console.error('FileReader is not supported in this browser.');
      return;
    }

    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      try {
        const wb = read(event.target?.result);
        const sheetName = wb.SheetNames[0];
        const worksheet = wb.Sheets[sheetName];

        if (worksheet) {
          const range = utils.decode_range(worksheet['!ref'] as string);
          range.s.r = 0;
          worksheet['!ref'] = utils.encode_range(range);

          const jsonData: any[][] = utils.sheet_to_json(worksheet, {
            header: 1,
            blankrows: false,
            defval: null,
          });

          callback(jsonData);
        }
      } catch (error) {
        console.error('Error reading the spreadsheet:', error);
      }
    };

    reader.onerror = () => {
      console.error('Error reading the file:', reader.error);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleFileSubmit = (values: BasicFormData) => {
    parseSpreadsheet(values.arquivo, (faturas) => {
      setSpreadsheetData(faturas);
    });
  };

  const faturaHeaders = useMemo(
    () => [
      'FATURA/PO',
      'DATA DA FATURA',
      'PAÍS DE PROCEDÊNCIA',
      'URF DE DESPACHO',
      'URF DE ENTRADA',
      'INCOTERM',
      'MOEDA DO MLE',
      'VALOR DO MLE NA MOEDA',
      'MOEDA DO DESCONTO',
      'VALOR DO DESCONTO NA MOEDA',
      'MOEDA DAS DESPESAS',
      'VALOR DAS DESPESAS NA MOEDA',
      'MOEDA DO FRETE',
      'VALOR DO FRETE PREPAID NA MOEDA',
      'VALOR DO FRETE COLLECT NA MOEDA',
      'VALOR DO FRETE NO NACIONAL NA MOEDA',
      'MOEDA DO SEGURO',
      'VALOR DO SEGURO NA MOEDA',
    ],
    []
  );

  const mercadoriaHeaders = useMemo(
    () => [
      'FATURA/PO',
      'PARTNUMBER',
      'APELIDO',
      'DESCRIÇÃO RESUMIDA',
      'UNIDADE DE MEDIDA',
      'NCM SH',
      'DESTAQUE DO NCM',
      'NALADI SH',
      'NALADI NCCA',
      'ALÍQUOTA ICMS',
      'QUANTIDADE',
      'QUANTIDADE ESTATÍSTICA',
      'PESO LÍQUIDO',
      'PESO LÍQUIDO UNITÁRIO',
      'PESO BRUTO UNITÁRIO',
      'CÓDIGO DO EXPORTADOR',
      'CÓDIGO DO FABRICANTE',
      'VALOR UNITÁRIO NA MOEDA',
      'VALOR TOTAL NA MOEDA',
      'PAÍS DE ORIGEM',
      'NVES',
      'DESCRIÇÃO NFE',
      'QUEBRA AUXILIAR',
      'ESPECIFICAÇÃO DA MERCADORIA',
      'NÚMERO DO ATO CONCESSÓRIO',
      'ITEM DO ATO CONCESSÓRIO',
      'MARCA',
      'NÚMERO DE SÉRIE',
      'MODELO',
      'ANO DE FABRICAÇÃO',
      'LOTE',
      'DATA DE VALIDADE',
      'NECESSITA DE LI',
      'NÚMERO DA FATURA',
      'ITEM DA FATURA',
      'CFOP',
      'GTIN',
      'CÓDIGO SECUNDÁRIO',
      'AUXILIAR INVOICE',
      'SEQUENCIA INVOICE',
      'NÚMERO PEDIDO',
      'SEQUENCIA PEDIDO',
      'NUMERO ORDEM COMPRA',
      'SEQUENCIA ORDEM COMPRA',
      'ÁREA',
    ],
    []
  );

  const [faturaColumns, itemsColumns] = useMemo(() => {
    const faturaColumns: GridColDef[] = faturaHeaders.map((header, index) => {
      const field = header
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^\w\s]/gi, '')
        .split(' ')
        .map((word, i) => (i === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()))
        .join('');

      return {
        field: field,
        headerName: header,
        width: 200,
        headerClassName: 'header-theme',
      };
    });

    const itemsColumns: GridColDef[] = mercadoriaHeaders.map((header, index) => {
      const field = header
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^\w\s]/gi, '')
        .split(' ')
        .map((word, i) => (i === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()))
        .join('');

      return {
        field: field,
        headerName: header,
        width: 200,
        headerClassName: 'header-theme',
      };
    });

    return [faturaColumns, itemsColumns];
  }, [spreadsheetData]);

  const [faturasData, itensData] = useProcessedSpreadsheetData(spreadsheetData, faturaColumns, itemsColumns);

  const resetForm = () => {
    resetSpreadsheetData();
  };


  const resetProgressBar = useCallback(() => {
    setCurrentStep({ step: 0, description: 'Criando Follow Ups' });
  }, []);

  const [decisionPromiseResolver, setDecisionPromiseResolver] = useState<(value: boolean) => void>(() => { });

  const handleConfirm = () => {
    decisionPromiseResolver(true);
  };

  const handleCancel = () => {
    decisionPromiseResolver(false);
  };

  const handleFaturasSubmit = async (values: BasicFormData) => {
    setIsModalOpen(true);

    const followUpResponse = await planilhasPageHelpers.createFollowUps(faturasData, values.cliente, values.servico);

    const filteredFollowUps = [];

    let messageError: string = 'Não há dados válidos a serem cadastrados.';

    for (const followUp of followUpResponse) {
      if (followUp.hasError) {
        messageError = followUp.message ? followUp.message : 'Erro ao criar Follow Up:';
        dispatch(setErrorFeedback({ message: messageError }));
        continue;
      }

      if (followUp.alreadyExists) {
        setCurrentFollowUp(followUp.numero);
        setConfirmModalOpen(true);

        const userDecision = await new Promise((resolve) => setDecisionPromiseResolver(() => resolve));
        setConfirmModalOpen(false);

        if (userDecision) {
          filteredFollowUps.push(followUp);
        }
      } else {
        filteredFollowUps.push(followUp);
      }
    }

    if (filteredFollowUps.length === 0) {
      setIsModalOpen(false);
      dispatch(setErrorFeedback({ message: messageError }));
      return;
    }

    let messageModal = '';
    if (values.catalogoProdutos && values.fatura) {
      messageModal = 'Criando Faturas e catálogo de produtos';
    } else if (values.catalogoProdutos && !values.fatura) {
      messageModal = 'Criando Catálogo de produtos';
    } else {
      messageModal = 'Criando Faturas';
    }

    if (selectedUnidade && token) {
      setCurrentStep({ step: 2, description: messageModal });

      try {
        const body = {
          faturas: faturasData,
          itens: itensData,
          followUps: filteredFollowUps,
        };
        const respSpreadsheet = await planilhasPageHelpers.uploadFile(values, selectedUnidade, token, body);
        if (respSpreadsheet.status !== 200) {
          dispatch(
            setErrorFeedback({
              message: respSpreadsheet.data
                ? respSpreadsheet.data
                : t('com.muralis.qcx.integracao.Planilhas.erros.erroAoGerarPlanilha'),
            })
          );
        } else {
          dispatch(
            setSuccessFeedback({
              message: respSpreadsheet.data
                ? respSpreadsheet.data
                : t('com.muralis.qcx.integracao.Planilhas.sucesso.planilhaGeradaComSuceso'),
            })
          );
        }
      } catch (error: any) {
        console.log(error);
        console.log(error.message);
        dispatch(setErrorFeedback({ message: 'Tente novamente a integração' }));
      } finally {
        setIsModalOpen(false);
        resetProgressBar();
        resetSpreadsheetData();
      }
    }
  };

  return (
    <PageLayout title={'Planilhas'} icon={<HistoryEduIcon color={'secondary'} />}>
      <>
        <ConfirmationModal
          open={confirmModalOpen}
          message={`O Follow Up ${currentFollowUp} já existe com uma fatura associada. Deseja continuar o processamento?`}
          singleAction={false}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          title={'Follow Up já existe!'}
        />

        <ProgressBarModal
          title="Importando planilha"
          open={isModalOpen}
          totalSteps={2}
          currentStep={currentStep}
          onClose={() => setIsModalOpen(false)}
        ></ProgressBarModal>
        <Form onSubmit={(values: BasicFormData) => handleFileSubmit(values)}>
          {({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Link href={URL_MODELO} target="_blank">
                      <QCXInfoAlert>Clique aqui para fazer o download da planilha modelo</QCXInfoAlert>
                    </Link>
                  </Grid>
                  <FormHeaderMedium>Informações básicas</FormHeaderMedium>
                  <Grid item xs={6}>
                    <SelectStyled
                      options={(clientes || []).map((cliente, index) => ({
                        id: cliente.id,
                        value: cliente.description + " - " + cliente.id,
                      }))}
                      onChangeAction={(event, newValue) => handleClienteChange(event, newValue, form)}
                      label={"Cliente"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectStyled
                      options={(servicosData || []).map((servico) => ({
                        id: servico.id,
                        value: servico.nome,
                      }))}
                      label={"Serviço"}
                      onChangeAction={(event, newValue) => handleServicoChange(event, newValue, form)}
                    />
                  </Grid>
                  <FormHeaderMedium>Registros</FormHeaderMedium>
                  <Grid item xs={12} sm={3} md={3}>
                    <QCXFinalCheckboxField
                      name="fatura"
                      label={t('com.muralis.qcx.fatura.labelSingular')}
                      required={undefined}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <QCXFinalCheckboxField
                      name="catalogoProdutos"
                      label={t('com.muralis.qcx.catalogoProdutos.label')}
                      required={undefined}
                    />
                  </Grid>
                  {values?.catalogoProdutos && (
                    <Grid item xs={6}>
                      <MultiSelectClientes
                        name="clientes"
                        label="Clientes para o Catálogo"
                        clientes={clientes}
                        fetchData={false}
                      />
                    </Grid>
                  )}
                  <FormHeaderMedium>Seleção de arquivo</FormHeaderMedium>
                  <Field name="arquivo" validate={required}>
                    {({ input }) => <FileDragAndDrop input={input} accept={MediaType.EXCEL} />}
                  </Field>
                  <Grid item xs={12} textAlign="right">
                    <Button type="submit" color="secondary" variant="contained"
                      disabled={!cliente || !servico || isLoading || !!error}>
                      Importar planilha
                    </Button>
                  </Grid>
                  {spreadsheetData && (
                    <>
                      <FormHeaderMedium>Revisão dos dados</FormHeaderMedium>
                      <FormHeaderMedium>Faturas</FormHeaderMedium>
                      <Grid item xs={12}>
                        <DataTable columns={faturaColumns} rows={faturasData}></DataTable>
                      </Grid>
                      <FormHeaderMedium>Itens</FormHeaderMedium>

                      <Grid item xs={12}>
                        <DataTable columns={itemsColumns} rows={itensData}></DataTable>
                      </Grid>

                      <Grid item xs={12} textAlign="right">
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={resetForm}
                          style={{ margin: '0 10px 0 0' }}
                        >
                          Limpar
                        </Button>
                        <Button
                          color="secondary"
                          variant="contained"
                          disabled={(!values?.fatura && !values?.catalogoProdutos)}
                          onClick={() => handleFaturasSubmit(values)}
                        >
                          Salvar
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </form>
            );
          }}
        </Form>
      </>
    </PageLayout>
  );
};

export default FaturasPlanilhaPage;
