/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../../../../../../shared-components/dados-embarque/base-components/BaseSelectWithOptions';

function SelectProduto({ ...props }: any) {
  const list = useMemo(
    () => [
      {
        id: 'FATURA',
        label: 'FATURA',
        value: 'FATURA',
      },
      {
        id: 'DIDUIMP',
        label: 'DIDUIMP',
        value: 'DIDUIMP',
      },
      {
        id: 'LI',
        label: 'LI',
        value: 'LI',
      }
    ],
    []
  );

  return <BaseSelectWithOptions list={list} {...props} />;
}

export default SelectProduto;
