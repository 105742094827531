export enum LeituraErrorFields {
  'Exportador',
  'Fabricante',
  'Transportador Internacional',
  'Local de Embarque',
  'Agente de Carga',
  'Dispositivo Legal de Exoneração do ICMS',
  'Local do Incoterm',
  'Unidade de Medida'
}

export interface ILeituraError {
  tipo: 'Erro' | 'Aviso';
  origem: 'Correlação' | 'Fatura' | 'Item';
  campo?: LeituraErrorFields;
  mensagem: string;
  item?: string;
  fatura?: string;
}
