import { createAsyncThunk } from '@reduxjs/toolkit';
import { numerarioImportacaoAPI } from './numerarioImportacaoAPI';
import { setErrorFeedback, setSuccessFeedback } from '../feedback/feedbackSlice';

const fetchAllImportacaoNumerarioByFilterPaginationAsync = createAsyncThunk('numerarioImportacao/fetchAllImportacaoNumerarioByFilterPagination', async (data) => {
  const response = await numerarioImportacaoAPI.fetchAllImportacaoNumerarioByFilterPagination(data);
  return {
      data: response.data,
  };
});

const fetchAllExportacaoNumerarioByFilterPaginationAsync = createAsyncThunk('numerarioImportacao/fetchAllExportacaoNumerarioByFilterPagination', async (data) => {
  const response = await numerarioImportacaoAPI.fetchAllExportacaoNumerarioByFilterPagination(data);
  return {
      data: response.data,
  };
});

const fetchAllAsync = createAsyncThunk('numerarioImportacao/fetchAll', async (produto) => {
  const { data } = await numerarioImportacaoAPI.fetchByProduto(produto);
  return { data };
});

const fetchByIdAsync = createAsyncThunk('numerarioImportacao/fetchById', async (data) => {
  const response = await numerarioImportacaoAPI.fetchById(data);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const activateByIdAsync = createAsyncThunk('numerarioImportacao/activateById', async (id) => {
  const { status, data } = await numerarioImportacaoAPI.activateById(id);
  return { response: { status, data } };
});

const inactivateByIdAsync = createAsyncThunk('numerarioImportacao/inactivateById', async (id) => {
  const { status, data } = await numerarioImportacaoAPI.inactivateById(id);
  return { response: { status, data } };
});

const notifyByIdAsync = createAsyncThunk('numerarioImportacao/notifyById', async (id, thunkApi) => {
  const { status, data } = await numerarioImportacaoAPI.notifyNumerario(id);

  if (status === 200) {
    thunkApi.dispatch(
      setSuccessFeedback({
        message: 'Notificação enviada com sucesso!',
      })
    );
  } else {
    thunkApi.dispatch(
      setErrorFeedback({
        message: 'Erro ao enviar notificação.',
      })
    );
  }

  return { response: { status, data } };
});

const numerarioImportacaoThunks = {
  fetchAllAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  notifyByIdAsync,
  fetchAllImportacaoNumerarioByFilterPaginationAsync,
  fetchAllExportacaoNumerarioByFilterPaginationAsync,
};

export {
  numerarioImportacaoThunks,
  fetchAllAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  notifyByIdAsync,
  fetchAllImportacaoNumerarioByFilterPaginationAsync,
  fetchAllExportacaoNumerarioByFilterPaginationAsync,
};
