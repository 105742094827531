import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import _, { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { validate as isUuid } from 'uuid';
import {
  changeToBackgroundCreateMode,
  changeToCreateMode,
  changeToExternalMode,
  loading,
  numerarioImportacaoActions,
  refresh,
  resetBackgroundMode,
  success,
} from '../../../../features/numerario-importacao/numerarioImportacaoSlice';
import {
  selectNumerarioImportacao,
  selectStatus,
  selectMode,
  selectSubMode,
  selectBackgroundMode,
} from '../../../../features/numerario-importacao/numerarioImportacaoSelectors';
import { fetchByIdAsync } from '../../../../features/numerario-importacao/numerarioImportacaoThunks';
import {
  isCreateMode,
  isNoneMode,
  isLoadingStatus,
  isIdleStatus,
  isFailureStatus,
  isBackgroundCreateMode,
  isSubUpdateMode,
  isExternalMode,
} from '../../../../utils/store/store-utils';
import QCXRegistrationFormPageTemplate from '../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import QCXNumerarioImportacaoFinalForm from '../../../../components/numerario-importacao/QCXNumerarioImportacaoFinalForm';
import useAutoChangeMode from '../../../../utils/hooks/mode/useAutoChangeMode';
import { changeModeTo } from '../../../../features/conta-bancaria/contaBancariaSlice';
import { normalizeNumeral, unnormalizeNumeral } from '../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../../utils/hooks/form/field/formatters';
import NumerarioImportacaoUtils from '../../../../utils/general/numerario-importacao/NumerarioImportacaoUtils';
import { setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import { clienteActions } from '../../../../features/cliente/clienteSlice';
import { IDLE_CONTROL } from '../../../../features/config-control';

export default function NumerarioRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const params = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const subMode = useSelector(selectSubMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const numerarioImportacao = useSelector(selectNumerarioImportacao);

  const resetModes = useCallback(() => {
    dispatch(numerarioImportacaoActions.resetMode());
    dispatch(numerarioImportacaoActions.resetSubMode());
    dispatch(numerarioImportacaoActions.resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(numerarioImportacaoActions.resetModel());
  }, []);

  useEffect(() => {
    dispatch(clienteActions.changeControlTo(IDLE_CONTROL));

    const clearOnUnmount = () => {
      resetModes();
      resetModels();

      dispatch(clienteActions.resetControl());
    };

    return clearOnUnmount;
  }, []);

  const isLoading = useMemo(() => isLoadingStatus(status), [status]);

  const isIdle = useMemo(() => isIdleStatus(status), [status]);

  const isFailure = useMemo(() => isFailureStatus(status), [status]);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isExternal = useMemo(() => isExternalMode(mode), [mode]);

  const isSubUpdate = useMemo(() => isSubUpdateMode(subMode), [subMode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const handleChangeToCreate = useCallback(() => {
    dispatch(changeToCreateMode());
  }, []);

  const handleChangeToBackgroundCreate = useCallback(() => {
    dispatch(changeToBackgroundCreateMode());
  }, []);

  const handleResetBackgroundMode = useCallback(() => {
    dispatch(resetBackgroundMode());
  }, []);

  const handleChangeToExternalMode = useCallback(() => {
    dispatch(changeToExternalMode());
  }, []);

  const fetchById = useCallback((numerarioImportacaoId) => {
    dispatch(fetchByIdAsync(numerarioImportacaoId));
  }, []);

  const normalize = useCallback((unnormalizedData) => {
    const data = _.omit(unnormalizedData, ['ignorableFields', 'draftFields']);

    const unnormalizedCapas = data?.capas || [];

    const normalizedCapas = unnormalizedCapas.map((capa) => {
      const unnormalizedLancamentos = capa?.lancamentos || [];
      const normalizedLancamentos = unnormalizedLancamentos.map(({ id, totalMoeda, totalReal, ...lancamento }) => ({
        id: isUuid(id) ? undefined : id,
        totalMoeda: normalizeNumeral(totalMoeda),
        totalReal: normalizeNumeral(totalMoeda),
        ...lancamento,
      }));

      return {
        ...capa,
        ...(!_.isEmpty(normalizedLancamentos)
          ? {
              lancamentos: normalizedLancamentos,
            }
          : {}),
        ...(capa?.taxaCambio
          ? {
              taxaCambio: normalizeNumeral(capa?.taxaCambio),
            }
          : {}),
      };
    });

    return {
      ...data,
      importador: data?.importador?.id
        ? {
            id: data?.importador?.id,
          }
        : undefined,
      servico: data?.servico?.id
        ? {
            id: data?.servico?.id,
          }
        : undefined,
      viaTransporte: data?.viaTransporte?.id
        ? {
            id: data?.viaTransporte?.id,
          }
        : undefined,
      capas: normalizedCapas,
    };
  }, []);

  const unnormalize = useCallback((data) => {
    const normalizedCapas = data?.capas || [];

    const unnormalizedCapas = normalizedCapas.map((capa) => {
      const unnormalizedLancamentos = capa?.lancamentos || [];
      const normalizedLancamentos = unnormalizedLancamentos.map(({ totalMoeda, totalReal, ...lancamento }) => ({
        ...lancamento,
        totalMoeda: unnormalizeNumeral(totalMoeda, formatBrazilianNumericDecimal(2)),
        totalReal: unnormalizeNumeral(totalReal, formatBrazilianNumericDecimal(2)),
      }));

      return {
        ...capa,
        ...(!_.isEmpty(normalizedLancamentos)
          ? {
              lancamentos: normalizedLancamentos,
            }
          : {}),
        taxaCambio: unnormalizeNumeral(capa?.taxaCambio, formatBrazilianNumericDecimal(5)),
      };
    });

    const sortedCapasByItem = _.sortBy(unnormalizedCapas, 'item');

    return {
      ...data,
      importador: {
        ...data?.importador,
        percentualSpread: unnormalizeNumeral(data?.importador?.percentualSpread, formatBrazilianNumericDecimal(2)),
      },
      capas: sortedCapasByItem,
    };
  }, []);

  const handleAfterCreate = useCallback(
    async (data) => {
      const executeDebounced = debounce(async () => {
        const numero = NumerarioImportacaoUtils.formatIdentificacao(data?.identificacao);

        history.push(t('com.muralis.qcx.url.importacaoSolicitacaoNumerarioQuery', { q: numero }));

        dispatch(refresh());
        dispatch(success());

        dispatch(
          setSuccessFeedback({
            message: t('com.muralis.qcx.mensagem.numerarioNumeroSolicitado', { numero }),
          })
        );
      }, 500);

      dispatch(loading());

      executeDebounced();
    },
    [history]
  );

  const handleAlternativeSave = useCallback(async () => {
    const normalizedData = normalize(numerarioImportacao);

    if (isCreate || isBackgroundCreate) {
      await handleAfterCreate(normalizedData);
    }
  }, [isCreate, normalize, handleAfterCreate, isBackgroundCreate, numerarioImportacao]);

  const model = useMemo(
    () => (isCreate && !isBackgroundCreate ? {} : unnormalize(numerarioImportacao)),
    [isCreate, isBackgroundCreate, numerarioImportacao, unnormalize]
  );

  const actionName = useMemo(() => {
    if (isCreate || isNone) return t('com.muralis.qcx.acoes.nova');
    if (isSubUpdate) return t('com.muralis.qcx.acoes.alterar');
    return t('com.muralis.qcx.acoes.visualizar');
  }, [isNone, isCreate, isSubUpdate]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloImportacao'),
          name: t('com.muralis.qcx.importacao.label'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.importacaoSolicitacaoNumerario'),
          name: t('com.muralis.qcx.numerarioImportacao.labelPlural'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.solicitacao'),
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [actionName]
  );

  const pageTitle = useMemo(() => {
    const formmatedIdentificacao = NumerarioImportacaoUtils.formatIdentificacao(model?.identificacao);

    if (isNone || isCreate || isBackgroundCreate) {
      return formmatedIdentificacao
        ? t('com.muralis.qcx.numerarioImportacao.novaSolicitacaoNumero', { numero: formmatedIdentificacao })
        : t('com.muralis.qcx.numerarioImportacao.novaSolicitacao');
    }

    return formmatedIdentificacao
      ? t('com.muralis.qcx.numerarioImportacao.numerarioNumero', { numero: formmatedIdentificacao })
      : t('com.muralis.qcx.numerarioImportacao.label');
  }, [model, isNone, isCreate, isBackgroundCreate]);

  useAutoChangeMode((currentMode) => dispatch(changeModeTo(currentMode)), [isExternal]);

  useEffect(() => {
    const handleFetchById = () => {
      if (!isEmpty(params?.id)) {
        fetchById(params?.id);

        handleChangeToExternalMode();
      }
    };

    handleFetchById();
  }, [params, handleChangeToExternalMode]);

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isIdle={isIdle}
      isLoading={isLoading}
      isFailure={isFailure}
      isCreate={isCreate}
      isExternal={isExternal}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXNumerarioImportacaoFinalForm
          initialValues={model}
          normalize={normalize}
          unnormalize={unnormalize}
          handleAlternativeSave={handleAlternativeSave}
          authInfo={authInfo}
          requiredRoles={[NumerarioImportacaoUtils.ROLE]}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
