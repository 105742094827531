import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { cidadeActions } from '../../features/cidade/cidadeSlice';
import { estadoActions } from '../../features/estado/estadoSlice';
import { paisActions } from '../../features/pais/paisSlice';
import QCXBox from '../../shared-components/box/QCXBox';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalOnblurMaskedField from '../../shared-components/final-onblur-masked-field/QCXFinalOnblurMaskedField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectCidadeAutocomplete from '../../shared-components/select-cidade/QCXSelectCidadeAutocomplete';
import QCXSelectEstadoAutocomplete from '../../shared-components/select-estado/QCXSelectEstadoAutocomplete';
import { formatCep, formatCnpj } from '../../utils/hooks/form/field/formatters';
import { numberOnly, parseCep, parseCnpj } from '../../utils/hooks/form/field/parsers';
import useValidators from '../../utils/hooks/useValidators';
import { required as requiredValidator, validCep, validCnpj } from '../../utils/validators/field/validator';
import { configurePaisGetterByCode, PAIS_BRASIL_CODE } from '../../shared-components/select-pais/paisUtils';

export default function QCXTransportadoraTecnicalDataForm({
  initialValues,
  handleSubmit,
  isCreate,
  isConsult,
  controlComponentProps,
  authInfo = {},
  requiredRoles = [],
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const paises = useSelector(paisActions.selectPaises);

  useEffect(() => {
    dispatch(paisActions.fetchAllAsync());

    const resetDependencies = () => {
      dispatch(cidadeActions.resetList());
      dispatch(estadoActions.resetList());
      dispatch(paisActions.resetList());
    };

    return resetDependencies;
  }, []);

  const internalInitialValues = useMemo(() => {
    if (isCreate) {
      const getPaisByCode = configurePaisGetterByCode(paises);
      const defaultPaisBrasil = getPaisByCode(PAIS_BRASIL_CODE);

      return {
        ...initialValues,
        endereco: {
          ...initialValues?.endereco,
          cidade: {
            ...initialValues?.endereco?.cidade,
            estado: {
              ...initialValues?.endereco?.cidade?.estado,
              pais: defaultPaisBrasil,
            },
          },
        },
      };
    }

    return initialValues;
  }, [paises, isCreate, initialValues]);

  useEffect(() => {
    if (internalInitialValues?.endereco?.cidade?.estado?.pais?.id) {
      dispatch(
        estadoActions.fetchByFilterAsync([
          { name: 'pais', value: internalInitialValues.endereco.cidade.estado.pais.id },
        ])
      );
    }
  }, [internalInitialValues]);

  const validateCnpj = useMemo(() => useValidators([requiredValidator, validCnpj]), [requiredValidator, validCnpj]);

  const validateCep = useMemo(() => useValidators([requiredValidator, validCep(8, 9)]), [requiredValidator, validCep]);

  const handlePaisChange = () => (value) => {
    dispatch(estadoActions.fetchByFilterAsync([{ name: 'pais', value }]));
  };

  const handleEstadoChange = (form) => (value) => {
    form.change('endereco.cidade.id', undefined);
    form.change('endereco.cidade.estado.id', value);

    dispatch(cidadeActions.fetchByFilterAsync([{ name: 'estado', value }]));
  };

  const handleCidadeChange = (form) => (value) => {
    form.change('endereco.cidade.id', value);
  };

  const listeners = [
    { name: 'endereco.cidade.estado.pais.id', fn: handlePaisChange },
    { name: 'endereco.cidade.estado.id', fn: handleEstadoChange },
    { name: 'endereco.cidade.id', fn: handleCidadeChange },
  ];

  return (
    <QCXFormStepWizard
      initialValues={internalInitialValues}
      onSubmit={handleSubmit}
      onChangeListeners={listeners}
      controlComponentProps={controlComponentProps}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
    >
      {({ values }) => (
        <>
          <Typography variant="h6" color="primary">
            <QCXBox fontWeight="fontWeightBold" pt={3}>
              {t('com.muralis.qcx.dadosTecnicos').toUpperCase()}
            </QCXBox>
          </Typography>
          <Grid container spacing={2} style={{ marginTop: '1rem' }}>
            {!isCreate && (
              <Grid item xs={12} sm={3} md={2}>
                <QCXFinalTextField
                  id="text-field-id-transpotadora"
                  key="text-field-id-transpotadora"
                  name="id"
                  label={t('com.muralis.qcx.codigo')}
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={12} sm={isCreate ? 6 : 9} md={isCreate ? 7 : 5}>
              <QCXFinalTextField
                id="text-field-id-transpotadora"
                key="text-field-id-transpotadora"
                name="description"
                label={t('com.muralis.qcx.transportadora.nomeTransportadora')}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <QCXFinalTextField
                id="text-field-pessoa-juridica-nome-resumido"
                key="text-field-pessoa-juridica-nome-resumido"
                label={t('com.muralis.qcx.nomeResumido')}
                name="pessoaJuridica.nomeResumido"
                maxLength={50}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalOnblurMaskedField
                id="masked-text-field-pessoa-juridica-cnpj"
                key="masked-text-field-pessoa-juridica-cnpj"
                name="pessoaJuridica.cnpj"
                label={t('com.muralis.qcx.empresa.CNPJ')}
                maxLength={18}
                format={formatCnpj}
                parse={parseCnpj}
                validate={validateCnpj}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <QCXFinalTextField
                id="text-field-pessoa-juridica-nome"
                key="text-field-pessoa-juridica-nome"
                label={t('com.muralis.qcx.empresa.razaoSocial')}
                name="pessoaJuridica.nome"
                maxLength={100}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <QCXFinalOnblurMaskedField
                id="masked-text-field-endereco-cep"
                key="masked-text-field-endereco-cep"
                label={t('com.muralis.qcx.endereco.CEP')}
                name="endereco.cep"
                maxLength={9}
                format={formatCep}
                parse={parseCep}
                validate={validateCep}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <QCXFinalTextField
                id="text-field-endereco-logradouro"
                key="text-field-endereco-logradouro"
                label={t('com.muralis.qcx.endereco.logradouro')}
                name="endereco.logradouro"
                maxLength={150}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <QCXFinalTextField
                id="text-field-endereco-numero"
                key="text-field-endereco-numero"
                label={t('com.muralis.qcx.endereco.numero')}
                name="endereco.numero"
                maxLength={10}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalTextField
                id="text-field-endereco-complemento"
                key="text-field-endereco-complemento"
                label={t('com.muralis.qcx.endereco.complemento')}
                name="endereco.complemento"
                maxLength={50}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalTextField
                id="text-field-endereco-bairro"
                key="text-field-endereco-bairro"
                label={t('com.muralis.qcx.endereco.bairro')}
                name="endereco.bairro"
                maxLength={70}
                validate={requiredValidator}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXSelectEstadoAutocomplete
                id="select-endereco-estado"
                key="select-endereco-estado"
                name="endereco.cidade.estado.id"
                label={t('com.muralis.qcx.endereco.estado')}
                fieldProps={{ validate: requiredValidator }}
                initialValues={values}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXSelectCidadeAutocomplete
                name="endereco.cidade.id"
                label={t('com.muralis.qcx.endereco.cidade')}
                initialValues={values}
                fieldProps={{ validate: requiredValidator }}
                disabled={isConsult}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalTextField
                id="text-field-pessoa-juridica-inscricao-estadual"
                key="text-field-pessoa-juridica-inscricao-estadual"
                label={t('com.muralis.qcx.empresa.inscricaoEstadual')}
                name="pessoaJuridica.inscricaoEstadual"
                parse={numberOnly}
                maxLength={15}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <QCXFinalTextField
                id="text-field-pessoa-juridica-inscricao-municipal"
                key="text-field-pessoa-juridica-inscricao-municipal"
                label={t('com.muralis.qcx.empresa.inscricaoMunicipal')}
                name="pessoaJuridica.inscricaoMunicipal"
                parse={numberOnly}
                maxLength={15}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={isCreate ? 6 : 4} md={3}>
              <QCXFinalCheckboxField
                id="checkbox-field-importador"
                key="checkbox-field-importador"
                name="importador"
                label={t('com.muralis.qcx.importador.label')}
                disabled={isConsult}
              />
            </Grid>
            <Grid item xs={12} sm={isCreate ? 6 : 4} md={3}>
              <QCXFinalCheckboxField
                id="checkbox-field-exportador"
                key="checkbox-field-exportador"
                name="exportador"
                label={t('com.muralis.qcx.exportador.label')}
                disabled={isConsult}
              />
            </Grid>
          </Grid>
        </>
      )}
    </QCXFormStepWizard>
  );
}
