
import {
    Typography, Grid, Paper, TableContainer, Table,
    TableRow, TableCell, TableBody
} from '@material-ui/core';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

export interface TransmissaoLiData {
    versao: string;
    dataHoraEnvioFormatada: string;
    listaLIVORetorno: ListaLIVoRetorno;
    cpfUsuario: string;
    versaoValida: boolean;
    idLote: string;
}

interface ListaLIVoRetorno {
    li: LiDto;
}

interface LiDto {
    importador: Importador;
    numeroLI: string;
    mensagemDiagnostico: MensagemDiagnostico[];
    dtRegistro: string;
    idSolicitacao: string;
}

interface Importador {
    numero: string;
    tipoImportador: string;
}

interface MensagemDiagnostico {
    mensagemDiagnostico: string[];
}

const useStyles = makeStyles({
    chave: {
        display: 'flex',
        fontWeight: 'bold',
        fontSize: '1rem',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
    },
    valor: {
        fontSize: '1rem',
        marginLeft: '0.5rem',
        fontWeight: 'normal',
        color: '#212121',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        flex: 1,
    },
    tableHeaderCell: {
        fontWeight: 'bold',
        color: '#212121',
    },
    tableCell: {
        borderBottom: '1px solid #e0e0e0',
    },
    mensagem: {
        wordWrap: 'break-word',
        whiteSpace: 'normal',
    },
    titulo: {
        fontSize: '1.rem',
        fontWeight: 'bold',
        color: 'secondary',
    },
    mensagemErro: {
        wordWrap: 'break-word',
        whiteSpace: 'normal',
        display: 'flex',
        gap: "9px",
        alignItems: 'center',
    },
});

export const RenderDadosLiTransmissao = ({ transmissao }: { transmissao: TransmissaoLiData }) => {
    const classes = useStyles();
    const { t } = useTranslation();


    const versao = t('com.muralis.qcx.transmissaoLi.versao');
    const dataEnvio = t('com.muralis.qcx.transmissaoLi.dataEnvio');
    const cpfUsuario = t('com.muralis.qcx.transmissaoLi.cpfUsuario');
    const versaoValida = t('com.muralis.qcx.transmissaoLi.versaoValida');
    const idLote = t('com.muralis.qcx.transmissaoLi.idLote');
    const numeroLI = t('com.muralis.qcx.transmissaoLi.numeroLI');
    const dtRegistro = t('com.muralis.qcx.transmissaoLi.dtRegistro');
    const idSolicitacao = t('com.muralis.qcx.transmissaoLi.idSolicitacao');
    const numeroImportador = t('com.muralis.qcx.transmissaoLi.numeroImportador');
    const tipoImportador = t('com.muralis.qcx.transmissaoLi.tipoImportador');
    const mensagemDiagnostico = t('com.muralis.qcx.transmissaoLi.mensagemDiagnostico');

    return (
        <Paper style={{ padding: '20px' }}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <Typography variant="h6" color='primary' className={classes.chave}>
                        {dataEnvio + ": "} <span className={classes.valor}>{transmissao.dataHoraEnvioFormatada}</span>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" color='primary' className={classes.chave}>
                        {versao + ": "} <span className={classes.valor}>{transmissao.versao}</span>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" color='primary' className={classes.chave}>
                        {cpfUsuario + ": "} <span className={classes.valor}>{transmissao.cpfUsuario}</span>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" color='primary' className={classes.chave}>
                        {versaoValida + ": "} <span className={classes.valor}>{transmissao.versaoValida ? "Sim" : "Não"}</span>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" color='primary' className={classes.chave}>
                        {idLote + ": "} <span className={classes.valor}>{transmissao.idLote}</span>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" color='primary' className={classes.chave}>
                        {numeroLI + ": "} <span className={classes.valor}>{transmissao.listaLIVORetorno.li.numeroLI}</span>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" color='primary' className={classes.chave}>
                        {dtRegistro + ": "} <span className={classes.valor}>{transmissao.listaLIVORetorno.li.dtRegistro}</span>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" color='primary' className={classes.chave}>
                        {idSolicitacao + ": "} <span className={classes.valor}>{transmissao.listaLIVORetorno.li.idSolicitacao}</span>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" color='primary' className={classes.chave}>
                        {numeroImportador + ": "} <span className={classes.valor}>{transmissao.listaLIVORetorno.li.importador.numero}</span>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" color='primary' className={classes.chave}>
                        {tipoImportador + ": "} <span className={classes.valor}>
                            {transmissao.listaLIVORetorno.li.importador.tipoImportador === "J" ? "Jurídico" : "Físico"}
                        </span>
                    </Typography>
                </Grid>


                <TableContainer>
                    <Typography color='secondary' variant='h6' className={classes.titulo} style={{ textAlign: 'center', margin: '1rem auto' }}>
                        {mensagemDiagnostico}
                    </Typography>
                    <Table>
                        <TableBody>
                            {transmissao.listaLIVORetorno.li.mensagemDiagnostico[0].mensagemDiagnostico.map((mensagem, index) => (
                                <TableRow key={index}>
                                    <TableCell className={classes.mensagemErro}>
                                        <span><ErrorOutlineIcon color="error" /></span>
                                        {mensagem}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Paper>
    );

};
