import { Box, Grid } from '@material-ui/core';
import { useFormState, useForm } from 'react-final-form';
import _ from 'lodash';
import { useMemo, useCallback, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXFinalBondManager from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManager';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';

export default function QCXSelectManyCodigoInterno({ ...props }) {
  const { title, name, inputConfig, isConsult, isAlternativeReasonToDisabled, autoCompleteProps = {} } = props;

  const { t } = useTranslation();

  const form = useForm();
  const { values } = useFormState();

  useEffect(() => {
    const previousInternalCodes = values?.codigos ?? [];
    const isPartnumberInInternalCodes = previousInternalCodes.find((c) => c.codigo === values.partnumber);

    if (!values?.usePartnumberAsInternalCode && isPartnumberInInternalCodes)
      form.change('usePartnumberAsInternalCode', true);
    if (values?.usePartnumberAsInternalCode && !isPartnumberInInternalCodes)
      form.change('usePartnumberAsInternalCode', false);

    const isSecondaryCodeInInternalCodes = previousInternalCodes.find((c) => c.codigo === values.codigoSecundario);

    if (!values?.useSecondaryCodeAsInternalCode && isSecondaryCodeInInternalCodes)
      form.change('useSecondaryCodeAsInternalCode', true);
    if (values?.useSecondaryCodeAsInternalCode && !isSecondaryCodeInInternalCodes)
      form.change('useSecondaryCodeAsInternalCode', false);
  }, [values]);

  const codigoInternoProps = useMemo(
    () => ({
      name,
      columns: [
        {
          field: 'codigo',
          headerName: 'Código',
          type: 'string',
          flex: 300,
          valueGetter: ({ row }) => row?.codigo,
        },
      ],
    }),
    []
  );

  const codigoInternoFormProps = useMemo(
    () => ({
      rootName: `ignorableFields`,
      fields: [
        {
          name: 'codigoAtual',
          label: 'Código',
          required: false,
        },
      ],
    }),
    []
  );

  const handleAlreadyExistsFornecedor = useCallback((currentVinculo, currentVinculosList) => {
    if (_.isArray(currentVinculosList)) {
      const vinculoRepetido = currentVinculosList.find((el) => el.codigo === currentVinculo.codigoAtual);
      if (vinculoRepetido) return true;
    }
    return false;
  }, []);

  const handleAddCodigoInterno = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
      if (!currentVinculo?.codigoAtual) {
        return;
      }

      const novoCodigoInterno = {
        id: uuid(),
        codigo: currentVinculo.codigoAtual,
      };

      if (_.isArray(currentVinculos)) {
        form.change('codigos', [...currentVinculos, novoCodigoInterno]);
      } else {
        form.change('codigos', [novoCodigoInterno]);
      }

      form.change('ignorableFields.codigoAtual', null);
    },
    [values]
  );

  const handleUpdateCodigoInterno = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      if (!currentVinculo?.id) {
        return;
      }
      if (!_.isArray(currentVinculos)) {
        return;
      }

      const { id } = currentVinculo;
      const vinculos = [...currentVinculos];
      const index = vinculos.findIndex((el) => el.id === id);

      if (index === -1) {
        return;
      }

      vinculos[index].codigo = currentVinculo.codigoAtual;
      form.change('codigos', [...vinculos]);

      form.change('ignorableFields.codigoAtual', null);
    },
    [values]
  );

  const handlePartnumberAsInternalCode = (e) => {
    const { checked } = e.target;

    const previousInternalCodes = values?.codigos ?? [];

    const partnumberInternalCodeIndex = previousInternalCodes.findIndex((c) => c.codigo === values.partnumber);

    if (checked && partnumberInternalCodeIndex === -1) {
      form.change('codigos', [
        ...previousInternalCodes,
        {
          id: uuid(),
          codigo: values?.partnumber,
        },
      ]);
    }

    if (!checked && partnumberInternalCodeIndex !== -1) {
      const copy = [...previousInternalCodes];
      copy.splice(partnumberInternalCodeIndex, 1);
      form.change('codigos', copy);
    }
  };

  const handleSecondaryCodeAsInternalCode = (e) => {
    const { checked } = e.target;

    const previousInternalCodes = values?.codigos ?? [];

    const secondaryCodeInternalCodeIndex = previousInternalCodes.findIndex((c) => c.codigo === values.codigoSecundario);

    if (checked && secondaryCodeInternalCodeIndex === -1) {
      form.change('codigos', [
        ...previousInternalCodes,
        {
          id: uuid(),
          codigo: values?.codigoSecundario,
        },
      ]);
    }

    if (!checked && secondaryCodeInternalCodeIndex !== -1) {
      const copy = [...previousInternalCodes];
      copy.splice(secondaryCodeInternalCodeIndex, 1);
      form.change('codigos', copy);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <QCXFormSubtitle title={title} />

        {values.partnumber && (
          <Box>
            <QCXFinalCheckboxField
              onChange={handlePartnumberAsInternalCode}
              id="usar-partnumber-field"
              key="usar-partnumber-field"
              name="usePartnumberAsInternalCode"
              label={t('com.muralis.qcx.usePartnumberAsInternalCode')}
              disabled={isConsult}
              required={undefined}
            />
          </Box>
        )}

        {values.codigoSecundario && (
          <Box marginTop={1}>
            <QCXFinalCheckboxField
              onChange={handleSecondaryCodeAsInternalCode}
              id="usar-codigo-secundario-field"
              key="usar-codigo-secundario-field"
              name="useSecondaryCodeAsInternalCode"
              label={t('com.muralis.qcx.useSecondaryCodeAsInternalCode')}
              disabled={isConsult}
              required={undefined}
            />
          </Box>
        )}
      </Grid>

      <QCXFinalBondManager
        isParentConsult={isConsult}
        listProps={codigoInternoProps}
        formProps={codigoInternoFormProps}
        handleAdd={handleAddCodigoInterno}
        handleUpdate={handleUpdateCodigoInterno}
        handleAlreadyExists={handleAlreadyExistsFornecedor}
        forceShowDelete
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <QCXFinalTextField
            id="text-partnumber-field"
            key="text-partnumber-field"
            name="ignorableFields.codigoAtual"
            label="Código Interno"
            disabled={isConsult}
          />
        </Grid>
      </QCXFinalBondManager>
    </>
  );
}
