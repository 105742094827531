import React, { useCallback, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Send as SendIcon } from '@material-ui/icons';
import { CLOSED_STATUS, DONE_STATUS, FAIL_STATUS, isOpenStatus } from '../../utils/hooks/form/dialog/formDialogUtils';
import QCXFinalFormDialog from '../../shared-components/dialog/QCXFinalFormDialog';
import QCXMoveFocusInside from '../focus-lock/QCXMoveFocusInside';
import { followUpActions } from '../../features/follow-up/followUpSlice';
import QCXFinalMultilineTextField from '../../shared-components/final-text-field/QCXFinalMultilineTextField';
import { PERFORMING_ACTION_STATUS } from '../../features/status';
import { followUpAPI } from '../../features/follow-up/followUpAPI';
import { followUpSelectors } from '../../features/follow-up/followUpSelectors';
import QCXCircularProgress from '../../shared-components/progress/QCXCircularProgress';
import { isPerformingActionStatus } from '../../utils/store/store-utils';
import { setErrorFeedback } from '../../features/feedback/feedbackSlice';

export default function QCXEmailDocumentoFollowUpFormDialog({
  parentModel,
  status,
  handleStatus,
  handleClearSelection,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const statusFollowUp = useSelector(followUpSelectors.selectStatus);

  const emailModel = useSelector(followUpSelectors.selectRelatedEmailModel);

  const performingAction = useMemo(() => isPerformingActionStatus(statusFollowUp), [statusFollowUp]);

  const normalize = useCallback(
    (unnormalizedData) => ({
      ...unnormalizedData,
      arquivos: (unnormalizedData?.arquivos || []).map((arquivo) => ({
        id: arquivo?.id,
      })),
    }),
    []
  );

  const handleSubmit = useCallback(
    async (values) => {
      try {
        dispatch(
          followUpActions.changeStatusTo({
            status: PERFORMING_ACTION_STATUS,
          })
        );

        const data = normalize(values);

        const response = await followUpAPI.sendEmailBy(data);

        if (response.status === 200) {
          const responseMessage = t('com.muralis.qcx.mensagem.emailEnviadoContatoClienteProcessoFollowUP', {
            numero: parentModel?.numero,
          });

          const simplifiedResponse = {
            status: response?.status,
            message: responseMessage,
            data: response?.data,
          };

          dispatch(followUpActions.resetRelatedEmailModel());
          dispatch(followUpActions.success());
          dispatch(followUpActions.setResponse(simplifiedResponse));

          dispatch(followUpActions.refresh());

          handleClearSelection();
          handleStatus(DONE_STATUS);
        }
      } catch (error) {
        handleStatus(FAIL_STATUS);

        const isUnknownError =
          _.isEmpty(error?.response) || _.isEmpty(error?.response?.data) || _.isEmpty(error?.response?.data?.message);

        const errorMessage = isUnknownError
          ? t('com.muralis.qcx.erro.erroEnviarEmailProcessoFollowUp', { numero: parentModel?.numero })
          : t('com.muralis.qcx.erro.erroEnviarEmailProcessoFollowUpEspecifico', {
              numero: parentModel?.numero,
              mensagem: error?.response?.data?.message,
            });

        dispatch(followUpActions.failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
        dispatch(followUpActions.setResponse(error.response));
      }
    },
    [normalize, parentModel, handleStatus, handleClearSelection]
  );

  const handleCloseFormDialog = useCallback(() => handleStatus(CLOSED_STATUS), [handleStatus]);

  const open = useMemo(() => isOpenStatus(status), [status]);

  const formDialogTitle = useMemo(
    () => (
      <>
        {t('com.muralis.qcx.documento.enviarDocumentosEmail').toUpperCase()}
        <br />
        <Typography variant="caption">
          <Trans i18nKey="com.muralis.qcx.mensagem.observacaoRemetenteDestinarioProcesso">
            <em>
              <strong />
              <ins>{{ numero: parentModel?.numero }}</ins>
            </em>
          </Trans>
        </Typography>
      </>
    ),
    [parentModel]
  );

  const formDialogContent = useMemo(() => t('com.muralis.qcx.mensagem.preenchaCampoMensagemCorpoEmail'), []);

  const formDialogActionsProps = useMemo(
    () => ({
      button: {
        submit: {
          description: performingAction ? t('com.muralis.qcx.acoes.enviando') : t('com.muralis.qcx.acoes.enviar'),
          size: 'small',
          startIcon: performingAction ? <QCXCircularProgress color="disabled" size={20} /> : <SendIcon />,
          disabled: performingAction,
        },
        cancel: {
          description: t('com.muralis.qcx.acoes.cancelar'),
          size: 'small',
          disabled: performingAction,
        },
      },
    }),
    [performingAction]
  );

  return (
    <QCXFinalFormDialog
      key="form-dialog-enviar-email-documento-etapa-follow-up"
      id="form-dialog-enviar-email-documento-etapa-follow-up"
      title={formDialogTitle}
      content={formDialogContent}
      dialogActionsProps={formDialogActionsProps}
      open={open}
      initialValues={emailModel}
      onSubmit={handleSubmit}
      onClose={handleCloseFormDialog}
    >
      {({ submitting }) => (
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <QCXMoveFocusInside>
              <QCXFinalMultilineTextField
                id="multiline-text-field-mensagem-email"
                key="multiline-text-field-mensagem-email"
                name="mensagem"
                label={t('com.muralis.qcx.mensagem.label')}
                rows={10}
                disabled={submitting || performingAction}
              />
            </QCXMoveFocusInside>
          </Grid>
        </Grid>
      )}
    </QCXFinalFormDialog>
  );
}
