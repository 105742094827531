import axios from 'axios';
import { saveAs } from 'file-saver';
interface ApiResponse {
  data?: any; // Adapte conforme a estrutura esperada da sua resposta
  statusCode: number;
  message?: string;
}

export async function postCrawler(token: string, selectedUnidade: string, url: string, body: any): Promise<ApiResponse> {
  try {
    const response = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        quickcomexTenant: selectedUnidade,
      },
    });

    return {
      data: response.data,
      statusCode: response.status,
      message: response?.data, 
    };

  } catch (error) {
    if (axios.isAxiosError(error)) {
      return {
        data: error.response?.data,
        statusCode: error.response?.status || 500,
        message: error.response?.data || "Erro interno do servidor",
      };
    } else {
      console.error("Erro ao fazer a requisição:", error);
      return {
        statusCode: 500,
        message: "Erro interno do servidor",
      };
    }
  }
}