import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../../shared-components/dados-embarque/base-components/BaseSelectWithOptions';

function SelectTipoImportacao({ ...props }: { label: string; name: string }) {
  const list = useMemo(
    () => [
      {
        id: 'Própria',
        label: 'Própria',
        value: 'PROPRIA',
      },
      {
        id: 'Sob Encomenda',
        label: 'Sob Encomenda',
        value: 'SOBENCOMENDA',
      },
    ],
    []
  );

  return <BaseSelectWithOptions list={list} {...props} />;
}

export default SelectTipoImportacao;
