import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUnidadeSelecionada,
  selectRelatedUnidadeNegocioModel,
  fetchUnidadesNegocioByIdAsync,
  selectStatus,
} from '../../../features/usuario-logado/usuarioLogadoSlice';
import { ALTERNATIVE_LOADING_STATUS } from '../../../features/status';

export default function useCurrentUnidadeNegocio() {
  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const selectedId = useSelector(selectUnidadeSelecionada);
  const selectedUnidadeNegocioModel = useSelector(selectRelatedUnidadeNegocioModel);

  useEffect(() => {
    if (selectedId) {
      dispatch(fetchUnidadesNegocioByIdAsync(selectedId));
    }
  }, [selectedId]);

  const currentData =
    status === ALTERNATIVE_LOADING_STATUS || selectedUnidadeNegocioModel?.id !== selectedId
      ? selectedId
      : selectedUnidadeNegocioModel;

  return currentData;
}
