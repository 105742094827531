import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { isEmpty } from 'lodash';
import {
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  resetModel,
  selectEtapa,
  selectMode,
  setError,
  setModel,
  setResponse,
  success,
  addToList,
  updateOnList,
} from '../../../../../features/etapa/etapaSlice';
import { isCreateMode, isConsultMode, isUpdateMode, isNoneMode } from '../../../../../utils/store/store-utils';
import { register, save } from '../../../../../features/etapa/etapaAPI';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';

import QCXEtapaFinalForm from '../../../../../components/etapa/QCXEtapaFinalForm';
import EtapaUtils from '../../../../../utils/general/etapa/EtapaUtils';
import { setSuccessFeedback } from '../../../../../features/feedback/feedbackSlice';

export default function EtapaRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const mode = useSelector(selectMode);
  const etapa = useSelector(selectEtapa);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const handleCancelUpdate = () => {
    if (id) {
      dispatch(fetchByIdAsync(id));
    }
    handleChangeToConsult();
  };

  const normalize = useCallback(
    (unnormalizedData) => ({
      ...unnormalizedData,
      publica: EtapaUtils.parseFromSingletonList(unnormalizedData?.publica || []),
    }),
    []
  );

  const unnormalize = useCallback(
    (normalizedData) => ({
      ...normalizedData,
      publica: EtapaUtils.parseToSingletonList(normalizedData?.publica),
    }),
    []
  );

  const handleDispatchSetModel = useCallback(
    (data) => {
      dispatch(setModel(normalize(data)));
    },
    [normalize]
  );

  const create = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await register(data);

        if (response?.status === 201) {
          dispatch(resetModel());

          const created = response?.data;

          handleDispatchSetModel(created);
          dispatch(addToList({ data: created }));

          const handleResultWithDebounce = debounce(() => {
            history.push(t('com.muralis.qcx.url.moduloGeraisServicosEtapas'));

            dispatch(success());
            dispatch(
              setSuccessFeedback({
                message: t('com.muralis.qcx.mensagem.etapaCadastrada', { etapa: data?.description }),
              })
            );
          }, 500);

          handleResultWithDebounce();
        }
      } catch (error) {
        dispatch(failure());
        let errorMessage = t('com.muralis.qcx.erro.erroSalvarDadosEtapa');
        if (error?.response && error?.response?.data) {
          errorMessage = t('com.muralis.qcx.erro.erroSalvarDadosEtapaMensagem', { erro: error?.response.data.message });
        }
        dispatch(
          setError({
            message: errorMessage,
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(
              setSuccessFeedback({ message: t('com.muralis.qcx.mensagem.etapaSalva', { etapa: saved?.description }) })
            );

            dispatch(setModel(saved));
            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
          handleChangeToConsult();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(
          setError({
            message: t('com.muralis.qcx.erro.erroSalvarDadosEtapaEspecifico', {
              etapa: data?.description,
              erro: response?.data?.message,
            }),
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data) => {
    const normalizedData = normalize(data);

    if (isUpdate) {
      await update(normalizedData);
    } else {
      await create(normalizedData);
    }
  };

  const model = useMemo(() => (!isCreate ? unnormalize(etapa) : {}), [isCreate, etapa, unnormalize]);

  const actionName = useMemo(() => {
    if (isCreate || isNone) return t('com.muralis.qcx.acoes.nova');
    if (isConsult) return t('com.muralis.qcx.acoes.visualizar');
    return t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.cadastros'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloCadastrosGerais'),
          name: t('com.muralis.qcx.gerais'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.servico.labelPlural'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloGeraisServicosEtapas'),
          name: t('com.muralis.qcx.etapa.labelPlural'),
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [actionName]
  );

  const pageTitle = useMemo(
    () =>
      isNone || isCreate
        ? t('com.muralis.qcx.etapa.novaEtapa')
        : t('com.muralis.qcx.etapa.etapaExistente', { ID: etapa?.description || etapa?.id || '-' }),
    [isNone, isCreate, etapa]
  );

  const breadcrumbsProps = {
    maxItems: 6,
  };

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      breadcrumbsProps={breadcrumbsProps}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXEtapaFinalForm
          model={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          authInfo={authInfo}
          requiredRoles={['etapa']}
          disablePristine
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
