import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from 'react-router';
import { Button, Dialog, FormControl, Grid, Typography } from '@mui/material';
import MultiSelectStyled, { SimpleMultiSelectOption } from '../../../common/components/input/multiSelectStyled';
import { useListClientes } from '../../../common/hooks/useListClientes';
import { useEffect, useState, SyntheticEvent } from 'react';
import {
  selectUnidadesDeNegocioAssociadas,
  selectUnidadeSelecionada,
} from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../../App';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import { generateContasAReceberReport } from './ContasReceberPage.helpers';
import { useUnidadeNegocioGuard } from '../../../common/hooks/useUnidadeNegocioGuard';
import { GridItemModalProps } from '../../../common/components/cardsGrid/cardsModalGrid';
import { Box, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';

interface ControlledValue {
  id: string | number;
  value: string;
}

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      minWidth: '50vw',
    },
  },
});

const ContasReceberPage = ({ title, isOpen, closeModal }: GridItemModalProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { token } = keycloak;
  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

  useUnidadeNegocioGuard(); // Ensures the user has access to the necessary business units

  const tenantList = useSelector(selectUnidadesDeNegocioAssociadas);
  const selectedUnit = useSelector(selectUnidadeSelecionada); // Selecting the unit
  const [clientes] = useListClientes();

  const [selectedTenants, setSelectedTenants] = useState<SimpleMultiSelectOption[]>([]);
  const [selectedClients, setSelectedClients] = useState<ControlledValue[]>([]);

  const [startDateISO, setStartDateISO] = useState(new Date(0).toISOString());
  const [endDateISO, setEndDateISO] = useState(new Date().toISOString());

  useEffect(() => {
    // Fetch any other data necessary for this report
  }, []);

  const handleTenantsChange = (event: SyntheticEvent<Element, Event>, value: SimpleMultiSelectOption[] | null) => {
    setSelectedTenants(value || []);
  };

  const handleClientsChange = (event: SyntheticEvent<Element, Event>, value: ControlledValue[] | null) => {
    const newSelectedClients: ControlledValue[] = [];
    value?.forEach((v) => {
      const selectedClient = clientes.find((cliente) => cliente.id === v.id);
      if (selectedClient && selectedClient.id && !selectedClients.some((client) => client.id === selectedClient.id)) {
        newSelectedClients.push({
          id: selectedClient.id.toString(),
          value: `(${selectedClient.pessoa!.cnpj}) ${selectedClient.pessoa!.nome}`,
        });
      }
    });
    setSelectedClients(newSelectedClients);
  };

  const handleSubmit = async () => {
    keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

    if (selectedTenants.length === 0) {
      dispatch(setErrorFeedback({ message: 'Selecione pelo menos uma Unidade de Negócio.' }));
      return;
    }

    if (token && selectedUnit) {
      // Include selectedUnit for authentication
      try {
        const tenantIds = selectedTenants.map((tenant) => tenant.id.toString()); // Send only the IDs
        const clientIds = selectedClients.map((client) => client.id.toString()); // Send only the IDs
        console.log(token, selectedUnit, tenantIds, clientIds);

        const error = await generateContasAReceberReport(
          token,
          selectedUnit,
          tenantIds,
          clientIds,
          startDateISO,
          endDateISO
        ); // Pass selectedUnit ID
        if (error) {
          dispatch(setErrorFeedback({ message: error }));
        } else {
          dispatch(setSuccessFeedback({ message: 'Relatório gerado com sucesso!' }));
        }
      } catch (error) {
        dispatch(setErrorFeedback({ message: 'Erro ao gerar o relatório.' }));
      }
    }
  };

  return (
    <Dialog className={classes.root} open={isOpen} onClose={closeModal}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Form onSubmit={() => {}}>
          {() => (
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ marginBottom: '25px' }}>
                  <FormControl fullWidth error={selectedTenants.length === 0}>
                    <MultiSelectStyled
                      disablePortal={false}
                      controlledValues={selectedTenants}
                      options={tenantList.map((tenant: { id: any; pessoa: { nomeResumido: any } }) => ({
                        id: tenant.id,
                        value: tenant.pessoa.nomeResumido,
                      }))}
                      onChangeAction={handleTenantsChange}
                      label={'Unidade de Negócio'}
                    />
                    {selectedTenants.length === 0 && (
                      <Typography variant="body2" color="error">
                        Este campo é obrigatório
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '25px' }}>
                  <FormControl fullWidth>
                    <MultiSelectStyled
                      disablePortal={false}
                      options={clientes.map((client) => ({
                        id: client.id ?? '',
                        value: `(${client.pessoa!.cnpj}) ${client.pessoa!.nome}`,
                      }))}
                      onChangeAction={handleClientsChange}
                      label={'Clientes'}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} style={{ marginBottom: '25px' }}>
                  <Field
                    name="startDate"
                    render={({ input }) => (
                      <QCXFinalDatePickerField
                        id="contas-pagar-vencimento-start-date"
                        name={input.name}
                        label={'Data de Início'}
                        required={true}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        onChange={(date: any) => {
                          setStartDateISO(date.toISOString());
                          input.onChange(date);
                        }}
                        validate={undefined}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} style={{ marginBottom: '25px' }}>
                  <Field
                    name="endDate"
                    render={({ input }) => (
                      <QCXFinalDatePickerField
                        id="contas-pagar-vencimento-end-date"
                        name={input.name}
                        label={'Data final'}
                        required={true}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        onChange={(date: any) => {
                          setEndDateISO(date.toISOString());
                          input.onChange(date);
                        }}
                        validate={undefined}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Box padding={2} display="flex" gridGap={4}>
          <Button variant="outlined" color="primary" onClick={closeModal}>
            Fechar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            fullWidth
            disabled={selectedTenants.length === 0}
          >
            Gerar relatório
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ContasReceberPage;
