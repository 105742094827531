import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  setClientes,
  setContatos,
  selectSubMode,
  selectRelatedContatoModel,
} from '../../features/transportadora/transportadoraSlice';
import QCXFinalFormWizard from '../../shared-components/final-form-wizard/QCXFinalFormWizard';
import QCXTransportadoraTecnicalDataForm from './QCXTransportadoraTecnicalDataForm';
import QCXSelectManyClienteFormTableManager from '../cliente/QCXSelectManyClienteFormTableManager';
import QCXContatoTransportadoraForm from './QCXContatoTransportadoraForm'
import {
  isNoneSubMode, isSubConsultMode, isSubCreateMode, isSubUpdateMode,
} from '../../utils/store/store-utils';

export default function QCXTransportadoraWizardFinalForm({
  handleSubmit,
  handleAlternativeSave,
  handleChangeToPreparingAction,
  model,
  handleChangeModel,
  handleChangeToCreate,
  handleChangeToConsult,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToBackgroundCreate,
  handleResetBackgroundMode,
  isCreate,
  isConsult,
  isUpdate,
  isBackgroundCreate,
  isPreparingAction,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const subMode = useSelector(selectSubMode);
  const contatoTransportadora = useSelector(selectRelatedContatoModel);

  const isSubNone = useMemo(() => (
    isNoneSubMode(subMode)
  ), [subMode]);

  const isSubCreate = useMemo(() => (
    isSubCreateMode(subMode)
  ), [subMode]);

  const isSubConsult = useMemo(() => (
    isSubConsultMode(subMode)
  ), [subMode]);

  const isSubUpdate = useMemo(() => (
    isSubUpdateMode(subMode)
  ), [subMode]);


  const clientes = useMemo(() => (
    model?.clientes || []
  ), [model]);

  const contatos = useMemo(() => (
    model?.contatos || []
  ), [model]);

  const handleSetSelectedClientes = (data) => {
    dispatch(setClientes(data));
  };

  const checkIfIsAlternativeSaveByStep = (currentStep) => (currentStep === 1 || currentStep === 2)

  const wizardSteps = [
    t('com.muralis.qcx.dadosTecnicos'),
    t('com.muralis.qcx.cliente.labelPlural'),
    t('com.muralis.qcx.contato.labelPlural'),
  ];

  return (
    <QCXFinalFormWizard
      steps={wizardSteps}
      initialValues={model}
      handleChangeValues={handleChangeModel}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      checkIfIsAlternativeSaveByStep={checkIfIsAlternativeSaveByStep}
      handleSubmit={handleSubmit}
      handleAlternativeSave={handleAlternativeSave}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      {...restProps}
    >
      {(formProps) => (
        <QCXTransportadoraTecnicalDataForm
          key="ficha-dados-tecnicos-transportadora"
          isCreate={isCreate}
          isConsult={isConsult}
          isUpdate={isUpdate}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...formProps}
        />
      )}
      {({ controlComponentProps, ...restFormProps }) => (
        <QCXSelectManyClienteFormTableManager
          key="ficha-clientes-transportadora"
          list={clientes}
          isConsult={isConsult}
          handleChange={handleSetSelectedClientes}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
          {...restFormProps}
        />
      )}
      {({ controlComponentProps, ...restFormProps }) => (
        <QCXContatoTransportadoraForm
          key="contatos-cliente"
          isConsult={isConsult}
          isUpdate={isUpdate}
          isSubNone={isSubNone}
          isSubCreate={isSubCreate}
          isSubConsult={isSubConsult}
          isSubUpdate={isSubUpdate}
          initialValues={contatoTransportadora}
          list={contatos}
          controlComponentProps={controlComponentProps}
          authInfo={authInfo}
          requiredRoles={requiredRoles}
        />
      )}
    </QCXFinalFormWizard>
  );
}
