import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import _, { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { validate as isUuid } from 'uuid';
import {
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  loading,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetSubMode,
  setError,
  setModel,
  setResponse,
  success,
} from '../../../../features/faturamento/faturamentoSlice';
import {
  selectFaturamento,
  selectStatus,
  selectMode,
  selectSubMode,
  selectBackgroundMode,
} from '../../../../features/faturamento/faturamentoSelectors';
import { fetchByIdAsync } from '../../../../features/faturamento/faturamentoThunks';
import {
  isCreateMode,
  isNoneMode,
  isLoadingStatus,
  isIdleStatus,
  isFailureStatus,
  isBackgroundCreateMode,
  isSubUpdateMode,
  isExternalMode,
  isUpdateMode,
  isConsultMode,
} from '../../../../utils/store/store-utils';
import QCXRegistrationFormPageTemplate from '../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import QCXFaturamentoFinanceiroForm from '../../../../components/faturamento/QCXFaturamentoFinanceiroForm';
import useAutoChangeMode from '../../../../utils/hooks/mode/useAutoChangeMode';
import { changeModeTo, selectContasBancarias } from '../../../../features/conta-bancaria/contaBancariaSlice';
import { normalizeNumeral, unnormalizeNumeral } from '../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../../utils/hooks/form/field/formatters';
import { clienteActions } from '../../../../features/cliente/clienteSlice';
import { IDLE_CONTROL } from '../../../../features/config-control';
import { faturamentoAPI } from '../../../../features/faturamento/faturamentoAPI';
import FaturamentoUtils from '../../../../utils/general/faturamento/FaturamentoUtils';

export default function FaturamentoRegistrationPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const params = useParams();
  const history = useHistory();

  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const subMode = useSelector(selectSubMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const faturamento = useSelector(selectFaturamento);
  const contasBancarias = useSelector(selectContasBancarias);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetSubMode());
    dispatch(resetBackgroundMode());
  }, []);
  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  useEffect(() => {
    dispatch(clienteActions.changeControlTo(IDLE_CONTROL));

    const clearOnUnmount = () => {
      resetModes();
      resetModels();

      dispatch(clienteActions.resetControl());
    };

    return clearOnUnmount;
  }, []);

  const isLoading = useMemo(() => isLoadingStatus(status), [status]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isIdle = useMemo(() => isIdleStatus(status), [status]);

  const isFailure = useMemo(() => isFailureStatus(status), [status]);

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isExternal = useMemo(() => isExternalMode(mode), [mode]);

  const isSubUpdate = useMemo(() => isSubUpdateMode(subMode), [subMode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const handleChangeToCreate = useCallback(() => {
    dispatch(changeToCreateMode());
  }, []);

  const handleChangeToConsult = useCallback(() => {
    dispatch(changeToConsultMode());
  }, []);

  const handleChangeToUpdate = useCallback(() => {
    dispatch(changeToUpdateMode());
  }, []);

  const handleChangeToBackgroundCreate = useCallback(() => {
    dispatch(changeToBackgroundCreateMode());
  }, []);

  const handleResetBackgroundMode = useCallback(() => {
    dispatch(resetBackgroundMode());
  }, []);

  const fetchById = useCallback((faturamentoId) => {
    dispatch(fetchByIdAsync(faturamentoId));
  }, []);

  const normalize = useCallback(
    (unnormalizedData) => {
      const data = _.omit(unnormalizedData, ['ignorableFields', 'draftFields']);

      const unnormalizedCapas = data?.capas || [];

      const normalizedCapas = unnormalizedCapas.map((capa) => {
        const unnormalizedLancamentos = capa?.lancamentos || [];
        const normalizedLancamentos = unnormalizedLancamentos.map(({ id, totalMoeda, totalReal, ...lancamento }) => ({
          id: isUuid(id) ? undefined : id,
          totalMoeda: normalizeNumeral(totalMoeda),
          totalReal: normalizeNumeral(totalMoeda),
          ...lancamento,
        }));

        return {
          ...capa,
          ...(!_.isEmpty(normalizedLancamentos)
            ? {
                lancamentos: normalizedLancamentos,
              }
            : {}),
        };
      });
      return {
        ...data,
        importador: data?.importador?.id
          ? {
              id: data?.importador?.id,
            }
          : undefined,
        servico: data?.servico?.id
          ? {
              id: data?.servico?.id,
            }
          : undefined,
        viaTransporte: data?.viaTransporte?.id
          ? {
              id: data?.viaTransporte?.id,
            }
          : undefined,
        capas: normalizedCapas,
        taxaCambio: normalizeNumeral(data?.taxaCambio),
        observacao: data?.observacao,
      };
    },
    [normalizeNumeral, contasBancarias]
  );

  const unnormalize = useCallback(
    (data) => {
      const normalizedCapas = data?.capas || [];
      const unnormalizedCapas = normalizedCapas.map((capa) => {
        const unnormalizedLancamentos = capa?.lancamentos || [];
        const normalizedLancamentos = unnormalizedLancamentos.map(({ totalMoeda, totalReal, ...lancamento }) => ({
          ...lancamento,
          totalMoeda: unnormalizeNumeral(totalMoeda, formatBrazilianNumericDecimal(2)),
          totalReal: unnormalizeNumeral(totalReal, formatBrazilianNumericDecimal(2)),
        }));

        return {
          ...capa,
          ...(!_.isEmpty(normalizedLancamentos)
            ? {
                lancamentos: normalizedLancamentos,
              }
            : {}),
        };
      });

      const sortedCapasByItem = _.sortBy(unnormalizedCapas, 'item');

      return {
        ...data,
        capas: sortedCapasByItem,
        observacao: data?.observacao,
        taxaCambio: unnormalizeNumeral(data?.taxaCambio, formatBrazilianNumericDecimal(5)),
      };
    },
    [formatBrazilianNumericDecimal, unnormalizeNumeral]
  );

  const handleDispatchSetModel = useCallback(
    (data) => {
      const normalizedData = normalize(data);

      dispatch(setModel(normalizedData));
    },
    [normalize]
  );

  const model = useMemo(
    () => (isCreate && !isBackgroundCreate ? {} : unnormalize(faturamento)),
    [isCreate, isBackgroundCreate, faturamento, unnormalize]
  );

  const create = useCallback(async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await faturamentoAPI.save(data);

        if (response?.status === 200) {
          dispatch(resetModel());
          const created = response?.data;

          handleDispatchSetModel(created);

          const handleResultWithDebounce = debounce(() => {
            history.push(t('com.muralis.qcx.url.financeiroFaturamento'));

            dispatch(success());
            dispatch(
              setResponse({
                status: response.status,
                data: created,
                message: t('com.muralis.qcx.mensagem.solicitacaoFaturamentoSalva'),
              })
            );
          }, 500);

          handleResultWithDebounce();
        }
      } catch (error) {
        const errorObject = error?.response?.data?.message;
        const errorMessage = errorObject
          ? t('com.muralis.qcx.erro.erroSalvarFaturamentoEspecifico', { erro: errorObject })
          : t('com.muralis.qcx.erro.erroSalvarFaturamento');

        dispatch(failure());
        dispatch(
          setError({
            message: errorMessage,
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  }, []);

  const update = useCallback(
    async (data) => {
      const executeDebounced = debounce(async () => {
        try {
          const response = await faturamentoAPI.save(data);

          if (response?.status === 200) {
            const handleResultWithDebounce = debounce(() => {
              dispatch(success());

              const saved = response?.data;

              dispatch(
                setResponse({
                  status: response.status,
                  data: saved,
                  message: t('com.muralis.qcx.mensagem.solicitacaoFaturamentoSalva'),
                })
              );

              dispatch(setModel(saved));
              dispatch(fetchByIdAsync(saved?.id));
            }, 500);

            handleResultWithDebounce();
          }
        } catch (error) {
          const errorObject = error?.response?.data?.message;
          const errorMessage = errorObject
            ? t('com.muralis.qcx.erro.erroSalvarFaturamentoEspecifico', { erro: errorObject })
            : t('com.muralis.qcx.erro.erroSalvarFaturamento');

          dispatch(failure());
          dispatch(
            setError({
              message: errorMessage,
            })
          );
        }
      }, 500);

      dispatch(loading());
      executeDebounced();
    },
    [handleChangeToConsult]
  );

  const handleSubmit = useCallback(
    async (data) => {
      const normalizedData = normalize(data);
      if (isUpdate) {
        await update(normalizedData);
      } else {
        await create(normalizedData);
      }
    },
    [isUpdate, normalize]
  );

  const actionName = useMemo(() => {
    if (isCreate || isNone) return t('com.muralis.qcx.acoes.novo');
    if (isSubUpdate) return t('com.muralis.qcx.acoes.alterar');
    return t('com.muralis.qcx.acoes.visualizar');
  }, [isNone, isCreate, isSubUpdate]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloFinanceiro'),
          name: t('com.muralis.qcx.financeiro.label'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.financeiroFaturamento'),
          name: t('com.muralis.qcx.faturamento.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.solicitacao'),
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [actionName]
  );

  const pageTitle = useMemo(
    () =>
      (isNone || isCreate || isBackgroundCreate) && !isUpdate
        ? t('com.muralis.qcx.faturamento.novoFaturamento')
        : t('com.muralis.qcx.faturamento.faturamentoExistente', {
            identificador: FaturamentoUtils.formatProcessosCapas(model?.capas, 3) || '',
          }),
    [model, isNone, isUpdate, isCreate, isBackgroundCreate]
  );

  useAutoChangeMode((currentMode) => dispatch(changeModeTo(currentMode)), [isUpdate]);

  useEffect(() => {
    const handleFetchById = () => {
      if (!isEmpty(params?.id)) {
        fetchById(params?.id);
      }
    };

    handleFetchById();
  }, [params]);

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      isIdle={isIdle}
      isLoading={isLoading}
      isFailure={isFailure}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isExternal={isExternal}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXFaturamentoFinanceiroForm
          initialValues={model}
          normalize={normalize}
          unnormalize={unnormalize}
          handleSubmit={handleSubmit}
          handleChangeModel={handleDispatchSetModel}
          authInfo={authInfo}
          requiredRoles={['faturamento']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
