import i18next from 'i18next';

const breadcrumbs = [
  {
    link: {
      to: '/',
      name: i18next.t('com.muralis.qcx.codigo'),
    },
  },
  {
    text: {
      name: i18next.t('com.muralis.qcx.cadastros'),
    },
  },
  {
    link: {
      to: i18next.t('com.muralis.qcx.url.moduloOperacionais'),
      name: i18next.t('com.muralis.qcx.operacionais'),
    },
  },
  {
    text: {
      name: i18next.t('com.muralis.qcx.catalogoProdutosMercadoria'),
    },
  },
  {
    link: {
      to: i18next.t('com.muralis.qcx.url.moduloOperacionaisCatalogosProdutos'),
      name: i18next.t('com.muralis.qcx.principal'),
    },
  },
  {
    text: {
      name: 'Filtro',
    },
  },
];

export default breadcrumbs;
