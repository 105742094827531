import { createAsyncThunk } from '@reduxjs/toolkit';
import { DEFAULT_TIPO_DI_DUIMP_PARAMETER } from '../../../../../utils/general/fatura/faturaUtils';
import * as custeiosAPI from './custeiosAPI';
import { faturaAPI } from '../../../../../features/fatura/faturaAPI';
import { followUpAPI } from '../../../../../features/follow-up/followUpAPI';
import { setErrorFeedback } from '../../../../../features/feedback/feedbackSlice';
import { finishLoading, startLoading } from '../../../../../features/application-state/applicationStateSlice';
import d from 'final-form-arrays';
import { MediaType } from '../../../../../utils/api/api-utils';

const fetchAllFromFaturaAsync = createAsyncThunk('custeios/fetchAllFromFatura', async () => {
  const { data } = await custeiosAPI.fetchByFilter([
    // DEFAULT_TIPO_DI_DUIMP_PARAMETER,
  ]);
  return { data };
});

const fetchAllAsync = createAsyncThunk('custeios/fetchAll', async (data, { rejectWithValue, dispatch }) => {
  try {
    dispatch(startLoading());
    const response = await custeiosAPI.fetchAll();

    if (response?.status === 200) {
      dispatch(finishLoading());
    }

    return { data: response.data };
  } catch (error: any) {
    dispatch(setErrorFeedback({ message: error?.response?.data?.message }));
    return rejectWithValue(error?.response?.data);
  }
});

interface Param {
  name: string;
  value: number | string;
}

const fetchPaginateAsync = createAsyncThunk('custeios/fetchPaginateAsync', async (data: Param[], { rejectWithValue, dispatch }) => {
  try {
    dispatch(startLoading());
    const response = await custeiosAPI.fetchPaginate(data);

    if (response?.status === 200) {
      dispatch(finishLoading());
    }

    return { data: response.data };
  } catch (error: any) {
    dispatch(setErrorFeedback({ message: error?.response?.data?.message }));
    return rejectWithValue(error?.response?.data);
  }
});

const carregaDespesasAsync = createAsyncThunk<any, string>('custeios/carregaDespesas', async (data, { rejectWithValue, dispatch }) => {
  try {
    dispatch(startLoading());
    const response = await custeiosAPI.getDespesasBasicas([{ "name" : 'processo', "value": data}]);
    
    if (response?.status === 200) {
      dispatch(finishLoading());
    }

    return { despesas: response.data };
  } catch (error: any) {
    dispatch(setErrorFeedback({ message: error?.response?.data?.message }));
    return rejectWithValue(error?.response?.data);
  }
});

const fetchByIdAsync = createAsyncThunk(
  'custeios/fetchById',
  async (data: number | string, { rejectWithValue, dispatch }) => {
    let response = {
      status: 0,
      data: {},
    };

    dispatch(startLoading());

    const custeioResponse = await custeiosAPI.fetchById(data);

    if (custeioResponse.data?.fatura?.id) {
      response.status = custeioResponse.status;
      response.data = custeioResponse.data;
    } else if (response.status == 200) {
      const faturaResponse = await faturaAPI.fetchById(custeioResponse?.data?.fatura?.id);
      response.status = faturaResponse.status;
      response.data = { ...custeioResponse.data, fatura: faturaResponse.data };
    }

    dispatch(finishLoading());

    return { response };
  }
);

const generateSpreadsheetById = createAsyncThunk(
  'custeios/generateSpreadsheetById',
  async ({ id }: { id: number | string }, { rejectWithValue, dispatch }) => {
    try {
      const custeioResponse = await custeiosAPI.fetchById(id);
      const response = await custeiosAPI.generateSpreadsheetById(id);

      if (response?.status === 204) {
        return;
      }

      const fileName = `Relatório Custeio - ${custeioResponse?.data?.followUp?.numero}.xlsx`;

      const excelData = new Blob([response?.data], { type: MediaType.EXCEL });

      const url = window.URL.createObjectURL(excelData);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.log(e);
    }
    dispatch(finishLoading());
  }
);

const activateByIdAsync = createAsyncThunk('custeios/activateById', async (id: number | string) => {
  const { status, data } = await custeiosAPI.activateById(id);
  return { response: { status, data } };
});

const inactivateByIdAsync = createAsyncThunk('custeios/inactivateById', async (id: number | string) => {
  const { status, data } = await custeiosAPI.inactivateById(id);
  return { response: { status, data } };
});

const custeiosThunks = {
  fetchAllFromFaturaAsync,
  fetchByIdAsync,
  fetchAllAsync,
  fetchPaginateAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  generateSpreadsheetById,
  carregaDespesasAsync
};

export {
  custeiosThunks,
  fetchAllFromFaturaAsync,
  fetchByIdAsync,
  fetchAllAsync,
  fetchPaginateAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  generateSpreadsheetById,
  carregaDespesasAsync
};
