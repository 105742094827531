import { useKeycloak } from '@react-keycloak/web';
import axios from 'axios';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorFeedback } from '../../../../../features/feedback/feedbackSlice';
import { selectUnidadeSelecionada } from '../../../../../features/usuario-logado/usuarioLogadoSlice';
import { DashboardSgtConhecimentoRow } from '../types/DashboardSgtConhecimentoRow';
import { DashboardSgtFaturaRow } from '../types/DashboardSgtFaturaRow';

export const useDashboardSgt = () => {
  const dispatch = useDispatch();
  const {
    keycloak: { token },
  } = useKeycloak();
  const selectedUnidadeNegocioId = useSelector(selectUnidadeSelecionada);
  const [faturaTableData, setFaturaTableData] = useState<DashboardSgtFaturaRow[]>([]);
  const [conhecimentoTableData, setConhecimentoTableData] = useState<DashboardSgtConhecimentoRow[]>([]);
  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(false);

  const showErrorFeedback = (message: string) => {
    dispatch(
      setErrorFeedback({
        message,
      })
    );
  };

  const fetchDashboardSgt = useCallback(
    async (params: object = {}) => {
      setLoading(true);

      try {
        const url = process.env.REACT_APP_QCX_API_BASE_URL! + '/integracao/dashboard-sgt/aggregate';
        const response = await axios.get(url, {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
            quickcomexTenant: selectedUnidadeNegocioId,
          },
        });

        if (response.status === 200) {
          setFaturaTableData(response.data.faturas);
          setConhecimentoTableData(response.data.conhecimentos);
        } else {
          showErrorFeedback('Erro ao consultar dados do Dashboard');
        }
      } catch (err) {
        showErrorFeedback('Erro ao consultar dados do Dashboard');
      } finally {
        setInitializing(false);
        setLoading(false);
      }
    },
    [selectedUnidadeNegocioId, token]
  );

  const uploadPlanilhaByUrl = (urlSuffix: string) => async (arquivo: File) => {
    setLoading(true);

    const formData = new FormData();

    formData.append('bodyData', arquivo);

    const url = process.env.REACT_APP_QCX_API_BASE_URL! + '/integracao/dashboard-sgt/' + urlSuffix;
    try {
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          quickcomexTenant: selectedUnidadeNegocioId,
        },
      });

      if (response.status !== 200) {
        showErrorFeedback('Erro ao importar planilha');
        return;
      }
    } catch (err) {
      showErrorFeedback('Erro ao importar planilha');
    } finally {
      setLoading(false);
    }
  };

  const generateExcelByUrl =
    (urlSuffix: 'faturas' | 'conhecimentos') =>
    async (params: object = {}) => {
      setLoading(true);

      try {
        const url = process.env.REACT_APP_QCX_API_BASE_URL! + '/integracao/dashboard-sgt/download-excel/' + urlSuffix;
        const response = await axios.get(url, {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
            quickcomexTenant: selectedUnidadeNegocioId,
          },
          responseType: 'blob',
        });

        if (response.status === 200) {
          const file = new File([response.data], `dashboard-sgt-${urlSuffix}.xlsx`, {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          const url = window.URL.createObjectURL(file);
          const a = document.createElement('a');
          a.href = url;
          a.download = file.name;
          a.click();
        } else {
          showErrorFeedback('Erro ao gerar Excel do Dashboard');
        }
      } catch (err) {
        showErrorFeedback('Erro ao gerar Excel do Dashboard');
      } finally {
        setLoading(false);
      }
    };

  const uploadPlanilhaFatura = uploadPlanilhaByUrl('import-planilha-fatura');
  const uploadPlanilhaConhecimento = uploadPlanilhaByUrl('import-planilha-conhecimento');

  const generateExcelFatura = generateExcelByUrl('faturas');
  const generateExcelConhecimento = generateExcelByUrl('conhecimentos');

  return {
    initializing,
    loading,
    fetchDashboardSgt,
    uploadPlanilhaFatura,
    uploadPlanilhaConhecimento,
    generateExcelFatura,
    generateExcelConhecimento,
    faturaTableData,
    conhecimentoTableData,
  };
};
