import PageLayout from '../../../common/layouts/pageLayout';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import DashboardSgtUploadForm, { DashboardSgtFormValues } from './components/DashboardSgtUploadForm.component';
import DashboardSgtTables from './components/DashboardSgtTables.component';
import { useDashboardSgt } from './hooks/dashboardSgt.hook';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  getDateOrDefaultEndDate,
  getDateOrDefaultStartDate,
  getDefaultEndDate,
  getDefaultStartDate,
} from '../../../../utils/general/dateRangeFilter-utils';
import { DashboardSgtAdvancedFilterFormConfigChange } from './types/DashboardSgtAdvancedFilterFormConfigChange';
import { DashboardSgtDateFilter } from './types/DashboardSgtDateFilter';
import QCXDocumentosFollowUpDialogManager from '../../../../components/follow-up/QCXDocumentosFollowUpDialogManager';
import useFormDialogSync from '../../../../utils/hooks/form/dialog/useFormDialogSync';
import QCXProgressBackdrop from '../../../../shared-components/backdrop/QCXProgressBackdrop';

const DashboardSgtPage = () => {
  const {
    initializing,
    loading,
    fetchDashboardSgt,
    uploadPlanilhaFatura,
    uploadPlanilhaConhecimento,
    generateExcelFatura,
    generateExcelConhecimento,
    faturaTableData,
    conhecimentoTableData,
  } = useDashboardSgt();

  const hasCheckedCollapseNeedRef = useRef(false);
  const [shouldCollapse, setShouldCollapse] = useState<boolean | undefined>(undefined);

  const [faturaDateFilter, setFaturaDateFilter] = useState<DashboardSgtDateFilter>({
    dateStart: getDefaultStartDate().valueOf(),
    dateEnd: getDefaultEndDate().valueOf(),
  });
  const [conhecimentoDateFilter, setConhecimentoDateFilter] = useState<DashboardSgtDateFilter>({
    dateStart: getDefaultStartDate().valueOf(),
    dateEnd: getDefaultEndDate().valueOf(),
  });

  const [selectedConhecimentoId, setSelectedConhecimentoId] = useState<number | null>(null);
  const selectedConhecimento = conhecimentoTableData.find((c) => c.id === selectedConhecimentoId);

  const [handleOpenModalDocumentos, formDialogDocumentosStatus, handleFormDialogDocumentosStatus] = useFormDialogSync();

  const handleSubmit = async (values: DashboardSgtFormValues) => {
    if (!values?.tipoPlanilha || !values?.arquivo) {
      return;
    }

    if (values.tipoPlanilha === 'FATURA') await uploadPlanilhaFatura(values.arquivo);
    if (values.tipoPlanilha === 'CONHECIMENTO') await uploadPlanilhaConhecimento(values.arquivo);

    fetchDashboardSgt({
      faturaDateStart: faturaDateFilter.dateStart,
      faturaDateEnd: faturaDateFilter.dateEnd,
      conhecimentoDateStart: faturaDateFilter.dateStart,
      conhecimentoDateEnd: faturaDateFilter.dateEnd,
    });
  };

  useEffect(() => {
    if (hasCheckedCollapseNeedRef.current) return;

    if (!initializing) {
      hasCheckedCollapseNeedRef.current = true;
    }

    if (faturaTableData?.length > 0 && conhecimentoTableData?.length > 0) {
      setShouldCollapse((prev) => {
        if (prev === undefined) return true;

        return prev;
      });
    }
  }, [initializing, faturaTableData, conhecimentoTableData]);

  const handleFaturaConfigChange = useCallback(({ advancedFilter }: DashboardSgtAdvancedFilterFormConfigChange) => {
    const dateStart = getDateOrDefaultStartDate(advancedFilter?.dateStart).valueOf();
    const dateEnd = getDateOrDefaultEndDate(advancedFilter?.dateEnd).valueOf();

    setFaturaDateFilter((prev) => {
      if (prev.dateStart === dateStart && prev.dateEnd === dateEnd) return prev;

      return { dateStart, dateEnd };
    });
  }, []);

  const handleConhecimentoConfigChange = useCallback(
    ({ advancedFilter }: DashboardSgtAdvancedFilterFormConfigChange) => {
      const dateStart = getDateOrDefaultStartDate(advancedFilter?.dateStart).valueOf();
      const dateEnd = getDateOrDefaultEndDate(advancedFilter?.dateEnd).valueOf();

      setConhecimentoDateFilter((prev) => {
        if (prev.dateStart === dateStart && prev.dateEnd === dateEnd) return prev;

        return { dateStart, dateEnd };
      });
    },
    []
  );

  const openGED = (id: number) => {
    setSelectedConhecimentoId(id);
    // @ts-ignore
    handleOpenModalDocumentos();
  };

  const handleGenerateExcelFatura = () => {
    generateExcelFatura({
      faturaDateStart: faturaDateFilter.dateStart,
      faturaDateEnd: faturaDateFilter.dateEnd,
    });
  };

  const handleGenerateExcelConhecimento = () => {
    generateExcelConhecimento({
      conhecimentoDateStart: conhecimentoDateFilter.dateStart,
      conhecimentoDateEnd: conhecimentoDateFilter.dateEnd,
    });
  };

  useEffect(() => {
    if (
      !faturaDateFilter.dateStart &&
      !faturaDateFilter.dateEnd &&
      !conhecimentoDateFilter.dateStart &&
      !conhecimentoDateFilter.dateEnd
    )
      return;

    fetchDashboardSgt({
      faturaDateStart: faturaDateFilter.dateStart,
      faturaDateEnd: faturaDateFilter.dateEnd,
      conhecimentoDateStart: conhecimentoDateFilter.dateStart,
      conhecimentoDateEnd: conhecimentoDateFilter.dateEnd,
    });
  }, [fetchDashboardSgt, faturaDateFilter, conhecimentoDateFilter]);

  return (
    <PageLayout title="Dashboard" icon={<HistoryEduIcon color={'secondary'} />}>
      <DashboardSgtUploadForm handleSubmit={handleSubmit} shouldCollapse={shouldCollapse} />

      <DashboardSgtTables
        faturaTableData={faturaTableData}
        conhecimentoTableData={conhecimentoTableData}
        onFaturaConfigChange={handleFaturaConfigChange}
        onConhecimentoConfigChange={handleConhecimentoConfigChange}
        openGED={openGED}
        generateExcelFatura={handleGenerateExcelFatura}
        generateExcelConhecimento={handleGenerateExcelConhecimento}
      />

      <QCXDocumentosFollowUpDialogManager
        model={selectedConhecimento?.followUp}
        status={formDialogDocumentosStatus}
        handleStatus={handleFormDialogDocumentosStatus}
      />

      <QCXProgressBackdrop open={loading} />
    </PageLayout>
  );
};

export default DashboardSgtPage;
