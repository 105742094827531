import { useMemo } from 'react';
import { QueriesTypes, useGetRequest } from './queries';

export const useGenerateDanfeFileReport = (idDANFE: string, tyFile: string) => {
  let url: QueriesTypes = QueriesTypes.DanfeDonwloadXML;

  if (tyFile !== undefined && tyFile === 'txt') {
    url = QueriesTypes.DanfeDonwloadTXT;
  }

  if (tyFile !== undefined && tyFile === 'assinada') {
    url = QueriesTypes.DanfeDonwloadXMLAssinada;
  }

  if (tyFile !== undefined && tyFile === 'sefaz') {
    url = QueriesTypes.DanfeDonwloadXMLSefaz;
  }

  const { data, isError, isLoading } = useGetRequest(url, idDANFE);

  const result = useMemo(() => {
    if (!isLoading && !isError) {
      return data;
    }
  }, [data, isLoading]);

  return { data: result };
};
