/* eslint-disable react/no-array-index-key */
import { CircularProgress, Grid, IconButton, Tooltip } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';

export interface ActionsBarButtonProps {
  icon: SvgIconComponent;
  title: string;
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}

export interface ActionsBarProps {
  buttons: ActionsBarButtonProps[];
}

const ActionsBar = ({ buttons }: ActionsBarProps) => {
  if (!buttons || buttons.length === 0) {
    return <></>;
  }

  return (
    <Grid container item justify="flex-end" xs={12}>
      {buttons.map((button, index) => (
        <Tooltip key={`${button.title}-${index}`} title={button.title}>
          <IconButton
            aria-label="Add"
            style={{
              backgroundColor: 'lightgray',
              borderRadius: '10px',
              padding: '10px',
              margin: '0 5px',
            }}
            size="small"
            onClick={button.onClick}
            disabled={button.disabled || button.isLoading}
          >
            {button.isLoading ? <CircularProgress size={20} variant="indeterminate" /> : <button.icon />}
          </IconButton>
        </Tooltip>
      ))}
    </Grid>
  );
};

export default ActionsBar;
