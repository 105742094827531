import { IconButton, Tooltip } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import {
  Block as BlockIcon,
  Loop as LoopIcon,
  MoreHoriz as MoreHorizIcon,
  Search as PageviewIcon,
  Receipt as ReceiptIcon,
  GridOn as TableChartIcon,
  Timeline as TimelineIcon,
} from '@material-ui/icons';
import { isEmpty, isFunction } from 'lodash';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import QCXMoveFocusInside from '../../../../components/focus-lock/QCXMoveFocusInside';
import { userHasDeleteRole } from '../../../../components/perfil/perfil-utils';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import FollowUpExportacaoContext from '../../../../contexts/export/follow-up/FollowUpExportacaoContext';
import { clienteActions } from '../../../../features/cliente/clienteSlice';
import { IDLE_CONTROL } from '../../../../features/config-control';
import { setQuery } from '../../../../features/controller/controllerSlice';
import { setErrorFeedback } from '../../../../features/feedback/feedbackSlice';
import followUpExportacaoSelectors from '../../../../features/follow-up-exportacao/followUpExportacaoSelectors';
import {
  followUpExportacaoActions,
  resetRefresh,
} from '../../../../features/follow-up-exportacao/followUpExportacaoSlice';
import followUpExportacaoThunks from '../../../../features/follow-up-exportacao/followUpExportacaoThunks';
import { selectFollowUps, selectFollowUpsCount } from '../../../../features/follow-up/followUpSelectors';
import { changeToConsultMode } from '../../../../features/follow-up/followUpSlice';
import { clearStatusAsync, fetchFollowUpExportacaoWithPaginationAsync } from '../../../../features/follow-up/followUpThunks';
import { servicoActions } from '../../../../features/servico/servicoSlice';
import QCXFinalFormDialog from '../../../../shared-components/dialog/QCXFinalFormDialog';
import QCXListItemIcon from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import SimpleConsultPageTemplate from '../../../../templates/simple-consult-page/SimpleConsultPageTemplate/SimpleConsultPageTemplate';
import useConfigurePopUp from '../../../../ts/common/hooks/popUps/useConfigurePopUp';
import FollowUpStatusUtils from '../../../../utils/general/follow-up/FollowUpStatusUtils';
import { formatDate } from '../../../../utils/general/general-utils';
import { formatCnpj } from '../../../../utils/hooks/form/field/formatters';
import { isAlternativeLoadingStatus, isRefresh } from '../../../../utils/store/store-utils';
import { required } from '../../../../utils/validators/field/validator';
import FinalSelectMotivoCancelamentoFollowup from '../../import/follow-up/Components/FinalSelectMotivoCancelamentoFollowup';
import { selectDadosEmbarques } from '../dados-embarque/api/dadosEmbarqueSelectors';
import { fetchAllDadosEmbarqueExportacaoByFilterAsync } from '../dados-embarque/api/dadosEmbarqueThunks';

export default function FollowUpExportacaoConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const { status } = useContext(FollowUpExportacaoContext);

  const dispatch = useDispatch();

  const refresh = useSelector(followUpExportacaoSelectors.selectRefresh);
  const followUps = useSelector(selectFollowUps);
  const historyModel = useSelector(followUpExportacaoSelectors.selectHistoryModel);
  const dadosEmbarque = useSelector(selectDadosEmbarques);

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const fetchAll = useCallback(() => {
    dispatch(
      fetchFollowUpExportacaoWithPaginationAsync([
        { name: 'page', value: 1 },
        { name: 'size', value: 10 },
      ])
    );
    dispatch(fetchAllDadosEmbarqueExportacaoByFilterAsync([
      { name: 'page', value: 1 },
      { name: 'size', value: 10 },
    ]));
  }, []);

  const resetModes = useCallback(() => {
    dispatch(followUpExportacaoActions.resetMode());
    dispatch(followUpExportacaoActions.resetBackgroundMode());
    dispatch(followUpExportacaoActions.resetSubMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(followUpExportacaoActions.resetModel());
    dispatch(followUpExportacaoActions.resetHistoryModel());
    dispatch(followUpExportacaoActions.resetAlreadyCreated());
  }, []);

  const resetLists = useCallback(() => {
    dispatch(followUpExportacaoActions.resetList());
  }, []);

  const configureDependencies = useCallback(() => {
    dispatch(clienteActions.changeControlTo(IDLE_CONTROL));
    dispatch(servicoActions.changeControlTo(IDLE_CONTROL));
  }, []);

  useEffect(() => {
    fetchAll();

    resetModes();
    resetModels();
    resetLists();
  }, []);

  useEffect(() => {
    const checkIfIsRefresh = () => {
      if (isRefresh(refresh)) {
        dispatch(followUpExportacaoThunks.fetchAllAsync());
        dispatch(resetRefresh());
      }
    };

    checkIfIsRefresh();
  }, [refresh]);

  useEffect(() => {
    const checkIfExistsQuery = () => {
      if (!isEmpty(historyModel) && historyModel?.last?.numero) {
        dispatch(setQuery(historyModel?.last?.numero));
      }
    };

    checkIfExistsQuery();
  }, [historyModel]);

  const handleConsultarTimelineClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(followUpExportacaoActions.changeToConsultMode());

        history.push(t('com.muralis.qcx.url.exportacaoFollowUpTimeline', { id: data?.id }));
      }
    },
    [history]
  );

  const handleConsultarClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        configureDependencies();

        dispatch(followUpExportacaoActions.changeToConsultMode());

        history.push(t('com.muralis.qcx.url.exportacaoFollowUpDetalhes', { id: data?.id }));
      }
    },
    [history, configureDependencies]
  );

  const [handleActivateOrInactivateByIdClickMiddleware, operationOfActivateOrInactivateById] = useConfigurePopUp({
    primaryConfirmCallback: async (data) => {
      dispatch(followUpExportacaoThunks.inactivateByIdAsync(data));
    },
    initialOptions: {
      title: t('com.muralis.qcx.followup.desativarFollowUp'),
      message: t('com.muralis.qcx.followup.informeMotivoCancelamento'),
    },
  });

  const handleConfigureActivateOrInactivateByIdClick = useCallback(
    (row, popupState) => {
      const { id } = row;

      const handleActivateOrInactivateByIdClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const dataOfOperationActivateOrInactivate = {
            id,
          };

          handleActivateOrInactivateByIdClickMiddleware({ args: dataOfOperationActivateOrInactivate });
        }
      };

      return handleActivateOrInactivateByIdClick;
    },
    [handleActivateOrInactivateByIdClickMiddleware]
  );

  const handleButtonNewClick = useCallback(
    (event) => {
      event.stopPropagation();

      configureDependencies();

      dispatch(followUpExportacaoActions.changeToCreateMode());

      history.push(t('com.muralis.qcx.url.exportacaoFollowUpNovo'));
    },
    [history, configureDependencies]
  );

  const handleLimparStatus = useCallback((event, data) => {
    if (isFunction(event?.stopPropagation)) {
      event.stopPropagation();
    }

    dispatch(clearStatusAsync(data));
  }, []);

  const handleConsultarDadosEmbarqueClick = useCallback(
    (event, data) => {
      event.stopPropagation();

      const dadoEmbarque = (dadosEmbarque ?? []).find((dado) => dado?.followUp?.id === data?.id);

      if (dadoEmbarque) {
        dispatch(changeToConsultMode());
        history.push(t('com.muralis.qcx.url.exportacaoDadosEmbarqueDetalhes', { id: dadoEmbarque?.id }));
      } else {
        dispatch(setErrorFeedback({ message: t('com.muralis.qcx.dadosEmbarque.naoEncontrado') }));
      }
    },
    [dadosEmbarque]
  );

  const handleConsultarListClick = useCallback(
    (event, data) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();

        dispatch(changeToConsultMode());

        history.push(`/exportacao/follow-up/${data.id}/lista`);
      }
    },
    [history]
  );

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.processo'),
      headerAlign: 'center',
      align: 'center',
      flex: 200,
      valueGetter: ({ row }) => row?.numero || '-',
    },
    {
      field: 'cliente',
      headerName: t('com.muralis.qcx.exportador.label'),
      flex: 120,
      valueGetter: ({ row }) => row?.importador?.pessoa?.nome.split(' ')[0] || '-',
    },
    {
      field: 'cliente.cnpj',
      headerName: t('com.muralis.qcx.empresa.CNPJ'),
      flex: 140,
      valueGetter: ({ row }) =>
        // FIXME: Alterar para 'cliente'
        formatCnpj(row?.importador?.pessoa?.cnpj) || '-',
    },
    {
      field: 'servico',
      headerName: t('com.muralis.qcx.servico.labelSingular'),
      flex: 160,
      valueGetter: ({ row }) => row?.servico?.description || '-',
    },
    {
      field: 'insertionDate',
      headerName: t('com.muralis.qcx.abertura'),
      headerAlign: 'center',
      align: 'center',
      type: 'date',
      flex: 100,
      valueGetter: ({ row }) => formatDate(row?.insertionDate, 'DD/MM/YYYY HH:mm:ss', 'DD/MM/YYYY') || '-',
    },
    {
      field: 'status',
      headerName: t('com.muralis.qcx.status'),
      flex: 160,
      valueGetter: ({ row }) =>
        FollowUpStatusUtils.getLabelByValue(row?.status) ? t(FollowUpStatusUtils.getLabelByValue(row?.status)) : '-',
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 80,
      renderCell: ({ row: { id, atributosAdicionais, active, status: followUpStatus } }) => (
        <>
          <QCXPopupState popupId="popup-menu-follow-up">
            {(popupState) => (
              <>
                <Tooltip title={t('com.muralis.qcx.maisOpcoes')} arrow>
                  <IconButton
                    key={`btn-more-options-${id}`}
                    name={`btn-more-options-${id}`}
                    {...bindTrigger(popupState)}
                  >
                    <MoreHorizIcon color="secondary" size={20} />
                  </IconButton>
                </Tooltip>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={(event) => {
                      popupState.close(event);

                      handleConsultarListClick(event, {
                        id,
                      });
                    }}
                  >
                    <QCXListItemIcon>
                      <TableChartIcon color="secondary" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {'Visualizar Lista'.toUpperCase()}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      popupState.close(event);

                      handleConsultarTimelineClick(event, {
                        id,
                      });
                    }}
                  >
                    <QCXListItemIcon>
                      <TimelineIcon color="secondary" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.visualizarTimeline').toUpperCase()}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      popupState.close(event);

                      handleLimparStatus(event, {
                        id,
                      });
                    }}
                    disabled={!FollowUpStatusUtils.canClearStatus(followUpStatus)}
                  >
                    <QCXListItemIcon>
                      <LoopIcon
                        fontSize="small"
                        color={FollowUpStatusUtils.canClearStatus(followUpStatus) ? 'green' : 'disabled'}
                      />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.acoes.limparStatus').toUpperCase()}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={(event) => {
                      popupState.close(event);

                      handleConsultarClick(event, {
                        id,
                      });
                    }}
                  >
                    <QCXListItemIcon>
                      <PageviewIcon fontSize="small" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                    </Typography>
                  </MenuItem>
                  {userHasDeleteRole(authInfo, 'exportacao-follow-up') && (
                    <>
                      <MenuItem
                        onClick={handleConfigureActivateOrInactivateByIdClick(
                          {
                            id,
                            atributosAdicionais,
                            active,
                          },
                          popupState
                        )}
                      >
                        <QCXListItemIcon>
                          <BlockIcon fontSize="small" />
                        </QCXListItemIcon>
                        <Typography
                          variant="inherit"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {t('com.muralis.qcx.acoes.cancelar').toUpperCase()}
                        </Typography>
                      </MenuItem>
                    </>
                  )}
                  <MenuItem
                    onClick={(event) => {
                      popupState.close(event);

                      handleConsultarDadosEmbarqueClick(event, {
                        id,
                      });
                    }}
                  >
                    <QCXListItemIcon>
                      <PageviewIcon fontSize="small" />
                    </QCXListItemIcon>
                    <Typography
                      variant="inherit"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {t('com.muralis.qcx.acoes.consultarDadosEmbarque').toUpperCase()}
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            )}
          </QCXPopupState>
        </>
      ),
    },
  ];

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloExportacao'),
          name: t('com.muralis.qcx.exportacao.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.followUpExportacao.label'),
        },
        default: true,
      },
    ],
    []
  );

  const handleConfigChange = useCallback(({ currentPage, pageSize, filterInputValue }) => {
    dispatch(
      fetchFollowUpExportacaoWithPaginationAsync([
        { name: 'page', value: currentPage },
        { name: 'size', value: pageSize },
        { name: 'processo', value: filterInputValue },
      ])
    );
    dispatch(fetchAllDadosEmbarqueExportacaoByFilterAsync([{ name: 'page', value: currentPage }, { name: 'size', value: pageSize }]));
  }, []);

  const rowsCount = useSelector(selectFollowUpsCount);

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.followUpExportacao.label'),
        icon: <ReceiptIcon />,
        breadcrumbs,
      },
      control: {
        buttonNew: {
          description: t('com.muralis.qcx.followUpExportacao.novoRegistroFollowUp'),
          onClick: handleButtonNewClick,
        },
      },
      table: {
        rowsCount,
        onConfigChangeCallback: handleConfigChange,
        columns,
      },
    }),
    [columns, breadcrumbs, handleButtonNewClick]
  );

  return (
    <SimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={followUps}
      isLoading={isAlternativeLoading}
      authInfo={authInfo}
      requiredRoles={['exportacao-follow-up']}
    >
      <QCXFinalFormDialog
        key="form-dialog-inactive-follow-up"
        id="form-dialog-inactive-follow-up"
        title={operationOfActivateOrInactivateById.title}
        content={operationOfActivateOrInactivateById.message}
        dialogActionsProps={{
          button: {
            submit: {
              description: t('com.muralis.qcx.acoes.excluir'),
              size: 'small',
            },
            cancel: {
              description: t('com.muralis.qcx.acoes.cancelar'),
              size: 'small',
            },
          },
        }}
        open={operationOfActivateOrInactivateById.isPopUpOpen}
        initialValues={{}}
        onSubmit={operationOfActivateOrInactivateById.confirmPrimaryAction}
        onClose={operationOfActivateOrInactivateById.reset}
      >
        {() => (
          <QCXMoveFocusInside>
            <FinalSelectMotivoCancelamentoFollowup
              name="motivoCancelamento"
              label={t('com.muralis.qcx.followup.motivoCancelamento')}
              validate={required}
              required
            />
          </QCXMoveFocusInside>
        )}
      </QCXFinalFormDialog>
    </SimpleConsultPageTemplate>
  );
}
