import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useFormState } from 'react-final-form';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import { RELACAO_FORNECEDOR_LIST } from './relacaoFornecedorUtils';

export default function QCXSelectRelacaoFornecedorAutocomplete({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const [defaultValue, setDefaultValue] = useState('');
  const [relacaoFornecedorList, setRelacaoFornecedorList] = useState([])

  const { values } = useFormState();
  const isDuimp = () => Array.isArray(values?.tipo) && values.tipo.includes("DUIMP")
  const setListaRelacoes = () => {
    if(!isDuimp()) {
      setRelacaoFornecedorList([...RELACAO_FORNECEDOR_LIST])
    } else {
      setRelacaoFornecedorList([...RELACAO_FORNECEDOR_LIST.filter(el => el.value !== 'FABRICANTE_DESCONHECIDO')])
    }
  }

  useEffect(() => {
    setListaRelacoes()
  }, [values]);

  useEffect(() => {
    const value = RELACAO_FORNECEDOR_LIST
      .find((item) => (
        item?.value === _.get(initialValues, name)
      ));

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue('');
    }
  }, [initialValues]);

  const filterOptions = useMemo(() => (
    createFilterOptions({
      stringify: (option) => (
        option.label + option.value
      ),
    })
  ), []);

  const getOptionLabel = useCallback((item) => (
    item?.label || ''
  ), []);

  const getOptionValue = useCallback((item) => (
    item?.value || ''
  ), []);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={relacaoFornecedorList}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      {...restProps}
    />
  );
}
