import CardsGridSubmenu from '../../../../common/components/cardsGrid/cardsGridSubmenu';
import BasicLayout from '../../../../common/layouts/basicLayout';
import { CardsGridItem } from '../../../../common/components/cardsGrid/cardsGrid';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AssessmentIcon from '@mui/icons-material/Assessment';

const cover: CardsGridItem = {
  title: 'Escrita',
  icon: AssessmentIcon,
  path: '/integracao/croda',
};

const gridItems: CardsGridItem[] = [
  {
    title: 'DANFE',
    icon: ReceiptLongIcon,
    path: '/integracao/croda/escrita/danfe',
  },
  {
    title: 'Nota de Débito',
    icon: ReceiptLongIcon,
    path: '/integracao/croda/escrita/nota-fiscal',
  },
];

const CrodaEscritaPage = () => {
  return (
    <BasicLayout title={'Croda'} icon={<AssessmentIcon color={'secondary'} />}>
      <CardsGridSubmenu cover={cover} items={gridItems}></CardsGridSubmenu>
    </BasicLayout>
  );
};

export default CrodaEscritaPage;
