import { useEffect, useState } from 'react';
import { fetchLogsByCatalogoProdutosId } from '../../../../../../../features/catalogo-produtos/catalogoProdutosAPI';

export type LogChanges = {
  [key: string]: {
    previousValue: string | null | boolean;
    newValue: string | null | boolean;
  };
};

export type CatalogoProdutosLog = {
  id: number;
  insertionDate: string;
  partnumber: string;
  userId: string;
  userFullname: string;
  changes: LogChanges;
};

const useLogs = (id: string) => {
  const [logs, setLogs] = useState<CatalogoProdutosLog[]>([]);

  useEffect(() => {
    fetchLogsByCatalogoProdutosId(id).then((response) => {
      if (Array.isArray(response.data)) {
        const parsedLogs = response.data.map((r: any) => ({ ...r, changes: JSON.parse(r.changes) }));
        setLogs(parsedLogs);
      }
    });
  }, [id]);

  return { logs };
};

export default useLogs;
