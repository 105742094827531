import React, { useMemo } from 'react';

export default function QCXDynamicFieldGroup({ rootName, list = [], children }) {
  const generatedObjectsProps = useMemo(() => {
    const obj = {
      identificacao: undefined,
      valorReal: undefined,
      valorSiscomex: undefined,
      codigoReceita: undefined,
      novoCodigoReceita: undefined,
    };

    const keys = Object.keys(obj);

    const result = list.map((...args) => ({
      ...keys.reduce(
        (previous, currentKey) => ({
          ...previous,
          [currentKey]: {
            name: `${rootName}[${args[1]}].${currentKey}`,
          },
        }),
        {}
      ),
    }));

    return result;
  }, [list, rootName]);

  return <>{generatedObjectsProps.map((item, index) => children({ ...item, index }))}</>;
}
