import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userHasCreateRole } from '../../components/perfil/perfil-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectTipoSolicitacaoPagamentoAutocompleteField({
  label,
  name,
  initialValues,
  authInfo,
  ...restProps
}) {
  const { t } = useTranslation();

  const [defaultValue, setDefaultValue] = useState('');

  const TIPO_SOLICITACAO_PAGAMENTO = useMemo(() => {
    const tipos = [];

    console.log(authInfo);
    if (userHasCreateRole(authInfo, 'exportacao-solicitacao-pagamento-administrativo')) {
      tipos.push(t('com.muralis.qcx.administrativa').toUpperCase());
    }

    if (userHasCreateRole(authInfo, 'exportacao-solicitacao-pagamento-operacional')) {
      tipos.push(t('com.muralis.qcx.operacional').toUpperCase());
    }

    return tipos;
  }, [authInfo]);

  useEffect(() => {
    const tipoSolicitacaoPagamento = TIPO_SOLICITACAO_PAGAMENTO.find((item) => item === _.get(initialValues, name));
    if (tipoSolicitacaoPagamento) {
      setDefaultValue(tipoSolicitacaoPagamento);
    }
  }, [initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => item,
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={TIPO_SOLICITACAO_PAGAMENTO}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => item}
      getOptionValue={(item) => item}
      {...restProps}
    />
  );
}
