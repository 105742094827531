import { CircularProgress } from '@material-ui/core';
import { Button, Grid } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PageLayout from '../../../common/layouts/pageLayout';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import { exportFatura, fetchFaturasNumero, fetchClientsNome } from './ocrExportHelpers';
import MultiSelectStyled, { SimpleMultiSelectOption } from '../../../common/components/input/multiSelectStyled';
import { useState, useCallback, useRef, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { selectUnidadeSelecionada } from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../../App';
import { useUnidadeNegocioGuard } from '../../../common/hooks/useUnidadeNegocioGuard';
import { time } from 'console';

const OcrExportPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useUnidadeNegocioGuard();
  const { keycloak } = useKeycloak();
  const { token } = keycloak;

  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

  const selectedUnit = useSelector(selectUnidadeSelecionada);

  const [faturas, setFaturas] = useState<SimpleMultiSelectOption[]>([]);
  const [selectedFaturas, setSelectedFaturas] = useState<SimpleMultiSelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [clients, setClients] = useState<SimpleMultiSelectOption[]>([]);
  const [selectedClients, setSelectedClients] = useState<SimpleMultiSelectOption[]>([]);
  const [isLoadingClients, setIsLoadingClients] = useState(false);

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    fetchClients('a');
  }, []);

  const fetchClients = useCallback(
    async (inputValue: string) => {
      if (!token || !selectedUnit) {
        dispatch(setErrorFeedback({ message: 'Token ou unidade não disponível.' }));
        return;
      }
      try {
        setIsLoadingClients(true);
        const clientOptions = await fetchClientsNome(inputValue, token, selectedUnit);
        setClients(clientOptions);
      } catch (error) {
        dispatch(setErrorFeedback({ message: 'Erro ao buscar clientes', details: (error as any).message }));
      } finally {
        setIsLoadingClients(false);
      }
    },
    [dispatch, token, selectedUnit]
  );

  const fetchFaturas = useCallback(
    async (inputValue: string) => {
      if (!token || !selectedUnit) {
        dispatch(setErrorFeedback({ message: 'Token ou unidade não disponível.' }));
        return;
      }
      try {
        setIsLoading(true);
        const selectedClientNames = selectedClients.map((opt) => opt.value);
        const faturaOptions = await fetchFaturasNumero(inputValue, selectedClientNames, token, selectedUnit);
        setFaturas(faturaOptions);
      } catch (error) {
        dispatch(setErrorFeedback({ message: 'Erro ao buscar faturas', details: (error as any).message }));
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, token, selectedUnit, selectedClients]
  );

  const onSubmit = async () => {
    if (!token || !selectedUnit) {
      dispatch(setErrorFeedback({ message: 'Token ou unidade não disponível.' }));
      return;
    }
    try {
      const selectedNumbers = selectedFaturas.map((opt) => opt.value);
      const selectedClientNames = selectedClients.map((opt) => opt.value);
      const message: string = await exportFatura(selectedNumbers, selectedClientNames, token, selectedUnit);
      if (message != null) {
        dispatch(setErrorFeedback({
          message: message ? message : t('com.muralis.qcx.integracao.Planilhas.erros.erroAoGerarPlanilha'),
        }));
      } else {
        dispatch(setSuccessFeedback({
          message: t('com.muralis.qcx.integracao.Planilhas.sucesso.planilhaComSuceso')
        }));
      }
    } catch (error) {
      console.error('Error while exporting fatura:', error);
      const errorMessage = (error as any).message;
      dispatch(setErrorFeedback({ message: 'Erro ao exportar fatura', details: errorMessage }));
    }
  };

  return (
    <PageLayout title={'EXPORTAR FATURA OCR'} icon={<PreviewIcon color={'secondary'} />}>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h2>Informações</h2>
                </Grid>
                <Grid item xs={4}>
                  {isLoadingClients ? (
                    <CircularProgress size={24} />
                  ) : (
                    <MultiSelectStyled
                      options={clients}
                      placeholder='Digite o nome do cliente para ver as opções'
                      label={t('Selecione os Clientes')}
                      controlledValues={selectedClients}
                      onChangeAction={(event, selectedOptions) => {
                        setSelectedClients(selectedOptions || []);
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={4}>
                  {isLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <MultiSelectStyled
                      options={faturas}
                      placeholder='Digite o número da fatura para ver as opções'
                      label={t('Selecione as Faturas')}
                      controlledValues={selectedFaturas}
                      onChangeAction={(event, selectedOptions) => {
                        setSelectedFaturas(selectedOptions || []);
                      }}
                      onInputChange={(inputValue) => {
                        if (debounceTimeout.current) {
                          clearTimeout(debounceTimeout.current);
                        }
                        debounceTimeout.current = setTimeout(() => {
                          if (inputValue) {
                            fetchFaturas(inputValue);
                          }
                        }, 1000);
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={12} textAlign="right">
                  <Button type="submit" color="secondary" variant="contained" disabled={submitting}>
                    {submitting ? <CircularProgress size={'24px'} /> : 'Exportar fatura'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Form>
    </PageLayout>
  );
};

export default OcrExportPage;
