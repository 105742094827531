import { Box, Grid } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFormTitle from '../../../../../../../components/form-title/QCXFormTitle';
import QCXFinalDatePickerField from '../../../../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalForm from '../../../../../../../shared-components/final-form/QCXFinalForm';
import QCXSelectClienteAutocomplete from '../../../../../../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXSelectNcmSubItemAutocomplete from '../../../../../../../shared-components/select-ncm-subitem/QCXSelectNcmSubItemAutocomplete';
import MultiSelectFormInput from '../../../../../../../ts/common/components/forms/MultiSelectFormInput';
import QCXFinalMultilineInputWithChips from '../../../../../import/transferencia-lote/TransferenciaLoteManagerPage/QCXFinalMultilineInputWIthChips';
import catalogoProdutosStatusIntegracaoOptions from '../../utils/catalogoProdutosStatusIntegracaoOptions';
import { CatalogoProdutosPartnumberFiltroFormValues } from '../types/CatalogoProdutosPartnumberFiltroFormValues';

type CatalogoProdutosPartnumberFiltroFormProps = {
  handleSubmit: (values: CatalogoProdutosPartnumberFiltroFormValues) => void;
  onNcmChange: (ncm: CatalogoProdutosPartnumberFiltroFormValues['ncm']) => void;
};

export default function CatalogoProdutosPartnumberFiltroForm({
  handleSubmit,
  onNcmChange,
}: CatalogoProdutosPartnumberFiltroFormProps) {
  const { t } = useTranslation();

  const callNcmCallback = useCallback(
    () => (value: CatalogoProdutosPartnumberFiltroFormValues['ncm']) => {
      onNcmChange(value);
    },
    [onNcmChange]
  );

  const formListeners = useMemo(
    () => [
      {
        name: 'ncm',
        fn: callNcmCallback,
      },
    ],
    [callNcmCallback]
  );

  return (
    <Grid container>
      <QCXFinalForm
        initialValues={{}}
        onChangeListeners={formListeners}
        onSubmit={handleSubmit}
        isConsult={false}
        isCreate={false}
        isUpdate
        handleChangeToUpdate={() => {}}
        handleChangeToCreate={() => {}}
        handleChangeToConsult={() => {}}
        handleCancelUpdate={() => {}}
        handleSubmitValues={() => {}}
        handleAlternativeSave={undefined}
        validate={undefined}
        disablePristine
        controlButtons={{
          create: {},
          edit: {},
          save: {
            description: 'Filtrar',
          },
          cancel: {
            hidden: true,
          },
        }}
        focusLockProps={{
          disabled: true,
        }}
        debugOn={false}
      >
        {() => (
          <>
            {/* @ts-expect-error boxProps */}
            <QCXFormTitle boxProps={{ mb: 3 }} title={t('com.muralis.qcx.partnumberTags')} />

            <Grid container spacing={1} sm={12}>
              <Grid item sm={3}>
                <QCXFinalDatePickerField
                  id="date-picker-field-insertion-date"
                  key="date-picker-field-insertion-date"
                  label={t('com.muralis.qcx.dateRange0')}
                  name="insertionDateRangeStart"
                  required={undefined}
                  validate={undefined}
                />
              </Grid>

              <Grid item sm={3}>
                <QCXFinalDatePickerField
                  id="date-picker-field-insertion-date"
                  key="date-picker-field-insertion-date"
                  label={t('com.muralis.qcx.dateRange1')}
                  name="insertionDateRangeEnd"
                  required={undefined}
                  validate={undefined}
                />
              </Grid>

              <Grid item sm={6}>
                <Box style={{ marginTop: '-5px' }}>
                  <QCXSelectNcmSubItemAutocomplete
                    id="text-field-filtro-ncm"
                    key="text-field-filtro-ncm"
                    name="ncm"
                    label={t('com.muralis.qcx.NCM.label')}
                    fieldProps={undefined}
                    required
                  />
                </Box>
              </Grid>

              <Grid item sm={12}>
                <Box mt={1}>
                  <QCXSelectClienteAutocomplete
                    id="autocomplete-select-cnpj-field"
                    key="autocomplete-select-cnpj-field"
                    name="cliente.id"
                    label={t('com.muralis.qcx.cliente.label')}
                    initialValues={undefined}
                    filter={
                      {
                        clientesAssociadosACatalogoProdutos: true,
                      } as any
                    }
                    required={false}
                  />
                </Box>
              </Grid>

              <Grid item sm={12}>
                <Box mt={1}>
                  <MultiSelectFormInput
                    name="statusFilters"
                    options={catalogoProdutosStatusIntegracaoOptions}
                    label="Status"
                  />
                </Box>
              </Grid>

              <Grid item sm={12}>
                <Box mt={1}>
                  <QCXFinalMultilineInputWithChips
                    name="tags"
                    label={t('com.muralis.qcx.descriptionTags')}
                    separateByEnterKey
                  />
                </Box>
              </Grid>

              <Grid item sm={12}>
                <Box mt={1}>
                  <QCXFinalMultilineInputWithChips name="partnumbersTags" label={t('com.muralis.qcx.partnumberTags')} />
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </QCXFinalForm>
    </Grid>
  );
}
