import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  Label as LabelIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  InsertDriveFile,
} from '@material-ui/icons';
import {
  CurrencyExchange as CurrencyExchangeIcon,
} from '@mui/icons-material';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
// import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import FlakyIcon from '@mui/icons-material/Flaky';
import PaidIcon from '@mui/icons-material/Paid';
import { useDispatch } from 'react-redux';
import { FINANCEIRO_MODULE } from '../../../utils/general/modules';
import { focusLockActions } from '../../../features/focus-lock/focusLockSlice';
import QCXMainLayout from '../../../components/main-layout/QCXMainLayout';
import QCXFocusLock from '../../../components/focus-lock/QCXFocusLock';
import QCXCardMenu from '../../../shared-components/card-menu/QCXCardMenu';

const useStyles = makeStyles((theme) => ({
  cardMenuContainer: {
    marginTop: theme.spacing(0),
  },
  cardMenu: {
    width: theme.spacing(100),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  subNested: {
    paddingLeft: theme.spacing(8),
  },
  active: {
    display: 'flex!important',
  },
  noActive: {
    display: 'none!important',
    transformOrigin: '0 0 0!important',
  },
}));

export default function FinancialPage({ authInfo = {} }) {
  const { t } = useTranslation();

  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    location: {
      pathname: currentLocationPath,
    },
  } = useHistory();

  const userHasAnyRole = useCallback((mainRoles = []) => (
    authInfo?.roles?.some(
      (role) => (
        mainRoles?.find(
          (item) => role?.startsWith(item)
        )
      )
    )
  ), [authInfo]);

  useEffect(() => {
    const handleFocusLock = () => {
      const currentPaths = currentLocationPath.split('/');

      const match = (modulePaths) => (
        modulePaths.every(
          (path) => currentPaths.includes(path)
        )
      );

      if (match(FINANCEIRO_MODULE)) {
        dispatch(focusLockActions.lock());
      }
    };

    handleFocusLock();
  }, [currentLocationPath]);

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.financeiro.label'),
      },
    },
  ];

  return (
    <QCXMainLayout
      pageIcon={<LabelIcon />}
      pageTitle={t('com.muralis.qcx.financeiro.label')}
      breadcrumbs={breadcrumbs}
      authInfo={authInfo}
    >
      <Grid
        className={classes.cardMenuContainer}
        item
        container
        justify="center"
        xs={12}
        sm={12}
        md={12}
        xl={12}
      >
        <QCXFocusLock>
          <Grid container style={{ maxWidth: '1920px' }}>
            {userHasAnyRole(['solicitacao-pagamento']) && (
              <Grid
                item
                justify="center"
                xs={6}
                sm={4}
                md={3}
                lg={3}
                xl={2}
                className={classes.active}
                disabled
              >
                <QCXCardMenu
                  title={t('com.muralis.qcx.solicitacaoPagamento.label')}
                  link={t('com.muralis.qcx.url.financeiroSolicitacaoPagamento')}
                  icon={AccountBalanceWalletIcon}
                />

              </Grid>
            )}
            {userHasAnyRole(['aprovar-recusar-pagamento']) && (
              <Grid
                item
                justify="center"
                xs={6}
                sm={4}
                md={3}
                lg={3}
                xl={2}
                className={classes.active}
              >
                <QCXCardMenu
                  title={t('com.muralis.qcx.financeiro.aprovarRecusarPagamento')}
                  link={t('com.muralis.qcx.url.financeiroAprovarRecusarPagamento')}
                  icon={FlakyIcon}
                />
              </Grid>
            )}
            {userHasAnyRole(['conta-pagar']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={classes.active}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.financeiro.contasPagar')}
                link={t('com.muralis.qcx.url.financeiroContasPagar')}
                icon={CurrencyExchangeIcon}
              />
            </Grid>
            )}
            {userHasAnyRole(['conta-receber']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={classes.active}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.financeiro.contasReceber')}
                link={t('com.muralis.qcx.url.financeiroContasReceber')}
                icon={CurrencyExchangeIcon}
              />
            </Grid>
            )}
            {userHasAnyRole(['faturamento']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={classes.active}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.financeiro.faturamento')}
                link={t('com.muralis.qcx.url.financeiroFaturamento')}
                icon={PaidIcon}
              />
            </Grid>
            )}
            {userHasAnyRole(['conciliacao-bancaria']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={classes.active}
            >
              <QCXCardMenu
                title={t('com.muralis.qcx.financeiro.conciliacaoBancaria')}
                link={t('com.muralis.qcx.url.financeiroConciliacaoBancaria')}
                icon={LocalAtmIcon}
              />
            </Grid>
            )}
            {userHasAnyRole(['relatorios-financeiros']) && (
            <Grid
              item
              justify="center"
              xs={6}
              sm={4}
              md={3}
              lg={3}
              xl={2}
              className={classes.active}
            >
              <QCXCardMenu
                title='Relatórios Financeiros'
                link='/financeiro/relatorios'
                icon={InsertDriveFile}
              />
            </Grid>
            )}
          </Grid>
        </QCXFocusLock>
      </Grid>
    </QCXMainLayout>
  );
}
