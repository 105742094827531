const selectLicencasImportacao = (state) => state.licencaImportacao.list;
const selectLicencaImportacao = (state) => state.licencaImportacao.model;
const selectRelatedReportModel = (state) => state.licencaImportacao.related.model.report;
const selectRelatedMercadoriaModel = (state) => state.licencaImportacao.related.model.mercadoria;
const selectRelatedDuplicataModel = (state) => state.licencaImportacao.related.model.duplicata;
const selectRelatedRegistroModel = (state) => state.licencaImportacao.related.model.registro;
const selectRelatedTransferenciaModel = (state) => state.licencaImportacao.related.model.transferencia;
const selectRelatedTransferenciaItemModel = (state) => state.licencaImportacao.related.model.transferenciaItem;
const selectRelatedEstornoModel = (state) => state.licencaImportacao.related.model.estorno;
const selectRelatedCalculoModel = (state) => state.licencaImportacao.related.model.calculo;
const selectRelatedSelectionLicencaImportacaoList = (state) =>
  state.licencaImportacao.related.list.selectionLicencaImportacao;
const selectRelatedSelectionAvailableItemList = (state) => state.licencaImportacao.related.list.selectionAvailableItem;
const selectRelatedSelectionItemList = (state) => state.licencaImportacao.related.list.selectionItem;
const selectRelatedSelectionChangedItemList = (state) => state.licencaImportacao.related.list.selectionChangedItem;
const selectStatus = (state) => state.licencaImportacao.status;
const selectRefresh = (state) => state.licencaImportacao.refresh;
const selectMode = (state) => state.licencaImportacao.mode.main;
const selectBackgroundMode = (state) => state.licencaImportacao.mode.background;
const selectSubMode = (state) => state.licencaImportacao.mode.sub;
const selectSubBackgroundMode = (state) => state.licencaImportacao.mode.subBackground;
const selectType = (state) => state.licencaImportacao.type;
const selectHistoryModel = (state) => state.licencaImportacao.historyModel;
const selectError = (state) => state.licencaImportacao.error;
const selectResponse = (state) => state.licencaImportacao.response;
const selectCountLi = (state) => state.licencaImportacao.count;

const licencaImportacaoSelectors = {
  selectLicencasImportacao,
  selectLicencaImportacao,
  selectRelatedReportModel,
  selectRelatedMercadoriaModel,
  selectRelatedDuplicataModel,
  selectRelatedRegistroModel,
  selectRelatedTransferenciaModel,
  selectRelatedTransferenciaItemModel,
  selectRelatedEstornoModel,
  selectRelatedSelectionLicencaImportacaoList,
  selectRelatedSelectionAvailableItemList,
  selectRelatedSelectionItemList,
  selectRelatedSelectionChangedItemList,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectSubBackgroundMode,
  selectType,
  selectHistoryModel,
  selectError,
  selectResponse,
  selectCountLi,
};

export {
  licencaImportacaoSelectors,
  selectLicencasImportacao,
  selectRelatedCalculoModel,
  selectLicencaImportacao,
  selectRelatedReportModel,
  selectRelatedMercadoriaModel,
  selectRelatedDuplicataModel,
  selectRelatedRegistroModel,
  selectRelatedTransferenciaModel,
  selectRelatedTransferenciaItemModel,
  selectRelatedEstornoModel,
  selectRelatedSelectionLicencaImportacaoList,
  selectRelatedSelectionAvailableItemList,
  selectRelatedSelectionItemList,
  selectRelatedSelectionChangedItemList,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectSubBackgroundMode,
  selectType,
  selectHistoryModel,
  selectError,
  selectResponse,
  selectCountLi,
};
