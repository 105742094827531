import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllFromFaturaAsync } from '../../features/declaracao-importacao/declaracaoImportacaoThunks';
import { selectDeclaracoesImportacao } from '../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import {
  CALCULADA_STATUS,
  CONFERIDA_STATUS,
  LIBERADA,
} from '../../utils/general/operations/operationUtils';

function diDescription(di) {
  const numeroProcesso = di?.atributosAdicionais?.processo;
  return `${numeroProcesso}`;
}

function filterDi(list) {
  return list?.filter((di) => (
    di?.calculada === CALCULADA_STATUS
    && di?.atributosAdicionais?.conferida === CONFERIDA_STATUS
    && di?.atributosAdicionais?.liberada === LIBERADA
    && di?.atributosAdicionais?.registro
  ));
}

export default function QCXSelectDeclaracaoImportacaoAutocomplete({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const dispatch = useDispatch();

  const list = useSelector(selectDeclaracoesImportacao);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    dispatch(fetchAllFromFaturaAsync());
  }, []);

  useEffect(() => {
    const value = list.find((item) => (
      item?.id === _.get(initialValues, name)
    ));

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (option) => (
      diDescription(option)
    ),
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={filterDi(list)}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionValue={(option) => (
        option?.id
      )}
      getOptionLabel={(option) => (
        diDescription(option)
      )}
      {...restProps}
    />
  );
}
