/* eslint-disable indent */
import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { normalizeNumeral, unnormalizeNumeral } from '../general-utils';
import { formatBrazilianNumericDecimal } from '../../hooks/form/field/formatters';
import { fetchByFilter } from '../../../features/taxa-conversao-cambio/taxaConversaoCambioAPI';

// renomear para useTotaisListenersGroup
// usar dentro do QCXDeclaracaoImportacaoTotaisFormGroup
export default function useTotaisListenersGroup(isConsult, moedas) {
  // const { values } = useFormState();

  const handleMoedaMLEIdChange = useCallback(
    (form) => (value) => {
      if (!isConsult) {
        if (!value) {
          form.change('mle.valorMoeda', '');
          form.change('mle.valorReal', '');

          // form.resetFieldState('mle.valorMoeda');
          // form.resetFieldState('mle.valorReal');
        } else {
          form.change('mle.valorReal', '');
          // form.resetFieldState('mle.valorReal');

          // Descomentar para alterar a moeda do seguro de acordo com a moeda do MLE
          // form.change('seguro.moeda.id', value);
        }
      }
    },
    [isConsult]
  );

  const handleMoedaDescontoIdChange = useCallback(
    (form) => (value) => {
      if (!isConsult) {
        if (!value) {
          form.change('desconto.valorMoeda', '');
          form.change('desconto.valorReal', '');

          form.resetFieldState('desconto.valorMoeda');
          form.resetFieldState('desconto.valorReal');
        } else {
          form.change('desconto.valorReal', '');
          form.resetFieldState('desconto.valorReal');
        }
      }
    },
    [isConsult]
  );

  const handleMoedaDespesaIdChange = useCallback(
    (form) => (value) => {
      if (!isConsult) {
        if (!value) {
          form.change('despesa.valorMoeda', '');
          form.change('despesa.valorReal', '');

          form.resetFieldState('despesa.valorMoeda');
          form.resetFieldState('despesa.valorReal');
        } else {
          form.change('despesa.valorReal', '');
          form.resetFieldState('despesa.valorReal');
        }
      }
    },
    [isConsult]
  );

  const handleMoedaFreteIdChange = useCallback(
    (form) => (value) => {
      if (!isConsult) {
        if (!value) {
          form.change('frete.valorMoedaPrepaid', '');
          form.change('frete.valorRealPrepaid', '');
          form.change('frete.valorMoedaCollect', '');
          form.change('frete.valorRealCollect', '');
          form.change('frete.valorFreteNacionalMoeda', '');
          form.change('frete.valorFreteNacionalReal', '');

          if (form.getState().values?.atributosAdicionais?.tipo !== 'DUE') {
            form.resetFieldState('frete.valorMoedaPrepaid');
            form.resetFieldState('frete.valorRealPrepaid');
            form.resetFieldState('frete.valorMoedaCollect');
            form.resetFieldState('frete.valorRealCollect');
            form.resetFieldState('frete.valorFreteNacionalMoeda');
            form.resetFieldState('frete.valorFreteNacionalReal');
          }
        } else {
          form.change('frete.valorRealPrepaid', '');
          form.change('frete.valorRealCollect', '');
          form.change('frete.valorFreteNacionalReal', '');

          if (form.getState().values?.atributosAdicionais?.tipo !== 'DUE') {
            form.resetFieldState('frete.valorRealPrepaid');
            form.resetFieldState('frete.valorRealCollect');
            form.resetFieldState('frete.valorFreteNacionalReal');
          }
        }
      }
    },
    [isConsult]
  );

  const handleValorMoedaFreteTerritorioNacionalChange = useCallback(
    (form) => (value) => {
      if (!value) {
        form.change('frete.valorFreteNacionalReal', '');
        if (form.getState().values?.atributosAdicionais?.tipo !== 'DUE') {
          form.resetFieldState('frete.valorFreteNacionalReal');
        }
      }
    },
    []
  );

  const handleMoedaSeguroIdChange = useCallback(
    (form) => () => {
      if (!isConsult) {
        form.change('seguro.valorMoeda', '0,00');
        form.change('seguro.valorReal', '0,00');

        form.resetFieldState('seguro.valorMoeda');
        form.resetFieldState('seguro.valorReal');
      }
    },
    [isConsult]
  );

  const handleValorNaMoedaSeguro = useCallback(
    (form, values) => async () => {
      const valorMleReal = Number(normalizeNumeral(values?.mle?.valorReal));
      const valorFreteCollectReal = Number(normalizeNumeral(values?.frete?.valorRealCollect));
      const valorFretePrepaidReal = Number(normalizeNumeral(values?.frete?.valorRealPrepaid));
      const valorFreteNacionalReal = Number(normalizeNumeral(values?.frete?.valorFreteNacionalReal));
      const valorPercentualSeguro = normalizeNumeral(values?.seguro?.valorPercentual);
      const moedaId = Number(_.get(values, 'seguro.moeda.id'));
      const moedaSeguro = moedas.find((item) => item?.id === moedaId);
      const isCalculaSobreMleFrete = values?.seguro?.calculaSobreMleFrete;
      const embutirFreteItem = values?.frete?.embutirItem;

      if (valorPercentualSeguro === 0 || valorPercentualSeguro === '') {
        return;
      }

      // 790 CODIGO MOEDA REAL

      if (isCalculaSobreMleFrete && moedaSeguro?.id && moedaSeguro?.code !== '790') {
        let valorSeguroReal;

        if (embutirFreteItem) {
          valorSeguroReal = (valorMleReal * (valorPercentualSeguro / 100)).toFixed(2);
        } else {
          valorSeguroReal = (
            (valorMleReal + (valorFreteCollectReal + valorFretePrepaidReal) - valorFreteNacionalReal) *
            (valorPercentualSeguro / 100)
          ).toFixed(2);
        }

        const formattedSeguroReal = unnormalizeNumeral(valorSeguroReal, formatBrazilianNumericDecimal(2));
        form.change('seguro.valorReal', formattedSeguroReal);
        if (valorSeguroReal) {
          const response = await fetchByFilter([{ name: 'moeda', value: moedaSeguro.id }]);
          const tcc = _.get(response, 'data[0]');
          if (tcc?.taxaConversao) {
            const valorMoeda = (valorSeguroReal / tcc.taxaConversao).toFixed(2);
            const formattedValorMoeda = unnormalizeNumeral(valorMoeda, formatBrazilianNumericDecimal(2));
            form.change('seguro.valorMoeda', formattedValorMoeda);
          } else {
            form.change('seguro.valorMoeda', '0,00');
          }
        }
      }
      if (isCalculaSobreMleFrete && (!moedaSeguro?.id || moedaSeguro?.code === '790')) {
        let valorSeguroReal;

        if (embutirFreteItem) {
          valorSeguroReal = (valorMleReal * (valorPercentualSeguro / 100)).toFixed(2);
        } else {
          valorSeguroReal = (
            (valorMleReal + (valorFreteCollectReal + valorFretePrepaidReal - valorFreteNacionalReal)) *
            (valorPercentualSeguro / 100)
          ).toFixed(2);
        }

        const formattedSeguroReal = unnormalizeNumeral(valorSeguroReal, formatBrazilianNumericDecimal(2));
        form.change('seguro.valorMoeda', formattedSeguroReal);
        form.change('seguro.valorReal', formattedSeguroReal);
      }
      if (!isCalculaSobreMleFrete && moedaSeguro?.id && moedaSeguro?.code !== '790') {
        let valorSeguroReal;

        if (embutirFreteItem) {
          valorSeguroReal =
            (valorMleReal - (valorFreteCollectReal + valorFretePrepaidReal - valorFreteNacionalReal)) *
            (valorPercentualSeguro / 100);
        } else {
          valorSeguroReal = (valorMleReal * (valorPercentualSeguro / 100)).toFixed(2);
        }

        const formattedSeguroReal = unnormalizeNumeral(valorSeguroReal, formatBrazilianNumericDecimal(2));
        form.change('seguro.valorReal', formattedSeguroReal);
        if (valorSeguroReal) {
          const response = await fetchByFilter([{ name: 'moeda', value: moedaSeguro.id }]);
          const tcc = _.get(response, 'data[0]');
          if (tcc?.taxaConversao) {
            const valorMoeda = (valorSeguroReal / tcc.taxaConversao).toFixed(2);
            const formattedValorMoeda = unnormalizeNumeral(valorMoeda, formatBrazilianNumericDecimal(2));
            form.change('seguro.valorMoeda', formattedValorMoeda);
          } else {
            form.change('seguro.valorMoeda', '0,00');
          }
        }
      }
      if (!isCalculaSobreMleFrete && (!moedaSeguro?.id || moedaSeguro?.code === '790')) {
        let valorSeguroMoeda;

        if (embutirFreteItem) {
          valorSeguroMoeda =
            (valorMleReal - (valorFreteCollectReal + valorFretePrepaidReal - valorFreteNacionalReal)) *
            (valorPercentualSeguro / 100);
        } else {
          valorSeguroMoeda = (valorMleReal * (valorPercentualSeguro / 100)).toFixed(2);
        }
        const formattedSeguroMoeda = unnormalizeNumeral(valorSeguroMoeda, formatBrazilianNumericDecimal(2));
        form.change('seguro.valorMoeda', formattedSeguroMoeda);
        form.change('seguro.valorReal', formattedSeguroMoeda);
      }
    },
    [moedas, normalizeNumeral, formatBrazilianNumericDecimal]
  );

  const handleMoedaMLEDUEOnBlur = useCallback(
    (form, values) => async () => {
      try {
        const moedaMLE = form.getState().values.mle.moeda;
        form.change('desconto.moeda', moedaMLE);
        _.set(values, 'desconto.moeda', moedaMLE);
        form.change('despesa.moeda', moedaMLE);
        _.set(values, 'despesa.moeda', moedaMLE);
        form.change('frete.moeda', moedaMLE);
        _.set(values, 'frete.moeda', moedaMLE);
        form.change('seguro.moeda', moedaMLE);
        _.set(values, 'seguro.moeda', moedaMLE);
      } catch (e) {
        console.error(e);
      }
    },
    []
  );

  const handleValorNaMoedaOnBlur = useCallback(
    (form, values, moedaPath, valorNaMoedaPath, valorEmReaisPath) => async () => {
      const moedaId = Number(_.get(values, `${moedaPath}.id`));
      const moeda = moedas.find((item) => item?.id === moedaId);
      const valorNaMoeda = normalizeNumeral(_.get(values, valorNaMoedaPath));

      if (moeda?.code === '790' && valorNaMoeda) {
        const formattedValorEmReais = unnormalizeNumeral(valorNaMoeda, formatBrazilianNumericDecimal(2));
        form.change(valorEmReaisPath, formattedValorEmReais);
        // return;
      }
      let valorCalculadoReais;
      if (moedaId && valorNaMoeda && moeda?.code !== '790') {
        const response = await fetchByFilter([{ name: 'moeda', value: moedaId }]);
        const tcc = _.get(response, 'data[0]');
        if (tcc?.taxaConversao) {
          const valorEmReais = (valorNaMoeda * tcc.taxaConversao).toFixed(2);
          const formattedValorEmReais = unnormalizeNumeral(valorEmReais, formatBrazilianNumericDecimal(2));
          valorCalculadoReais = formattedValorEmReais;
          form.change(valorEmReaisPath, formattedValorEmReais);
        } else {
          form.change(valorEmReaisPath, '0,00');
        }
      }
      const updatedValues = _.set(values, valorEmReaisPath, valorCalculadoReais);
      handleValorNaMoedaSeguro(form, updatedValues)();
    },
    [moedas, unnormalizeNumeral, handleValorNaMoedaSeguro, formatBrazilianNumericDecimal]
  );

  const handleValorEmReaisOnBlur = useCallback(
    (form, values, moedaPath, valorNaMoedaPath, valorEmReaisPath) => async () => {
      const moedaId = Number(_.get(values, `${moedaPath}.id`));
      const moeda = moedas.find((item) => item?.id === moedaId);
      const valorReal = normalizeNumeral(_.get(values, valorEmReaisPath));
      let valorMoeda = 0;

      if (moeda?.code === '790' && valorReal) {
        valorMoeda = valorReal;
      }

      if (moedaId && valorReal && moeda?.code !== '790' && valorReal !== 0) {
        const response = await fetchByFilter([{ name: 'moeda', value: moedaId }]);
        const tcc = _.get(response, 'data[0]');
        if (tcc?.taxaConversao) {
          valorMoeda = Math.round((valorReal / tcc.taxaConversao) * 100) / 100;
        }
      }

      const updates = {
        [valorEmReaisPath]: valorReal,
        [valorNaMoedaPath]: valorMoeda,
      };

      _.forOwn(updates, (value, key) => {
        const formattedValue = unnormalizeNumeral(value, formatBrazilianNumericDecimal(2));
        _.set(values, key, formattedValue);
        if (value !== 0) {
          form.change(key, formattedValue);
        } else {
          form.change(key, '0,00');
        }
      });
    },
    [moedas, unnormalizeNumeral, formatBrazilianNumericDecimal]
  );

  const handleAFRMMValorEmReaisOnBlur = useCallback(
    (form, values, moedaPath, valorNaMoedaPath, valorRealPath, valorEmDolarPath, dataDePagamentoPath) => async () => {
      const moedaId = Number(_.get(values, moedaPath));
      const moeda = moedas.find((item) => item?.id === moedaId);
      const valorReal = Number(normalizeNumeral(_.get(values, valorRealPath)));
      const dataPagamento = _.get(values, dataDePagamentoPath);

      let valorNaMoeda = '0,00';
      let valorEmDolar = '0,00';

      if (moeda?.code === '790' && valorReal) {
        // Se a moeda é real, atualiza o valor na moeda com o valor em reais
        valorNaMoeda = unnormalizeNumeral(valorReal, formatBrazilianNumericDecimal(2));
      } else if (moedaId && valorReal && moeda?.code !== '790' && valorReal !== 0) {
        // Se a moeda não é real, atualiza o valor na moeda com usando a taxa de conversão
        const response = await fetchByFilter([{ name: 'moeda', value: moedaId }]);
        const responseData = _.get(response, 'data', []);

        const tcc = responseData[0];

        if (tcc?.taxaConversao) {
          valorNaMoeda = (valorReal / tcc.taxaConversao).toFixed(2);

          if (moeda?.code === '220') {
            valorEmDolar = valorNaMoeda;
          } else {
            const taxasConversaoDolar = await fetchByFilter([{ name: 'moeda', value: 153810 }]);
            const tcd = taxasConversaoDolar.data[0];

            if (tcd?.taxaConversao) {
              valorEmDolar = (valorReal / tcd.taxaConversao).toFixed(2);
            }
          }
        }
      }

      if (!dataPagamento) {
        _.set(values, dataDePagamentoPath, null);
      }

      const updates = {
        [valorRealPath]: valorReal,
        [valorNaMoedaPath]: valorNaMoeda,
        [valorEmDolarPath]: valorEmDolar,
      };

      _.forOwn(updates, (value, key) => {
        const formattedValue = unnormalizeNumeral(value, formatBrazilianNumericDecimal(2));
        _.set(values, key, formattedValue);
        form.change(key, formattedValue);
      });
    },
    [normalizeNumeral, unnormalizeNumeral, formatBrazilianNumericDecimal]
  );

  const changeListeners = useMemo(
    () => [
      {
        name: 'mle.moeda.id',
        fn: handleMoedaMLEIdChange,
      },
      {
        name: 'desconto.moeda.id',
        fn: handleMoedaDescontoIdChange,
      },
      {
        name: 'despesa.moeda.id',
        fn: handleMoedaDespesaIdChange,
      },
      {
        name: 'frete.moeda.id',
        fn: handleMoedaFreteIdChange,
      },
      {
        name: 'frete.valorFreteNacionalMoeda',
        fn: handleValorMoedaFreteTerritorioNacionalChange,
      },
      {
        name: 'seguro.moeda.id',
        fn: handleMoedaSeguroIdChange,
      },
      {
        name: 'seguro.calculaSobreMleFrete',
        fn: handleValorNaMoedaSeguro,
      },
    ],
    [
      handleMoedaMLEIdChange,
      handleMoedaDescontoIdChange,
      handleValorNaMoedaSeguro,
      handleMoedaFreteIdChange,
      handleMoedaSeguroIdChange,
      handleMoedaDespesaIdChange,
      handleValorMoedaFreteTerritorioNacionalChange,
    ]
  );

  const onBlurObjectHandlers = useMemo(
    () => ({
      handleValorNaMoedaOnBlur,
      handleMoedaMLEDUEOnBlur,
      handleValorNaMoedaSeguro,
      handleValorEmReaisOnBlur,
      handleAFRMMValorEmReaisOnBlur,
    }),
    [
      handleValorNaMoedaOnBlur,
      handleMoedaMLEDUEOnBlur,
      handleValorNaMoedaSeguro,
      handleValorEmReaisOnBlur,
      handleAFRMMValorEmReaisOnBlur,
    ]
  );

  return [onBlurObjectHandlers, changeListeners];
}
