import { useMemo } from "react";
import { FaturaData, MercadoriaData } from "./faturasPlanilhasPage.types";
import { convertExcelTimestampToDateStr } from "../../../../../utils/general/dateTime-utils";

export function useProcessedSpreadsheetData(
    spreadsheetData: any[][] | undefined,
    faturaColumns: { field?: string }[],
    itemsColumns: { field?: string }[]
  ): [FaturaData[], MercadoriaData[]]{
    const createObjectFromValues = <T extends Record<string, any>>(
        values: string[],
        columns: { field?: string }[],
        additionalData?: Partial<T>
      ): T => {
        const obj: Record<string, any> = {};
    
        values.forEach((value, index) => {
          const fieldName = columns[index]?.field;
          if (fieldName) {
            if(fieldName === 'dataDeValidade'){
              obj[fieldName] = convertExcelTimestampToDateStr((value as unknown as number));
            }else{
              obj[fieldName] = value;
            }
          }
        });
    
        if (additionalData) {
          for (const key in additionalData) {
            if (additionalData[key] !== undefined) {
              obj[key] = additionalData[key];
            }
          }
        }
    
        return obj as T;
      };
    
    return useMemo(() => {
      const faturasData: FaturaData[] = [];
      const itensData: MercadoriaData[] = [];
      const alreadyAdded = new Set<string>();

      if (faturaColumns.length && itemsColumns.length && spreadsheetData) {
        const spreadsheetROI = spreadsheetData.slice(3);

        spreadsheetROI.forEach((row, outterIndex) => {
          const numeroFatura = row[0] as string;
          const faturaValues = row.slice(0, 18);
          const itemValues = row.slice(17);

          if (!alreadyAdded.has(numeroFatura)) {
            const fatura = createObjectFromValues<FaturaData>(faturaValues, faturaColumns, { id: outterIndex });
            faturasData.push(fatura);
            alreadyAdded.add(numeroFatura);
          }

          const item = createObjectFromValues<MercadoriaData>(itemValues, itemsColumns, {
            id: outterIndex,
            faturapo: numeroFatura,
          });
          itensData.push(item);
        });
      }

      return [faturasData, itensData];
    }, [spreadsheetData, faturaColumns, itemsColumns]);
  };

  export function useProcessedItemSpreadsheetData(
    spreadsheetData: any[][] | undefined,
    itemsColumns: { field?: string }[],
    setIsModalOpen: (value: boolean) => void
  ): MercadoriaData[] {
    const createObjectFromValues = <T extends Record<string, any>>(
      values: string[],
      columns: { field?: string }[],
      additionalData?: Partial<T>
    ): T => {
      const obj = columns.reduce<Record<string, any>>((acc, column, index) => {
        const fieldName = column.field;
        if (fieldName) {
          acc[fieldName] = fieldName === 'dataDeValidade'
            ? convertExcelTimestampToDateStr(values[index] as unknown as number)
            : values[index];
        }
        return acc;
      }, {});
  
      return { ...obj, ...additionalData } as T;
    };
  
    return useMemo(() => {
      if (!itemsColumns.length || !spreadsheetData) {
        return [];
      }
  
      const spreadsheetROI = spreadsheetData.slice(3);
  
      const itensData: MercadoriaData[] = spreadsheetROI.map((row, index) => {
        const itemValues = row.slice(0, itemsColumns.length);
        return createObjectFromValues<MercadoriaData>(itemValues, itemsColumns, { id: index });
      });
  
      setIsModalOpen(false);
  
      return itensData;
    }, [spreadsheetData, itemsColumns, setIsModalOpen]);
  }
  
  