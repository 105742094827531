import { Grid, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArchiveIcon from '@mui/icons-material/Archive';
import { ChangeEvent, DragEvent, useRef, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { FieldInputProps } from 'react-final-form';

const FileDragAndDrop = ({
  accept,
  input,
  onChangeAction,
  multiple = false,
}: {
  accept: string;
  input?: FieldInputProps<File, HTMLInputElement>;
  onChangeAction?: (event: ChangeEvent<HTMLInputElement>, file: File) => void;
  multiple?: boolean;
}) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [files, setFiles] = useState<File[] | null>(null);
  const theme = useTheme();

  const handleDrag = (e: DragEvent<HTMLFormElement> | DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleFilesChange = (files: FileList) => {
    if (multiple) {
      const filesArray = Array.from(files);
      setFiles(filesArray);
      input?.onChange(filesArray);
      // TODO: onChangeAction for multiple files
    } else {
      const file = files[0];
      setFile(file);
      input?.onChange(file);
      onChangeAction?.({} as ChangeEvent<HTMLInputElement>, file);
    }
  };

  const handleDrop = (e: DragEvent<HTMLFormElement> | DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files) {
      handleFilesChange(e.dataTransfer.files);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      handleFilesChange(e.target.files);
    }
  };

  return (
    <Grid item xs={12}>
      <form
        action=""
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
        onSubmit={(e) => e.preventDefault()}
        style={{
          position: 'relative',
          textAlign: 'center',
          color: 'gray',
        }}
      >
        <input
          ref={inputRef}
          type="file"
          accept={accept}
          id="input-file-upload"
          multiple={multiple}
          onChange={handleChange}
          style={{ display: 'none' }}
          name={input?.name}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px dashed #ccc',
            borderRadius: '5px',
            minHeight: '100px',
            display: 'flex',
            cursor: 'pointer',
          }}
        >
          <div>
            {dragActive && (
              <>
                <ArchiveIcon sx={{ width: 40, height: 40 }} color={'primary'} />
                <Typography color={'primary'}>Solte o arquivo aqui</Typography>
              </>
            )}
            {!dragActive && (
              <>
                <CloudUploadIcon sx={{ width: 40, height: 40 }} color={file ? 'primary' : 'inherit'} />
                {!multiple && (
                  <Typography color={file ? 'primary' : 'inherit'}>
                    {file
                      ? `Arquivo ${file.name} selecionado`
                      : 'Arraste e solte o arquivo aqui ou clique para selecionar'}
                  </Typography>
                )}
                {multiple && (
                  <Typography color={files ? 'primary' : 'inherit'}>
                    {files
                      ? `Arquivos selecionados: ${files.map((file) => file.name).join(', ')}`
                      : 'Arraste e solte os arquivos aqui ou clique para selecionar'}
                  </Typography>
                )}
              </>
            )}
          </div>
        </label>
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: '0px',
              right: ' 0px',
              bottom: ' 0px',
              left: ' 0px',
            }}
          ></div>
        )}
      </form>
    </Grid>
  );
};

export default FileDragAndDrop;
