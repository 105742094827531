import { StompSessionProvider } from 'react-stomp-hooks';

interface WebSocketProviderProps {
  children: React.ReactNode;
  webSocketUrl: string;
}

const WebSocketProvider = ({ children, webSocketUrl }: WebSocketProviderProps) => (
  <StompSessionProvider url={webSocketUrl}>{children}</StompSessionProvider>
);

export default WebSocketProvider;
