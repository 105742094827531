import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, styled } from '@mui/material';

interface ConfirmationModalProps {
  title: string;
  open: boolean;
  message: string;
  singleAction?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isHtmlMessage?: boolean; 
}

const ConfirmButton = styled(Button)`
  background: #312783;
  color: white;
  &:hover {
    background: #251b62;
  }
`;

const CancelButton = styled(Button)`
  background: ${({ theme }) => theme.palette.grey[500]};
  color: white;
  &:hover {
    background: ${({ theme }) => theme.palette.grey[700]};
  }
`;

const CustomDialogTitle = styled(DialogTitle)({
  fontSize: '1.25rem',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '8px',
});

const CustomDialogContent = styled(DialogContent)({
  padding: '8px',
  maxHeight: '400px',
  overflowY: 'auto',  
});

const CustomDialogContentText = styled('div')({
  fontSize: '1rem',
  padding: '16px',
  border: '1px solid transparent', 
  borderRadius: '4px',
});

const CustomDialogActions = styled(DialogActions)({
  padding: '8px',
});

const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '100%',   
    maxWidth: '600px', 
  },
});

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  open,
  message,
  singleAction,
  onConfirm,
  onCancel,
  isHtmlMessage = false, 
}) => {
  return (
    <StyledDialog open={open} onClose={onCancel}>
      <CustomDialogTitle>{title}</CustomDialogTitle>
      <CustomDialogContent>
        {isHtmlMessage ? (
          <CustomDialogContentText dangerouslySetInnerHTML={{ __html: message }} />
        ) : (
          <CustomDialogContentText>{message}</CustomDialogContentText>
        )}
      </CustomDialogContent>
      <CustomDialogActions>
        {!singleAction && <CancelButton onClick={onCancel}>Não</CancelButton>}
        <ConfirmButton onClick={onConfirm} autoFocus>
          {singleAction ? 'Ok' : 'Sim'}
        </ConfirmButton>
      </CustomDialogActions>
    </StyledDialog>
  );
};

export default ConfirmationModal;
