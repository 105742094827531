import { Grid, makeStyles } from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
} from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { useFormState } from 'react-final-form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchById as fetchEmpresaEstrangeiraById } from '../../features/empresa-estrangeira/empresaEstrangeiraAPI';
import QCXSelectEmpresaEstrangeiraAutocomplete from '../../shared-components/final-select-empresa-estrangeira-field/QCXSelectEmpresaEstrangeiraAutocomplete';
import QCXSelectRelacaoFornecedorAutocomplete from '../../shared-components/select-relacao-fornecedor/QCXSelectRelacaoFornecedorAutocomplete';
import QCXSelectPaisAutocomplete from '../../shared-components/select-pais/QCXSelectPaisAutocomplete';
import QCXReadOnlyTextField from '../../shared-components/text-field/QCXReadOnlyTextField';
import { required as requiredValidator } from '../../utils/validators/field/validator';
import {
  RELACAO_FABRICANTE_DESCONHECIDO,
  RELACAO_FABRICANTE_EXPORTADOR,
  RELACAO_FABRICANTE_NAO_EXPORTADOR,
} from './relacao-fornecedor-utils';
import { isValid } from '../../utils/general/general-utils';
import QCXAloneInlineBoxWrapper from '../../shared-components/alone-inline-box-wrapper/QCXAloneInlineBoxWrapper';
import QCXSelectManyFabricantes from './QCXSelectManyFabricantes'
import QCXSelectManyExportadores from './QCXSelectManyExportadores'
import QCXSelectManyCodigoInterno from './QCXSelectManyCodigoInterno'
import QCXButton from '../../shared-components/button/QCXButton';
import ExportadorModal from '../../ts/pages/importacao/faturaMercadoria/exportadorModal';


const useStyles = makeStyles((theme) => ({
  controlButton: {
    marginRight: theme.spacing(1),
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
  },
}));

const DEFAULT_PAIS_MASK_VALUE = '####### / ###';

export default function QCXRelacaoFornecedorFieldGroup({
  isConsult,
  isAlternativeReasonToDisabled,
  hideCodigoInterno = false,
  relacao,
  fabricante,
  exportador,
  rootFieldName = '',
  required = true,
  fieldNames = {
    relacao: 'relacao',
    paisOrigem: 'paisOrigem',
    exportador: 'exportador',
    fabricante: 'fabricante',
  },
  autoCompleteProps = {},
  renderSiblings = () => {},
  manyFornecedores = false,
  authInfo = {},
}) {
  const { t } = useTranslation();

  const { values } = useFormState();

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const [loadingPaisOrigemFabricante, setLoadingPaisOrigemFabricante] = useState(false);
  const [loadingPaisAquisicaoExportador, setLoadingPaisAquisicaoExportador] = useState(false);

  const [paisOrigemFabricante, setPaisOrigemFabricante] = useState(DEFAULT_PAIS_MASK_VALUE);
  const [paisAquisicaoExportador, setPaisAquisicaoExportador] = useState('-');

  const isEmptyRelacao = useMemo(() => isEmpty(relacao), [relacao, isEmpty]);

  const isFabricanteExportador = useMemo(() => relacao === RELACAO_FABRICANTE_EXPORTADOR, [relacao]);

  const isNotFabricanteExportador = useMemo(() => relacao === RELACAO_FABRICANTE_NAO_EXPORTADOR, [relacao]);

  const isUnknownFabricante = useMemo(() => relacao === RELACAO_FABRICANTE_DESCONHECIDO, [relacao]);

  const classes = useStyles();

  const preparePaisByEmpresaEstrangeira = useCallback((data) => {
    const defaultValue = DEFAULT_PAIS_MASK_VALUE;

    if (data && !isEmpty(data)) {
      const pais = data?.endereco?.pais;
      const name = pais?.description;
      const code = pais?.code;

      const paisFromEmpresaEstrangeira = code && name ? `${name} / ${code}` : defaultValue;

      return paisFromEmpresaEstrangeira;
    }

    return defaultValue;
  }, []);

  const handleSetPaisOrigemFabricante = useCallback(
    (data) => {
      const preparedPaisOrigemFabricante = preparePaisByEmpresaEstrangeira(data);
      setPaisOrigemFabricante(preparedPaisOrigemFabricante);
    },
    [setPaisOrigemFabricante, preparePaisByEmpresaEstrangeira]
  );

  const handleSetPaisAquisicaoExportador = useCallback(
    (data) => {
      const preparedPaisAquisicaoExportador = preparePaisByEmpresaEstrangeira(data);

      setPaisAquisicaoExportador(preparedPaisAquisicaoExportador);
    },
    [setPaisAquisicaoExportador, preparePaisByEmpresaEstrangeira]
  );

  const findPaisByEmpresaEstrangeiraId = useCallback(async (id) => {
    if (isValid(id)) {
      const found = await fetchEmpresaEstrangeiraById(id);

      return found?.data;
    }

    return undefined;
  }, []);

  useEffect(() => {
    const fetchPaisAquisicaoExportador = async (data) => {
      if (!data || !data.id || (isConsult && paisAquisicaoExportador !== '-')) return;

      setLoadingPaisAquisicaoExportador(true);
      handleSetPaisAquisicaoExportador(DEFAULT_PAIS_MASK_VALUE);

      const foundPaisAquisicaoExportador = await findPaisByEmpresaEstrangeiraId(data?.id);
      handleSetPaisAquisicaoExportador(foundPaisAquisicaoExportador);

      setLoadingPaisAquisicaoExportador(false);
    };

    if (exportador) {
      fetchPaisAquisicaoExportador(exportador);
    }
  }, [isConsult, exportador, handleSetPaisAquisicaoExportador]);

  useEffect(() => {
    const fetchPaisOrigemFabricante = async (data) => {
      if (!data || !data.id) return;

      setLoadingPaisOrigemFabricante(true);
      handleSetPaisOrigemFabricante(DEFAULT_PAIS_MASK_VALUE);

      const isDifferentFabricanteIdFromExportadorId = data && exportador?.id !== data?.id;

      if (isNotFabricanteExportador && isDifferentFabricanteIdFromExportadorId) {
        const foundPaisOrigemFabricante = await findPaisByEmpresaEstrangeiraId(data?.id);
        handleSetPaisOrigemFabricante(foundPaisOrigemFabricante);
      }

      setLoadingPaisOrigemFabricante(false);
    };

    fetchPaisOrigemFabricante(fabricante);
  }, [
    isConsult,
    fabricante,
    exportador,
    isNotFabricanteExportador,
    handleSetPaisOrigemFabricante,
    setLoadingPaisOrigemFabricante,
  ]);

  const fabricanteFilterPredicate = useCallback(
    (empresaEstrangeira) =>
      empresaEstrangeira?.fabricante && empresaEstrangeira?.active && empresaEstrangeira?.id !== exportador?.id,
    [exportador]
  );

  const exportadorFilterPredicate = useCallback(
    (empresaEstrangeira) => empresaEstrangeira?.exportador && empresaEstrangeira?.active,
    []
  );

  const renderSiblingsWrapper = useCallback(() => renderSiblings(values), [renderSiblings, values]);

  const verifiedRootFieldName = useMemo(() => (isEmpty(rootFieldName) ? '' : `${rootFieldName}.`), [rootFieldName]);

  const computedRelacaoFornecedorFieldName = useMemo(
    () => `${verifiedRootFieldName}${fieldNames?.relacao}`,
    [verifiedRootFieldName, fieldNames]
  );

  const computedPaisOrigemMercadoriaFieldName = useMemo(
    () => `${verifiedRootFieldName}${fieldNames?.paisOrigem}.id`,
    [verifiedRootFieldName, fieldNames]
  );

  const computedExportadorFieldName = useMemo(
    () => `${verifiedRootFieldName}${fieldNames?.exportador}.id`,
    [verifiedRootFieldName, fieldNames]
  );

  const computedFabricanteFieldName = useMemo(
    () => `${verifiedRootFieldName}${fieldNames?.fabricante}.id`,
    [verifiedRootFieldName, fieldNames]
  );

  const paisOrigemAquisicaoLabel = useMemo(
    () =>
      isFabricanteExportador ? t('com.muralis.qcx.pais.paisOrigemAquisicao') : t('com.muralis.qcx.pais.paisAquisicao'),
    [isFabricanteExportador]
  );

  const isDuimp = () => Array.isArray(values?.tipo) && values.tipo.includes("DUIMP")

  return (
    <>
      <Grid item container spacing={2}>
        {!hideCodigoInterno && isDuimp() && (
          <>
            <QCXSelectManyCodigoInterno
              title="Código Interno do Produto"
              name="codigos"
              selected={values}
              isConsult={isConsult}
              configureFilterPredicate={exportadorFilterPredicate}
            />
          </>
        )}
        
        <QCXAloneInlineBoxWrapper renderSiblings={renderSiblingsWrapper}>
          {(fieldProps) => (
            <QCXSelectRelacaoFornecedorAutocomplete
              id="autocomplete-select-relacao-fornecedor-field"
              key="autocomplete-select-relacao-fornecedor-field"
              name={computedRelacaoFornecedorFieldName}
              label={t('com.muralis.qcx.fornecedor.relacaoFornecedor')}
              initialValues={values}
              fieldProps={{
                validate: required && requiredValidator,
              }}
              required={required}
              disabled={isConsult || isAlternativeReasonToDisabled}
              {...fieldProps}
            />
          )}
        </QCXAloneInlineBoxWrapper>
        {!isEmptyRelacao && (isFabricanteExportador || isNotFabricanteExportador || isUnknownFabricante) && (!manyFornecedores || (manyFornecedores && !isDuimp())) && (
          <>
            {(isFabricanteExportador || isNotFabricanteExportador || isUnknownFabricante) && (
                            <Grid item container xs={12} sm={12} md={12} lg={12} spacing={1}>
              <Grid item xs={10} sm={10} md={5} lg={5}>
                <QCXSelectEmpresaEstrangeiraAutocomplete
                  id="select-field-empresa-estrangeira-exportador"
                  key="select-field-empresa-estrangeira-exportador"
                  name={computedExportadorFieldName}
                  label={t('com.muralis.qcx.exportador.label')}
                  initialValues={values}
                  filterPredicate={exportadorFilterPredicate}
                  fieldProps={{
                    validate: requiredValidator,
                  }}
                  disabled={isConsult || isAlternativeReasonToDisabled}
                  required
                  {...autoCompleteProps}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={1} lg={1}>
                <QCXButton
                  id="add-item-button"
                  key="add-item-button"
                  className={classes.controlButtonTop}
                  color="primary"
                  startIcon={<AddCircleIcon />}
                  disabled={isConsult || isAlternativeReasonToDisabled}
                  onClick={handleOpenModal}
                >
                  {t('com.muralis.qcx.acoes.novo')}
                </QCXButton>
              </Grid>
              {isModalOpen && <ExportadorModal open={isModalOpen} onClose={handleCloseModal} authInfo={authInfo}/>}
            </Grid>
            )}
            {paisAquisicaoExportador && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <QCXReadOnlyTextField
                  key="pais-origem-read-only-input"
                  id="pais-origem-read-only-input"
                  label={paisOrigemAquisicaoLabel}
                  value={paisAquisicaoExportador}
                  defaultValue=""
                  loading={loadingPaisAquisicaoExportador}
                  disableHelperText
                  disabled
                />
              </Grid>
            )}
            {isNotFabricanteExportador && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <QCXSelectEmpresaEstrangeiraAutocomplete
                  id="select-field-empresa-estrangeira-fabricante"
                  key="select-field-empresa-estrangeira-fabricante"
                  name={computedFabricanteFieldName}
                  label={t('com.muralis.qcx.fabricante.label')}
                  initialValues={values}
                  filterPredicate={fabricanteFilterPredicate}
                  fieldProps={{
                    validate: requiredValidator,
                  }}
                  disabled={isConsult || isAlternativeReasonToDisabled}
                  required
                  {...autoCompleteProps}
                />
              </Grid>
            )}
            {isNotFabricanteExportador && paisOrigemFabricante && (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <QCXReadOnlyTextField
                  key="pais-aquisicao-read-only-input"
                  id="pais-aquisicao-read-only-input"
                  label={t('com.muralis.qcx.pais.paisOrigem')}
                  value={paisOrigemFabricante}
                  defaultValue="####### / ###"
                  loading={loadingPaisOrigemFabricante}
                  disableHelperText
                  disabled
                />
              </Grid>
            )}
            {!isEmptyRelacao && isUnknownFabricante && (
              <Grid item container xs={12} sm={12} md={12} lg={12}>
                <Grid item xs={12} sm={12} md={6} lg={6} />
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <QCXSelectPaisAutocomplete
                    id="select-field-pais-origem"
                    key="select-field-pais-origem"
                    style={{
                      paddingLeft: '8px',
                    }}
                    name={computedPaisOrigemMercadoriaFieldName}
                    label={t('com.muralis.qcx.pais.paisOrigemMercadoria')}
                    disabled={isConsult || !isUnknownFabricante || isAlternativeReasonToDisabled}
                    fieldProps={{
                      validate: isUnknownFabricante && requiredValidator,
                    }}
                    initialValues={values}
                    required={isUnknownFabricante}
                    {...autoCompleteProps}
                  />
                </Grid>
              </Grid>
            )}
          </>
        )}
        { !isEmptyRelacao && (isFabricanteExportador || isNotFabricanteExportador || isUnknownFabricante) && manyFornecedores && isDuimp() && (
          <>  

            <QCXSelectManyExportadores
              title="EXPORTADOR"
              name="exportadores"
              selected={values}
              isConsult={isConsult}
              configureFilterPredicate={exportadorFilterPredicate}
              exportador
            />

            { isNotFabricanteExportador && (
              <QCXSelectManyFabricantes
                title="FABRICANTE"
                name="relacaoFabricantes"
                selected={values}
                isConsult={isConsult}
                configureFilterPredicate={exportadorFilterPredicate}
                exportador
              />
            )}
          </>
        )}
      </Grid>
    </>
  );
}
