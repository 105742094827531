/* eslint-disable no-unused-vars */
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectMoedas } from '../../features/moeda/moedaSlice';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectMoedaAutocomplete({ label, name, initialValues, defaultCode, ...restProps }) {
  const dispatch = useDispatch();
  const list = useSelector(selectMoedas);
  useEffect(() => {
    if (list.length === 0) {
      dispatch(fetchAllAsync());
    }
  }, [dispatch, list]);

  const { initialValues: formInitialValues } = useFormState();
  const localInitialValues = useMemo(() => initialValues || formInitialValues, [initialValues, formInitialValues]);

  const [defaultValue, setDefaultValue] = useState('');
  useEffect(() => {
    const defaultMoeda = list?.find((m) => m.code === defaultCode);
    if (defaultMoeda) {
      setDefaultValue(defaultMoeda);
      return;
    }

    const value = list.find((item) => item.id === Number(_.get(localInitialValues, name)));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, localInitialValues, name, defaultCode]);

  const filterOptions = createFilterOptions({
    stringify: (option) => `${option.code} - ${option.description}`,
  });
  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={list}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => (item?.code && item?.description ? `${item?.code} - ${item?.description}` : '')}
      {...restProps}
    />
  );
}
