import { Link } from '@material-ui/core';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import { Button, Grid } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import QCXInfoAlert from '../../../../../shared-components/alert/QCXInfoAlert';
import { MediaType } from '../../../../../utils/api/api-utils';
import { required } from '../../../../../utils/validators/field/validator';
import FileDragAndDrop from '../../../../common/components/fileDragAndDrop';
import FormHeaderMedium from '../../../../common/components/forms/formHeaderMedium';
import PageLayout from '../../../../common/layouts/pageLayout';
import ProgressBarModal, { ProgressBarStep } from '../components/progressModal';
import { useKeycloak } from '@react-keycloak/web';
import { uploadFile } from '../../../../common/services/empresaEstrangeira';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../../../App';
import { selectUnidadeSelecionada } from '../../../../../features/usuario-logado/usuarioLogadoSlice';
import { setErrorFeedback, setSuccessFeedback } from '../../../../../features/feedback/feedbackSlice';
import { useHistory } from 'react-router';
import { useUnidadeNegocioGuard } from '../../../../common/hooks/useUnidadeNegocioGuard';

interface BasicFormData {
  arquivo: File;
}

const EmpresasEstrangeirasPlanilhaPage = () => {
  useUnidadeNegocioGuard()
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<ProgressBarStep>({
    step: 0,
    description: t('com.muralis.qcx.integracao.Planilhas.empresasEstrangeiras.criandoEmpresasEstrangeiras'),
  });

  const URL_MODELO = useMemo(() => process.env.REACT_APP_MODELOS_RELATORIOS_INTEGRACAO_EMPRESAS_ESTRANGEIRAS_URL, []);

  const { keycloak } = useKeycloak();
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);
  const { token } = keycloak;
  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  const history = useHistory();

  const handleFileSubmit = async (values: BasicFormData) => {
    keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);

    if (token && selectedUnidade) {
      setIsModalOpen(true);

      try {
        const response = await uploadFile(values.arquivo, selectedUnidade, token);

        if (response.status != 200) {
          dispatch(setErrorFeedback({
            message: response.data ? response.data : t('com.muralis.qcx.integracao.Planilhas.erros.erroAoGerarPlanilha'),
          }));

        } else {
          dispatch(setSuccessFeedback({
            message: t('com.muralis.qcx.integracao.Planilhas.sucesso.planilhaGeradaComSuceso')
          }));
        }

      } catch (error) {
        dispatch(setErrorFeedback({
          message: t('translation.com.muralis.qcx.integracao.Planilhas.erros.erroInesperado')
        }));
      }

      setIsModalOpen(false);
      handleNavigation("/integracao/planilhas");
    }
  }

  const handleNavigation = (path: string | undefined) => {
    if (path) {
      history.push(path);
    }
  };

  return (
    <PageLayout title={'Planilhas'} icon={<HistoryEduIcon color={'secondary'} />}>
      <>
        <ProgressBarModal
          title={t('com.muralis.qcx.integracao.Planilhas.sucesso.importandoPlanilha')}
          open={isModalOpen}
          totalSteps={2}
          currentStep={currentStep}
          onClose={() => setIsModalOpen(false)}
        />

        <Form onSubmit={(values: BasicFormData) => handleFileSubmit(values)}>
          {({ handleSubmit }) => {

            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Link href={URL_MODELO} target="_blank">
                      <QCXInfoAlert>{t('com.muralis.qcx.integracao.Planilhas.sucesso.cliqueParaFazerDanwloadingDeUmaModelo')}</QCXInfoAlert>
                    </Link>
                  </Grid>
                  <FormHeaderMedium>{t('com.muralis.qcx.integracao.Planilhas.sucesso.selecaoDeArquivo')}</FormHeaderMedium>
                  <Field name="arquivo" validate={required}>
                    {({ input }) => <FileDragAndDrop input={input} accept={MediaType.EXCEL}></FileDragAndDrop>}
                  </Field>
                  <Grid item xs={12} textAlign="right">
                    <Button type="submit" color="secondary" variant="contained" >
                      Importar planilha
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
      </>
    </PageLayout>
  );
};

export default EmpresasEstrangeirasPlanilhaPage;
