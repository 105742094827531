import { Button, FormControl, Grid, TextField } from "@mui/material";
import { GridActionsCellItem
, GridColDef } from "@mui/x-data-grid";
import { PrimitiveAtom, useAtom } from "jotai";
import { FormEvent, useMemo, useState } from "react";
import DataTable from "../../../../../common/components/dataTable";
import FormHeader from "../../../../../common/components/forms/formHeader";
import { changeBulletProps } from "../../../../../common/layouts/paginationLayout/helpers/paginationLayoutHelpers";
import { PaginationBulletProps } from "../../../../../common/layouts/paginationLayout/paginationLayout";
import {
  PropostaData,
  propostaDataAtom,
  propostasActivePage,
  propostasNewPageBulletProps,
} from "../propostasNewPage";
import { ContactProfile } from "../../../../../common/types/contactProfile";
import CellphoneTextField from "../../../../../common/components/input/cellphoneTextField";
import SelectStyled from "../../../../../common/components/input/selectStyled";
import TelephoneTextField from "../../../../../common/components/input/telephoneTextField";
import { Delete as DeleteIcon } from "@material-ui/icons";

const SecondPage = () => {
  // States
  const [propostaData, setPropostaData] =
    useAtom<PrimitiveAtom<PropostaData>>(propostaDataAtom);
  const [activePage, setActivePage] =
    useAtom<PrimitiveAtom<number>>(propostasActivePage);
  const [bulletProps, setBulletProps] = useAtom<
    PrimitiveAtom<PaginationBulletProps[]>
  >(propostasNewPageBulletProps);
  const [formState, setFormState] = useState<ContactProfile>();

  const rows: {
    id?: number;
    nome?: string;
    telefone?: string;
    celular?: string;
    email?: string;
  }[] = useMemo(() => {
    return (
      propostaData.contatosAdicionais?.map((contato) => {
        return {
          id: contato.id,
          nome: contato.dadosPessoais?.nome,
          telefone: contato.contato?.telefone,
          celular: contato.contato?.celular,
          email: contato.contato?.email,
        };
      }) ?? []
    );
  }, [propostaData.contatosAdicionais]);

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "nome",
        headerName: "Nome",
        flex: 1,
        headerClassName: "header-theme",
      },
      {
        field: "telefone",
        headerName: "Telefone",
        flex: 1,
        headerClassName: "header-theme",
      },
      {
        field: "celular",
        headerName: "Celular",
        flex: 1,
        headerClassName: "header-theme",
      },
      {
        field: "email",
        headerName: "E-mail",
        flex: 1,
        headerClassName: "header-theme",
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Ações",
        headerClassName: "header-theme",
        // @ts-ignore
        getActions: (params: any) => [
          <GridActionsCellItem
placeholder=""
            icon={<DeleteIcon color="error" />}
            label="Excluir"
            onClick={() => handleRemoveContact(params.id)}
          />,
        ],
      },
    ];
  }, []);

  // State setters
  const handleAddContact = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPropostaData((prev) => {
      const newContact = {
        ...formState,
        id: (prev.cliente?.contatos?.length ?? 0) + 1,
      };
      return {
        ...prev,
        contatosAdicionais: [...(prev.contatosAdicionais ?? []), newContact],
      };
    });
    setFormState(undefined);
  };

  const handleRemoveContact = (id: number) => {
    setPropostaData((prev) => {
      return {
        ...prev,
        contatosAdicionais: prev.cliente?.contatos?.filter(
          (contato) => contato.id !== id
        ),
      };
    });
  };

  // Navigation helpers
  const handleNext = () => {
    changeBulletProps(3, setBulletProps);
    setActivePage(3);
  };
  const handlePrevious = () => {
    changeBulletProps(1, setBulletProps);
    setActivePage(1);
  };

  return (
    <div style={{ display: activePage == 2 ? "inherit" : "none" }}>
      <form onSubmit={handleAddContact}>
        <Grid container spacing={2} xs={12} marginTop={2}>
          <Grid item xs={6}>
            <FormControl fullWidth required>
              <TextField
                label="Nome Completo"
                value={formState?.dadosPessoais?.nome ?? ""}
                size="small"
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    dadosPessoais: {
                      ...formState?.dadosPessoais,
                      nome: e.target.value,
                    },
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth required>
              <TextField
                label="Nome Resumido"
                value={formState?.dadosPessoais?.nomeResumido ?? ""}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    dadosPessoais: {
                      ...formState?.dadosPessoais,
                      nomeResumido: e.target.value,
                    },
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <SelectStyled
                options={["Feminino", "Masculino", "Outro"].map(
                  (genero, index) => ({
                    id: index,
                    value: genero,
                  })
                )}
                onChangeAction={(e, value) => {
                  setFormState({
                    ...formState,
                    genero: value?.value,
                  });
                }}
                label={"Gênero"}
              ></SelectStyled>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <TextField
                required
                type={"tel"}
                label="Telefone"
                size="small"
                value={formState?.contato?.telefone ?? ""}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    contato: {
                      ...formState?.contato,
                      telefone: e.target.value,
                    },
                  })
                }
                InputProps={{
                  inputComponent: TelephoneTextField as any,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <TextField
                required
                type={"tel"}
                label="Celular"
                size="small"
                value={formState?.contato?.celular ?? ""}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    contato: {
                      ...formState?.contato,
                      celular: e.target.value,
                    },
                  })
                }
                InputProps={{
                  inputComponent: CellphoneTextField as any,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                required
                type={"email"}
                label="E-mail"
                size="small"
                value={formState?.contato?.email ?? ""}
                onChange={(e) =>
                  setFormState({
                    ...formState,
                    contato: {
                      ...formState?.contato,
                      email: e.target.value,
                    },
                  })
                }
                InputLabelProps={{
                  shrink: true,
                }}
              ></TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} textAlign="right">
            <Button variant="contained" type="submit" color="secondary">
              Adicionar
            </Button>
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={2}>
        <FormHeader>Contatos Adicionados</FormHeader>
        <Grid item xs={12}>
          <DataTable columns={columns} rows={rows}></DataTable>
        </Grid>
        <Grid item xs={12} textAlign="right">
          <Button
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={handlePrevious}
            color="secondary"
          >
            Anterior
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={handleNext}
            color="secondary"
          >
            Próximo
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SecondPage;
