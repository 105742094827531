import CardsGridSubmenu from '../../../common/components/cardsGrid/cardsGridSubmenu';
import BasicLayout from '../../../common/layouts/basicLayout';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Web from '@mui/icons-material/Web';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { CardsGridItem } from '../../../common/components/cardsGrid/cardsGrid';
import React from 'react';

const cover: CardsGridItem = {
  title: 'Roche',
  icon: Web,
  path: '/integracao',
};

const gridItems: CardsGridItem[] = [
  {
    title: 'Escrita',
    icon: DriveFileRenameOutlineIcon,
    path: '/integracao/roche/escrita',
  },
];

const RochePage = () => {
  return (
    <BasicLayout
      title={'Roche'}
      icon={<AssessmentIcon color={'secondary'} />}
    >
      <CardsGridSubmenu cover={cover} items={gridItems}></CardsGridSubmenu>
    </BasicLayout>
  );
};

export default RochePage;