import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectFollowUps } from '../../../../../../features/follow-up/followUpSelectors';
import { fetchPreviewAsync } from '../../../../../../features/follow-up/followUpThunks';
import QCXAutocompleteSelect from '../../../../../../shared-components/autocomplete-select/QCXAutocompleteSelect';

export default function QCXSelectFollowUpImportacaoAutocompleteField({
  label,
  name,
  isCreate,
  disabled,
  ...restProps
}) {
  const dispatch = useDispatch();

  const list = useSelector(selectFollowUps);

  const { initialValues } = useFormState();

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    dispatch(fetchPreviewAsync({ noDadoEmbarqueAssociated: true }));
  }, []);

  // Esse trecho de codigo foi a unica forma que encontrei para exibir o processo salvo na dropdown
  // devido ao filtro aplicado, o processo não aparece como uma opção e por isso não é exibido
  // esse trecho insere "manualmente" o processo na lista a partir dos dados do dado de embarque
  const options = useMemo(() => {
    if (!isCreate && _.get(initialValues, 'followUp') !== undefined) {
      return [...list, _.get(initialValues, 'followUp')];
    }
    return list;
  }, [list, isCreate, initialValues]);

  useEffect(() => {
    const value = options.find((item) => item?.id === _.get(initialValues, name)?.id);

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [options, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (option) => option?.numero || '',
  });

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={options}
      disabled={(isCreate !== undefined && !isCreate) || disabled}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => item?.numero || ''}
      {...restProps}
    />
  );
}
