import axios from "axios";

const downloadFile = async (
  token: string,
  selectedUnidade: string,
  url: string,
  name: string
) => {
  let message = null;

  try {
    const response = await axios.get(url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          quickcomexTenant: selectedUnidade,
        }
      }
    );

    if (response.status === 200) { // Successful response
      const fileContent = response.data.file;
      const reportMessage = response.data.report;

      const file = new File([Uint8Array.from(atob(fileContent), c => c.charCodeAt(0))], name, {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const url = window.URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;
      a.click();

      message = reportMessage;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response && error.response.data) {
        message = error.response.data;
      } else {
        message = "Erro inesperado";
      }
    } else {
      message = "Erro inesperado";
    }
  }
  return message;
}

export { downloadFile };
