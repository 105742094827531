import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  batchTransmiteCatalogosAsync,
  resetMode,
} from '../../../../../../../features/catalogo-produtos/catalogoProdutosSlice';
import { setSuccessFeedback } from '../../../../../../../features/feedback/feedbackSlice';

export default function useTransmitirModal() {
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [catalogoState, setCatalogoState] = useState('ATIVADO');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleCatalogoStateChange = (event: any) => {
    setCatalogoState(event.target.value);
  };

  const handleConfirm = (ids: number[]) => {
    setIsModalOpen(false);

    const payload = {
      ids,
      state: catalogoState,
    };

    dispatch(batchTransmiteCatalogosAsync(payload));

    dispatch(
      setSuccessFeedback({
        message: 'Sucesso ao enviar',
      })
    );

    dispatch(resetMode());
  };

  return {
    isModalOpen,
    openModal,
    closeModal,
    catalogoState,
    handleCatalogoStateChange,
    handleConfirm,
  };
}
