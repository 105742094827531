import { Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QCXFinalDatePickerField from '../../../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXSelectClienteAutocomplete from '../../../../../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXSelectNcmSubItemAutocomplete from '../../../../../../shared-components/select-ncm-subitem/QCXSelectNcmSubItemAutocomplete';
import MultiSelectFormInput from '../../../../../../ts/common/components/forms/MultiSelectFormInput';
import QCXFinalMultilineInputWithChips from '../../../../import/transferencia-lote/TransferenciaLoteManagerPage/QCXFinalMultilineInputWIthChips';
import catalogoProdutosStatusIntegracaoOptions from '../utils/catalogoProdutosStatusIntegracaoOptions';

export default function CatalogoProdutosAdvancedFilterForm() {
  const { t } = useTranslation();

  return (
    <Box style={{ minWidth: '500px' }}>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <QCXFinalDatePickerField
            id="date-picker-field-insertion-date"
            key="date-picker-field-insertion-date"
            label={t('com.muralis.qcx.dateRange0')}
            name="insertionDateRangeStart"
            required={undefined}
            validate={undefined}
          />
        </Grid>

        <Grid item sm={6}>
          <QCXFinalDatePickerField
            id="date-picker-field-insertion-date"
            key="date-picker-field-insertion-date"
            label={t('com.muralis.qcx.dateRange1')}
            name="insertionDateRangeEnd"
            required={undefined}
            validate={undefined}
          />
        </Grid>

        <Grid item sm={12}>
          <QCXSelectClienteAutocomplete
            id="text-field-cliente"
            key="text-field-cliente"
            name="cliente"
            label={t('com.muralis.qcx.cliente.label')}
            required={false}
            filter={undefined}
            initialValues={undefined}
          />
        </Grid>

        <Grid item sm={12}>
          <QCXSelectNcmSubItemAutocomplete
            id="text-field-ncm-id"
            key="text-field-ncm-id"
            name="ncm.id"
            label={t('com.muralis.qcx.NCM.label')}
            fieldProps={undefined}
            disabled={false}
            required={false}
          />
        </Grid>

        <Grid item sm={12}>
          <MultiSelectFormInput name="statusFilters" options={catalogoProdutosStatusIntegracaoOptions} label="Status" />
        </Grid>

        <Grid item sm={12}>
          <QCXFinalMultilineInputWithChips
            name="tags"
            label={t('com.muralis.qcx.descriptionTags')}
            separateByEnterKey
          />
        </Grid>

        <Grid item sm={12}>
          <QCXFinalMultilineInputWithChips name="partnumbersTags" label={t('com.muralis.qcx.partnumberTags')} />
        </Grid>
      </Grid>
    </Box>
  );
}
