import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import debounce from 'lodash/debounce';
import QCXEstadoForm from '../../../../../components/estado/QCXEstadoForm';
import { register, save, checkForDuplicates } from '../../../../../features/estado/estadoAPI';
import {
    addToList,
    changeToConsultMode,
    changeToCreateMode,
    changeToUpdateMode,
    fetchByIdAsync,
    resetModel,
    setModel,
    success,
    failure,
    selectEstado,
    selectMode, 
    loading,
    setError,
    updateOnList,
    setResponse,
} from '../../../../../features/estado/estadoSlice';
import {
    isConsultMode,
    isCreateMode,
    isUpdateMode,
} from '../../../../../utils/store/store-utils';
import { setSuccessFeedback, setErrorFeedback } from '../../../../../features/feedback/feedbackSlice';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';

export default function EstadoRegistrationPage({ authInfo = {} }) {
    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const estado = useSelector(selectEstado);
    const mode = useSelector(selectMode);

    const isCreate = useMemo(() => isCreateMode(mode), [mode]);
    const isConsult = useMemo(() => isConsultMode(mode), [mode]);
    const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

    useEffect(() => {
        if (id) {
            dispatch(fetchByIdAsync(id));
            dispatch(changeToConsultMode());
        }
    }, [id, dispatch]);

    const handleChangeToCreate = () => {
        dispatch(changeToCreateMode());
    };

    const handleChangeToConsult = () => {
        dispatch(changeToConsultMode());
    };

    const handleChangeToUpdate = () => {
        dispatch(changeToUpdateMode());
    };

    const handleCancelUpdate = () => {
        const currentId = estado?.id || id;

        if (currentId) {
            dispatch(fetchByIdAsync(currentId));
        }
        handleChangeToConsult();
    };

    const create = async (data) => {
        const executeDebounced = debounce(async () => {
            try {
                const response = await register(data);

                if (response.status === 201) {
                    dispatch(resetModel());
                    dispatch(setSuccessFeedback({ message: 'Estado criado com sucesso!' }));

                    const created = response.data;

                    dispatch(addToList({ data: created }));
                    dispatch(success());
                    dispatch(
                        setResponse({
                            status: response.status,
                            data: created,
                            message: 'Estado Cadastrado com Sucesso',
                        })
                    );
                    history.push(t('com.muralis.qcx.url.moduloOperacionaisEstado'));
                }
            } catch (error) {
                dispatch(setErrorFeedback({ message: 'Erro ao cadastrar Estado' }));
                dispatch(failure());
                dispatch(
                    setError({
                        message: 'Erro ao Cadastrar estado',
                    })
                );
            }
        }, 500);

        dispatch(loading());
        executeDebounced();
    };

    const update = async (data) => {
        const executeDebounced = debounce(async () => {
            try {
                const response = await save(data);

                if (response.status === 200) {
                    const saved = response.data;
                    dispatch(setSuccessFeedback({ message: 'Estado Atualizado com sucesso!' }));

                    dispatch(success());
                    dispatch(setModel(saved));
                    dispatch(updateOnList({ data: saved }));
                    dispatch(fetchByIdAsync(saved.id));
                    dispatch(
                        setResponse({
                            status: response.status,
                            data: saved,
                            message: 'Estado Atualizado com Sucesso',
                        })
                    );
                    handleChangeToConsult(); // Switch to consult mode after update
                }
            } catch (error) {
                dispatch(setErrorFeedback({ message: 'Erro ao Atualizar Estado, verifique o código do estado.' }));
                dispatch(failure());
                dispatch(
                    setError({
                        message: 'Erro ao Atualizar estado',
                    })
                );
            }
        }, 500);

        dispatch(loading());
        executeDebounced();
    };

    const handleSubmit = async (data) => {
        const brasil = (data?.pais?.id === 1)
        if (brasil) {
            dispatch(setErrorFeedback({ message: 'Não é permitido selecionar BRASIL como País para o Estado.' }));
            return;
        } 
        if (!brasil){         
            if (isUpdate) {
                await update(data);
            } else {
                try {
                    const duplicateCheckResponse = await checkForDuplicates(data);
                    const isDuplicate = duplicateCheckResponse?.data?.isDuplicate; 
                    if (isDuplicate) {
                        dispatch(setErrorFeedback({ message: 'Já existe um Estado com este código. Por favor, verifique.' }));
                        return; 
                    }
                    await create(data);
                } catch (error) {
                    dispatch(setErrorFeedback({ message: 'Erro ao verificar duplicatas. Por favor, tente novamente.' }));
                }
            }
        }
    
    };

    const model = useMemo(() => (isCreate ? {} : estado), [isCreate, estado]);

    const breadcrumbs = useMemo(() => [
        { link: { to: '/', name: t('com.muralis.qcx.inicio') } },
        { text: { name: t('com.muralis.qcx.cadastros') } },
        { link: { to: t('com.muralis.qcx.url.moduloOperacionais'), name: t('com.muralis.qcx.operacionais') } },
        { link: { to: t('com.muralis.qcx.url.moduloOperacionaisEstado'), name: t('com.muralis.qcx.estado.labelPlural') } },
        { text: { name: isCreate ? t('com.muralis.qcx.acoes.novo') : t('com.muralis.qcx.acoes.visualizar') } },
    ], [isCreate, t]);

    const pageTitle = useMemo(() =>
        isCreate
            ? 'Novo estado'
            : 'Editar estado',
        [isCreate, t]
    );

    return (
        <QCXRegistrationFormPageTemplate
            pageTitle={pageTitle}
            breadcrumbs={breadcrumbs}
            isCreate={isCreate}
            isConsult={isConsult}
            isUpdate={isUpdate}
            handleChangeToCreate={handleChangeToCreate}
            handleChangeToConsult={handleChangeToConsult}
            handleChangeToUpdate={handleChangeToUpdate}
            handleCancelUpdate={handleCancelUpdate}
            authInfo={authInfo}
        >
            {(formProps) => (
                <QCXEstadoForm
                    initialValues={model}
                    handleSubmit={handleSubmit}
                    isConsult={isConsult}
                    isUpdate={isUpdate}
                    authInfo={authInfo}
                    {...formProps}
                />
            )}
        </QCXRegistrationFormPageTemplate>
    );
}
