import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import {
  Search as PageviewIcon,
  Delete as DeleteIcon,
  Explore as ExploreIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import SimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/SimpleConsultPageTemplate/SimpleConsultPageTemplate';
import {
    activateByIdAsync,
    inactivateByIdAsync,
    fetchPaginateAsync,
    selectCidades,
    selectCidadeCount,
    selectStatus,
    resetMode,
    resetModel,
  } from '../../../../../features/cidade/cidadeSlice';
import { ALTERNATIVE_LOADING_STATUS } from '../../../../../features/status';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';

export default function CidadeConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useDispatch();
  const cidades = useSelector(selectCidades);
  const rowsCount = useSelector(selectCidadeCount);
  const status = useSelector(selectStatus);
  const isAlternativeLoading = useMemo(() => status === ALTERNATIVE_LOADING_STATUS, [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchPaginateAsync([
      { name: 'page', value: 1 },
      { name: 'size', value: 10 }
    ]));
  }, [dispatch]);

  useEffect(() => {
    fetchAll();
    dispatch(resetMode());
    dispatch(resetModel());
  }, [fetchAll, dispatch]);

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      flex: 110,
      valueGetter: ({ row }) => row?.code,
    },
    {
      field: 'descricao',
      headerName: t('com.muralis.qcx.descricao'),
      type: 'string',
      flex: 275,
      valueGetter: ({ row }) => row?.description,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      type: 'string',
      flex: 165,
      valueGetter: ({ row }) => row?.estado.description,
    },
    {
        field: 'pais',
        headerName: 'País',
        type: 'string',
        flex: 165,
        valueGetter: ({ row }) => row?.estado.pais.description,
      },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'cidade-cadastro') && (
            <IconButton
              key={`stw-active${id}`}
              value={active}
              name={`stw-active-${id}`}
              onClick={(event) => handleActivateInactivate(event, id)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const handleActivateInactivate = useCallback((event, id) => {
    event.preventDefault();

    const selectedCidade = cidades.find((cidade) => cidade.id === id);
    const isActivation = !selectedCidade.active;

    if (isActivation) {
      dispatch(activateByIdAsync(id)).then(() => {
        fetchAll();
      });
    } else {
      dispatch(inactivateByIdAsync(id)).then(() => {
        fetchAll();
      });
    }
  }, [dispatch, cidades, fetchAll]);

  const handleClickOnEditButton = useCallback(
    (event, id) => {
      history.push(t('com.muralis.qcx.url.moduloOperacionaisCidadeDetalhes', { id }));
    },
    [history, t]
  );

  const handleConfigChange = useCallback(({ currentPage, pageSize, filterInputValue }) => {
    dispatch(
      fetchPaginateAsync([
        { name: 'page', value: currentPage },
        { name: 'size', value: pageSize },
        { name: 'descricao', value: filterInputValue },
      ])
    );
  }, [dispatch]);

  return (
    <SimpleConsultPageTemplate
      pageProps={{
        title: t('com.muralis.qcx.endereco.cidade'),
        icon: <ExploreIcon />,
        breadcrumbs: [
          { link: { to: '/', name: t('com.muralis.qcx.inicio') } },
          { text: { name: t('com.muralis.qcx.cadastros') } },
          { link: { to: t('com.muralis.qcx.url.moduloOperacionais'), name: t('com.muralis.qcx.operacionais') } },
          { text: { name: t('com.muralis.qcx.endereco.cidade') } },
        ],
      }}
      controlProps={{
        buttonNew: { description: 'Nova Cidade', path: t('com.muralis.qcx.url.moduloOperacionaisCidadeNovo') },
      }}
      tableProps={{ columns, rowsCount, onConfigChangeCallback: handleConfigChange }}
      tableData={cidades}
      isLoading={isAlternativeLoading}
      filterPropGetter={(cidade) => cidade.descricao}
      buttonNewText={t('com.muralis.qcx.acoes.novo').toUpperCase()}
      authInfo={authInfo}
      requiredRoles={['cidade-cadastro']}
    />
  );
}
