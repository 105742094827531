import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAsync, selectConfigControl, selectNves } from '../../features/nve/nveSlice';
import { isAutoLoadControl } from '../../utils/store/store-utils';
import QCXAutocompleteSelect from '../autocomplete-select/QCXAutocompleteSelect';
import { configureNveNivelWith } from './nveUtils';
import { fetchByFilterAsync as fetchNcmByFilterAsync } from '../../features/ncm-subitem/ncmSubitemSlice';

export default function QCXSelectNveAutocomplete({
  label,
  name,
  initialValues,
  ...restProps
}) {
  const dispatch = useDispatch();

  // Adiciona um refetch do NCM para que o NVE seja carregado
  useEffect(() => {
    const validNcmCodeLength = 8;
    if (initialValues?.ncm && initialValues?.ncm?.code?.length === validNcmCodeLength) {
      dispatch(fetchNcmByFilterAsync([{ name: 'code', value: initialValues.ncm.code }]));
    }
  }, [initialValues]);

  const list = useSelector(selectNves);
  const nves = useMemo(() => (
    configureNveNivelWith(list)
  ), [list]);

  const configControl = useSelector(selectConfigControl);

  useEffect(() => {
    if (isAutoLoadControl(configControl)) {
      dispatch(fetchAllAsync());
    }
  }, [configControl]);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    const value = nves.find((item) => item?.id === _.get(initialValues, name));
    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [list, initialValues]);

  const filterOptions = createFilterOptions({
    stringify: (item) => `${item?.code} - ${item?.description}`,
  });
  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={nves}
      filterOptions={filterOptions}
      defaultValue={defaultValue}
      getOptionLabel={(item) => item?.nivel}
      {...restProps}
    />
  );
}
