import React, { useState } from 'react';
import { Checkbox, Box, Typography, FormControlLabel } from '@mui/material';

interface Column {
  name: string;
  checked: boolean;
}

interface ColumnSelectorProps {
  columns: Column[];
  onChange: (updatedColumns: Column[]) => void;
}

const ColumnSelector: React.FC<ColumnSelectorProps> = ({ columns, onChange }) => {
  const [localColumns, setLocalColumns] = useState(columns);

  const handleCheckboxChange = (index: number) => {
    const newColumns = [...localColumns];
    newColumns[index].checked = !newColumns[index].checked;
    setLocalColumns(newColumns);
    onChange(newColumns);
  };

  const handleToggleAll = () => {
    const allChecked = localColumns.every(column => column.checked);
    const newColumns = localColumns.map((column) => ({
      ...column,
      checked: !allChecked,
    }));
    setLocalColumns(newColumns);
    onChange(newColumns);
  };

  return (
    <Box mt={2} p={2} border={1} borderRadius={4} borderColor="grey.300">
      <Typography variant="h6">Colunas de Processos</Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={localColumns.every(column => column.checked)}
            indeterminate={
              !localColumns.every(column => column.checked) &&
              localColumns.some(column => column.checked)
            }
            onChange={handleToggleAll}
          />
        }
        label={localColumns.every(column => column.checked) ? 'Desligar Todas' : 'Ligar Todas'}
      />
      <Box mt={2}>
        {localColumns.map((column, index) => (
          <Box key={column.name} display="flex" alignItems="center" mb={1}>
            <Checkbox
              checked={column.checked}
              onChange={() => handleCheckboxChange(index)}
            />
            <Typography>{column.name}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ColumnSelector;
