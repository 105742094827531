import React, { useEffect, useMemo, useState } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createFilterOptions } from '@material-ui/lab/useAutocomplete';
import { OnChange } from 'react-final-form-listeners';
import _ from 'lodash';

import QCXAutocompleteSelect from '../../autocomplete-select/QCXAutocompleteSelect'; // texto


export default function QCXPalavraChaveListaField({
    palavraChave,
    disabled,
    ...restProps
}) {
    const basePath = `ignorableFields.documentoAnexacao.palavrasChave`
    const [listValores, setListValores] = useState([]);
    const [defaultValue, setDefaultValue] = useState('');

    useEffect(() => {
        
      if(palavraChave && palavraChave.dominios && _.isArray(palavraChave.dominios)) {
          setListValores([...palavraChave.dominios].map(el => ({
              id: el.codigo,
              value: `${el.codigo} - ${el.descricao}`,
          })))
          setDefaultValue({ id: null, value: '' });
      }

      return null;
  }, [])

    const filterOptions = createFilterOptions({
      stringify: (item) => `${item.codigo} - ${item.value}`,
    });

    return (
        <>
            <QCXAutocompleteSelect
                id={`cod_${palavraChave.idPalavraChave}`}
                key={`cod_${palavraChave.idPalavraChave}`}
                name={`${basePath}.cod_${palavraChave.idPalavraChave}`}
                label={palavraChave.nomePalavraChave}
                disabled={disabled}
                maxLength={palavraChave.tamanhoCampo}
                required={palavraChave.obrigatoria}
                options={listValores}
                filterOptions={filterOptions}
                defaultValue={defaultValue}
                getOptionLabel={(item) => (
                    item.value ? item.value : ''
                )}
                {...restProps}
            />

            <OnChange name={`${basePath}.cod_${palavraChave.idPalavraChave}`}>
                {(value, previous) => console.log(value)}
            </OnChange>
        </>

    );
}
