import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectFollowUps } from '../../../../../../features/follow-up/followUpSelectors';
import { fetchAllAsync } from '../../../../../../features/follow-up/followUpThunks';
import QCXAutocompleteSelect from '../../../../../../shared-components/autocomplete-select/QCXAutocompleteSelect';
import { selectDeclaracoesImportacao } from '../../../../../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import { setModel } from '../../../../../../features/declaracao-importacao/declaracaoImportacaoSlice';
import { fetchAllFromFaturaAsync as fetchAllDIs } from '../../../../../../features/declaracao-importacao/declaracaoImportacaoThunks';

export default function QCXSelectFollowUpImportacaoAutocompleteField({
  label,
  name,
  isCreate,
  disabled,
  ...restProps
}) {
  const dispatch = useDispatch();

  const fups = useSelector(selectFollowUps);
  const dis = useSelector(selectDeclaracoesImportacao);

  const { initialValues } = useFormState();

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    dispatch(fetchAllAsync());
    dispatch(fetchAllDIs());
  }, []);

  const options = useMemo(() => {
    const filteredDis = dis.filter(
      (di) =>
        di?.calculada === 'CALCULADA' &&
        di?.atributosAdicionais?.conferida === 'CONFERIDA' &&
        di?.atributosAdicionais?.liberada === 'LIBERADA' &&
        di?.atributosAdicionais?.registro
    );
    const followUps = filteredDis?.map((di) => {
      const followUp = fups.find((fu) => fu?.numero === di.atributosAdicionais.processo);
      return followUp ? { id: followUp.id, followUp, di } : [];
    });
    const flattenedFollowUps = followUps.flat();
    return flattenedFollowUps;
  }, [fups, dis, isCreate]);

  useEffect(() => {
    const value = options.find((item) => item?.id === _.get(initialValues, name));

    if (value) {
      setDefaultValue(value);
    } else {
      setDefaultValue({ id: null, description: '' });
    }
  }, [options, initialValues]);

  return (
    <QCXAutocompleteSelect
      label={label}
      id={name}
      name={name}
      options={options}
      disabled={(isCreate !== undefined && !isCreate) || disabled}
      defaultValue={defaultValue}
      getOptionLabel={(item) => item.followUp?.numero || ''}
      onChange={(event, value) => {
        dispatch(setModel(value?.di));
      }}
      {...restProps}
    />
  );
}
