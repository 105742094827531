/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import { isArray } from 'lodash';
import { CatalogoProdutos } from 'quickcomex-api-types';
import { v4 as uuid } from 'uuid';
import i18n from '../../i18n';
import { isSubCreateMode } from '../../utils/store/store-utils';
import { IDLE_CONTROL } from '../config-control';
import {
  BACKGROUND_CREATE_MODE,
  CONSULT_MODE,
  CREATE_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  NONE_SUB_MODE,
  SUB_CONSULT_MODE,
  SUB_CREATE_MODE,
  SUB_UPDATE_MODE,
  UPDATE_MODE,
  TRANSFER_MODE,
} from '../mode';
import { ALTERNATIVE_LOADING_STATUS, FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS } from '../status';
import {
  activateById,
  fetchAll,
  fetchByFilter,
  fetchByProdutosPortalUnico,
  fetchById,
  fetchCatalogoByFilter,
  inactivateById,
  batchTransmiteCatalogos,
  generateExcelReport,
} from './catalogoProdutosAPI';

interface CatalogoProdutosInitialState {
  status: string;
  mode: {
    main: string;
    background: string;
    sub: string;
  };
  error: any;
  response: {
    status?: number | null;
    message?: string | null;
    data: any;
  };
  config: {
    control: string;
  };
  list: any[];
  count: number;
  model: any;
  related: {
    model: {
      especificacao: any;
    };
    list: {
      selectionCatalogos: any[];
    };
  };
  subMode?: string;
}

const initialState: CatalogoProdutosInitialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
  },
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {
    control: IDLE_CONTROL,
  },
  list: [],
  count: 0,
  model: {
    id: '',
    insertionDate: '',
    code: '',
    description: '',
    active: null,
    partnumber: '',
    apelido: '',
    especificacao: '',
    icms: '',
    exTarifario: null,
    modelo: '',
    dataValidade: '',
    anoFabricacao: '',
    lote: '',
    valorUnitarioMoeda: '',
    pesoLiquido: '',
    relacao: '',
    exportador: { id: '' },
    fabricante: { id: '' },
    paisOrigem: { id: '' },
    unidadeDeMedida: { id: '' },
    operacaoFiscal: { id: '' },
    tipoCalculo: '',
    ncm: { id: '' },
    naladiNcca: { id: '' },
    naladiSh: { id: '' },
    clientes: [],
    especificacoes: [],
  },
  related: {
    model: {
      especificacao: {},
    },
    list: {
      selectionCatalogos: [],
    },
  },
};

const fetchAllAsync = createAsyncThunk('catalogoProdutos/fetchAll', async () => {
  const { data } = await fetchAll();
  return { data };
});

const fetchCatalogoProdutosByFilterAsync = createAsyncThunk(
  'catalogoProdutos/fetchByCatalogoProdutosFilter',
  async (params: { name: string; value: any }[]) => {
    const { data }: any = await fetchCatalogoByFilter(params);
    return { data };
  }
);

const batchTransmiteCatalogosAsync = createAsyncThunk(
  'catalogoProdutos/batchTransmiteCatalogos',
  async ({ ids, state }: { ids: number[]; state: string }) => {
    const { data }: any = await batchTransmiteCatalogos({ ids, state });
    return { data };
  }
);

const fetchAllAsyncAndProcess = createAsyncThunk(
  'catalogoProdutos/fetchAllAndProcess',
  async ({ exclude }: { exclude: any[] }) => {
    const { data } = await fetchAll();

    if (isArray(exclude)) {
      const processed = data.filter((model: any) => exclude.indexOf(model?.id) === -1);

      return {
        data: processed,
      };
    }

    return { data };
  }
);

const fetchByIdAsync = createAsyncThunk('catalogoProdutos/fetchById', async (data) => {
  const response = await fetchById(data);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const activateByIdAsync = createAsyncThunk('catalogoProdutos/activateById', async (id) => {
  const { status, data } = await activateById(id);
  return { response: { status, data } };
});

const fetchByFilterAsync = createAsyncThunk(
  'catalogoProdutos/fetchByFilter',
  async (params: { name: string; value: any }[]) => {
    const { data } = await fetchByFilter(params);
    return { data };
  }
);

const generateExcelAsync = createAsyncThunk(
  'catalogoProdutos/generateExcel',
  async (params: { name: string; value: any }[]) => {
    const response = await generateExcelReport(params);

    if (response.status === 200) {
      const blob = new Blob([response.data], { type: 'application/xml' });
      saveAs(blob, 'catalogo_produtos.xlsx');
    }

    return response.data;
  }
);

const fetchByProdutosPortalUnicoAsync = createAsyncThunk(
  'catalogoProdutos/fetchByProdutosPortalUnico', async (params: { name: string; value: any }[]) => {
    const { data } = await fetchByProdutosPortalUnico(params);
    return { data };
  }
);

const inactivateByIdAsync = createAsyncThunk('catalogoProdutos/inactivateById', async (id) => {
  const { status, data } = await inactivateById(id);
  return { response: { status, data } };
});

const catalogoProdutosSlice = createSlice({
  name: 'catalogoProdutos',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
      state.related.model.especificacao = {};
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setRelatedEspecificacaoModel: (state, action) => {
      state.related.model.especificacao = {
        ...action.payload,
      };
      state.mode.sub = SUB_CONSULT_MODE;
    },
    addEspecificacao: (state, action) => {
      state.model.especificacoes = [
        ...state.model.especificacoes,
        {
          ...action.payload.data,
          id: uuid(),
        },
      ];
      state.related.model.especificacao = {};
      state.mode.sub = NONE_SUB_MODE;
    },
    updateEspecificacao: (state, action) => {
      const updated = action.payload.data;
      state.model.especificacoes = state.model.especificacoes.map((current: any) =>
        current?.id === updated?.id ? updated : current
      );
      state.mode.sub = SUB_CONSULT_MODE;
    },
    removeEspecificacaoById: (state, action) => {
      const { id } = action.payload.data;
      state.model.especificacoes = state.model.especificacoes.filter((current: any) => current?.id !== id);
      state.related.model.especificacao = initialState.related.model.especificacao;
      state.mode.sub = NONE_SUB_MODE;
    },
    resetEspecificacoes: (state, action) => {
      state.model.especificacoes = action.payload.data;
      state.related.model.especificacao = initialState.related.model.especificacao;
      state.mode.sub = NONE_SUB_MODE;
    },
    resetRelatedEspecificacaoModel: (state) => {
      state.related.model.especificacao = {};

      if (!isSubCreateMode(state.subMode)) {
        state.mode.sub = NONE_SUB_MODE;
      }
    },
    setClientes: (state, action) => {
      state.model.clientes = [...action.payload];
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    resetCatalogoProdutos: (state) => {
      state.list = [];
      state.count = 0;
    },
    changeToTransferMode: (state) => {
      state.mode.main = TRANSFER_MODE;
    },
    setRelatedSelectionCatalogoList: (state, action) => {
      state.related.list.selectionCatalogos = action.payload;
    },
    resetRelatedSelectionCatalogoList: (state, action) => {
      state.related.list.selectionCatalogos = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaCatalogoProdutosMensagem', {
            erro: action.error.message,
          }),
        };
      })
      .addCase(fetchAllAsyncAndProcess.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsyncAndProcess.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsyncAndProcess.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaCatalogoProdutosMensagem', {
            erro: action.error.message,
          }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByFilterAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(generateExcelAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = {
            ...action.payload.response.data,
          };
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByFilterAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(generateExcelAsync.fulfilled, (state) => {
        state.status = IDLE_STATUS;
      })
      .addCase(fetchByFilterAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarCatalogoProdutos', { erro: action.error.message }),
        };
      })
      .addCase(generateExcelAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarCatalogoProdutos', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarCatalogoProdutos', { erro: action.error.message }),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { descricaoResumida } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.catalogoProdutosAtivado', {
          catalogo: descricaoResumida,
        });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroAtivarCatalogoProdutos', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { descricaoResumida } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.catalogoProdutosInativado', {
          catalogo: descricaoResumida,
        });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroInativarCatalogoProdutos', { erro: action.error.message }),
        };
      })
      .addCase(fetchCatalogoProdutosByFilterAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchCatalogoProdutosByFilterAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.list = action?.payload?.data?.itens;
        state.count = action?.payload?.data?.totalNumberOfItens;
      })
      .addCase(fetchCatalogoProdutosByFilterAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarCatalogoProdutos', { erro: action.error.message }),
        };
      })
      .addCase(batchTransmiteCatalogosAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(batchTransmiteCatalogosAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
      })
      .addCase(batchTransmiteCatalogosAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarCatalogoProdutos', { erro: action.error.message }),
        };
      })
      .addCase(fetchByProdutosPortalUnicoAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByProdutosPortalUnicoAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        if (action.payload?.data && Array.isArray(action.payload?.data)) {
          state.list = action.payload?.data;
        } else {
          state.list = [];
        }
      })
      .addCase(fetchByProdutosPortalUnicoAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarCatalogoProdutos', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  resetStatus,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  changeToSubCreateMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  resetSubMode,
  changeControlTo,
  setModel,
  resetModel,
  resetCatalogoProdutos,
  addToList,
  updateOnList,
  setRelatedEspecificacaoModel,
  addEspecificacao,
  updateEspecificacao,
  removeEspecificacaoById,
  resetRelatedEspecificacaoModel,
  setClientes,
  setResponse,
  clearResponse,
  setError,
  clearError,
  resetEspecificacoes,
  changeToTransferMode,
  setRelatedSelectionCatalogoList,
  resetRelatedSelectionCatalogoList,
} = catalogoProdutosSlice.actions;

const selectCatalogosProdutos = (state: any) => state.catalogoProdutos.list as CatalogoProdutos[];
const selectCatalogosProdutosCount = (state: any) => state.catalogoProdutos.count;
const selectStatus = (state: any) => state.catalogoProdutos.status;
const selectMode = (state: any) => state.catalogoProdutos.mode.main;
const selectBackgroundMode = (state: any) => state.catalogoProdutos.mode.background;
const selectSubMode = (state: any) => state.catalogoProdutos.mode.sub;
const selectConfigControl = (state: any) => state.catalogoProdutos.config.control;
const selectError = (state: any) => state.catalogoProdutos.error;
const selectResponse = (state: any) => state.catalogoProdutos.response;
const selectCatalogoProdutos = (state: any) => state.catalogoProdutos.model as CatalogoProdutos;

const selectRelatedEspecificacaoModel = (state: any) => state.catalogoProdutos.related.model.especificacao;
const selectRelatedSelectionCatalogoList = (state: any) => state.catalogoProdutos.related.list.selectionCatalogos;

const catalogoProdutosActions = {
  ...catalogoProdutosSlice.actions,
  selectCatalogosProdutos,
  selectStatus,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectError,
  selectResponse,
  selectCatalogoProdutos,
  selectCatalogosProdutosCount,
  selectRelatedEspecificacaoModel,
  selectRelatedSelectionCatalogoList,
};

export {
  activateByIdAsync,
  addEspecificacao,
  addToList,
  catalogoProdutosActions,
  catalogoProdutosSlice,
  changeControlTo,
  changeStatusTo,
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToSubConsultMode,
  changeToSubCreateMode,
  changeToSubUpdateMode,
  changeToUpdateMode,
  clearError,
  clearResponse,
  failure,
  fetchAllAsync,
  fetchAllAsyncAndProcess,
  fetchByFilterAsync,
  generateExcelAsync,
  fetchByProdutosPortalUnicoAsync,
  fetchByIdAsync,
  inactivateByIdAsync,
  loading,
  removeEspecificacaoById,
  resetBackgroundMode,
  resetEspecificacoes,
  resetMode,
  resetModel,
  resetCatalogoProdutos,
  resetRelatedEspecificacaoModel,
  resetStatus,
  resetSubMode,
  selectBackgroundMode,
  selectCatalogoProdutos,
  selectCatalogosProdutos,
  selectConfigControl,
  selectError,
  selectMode,
  selectRelatedEspecificacaoModel,
  selectResponse,
  selectStatus,
  selectSubMode,
  setClientes,
  setError,
  setModel,
  setRelatedEspecificacaoModel,
  setResponse,
  success,
  updateEspecificacao,
  updateOnList,
  fetchCatalogoProdutosByFilterAsync,
  selectCatalogosProdutosCount,
  changeToTransferMode,
  setRelatedSelectionCatalogoList,
  selectRelatedSelectionCatalogoList,
  resetRelatedSelectionCatalogoList,
  batchTransmiteCatalogosAsync,
};

export default catalogoProdutosSlice.reducer;
