import { IconButton } from '@material-ui/core';
import { Delete as DeleteIcon, Search as PageviewIcon } from '@material-ui/icons';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ModeloInformacoesComplementaresContext from '../../../../../contexts/registrations/modelo-informacoes-complementares/ModeloInformacoesComplementaresContext';
import {
  activateByIdAsync,
  fetchAllAsync,
  inactivateByIdAsync,
  resetMode,
  resetModel,
  selectModelosInformacoesComplementares,
} from '../../../../../features/modelo-informacoes-complementares/modeloInformacoesComplementaresSlice';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import useOperationController from '../../../../../utils/hooks/store/operation/useOperationController';
import { isAlternativeLoadingStatus } from '../../../../../utils/store/store-utils';
import { TipoProdutoUtils } from '../../../../../shared-components/select-tipo-produto/tipoProdutoUtils';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';

export default function ModeloInformacoesComplementaresConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const { status } = useContext(ModeloInformacoesComplementaresContext);

  const dispatch = useDispatch();

  const modelosInformacoesComplementares = useSelector(selectModelosInformacoesComplementares);

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const fetchAll = useCallback(() => {
    dispatch(fetchAllAsync());
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
  }, []);

  const handleActivateInactivate = useOperationController(async (event, id) => {
    event.preventDefault();

    const { active } = modelosInformacoesComplementares.find(
      (modeloInformacoesComplementares) => modeloInformacoesComplementares.id === id
    );

    const isActivation = !active;

    if (isActivation) {
      dispatch(activateByIdAsync(id));
    } else {
      dispatch(inactivateByIdAsync(id));
    }
  });

  const handleClickOnEditButton = useCallback(
    (event, id) => {
      event.stopPropagation();
      history.push(t('com.muralis.qcx.url.moduloOperacionaisModelosInformacoesComplementaresDetalhes', { id }));
    },
    [history]
  );

  const columns = [
    {
      field: 'id',
      headerAlign: 'center',
      align: 'center',
      headerName: t('com.muralis.qcx.codigo'),
      type: 'string',
      flex: 90,
      valueGetter: ({ row }) => row?.id,
    },
    {
      field: 'description',
      headerName: t('com.muralis.qcx.nome'),
      type: 'string',
      flex: 195,
      valueGetter: ({ row }) => row?.description,
    },
    {
      field: 'uso',
      headerName: t('com.muralis.qcx.uso'),
      type: 'string',
      flex: 130,
      valueGetter: ({ row }) => t(TipoProdutoUtils.getLabelByValue(row?.uso)),
    },
    {
      field: 'complemento',
      headerName: t('com.muralis.qcx.endereco.complemento'),
      type: 'string',
      flex: 190,
      valueGetter: ({ row }) => row?.complemento || '-',
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 120,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'modelo-informacoes-complementares') && (
            <IconButton
              key={`stw-active${id}`}
              value={active}
              name={`stw-active-${id}`}
              onClick={(event) => handleActivateInactivate(event, id)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.modeloInformacaoComplementar.labelPlural'),
      },
    },
  ];

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.modeloInformacaoComplementar.labelPlural'),
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.modeloInformacaoComplementar.novoModeloInformacoesComplementares'),
        path: t('com.muralis.qcx.url.moduloOperacionaisModelosInformacoesComplementaresNovo'),
      },
    },
    table: {
      columns,
    },
  };

  return (
    <QCXSimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={modelosInformacoesComplementares}
      isLoading={isAlternativeLoading}
      buttonNewText={t('com.muralis.qcx.acoes.novo').toUpperCase()}
      multipleFilter
      authInfo={authInfo}
      requiredRoles={['modelo-informacoes-complementares']}
    />
  );
}
