import ExtensionIcon from '@mui/icons-material/Extension';
import CardsGridSubmenu from '../../../common/components/cardsGrid/cardsGridSubmenu';
import BasicLayout from '../../../common/layouts/basicLayout';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { CardsGridItem } from '../../../common/components/cardsGrid/cardsGrid';
import Web from '@mui/icons-material/Web';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

const cover: CardsGridItem = {
  title: 'Thermo / Fisher',
  icon: Web,
  path: '/integracao',
};

const gridItems: CardsGridItem[] = [
  {
    title: 'Leitura',
    icon: ReadMoreIcon,
    path: '/integracao/thermo-fisher/leitura',
    requiredRoles: ['thermo-fisher-visualizar'],
  },
  {
    title: 'Escrita',
    icon: DriveFileRenameOutlineIcon,
    path: '/integracao/thermo-fisher/escrita',
    requiredRoles: ['thermo-fisher-visualizar'],
  },
  {
    title: 'Correspondência de Dados',
    icon: ImportExportIcon,
    path: '/integracao/totvs-easy/correspondencia',
  },
];

const ThermoFisherPage = () => {
  return (
    <BasicLayout title={'Thermo / Fisher'} icon={<ExtensionIcon color={'secondary'} />}>
      <CardsGridSubmenu cover={cover} items={gridItems}></CardsGridSubmenu>
    </BasicLayout>
  );
};

export default ThermoFisherPage;
