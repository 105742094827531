import { validateQueryParams } from '../../utils/api/api-utils';
import { doGetQCXRequest } from '../../utils/api/domain/domain-api-utils';

async function fetchAllByNcmCodes(params = []) {
  const validatedQueryParams = validateQueryParams(params);

  return doGetQCXRequest(['aduaneiras-ncm-tec/filter'], validatedQueryParams);
}

const aduaneirasNcmTecAPI = {
  fetchAllByNcmCodes,
};

export { aduaneirasNcmTecAPI, fetchAllByNcmCodes };
