/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { Grid, makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QCXFormSubtitle from '../../components/form-title/QCXFormSubtitle';
import { selectRegimesTributacao } from '../../features/regime-tributacao/regimeTributacaoSlice';
import { selectTiposDeclaracao } from '../../features/tipo-declaracao/tipoDeclaracaoSlice';
import QCXAliquotaEspecificaFormGroup from '../antidumping-form-group/QCXAliquotaEspecificaFormGroup';
import QCXFinalAlternativeCurrencyField from '../final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalPercentualField from '../final-percentual-field/QCXFinalPercentualField';
import QCXSelectFundamentoLegalPisCofinsAutocomplete from '../select-fundamento-legal-pis-cofins/QCXSelectFundamentoLegalPisCofinsAutocomplete';
import QCXSelectRegimeTributacaoAutocomplete from '../select-regime-tributacao/QCXSelectRegimeTributacaoAutocomplete';
import {
  configureGeneralRegimeTributacaoMatcherWith,
  REGIME_TRIBUTACAO_IMUNIDADE_CODE,
  REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE,
  REGIME_TRIBUTACAO_REDUCAO_CODE,
  REGIME_TRIBUTACAO_SUSPENSAO_CODE,
} from '../select-regime-tributacao/regimeTributacaoUtils';
import { configureGeneralTipoDeclaracaoMatcherWith, TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA } from '../select-tipo-declaracao/tipoDeclaracaoUtils';
import QCXSelectFundamentoReducaoLegalPisCofinsAutocomplete from '../select-fundamento-legal-reducao-pis-cofins/QCXSelectFundamentoLegalReducaoPisCofinsAutocomplete';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
}));

export default function QCXPisCofinsFormGroup({
  intl,
  modes,
  submitSucceeded,
  parentModel,
}) {
  const { t } = useTranslation();

  const classes = useStyles();

  const { values } = useFormState();

  const regimesTributacao = useSelector(selectRegimesTributacao);
  const tiposDeclaracao = useSelector(selectTiposDeclaracao);

  const tipoDeclaracao = useMemo(() => (
    parentModel?.atributosAdicionais?.tipoDeclaracao?.id
  ), [parentModel]);

  const regimeTributacao = useMemo(() => (
    values?.pisCofins?.regimeTributacao?.id
  ), [values]);

  const isSomeRegimeTributacaoBy = useCallback((codesToCompare) => (
    configureGeneralRegimeTributacaoMatcherWith(
      regimesTributacao
    )(
      regimeTributacao,
      codesToCompare
    )
  ), [
    regimeTributacao,
    regimesTributacao,
  ]);

  const isSomeTipoDeclaracaoBy = useCallback((codesToCompare) => (
    configureGeneralTipoDeclaracaoMatcherWith(
      tiposDeclaracao
    )(
      tipoDeclaracao,
      codesToCompare
    )
  ), [tiposDeclaracao, tipoDeclaracao]);

  const {
    isConsult,
    isSubConsult,
    isSubNone,
  } = modes;

  const aliquotaEspecificaPisPasFields = useMemo(() => ({
    rootName: 'pisCofins-pis-pas',
    valorReais: {
      name: 'pisCofins.valorRecolherPisPasepReal',
    },
    quantidade: {
      name: 'pisCofins.quantidadePisPasep',
    },
    unidadeDeMedida: {
      name: 'pisCofins.unidadeDeMedidaPisPasep.id',
    },
  }), []);

  const aliquotaEspecificaCofinsFields = useMemo(() => ({
    rootName: 'pisCofins-cofins',
    valorReais: {
      name: 'pisCofins.valorRecolherCofinsReal',
    },
    quantidade: {
      name: 'pisCofins.quantidadeCofins',
    },
    unidadeDeMedida: {
      name: 'pisCofins.unidadeDeMedidaCofins.id',
    },
  }), []);

  return (
    <>
      <Grid item container xs={12} sm={6} md={6} lg={6}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXSelectRegimeTributacaoAutocomplete
            id="select-field-regime-tributacao"
            key="select-field-regime-tributacao"
            name="pisCofins.regimeTributacao.id"
            label={t('com.muralis.qcx.regimeTributacao')}
            disabled={isConsult || isSubConsult || isSubNone}
            initialValues={values}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={6} md={6} lg={6}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridFields}
        >
          <QCXFinalAlternativeCurrencyField
            id="currency-valor-base-calculo-real-field"
            key="currency-valor-base-calculo-real-field"
            name="pisCofins.baseCalculoReal"
            label={t('com.muralis.qcx.baseCalculoReais')}
            intl={intl}
            disabled
            submitSucceeded={submitSucceeded}
            adorned
            scale={7}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXSelectFundamentoLegalPisCofinsAutocomplete
            id="select-field-fundamento-legal-pis-cofins"
            key="select-field-fundamento-legal-pis-cofins"
            name="pisCofins.fundamentoLegal.id"
            label={t('com.muralis.qcx.legislacao.fundamentoLegal')}
            disabled={isConsult || isSubConsult || isSubNone}
            initialValues={values}
            isDuimp
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <QCXFormSubtitle
          title={t('com.muralis.qcx.reducaoBaseCalculo')}
        />
      </Grid>
      <Grid item container xs={12} sm={6} md={6} lg={6}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXSelectFundamentoReducaoLegalPisCofinsAutocomplete
            id="select-field-fundamento-legal-pis-cofins"
            key="select-field-fundamento-legal-pis-cofins"
            name="pisCofins.fundamentoLegalRegimeTributacaoPisCofins.id"
            label={t('com.muralis.qcx.legislacao.fundamentoLegal')}
            disabled={isConsult || isSubConsult || isSubNone}
            initialValues={values}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={6} md={6} lg={6}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridFields}
        >
          <QCXFinalPercentualField
            id="percentual-redução-field"
            key="percentual-redução-field"
            name="pisCofins.percentualReducao"
            label={t('com.muralis.qcx.percentualReducao')}
            disabled={isConsult || isSubConsult || isSubNone}
            scale={7}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
        className={classes.gridFields}
      >
        <QCXFinalPercentualField
          id="percentual-redução-base-calculo-field"
          key="percentual-redução-base-calculo-field"
          name="pisCofins.percentualReducaoBaseCalculo"
          label={t('com.muralis.qcx.percentualReducaoBaseCalculo')}
          disabled={isConsult || isSubConsult || isSubNone}
          scale={7}
        />
      </Grid>

      {!isSomeRegimeTributacaoBy([
        REGIME_TRIBUTACAO_IMUNIDADE_CODE,
        REGIME_TRIBUTACAO_NAO_INCIDENCIA_CODE,
      ]) && (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFormSubtitle
                title={t('com.muralis.qcx.PISPASEP')}
              />
            </Grid>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <QCXFinalPercentualField
                  id="aliquota-ad-valorem-pis-pas-sep-field"
                  key="aliquota-ad-valorem-pis-pas-sep-field"
                  name="pisCofins.aliquotaPisPasep"
                  label={t('com.muralis.qcx.aliquotaADValorem')}
                  disabled={isConsult || isSubConsult || isSubNone}
                  scale={7}
                />
              </Grid>
            </Grid>
            {isSomeRegimeTributacaoBy([
              REGIME_TRIBUTACAO_REDUCAO_CODE,
            ]) && (
                <Grid item container xs={12} sm={6} md={6} lg={6}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.gridFields}
                  >
                    <QCXFinalPercentualField
                      id="percentual-aliquota-reduzida-pis-field"
                      key="percentual-aliquota-reduzida-pis-field"
                      name="pisCofins.aliquotaReduzidaPisPasep"
                      label={t('com.muralis.qcx.alioquoteReduzidaPercentual')}
                      disabled={isConsult || isSubConsult || isSubNone}
                      scale={7}
                    />
                  </Grid>
                </Grid>
              )}

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFormSubtitle
                title={t('com.muralis.qcx.aliquotaEspecifica')}
              />
            </Grid>
            <QCXAliquotaEspecificaFormGroup
              modes={modes}
              submitSucceeded={submitSucceeded}
              fields={aliquotaEspecificaPisPasFields}
              intl={intl}
            />
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
            >
              <QCXFinalAlternativeCurrencyField
                id="valor-devido-reais-tarifario-field-pis"
                key="valor-devido-reais-tarifario-field-pis"
                name="pisCofins.valorDevidoPisPasepReal"
                label={t('com.muralis.qcx.valorDevidoReais')}
                disabled
                adorned
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
            >
              <QCXFinalAlternativeCurrencyField
                id="valor-recolher-reais-tarifario-field-pis"
                key="valor-recolher-tarifario-field-pis"
                name="pisCofins.valorRecolherPisPasepReal"
                label={t('com.muralis.qcx.valorRecolherReais')}
                disabled
                adorned
              />
            </Grid>
            {(
              isSomeRegimeTributacaoBy(
                [
                  REGIME_TRIBUTACAO_SUSPENSAO_CODE,
                ]
              ) && (
                isSomeTipoDeclaracaoBy(
                  [
                    TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA,
                  ]
                )
              )
            ) && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                >
                  <QCXFinalAlternativeCurrencyField
                    id="recolher-permanecia-field-pispasep"
                    key="recolher-permanecia-field-pispasep"
                    name="pisCofins.recolherPermanenciaPisPasep"
                    label={t('com.muralis.qcx.valorSuspensoReais')}
                    disabled
                    adorned
                  />
                </Grid>
              )}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFormSubtitle
                title={t('com.muralis.qcx.imposto.COFINS')}
              />
            </Grid>
            <Grid item container xs={12} sm={6} md={6} lg={6}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <QCXFinalPercentualField
                  id="aliquota-ad-valorem-cofins-field"
                  key="aliquota-ad-valorem-cofins-field"
                  name="pisCofins.aliquotaCofins"
                  label={t('com.muralis.qcx.aliquotaADValorem')}
                  disabled={isConsult || isSubConsult || isSubNone}
                  sclae={5}
                />
              </Grid>
            </Grid>
            {isSomeRegimeTributacaoBy([
              REGIME_TRIBUTACAO_REDUCAO_CODE,
            ]) && (
                <Grid item container xs={12} sm={6} md={6} lg={6}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={classes.gridFields}
                  >
                    <QCXFinalPercentualField
                      id="percentual-aliquota-reduzida-cofins-field"
                      key="percentual-aliquota-reduzida-cofins-field"
                      name="pisCofins.aliquotaReduzidaCofins"
                      label={t('com.muralis.qcx.alioquoteReduzidaPercentual')}
                      scale={7}
                      disabled={isConsult || isSubConsult || isSubNone}
                    />
                  </Grid>
                </Grid>
              )}

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <QCXFormSubtitle
                title={t('com.muralis.qcx.aliquotaEspecifica')}
              />
            </Grid>
            <QCXAliquotaEspecificaFormGroup
              modes={modes}
              submitSucceeded={submitSucceeded}
              fields={aliquotaEspecificaCofinsFields}
              intl={intl}
            />
          </>
        )}
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
      >
        <QCXFinalAlternativeCurrencyField
          id="valor-devido-reais-tarifario-field-Cofins"
          key="valor-devido-reais-tarifario-field-Cofins"
          name="pisCofins.valorDevidoCofinsReal"
          label={t('com.muralis.qcx.valorDevidoReais')}
          disabled
          adorned
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={6}
      >
        <QCXFinalAlternativeCurrencyField
          id="valor-recolher-reais-tarifario-field-Cofins"
          key="valor-recolher-tarifario-field-Cofins"
          name="pisCofins.valorRecolherCofinsReal"
          label={t('com.muralis.qcx.valorRecolherReais')}
          disabled
          adorned
        />
      </Grid>
      {(
        isSomeRegimeTributacaoBy(
          [
            REGIME_TRIBUTACAO_SUSPENSAO_CODE,
          ]
        ) && (
          isSomeTipoDeclaracaoBy(
            [
              TIPO_DECLARACAO_CONSUMO_E_ADMISSAO_TEMPORARIA,
            ]
          )
        )
      ) && (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
          >
            <QCXFinalAlternativeCurrencyField
              id="recolher-permanecia-field-cofins"
              key="recolher-permanecia-field-cofins"
              name="pisCofins.recolherPermanenciaCofins"
              label={t('com.muralis.qcx.valorSuspensoReais')}
              disabled
              adorned
            />
          </Grid>
        )}
    </>
  );
}
