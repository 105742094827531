import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import QCXUnidadeDeMedidaForm from '../../../../../components/unidade-medida/QCXUnidadeDeMedidaForm';
import { register, save } from '../../../../../features/unidade-medida/unidadeDeMedidaAPI';
import {
  addToList,
  changeToBackgroundCreateMode,
  changeToConsultMode,
  changeToCreateMode,
  changeToUpdateMode,
  failure,
  fetchByIdAsync,
  loading,
  preparingAction,
  resetBackgroundMode,
  resetModel,
  selectBackgroundMode,
  selectMode,
  selectStatus,
  selectUnidadeDeMedida,
  selectUnidadesdeMedida,
  setError,
  setModel,
  setResponse,
  success,
  updateOnList,
} from '../../../../../features/unidade-medida/unidadeDeMedidaSlice';
import i18n from '../../../../../i18n';
import QCXRegistrationFormPageTemplate from '../../../../../templates/registration-form-page/QCXRegistrationFormPageTemplate';
import { padStart, trimValue } from '../../../../../utils/general/general-utils';
import {
  isBackgroundCreateMode,
  isConsultMode,
  isCreateMode,
  isNoneMode,
  isPreparingActionStatus,
  isUpdateMode,
} from '../../../../../utils/store/store-utils';
import useCurrentUnidadeNegocio from '../../../../../utils/hooks/user/useCurrentUnidadeNegocio';

export default function UnidadeDeMedidaRegistrationPage({ authInfo = {} }) {
  const { id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const status = useSelector(selectStatus);
  const mode = useSelector(selectMode);
  const backgroundMode = useSelector(selectBackgroundMode);
  const unidadesDeMedida = useSelector(selectUnidadesdeMedida);

  const unidadeDeMedida = useSelector(selectUnidadeDeMedida);
  const currentUnidadeDeNegocio = useCurrentUnidadeNegocio();

  const unidadeDeMedidaAlias = unidadeDeMedida?.aliases?.filter(
    (alias) => alias?.tennantUnidadeDeNegocio?.id === currentUnidadeDeNegocio?.id
  )[0];

  const handleChangeToPreparingAction = () => {
    dispatch(preparingAction());
  };

  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const isBackgroundCreate = useMemo(() => isBackgroundCreateMode(backgroundMode), [backgroundMode]);

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isUpdate = useMemo(() => isUpdateMode(mode), [mode]);

  const isPreparingAction = useMemo(() => isPreparingActionStatus(status), [status]);

  const handleChangeToCreate = () => {
    dispatch(changeToCreateMode());
  };

  const handleChangeToBackgroundCreate = () => {
    dispatch(changeToBackgroundCreateMode());
  };

  const handleChangeToConsult = () => {
    dispatch(changeToConsultMode());
  };

  const handleChangeToUpdate = () => {
    dispatch(changeToUpdateMode());
  };

  const handleResetBackgroundMode = () => {
    dispatch(resetBackgroundMode());
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchByIdAsync(id));
      handleChangeToConsult();
    }
  }, [id]);

  const handleCancelUpdate = () => {
    const currentId = isBackgroundCreate ? unidadeDeMedida?.id : id;

    if (currentId) {
      dispatch(fetchByIdAsync(currentId));
    }
    handleChangeToConsult();
  };

  const normalize = useCallback(
    (unnormalizedData) => {
      const alias = unnormalizedData?.apelido;

      const normalizedAliases = unnormalizedData?.aliases
        ? [...unnormalizedData?.aliases?.filter((current) => current?.id !== unidadeDeMedidaAlias?.id)]
        : [];

      if (alias?.description || alias?.sigla) {
        normalizedAliases.push({
          ...unidadeDeMedidaAlias,
          id: unidadeDeMedidaAlias?.id ?? 0,
          description: alias?.description,
          sigla: trimValue(alias?.sigla),
          tennantUnidadeDeNegocio: {
            id: currentUnidadeDeNegocio?.id,
          },
        });
      }

      const normalizedData = {
        ...unnormalizedData,
        apelido: null,
        sigla: trimValue(unnormalizedData?.sigla),
        aliases: normalizedAliases,
      };
      return normalizedData;
    },
    [currentUnidadeDeNegocio, unidadeDeMedidaAlias]
  );

  const unnormalize = useCallback(
    (data) => ({
      ...data,
      apelido: {
        ...unidadeDeMedidaAlias,
      },
    }),
    [unidadeDeMedida, currentUnidadeDeNegocio, unidadeDeMedidaAlias]
  );

  const handleDispatchSetModel = useCallback(
    (data) => {
      dispatch(setModel(normalize(data)));
    },
    [normalize]
  );

  const codeExistsMessage = () => new Error(i18n.t('com.muralis.qcx.validacao.codigoExiste'));

  const create = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const codeAlreadyExists = unidadesDeMedida.some((current) => {
          const currentPaddedCode = padStart(current?.code, 3, '0');
          const dataPaddedCode = padStart(data?.code, 3, '0');
          return currentPaddedCode === dataPaddedCode;
        });

        if (codeAlreadyExists) {
          throw codeExistsMessage();
        }

        const response = await register(data);
        if (response?.status === 201) {
          dispatch(resetModel());
          const created = response?.data;

          handleDispatchSetModel(created);

          const handleResultWithDebounce = debounce(() => {
            history.push(i18n.t('com.muralis.qcx.url.modulosOperacionaisUnidadeMedida'));

            dispatch(success());
            dispatch(
              setResponse({
                status: response.status,
                data: created,
                message: i18n.t('com.muralis.qcx.mensagem.unidadeMedidaCadastrada', {
                  unidadeMedida: created?.description,
                }),
              })
            );

            dispatch(addToList({ data: created }));
          }, 500);

          handleResultWithDebounce();
        }
      } catch (error) {
        const errorMessage = !isEmpty(error?.response)
          ? i18n.t('com.muralis.qcx.erro.erroCadastrarMedidaEspecifico', {
              unidadeMedida: data?.description,
              erro: error?.response?.data?.message,
            })
          : i18n.t('com.muralis.qcx.erro.erroCadastrarMedidaMensagem', { erro: error?.message });
        dispatch(failure());
        dispatch(
          setError({
            message: errorMessage,
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const update = async (data) => {
    const executeDebounced = debounce(async () => {
      try {
        const response = await save(data);

        if (response?.status === 200) {
          const handleResultWithDebounce = debounce(() => {
            handleChangeToConsult();
            dispatch(success());

            const saved = response?.data;

            dispatch(
              setResponse({
                status: response.status,
                data: saved,
                message: i18n.t('com.muralis.qcx.mensagem.alteracoesSalvasUnidadeMedida', {
                  unidadeMedida: saved?.code,
                }),
              })
            );

            dispatch(updateOnList({ data: saved }));
            dispatch(fetchByIdAsync(saved?.id));
          }, 500);

          handleResultWithDebounce();
          handleChangeToConsult();
        }
      } catch ({ response }) {
        dispatch(failure());
        dispatch(
          setError({
            message: i18n.t('com.muralis.qcx.erro.erroSalvarAlteracoesUnidadeMedida', {
              unidadeMedida: data?.pessoaJuridica?.nome,
              erro: response.data.message,
            }),
          })
        );
      }
    }, 500);

    dispatch(loading());
    executeDebounced();
  };

  const handleSubmit = async (data) => {
    const normalizedData = normalize(data);

    if (isUpdate && !isBackgroundCreate) {
      await update(normalizedData);
    } else {
      await create(normalizedData);
    }
  };

  const model = useMemo(
    () => (!isCreate ? unnormalize(unidadeDeMedida) : {}),
    [isCreate, unidadeDeMedida, unnormalize]
  );

  const actionName = useMemo(() => {
    if (isCreate || isBackgroundCreate || isNone) return i18n.t('com.muralis.qcx.acoes.nova');
    if (isConsult) return i18n.t('com.muralis.qcx.acoes.visualizar');
    return i18n.t('com.muralis.qcx.acoes.alterar');
  }, [isNone, isCreate, isConsult, isBackgroundCreate]);

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: i18n.t('com.muralis.qcx.inicio'),
        },
      },
      {
        text: {
          name: i18n.t('com.muralis.qcx.cadastros'),
        },
      },
      {
        link: {
          to: i18n.t('com.muralis.qcx.url.moduloOperacionais'),
          name: i18n.t('com.muralis.qcx.operacionais'),
        },
      },
      {
        text: {
          name: i18n.t('com.muralis.qcx.catalogoProdutosMercadoria'),
        },
      },
      {
        link: {
          to: i18n.t('com.muralis.qcx.url.modulosOperacionaisUnidadeMedida'),
          name: i18n.t('com.muralis.qcx.unidadeMedida.labelPlural'),
        },
      },
      {
        text: {
          name: actionName,
        },
      },
    ],
    [actionName]
  );

  const breadcrumbsProps = {
    maxItems: 6,
  };

  const pageTitle = useMemo(
    () =>
      isNone || isCreate || isBackgroundCreate
        ? i18n.t('com.muralis.qcx.unidadeMedida.novaUnidadeMedida')
        : i18n.t('com.muralis.qcx.unidadeMedida.unidadeMedidaExistente', {
            ID: unidadeDeMedida?.description || unidadeDeMedida?.id || '-',
          }),
    [isNone, isCreate, isBackgroundCreate, unidadeDeMedida]
  );

  return (
    <QCXRegistrationFormPageTemplate
      pageTitle={pageTitle}
      breadcrumbs={breadcrumbs}
      breadcrumbsProps={breadcrumbsProps}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isBackgroundCreate={isBackgroundCreate}
      isPreparingAction={isPreparingAction}
      handleChangeToPreparingAction={handleChangeToPreparingAction}
      handleChangeToCreate={handleChangeToCreate}
      handleChangeToBackgroundCreate={handleChangeToBackgroundCreate}
      handleChangeToConsult={handleChangeToConsult}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      handleResetBackgroundMode={handleResetBackgroundMode}
      authInfo={authInfo}
    >
      {(formProps) => (
        <QCXUnidadeDeMedidaForm
          initialValues={model}
          handleChangeModel={handleDispatchSetModel}
          handleSubmit={handleSubmit}
          authInfo={authInfo}
          requiredRoles={['unidade-de-medida']}
          {...formProps}
        />
      )}
    </QCXRegistrationFormPageTemplate>
  );
}
