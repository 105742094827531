import React from 'react';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@material-ui/core';

export default function QCXButton({
  tooltip = false,
  tooltipDescription,
  showTooltipForDisabledButton = false,
  variant = 'contained',
  color = 'default' || 'inherit' || 'primary' || 'secondary',
  fullWidth = false,
  disabled,
  children,
  ...restProps
}) {
  const button = (
    <Button variant={variant} color={color || 'default'} disabled={disabled} fullWidth={fullWidth} {...restProps}>
      {children}
    </Button>
  );
  return tooltip && (!disabled || showTooltipForDisabledButton) ? (
    <Tooltip arrow title={tooltipDescription} aria-label={tooltipDescription}>
      <span>{button}</span>
    </Tooltip>
  ) : (
    button
  );
}
