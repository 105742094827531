import { Grid, MenuItem, Typography, makeStyles } from '@material-ui/core';
import { Search as PageviewIcon, Refresh as Reload } from '@material-ui/icons';
import { isArrayLikeObject, isEmpty, isFunction, isUndefined } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuid } from 'uuid';
import { clienteAPI } from '../../features/cliente/clienteAPI';
import {
  documentoInstrucaoDespachoDeclaracaoImportacaoActions,
  documentoInstrucaoDespachoDeclaracaoImportacaoSelectors,
} from '../../features/documento-instrucao-despacho-declaracao-importacao/documentoInstrucaoDespachoDeclaracaoImportacaoSlice';
import {
  documentoInstrucaoDespachoAPI,
  fetchById as fetchDocumentoInstrucaoDespachoById,
} from '../../features/documento-instrucao-despacho/documentoInstrucaoDespachoAPI';
import { findByParams as findFatura } from '../../features/fatura/faturaAPI';
import { setErrorFeedback } from '../../features/feedback/feedbackSlice';
import { servicoAPI } from '../../features/servico/servicoAPI';
import { servicoActions } from '../../features/servico/servicoSlice';
import { transportadoraActions } from '../../features/transportadora/transportadoraSlice';
import {
  vinculoReferenciaClienteFollowUpActions,
  vinculoReferenciaClienteFollowUpSelectors,
} from '../../features/vinculo-referencia-cliente-follow-up/vinculoReferenciaClienteFollowUpSlice';
import QCXInfoAlert from '../../shared-components/alert/QCXInfoAlert';
import QCXAloneInlineBoxWrapper from '../../shared-components/alone-inline-box-wrapper/QCXAloneInlineBoxWrapper';
import QCXFinalCheckboxField from '../../shared-components/final-checkbox-field/QCXFinalCheckboxField';
import QCXFinalDatePickerField from '../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalForm from '../../shared-components/final-form/QCXFinalForm';
import QCXFinalBondManager from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManager';
import QCXFinalBondManagerOld from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXSelectClienteAutocomplete from '../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import { LocalPortoAeroportoUtils } from '../../shared-components/final-select-local-porto-aeroporto/localPortoAeroportoUtils';
import QCXFinalMultilineTextField from '../../shared-components/final-text-field/QCXFinalMultilineTextField';
import QCXFinalTextField from '../../shared-components/final-text-field/QCXFinalTextField';
import QCXListItemIcon from '../../shared-components/list-item-icon/QCXListItemIcon';
import QCXSelectDocumentoInstrucaoAutocomplete from '../../shared-components/select-documento-instrucao-despacho/QCXSelectDocumentoInstrucaoAutocomplete';
import { isDocumentoInstrucaoConhecimentoCargaByCode } from '../../shared-components/select-documento-instrucao-despacho/documentoInstrucaoDespachoUtils';
import QCXSelectPortoAeroportoAutocomplete from '../../shared-components/select-porto-aeroporto/QCXSelectPortoAeroportoAutocomplete';
import QCXSelectServicoAutocomplete from '../../shared-components/select-servico/QCXSelectServicoAutocomplete';
import TipoMascaraFollowUpUtils from '../../shared-components/select-tipo-mascara-follow-up/tipoMascaraFollowUpUtils';
import { TipoProdutoUtils } from '../../shared-components/select-tipo-produto/tipoProdutoUtils';
import QCXSelectTransportadoraAutocomplete from '../../shared-components/select-transportadora/QCXSelectTransportadoraAutocomplete';
import {
  VIA_TRANSPORTE_FLUVIAL_CODE,
  VIA_TRANSPORTE_LACUSTRE_CODE,
  VIA_TRANSPORTE_MARITIMA_CODE,
  isSomeViaTransporteByCode,
  isViaTransporteAereaByCode,
} from '../../shared-components/select-via-transporte/viaTransporteUtils';
import AfrmmUtils from '../../utils/general/afrmm/AfrmmUtils';
import FollowUpOcrStatusUtils from '../../utils/general/follow-up/FollowUpOcrStatusUtils';
import FollowUpUtils from '../../utils/general/follow-up/FollowUpUtils';
import { isStrictEquals } from '../../utils/general/general-utils';
import { parseToNumber } from '../../utils/general/parse-utils';
import { formatNumeroConhecimentoMaster, formatNumeroDI } from '../../utils/hooks/form/field/formatters';
import { parseNumeroConhecimentoMaster, parseToAlphanumericOnly } from '../../utils/hooks/form/field/parsers';
import useCurrentUnidadeNegocio from '../../utils/hooks/user/useCurrentUnidadeNegocio';
import { required, validateNumeroConhecimentoMasterAereo } from '../../utils/validators/field/validator';
import QCXMoveFocusInside from '../focus-lock/QCXMoveFocusInside';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import { MediaType } from '../../utils/api/api-utils';
import QCXFinalCustomInputFile from '../../shared-components/input-file/QCXFinalCustomInputFile';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
}));

export default function QCXFollowUpFinalForm({
  isCreate,
  isConsult,
  isUpdate,
  initialValues,
  handleSubmit,
  handleSubmitReprocess,
  handleChangeToUpdate,
  handleCancelUpdate,
  success,
  failure,
  setLoading,
  authInfo = {},
  requiredRoles = [],
  ...restProps
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const classes = useStyles();

  const dispatch = useDispatch();

  const currentUnidadeNegocio = useCurrentUnidadeNegocio();

  const isTipoMascaraFollowUpManual = useMemo(() => {
    if (isCreate) {
      return TipoMascaraFollowUpUtils.isManual(currentUnidadeNegocio?.tipoMascaraFollowUp);
    }

    const tipoMascaraFollowUp = initialValues?.followUpSequencial?.tipoMascaraFollowUp;

    if ((isConsult || isUpdate) && tipoMascaraFollowUp) {
      return TipoMascaraFollowUpUtils.isManual(tipoMascaraFollowUp);
    }

    return true;
  }, [isCreate, initialValues, currentUnidadeNegocio]);

  const getViaTransporteCodeFrom = useCallback((values) => values?.viaTransporte?.code, []);

  const hasPendencia = useCallback((values) => values?.observacao, []);

  const isViaTransporteAereoBy = useCallback(
    (values) => isViaTransporteAereaByCode(getViaTransporteCodeFrom(values)),
    [getViaTransporteCodeFrom]
  );

  const isRequiredAfrmmBy = useCallback(
    (values) =>
      isSomeViaTransporteByCode(getViaTransporteCodeFrom(values), [
        VIA_TRANSPORTE_MARITIMA_CODE,
        VIA_TRANSPORTE_FLUVIAL_CODE,
        VIA_TRANSPORTE_LACUSTRE_CODE,
      ]),
    [getViaTransporteCodeFrom]
  );

  const referenciaClienteReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoReferenciaClienteFollowUpSelectors.selectStatus,
        selectMode: vinculoReferenciaClienteFollowUpSelectors.selectMode,
        selectModel: vinculoReferenciaClienteFollowUpSelectors.selectModel,
      },
      actions: {
        loading: vinculoReferenciaClienteFollowUpActions.loading,
        resetStatus: vinculoReferenciaClienteFollowUpActions.resetStatus,
        changeToUpdateMode: vinculoReferenciaClienteFollowUpActions.changeToUpdateMode,
        changeToCreateMode: vinculoReferenciaClienteFollowUpActions.changeToCreateMode,
        resetMode: vinculoReferenciaClienteFollowUpActions.resetMode,
        setModel: vinculoReferenciaClienteFollowUpActions.setModel,
        resetModel: vinculoReferenciaClienteFollowUpActions.resetModel,
      },
    }),
    []
  );

  const referenciaClienteFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.referenciaCliente',
      fields: [
        {
          name: 'referencia',
          label: t('com.muralis.qcx.referenciaCliente'),
        },
      ],
    }),
    []
  );

  const referenciaClienteListProps = useMemo(
    () => ({
      name: 'referencias',
      columns: [
        {
          field: 'referencia',
          headerName: t('com.muralis.qcx.referencia'),
          headerAlign: 'left',
          align: 'left',
          flex: 140,
          valueGetter: ({ row }) => row?.referencia || '',
        },
      ],
    }),
    []
  );

  const handleAddReferenciaCliente = useCallback(async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
    const vinculo = {
      id: uuid(),
      referencia: currentVinculo?.referencia,
    };

    const updatedVinculos = [...currentVinculos, vinculo];

    handleSuccessAddVinculo(updatedVinculos);
  }, []);

  const handleUpdateReferenciaCliente = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      const updatedVinculo = {
        ...currentVinculo,
        referencia: currentVinculo?.referencia,
      };

      const updatedVinculos =
        currentVinculos?.map((existingVinculo) =>
          isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
        ) || currentVinculos;

      handleSuccessUpdateVinculo(updatedVinculos);
    },
    []
  );

  const handleReferenciaClienteAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(vinculo?.referencia, currentVinculo?.referencia) &&
          !isStrictEquals(vinculo?.id, currentVinculo?.id)
      ),
    []
  );

  const documentoInstrucaoDespachoReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: documentoInstrucaoDespachoDeclaracaoImportacaoSelectors.selectStatus,
        selectMode: documentoInstrucaoDespachoDeclaracaoImportacaoSelectors.selectMode,
        selectModel: documentoInstrucaoDespachoDeclaracaoImportacaoSelectors.selectModel,
      },
      actions: {
        loading: documentoInstrucaoDespachoDeclaracaoImportacaoActions.loading,
        resetStatus: documentoInstrucaoDespachoDeclaracaoImportacaoActions.resetStatus,
        changeToUpdateMode: documentoInstrucaoDespachoDeclaracaoImportacaoActions.changeToUpdateMode,
        changeToCreateMode: documentoInstrucaoDespachoDeclaracaoImportacaoActions.changeToCreateMode,
        resetMode: documentoInstrucaoDespachoDeclaracaoImportacaoActions.resetMode,
        setModel: documentoInstrucaoDespachoDeclaracaoImportacaoActions.setModel,
        resetModel: documentoInstrucaoDespachoDeclaracaoImportacaoActions.resetModel,
      },
    }),
    []
  );

  const documentoInstrucaoDespachoFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.documentoInstrucaoDespacho',
      fields: [
        {
          name: 'documentoInstrucaoDespachoImportacao.id',
          label: t('com.muralis.qcx.documento.documentoInstrucaoDespacho'),
        },
        {
          name: 'numero',
          label: t('com.muralis.qcx.numero'),
        },
      ],
    }),
    []
  );

  const documentoInstrucaoDespachoListProps = useMemo(
    () => ({
      name: 'documentos',
      columns: [
        {
          field: 'documentoInstrucaoDespachoImportacao.description',
          headerName: t('com.muralis.qcx.documento.documentoInstrucaoDespacho'),
          headerAlign: 'left',
          align: 'left',
          flex: 260,
          valueGetter: ({ row }) =>
            `${row?.documentoInstrucaoDespachoImportacao?.code} - ${row?.documentoInstrucaoDespachoImportacao?.description}` ||
            '',
        },
        {
          field: 'numero',
          headerName: t('com.muralis.qcx.numero'),
          headerAlign: 'left',
          align: 'left',
          flex: 140,
          valueGetter: ({ row }) => row?.numero || '',
        },
      ],
    }),
    []
  );

  const handleAddDocumentoInstrucaoDespacho = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
      const documentoInstrucaoDespachoResponse = await fetchDocumentoInstrucaoDespachoById(
        currentVinculo?.documentoInstrucaoDespachoImportacao?.id
      );

      if (documentoInstrucaoDespachoResponse?.status === 200) {
        const foundDocumentoInstrucaoDespacho = documentoInstrucaoDespachoResponse?.data;

        const vinculo = {
          id: uuid(),
          documentoInstrucaoDespachoImportacao: foundDocumentoInstrucaoDespacho,
          numero: currentVinculo?.numero,
        };

        const updatedVinculos = [...currentVinculos, vinculo];

        handleSuccessAddVinculo(updatedVinculos);
      }
    },
    []
  );

  const handleUpdateDocumentoInstrucaoDespacho = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessUpdateVinculo) => {
      const documentoInstrucaoDespachoResponse = await fetchDocumentoInstrucaoDespachoById(
        currentVinculo?.documentoInstrucaoDespachoImportacao?.id
      );

      if (documentoInstrucaoDespachoResponse?.status === 200) {
        const foundDocumentoInstrucaoDespacho = documentoInstrucaoDespachoResponse?.data;

        const updatedVinculo = {
          ...currentVinculo,
          documentoInstrucaoDespachoImportacao: foundDocumentoInstrucaoDespacho,
          numero: currentVinculo?.numero,
        };

        const updatedVinculos =
          currentVinculos?.map((existingVinculo) =>
            isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
          ) || currentVinculos;

        handleSuccessUpdateVinculo(updatedVinculos);
      }
    },
    []
  );

  const handleDocumentoInstrucaoDespachoAlreadyExists = useCallback(
    (currentVinculo, currentVinculosList) =>
      currentVinculosList.some(
        (vinculo) =>
          isStrictEquals(
            vinculo?.documentoInstrucaoDespachoImportacao?.id,
            currentVinculo?.documentoInstrucaoDespachoImportacao?.id
          ) &&
          isStrictEquals(vinculo?.numero, currentVinculo?.numero) &&
          !isStrictEquals(vinculo?.id, currentVinculo?.id)
      ),
    []
  );

  const handleClickOnViewButton = useCallback(
    (event, followUpStorage, followUpId) => {
      if (isFunction(event?.stopPropagation)) {
        dispatch(setLoading());
        event.stopPropagation();
        const arquivoId = followUpStorage?.documento?.id;

        findFatura([
          { name: 'arquivoId', value: arquivoId },
          { name: 'followUpId', value: followUpId },
        ])
          .then((response) => {
            if (response.status === 200) {
              const faturaId = response.data.id;
              history.push(`/importacao/faturas/${faturaId}/detalhes`);
              dispatch(success());
              return;
            }
            throw response;
          })
          .catch(() => {
            dispatch(failure());
            dispatch(
              setErrorFeedback({
                message: t('com.muralis.qcx.validacao.faturaNaoEncontrada'),
              })
            );
          });
      }
    },
    [history]
  );

  const anexoFaturaListProps = useMemo(
    () => ({
      name: 'arquivos',
      columns: [
        {
          field: 'fileName',
          headerName: t('com.muralis.qcx.fatura.faturaPDFResumida'),
          headerAlign: 'left',
          align: 'left',
          flex: 140,
          valueGetter: ({ row }) => {
            if (row?.fileName && row?.fatura?.length) {
              const fileSizeInMb = parseToNumber(row?.fatura[0]?.size) / 1024 ** 2;

              if (fileSizeInMb < 1) {
                const fileSizeInKb = fileSizeInMb * 1024;
                return `${row?.fileName} (~ ${fileSizeInKb.toFixed(0)} KB)`;
              }

              return `${row?.fileName} (~ ${fileSizeInMb.toFixed(2)} MB)`;
            }

            return row?.fileName || '';
          },
        },
        {
          field: 'status',
          headerName: t('com.muralis.qcx.status'),
          headerAlign: 'center',
          align: 'center',
          flex: 90,
          valueGetter: ({ row }) => (row?.status ? t(FollowUpOcrStatusUtils.getLabelByValue(row?.status)) : '-'),
        },
      ],
      renderOtherMenuItems: (row, popupState) => [
        <MenuItem
          key="btn-option-reprocess-fatura"
          name="btn-option-reprocess-fatura"
          onClick={(event) => {
            popupState.close(event);
            handleSubmitReprocess();
          }}
          disabled={!['PROCESSANDO', 'ERRO_TESSERACT'].includes(row?.status) || isConsult}
        >
          <QCXListItemIcon>
            <Reload fontSize="small" />
          </QCXListItemIcon>
          <Typography
            variant="inherit"
            style={{
              fontSize: 12,
            }}
          >
            {t('com.muralis.qcx.acoes.reprocessar').toUpperCase()}
          </Typography>
        </MenuItem>,
        <MenuItem
          key="btn-option-show-fatura"
          name="btn-option-show-fatura"
          onClick={(event) => {
            popupState.close(event);
            handleClickOnViewButton(event, row, initialValues?.id);
          }}
          disabled={!['CONCLUIDO', 'CONCLUIDO_INCOMPLETO'].includes(row?.status)}
        >
          <QCXListItemIcon>
            <PageviewIcon fontSize="small" />
          </QCXListItemIcon>
          <Typography
            variant="inherit"
            style={{
              fontSize: 12,
            }}
          >
            {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
          </Typography>
        </MenuItem>,
      ],
    }),
    [initialValues, handleClickOnViewButton, handleSubmitReprocess]
  );

  const handleFormatNumeroConhecimentoMasterBy = useCallback((fieldName, form, values) => {
    if (isViaTransporteAereoBy(values?.servico) && values?.numeroConhecimentoMaster?.length > 10) {
      const parsedNumeroConhecimentoMaster = parseNumeroConhecimentoMaster(values?.numeroConhecimentoMaster);

      const formattedNumeroConhecimentoMaster = formatNumeroConhecimentoMaster(parsedNumeroConhecimentoMaster);

      form.change(fieldName, formattedNumeroConhecimentoMaster);

      return;
    }

    if (!isViaTransporteAereoBy(values?.servico) && values?.numeroConhecimentoMaster?.length > 10) {
      const parsedNumeroConhecimentoMaster = parseToAlphanumericOnly(values?.numeroConhecimentoMaster);

      form.change(fieldName, parsedNumeroConhecimentoMaster);
    }
  }, []);

  const handleImportadorIdChange = useCallback(
    (form, values) => async (value) => {
      if (value) {
        const fetchServicosByImportador = async () => {
          const response = await clienteAPI.fetchServicosByClienteId(value, [
            {
              name: 'produto',
              value: TipoProdutoUtils.IMPORTACAO,
            },
          ]);

          if (response.status === 200) {
            const foundServicos = isArrayLikeObject(response?.data) ? response.data : [];

            dispatch(servicoActions.setList(foundServicos));

            const currentServicoId = values?.servico?.id;

            if (currentServicoId) {
              const foundCurrentServicoInCliente = foundServicos.find(
                (foundServico) => foundServico.id === currentServicoId
              );

              const clienteHasNotCurrentServico = isUndefined(foundCurrentServicoInCliente);

              if (clienteHasNotCurrentServico) {
                form.change('servico', undefined);
              }
            }
          }
        };

        const fetchTransportadorasByImportador = async () => {
          const filterParams = [
            {
              name: 'cliente',
              value,
            },
            {
              name: 'importador',
              value: true,
            },
          ];

          dispatch(transportadoraActions.fetchByFilterAsync(filterParams));
        };

        fetchServicosByImportador();
        fetchTransportadorasByImportador();

        return;
      }

      if (!(isConsult || isUpdate) && !value) {
        form.change('servico', undefined);
        form.change('transportadora', undefined);
      }
    },
    [isConsult, isUpdate]
  );

  const handleServicoIdChange = useCallback(
    (form, values) => async (value) => {
      if (!isConsult || isUpdate) {
        if (value) {
          const response = await servicoAPI.fetchById(value);

          if (response.status === 200) {
            const foundServico = response.data;

            form.change('servico', foundServico);

            const changeTaxaAfrmmToUndefinedIfNonRequired = () => {
              if (!isRequiredAfrmmBy(foundServico)) {
                form.change('taxaAfrmm', undefined);
              }
            };

            handleFormatNumeroConhecimentoMasterBy('numeroConhecimentoMaster', form, {
              ...values,
              servico: foundServico,
            });
            changeTaxaAfrmmToUndefinedIfNonRequired();
          }

          return;
        }

        form.change('servico', undefined);
        form.change('taxaAfrmm', undefined);
      }
    },
    [isConsult, isUpdate, isRequiredAfrmmBy, handleFormatNumeroConhecimentoMasterBy]
  );

  const handleNumeroConhecimentoMasterChange = useCallback(
    (form, values) => async (value) => {
      if (!value || isEmpty(value) || !values?.numeroConhecimentoMaster || isEmpty(values?.numeroConhecimentoMaster)) {
        form.resetFieldState('previsaoChegada');
      }
    },
    [isConsult]
  );

  const handleNumeroConhecimentoHouseChange = useCallback(
    (form, values) => async (value) => {
      if (!value || isEmpty(value) || !values?.numeroConhecimentoHouse || isEmpty(values?.numeroConhecimentoHouse)) {
        form.resetFieldState('previsaoChegada');
      }
    },
    [isConsult]
  );

  const handleDocumentoInstrucaoDespachoIdChange = useCallback(
    (form, values) => async (value) => {
      if (value) {
        const response = await documentoInstrucaoDespachoAPI.fetchById(value);

        if (response?.status === 200) {
          const foundDocumentoInstrucao = response?.data;

          const isNumeroDocumentoInstrucaoSuggestible = isDocumentoInstrucaoConhecimentoCargaByCode(
            foundDocumentoInstrucao?.code
          );

          if (!isNumeroDocumentoInstrucaoSuggestible) {
            return;
          }

          if (values?.numeroConhecimentoHouse) {
            form.change('ignorableFields.documentoInstrucaoDespacho.numero', values?.numeroConhecimentoHouse);

            return;
          }

          if (values?.numeroConhecimentoMaster) {
            handleFormatNumeroConhecimentoMasterBy('ignorableFields.documentoInstrucaoDespacho.numero', form, values);
          }
        }
      }
    },
    [handleFormatNumeroConhecimentoMasterBy]
  );

  const listeners = useMemo(
    () => [
      {
        name: 'importador.id',
        fn: handleImportadorIdChange,
      },
      {
        name: 'servico.id',
        fn: handleServicoIdChange,
      },
      {
        name: 'numeroConhecimentoMaster',
        fn: handleNumeroConhecimentoMasterChange,
      },
      {
        name: 'numeroConhecimentoHouse',
        fn: handleNumeroConhecimentoHouseChange,
      },
      {
        name: 'ignorableFields.documentoInstrucaoDespacho.documentoInstrucaoDespachoImportacao.id',
        fn: handleDocumentoInstrucaoDespachoIdChange,
      },
    ],
    [
      handleImportadorIdChange,
      handleServicoIdChange,
      handleNumeroConhecimentoMasterChange,
      handleNumeroConhecimentoHouseChange,
    ]
  );

  const formControlButtons = useMemo(
    () => ({
      create: {
        description: t('com.muralis.qcx.acoes.abrir').toUpperCase(),
      },
    }),
    []
  );

  const fileXmlGetter = useCallback((values) => {
    if (!values?.id && values?.length > 0) {
      return values[0];
    }

    return values;
  }, []);

  const fileXmlNameGetter = useCallback((file) => {
    if (file?.id) {
      return file?.nome;
    }
    return file?.name;
  }, []);

  return (
    <QCXFinalForm
      isConsult={isConsult}
      isUpdate={isUpdate}
      initialValues={initialValues}
      handleSubmitValues={handleSubmit}
      handleChangeToUpdate={handleChangeToUpdate}
      handleCancelUpdate={handleCancelUpdate}
      onChangeListeners={listeners}
      controlButtons={formControlButtons}
      authInfo={authInfo}
      requiredRoles={requiredRoles}
      disablePristine
      {...restProps}
    >
      {({ values }) => (
        <>
          <Grid item container spacing={2}>
            <Grid
              item
              xs={12}
              style={{
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
              }}
            >
              {isTipoMascaraFollowUpManual && isCreate && (
                <QCXInfoAlert>
                  <Trans i18nKey="com.muralis.qcx.unidadeNegocio.configuracaoUnidadeNegocioNumeroProcesso">
                    <strong>{{ termo: t('com.muralis.qcx.manualmente') }}</strong>
                  </Trans>
                </QCXInfoAlert>
              )}
              {!isTipoMascaraFollowUpManual && isCreate && (
                <QCXInfoAlert>
                  <Trans i18nKey="com.muralis.qcx.numeroProcessoAutomaticoUnidadeNegocioAtual">
                    <strong>{{ termo: t('com.muralis.qcx.automaticamente') }}</strong>
                  </Trans>
                </QCXInfoAlert>
              )}
            </Grid>
            {(isTipoMascaraFollowUpManual || !isCreate) && (
              <QCXAloneInlineBoxWrapper
                gridBreakpoints={{
                  sm: 12,
                  md: 6,
                }}
              >
                {(fieldProps) => (
                  <QCXMoveFocusInside>
                    <QCXFinalTextField
                      id="text-field-numero-processo"
                      key="text-field-numero-processo"
                      name="numero"
                      label={t('com.muralis.qcx.numeroProcesso')}
                      validate={required}
                      disabled={isConsult || !isTipoMascaraFollowUpManual}
                      helperText={
                        !isTipoMascaraFollowUpManual && (isConsult || isUpdate)
                          ? t('com.muralis.qcx.mensagem.sequencialGeradoAutomaticamente')
                          : undefined
                      }
                      required
                      noSpacesAllowed
                      {...fieldProps}
                    />
                  </QCXMoveFocusInside>
                )}
              </QCXAloneInlineBoxWrapper>
            )}
            <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
              <QCXSelectClienteAutocomplete
                id="autocomplete-select-importador-field"
                key="autocomplete-select-importador-field"
                name="importador.id"
                label={t('com.muralis.qcx.importador.label')}
                initialValues={values}
                filter={{
                  importador: true,
                }}
                fieldProps={{ validate: required }}
                disabled={isConsult || isUpdate}
                required
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
              <QCXSelectServicoAutocomplete
                id="autocomplete-select-servico-field"
                key="autocomplete-select-servico-field"
                name="servico.id"
                label={t('com.muralis.qcx.servico.labelSingular')}
                initialValues={values}
                fieldProps={{ validate: required }}
                disabled={isConsult || isUpdate}
                required
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
              <QCXFinalTextField
                id="text-field-numero-conhecimento-master"
                key="text-field-numero-conhecimento-master"
                name="numeroConhecimentoMaster"
                label={t('com.muralis.qcx.numeroConhecimentoMaster')}
                {...(isViaTransporteAereoBy(values?.servico)
                  ? {
                      format: formatNumeroConhecimentoMaster,
                      parse: parseNumeroConhecimentoMaster,
                      validate: validateNumeroConhecimentoMasterAereo,
                      maxLength: 11,
                    }
                  : {
                      parse: parseToAlphanumericOnly,
                      maxLength: 18,
                    })}
                disabled={isConsult}
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
              <QCXFinalTextField
                id="text-field-numero-conhecimento-house"
                key="text-field-numero-conhecimento-house"
                name="numeroConhecimentoHouse"
                label={t('com.muralis.qcx.numeroConhecimentoHouse')}
                maxLength={18}
                disabled={isConsult}
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={6} md={6}>
              <QCXFinalDatePickerField
                id="date-picker-data-previsao-chegada-field"
                key="date-picker-data-previsao-chegada-field"
                name="previsaoChegada"
                label={t('com.muralis.qcx.previsaoChegada')}
                {...(!!values?.numeroConhecimentoMaster && !!values?.numeroConhecimentoHouse
                  ? {
                      validate: required,
                      required: true,
                    }
                  : {})}
                disabled={isConsult}
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={6} md={6}>
              <QCXSelectTransportadoraAutocomplete
                id="autocomplete-select-transportadora-field"
                key="autocomplete-select-transportadora-field"
                name="transportadora"
                label={t('com.muralis.qcx.transportadora.labelPlural')}
                initialValues={values}
                disabled={isConsult}
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
              <QCXFinalTextField
                id="text-field-numero-presenca-carga"
                key="text-field-numero-presenca-carga"
                name="numeroPresencaCarga"
                label={t('com.muralis.qcx.numeroPresencaCarga')}
                maxLength={18}
                disabled={isConsult}
              />
            </Grid>
            <Grid item className={classes.gridFields} xs={12} sm={12} md={6}>
              <QCXSelectPortoAeroportoAutocomplete
                id="select-field-local-embarque"
                key="select-field-local-embarque"
                name="localEmbarque.id"
                label={t('com.muralis.qcx.localDesembarque')}
                initialValues={values}
                fieldProps={{ validate: required }}
                filter={{
                  local: LocalPortoAeroportoUtils.DESTINO,
                }}
                disabled={isConsult}
                required
              />
            </Grid>
            {isRequiredAfrmmBy(values?.servico) && (
              <Grid item xs={12} sm={6} md={6}>
                <QCXFinalCheckboxField
                  id="text-field-afrmm-isento"
                  key="text-field-afrmm-isento"
                  label={t('com.muralis.qcx.AFRMMIsento')}
                  name="taxaAfrmm"
                  value={AfrmmUtils.ISENTO}
                  disabled={isConsult}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <QCXInfoAlert>Esses campos só devem ser prenchidos, para DIs não cadastradas no sistema.</QCXInfoAlert>
            </Grid>

            <Grid item xs={6}>
              <QCXFinalTextField
                id="text-field-numero-di"
                key="text-field-numero-di"
                label={t('com.muralis.qcx.numeroDI')}
                name="numeroDeclaracaoImportacao"
                disabled={isConsult}
                format={formatNumeroDI}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <QCXFinalCustomInputFile
                id="upload-input-xml"
                key="upload-input-xml"
                name="xml"
                label="xml di"
                tooltipTitle="xml di"
                accept={MediaType.APPLICATION_XML}
                fileGetter={fileXmlGetter}
                fileNameGetter={fileXmlNameGetter}
                disableRemoveControl
                disabled={isConsult}
              />
            </Grid>

            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.referenciasCliente')} />
            </Grid>
            <QCXFinalBondManagerOld
              isParentConsult={isConsult}
              reducerConfig={referenciaClienteReducerConfig}
              listProps={referenciaClienteListProps}
              formProps={referenciaClienteFormProps}
              controlButtonsGridProps={{
                add: {
                  xs: 12,
                  sm: 4,
                  md: 4,
                },
                update: {
                  xs: 6,
                  sm: 2,
                  md: 2,
                },
                clear: {
                  xs: 6,
                  sm: 2,
                  md: 2,
                },
              }}
              handleAdd={handleAddReferenciaCliente}
              handleUpdate={handleUpdateReferenciaCliente}
              handleAlreadyExists={handleReferenciaClienteAlreadyExists}
            >
              {({ loading }) => (
                <Grid item className={classes.customGridFields} xs={12} sm={8} md={8}>
                  <QCXFinalTextField
                    id="text-field-vinculo-referencia-cliente"
                    key="text-field-vinculo-referencia-cliente"
                    name="ignorableFields.referenciaCliente.referencia"
                    label={t('com.muralis.qcx.referenciaCliente')}
                    maxLength={100}
                    disabled={isConsult || loading}
                  />
                </Grid>
              )}
            </QCXFinalBondManagerOld>

            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.documento.documentosIntrucaoDespacho')} />
            </Grid>
            <QCXFinalBondManagerOld
              isParentConsult={isConsult}
              listProps={documentoInstrucaoDespachoListProps}
              formProps={documentoInstrucaoDespachoFormProps}
              reducerConfig={documentoInstrucaoDespachoReducerConfig}
              controlButtonsGridProps={{
                add: {
                  xs: 12,
                  sm: 4,
                  md: 4,
                },
                update: {
                  xs: 6,
                  sm: 2,
                  md: 2,
                },
                clear: {
                  xs: 6,
                  sm: 2,
                  md: 2,
                },
              }}
              handleAdd={handleAddDocumentoInstrucaoDespacho}
              handleUpdate={handleUpdateDocumentoInstrucaoDespacho}
              handleAlreadyExists={handleDocumentoInstrucaoDespachoAlreadyExists}
            >
              {({ loading }) => (
                <>
                  <Grid item className={classes.gridFields} xs={12} sm={12} md={4} lg={4}>
                    <QCXSelectDocumentoInstrucaoAutocomplete
                      id="autocomplete-select-vinculo-instrucao-despacho-documento-field"
                      key="autocomplete-select-vinculo-instrucao-despacho-documento-field"
                      name="ignorableFields.documentoInstrucaoDespacho.documentoInstrucaoDespachoImportacao.id"
                      label={t('com.muralis.qcx.documento.documentoInstrucao')}
                      initialValues={values}
                      disabled={isConsult || loading}
                    />
                  </Grid>
                  <Grid item className={classes.customGridFields} xs={12} sm={8} md={4} lg={4}>
                    <QCXFinalTextField
                      id="text-field-vinculo-instrucao-despacho-numero"
                      key="text-field-vinculo-instrucao-despacho-numero"
                      name="ignorableFields.documentoInstrucaoDespacho.numero"
                      label={t('com.muralis.qcx.numero')}
                      maxLength={24}
                      disabled={isConsult || loading}
                    />
                  </Grid>
                </>
              )}
            </QCXFinalBondManagerOld>
            <Grid item xs={12}>
              <QCXFormSubtitle title={t('com.muralis.qcx.fatura.faturaPDF')} />
            </Grid>
            <QCXFinalBondManager
              isParentConsult={isConsult}
              listProps={anexoFaturaListProps}
              readOnlyMode
              forceShowDelete
            >
              {() => <></>}
            </QCXFinalBondManager>
          </Grid>
          {hasPendencia(values) && (
            <>
              <Grid item xs={12}>
                <QCXFormSubtitle title={t('com.muralis.qcx.pendencias')} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <QCXFinalMultilineTextField
                  id="text-observacao-multiline-field"
                  key="text-observacao-multiline-field"
                  name="observacao"
                  label={t('com.muralis.qcx.observacao')}
                  rows={10}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <QCXFinalCheckboxField
                  id="text-field-corrigido"
                  key="text-field-corrigido"
                  label={t('com.muralis.qcx.corrigido')}
                  name="corrigido"
                  value={FollowUpUtils.CORRIGIDO}
                  disabled={isConsult}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <QCXFinalCheckboxField
                  id="text-field-ignorar-erro"
                  key="text-field-ignorar-erro"
                  label={t('com.muralis.qcx.fatura.ignorarErrosFatura')}
                  name="ignorarErroFatura"
                  value={FollowUpUtils.IGNORAR_ERRO_FATURA}
                  disabled={isConsult}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </QCXFinalForm>
  );
}
