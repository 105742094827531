import { createSlice } from '@reduxjs/toolkit';
import { AUTO_LOAD_CONTROL, IDLE_CONTROL } from '../config-control';
import i18n from '../../i18n';
import {
  CONSULT_MODE,
  CREATE_MODE,
  UPDATE_MODE,
  LOCKED_MODE,
  NONE_MODE,
  BACKGROUND_CREATE_MODE,
  BACKGROUND_UPDATE_MODE,
  BACKGROUND_DELETE_MODE,
  NONE_BACKGROUND_MODE,
  SUB_CREATE_MODE,
  SUB_CONSULT_MODE,
  SUB_UPDATE_MODE,
  NONE_SUB_MODE,
  BACKGROUND_CONSULT_MODE,
  NONE_SUB_BACKGROUND_MODE,
} from '../mode';
import { NO_REFRESH, REFRESH } from '../refresh';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  fetchAllAsync,
  fetchAllOperatorsAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  duplicarByIdAsync,
  reactivateEtapaByIdAsync,
  inactivateEtapaByIdAsync,
  fetchPreviewAsync,
  clearStatusAsync,
  fetchFollowUpImportacaoWithPaginationAsync,
  fetchFollowUpExportacaoWithPaginationAsync,
  fetchByNumeroAsync,
} from './followUpThunks';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
    subBackground: NONE_SUB_BACKGROUND_MODE,
  },
  refresh: NO_REFRESH,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {
    control: AUTO_LOAD_CONTROL,
  },
  list: [],
  model: {},
  related: {
    model: {
      duplicata: {},
      email: {},
    },
  },
  historyModel: {
    last: {},
  },
};

const followUpSlice = createSlice({
  name: 'followUp',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    refresh: (state) => {
      state.refresh = REFRESH;
    },
    resetRefresh: (state) => {
      state.refresh = NO_REFRESH;
    },
    changeModeTo: (state, action) => {
      state.mode.main = action.payload;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    changeToLockMode: (state) => {
      state.mode.main = LOCKED_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    changeToBackgroundConsultMode: (state) => {
      state.mode.background = BACKGROUND_CONSULT_MODE;
    },
    changeToBackgroundUpdateMode: (state) => {
      state.mode.background = BACKGROUND_UPDATE_MODE;
    },
    changeToBackgroundDeleteMode: (state) => {
      state.mode.background = BACKGROUND_DELETE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    changeControlToAutoLoad: (state) => {
      state.config.control = AUTO_LOAD_CONTROL;
    },
    resetControl: (state) => {
      state.config.control = IDLE_CONTROL;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    setList: (state, action) => {
      state.list = action.payload.data;
    },
    resetList: (state) => {
      state.list = [];
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setRelatedDuplicataModel: (state, action) => {
      state.related.model.duplicata = {
        ...action.payload,
      };
    },
    resetRelatedDuplicataModel: (state) => {
      state.related.model.duplicata = {};
    },
    setRelatedEmailModel: (state, action) => {
      state.related.model.email = {
        ...action.payload,
      };
    },
    resetRelatedEmailModel: (state) => {
      state.related.model.email = {};
    },
    changeLastHistoryModel: (state, action) => {
      state.historyModel.last = action.payload;
    },
    resetHistoryModel: (state) => {
      state.historyModel = {};
    },
    resetLastHistoryModel: (state) => {
      state.historyModel.last = {};
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = {
            ...action.payload.response.data,
          };
          state.status = SUCCESS_STATUS;

          return;
        }

        state.status = IDLE_STATUS;
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarFollowUp', { mensagem: action.error.message }),
        };
      })
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaFollowUp', { mensagem: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchPreviewAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchPreviewAsync.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchPreviewAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaFollowUp', { mensagem: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchAllOperatorsAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllOperatorsAsync.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchAllOperatorsAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaFollowUp', { mensagem: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(duplicarByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(duplicarByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const requestPayload = action.meta.arg;

        const followUpDuplicado = action.payload.response.data;

        const { numero } = followUpDuplicado;

        state.response.message = i18n.t('com.muralis.qcx.mensagem.duplicadoProcessoOrigemFollowUp', {
          numero,
          numeroOrigem: requestPayload?.meta?.from?.numero,
        });

        state.list = [...state.list, followUpDuplicado];

        state.historyModel.last = {
          numero,
        };

        if (state.response.status === 201) {
          state.refresh = REFRESH;
        }
      })
      .addCase(duplicarByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: action.payload?.message
            ? i18n.t('com.muralis.qcx.erro.erroDuplicarFollowUpEspecifico', { mensagem: action.payload?.message })
            : i18n.t('com.muralis.qcx.erro.erroDuplicarFollowUp'),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { numero } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.followUpAtivado', { numero });

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroAtivarFollowUp', { mensagem: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;

        if (state.response.status === 200) {
          state.list = state.list.map((current) =>
            current.id === action.payload.response.data.id ? action.payload.response.data : current
          );
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroExcluirFollowUp', { mensagem: action.error.message }),
        };
      })
      .addCase(reactivateEtapaByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(reactivateEtapaByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { etapa } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.etapaFollowUpReativada', {
          descricao: etapa?.description,
        });

        if (state.response.status === 200) {
          state.refresh = REFRESH;
        }
      })
      .addCase(reactivateEtapaByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroReativarFollowUp', { mensagem: action.error.message }),
        };
      })
      .addCase(inactivateEtapaByIdAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(inactivateEtapaByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;

        const { etapa } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.etapaFollowUpDesativada', {
          descricao: etapa?.description,
        });

        if (state.response.status === 200) {
          state.refresh = REFRESH;
        }
      })
      .addCase(inactivateEtapaByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroDesativarFollowUp', { mensagem: action.error.message }),
        };
      })
      .addCase(clearStatusAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(clearStatusAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;

        const { numero } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.statusFollowUpLimpo', { numero });

        if (state.response.status === 200) {
          state.refresh = REFRESH;
        }
      })
      .addCase(clearStatusAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroLimparStatusFollowUp', { mensagem: action.error.message }),
        };
      })
      .addCase(fetchFollowUpImportacaoWithPaginationAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchFollowUpImportacaoWithPaginationAsync.fulfilled, (state, action) => {
        state.list = action?.payload?.data?.itens;
        state.count = action?.payload?.data?.totalNumberOfItens;
        state.refresh = NO_REFRESH;
        state.status = SUCCESS_STATUS;
      })
      .addCase(fetchFollowUpImportacaoWithPaginationAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaFollowUp', { mensagem: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchFollowUpExportacaoWithPaginationAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchFollowUpExportacaoWithPaginationAsync.fulfilled, (state, action) => {
        state.list = action?.payload?.data?.itens;
        state.count = action?.payload?.data?.totalNumberOfItens;
        state.refresh = NO_REFRESH;
        state.status = SUCCESS_STATUS;
      })
      .addCase(fetchFollowUpExportacaoWithPaginationAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaFollowUp', { mensagem: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchByNumeroAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByNumeroAsync.fulfilled, (state, action) => {
        state.model = {
          ...action.payload.response.data,
        };
        state.list = action?.payload?.response?.data?.itens;
        state.count = action?.payload?.response?.data?.totalNumberOfItens;
        state.status = SUCCESS_STATUS;
      })
      .addCase(fetchByNumeroAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarFollowUp', { mensagem: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  refresh,
  resetRefresh,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToLockedMode,
  resetMode,
  changeToBackgroundCreateMode,
  changeToBackgroundConsultMode,
  changeToBackgroundUpdateMode,
  changeToBackgroundDeleteMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  changeControlToAutoLoad,
  resetControl,
  setModel,
  resetModel,
  setList,
  resetList,
  addToList,
  updateOnList,
  setRelatedDuplicataModel,
  resetRelatedDuplicataModel,
  setRelatedEmailModel,
  resetRelatedEmailModel,
  resetRelatedEtapasList,
  changeLastHistoryModel,
  resetHistoryModel,
  resetLastHistoryModel,
  setResponse,
  clearResponse,
  setError,
  clearError,
} = followUpSlice.actions;

const followUpActions = followUpSlice.actions;

export {
  followUpSlice,
  followUpActions,
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  refresh,
  resetRefresh,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToLockedMode,
  resetMode,
  changeToBackgroundCreateMode,
  changeToBackgroundConsultMode,
  changeToBackgroundUpdateMode,
  changeToBackgroundDeleteMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  changeControlToAutoLoad,
  resetControl,
  setModel,
  resetModel,
  setList,
  resetList,
  addToList,
  updateOnList,
  setRelatedDuplicataModel,
  resetRelatedDuplicataModel,
  setRelatedEmailModel,
  resetRelatedEmailModel,
  resetRelatedEtapasList,
  changeLastHistoryModel,
  resetHistoryModel,
  resetLastHistoryModel,
  setResponse,
  clearResponse,
  setError,
  clearError,
};

export default followUpSlice.reducer;
