/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import { Grid, makeStyles } from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import uuid from 'react-uuid';
import QCXMoveFocusInside from '../../../../../../../components/focus-lock/QCXMoveFocusInside';
import QCXFormSubtitle from '../../../../../../../components/form-title/QCXFormSubtitle';
import QCXFormTitle from '../../../../../../../components/form-title/QCXFormTitle';
import { selectDeclaracaoImportacao } from '../../../../../../../features/declaracao-importacao/declaracaoImportacaoSelectors';
import { despesaReceitaAPI } from '../../../../../../../features/despesa-receita/despesaReceitaAPI';
import {
  vinculoDespesaReceitaActions,
  vinculoDespesaReceitaSelectors,
} from '../../../../../../../features/vinculo-despesa-receita/vinculoDespesaReceitaSlice';
import QCXAutocompleteSelect from '../../../../../../../shared-components/autocomplete-select/QCXAutocompleteSelect';
import ReferenciaClienteBondManager from '../../../../../../../shared-components/dados-embarque/bond-managers/ReferenciaClienteBondManager';
import QCXFinalAlternativeCurrencyField from '../../../../../../../shared-components/final-currency-field/QCXFinalAlternativeCurrencyField';
import QCXFinalDatePickerField from '../../../../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalBondManagerOld from '../../../../../../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManagerOld';
import QCXSelectClienteAutocomplete from '../../../../../../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXFinalTextField from '../../../../../../../shared-components/final-text-field/QCXFinalTextField';
import QCXFormStepWizard from '../../../../../../../shared-components/form-step-wizard/QCXFormStepWizard';
import QCXSelectDespesaReceitaAutocomplete from '../../../../../../../shared-components/select-despesa-receita/QCXSelectDespesaReceitaAutocomplete';
import QCXSelectServicoAutocomplete from '../../../../../../../shared-components/select-servico/QCXSelectServicoAutocomplete';
import { formatDate, isStrictEquals, unnormalizeNumeral } from '../../../../../../../utils/general/general-utils';
import { formatBrazilianNumericDecimal } from '../../../../../../../utils/hooks/form/field/formatters';
import { AMERICAN, BRAZILIAN } from '../../../../../../../utils/hooks/form/field/mask-types';
import {
  isConsultMode,
  isCreateMode,
  isPerformingActionStatus,
  isPreparingActionStatus,
  isSubUpdateMode,
} from '../../../../../../../utils/store/store-utils';
import { maxValue } from '../../../../../../../utils/validators/field/validator';
import QCXSelectFollowUpImportacaoAutocompleteField from '../../../CusteiosConsultPage/components/QCXSelectFollowUpImportacaoAutocompleteField';
import { CusteiosSelectors, selectMode } from '../../../api/custeiosSelectors';
import { CusteioObject } from '../../types/followUp';
import { useFormState } from 'react-final-form';
import FormHeader from '../../../../../../common/components/forms/formHeader';
import FormHeaderMedium from '../../../../../../common/components/forms/formHeaderMedium';
import QCXInfoAlert from '../../../../../../../shared-components/alert/QCXInfoAlert';
import { convertToYYYYMMDD } from '../../../../../../../utils/general/dateTime-utils';

const useStyles = makeStyles(() => ({
  gridFields: {
    paddingBottom: '16px',
  },
  subtitle: {
    margin: '20px 0',
  },
}));

export default function CusteiosBasicasStepForm({
  initialValues,
  handleSubmit,
  isLocked,
  controlComponentProps,
  ...restProps
}: any) {
  const { t } = useTranslation();

  const [key, setKey] = useState(uuid());

  const [isDolar, setIsDolar] = useState(false);

  useEffect(() => {
    if(initialValues && initialValues.fatura && initialValues.fatura.mle && initialValues.fatura.mle.moeda) {
      let moeda = initialValues.fatura.mle.moeda.id;
      if(moeda == 153810) {
        setIsDolar(true);
      }
    }
  }, [])

  const mode = useSelector(selectMode);

  const classes = useStyles();

  const isConsult = useMemo(() => isConsultMode(mode), [mode]);

  const isCreate = useMemo(() => isCreateMode(mode), [mode]);

  const filterOptions = createFilterOptions({
    stringify: (item: any) => `${item.value}`,
  });

  const despesaReceitaReducerConfig = useMemo(
    () => ({
      selectors: {
        selectStatus: vinculoDespesaReceitaSelectors.selectStatus,
        selectMode: vinculoDespesaReceitaSelectors.selectMode,
        selectModel: vinculoDespesaReceitaSelectors.selectModel,
      },
      actions: {
        loading: vinculoDespesaReceitaActions.loading,
        resetStatus: vinculoDespesaReceitaActions.resetStatus,
        changeToUpdateMode: vinculoDespesaReceitaActions.changeToUpdateMode,
        changeToCreateMode: vinculoDespesaReceitaActions.changeToCreateMode,
        resetMode: vinculoDespesaReceitaActions.resetMode,
        setModel: vinculoDespesaReceitaActions.setModel,
        resetModel: vinculoDespesaReceitaActions.resetModel,
      },
    }),
    []
  );

  const despesaReceitaFormProps = useMemo(
    () => ({
      rootName: 'ignorableFields.lancamento',
      fields: [
        {
          name: 'despesaReceita.id',
          label: t('com.muralis.qcx.despesa.despesaReceita'),
        },
        {
          name: 'tipoRateio',
          label: t('com.muralis.qcx.tipoRateio'),
        },
        {
          name: 'valorReal',
          label: t('com.muralis.qcx.moeda.valorReais'),
        },
        {
          name: 'dataPagamento',
          label: 'Data Pagamento',
          required: false,
        },
        {
          name: 'efetivoOuPrevisto',
          label: 'Tipo Pagamento',
          required: false,
        },
        {
          name: 'despachanteImportador',
          label: 'Despesas Pagas por:',
          required: false,
        },
        {
          name: 'adiantamento',
          label: 'Adiantamento',
          required: false,
        }
      ],
    }),
    []
  );

  const rateioOptions = useMemo(
    () => [
      { id: 'MLE', value: 'MLE' },
      { id: 'FRETE', value: 'Frete' },
      { id: 'PESO_LIQUIDO', value: 'Peso Líquido' },
      { id: 'QUANTIDADE', value: 'Quantidade' },
    ],
    []
  );

  function formatEfetivoPrevisto(value: string) {
    if(value == '1') {
      return 'Previsto';
    } else if(value == '2') {
      return 'Efetivo';
    }else {
      return '';
    }
  }

  function formatResp(value: string) {
    if(value == '1') {
      return 'Despachante';
    } else if(value == '2') {
      return 'Importador';
    }else {
      return '';
    }
  }

  function formatAdto(value: string) {
    if(value == 'S') {
      return 'Sim';
    } else if(value == 'N') {
      return 'Não';
    }else {
      return '';
    }
  }

  const despesaReceitaListProps = useMemo(
    () => ({
      name: 'despesas',
      columns: [
        {
          field: 'despesaReceita.description',
          headerName: t('com.muralis.qcx.despesa.despesaReceita'),
          headerAlign: 'left',
          align: 'left',
          flex: 260,
          valueGetter: ({ row }: any) => row?.despesaReceita.description || '',
        },
        {
          field: 'tipoRateio',
          headerName: "Rateio",
          headerAlign: 'left',
          align: 'left',
          flex: 150,  
          valueGetter: ({ row }: any) => row?.tipoRateio || '',
        },
        {
          field: 'dataPagamento',
          headerName: 'Pgto',
          headerAlign: 'right',
          align: 'right',
          flex: 130,
          valueGetter: ({ row }: any) => formatDate(row?.dataPagamento),
        },
        {
          field: 'efetivoOuPrevisto',
          headerName: "Tipo",
          headerAlign: 'right',
          align: 'right',
          flex: 130,
          valueGetter: ({ row }: any) => formatEfetivoPrevisto(row?.efetivoOuPrevisto),
        },
        {
          field: 'despachanteImportador',
          headerName: "Resp",
          headerAlign: 'right',
          align: 'right',
          flex: 140,
          valueGetter: ({ row }: any) => formatResp(row?.despachanteImportador),
        },
        {
          field: 'adiantamento',
          headerName: "Adto",
          headerAlign: 'right',
          align: 'right',
          flex: 130,
          valueGetter: ({ row }: any) => formatAdto(row?.adiantamento),
        },
        {
          field: 'valorReal',
          headerName: "VLR BRL",
          headerAlign: 'right',
          align: 'right',
          flex: 180,
          valueGetter: ({ row }: any) => row?.valorReal,
          valueFormatter: ({ value }: any) => {
            const unnormalizedValue = unnormalizeNumeral(value, formatBrazilianNumericDecimal(2));

            if (unnormalizedValue) {
              return `R$ ${unnormalizedValue}`;
            }

            if (value) {
              return `R$ ${value}`;
            }

            return '-';
          },
        },
        {
          field: 'valorMoeda',
          headerName: "VLR Moeda",
          headerAlign: 'right',
          align: 'right',
          flex: 200,
          valueGetter: ({ row }: any) => {
            if(isDolar) {
              return row?.valorDolar
            } else {
              return row?.valorMoeda
            }
          },
          valueFormatter: ({ value }: any) => {
            const unnormalizedValue = unnormalizeNumeral(value, formatBrazilianNumericDecimal(2));

            if (unnormalizedValue) {
              return `$ ${unnormalizedValue}`;
            }

            if (value) {
              return `$ ${value}`;
            }

            return '-';
          },
        },
        {
          field: 'valorDolar',
          headerName: "VLR USD",
          headerAlign: 'right',
          align: 'right',
          flex: 180,
          valueGetter: ({ row }: any) => row?.valorDolar,
          valueFormatter: ({ value }: any) => {
            const unnormalizedValue = unnormalizeNumeral(value, formatBrazilianNumericDecimal(2));

            if (unnormalizedValue) {
              return `U$ ${unnormalizedValue}`;
            }

            if (value) {
              return `U$ ${value}`;
            }

            return '-';
          },
        },
      ],
    }),
    []
  );

  const handleAddDespesaReceitaVinculo = useCallback(
    async (currentVinculo: any, currentVinculos: any, handleSuccessAddVinculo: any) => {
      const despesaReceitaResponse = await despesaReceitaAPI.fetchById(currentVinculo?.despesaReceita?.id);

      if (despesaReceitaResponse?.status === 200) {
        const vinculo = {
          id: uuid(),
          ...currentVinculo,
          despesaReceita: {
            ...despesaReceitaResponse?.data,
          },
        };
        const updatedVinculos = [...currentVinculos, vinculo];

        handleSuccessAddVinculo(updatedVinculos);
      }
      setKey(uuid());
    },
    []
  );

  const handleUpdateDespesaReceitaVinculo = useCallback(
    async (currentVinculo: any, currentVinculos: any, handleSuccessUpdateVinculo: any) => {
      const despesaReceitaResponse = await despesaReceitaAPI.fetchById(currentVinculo?.despesaReceita?.id);

      if (despesaReceitaResponse?.status === 200) {
        const updatedVinculo = {
          ...currentVinculo,
          despesaReceita: {
            ...despesaReceitaResponse?.data,
          },
        };

        const updatedVinculos =
          currentVinculos?.map((existingVinculo: any) =>
            isStrictEquals(existingVinculo?.id, updatedVinculo?.id) ? updatedVinculo : existingVinculo
          ) || currentVinculos;

        handleSuccessUpdateVinculo(updatedVinculos);
      }
    },
    []
  );

  const handleDespesaReceitaAlreadyExists = useCallback(
    (currentVinculo: any, currentVinculosList: any) =>
      currentVinculosList.some(
        (vinculo: any) =>
          !isStrictEquals(vinculo?.id, currentVinculo?.id) &&
          isStrictEquals(vinculo?.despesaReceita?.id, currentVinculo?.despesaReceita?.id) &&
          isStrictEquals(vinculo?.totalMoeda, currentVinculo?.totalMoeda) &&
          isStrictEquals(vinculo?.totalReal, currentVinculo?.totalReal)
      ),
    []
  );

  return (
    <QCXFormStepWizard
      initialValues={initialValues}
      onSubmit={handleSubmit}
      controlComponentProps={controlComponentProps}
      disablePristine
      {...restProps}
    >
      {({ form, values }: { form: any; values: CusteioObject }) => {
        return (
          <>
            <QCXFormTitle title={t('com.muralis.qcx.fichaBasicas')} />
            <Grid container spacing={2} style={{ marginTop: '1rem' }}>
              <Grid item xs={4}>
                <QCXMoveFocusInside>
                  <QCXSelectFollowUpImportacaoAutocompleteField
                    key="select-autocomplete-processo-field"
                    id="select-autocomplete-processo-field"
                    name="followUp.id"
                    label={t('com.muralis.qcx.processo')}
                    validate={undefined}
                    isCreate={isCreate}
                    initialValues={values.followUp?.id}
                    disabled
                  />
                </QCXMoveFocusInside>
              </Grid>

              <Grid item xs={4}>
                <QCXSelectClienteAutocomplete
                  key="select-autocomplete-cliente-field"
                  id="select-autocomplete-cliente-field"
                  name="cliente.id"
                  label={t('com.muralis.qcx.cliente.label')}
                  validate={undefined}
                  isCreate={isCreate}
                  disabled
                  filter={undefined}
                  initialValues={values.cliente?.id}
                />
              </Grid>

              <Grid item xs={4}>
                <QCXSelectServicoAutocomplete
                  key="select-autocomplete-servico-field"
                  id="select-autocomplete-servico-field"
                  name="followUp.servico.id"
                  label={t('com.muralis.qcx.servico.labelSingular')}
                  validate={undefined}
                  isCreate={isCreate}
                  disabled
                  filter={undefined}
                  initialValues={values.followUp.servico?.id}
                  produto={undefined}
                />
              </Grid>

              <Grid item xs={4}>
                <QCXFinalTextField
                  key="text-numero-di-field"
                  id="text-numero-di-field"
                  name="fatura.atributosAdicionais.registro"
                  label={t('com.muralis.qcx.NDIDUIMP')}
                  validate={undefined}
                  isCreate={isCreate}
                  disabled
                  required={false}
                  type={undefined}
                  parse={undefined}
                  maxLength={undefined}
                  children={undefined}
                />
              </Grid>

              <Grid item xs={4}>
                <QCXFinalDatePickerField
                  key="date-registro-di-field"
                  id="date-registro-di-field"
                  name="fatura.atributosAdicionais.dataRegistroSiscomex"
                  label={t('com.muralis.qcx.dataRegistro')}
                  validate={undefined}
                  isCreate={isCreate}
                  disabled
                  type={undefined}
                  parse={undefined}
                  maxLength={undefined}
                  children={undefined}
                  required={false}
                />
              </Grid>

              <FormHeader>{t('com.muralis.qcx.despesa.despesaReceita')}</FormHeader>

              {isCreate && (
                <Grid item xs={12}>
                  <QCXInfoAlert severity="info" style={{ marginBottom: '1rem' }}>
                    Os impostos do processo serão inseridos automaticamente ao clicar em 'Próximo'
                  </QCXInfoAlert>
                </Grid>
              )}

              <QCXFinalBondManagerOld
                listProps={despesaReceitaListProps}
                formProps={despesaReceitaFormProps}
                reducerConfig={despesaReceitaReducerConfig}
                handleAdd={handleAddDespesaReceitaVinculo}
                handleUpdate={handleUpdateDespesaReceitaVinculo}
                handleAlreadyExists={handleDespesaReceitaAlreadyExists}
                disableButtons={isConsult}
                readOnlyMode={false}
                isParentConsult={undefined}
                handleRemove={undefined}
                onClearDecorator={undefined}
                onConsultDecorator={undefined}
                onAddDecorator={undefined}
                controlButtonsGridProps={undefined}
              >
                <>
                  <Grid item xs={12} sm={12} md={6}>
                    <QCXSelectDespesaReceitaAutocomplete
                      id="autocomplete-select-despesa-receita-capa-field"
                      key="autocomplete-select-despesa-receita-capa-field"
                      name="ignorableFields.lancamento.despesaReceita.id"
                      label={t('com.muralis.qcx.despesa.despesaReceita')}
                      initialValues={values}
                      disabled={isConsult}
                      filterParams={undefined}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <QCXAutocompleteSelect
                      id="autocomplete-select-field-tipo-rateio"
                      key={`autocomplete-select-field-tipo-rateio-${key}`}
                      name="ignorableFields.lancamento.tipoRateio"
                      label={t('com.muralis.qcx.tipoRateio')}
                      disabled={isConsult}
                      options={rateioOptions}
                      filterOptions={filterOptions}
                      defaultValue={''}
                      getOptionLabel={(item: any) => (item.value ? item.value : '')}
                      required={false}
                    />
                  </Grid>
                  <Grid item className={classes.gridFields} xs={12} sm={3} md={3}>
                    <QCXFinalAlternativeCurrencyField
                      id="currency-valor-solicitado-despesa-receita-capa-field"
                      key={`currency-valor-solicitado-despesa-receita-capa-field-${key}`}
                      name="ignorableFields.lancamento.valorReal"
                      label={t('com.muralis.qcx.moeda.valorReais')}
                      validate={maxValue(999999999999)}
                      disabled={isConsult}
                      formatOnBlur
                      maxLength={undefined}
                      children={undefined}
                      submitSucceeded={undefined}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <QCXFinalDatePickerField
                      key="data-pagamento"
                      id="data-pagamento"
                      name="ignorableFields.lancamento.dataPagamento"
                      label={"Data do Pagamento"}
                      validate={undefined}
                      isCreate={isCreate}
                      disabled={isConsult}
                      type={undefined}
                      parse={undefined}
                      maxLength={undefined}
                      children={undefined}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <QCXAutocompleteSelect
                      id="autocomplete-select-field-efetivo-ou-previsto"
                      key={`autocomplete-select-field-efetivo-ou-previsto-${key}`}
                      name="ignorableFields.lancamento.efetivoOuPrevisto"
                      label={"Tipo Pagamento"}
                      disabled={isConsult}
                      options={[{ id: '2', value: 'Efetivo'}, {id: '1', value: 'Previsto'}]}
                      defaultValue={''}
                      getOptionLabel={(item: any) => (item.value ? item.value : '')}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <QCXAutocompleteSelect
                      id="autocomplete-select-field-despachanteImportador"
                      key={`autocomplete-select-field-despachanteImportador-${key}`}
                      name="ignorableFields.lancamento.despachanteImportador"
                      label={"Despesas Pagas por:"}
                      disabled={isConsult}
                      options={[{ id: '1', value: 'Despachante'}, {id: '2', value: 'Importador'}]}
                      defaultValue={''}
                      getOptionLabel={(item: any) => (item.value ? item.value : '')}
                      required={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <QCXAutocompleteSelect
                      id="autocomplete-select-field-adiantamento"
                      key={`autocomplete-select-field-adiantamento-${key}`}
                      name="ignorableFields.lancamento.adiantamento"
                      label={"Adiantamento"}
                      disabled={isConsult}
                      options={[{ id: 'S', value: 'Sim'}, {id: 'N', value: 'Não'}]}
                      defaultValue={''}
                      getOptionLabel={(item: any) => (item.value ? item.value : '')}
                      required={false}
                    />
                  </Grid>
                </>
              </QCXFinalBondManagerOld>
              <FormHeaderMedium>{t('com.muralis.qcx.totais')}</FormHeaderMedium>
              <Grid item className={classes.gridFields} xs={12} sm={4} md={4}>
                <QCXFinalAlternativeCurrencyField
                  id="currency-valor-reais-despesa-receita-capa-field"
                  key="currency-valor-reais-despesa-receita-capa-field"
                  name="ignorableFields.totalReal"
                  label={t('com.muralis.qcx.moeda.moedaDespesasReais')}
                  intl={BRAZILIAN}
                  validate={maxValue(999999999999)}
                  adorned
                  disabled
                  maxLength={undefined}
                  children={undefined}
                  submitSucceeded={undefined}
                  required={false}
                />
              </Grid>
              <Grid item className={classes.gridFields} xs={12} sm={4} md={4}>
                <QCXFinalAlternativeCurrencyField
                  id="currency-valor-reais-despesa-receita-capa-field"
                  key="currency-valor-reais-despesa-receita-capa-field"
                  name="ignorableFields.totalMoeda"
                  label={t('com.muralis.qcx.moeda.moedaDespesasMoeda')}
                  intl={AMERICAN}
                  validate={maxValue(999999999999)}
                  adorned
                  disabled
                  maxLength={undefined}
                  required={false}
                  children={undefined}
                  submitSucceeded={undefined}
                />
              </Grid>
              <Grid item className={classes.gridFields} xs={12} sm={4} md={4}>
                <QCXFinalAlternativeCurrencyField
                  id="currency-valor-reais-despesa-receita-capa-field"
                  key="currency-valor-reais-despesa-receita-capa-field"
                  name="ignorableFields.totalDolar"
                  label={t('com.muralis.qcx.moeda.moedaDespesasDolar')}
                  intl={AMERICAN}
                  validate={maxValue(999999999999)}
                  adorned
                  disabled
                  maxLength={undefined}
                  required={false}
                  children={undefined}
                  submitSucceeded={undefined}
                />
              </Grid>

              <Grid item xs={12}>
                <QCXFormSubtitle className={classes.subtitle} title={t('com.muralis.qcx.referenciasCliente')} />
              </Grid>

              <Grid item xs={12}>
                <ReferenciaClienteBondManager isConsult />
              </Grid>
            </Grid>
          </>
        );
      }}
    </QCXFormStepWizard>
  );
}
