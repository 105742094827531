import { Button, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import FormHeaderMedium from '../../../common/components/forms/formHeaderMedium';
import { KEYCLOAK_TOKEN_TIMEOUT } from '../../../../App';
import { selectUnidadeSelecionada } from '../../../../features/usuario-logado/usuarioLogadoSlice';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';
import { ProgressBarStep } from '../../integracao/planilhas/components/progressModal';
import { useTranslation } from 'react-i18next';
import { setErrorFeedback, setSuccessFeedback } from '../../../../features/feedback/feedbackSlice';
import { useUnidadeNegocioGuard } from '../../../common/hooks/useUnidadeNegocioGuard';
import { generateSpreadsheetTransportadoras } from './relatorioTransportadorasPage.helpers';
import PageLayout from '../../../common/layouts/pageLayout';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';

interface Datas {
  dataInicio: string;
  dataFim: string;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    gap: '10px', // Ajuste o gap para controlar a distância entre os inputs
  },
  input: {
    width: '10%',
    marginRight: '1px', // Reduz a margem direita para aproximar os inputs
    borderRadius: '4px',
  },
  button: {
    borderRadius: '4px',
  },
});


const RelatorioTransportadorasPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const history = useHistory();
  const selectedUnidade: number = useSelector(selectUnidadeSelecionada);
  const { token } = keycloak;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<ProgressBarStep>({
    step: 0,
    description: t('com.muralis.qcx.integracao.Planilhas.empresasEstrangeiras.criandoEmpresasEstrangeiras'),
  });
  const [datas, setDatas] = useState<Datas>({
    dataInicio: '',
    dataFim: '',
  });

  keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
  useUnidadeNegocioGuard();

  const validarDatas = (datas: Datas): boolean => {
    if (!datas.dataInicio || !datas.dataFim) {
      return false;
    }

    const dataInicio = new Date(datas.dataInicio);
    const dataFim = new Date(datas.dataFim);

    return dataInicio <= dataFim;
  };

  const handleChangeDataInicio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDatas((prevDatas) => ({ ...prevDatas, dataInicio: e.target.value }));
  };

  const handleChangeDataFim = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDatas((prevDatas) => ({ ...prevDatas, dataFim: e.target.value }));
  };


  const handleSubmit = async () => {
    keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
    setIsModalOpen(true);


    if (!validarDatas(datas)) {
      dispatch(setErrorFeedback({
        message: 'Preencha as datas de início e fim corretamente.',
      }));
      return;
    }

    if (token && selectedUnidade) {
      try {
        const error : string = await generateSpreadsheetTransportadoras(datas.dataInicio, datas.dataFim, token, selectedUnidade);

        if (error != null) {
          dispatch(setErrorFeedback({
            message: error ? error : t('com.muralis.qcx.integracao.Planilhas.erros.erroAoGerarPlanilha'),
          }));
        } else {
          dispatch(setSuccessFeedback({
            message: t('com.muralis.qcx.integracao.Planilhas.sucesso.planilhaComSuceso')
          }));
        }
      } catch (error) {
        dispatch(setErrorFeedback({
          message: t('translation.com.muralis.qcx.integracao.Planilhas.erros.erroInesperado')
        }));
      }
      setIsModalOpen(false);
    }

    keycloak.updateToken(KEYCLOAK_TOKEN_TIMEOUT);
    handleNavigation("/relatorios");
  };

  const handleNavigation = (path: string | undefined) => {
    if (path) {
      history.push(path);
    }
  };

  return (
    <PageLayout
      title={'Relatório Transportadoras'}
      icon={<InsertDriveFileIcon color={'secondary'} />}
    >
      <Grid container spacing={2}>
        <FormHeaderMedium>Selecione as Datas para gerar o relatório</FormHeaderMedium>

        <Grid item xs={12}>
          <div className={classes.container}>
            <TextField
              color='primary'
              label="Data de Início"
              type="date"
              value={datas.dataInicio}
              onChange={handleChangeDataInicio}
              InputLabelProps={{ shrink: true }}
              className={classes.input}
            />

            <TextField
              color='primary'
              label="Data de Fim"
              type="date"
              value={datas.dataFim}
              onChange={handleChangeDataFim}
              InputLabelProps={{ shrink: true }}
              className={classes.input}
            />
          </div>
        </Grid>

        <Grid item sm={2} textAlign={'right'}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
            fullWidth
            disabled={!validarDatas(datas)}
          >
            Gerar Relatório
          </Button>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default RelatorioTransportadorasPage;