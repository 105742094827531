import { useKeycloak } from '@react-keycloak/web';
import { useMemo } from 'react';
import { KeycloakTokenParsedExtended } from './interfaces';

export function useGetUserEmail() {
  const { keycloak } = useKeycloak();

  const keyCloakToken = useMemo(() => {
    return keycloak?.tokenParsed as KeycloakTokenParsedExtended;
  }, [keycloak]);

  const userEmail = useMemo(() => {
    const email = keyCloakToken?.email;
    if (email.length <= 74) {
      return email;
    }

    return email.substring(0, 71);
  }, [keyCloakToken]);

  return userEmail ?? 'Email';
}
