import _ from 'lodash';
import { MASK_NUMERIC_DECIMAL, BRAZILIAN, AMERICAN, MASK_NUMERIC_INTEGER } from './mask-types';

const prune = (value) => _.trim(value);

const numberOnly = (value) =>
  String(value).replace(/^-?|\D/g, (match, offset) => (match === '-' && offset === 0 ? '-' : ''));

const parseToInteger = (
  value,
  config = {
    allowNegative: false,
  }
) => {
  if (config?.allowNegative) {
    const primaryPattern = /[^-0-9]/g;
    const secondaryPattern = /(?!^-)-/g;
    const parsed = String(value).replace(primaryPattern, '');
    const reforcedParse = parsed.replace(secondaryPattern, '');

    return reforcedParse;
  }

  const pattern = /[^0-9]/g;
  const parsed = String(value).replace(pattern, '');

  return parsed;
};

const parseToCharactersOnly = (value) => {
  const pattern = /[^A-z]/g;
  const parsed = String(value).replace(pattern, '');
  return parsed;
};

const parseToAlphanumericOnly = (value) => String(value).replace(/[^A-z0-9]/g, '');

const parseToUpperCaseAlphanumericOnly = (value) => parseToAlphanumericOnly(value).toUpperCase();

const parseToUpperCaseOnly = (value) => String(value).toUpperCase();

const parseToUpperCaseCharacterOnly = (value) => parseToCharactersOnly(value).toUpperCase();

const parseNumericDecimal = (
  value,
  config = {
    decimalSeparator: '.',
  }
) => {
  const { decimalSeparator } = config;
  const unnormalizedNumber = String(value).replace(/[^\d\\.\\,]/g, '');

  const indexOfDecimalSeparator = unnormalizedNumber.indexOf(decimalSeparator);
  const unnormalizedDigits = unnormalizedNumber.split('');

  const numberOfDecimalSeparator = unnormalizedDigits
    .map((digit) => (digit === decimalSeparator ? 1 : 0))
    .reduce((previous, current) => previous + current, 0);

  if (numberOfDecimalSeparator > 1) {
    const onlyNumbers = String(unnormalizedNumber).replace(/[^\d]/g, '');
    const digits = onlyNumbers.split('');

    const parsedNumber = digits.reduce(
      (previous, current, index) =>
        index === indexOfDecimalSeparator - 1 ? `${previous}${current}${decimalSeparator}` : `${previous}${current}`,
      ''
    );
    return parsedNumber;
  }

  return unnormalizedNumber;
};

const parseBrazilianNumericDecimal = (value) =>
  parseNumericDecimal(value, {
    decimalSeparator: ',',
  });

const parseAmericanNumericDecimal = (value) =>
  parseNumericDecimal(value, {
    decimalSeparator: '.',
  });

const parseWithMaxLength = (value, fn, maxLength) => {
  const parsed = fn(value);
  if (parsed.length > maxLength) {
    return parsed.slice(0, maxLength);
  }
  return parsed;
};

const parseToOnlyNumbersWithMaxDigits = (maxDigits) => (value) => parseWithMaxLength(value, numberOnly, maxDigits);

const parseToAlphanumericOnlyWithMaxLength = (maxLength) => (value) =>
  parseWithMaxLength(value, parseToAlphanumericOnly, maxLength);

const parseToUpperCaseAlphanumericOnlyWithMaxLength = (maxLength) => (value) =>
  parseWithMaxLength(value, parseToUpperCaseAlphanumericOnly, maxLength);

const parseToUpperCaseCharacterOnlyWithMaxLength = (maxLength) => (value) =>
  parseWithMaxLength(value, parseToUpperCaseCharacterOnly, maxLength);

const parseCpf = (value) => parseToOnlyNumbersWithMaxDigits(11)(value);

const parseCnpj = (value) => parseToOnlyNumbersWithMaxDigits(14)(value);

const parseCpfOrCnpj = (value) => parseToOnlyNumbersWithMaxDigits(14)(value);

const parseCep = (value) => parseToOnlyNumbersWithMaxDigits(8)(value);

const parseTelefone = (value) => parseToOnlyNumbersWithMaxDigits(13)(value);

const parseTelefoneOnlyWithDDD = (value) => parseToOnlyNumbersWithMaxDigits(11)(value);

const parseTelefoneFixoWithDDD = (value) => parseToOnlyNumbersWithMaxDigits(12)(value);

const parseTelefoneCelular = (value) => parseToOnlyNumbersWithMaxDigits(13)(value);

const parseRegistroDespachante = (value) => parseToUpperCaseAlphanumericOnlyWithMaxLength(9)(value);

const parseSiglaMoeda = (value) => parseToUpperCaseCharacterOnlyWithMaxLength(3)(value);

const configureIntegerParser =
  (allowNegative = false) =>
  (value) =>
    parseToInteger(value, {
      allowNegative,
    });

const parseGrauPlanoConta = (value) => parseToOnlyNumbersWithMaxDigits(3)(value);

const parseAgencia = (value) => parseToOnlyNumbersWithMaxDigits(5)(value);

const parseContaCorrente = (value) => parseToOnlyNumbersWithMaxDigits(10)(value);

const parseNumeroConhecimentoMaster = (value) => parseToAlphanumericOnlyWithMaxLength(12)(value);

const parsers = [
  {
    targets: [MASK_NUMERIC_DECIMAL],
    fn: parseNumericDecimal,
  },
  {
    targets: [MASK_NUMERIC_DECIMAL, BRAZILIAN],
    fn: parseBrazilianNumericDecimal,
  },
  {
    targets: [MASK_NUMERIC_DECIMAL, AMERICAN],
    fn: parseAmericanNumericDecimal,
  },
  {
    targets: MASK_NUMERIC_INTEGER,
    fn: configureIntegerParser,
  },
];

const parseLatitudelongitude = (value) => String(value).replace(/[^-\d\\.]+/g, '');

export default parsers;

export {
  prune,
  numberOnly,
  parseToInteger,
  parseToCharactersOnly,
  parseToAlphanumericOnly,
  parseToOnlyNumbersWithMaxDigits,
  parseToAlphanumericOnlyWithMaxLength,
  parseCpf,
  parseCnpj,
  parseCpfOrCnpj,
  parseCep,
  parseTelefone,
  parseTelefoneOnlyWithDDD,
  parseTelefoneFixoWithDDD,
  parseTelefoneCelular,
  parseRegistroDespachante,
  configureIntegerParser,
  parseGrauPlanoConta,
  parseSiglaMoeda,
  parseAgencia,
  parseContaCorrente,
  parseNumeroConhecimentoMaster,
  parseToUpperCaseAlphanumericOnlyWithMaxLength,
  parseToUpperCaseOnly,
  parseLatitudelongitude,
};
