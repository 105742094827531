import { v4 as uuid } from 'uuid';
import { useForm, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import QCXDataGrid from '../../shared-components/data-grid/QCXDataGrid';

export default function QCXClienteServicoDespesaReceitaGrid() {
  const { t } = useTranslation();

  const form = useForm();
  const { values } = useFormState();

  const relacaoClienteServicoDespesaReceitas = values?.relacaoClienteServicoDespesaReceitas ?? [];

  const normalizedClienteServicoDespesaReceitasRows = relacaoClienteServicoDespesaReceitas.reduce((acc, curr) => {
    const existingRow = acc.find((r) => r.servico.id === curr.servico.id);

    if (existingRow && curr.tipo === 'FATURAMENTO') {
      existingRow.quantidadeFaturamento += 1;
      return acc;
    }

    if (existingRow && curr.tipo === 'NUMERARIO') {
      existingRow.quantidadeNumerario += 1;
      return acc;
    }

    const newRow = {
      id: uuid(),
      servico: curr.servico,
      quantidadeNumerario: curr.tipo === 'NUMERARIO' ? 1 : 0,
      quantidadeFaturamento: curr.tipo === 'FATURAMENTO' ? 1 : 0,
      quantidadeTabelasSda: 0,
    };

    return [...acc, newRow];
  }, []);

  const relacaoClienteServicoTabelasSda = values?.relacaoClienteServicoTabelasSda ?? [];

  const normalizedRows = relacaoClienteServicoTabelasSda.reduce((acc, curr) => {
    const existingRow = acc.find((r) => r.servico.id === curr.servico.id);

    if (existingRow) {
      existingRow.quantidadeTabelasSda += 1;
      return acc;
    }

    const newRow = {
      id: uuid(),
      servico: curr.servico,
      quantidadeNumerario: 0,
      quantidadeFaturamento: 0,
      quantidadeTabelasSda: 1,
    };

    return [...acc, newRow];
  }, normalizedClienteServicoDespesaReceitasRows);

  const columns = [
    {
      field: 'servico',
      headerName: 'Serviço',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      valueGetter: ({ row }) => row?.servico?.description ?? '-',
    },
    {
      field: 'quantidadeNumerario',
      headerName: 'Despesas/Receitas de Numerário',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      valueGetter: ({ row }) => row?.quantidadeNumerario,
    },
    {
      field: 'quantidadeFaturamento',
      headerName: 'Despesas/Receitas de Faturamento',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      valueGetter: ({ row }) => row?.quantidadeFaturamento,
    },
  ];

  const handleRowClick = ({ row }) => {
    form.change('servico.id', row.servico.id);
  };

  return (
    <QCXDataGrid
      columns={columns}
      rows={normalizedRows}
      onRowClick={handleRowClick}
      localeText={{
        noRowsLabel: t('com.muralis.qcx.validacao.nenhumItemEncontrado'),
      }}
    />
  );
}
