import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import { AUTO_LOAD_CONTROL } from '../config-control';
import { isSubCreateMode } from '../../utils/store/store-utils';
import {
  BACKGROUND_CREATE_MODE,
  CONSULT_MODE,
  CREATE_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  UPDATE_MODE,
  NONE_SUB_MODE,
  SUB_CREATE_MODE,
  SUB_CONSULT_MODE,
  SUB_UPDATE_MODE,
} from '../mode';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  activateById, fetchAll, fetchByFilter, fetchById, inactivateById,
} from './transportadoraAPI';
import i18n from '../../i18n';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
  },
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  list: [],
  config: {
    control: AUTO_LOAD_CONTROL,
  },
  model: {
    clientes: [],
    contatos: [],
  },
  related: {
    model: {
      contato:  {},
    },
  },
};

const fetchAllAsync = createAsyncThunk(
  'transportadora/fetchAll',
  async () => {
    const { data } = await fetchAll();
    return { data };
  }
);

const fetchByFilterAsync = createAsyncThunk(
  'transportadora/fetchByFilter',
  async (params) => {
    const { data } = await fetchByFilter(params);
    return { data };
  }
);
const fetchByIdAsync = createAsyncThunk(
  'transportadora/fetchById',
  async (data) => {
    const response = await fetchById(data);
    return {
      response: {
        status: response.status,
        data: response.data,
      },
    };
  }
);

const activateByIdAsync = createAsyncThunk(
  'transportadora/activateById',
  async (id) => {
    const { status, data } = await activateById(id);
    return { response: { status, data } };
  }
);

const inactivateByIdAsync = createAsyncThunk(
  'transportadora/inactivateById',
  async (id) => {
    const { status, data } = await inactivateById(id);
    return { response: { status, data } };
  }
);

const transportadoraSlice = createSlice({
  name: 'transportadora',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    changeModeTo: (state, action) => {
      state.mode.main = action.payload;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    setClientes: (state, action) => {
      state.model.clientes = [...action.payload];
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    resetRelatedContatoModel: (state) => {
      state.related.model.contato = {};

      if (!isSubCreateMode(state.subMode)) {
        state.mode.sub = NONE_SUB_MODE;
      }
    },
    setRelatedContatoModel: (state, action) => {
      state.related.model.contato = {
        ...action.payload,
      };
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
      state.related.model.contato = {};
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    setContatos: (state, action) => {
      state.model.contatos = [...action.payload];
    },
    addContato: (state, action) => {
      state.model.contatos = [
        ...state.model.contatos,
        {
          ...action.payload.data,
          id: uuid(),
        },
      ];
      state.related.model.contato = {};
      state.mode.sub = NONE_SUB_MODE;
    },
    updateContato: (state, action) => {
      const updated = action.payload.data;
      state.model.contatos = state.model.contatos.map((current) => (current?.id === updated?.id ? updated : current));
      state.mode.sub = SUB_CONSULT_MODE;
    },
    removeContatoById: (state, action) => {
      const { id } = action.payload.data;
      state.model.contatos = state.model.contatos.filter((current) => current?.id !== id);
      state.related.model.contato = initialState.related.model.contato;
      state.mode.sub = NONE_SUB_MODE;
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    resetList: (state) => {
      state.list = [];
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    changeControlTo: (state, action) => {
      state.config.control = action.payload;
    },
    resetControl: (state) => {
      state.config.control = initialState.config.control;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaTransportadoras', { erro: action.error.message }),
        };
      })
      .addCase(fetchByFilterAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchByFilterAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.list = action.payload.data;
      })
      .addCase(fetchByFilterAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaTransportadoras', { erro: action.error.message }),
        };
      })
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = action.payload.response.data;
          state.status = SUCCESS_STATUS;
        }
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarDadosTransportadora', { erro: action.error.message }),
        };
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { pessoaJuridica } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.transportadoraAtivada', { transportadora: pessoaJuridica?.nome });

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroAtivarTransportadora', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { pessoaJuridica } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.transportadoraInativada', { transportadora: pessoaJuridica?.nome });

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroInativarTransportadora', { erro: action.error.message }),
        };
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  setModel,
  setClientes,
  changeToSubCreateMode,
  changeToSubConsultMode,
  setContatos,
  addContato,
  updateContato,
  resetSubMode,
  resetRelatedContatoModel,
  setRelatedContatoModel,
  changeToSubUpdateMode,
  removeContatoById,
  resetModel,
  addToList,
  updateOnList,
  resetList,
  setConfig,
  changeControlTo,
  resetControl,
  setResponse,
  clearResponse,
  setError,
  clearError,
} = transportadoraSlice.actions;

const transportadoraActions = {
  ...transportadoraSlice.actions,
  fetchAllAsync,
  fetchByFilterAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
};

const selectStatus = (state) => state.transportadora.status;
const selectMode = (state) => state.transportadora.mode.main;
const selectBackgroundMode = (state) => state.transportadora.mode.background;
const selectError = (state) => state.transportadora.error;
const selectResponse = (state) => state.transportadora.response;
const selectConfigControl = (state) => state.transportadora.config.control;
const selectTransportadoras = (state) => state.transportadora.list;
const selectTransportadora = (state) => state.transportadora.model;
const selectSubMode = (state) => state.transportadora.mode.sub;
const selectRelatedContatoModel = (state) => state.transportadora.related.model.contato;

export {
  transportadoraSlice,
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  resetStatus,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  resetMode,
  changeToBackgroundCreateMode,
  resetBackgroundMode,
  setModel,
  setClientes,
  setContatos,
  addContato,
  changeToSubCreateMode,
  changeToSubConsultMode,
  updateContato,
  resetSubMode,
  resetRelatedContatoModel,
  setRelatedContatoModel,
  changeToSubUpdateMode,
  removeContatoById,
  resetModel,
  addToList,
  updateOnList,
  resetList,
  setConfig,
  changeControlTo,
  resetControl,
  setResponse,
  clearResponse,
  setError,
  clearError,
  fetchAllAsync,
  fetchByFilterAsync,
  fetchByIdAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  transportadoraActions,
  selectTransportadoras,
  selectTransportadora,
  selectStatus,
  selectMode,
  selectBackgroundMode,
  selectError,
  selectResponse,
  selectConfigControl,
  selectSubMode,
  selectRelatedContatoModel,
};

export default transportadoraSlice.reducer;
