import { useCallback, useMemo } from 'react';

const RELACAO_FABRICANTE_EXPORTADOR = 'FABRICANTE_EXPORTADOR';
const RELACAO_FABRICANTE_NAO_EXPORTADOR = 'FABRICANTE_NAO_EXPORTADOR';
const RELACAO_FABRICANTE_DESCONHECIDO = 'FABRICANTE_DESCONHECIDO';

function useFornecedorListenersGroup(
  config = {
    dadosComumFornecedor: true,
  }
) {
  const handleExportadorIdChange = useCallback((form, values) => (value) => {
    if (values?.relacao === RELACAO_FABRICANTE_EXPORTADOR) {
      form.change('fabricante', undefined);
      form.change('fabricante.id', value);
      return;
    }
    if (values?.relacao === RELACAO_FABRICANTE_NAO_EXPORTADOR) {
      if (values?.fabricante?.id !== value) {
        return;
      }
      form.change('fabricante', undefined);
      return;
    }
    if (values?.relacao === RELACAO_FABRICANTE_DESCONHECIDO) {
      form.change('fabricante', undefined);
      return;
    }
    form.change('exportador', undefined);
  }, [
    RELACAO_FABRICANTE_EXPORTADOR,
    RELACAO_FABRICANTE_NAO_EXPORTADOR,
    RELACAO_FABRICANTE_DESCONHECIDO,
  ]);

  const handleDadosComumFornecedorChange = useCallback((form) => () => {
    form.change('sobrescreveDadosPartnumber', 0);
  }, []);

  const onChangeListeners = useMemo(() => ([
    {
      name: 'exportador.id',
      fn: handleExportadorIdChange,
    },
    ...(config?.dadosComumFornecedor
      ? [{
        name: 'dadosComumFornecedor',
        fn: handleDadosComumFornecedorChange,
      }]
      : []
    )
  ]), [
    config,
    handleExportadorIdChange,
    handleDadosComumFornecedorChange,
  ]);

  return onChangeListeners;
}

export {
  useFornecedorListenersGroup,
  RELACAO_FABRICANTE_EXPORTADOR,
  RELACAO_FABRICANTE_NAO_EXPORTADOR,
  RELACAO_FABRICANTE_DESCONHECIDO,
};
