export type ApplicationPermission =
  | ASSESSORIA_FOLLOWUP
  | OCORRENCIA
  | IMPORTACAO_FOLLOWUP
  | MODULOS
  | GERARXML
  | PAGAMENTODI;

export enum ASSESSORIA_FOLLOWUP {
  ALTERAR = 'assessoria-follow-up-alterar',
  EXCLUIR = 'assessoria-follow-up-excluir',
  INCLUIR = 'assessoria-follow-up-incluir',
  VISUALIZAR = 'assessoria-follow-up-visualizar',
}

export enum MODULOS {
  CADASTROS = 'CADASTROS',
  IMPORTACAO = 'IMPORTACAO',
  EXPORTACAO = 'EXPORTACAO',
  FINANCEIRO = 'FINANCEIRO',
}

export enum IMPORTACAO_FOLLOWUP {
  VISUALIZAR = 'importacao-follow-up-visualizar',
}

export enum OCORRENCIA {
  ALTERAR = 'ocorrencia-alterar',
  EXCLUIR = 'ocorrencia-excluir',
  INCLUIR = 'ocorrencia-incluir',
  VISUALIZAR = 'ocorrencia-visualizar',
}

export enum DESVIO {
  ALTERAR = 'desvio-alterar',
  EXCLUIR = 'desvio-excluir',
  INCLUIR = 'desvio-incluir',
  VISUALIZAR = 'desvio-visualizar',
}

export enum GERARXML {
  REGISTRODI = 'gera-xml-di-registro-visualizar',
  DIAGNOSTICODI = 'gera-xml-di-diagnostico-visualizar',
  REGISTROLI = 'gera-xml-li-registro-visualizar',
  SUBSTITUTIVALI = 'gera-xml-substitutiva-li-visualizar',
}

export enum PAGAMENTODI {
  ALTERAR = 'pagamento-di-alterar',
  EXCLUIR = 'pagamento-di-excluir',
  INCLUIR = 'pagamento-di-incluir',
  VISUALIZAR = 'pagamento-di-visualizar',
}

export enum TRANSMISSAO {
  DIAGNOSTICODI = 'transmissao-di-diagnostico-visualizar',
  REGISTRODI = 'transmissao-di-registro-visualizar',
}