import { ca } from 'date-fns/locale';
import { validateQueryParams, ReponseType } from '../../utils/api/api-utils';
import {
  doGetQCXRequest,
  doPostQCXRequest,
  doPutQCXActivateRequest,
  doPutQCXInactivateRequest,
  doPutQCXRequest,
} from '../../utils/api/domain/domain-api-utils';
import {
  LICENCA_IMPORTACAO_ENDPOINT,
  QCX_RELATORIO_ENDPOINT,
  FILTER_ENDPOINT,
  FATURA_ENDPOINT,
} from '../../utils/api/domain/domain-endpoint-utils';

async function fetchAll() {
  return doGetQCXRequest([LICENCA_IMPORTACAO_ENDPOINT]);
}

async function fetchByFilter(params = []) {
  const validatedQueryParams = validateQueryParams(params);

  return doGetQCXRequest([LICENCA_IMPORTACAO_ENDPOINT, FILTER_ENDPOINT], validatedQueryParams);
}

async function fetchById(id) {
  return doGetQCXRequest([LICENCA_IMPORTACAO_ENDPOINT, id]);
}
async function generateReportById(id) {
  return doGetQCXRequest([LICENCA_IMPORTACAO_ENDPOINT, QCX_RELATORIO_ENDPOINT, id], [], {
    responseType: ReponseType.ARRAY_BUFFER,
  });
}

async function register(data, queryParams = []) {
  return doPostQCXRequest([LICENCA_IMPORTACAO_ENDPOINT], data, queryParams);
}

async function save(data, queryParams = []) {
  return doPutQCXRequest([LICENCA_IMPORTACAO_ENDPOINT], data, queryParams);
}

async function activateById(id) {
  return doPutQCXActivateRequest([LICENCA_IMPORTACAO_ENDPOINT], id);
}

async function inactivateById(id) {
  return doPutQCXInactivateRequest([LICENCA_IMPORTACAO_ENDPOINT], id);
}

async function fetchTransmissaoLiByIdAndTipo(id, tipo) {
  const url = `xml-transmissao/${id}?TIPO=${tipo}`;

  try {
    const response = await doGetQCXRequest([LICENCA_IMPORTACAO_ENDPOINT, url]);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function calculateLisEmLote(data) {
  try {
    const response = await doPutQCXRequest([FATURA_ENDPOINT, 'calcular', 'lis'], data);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function releaseLisEmLote(data) {
  try {
    const response = await doPutQCXRequest([FATURA_ENDPOINT, 'liberar', 'lis'], data);
    return response;
  } catch (error) {
    return error.response;
  }
}

async function gerarXmlLisEmLote(data) {
  try {
    const response = await doPutQCXRequest(['li', 'xml-report', 'lotes'], data, [], {
      responseType: 'blob',
    });
    return response;
  } catch (error) {
    return error.response;
  }
}

const licencaImportacaoAPI = {
  fetchAll,
  fetchByFilter,
  fetchById,
  register,
  save,
  activateById,
  inactivateById,
  generateReportById,
  fetchTransmissaoLiByIdAndTipo,
  calculateLisEmLote,
  releaseLisEmLote,
  gerarXmlLisEmLote,
};

export {
  licencaImportacaoAPI,
  fetchAll,
  fetchByFilter,
  fetchById,
  register,
  save,
  activateById,
  inactivateById,
  generateReportById,
  fetchTransmissaoLiByIdAndTipo,
  calculateLisEmLote,
  releaseLisEmLote,
  gerarXmlLisEmLote,
};
