/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../../../../../../shared-components/dados-embarque/base-components/BaseSelectWithOptions';

function SelectCanal({ ...props }: any) {
  const list = useMemo(
    () => [
      {
        id: 'VERDE',
        label: 'VERDE',
        value: 'VERDE',
      },
      {
        id: 'AMARELO',
        label: 'AMARELO',
        value: 'AMARELO',
      },
      {
        id: 'VERMELHO',
        label: 'VERMELHO',
        value: 'VERMELHO',
      },
      {
        id: 'CINZA',
        label: 'CINZA',
        value: 'CINZA',
      }
    ],
    []
  );

  return <BaseSelectWithOptions list={list} {...props} />;
}

export default SelectCanal;
