import { Card, CardContent, Grid, Grow, Typography, useTheme } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useKeycloak } from '@react-keycloak/web';

export type GridItemModalProps = {
  title: string;
  isOpen: boolean;
  closeModal: () => void;
};

export interface CardsModalGridItem {
  title: string;
  icon: SvgIconComponent;
  modalName: string;
  modalComponent: React.ComponentType<GridItemModalProps>;
  subItems?: boolean;
  disabled?: boolean;
  requiredRoles?: string[];
  requiredGroups?: string[];
}

type CardsModalGridProps = {
  items: CardsModalGridItem[];
  modalOpenName: string;
  openModalByName: (name: string) => () => void;
  closeModal: () => void;
};

const CardsModalGrid = ({ items, modalOpenName, openModalByName, closeModal }: CardsModalGridProps) => {
  const theme = useTheme();
  const history = useHistory();
  const keycloak = useKeycloak();
  //@ts-ignore
  const userGroups = keycloak?.keycloak?.tokenParsed?.attributes.grupo;
  //@ts-ignore
  const userRoles = keycloak?.keycloak?.tokenParsed?.resource_access.api.roles;

  const allowedItems = items.filter((item) => {
    if (item.requiredGroups) {
      return userGroups?.some((group: string) => item.requiredGroups?.includes(group));
    }
    if (item.requiredRoles) {
      return userRoles?.some((role: string) => item.requiredRoles?.includes(role));
    }
    return true;
  });

  return (
    <Grid container spacing={2} padding={1}>
      {allowedItems.map((item, index) => {
        const ModalComponent = item.modalComponent;

        return (
          <>
            <Grid item xs={6} sm={4} md={3} key={index} style={{ maxWidth: '330px' }}>
              <Grow mountOnEnter timeout={500} in>
                <Card
                  variant="outlined"
                  style={{
                    border: `1px solid ${theme.palette.secondary.main}`,
                    textAlign: 'center',
                    padding: '20px 0px',
                    cursor: 'pointer',
                    height: '170px',
                  }}
                  sx={{ boxShadow: 3 }}
                  onClick={!item.disabled ? openModalByName(item.modalName) : undefined}
                >
                  <CardContent
                    sx={{
                      marginTop: item.subItems ? '-10px' : '0px',
                    }}
                  >
                    <>
                      <item.icon color={item.disabled ? 'disabled' : 'secondary'} sx={{ fontSize: 47 }}></item.icon>
                      <Typography fontSize={12}>{item.title.toUpperCase()}</Typography>
                      {item.subItems && <ExpandMoreIcon color={'secondary'} sx={{ fontSize: 32 }} />}
                    </>
                  </CardContent>
                </Card>
              </Grow>
            </Grid>

            <ModalComponent title={item.title} isOpen={modalOpenName === item.modalName} closeModal={closeModal} />
          </>
        );
      })}
    </Grid>
  );
};

export default CardsModalGrid;
