import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetFeedback, selectFeedback } from '../../features/feedback/feedbackSlice';
import QCXProgressBackdrop from '../../shared-components/backdrop/QCXProgressBackdrop';
import QCXModeContextProvider from '../../shared-components/context-provider/QCXModeContextProvider';
import QCXSnackbar from '../../shared-components/snackbar/QCXSnackbar';

export default function QCXPageTemplate({ contextComponent, modeSelectors = {}, children }) {
  const dispatch = useDispatch();

  const { isLoading } = useContext(contextComponent);

  const feedback = useSelector(selectFeedback);

  const handleCloseSnackbar = useCallback(() => {
    if (feedback?.show) {
      dispatch(resetFeedback());
    }
  }, [feedback]);

  return (
    <QCXModeContextProvider value={modeSelectors}>
      {children}
      {/* Novo formato de feedbacks utiliza apenas o feedbackSnackbar 
      {successSnackbar?.open && (
        <QCXSuccessSnackbar
          open={successSnackbar?.open}
          content={successSnackbar?.message}
          handleClose={handleCloseSuccessSnackbar}
          {...successSnackbar}
        />
      )}
      {errorSnackbar?.open && (
        <QCXErrorSnackbar
          open={errorSnackbar?.open}
          content={errorSnackbar?.message}
          handleClose={handleCloseErrorSnackbar}
          {...errorSnackbar}
        />
      )} */}
      {feedback?.show && (
        <QCXSnackbar
          open={feedback?.show}
          severity={feedback?.severity}
          content={feedback?.message}
          onClose={handleCloseSnackbar}
          disableScrollLock
          {...feedback}
        />
      )}
      <QCXProgressBackdrop open={isLoading} />
    </QCXModeContextProvider>
  );
}
