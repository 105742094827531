import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../dados-embarque/base-components/BaseSelectWithOptions';

function SelectFormaCalculoSeguro({ ...props }) {
  const list = useMemo(
    () => [
      {
        id: 'MLE',
        label: 'MLE',
        value: 'MLE',
      },
      {
        id: 'MLE_FRETE',
        label: 'MLE + Frete',
        value: 'MLE_FRETE',
      },
      {
        id: 'MLE_ACRESCIMO',
        label: 'MLE + Acréscimo',
        value: 'MLE_ACRESCIMO',
      },
      {
        id: 'MLE_FRETE_ACRESCIMO',
        label: 'MLE + Frete + Acréscimo',
        value: 'MLE_FRETE_ACRESCIMO',
      },
    ],
    []
  );

  return <BaseSelectWithOptions list={list} {...props} />;
}

export default SelectFormaCalculoSeguro;
