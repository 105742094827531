const selectFaturamentos = (state) => state.faturamento.list;
const selectFaturamento = (state) => state.faturamento.model;
const selectRelatedPagosRecebidos = (state) => state.faturamento.pagosRecebidos;
const selectStatus = (state) => state.faturamento.status;
const selectRefresh = (state) => state.faturamento.refresh;
const selectMode = (state) => state.faturamento.mode.main;
const selectBackgroundMode = (state) => state.faturamento.mode.background;
const selectSubMode = (state) => state.faturamento.mode.sub;
const selectError = (state) => state.faturamento.error;
const selectResponse = (state) => state.faturamento.response;

const selectHistoryModel = (state) => state.faturamento.historyModel;

const selectRelatedReportModel = (state) => state.faturamento.related.model.report;

const selectImpostos = (state) => state.faturamento.impostos;

const faturamentoSelectors = {
  selectRelatedPagosRecebidos,
  selectHistoryModel,
  selectFaturamentos,
  selectFaturamento,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectError,
  selectResponse,
  selectRelatedReportModel,
  selectImpostos,
};

export {
  selectRelatedPagosRecebidos,
  selectHistoryModel,
  faturamentoSelectors,
  selectFaturamentos,
  selectFaturamento,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectError,
  selectResponse,
  selectRelatedReportModel,
  selectImpostos,
};
