import { createSlice } from '@reduxjs/toolkit';
import i18n from '../../i18n';
import {
  BACKGROUND_CONSULT_MODE,
  BACKGROUND_CREATE_MODE,
  BACKGROUND_DELETE_MODE,
  BACKGROUND_UPDATE_MODE,
  CONSULT_MODE,
  CREATE_MODE,
  EXTERNAL_MODE,
  LOCKED_MODE,
  NONE_BACKGROUND_MODE,
  NONE_MODE,
  NONE_SUB_BACKGROUND_MODE,
  NONE_SUB_MODE,
  SUB_CONSULT_MODE,
  SUB_CREATE_MODE,
  SUB_UPDATE_MODE,
  UPDATE_MODE,
} from '../mode';
import { NO_REFRESH, REFRESH } from '../refresh';
import {
  ALTERNATIVE_LOADING_STATUS,
  FAILURE_STATUS,
  IDLE_STATUS,
  LOADING_STATUS,
  PERFORMING_ACTION_STATUS,
  PREPARING_ACTION_STATUS,
  SUCCESS_STATUS,
} from '../status';
import {
  activateByIdAsync,
  fetchAllAsync,
  fetchAllExportacaoNumerarioByFilterPaginationAsync,
  fetchAllImportacaoNumerarioByFilterPaginationAsync,
  fetchByIdAsync,
  inactivateByIdAsync,
  notifyByIdAsync,
} from './numerarioImportacaoThunks';

const initialState = {
  status: IDLE_STATUS,
  mode: {
    main: NONE_MODE,
    background: NONE_BACKGROUND_MODE,
    sub: NONE_SUB_MODE,
    subBackground: NONE_SUB_BACKGROUND_MODE,
  },
  refresh: NO_REFRESH,
  error: null,
  response: {
    status: null,
    message: null,
    data: null,
  },
  config: {},
  list: [],
  model: {},
  related: {
    model: {
      email: {},
    },
  },
};

const numerarioImportacaoSlice = createSlice({
  name: 'numerarioImportacao',
  initialState,
  reducers: {
    changeStatusTo: (state, action) => {
      state.status = action.payload.status;
    },
    loading: (state) => {
      state.status = LOADING_STATUS;
    },
    success: (state) => {
      state.status = SUCCESS_STATUS;
    },
    failure: (state) => {
      state.status = FAILURE_STATUS;
    },
    preparingAction: (state) => {
      state.status = PREPARING_ACTION_STATUS;
    },
    performingAction: (state) => {
      state.status = PERFORMING_ACTION_STATUS;
    },
    resetStatus: (state) => {
      state.status = IDLE_STATUS;
    },
    refresh: (state) => {
      state.refresh = REFRESH;
    },
    resetRefresh: (state) => {
      state.refresh = NO_REFRESH;
    },
    changeModeTo: (state, action) => {
      state.mode.main = action.payload;
    },
    changeToCreateMode: (state) => {
      state.mode.main = CREATE_MODE;
    },
    changeToConsultMode: (state) => {
      state.mode.main = CONSULT_MODE;
    },
    changeToUpdateMode: (state) => {
      state.mode.main = UPDATE_MODE;
    },
    changeToLockedMode: (state) => {
      state.mode.main = LOCKED_MODE;
    },
    changeToExternalMode: (state) => {
      state.mode.main = EXTERNAL_MODE;
    },
    resetMode: (state) => {
      state.mode.main = NONE_MODE;
    },
    changeToBackgroundCreateMode: (state) => {
      state.mode.background = BACKGROUND_CREATE_MODE;
    },
    changeToBackgroundConsultMode: (state) => {
      state.mode.background = BACKGROUND_CONSULT_MODE;
    },
    changeToBackgroundUpdateMode: (state) => {
      state.mode.background = BACKGROUND_UPDATE_MODE;
    },
    changeToBackgroundDeleteMode: (state) => {
      state.mode.background = BACKGROUND_DELETE_MODE;
    },
    resetBackgroundMode: (state) => {
      state.mode.background = NONE_BACKGROUND_MODE;
    },
    changeToSubCreateMode: (state) => {
      state.mode.sub = SUB_CREATE_MODE;
    },
    changeToSubConsultMode: (state) => {
      state.mode.sub = SUB_CONSULT_MODE;
    },
    changeToSubUpdateMode: (state) => {
      state.mode.sub = SUB_UPDATE_MODE;
    },
    resetSubMode: (state) => {
      state.mode.sub = NONE_SUB_MODE;
    },
    setModel: (state, action) => {
      state.model = {
        ...action.payload,
      };
    },
    resetModel: (state) => {
      state.model = initialState.model;
    },
    addToList: (state, action) => {
      state.list = [...state.list, action.payload.data];
    },
    updateOnList: (state, action) => {
      state.list = state.list.map((current) => {
        if (current.id === action.payload.data?.id) {
          return action.payload.data;
        }
        return current;
      });
    },
    setRelatedEmailModel: (state, action) => {
      state.related.model.email = {
        ...action.payload,
      };
    },
    resetRelatedEmailModel: (state) => {
      state.related.model.email = {};
    },
    changeLastHistoryModel: (state, action) => {
      state.historyModel.last = action.payload;
    },
    resetHistoryModel: (state) => {
      state.historyModel = {};
    },
    resetLastHistoryModel: (state) => {
      state.historyModel.last = {};
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    clearResponse: (state) => {
      state.response = {
        status: null,
        message: null,
        data: null,
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(fetchByIdAsync.fulfilled, (state, action) => {
        if (action.payload.response.status === 200) {
          state.model = {
            ...action.payload.response.data,
          };
          state.status = SUCCESS_STATUS;

          return;
        }

        state.status = IDLE_STATUS;
      })
      .addCase(fetchByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarNumerario', { erro: action.error.message }),
        };
      })
      .addCase(fetchAllAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllAsync.fulfilled, (state, action) => {
        state.list = action.payload.data;
        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
      })
      .addCase(fetchAllAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroCarregarListaNumerarios', { erro: action.error.message }),
        };
        state.refresh = NO_REFRESH;
      })
      .addCase(activateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(activateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { numero } = action.payload.response.data;

        state.response.message = i18n.t('com.muralis.qcx.mensagem.numerarioAtivado', {
          numero,
        });

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(activateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroAtivarNumerario', { erro: action.error.message }),
        };
      })
      .addCase(inactivateByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(inactivateByIdAsync.fulfilled, (state, action) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;

        const { atributosAdicionais } = action.payload.response.data;
        state.response.message = i18n.t('com.muralis.qcx.mensagem.numerarioAtivado', {
          numero: atributosAdicionais?.numero,
        });

        if (state.response.status === 200) {
          state.list = state.list
            .map((current) => (
              current.id === action.payload.response.data.id
                ? action.payload.response.data
                : current
            ));
        }
      })
      .addCase(inactivateByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: i18n.t('com.muralis.qcx.erro.erroExcluirNumerario', { erro: action.error.message }),
        };
      })
      .addCase(notifyByIdAsync.pending, (state) => {
        state.status = LOADING_STATUS;
      })
      .addCase(notifyByIdAsync.fulfilled, (state, action ) => {
        state.status = SUCCESS_STATUS;
        state.response.status = action.payload.response.status;
        state.response.data = action.payload.response.data;
      })
      .addCase(notifyByIdAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: 'Erro ao notificar numerário.',
        };
      })
      .addCase(fetchAllImportacaoNumerarioByFilterPaginationAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllImportacaoNumerarioByFilterPaginationAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
        state.list = action?.payload?.data?.itens;
        state.count = action?.payload?.data?.totalNumberOfItens;
      })
      .addCase(fetchAllImportacaoNumerarioByFilterPaginationAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: 'Erro ao carregar lista de numerários.',
        };
        state.refresh = NO_REFRESH;
      })
      // Talvez faça sentido separar esses estados um dia
      .addCase(fetchAllExportacaoNumerarioByFilterPaginationAsync.pending, (state) => {
        state.status = ALTERNATIVE_LOADING_STATUS;
      })
      .addCase(fetchAllExportacaoNumerarioByFilterPaginationAsync.fulfilled, (state, action) => {
        state.status = IDLE_STATUS;
        state.refresh = NO_REFRESH;
        state.list = action?.payload?.data?.itens;
        state.count = action?.payload?.data?.totalNumberOfItens;
      })
      .addCase(fetchAllExportacaoNumerarioByFilterPaginationAsync.rejected, (state, action) => {
        state.status = FAILURE_STATUS;
        state.error = {
          ...action.error,
          message: 'Erro ao carregar lista de numerários.',
        };
        state.refresh = NO_REFRESH;
      });
  },
});

const {
  changeStatusTo,
  loading,
  success,
  failure,
  preparingAction,
  performingAction,
  resetStatus,
  refresh,
  resetRefresh,
  changeModeTo,
  changeToCreateMode,
  changeToConsultMode,
  changeToUpdateMode,
  changeToLockedMode,
  changeToExternalMode,
  resetMode,
  changeToBackgroundCreateMode,
  changeToBackgroundConsultMode,
  changeToBackgroundUpdateMode,
  changeToBackgroundDeleteMode,
  resetBackgroundMode,
  changeToSubConsultMode,
  changeToSubUpdateMode,
  changeToSubCreateMode,
  resetSubMode,
  setModel,
  resetModel,
  addToList,
  updateOnList,
  setRelatedEmailModel,
  resetRelatedEmailModel,
  resetRelatedEtapasList,
  changeLastHistoryModel,
  resetHistoryModel,
  resetLastHistoryModel,
  setResponse,
  clearResponse,
  setError,
  clearError,
} = numerarioImportacaoSlice.actions;

const numerarioImportacaoActions = numerarioImportacaoSlice.actions;

export {
  addToList, changeLastHistoryModel, changeModeTo, changeStatusTo, changeToBackgroundConsultMode, changeToBackgroundCreateMode, changeToBackgroundDeleteMode, changeToBackgroundUpdateMode, changeToConsultMode, changeToCreateMode, changeToExternalMode, changeToLockedMode, changeToSubConsultMode, changeToSubCreateMode, changeToSubUpdateMode, changeToUpdateMode, clearError, clearResponse, failure, loading, numerarioImportacaoActions, numerarioImportacaoSlice, performingAction, preparingAction, refresh, resetBackgroundMode, resetHistoryModel,
  resetLastHistoryModel, resetMode, resetModel, resetRefresh, resetRelatedEmailModel,
  resetRelatedEtapasList, resetStatus, resetSubMode, setError, setModel, setRelatedEmailModel, setResponse, success, updateOnList,
};

export default numerarioImportacaoSlice.reducer;
