import {
  Autorenew as AutorenewIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  BeenhereOutlined as BeenhereOutlineIcon,
  CancelOutlined as CancelOutlinedIcon,
  SummarizeOutlined as SummarizeOutlineIcon,
  MarkEmailReadOutlined as MarkEmailReadOutlineIcon,
  AttachEmailOutlined as AttachEmailOutlineIcon,
} from '@mui/icons-material';
import i18n from '../../../i18n';
import { getOptionByValueInList } from '../general-utils';

const SOLICITADO = 'SOLICITADO';
const IMPRESSO = 'IMPRESSO';
const ENVIADO = 'ENVIADO';
const ENVIADO_IMPRESSO = 'ENVIADO_IMPRESSO';
const PARCIAL = 'PARCIAL';
const RECEBIDO = 'RECEBIDO';
const CANCELADO = 'CANCELADO';

const LIST = [
  {
    label: i18n.t('com.muralis.qcx.numerarioImportacao.solicitado'),
    value: 'SOLICITADO',
    getIcon: () => (
      <CheckCircleOutlineIcon
        color="warning" 
      />
    ),
  },
  {
    label: i18n.t('com.muralis.qcx.numerarioImportacao.impresso'),
    value: 'IMPRESSO',
    getIcon: () => (
      <SummarizeOutlineIcon
        htmlColor="#312783"
      />
    ),
  },
  {
    label: i18n.t('com.muralis.qcx.numerarioImportacao.enviado'),
    value: 'ENVIADO',
    getIcon: () => (
      <MarkEmailReadOutlineIcon
        htmlColor="#312783"
      />
    ),
  },
  {
    label: i18n.t('com.muralis.qcx.numerarioImportacao.enviadoImpresso'),
    value: 'ENVIADO_IMPRESSO',
    getIcon: () => (
      <AttachEmailOutlineIcon
        htmlColor="#312783"
      />
    ),
  },
  {
    label: i18n.t('com.muralis.qcx.numerarioImportacao.parcial'),
    value: 'PARCIAL',
    getIcon: () => (
      <BeenhereOutlineIcon
        color="success"
      />
    ),
  },
  {
    label: i18n.t('com.muralis.qcx.numerarioImportacao.recebido'),
    value: 'RECEBIDO',
    getIcon: () => (
      <CheckCircleOutlineIcon
        color="success"
      />
    ),
  },
  {
    label: i18n.t('com.muralis.qcx.numerarioImportacao.cancelado'),
    value: 'CANCELADO',
    getIcon: () => (
      <CancelOutlinedIcon
        color="error"
      />
    ),
  },
  {
    label: i18n.t('com.muralis.qcx.numerarioImportacao.solicitacaoPendente'),
    value: 'PENDENTE',
    getIcon: () => (
      <AutorenewIcon
        htmlColor="#F39200"
      />
    ),
  },
];

const isSolicitado = (status) => (
  status === SOLICITADO
);
const isImpresso = (status) => (
  status === IMPRESSO
);
const isEnviado = (status) => (
  status === ENVIADO
);
const isEnviadoImpresso = (status) => (
  status === ENVIADO_IMPRESSO
);
const isParcial = (status) => (
  status === PARCIAL
);

const isRecebido = (status) => (
  status === RECEBIDO
);

const isCancelado = (status) => (
  status === CANCELADO
);

const getLabelByValue = (value) => {
  const foundOption = getOptionByValueInList(LIST, value);
  return foundOption && foundOption?.label;
};

const getRenderIconByValue = (value) => {
  const foundOption = getOptionByValueInList(LIST, value);
  return foundOption && foundOption?.getIcon;
};

const NumerarioImportacaoStatusUtils = Object.freeze({
  LIST,
  SOLICITADO,
  IMPRESSO,
  ENVIADO,
  ENVIADO_IMPRESSO,
  PARCIAL,
  RECEBIDO,
  CANCELADO,
  isSolicitado,
  isImpresso,
  isEnviado,
  isEnviadoImpresso,
  isParcial,
  isRecebido,
  isCancelado,
  getLabelByValue,
  getRenderIconByValue,
});

export default NumerarioImportacaoStatusUtils;
