import React, { useCallback } from 'react';
import QCXCustomFormTabControlButtonsGroup from './QCXCustomFormTabControlButtonsGroup';
import QCXCustomRegistrationOneToManyPageTemplate from './QCXCustomRegistrationOneToManyPageTemplate';

export default function QCXCustomRegistrationTabControlTemplate({
  title,
  tableProps,
  tableData,
  initialValues,
  isIdle,
  isLoading,
  isPreparingAction,
  isCreate,
  isConsult,
  isUpdate,
  isSubCreate,
  isSubConsult,
  isSubUpdate,
  isSubNone,
  isBackgroundDelete,
  isSubBackgroundCreate,
  isSubBackgroundConsult,
  isSubBackgroundUpdate,
  isSubBackgroundDelete,
  submitting,
  submitSucceeded,
  handleLoadingStatus,
  handleResetStatus,
  handleNew,
  handleCancelCreate,
  handleEdit,
  handleChangeToUpdate,
  handleCancelUpdate,
  handleChangeToConsult,
  handleClearForm,
  handleChangeModel,
  handleChangeToSubConsult,
  handleRemoveByForm,
  handleRemoveByTable,
  handleChangeToSubNone,
  handleAlternativeSave,
  sortableValueGetter,
  defaultItem,
  defaultRestartedValues,
  localeTableText,
  tabManagerProps = {},
  formPaginationControlProps = {
    dualControlButtons: {},
  },
  controlButtonsPaginationProps = {
    paginationInfoField: {
      label: 'Itens',
    },
  },
  hiddenNewButton = false,
  dualFormControlPagination = false,
  autoFormReset = true,
  autoControlInitialValues = false,
  ...restProps
}) {
  const renderFormControlButtonsComponent = useCallback(
    (formControlButtonsProps) => <QCXCustomFormTabControlButtonsGroup {...formControlButtonsProps} />,
    []
  );

  return (
    <QCXCustomRegistrationOneToManyPageTemplate
      initialValues={initialValues}
      title={title}
      tableProps={tableProps}
      tableData={tableData}
      isPreparingAction={isPreparingAction}
      isIdle={isIdle}
      isLoading={isLoading}
      isCreate={isCreate}
      isConsult={isConsult}
      isUpdate={isUpdate}
      isSubNone={isSubNone}
      isSubCreate={isSubCreate}
      isSubConsult={isSubConsult}
      isSubUpdate={isSubUpdate}
      isBackgroundDelete={isBackgroundDelete}
      isSubBackgroundCreate={isSubBackgroundCreate}
      isSubBackgroundConsult={isSubBackgroundConsult}
      isSubBackgroundUpdate={isSubBackgroundUpdate}
      isSubBackgroundDelete={isSubBackgroundDelete}
      submitting={submitting}
      submitSucceeded={submitSucceeded}
      defaultItem={defaultItem}
      handleEdit={handleEdit}
      handleChangeToSubConsult={handleChangeToSubConsult}
      handleChangeModel={handleChangeModel}
      handleNew={handleNew}
      handleCancelCreate={handleCancelCreate}
      handleCancelUpdate={handleCancelUpdate}
      handleChangeToConsult={handleChangeToConsult}
      handleRemoveByForm={handleRemoveByForm}
      handleRemoveByTable={handleRemoveByTable}
      handleClearForm={handleClearForm}
      defaultRestartedValues={defaultRestartedValues}
      handleChangeToSubNone={handleChangeToSubNone}
      handleChangeToUpdate={handleChangeToUpdate}
      handleLoadingStatus={handleLoadingStatus}
      handleResetStatus={handleResetStatus}
      localeTableText={localeTableText}
      sortableValueGetter={sortableValueGetter}
      handleAlternativeSave={handleAlternativeSave}
      tabManagerProps={tabManagerProps}
      formPaginationControlProps={formPaginationControlProps}
      controlButtonsPaginationProps={controlButtonsPaginationProps}
      renderFormControlButtonsComponent={renderFormControlButtonsComponent}
      dualFormControlPagination={dualFormControlPagination}
      autoFormReset={autoFormReset}
      autoControlInitialValues={autoControlInitialValues}
      hiddenNewButton={hiddenNewButton}
      {...restProps}
    />
  );
}
