import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';
import QCXSelectNaladiNccaAutocomplete from '../../shared-components/select-naladi-ncca/QCXSelectNaladiNccaAutocomplete';
import QCXSelectNaladiShAutocomplete from '../../shared-components/select-naladi-sh/QCXSelectNaladiShAutocomplete';
import QCXSelectNcmSubItemAutocomplete from '../../shared-components/select-ncm-subitem/QCXSelectNcmSubItemAutocomplete';
import { required as requiredValidator } from '../../utils/validators/field/validator';

export default function QCXNcmNaladiFormGroup({
  modes,
  isFilling,
  required = true,
  renderFields = {
    ncm: true,
    naladiSh: true,
    naladiNcca: true,
  },
  isDIDUIMP=false,
}) {
  const { t } = useTranslation();

  const {
    isConsult,
    isSubConsult,
    isSubNone,
  } = modes;

  const { values } = useFormState();

  return (
    <>
      {renderFields?.ncm && (
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXSelectNcmSubItemAutocomplete
            id="text-field-ncm-id"
            key="text-field-ncm-id"
            name="ncm.id"
            label={t('com.muralis.qcx.NCM.NCMSH')}
            fieldProps={required && {
              validate: requiredValidator,
            }}
            externallyFillable={isFilling}
            disabled={isConsult || isSubConsult || isSubNone || isDIDUIMP}
            required={required}
          />
        </Grid>
      )}
      {renderFields?.naladiSh && (
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXSelectNaladiShAutocomplete
            id="text-field-naladiSh-id"
            key="text-field-naladiSh-id"
            name="naladiSh.id"
            label={t('com.muralis.qcx.naladi.naladiSH')}
            initialValues={values}
            disabled={isConsult || isSubConsult || isSubNone || isDIDUIMP}
            externallyFillable={isFilling}
          />
        </Grid>
      )}
      {renderFields?.naladiSh && (
        <Grid
          item
          style={{
            paddingBottom: '16px',
          }}
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <QCXSelectNaladiNccaAutocomplete
            id="autocomplete-select-field-naladi-ncca-id"
            key="autocomplete-select-field-naladi-ncca-id"
            name="naladiNcca.id"
            label={t('com.muralis.qcx.naladi.naladiNCCA')}
            initialValues={values}
            disabled={isConsult || isSubConsult || isSubNone || isDIDUIMP}
            externallyFillable={isFilling}
          />
        </Grid>
      )}
    </>
  );
}
