/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../dados-embarque/base-components/BaseSelectWithOptions';

function SelectTipoRateioDespesasAcessorias({ ...props }: any) {
  const list = useMemo(
    () => [
      {
        id: 'CIF',
        label: 'CIF',
        value: 'CIF',
      },
      {
        id: 'PESO',
        label: 'PESO',
        value: 'PESO',
      },
    ],
    []
  );

  return <BaseSelectWithOptions list={list} {...props} />;
}

export default SelectTipoRateioDespesasAcessorias;
