import { Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import QCXFinalDatePickerField from '../../../../shared-components/final-date-picker-field/QCXFinalDatePickerField';
import QCXFinalTextField from '../../../../shared-components/final-text-field/QCXFinalTextField';
import QCXSelectClienteAutocomplete from '../../../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXSelectNcmSubItemAutocomplete from '../../../../shared-components/select-ncm-subitem/QCXSelectNcmSubItemAutocomplete';
import MultiSelectFormInput from '../../../../ts/common/components/forms/MultiSelectFormInput';
import QCXFinalMultilineInputWithChips from '../transferencia-lote/TransferenciaLoteManagerPage/QCXFinalMultilineInputWIthChips';


export default function FiltrosDuimpToConsultPage() {
  const { t } = useTranslation();

  const options = [
    {
      id: 'SIM',
      value: 'SIM',
    },
    {
      id: 'NAO',
      value: 'NÃO',
    },
    {
      id: 'TODOS',
      value: 'TODOS',
    },
  ];

  function renderDatas() {
    //! APENAS PARA NAO PERDER O CODIGO DOS INPUTS DE DATAS, QUANDO FOR IMPLEMENTA-LAS NOVAMENTE VOLTAR PARA O RETURN PRINCIPAL DO COMPONETE
    return (
      <>
    
        <>
          <Grid item sm={6}>
            <QCXFinalDatePickerField
              id="date-picker-field-insertion-date"
              key="date-picker-field-insertion-date"
              label={t('com.muralis.qcx.dataAberturaRange0')}
              name="dataAberturaRange0"
              required={undefined}
              validate={undefined}
            />
          </Grid>

          <Grid item sm={6}>
            <QCXFinalDatePickerField
              id="date-picker-field-insertion-date"
              key="date-picker-field-insertion-date"
              label={t('com.muralis.qcx.dataAberturaRange1')}
              name="dataAberturaRange1"
              required={undefined}
              validate={undefined}
            />
          </Grid>
        </>

        <>
          <Grid item sm={6}>
            <QCXFinalDatePickerField
              id="date-picker-field-insertion-date"
              key="date-picker-field-insertion-date"
              label={t('com.muralis.qcx.dataPrevisaoChegadaRange0')}
              name="dataPrevisaoChegadaRange0"
              required={undefined}
              validate={undefined}
            />
          </Grid>


          <Grid item sm={6}>
            <QCXFinalDatePickerField
              id="date-picker-field-insertion-date"
              key="date-picker-field-insertion-date"
              label={t('com.muralis.qcx.dataPrevisaoChegadaRange1')}
              name="dataPrevisaoChegadaRange1"
              required={undefined}
              validate={undefined}
            />
          </Grid>
        </>

        <>
          <Grid item sm={6}>
            <QCXFinalDatePickerField
              id="date-picker-field-insertion-date"
              key="date-picker-field-insertion-date"
              label={t('com.muralis.qcx.dataChegadaRange0')}
              name="dataChegadaRange0"
              required={undefined}
              validate={undefined}
            />
          </Grid>


          <Grid item sm={6}>
            <QCXFinalDatePickerField
              id="date-picker-field-insertion-date"
              key="date-picker-field-insertion-date"
              label={t('com.muralis.qcx.dataChegadaRange1')}
              name="dataChegadaRange1"
              required={undefined}
              validate={undefined}
            />
          </Grid>
        
        </>


        <Grid item sm={12}>
          <MultiSelectFormInput name="calculados" options={options} label="Calculados" />
        </Grid>

      </>
    );
  }

  return (
    <Box style={{ minWidth: '500px' }}>
      <Grid container spacing={2}>

        <Grid item sm={12}>
          <Box mt={1}>
            <QCXFinalMultilineInputWithChips
              name="processos"
              label="Num Processos"
            />
          </Box>
        </Grid>

        <Grid item sm={12}>
          <QCXSelectClienteAutocomplete
            id="text-field-cliente"
            key="text-field-cliente"
            name="clienteId"
            label={t('com.muralis.qcx.cliente.label')}
            required={false}
            filter={undefined}
            initialValues={undefined}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
