export const generateFileTxt = (txt: string, name: string) => {
    const blob = new Blob([txt], { type: 'text/plain' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = name;

    document.body.appendChild(link);
    link.click();

  document.body.removeChild(link);
}

interface GenerateDanfeResponse {
  statusCode: number;
  message: string;
  file?: Blob
}

export async function generateTxt( token: string, selectedUnidade: string, url: string, typeResponse?: string): Promise<GenerateDanfeResponse> {

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.append('Authorization', `Bearer ${token}`);
  requestHeaders.append('quickcomexTenant', selectedUnidade);

  const response = await fetch(url, {
    method: 'GET',
    headers: requestHeaders,
  });

  if (typeResponse && typeResponse === "file" && response.ok) {
     return {
      statusCode: response.status,
      message: "Download Realizado",
      file: await response.blob()
    }
  } 

  return {
    statusCode: response.status,
    message: await response.text()
  }

};

