/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: corrigir as tipagens e parar de usar any
import React, { useMemo } from 'react';
import BaseSelectWithOptions from '../base-components/BaseSelectWithOptions';

function EmpresaCourierSelect({ ...props }: any) {
  const list = useMemo(
    () => [
      {
        id: 'DHL',
        label: 'DHL',
        value: 'DHL',
      },
      {
        id: 'FEDEX',
        label: 'FEDEX',
        value: 'FEDEX',
      },
      {
        id: 'UPS',
        label: 'UPS',
        value: 'UPS',
      },
    ],
    []
  );

  return <BaseSelectWithOptions list={list} {...props} />;
}

export default EmpresaCourierSelect;
