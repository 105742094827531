const selectDadosEmbarques = (state) => state.dadosEmbarqueExportacao.list;
const selectDadosEmbarqueExpCount = (state) => state.dadosEmbarqueExportacao.count;
const selectDadosEmbarque = (state) => state.dadosEmbarqueExportacao.model;
const selectRelatedReportModel = (state) => state.dadosEmbarqueExportacao.related.model.report;
const selectRelatedItemModel = (state) => state.dadosEmbarqueExportacao.related.model.item;
const selectRelatedAdicaoModel = (state) => state.dadosEmbarqueExportacao.related.model.adicao;
const selectRelatedCalculoModel = (state) => state.dadosEmbarqueExportacao.related.model.calculo;
const selectRelatedDuplicataModel = (state) => state.dadosEmbarqueExportacao.related.model.duplicata;
const selectRelatedRegistroModel = (state) => state.dadosEmbarqueExportacao.related.model.registro;
const selectRelatedEmissaoIcmsModel = (state) => state.dadosEmbarqueExportacao.related.model.emissaoIcms;
const selectHistoryModel = (state) => state.dadosEmbarqueExportacao.historyModel;
const selectStatus = (state) => state.dadosEmbarqueExportacao.status;
const selectRefresh = (state) => state.dadosEmbarqueExportacao.refresh;
const selectMode = (state) => state.dadosEmbarqueExportacao.mode.main;
const selectBackgroundMode = (state) => state.dadosEmbarqueExportacao.mode.background;
const selectSubMode = (state) => state.dadosEmbarqueExportacao.mode.sub;
const selectSubBackgroundMode = (state) => state.dadosEmbarqueExportacao.mode.subBackground;
const selectError = (state) => state.dadosEmbarqueExportacao.error;
const selectResponse = (state) => state.dadosEmbarqueExportacao.response;
const selectConditionalSteps = (state) => state.dadosEmbarqueExportacao.config.conditionalSteps;

const DadosEmbarqueSelectors = {
  selectDadosEmbarques,
  selectDadosEmbarque,
  selectRelatedReportModel,
  selectRelatedItemModel,
  selectRelatedAdicaoModel,
  selectRelatedCalculoModel,
  selectRelatedDuplicataModel,
  selectRelatedRegistroModel,
  selectRelatedEmissaoIcmsModel,
  selectHistoryModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectSubBackgroundMode,
  selectError,
  selectResponse,
  selectConditionalSteps,
  selectDadosEmbarqueExpCount,
};

export {
  DadosEmbarqueSelectors,
  selectDadosEmbarques,
  selectDadosEmbarque,
  selectRelatedReportModel,
  selectRelatedItemModel,
  selectRelatedAdicaoModel,
  selectRelatedCalculoModel,
  selectRelatedDuplicataModel,
  selectRelatedRegistroModel,
  selectRelatedEmissaoIcmsModel,
  selectHistoryModel,
  selectStatus,
  selectRefresh,
  selectMode,
  selectBackgroundMode,
  selectSubMode,
  selectSubBackgroundMode,
  selectError,
  selectResponse,
  selectConditionalSteps,
  selectDadosEmbarqueExpCount,
};
