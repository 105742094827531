import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QCXFormSubtitle from '../../../../../../components/form-title/QCXFormSubtitle';
import QCXButton from '../../../../../../shared-components/button/QCXButton';

const ExportarCatalogoPopup = ({ open, onClose, onConfirm }) => {
  const { t } = useTranslation();

  const [diChecked, setDiChecked] = useState(false);
  const [duimpChecked, setDuimpChecked] = useState(false);

  const isButtonDisabled = !diChecked && !duimpChecked;

  const handleConfirm = () => {
    onConfirm({
      di: diChecked,
      duimp: duimpChecked,
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Exportar Catálogo de Produtos para Excel</DialogTitle>
      <DialogContent dividers>
        <Box>
          <QCXFormSubtitle title="Filtrar por:" />

          <Box mt={1} width="100%" display="flex" flexDirection="row" alignItems="center" gridGap={16}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={diChecked}
                  onChange={(event) => setDiChecked(event.target.checked)}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={t('com.muralis.qcx.DIDUIMP.DI')}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={duimpChecked}
                  onChange={(event) => setDuimpChecked(event.target.checked)}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={t('com.muralis.qcx.DIDUIMP.DUIMP')}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box mt={2} display="flex" alignItems="center" justifyContent="flex-end" gridGap={16}>
          <QCXButton onClick={onClose} color="primary">
            Fechar
          </QCXButton>

          <QCXButton disabled={isButtonDisabled} onClick={handleConfirm} color="secondary">
            Exportar
          </QCXButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ExportarCatalogoPopup;
