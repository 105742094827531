import CardsGridSubmenu from '../../../common/components/cardsGrid/cardsGridSubmenu';
import BasicLayout from '../../../common/layouts/basicLayout';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { CardsGridItem } from '../../../common/components/cardsGrid/cardsGrid';
import AssessmentIcon from '@mui/icons-material/Assessment';

const cover: CardsGridItem = {
  title: 'Croda',
  icon: AssessmentIcon,
  path: '/integracao',
};

const gridItems: CardsGridItem[] = [
  {
    title: 'Escrita',
    icon: DriveFileRenameOutlineIcon,
    path: '/integracao/croda/escrita',
  },
];

const CrodaPage = () => {
  return (
    <BasicLayout
      title={'Croda'}
      icon={<AssessmentIcon color={'secondary'} />}
    >
      <CardsGridSubmenu cover={cover} items={gridItems}></CardsGridSubmenu>
    </BasicLayout>
  );
};

export default CrodaPage;
