import { Box, Grid } from '@material-ui/core';
import ProcessosInfoForm from '../InformaçõesTabSection/ProcessosInfoForm';
import QCXFinalMultilineInputWithChips from '../QCXFinalMultilineInputWIthChips';
import QCXFinalTextField from '../../../../../../shared-components/final-text-field/QCXFinalTextField';

function FaturaSection() {
  return (
    <>
      <QCXFinalMultilineInputWithChips name="processos" label="Números dos Processos:" />

      <Box marginTop={2}>
        <ProcessosInfoForm />
      </Box>

      <Grid item xs={6}>
        <QCXFinalTextField
          id="text-field-numeroEmbarqueVinculado"
          key="text-field-numeroEmbarqueVinculado"
          label="Número Embarque Vinculado"
          name="numeroEmbarqueVinculado"
        />
      </Grid>
    </>
  );
}

export default FaturaSection;
