import { Grid, Box } from '@material-ui/core';
import QCXFinalMultilineInputWithChips from '../QCXFinalMultilineInputWIthChips';
import EtapaForms from './EtapaForms';
import ProcessosInfoForm from './ProcessosInfoForm';
import SelectProduto from './SelectProduto';

type InformacoesSectionProps = { isExpo?: boolean };

function InformacoesSection({ isExpo = false }: InformacoesSectionProps) {
  return (
    <>
      <QCXFinalMultilineInputWithChips name="processos" label="Números dos Processos:" />

      <Box marginTop={2}>
        <ProcessosInfoForm />
      </Box>

      {!isExpo && (
        <Grid xs={6} style={{ paddingBottom: '1rem' }}>
          <SelectProduto name="produto" label="Repassar para:" />
        </Grid>
      )}
      <EtapaForms />
    </>
  );
}

export default InformacoesSection;
